import { types } from './types';
import { createFilters } from './forms';

export const mutations = {

  [types.SET_OBSERVATION_TEMPLATE_LOADING](state, value) {
    state.observationTemplateLoading = value;
  },

  [types.SET_OBSERVATION_TEMPLATE](state, observationTemplate) {
    state.observationTemplate = observationTemplate;
  },

  [types.INIT_PAGE](state, { conclusions }) {
    const {
      conclusionNames,
      conclusionBy,
    } = createFilters({ conclusions });
    state.conclusionNames = conclusionNames;
    state.conclusionBy = conclusionBy;
  },

};
