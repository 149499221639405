import { types } from './types';
import { createInputConfig } from '@h4h/inputs';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import moment from 'moment';
import { otherPredefinedReason, stopEnrollmentReasonsCodes } from './stopEnrollmentReasonsCodes';
import { stopEnrollmentInputs } from './utils';
import { createEhDateInputConfig } from '../../../../../utils/factories';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PRISTINE](state, value) {
    stopEnrollmentInputs(state).forEach(input => input.pristine = value);
  },

  [types.INIT_POPUP](state, enrollments) {
    state.loading = false;
    state.popupId = null;
    state.enrollments = enrollments;
    state.endDateInput = createEhDateInputConfig({
      id: 'endDate',
      type: InputType.Date,
      value: new Date(),
      required: true,
      min: moment().startOf('day').toDate(),
      label: 'enrollments.deregistrationDate',
    });
    state.reasonInput = createInputConfig({
      id: 'reason',
      type: InputType.Textarea,
      value: state.reason ? state.reason.value : null,
      required: true,
      label: 'enrollments.otherPleaseSpecify',
    });
    let options = [
      ...stopEnrollmentReasonsCodes.map(code => ({
        value: code,
        label: 'stopEnrollmentReasons.' + code
      })),
      {
        value: otherPredefinedReason,
        label: 'enrollments.other'
      }
    ];
    state.stopEnrollmentReasonsInput = createInputConfig({
      id: 'stopEnrollmentPredefinedReasons',
      type: InputType.Select2,
      value: null,
      required: true,
      label: 'enrollments.reason',
      options
    });
  },

  [types.SET_POPUP_ID](state, popupId) {
    state.popupId = popupId;
  },
};
