import { router } from '../services/router';
import { localize } from '../services/localize';

const watchedMutationTypes = new Set(['route/ROUTE_CHANGED', 'preferences/setLocale']);

export const pageTitle = {
  namespaced: true,

  state: {
    name: '',
    title: ''
  },

  actions: {
    setName({ commit }, name) {
      commit('setName', name);
    }
  },

  mutations: {
    setName(state, name) {
      const title = localize(name);
      state.name = name;
      state.title = title;
      document.title = `H4H - ${ title }`;
    }
  }
};

export function pageTitlePlugin(store) {
  store.subscribe(function(mutation) {
    if (!watchedMutationTypes.has(mutation.type)) {
      return;
    }

    const names =
      mutation.type === 'route/ROUTE_CHANGED'
        ? router.getRouteMeta('title', mutation.payload.to)
        : router.getRouteMeta('title');

    store.dispatch('pageTitle/setName', names[0]);
  });
}
