export const state = {
  program: null,
  inputs: [],
  availablePeriods: [],
  supportedVendors: [],
  loading: false,
  observationThresholds: [],
  primaryPeriod: null,
  observationGroups: [],
  questionnaireGroups: [],
  fullEdit: false,
};
