export const cronEditor = {
  day: 'Day',
  week: 'Week',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
  advanced: 'Advanced',
  timeOfDay: 'Time of Day',
  suffixSt: 'st',
  suffixNd: 'nd',
  suffixRd: 'rd',
  suffixTh: 'th',
  addTimeslot: 'Add time slot',
  measurementDays: 'Day of the month',
  addMeasurementDay: 'Add measurement day',
  frequency: 'Frequency',
  invalid: 'Current settings are invalid.',
  days: 'Days',
  months: 'Months',
  seconds: 'Seconds',
  minutes: 'Minutes',
  hours: 'Hours',
  daysOfMonth: 'Days of month',
  daysOfWeek: 'Days of week',
  validationSixParts: 'A valid cron expression must consist of six parts.',
  validationValues: 'values:',
  validationFromTo: 'numbers from {from} to {to}',
  validationSingleNumber: 'a single number',
  validationAListOfNumbers: 'a list of numbers',
  validationARangeOfNumbers: 'a range of numbers',
  validationAStepValue: 'a step value',
  validationWeekdays: 'Weekdays: MON, TUE, WED, THU, FRI, SAT, SUN',
  validationAnyWeekday: 'any weekday',
  validationAListOfWeekdays: 'a list of weekdays',
  validationARangeOfWeekdays: 'a range of weekdays',
};