import { isString } from 'lodash';
import { isCallCenter } from '../../../services/auth/auth';
import { PatientFilterFields } from './constants';
import { shortFilerConstraints } from '../../../services/filter/utils/filterUtils';

export const getters = {
  filtersAreEmpty,
  lastXPatientsMode,

  dateOfBirthInput,
  emailInput,
  nameInput,
  includeArchivedInput,
  patientIdInput,

  tableInputs,
};

function dateOfBirthInput(state) {
  return state.inputs.find(input => input.id === PatientFilterFields.dateOfBirth);
}

function emailInput(state) {
  return state.inputs.find(input => input.id === PatientFilterFields.email);
}

function nameInput(state) {
  return state.inputs.find(input => input.id === PatientFilterFields.name);
}

function includeArchivedInput(state) {
  return state.inputs.find(input => input.id === PatientFilterFields.includeArchived);
}

function patientIdInput(state) {
  return state.inputs.find(input => input.id === PatientFilterFields.patientId);
}

function tableInputs(state) {
  const ids = [
    PatientFilterFields.name,
    PatientFilterFields.patientId,
    PatientFilterFields.dateOfBirth,
    PatientFilterFields.email,
  ];
  return state.inputs.filter(input => ids.indexOf(input.id) > -1);
}

function nonEmptyTextInput(input) {
  const value = input?.value;
  if (isString(value)) {
    return value.length >= shortFilerConstraints.min;
  }
  return false;
}

function filtersAreEmpty(state) {
  if (isCallCenter()) {
    return false;
  }
  if (nonEmptyTextInput(nameInput(state))) {
    return false;
  }
  if (nonEmptyTextInput(patientIdInput(state))) {
    return false;
  }
  if (dateOfBirthInput(state)?.value) {
    return false;
  }
  if (nonEmptyTextInput(emailInput(state))) {
    return false;
  }
  return true;
}

function lastXPatientsMode(state) {
  if (isCallCenter()) {
    return false;
  }
  return state.lastXPatientsMode;
}

