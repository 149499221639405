import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { types } from './types';
import { ObservationThresholdDialogInitModel } from './models';
import { ObservationThresholdModel } from '../../../model/thresholds/observationThresholdModel';
import { OBSERVATION_THRESHOLD_POPUP_MODE } from './constants';
import {
  createResetThresholdInputAction
} from '../../../components/observation/thresholds/dialog/components/ThresholdInputsGroup';
import {
  createInputStateChangeAction
} from '../../../components/observation/thresholds/dialog/components/InputStateChange';
import { setInputValueAction } from '@h4h/inputs';

export const actions = Object.freeze({
  initThresholdPopup,
  save,
  close,
  clearInput,
  resetThresholds,
  setShowDeletedThresholds,
  addMissingItems,
  resetItem,
  deleteItem,
  inputStateChange: createInputStateChangeAction(types.INPUT_STATE_CHANGED),
});

async function clearInput({ commit, dispatch }, input) {
  await setInputValueAction({ commit, dispatch }, { input, value: null });
}

function resetThresholds({ commit }) {
  commit(types.RESET_THRESHOLDS);
}

function setShowDeletedThresholds({ commit }, value) {
  commit(types.SET_SHOW_DELETED_THRESHOLDS, value);
}

function addMissingItems({ commit }) {
  commit(types.SET_SHOW_DELETED_THRESHOLDS, true);
  commit(types.ADD_MISSING_ITEMS);
}

function resetItem({ commit, dispatch }, group) {
  if (group.deleted) {
    commit(types.SET_ITEM_DELETE_STATE, { group, value: false });
  }
  else if (group.created) {
    commit(types.DELETE_ADDED_ITEM, group);
  }
  else {
    createResetThresholdInputAction()({ commit, dispatch }, group.input);
  }
}

function deleteItem({ commit }, group) {
  commit(types.SET_ITEM_DELETE_STATE, { group, value: true });
}

async function save({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.valid) {
    return;
  }
  const modifiedOnly = state.mode === OBSERVATION_THRESHOLD_POPUP_MODE.PERSONALIZED_THRESHOLD;
  const threshold = new ObservationThresholdModel({
    ...state.observationThreshold,
    severityConditionPerDefinition: state.thresholdInputsGroups.map(tig => tig.toObservationThresholdSeverityConditionsModel(modifiedOnly))
  });
  popups.hide({ id: state.popupId, result: threshold });
}

async function initThresholdPopup({ commit }, { observationThreshold, observationTemplate, terminologyTypes, mode }) {
  commit(types.INIT_DIALOG, new ObservationThresholdDialogInitModel({
    observationThreshold,
    observationTemplate,
    terminologyTypes,
    mode,
  }));
  const { id, closed } = await popups.show({
    name: PopupName.ObservationThreshold,
    type: PopupType.Modal
  });
  commit(types.SET_POPUP_ID, id);
  return { closed };
}

async function close({ state }) {
  popups.hide({ id: state.popupId });
}

