<template>
  <div :class="styles.flexEnd">
    <div
      v-for="input in inputs"
      :key="input.id"
      :class="input.styleClass || getInputStyles(input)"
    >
      <slot :name="createInputSlotName(input.id)">
        <Select
          v-if="input.type === InputType.Select && !input.dropdown"
          v-bind="input"
          :inline="true"
          @change="$props.setInputValueAction({ input, value: $event })"
        />
        <Smart
          v-else
          ref="inputs"
          :input="input"
          :info="input.info"
          :action="$props.setInputValueAction"
          :searchAction="input.searchAction"
          @keyup.enter.native="$emit('applyFilters')"
        />
      </slot>
    </div>
  </div>
</template>

<script>
  import { InputType, Smart, Select } from '@h4h/inputs';
  import styles from './filter.scss';
  import { createInputSlotName } from '../../utils/slotUtils';

  export default {
    name: 'H4hFilterRow',

    components: {
      Smart,
      Select
    },

    props: {
      inputs: {
        type: Array,
        required: true,
        validator: inputs => Array.isArray(inputs) && inputs.length <= 4
      },

      setInputValueAction: {
        type: Function,
        required: true
      }
    },

    data() {
      return {
        styles,
        createInputSlotName,
        InputType
      };
    },

    methods: {
      getInputStyles(input) {
        switch (input.type) {
          case InputType.Select2:
            return styles.select;
          case InputType.DateRange:
            return styles.dateRange;
          case InputType.Date:
            if (input.range) {
              return styles.dateRange;
            }
            return styles.date;
          case InputType.Checkbox:
            return styles.checkbox;
          case InputType.Select:
            if (!input.dropdown) {
              return styles.checkbox;
            }
            return styles.select;
          case InputType.Text:
          case InputType.Float:
          case InputType.Integer:
            return styles.text;
          default:
            return styles.select;
        }
      }
    }
  };
</script>
