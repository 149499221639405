import { Page } from '../../../constants/page';
import { currentTenant } from '../../api/tenant/tenant';
import { showRestError } from '../../../utils/errors';
import { callCenterTenantId, isCallCenter, setTenant } from '../../auth/auth';

export async function rootRedirect(to, from, next) {
  if (to.name !== Page.Root) {
    next();
    return;
  }

  let tenant;
  if (isCallCenter()) {
    tenant = callCenterTenantId;
  }
  else {
    const result = await currentTenant.fetchOne();
    if (!result.success) {
      showRestError(result.error, 'messages.cantObtainTenantInfo');
      next();
      return;
    }
    tenant = result.data.name;
  }
  if (tenant) {
    setTenant(tenant);
    next({ name: Page.Tenant, params: { tenantId: tenant } });
  }
  else {
    next({ name: Page.Ingress });
  }
}

