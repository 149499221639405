import { types } from './types';
import { EcgDrawModel } from '../../../../../../../../components/ecg/models';

export const mutations = {
  [types.SET_POPUP_ID](state, popupId) {
    state.popupId = popupId;
  },

  [types.SET_DATA](state, { ecg, rawEcg }) {
    state.ecg = ecg;
    state.rawEcg = rawEcg;
    state.ecgDrawModel = new EcgDrawModel({
      height: 200,
      width: 200 * ecg.duration,
      totalDurationInSeconds: ecg.duration,
      amplitudeResolution: rawEcg.amplitudeResolution,
      durationInSeconds: ecg.duration,
      values: rawEcg.rawECGData,
      scale: 0.4,
    });
  },
};

