import { state } from './state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { settings } from './settings';
import { practitionerAnamnesisSettings } from './practitioner';
import { withInputs } from '@h4h/inputs';

export const anamnesis = withInputs({
  namespaced: true,

  state,
  actions,
  getters,
  mutations,
  modules: {
    settings,
    practitionerAnamnesisSettings,
  }
});
