import { AnswerOptionType } from '../../../../constants/answerType';
import { AnswerOptionModel } from '../../../../model/questionnaireTemplateModel';
import { mapValues } from 'lodash';
import { getInputsMap } from '@h4h/inputs';

export const getters = {
  isValid,
  visibleInputs,
  model,
};

function isValid(state) {
  const inputs = visibleInputs(state);
  return inputs.length && inputs.every(i => i.valid);
}

function model(state) {
  let inputs = [state.inputsMap.type, ...visibleInputs(state)];
  const answer = new AnswerOptionModel(mapValues(getInputsMap(inputs), i => i.value));
  if (answer.answerTerminology) {
    answer.answerTerminology = answer.answerTerminology.value;
  }
  return answer;
}

function visibleInputs(state) {
  if (state.inputsMap) {
    const type = state.inputsMap.type;
    let inputs = [];
    switch (type.value) {
      case AnswerOptionType.TERMINOLOGY :
        inputs.push(state.inputsMap.answerTerminology);
        break;
      case AnswerOptionType.NUMERIC :
        inputs.push(state.inputsMap.answerNumeric);
        break;
      case AnswerOptionType.INTEGER :
        inputs.push(state.inputsMap.answerInt);
        break;
      case AnswerOptionType.FREE_STRING :
        inputs.push(state.inputsMap.answerFreeString);
        break;
    }
    return inputs;
  }
  return [];
}
