export const observationTemplates = {
  observationTemplates: 'Observation templates',
  addObservationTemplate: 'Add observation template',
  observationTemplate: 'Observation template',
  terminologyType: 'Terminology type',
  definitionsCount: 'Definitions count',
  definitions: 'Definitions',
  addDefinition: 'Add definition',
  inputType: 'Input type',
  unitCode: 'Unit code',
  limits: 'Limit values',
  criticalWhenValueHigherThan: 'Critical when value higher than',
  criticalWhenValueIsLessThan: 'Critical when value is less than',
  deviatingWhenAValueOfGreaterThan: 'Deviating when a value of greater than',
  deviatingWhenValueIsLessThan: 'Deviating when value is less than',
  resetDefaultLimits: 'Reset default limits',
  xMustNotBeLowerThanY: '`{0}` must not be lower than `{1}`',
  upperLimit: 'Upper limit',
  warningHigh: 'Warning high',
  warningLow: 'Warning low',
  lowerLimit: 'Lower limit',
  oppositeLimit: '(to {0} {1})',
  pleaseWaitWhileObservationThresholdsAreUpdating: 'Please wait while observation thresholds are updating',
  showRemovedLimits: 'Show removed limits',
  hideRemovedLimits: 'Hide removed limits',
  addMissingLimits: 'Add missing limits',
};
