/* global API_URL */

import { axios } from '../services/axios';
import { tryRequest } from '../utils/tryRequest';

// @todo: Shveikus - extend CrudService from @h4h/classes, add missing functionality to CrudService from @h4h/classes
export class CrudService {
  /**
   *
   * @param {String}    endpointPath
   * @param {Function}  [dataMapper]  - map data received from the API
   * @param {Function}  [modelMapper] - map data sent to API
   * @param {String}    baseUrl
   */
  constructor(
    endpointPath,
    dataMapper = data => data,
    modelMapper = model => model,
    baseUrl = API_URL
  ) {
    this.originalPath = endpointPath;

    if (!endpointPath.endsWith('/')) {
      endpointPath += '/';
    }

    this.path = endpointPath;
    this.dataMapper = dataMapper;
    this.modelMapper = modelMapper;
    this.baseUrl = baseUrl;
  }

  transformConfig(config = null) {
    return {
      baseURL: this.baseUrl,
      ...(config || {})
    };
  }

  async fetch(config = null) {
    return tryRequest(
      () => axios.get(this.originalPath, this.transformConfig(config)),
      data => data.map(this.dataMapper)
    );
  }

  async fetchOne(config = null) {
    return tryRequest(
      () => axios.get(this.originalPath, this.transformConfig(config)),
      this.dataMapper
    );
  }

  async get(id, config = null) {
    return tryRequest(
      () => axios.get(this.path + id, this.transformConfig(config)),
      this.dataMapper
    );
  }

  async create(model, config = null) {
    return tryRequest(
      () => axios.post(this.originalPath, this.modelMapper(model), this.transformConfig(config)),
      this.dataMapper
    );
  }

  async update(id, model, config = null) {
    return tryRequest(
      () => axios.put(this.path + id, this.modelMapper(model), this.transformConfig(config)),
      this.dataMapper
    );
  }

  async updateOne(model, config = null) {
    return tryRequest(
      () => axios.put(this.originalPath, this.modelMapper(model), this.transformConfig(config)),
      this.dataMapper
    );
  }

  async patch(id, model) {
    return tryRequest(
      () => axios.patch(this.path + id, this.modelMapper(model), this.transformConfig()),
      this.dataMapper
    );
  }

  async patchOne(model, config = null) {
    return tryRequest(
      () => axios.patch(this.originalPath, this.modelMapper(model), this.transformConfig(config)),
      this.dataMapper
    );
  }

  async delete(id) {
    return tryRequest(
      () => axios.delete(this.path + id, this.transformConfig())
    );
  }

  async deleteOne() {
    return tryRequest(
      () => axios.delete(this.originalPath, this.transformConfig())
    );
  }

}
