import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig } from '@h4h/inputs';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PHYSICIANS_LOADING](state, value) {
    state.physiciansLoading = value;
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.INIT_FORM](state, patientId) {
    state.inputs = [];
    state.physicians = [];
    state.loading = false;
    state.patientId = patientId;
  },

  [types.SET_DATA](state, { responsiblePhysicianId, physicians, sortedPhysiciansForSelect, label }) {
    state.physicians = physicians;
    state.sortedPhysiciansForSelect = sortedPhysiciansForSelect;
    const responsiblePhysician = createInputConfig({
      id: 'responsiblePhysicianId',
      placeholder: 'patient.noResponsiblePhysician',
      type: InputType.Typeahead2,
      required: true,
      label: label,
      value: responsiblePhysicianId ? responsiblePhysicianId : null,
      options: sortedPhysiciansForSelect,
    });
    state.inputs = [responsiblePhysician];
  },
};
