<template>
  <div class="tooltipWrapper">
    <Tooltip
      v-if="$props.info"
      :bottom="true"
      :nudgeTop="8"
      :maxWidth="250"
      :zIndex="styles.tooltipZindex"
    >
      <template #activatorContent>
        <div :class="[styles.label, $props.labelStyle]">
          {{ $localize($props.label) }}
          <Icon
            type="info"
            :class="styles.icon"
          />
        </div>
      </template>
      <template #info>
        <span :class="styles.tooltip">{{ $localize($props.info) }}</span>
      </template>
    </Tooltip>
    <span
      v-else
      :class="[styles.label, $props.labelStyle]"
    >
      {{ $localize($props.label) }}
    </span>
  </div>
</template>

<script>
  import { Tooltip } from '@h4h/tooltip';

  import { inputProps } from '../../utils';

  import styles from './labelTooltip.scss';

  export default {
    name: 'H4hLabelTooltip',

    components: {
      Tooltip,
    },

    props: {
      /** labels */
      info: inputProps.label,
      label: inputProps.label,

      /** styles */
      labelStyle: inputProps.style
    },

    data() {
      return {
        styles,
      };
    },

  };
</script>
