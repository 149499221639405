import moment from 'moment';

import { DATE_FORMAT, TIME_FORMAT, DATETIME_FORMAT } from './constants';

export function parseDate(date, format = DATE_FORMAT, isStrict = false) {
  return moment(date, format, isStrict).toDate();
}

export function parseTime(date, format = TIME_FORMAT, isStrict = false) {
  return moment(date, format, isStrict).toDate();
}

export function parseDateTime(date, format = DATETIME_FORMAT, isStrict = false) {
  return moment(date, format, isStrict).toDate();
}
