import { types } from './types';
import { createInputConfig, getInputsMap, InputType, validateTextInput } from '@h4h/inputs';

export const mutations = {
  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.INIT_POPUP](state, { popupId, groups, programInput }) {
    state.popupId = popupId;
    state.inputs = getInputsConfig(groups);
    state.inputsMap = getInputsMap(state.inputs);
    state.groups = groups;
    state.programInput = programInput;
  },

};

function getInputsConfig(groups) {
  const name = createTextInputConfig(groups, 'name', 'common.name', InputType.Text);
  return [
    name,
  ];
}

function createTextInputConfig(groups, id, label, inputType, required = true) {
  return createInputConfig({
    id,
    type: inputType,
    label,
    required,
    validate: input => {
      if (validateTextInput(input)) {
        return !groups.some(group => group.name === input.value);
      }
      return false;
    }
  });
}

