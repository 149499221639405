import axios from 'axios';
import { isNil } from 'lodash';
import Keycloak from 'keycloak-js';

import { Deferred } from '@h4h/classes';

import { Role } from './constants';

export class AuthService {
  /**
   * Authentication service - handles authentication (via Keycloak) and permission checks
   *
   * NOTE: MP has dynamic roles with static permissions, while eHealth has static roles
   *
   * @param {Promise<{ keycloak, locale? }>} configPromise
   * @param {Function} roleMapper
   */
  constructor(configPromise, roleMapper) {
    this.roleMapper = roleMapper;
    this.keycloak = null;

    this._toggles = null;
    this._locale = null;
    this._permissions = null;
    this.allowedPeriodOfInactivity = null;

    this.initialized = configPromise.then(({ keycloak, locale, toggles }) => {
      this.keycloak = new Keycloak(keycloak);
      this._locale = locale;
      this._toggles = toggles;
    });

    this.authenticated = new Deferred();
  }

  async login() {
    if (!this.keycloak) {
      await this.initialized;
    }

    if (this.keycloak.authenticated) {
      await this.updateToken();

      return {
        success: true,
        data: this.keycloak
      };
    }

    try {
      await this.keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false // prevent infinite reload loop due to iframe not seeing all cookies since Chrome 80 release
      });

      /*
        Allowed period of inactivity is the SSO Session Idle, which is configured on keycloak.
        The current implementation with message module, gives the heartbeat of the application on
        every 60 seconds so the keycloak is not aware of the activity of the user.
      */
      this.allowedPeriodOfInactivity = new Date(this.keycloak.refreshTokenParsed.exp * 1000).getTime() - new Date().getTime();

      this.authenticated.resolve();

      axios.defaults.headers.common = this._getAuthObj();

      this.followInactivity();

      return {
        success: true,
        data: this.keycloak
      };
    }
    catch (error) {
      return {
        success: false,
        error
      };
    }
  }

  isAuthenticated() {
    return !!this.keycloak?.authenticated;
  }

  followInactivity() {
    this.tracker();

    this.interval = setInterval(() => {
      if (Date.parse(localStorage.getItem('user_inactivity')) < Date.now()) {
        this.keycloak.logout();

        localStorage.removeItem('user_inactivity');
        clearInterval(this.interval);
      }
    }, 1000);
  }

  updateUserInactivity() {
    /* in case app is opened in several tabs */
    localStorage.setItem('user_inactivity', new Date(Date.now() + this.allowedPeriodOfInactivity));
  }

  tracker() {
    this.updateUserInactivity();

    window.onkeyup = () => this.updateUserInactivity();
    window.onmousemove = () => this.updateUserInactivity();
  }

  _getAuthObj() {
    if (!this.isAuthenticated()) {
      return {};
    }

    return {
      Authorization: `Bearer ${ this.keycloak.token }`
    };
  }

  getHeaders = async() => {
    await this.authenticated.promise;
    return this._getAuthObj();
  };

  logout() {
    if (this.isAuthenticated()) {
      this.keycloak.logout();
    }
  }

  async updateToken() {
    // do not update the tocken if it is still valid
    if (!this.keycloak || !this.keycloak.isTokenExpired()) {
      return;
    }
    try {
      await this.keycloak.updateToken(10);
      axios.defaults.headers.common = this._getAuthObj();
    }
    catch {
      /* If the session is expired logout the user */
      this.keycloak.logout();
    }
  }

  hasRole(role) {
    if (!this.isAuthenticated()) {
      return false;
    }

    return this.keycloak.hasRealmRole(this.roleMapper(role));
  }

  isToggled(selectedToggle) {
    // if toggle service is not deployed (toggles is []), app should not be blocked
    const toggle = this._toggles?.find(t => t.propKey === selectedToggle)?.propValue;
    return isNil(toggle) ? true : toggle;
  }

  hasPermission(name) {
    if (this.hasRole(Role.Admin)) {
      return true;
    }

    return !!this._permissions && this._permissions.includes(name) && !this._permissions.includes(name + '.never');
  }

  hasLocalePermission(locales) {
    return locales.includes(this._locale);
  }

  // Returns the userId from KeyCloak database
  getKeycloakUserId() {
    return this.keycloak?.subject;
  }

  // Returns the token
  // TODO: ProjectMerge - check implementation
  getParsedToken() {
    return this.keycloak?.tokenParsed;
  }

  // Returns the userId from tenant database
  getUserId() {
    return this.keycloak?.tokenParsed.uuid;
  }

  getUserName() {
    return this.keycloak?.tokenParsed.preferred_username;
  }

  getClientRoles() {
    return this.keycloak?.tokenParsed?.resource_access;
  }
}
