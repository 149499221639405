import { types } from './types';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_ANAMNESIS_TEMPLATE_ID](state, anamnesisTemplateId) {
    state.anamnesisTemplateId = anamnesisTemplateId;
  },
};
