import { getCronFrequencyTypes } from './types';
import { cronValidator } from '../../../utils/cronValidator';
import { regexValidators } from '../../../utils/cronValidator/constants';

/* A helper function to determine a suffix of a number */
export function determineNumberSuffix(number) {
  const numberAsString = `${ number }`;
  const lastDigit = parseInt(numberAsString.substr(numberAsString.length - 1, 1), 10);

  switch (lastDigit) {
    case 1:
      return 'cronEditor.suffixSt';
    case 2:
      return 'cronEditor.suffixNd';
    case 3:
      return 'cronEditor.suffixRd';
    default:
      return 'cronEditor.suffixTh';
  }
}

function numericCronArrayToArray(str) {
  return str.split(',').map(h => parseInt(h, 10));
}

function cronRangeToObject(str) {
  const split = str.split('-');
  return {
    from: split[0],
    to: split[1],
  };
}

export function parseCronExpression(cronString) {
  const types = getCronFrequencyTypes(true);
  const result = {
    valid: false,
    // default type is advanced unless determined otherwise
    type: types.Advanced,
    // seconds can be safely ignored when parsing an expression
    parsed: {
      minutes: null,
      hours: [],
      days: [],
      months: [],
      daysOfWeek: [],
    },
  };

  // check if the cron string is valid
  if (!cronValidator(cronString)) {
    return result;
  }
  const parts = cronString.trim().split(' ').filter(p => p && p.trim().length > 0);
  // we need exactly six parts of the expression
  if (parts.length !== 6) {
    return result;
  }

  const cronObject = {
    seconds: parts[0].trim(),
    minutes: parts[1].trim(),
    hours: parts[2].trim(),
    days: parts[3].trim(),
    months: parts[4].trim(),
    daysOfWeek: parts[5].trim(),
  };

  // seconds must be zero (note: maybe remove this since seconds are irrelevant?)
  if (cronObject.seconds !== '0') {
    return result;
  }
  // minutes must always contain only a simple number in all cases
  if (!regexValidators.isNumericValue.test(cronObject.minutes)) {
    return result;
  }
  cronObject.minutes = parseInt(cronObject.minutes, 10);
  // hours can only be an array of values for all cases
  const hoursAreAnArray = regexValidators.isListOfNumericValues.test(cronObject.hours);
  // days of month can be an array of values for Monthly frequency
  // .. or a range of values for Yearly frequency
  const daysAreAnArray = regexValidators.isListOfNumericValues.test(cronObject.days);
  const daysAreARange = regexValidators.isRangeOfNumericValues.test(cronObject.days);
  // months can be an array of values for Yearly frequency
  const monthsAreAnArray = regexValidators.isListOfNumericValues.test(cronObject.months);
  // days of week can be an array of values or * for Weekly frequency
  // or a numeric value for the Yearly frequency
  const daysOfWeekAreAnArray = regexValidators.isAnArrayOfWeekdays.test(cronObject.daysOfWeek);
  const daysOfWeekAreASingleValue = regexValidators.isASingleWeekday.test(cronObject.daysOfWeek);

  // pre-parse minutes and hours since we always need them
  if (!hoursAreAnArray) {
    return result;
  }
  result.valid = true;
  result.parsed.minutes = parseInt(cronObject.minutes, 10);
  result.parsed.hours = numericCronArrayToArray(cronObject.hours);

  // check if it's potentially a Daily frequency expression
  if (
    hoursAreAnArray &&
    cronObject.days === '*' &&
    cronObject.months === '*' &&
    cronObject.daysOfWeek === '*') {
    result.type = types.Day;
    result.parsed.minutes = parseInt(cronObject.minutes, 10);
    result.parsed.hours = numericCronArrayToArray(cronObject.hours);
  }
  // check if it's potentially a Weekly frequency expression
  else if (
    hoursAreAnArray &&
    cronObject.days === '*' &&
    cronObject.months === '*' &&
    daysOfWeekAreAnArray
  ) {
    result.type = types.Week;
    result.parsed.hours = numericCronArrayToArray(cronObject.hours);
    result.parsed.daysOfWeek = cronObject.daysOfWeek.split(',');
  }
  // check if it's potentially a Monthly frequency expression
  else if (
    hoursAreAnArray &&
    daysAreAnArray &&
    cronObject.months === '*' &&
    cronObject.daysOfWeek === '*'
  ) {
    result.type = types.Month;
    result.parsed.hours = numericCronArrayToArray(cronObject.hours);
    result.parsed.days = numericCronArrayToArray(cronObject.days);
  }
  // check if it's potentially a Yearly frequency expression
  else if (
    hoursAreAnArray &&
    daysAreARange &&
    monthsAreAnArray &&
    daysOfWeekAreASingleValue
  ) {
    result.type = types.Year;
    result.parsed.hours = numericCronArrayToArray(cronObject.hours);
    result.parsed.days = cronRangeToObject(cronObject.days);
    result.parsed.months = numericCronArrayToArray(cronObject.months);
    result.parsed.daysOfWeek = cronObject.daysOfWeek;
  }
  return result;
}

