/* Supported cron job frequency types */
const cronFrequencyTypes = Object.freeze({
  Day: { value: 'Day', label: 'cronEditor.daily' },
  Week: { value: 'Week', label: 'cronEditor.weekly' },
  Month: { value: 'Month', label: 'cronEditor.monthly' },
  Year: { value: 'Year', label: 'cronEditor.yearly' },
});

const cronAdvancedType = Object.freeze({
  Advanced: { value: 'Advanced', label: 'cronEditor.advanced' },
});

export function getCronFrequencyTypes(advanced) {
  const res = Object.freeze({
    ...cronFrequencyTypes,
    ...(advanced ? cronAdvancedType : {}),
  });
  return res;
}
/* A map of the supported cron frequency types */
export function getCronFrequencyTypesOptionMap(cronTypes) {
  const res = Object.freeze(
    Object.keys(cronTypes).map(key => cronTypes[key])
  );
  return res;
}

