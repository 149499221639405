import { isNil } from 'lodash';

/**
 * @param {*} value
 * @param {Number} defaultValue
 * @returns {Number}
 */
export function numberOrDefault(value, defaultValue) {
  return valueOrDefault(value, defaultValue, Number.isFinite);
}

/**
 * Passes through `value` if it is valid, uses `defaultValue` otherwise
 *
 * @template T
 * @param {Any} value
 * @param {T} defaultValue
 * @param {(value: any) => value is T} isValid
 *
 * @returns {T}
 */
export function valueOrDefault(value, defaultValue, isValid = isNil) {
  return isValid(value) ? value : defaultValue;
}
