export const tasks = {
  pageTitle: 'Taken',

  searchTasks: 'Zoek taken',
  loadingTasks: 'Taken laden',
  noTasksFound: 'Geen taken gevonden',

  taskType: 'Taaktype',
  program: 'Programma',
  values: 'Waardes',
  notification: 'Melding',

  myPatients: 'Mijn patiënten',
  allPatients: 'Alle patiënten',
  viewProfile: 'Dossier weergeven',

  severity: 'Urgentie',
  urgency: 'Taak urgentie',
  period: 'Periode',

  status: 'Status',
  closed: 'Gesloten',
  open: 'Open',
  handled: 'Afgehandeld',
  unhandled: 'Onverwerkt',

  low: 'Normaal',
  high: 'Kritisch',
  medium: 'Afwijkend',
  info: 'Informatief',
  unclassified: 'Niet geclassificeerd',

  handle: 'Afhandelen',
  created: 'Gemaakt',
  reassign: 'Doorzetten',
  completed: 'Afgemaakt',
  assignedTo: 'Toegewezen aan',
  taskHistory: 'Taak geschiedenis',
  handlePopupMessage: 'Hoe wil je deze taken afhandelen?',
  handlePopupHeader: 'Afhandelen',
  handlePopupDoneNothingOption: 'Geen actie nodig',
  handlePopupContactedPatientOption: 'Contact opgenomen',
  handleLogisticPopupMessage: 'Wilt u deze taken afhandelen?',
  infoMessage: 'Voor uw patiënt, %{lastName}, %{firstName} is monitoringsperiode is afgelopen. Controleer de metingen om de volgende stappen te bepalen.',
  information: 'Info',
  observation: 'Observatie',
  message: 'Bericht',
  forward: 'Doorsturen',
  forwarded: 'Doorgestuurd',
  forwardTaskPopupHeader: 'Taak doorsturen',
  forwardTask: 'Taak doorsturen',
  recipient: 'Ontvanger',
  currentResponsiblePhysician: '{0} (huidige behandelend arts)',
  ccSupportUser: '{0} (Zenuwcentrum Dokkum)',

  average: 'Gemiddelde',
  saveAndHandle: 'Opslaan en afhandelen',
  pleaseChooseTask: 'Kies een taak',
  taskIsForwarded: 'Taak wordt doorgestuurd',

  informationalNotifications: 'Informatieve meldingen',
  _7days: '7 dagen',
  _14days: '14 dagen',
  _30days: '30 dagen',
};
