import { createInputConfig, getInputsMap, InputType, setInputValue } from '@h4h/inputs';
import { defaultChosenSeverityForTasks, severityOptionsForTasks } from '../../constants/severity';
import {
  PractitionerTasksHandlingStatus,
  practitionerTasksHandlingStatusOptions
} from '../../constants/practitionerTasks';

import { types } from './types';
import { createEhPeriodInputConfig } from '../../utils/factories';
import { TaskListTypesModelFactory } from '../../utils/taskListTypesModelFactory';
import { cleanPageNum, toServerPageOptions } from '../../model/page';
import { createMainFilterInput } from '../../services/filter/utils/filterUtils';
import { findByKey } from '../../filters/findByKey';
import { localize } from '../../services/localize';
import { allNotificationTypes } from '../../constants/notificationType';
import { TaskListTypes } from '../../constants/taskListTypes';

function parsePeriodFilterValue(userFilter) {
  if (userFilter) {
    if (Array.isArray(userFilter)) {
      return userFilter.map(parsePeriodFilterValue);
    }
    else {
      return new Date(userFilter);
    }
  }
  return null;
}

export const mutations = {
  [types.SET_HANDLING](state, handling) {
    state.handling = handling;
  },

  [types.SET_POPUP_ID](state, popupId) {
    state.popupId = popupId;
  },

  [types.SET_TENANT_ID](state, value) {
    state.tenantId = value;
  },

  [types.SET_CURRENT_FORWARDING_TASK_ID](state, value) {
    state.currentForwardingTaskId = value;
  },

  [types.SET_TASKS_FOR_HANDLE](state, tasksForHandle) {
    state.tasksForHandle = tasksForHandle;
  },

  [types.SET_PROGRAMS_LOADING](state, value) {
    state.programsLoading = value;
  },

  [types.SET_PROGRAMS](state, value) {
    state.programs = value;
  },

  [types.SET_OBSERVATION_TEMPLATES](state, value) {
    state.observationTemplates = value;
  },

  [types.SET_OBSERVATION_TEMPLATES_LOADING](state, value) {
    state.observationTemplatesLoading = value;
  },

  /* task list mutations */
  [types.INIT_LIST_MODEL](state, listType) {
    state.taskLists[listType] = TaskListTypesModelFactory(listType);
    state.listInitialized = false;
  },

  [types.UPDATE_FILTER_PARAMS](state, { listObject }) {
    listObject.updateFilterParams();
    cleanPageNum(listObject);
  },

  [types.APPLY_FILTERS](state, { listObject, profile }) {
    const userFilters = profile?.filters;
    if (!userFilters) {
      return;
    }
    const inputsMap = getInputsMap(listObject.inputs);

    setInputValue({
      input: inputsMap['multipleFilter'],
      value: userFilters['multipleFilter'] || '',
    });
    setInputValue({
      input: inputsMap['program'],
      value: new Set(userFilters['program'] || []),
    });
    setInputValue({
      input: inputsMap['terminologyType'],
      value: new Set(userFilters['terminologyType'] || []),
    });
    setInputValue({
      input: inputsMap['messageCodes'],
      value: new Set(userFilters['messageCodes'] || []),
    });
    setInputValue({
      input: inputsMap['status'],
      value: new Set(userFilters['status'] || []),
    });
    setInputValue({
      input: inputsMap['severity'],
      value: new Set(userFilters['severity'] || []),
    });
    setInputValue({
      input: inputsMap['forwarded'],
      value: userFilters['forwarded'],
    });
    setInputValue({
      input: inputsMap['period'],
      value: parsePeriodFilterValue(userFilters['period']),
    });
    listObject.updateFilterParams();
    cleanPageNum(listObject);
  },

  [types.POPULATE_LIST_MODEL](state, { listType, terminologyTypes, tableFiltersProfile }) {
    const listModel = state.taskLists[listType];
    const inputs = [];
    const userFilters = tableFiltersProfile?.filters;

    const { staticInputValues, inputExtensions } = listModel;

    const periodLabel = listType === TaskListTypes.Default ? 'patients.observationDate' : 'common.date';
    const periodConfig = {
      id: 'period',
      label: periodLabel,
      placeholder: periodLabel,
      required: false,
      value: null,
    };
    if (userFilters?.period) {
      periodConfig.value = parsePeriodFilterValue(userFilters.period);
    }

    inputs.push(
      // createOwnPatientsInputConfig(staticInputValues, inputExtensions),
      createSearchInputConfig({ label: 'common.name' }, userFilters),
      createProgramInputConfig(state.programs, inputExtensions, userFilters),
      createMessageCodeConfig(userFilters),
      createObservationTypesInputConfig(state, terminologyTypes, inputExtensions, userFilters),
      createStatusInputConfig(staticInputValues, inputExtensions, userFilters),
      createSeverityInputConfig(staticInputValues, inputExtensions, userFilters),
      createEhPeriodInputConfig(periodConfig),
      createForwardedInputConfig(inputExtensions, userFilters),
    );

    listModel.inputs = inputs.filter(x => !!x);
    listModel.updateFilterParams();
    state.listInitialized = true;
  },

  [types.SET_LIST_LOADING](state, { listObject, value }) {
    listObject.loading = value;
  },

  [types.SET_TASKS_PAGE](state, { listObject, value }) {
    if (value && Array.isArray(value?.content)) {
      // proactively, manually set the status of the forwarding task to true
      // since the status update on the Task Service occurs asynchronously
      value.content.forEach(item => {
        if (item.id === state.currentForwardingTaskId) {
          item.forwarded = true;
          state.currentForwardingTaskId = null;
        }
      });
    }
    listObject.tasksPage = value;
    Object.assign(listObject.serverSideOptions, toServerPageOptions(value));
  },

  [types.SET_PAGINATION_CONFIG](state, { listObject, value }) {
    Object.assign(listObject.serverSideOptions, value);
  },

  [types.SET_SELECTED_TASKS](state, { listObject, value }) {
    listObject.selectedTasks = value;
  },
};

// function createOwnPatientsInputConfig(staticInputValues, inputExtensions) {
//   return createInputConfig({
//     id: 'ownPatients',
//     type: InputType.Select2,
//     value: staticInputValues?.ownPatients,
//     options: [
//       {
//         id: 'tasks.myPatients',
//         value: true,
//         label: 'tasks.myPatients'
//       },
//       {
//         id: 'tasks.allPatients',
//         value: false,
//         label: 'tasks.allPatients'
//       }
//     ],
//     ...(inputExtensions?.ownPatients || {}),
//   });
// }

function createSearchInputConfig(config = null, userFilters) {
  return createMainFilterInput({
    id: 'multipleFilter',
    ...config,
    value: userFilters?.multipleFilter || null,
  });
}

function createSeverityInputConfig(staticInputValues, inputExtensions, userFilters) {
  return createInputConfig({
    id: 'severity',
    multiple: true,
    value: staticInputValues?.severity || new Set(userFilters?.severity || defaultChosenSeverityForTasks),
    type: InputType.Select2,
    label: 'tasks.severity',
    showSelectAll: false,
    options: severityOptionsForTasks,
    required: false,
    ...(inputExtensions?.severity || {}),
  });
}

function createForwardedInputConfig(inputExtensions, userFilters) {
  return createInputConfig({
    id: 'forwarded',
    multiple: false,
    value: userFilters?.forwarded || null,
    type: InputType.Select2,
    label: 'tasks.forwarded',
    options: [
      {
        label: 'common.yes',
        value: 'true',
      },
      {
        label: 'common.no',
        value: 'false', // a hack to send to server. If we pass just boolean true/false then UI thinks input is not filled.
      },
    ],
    required: false,
    ...(inputExtensions?.forwarded || {}),
  });
}

function createStatusInputConfig(staticInputValues, inputExtensions, userFilters) {
  const nonStaticValue = userFilters?.status || [PractitionerTasksHandlingStatus.UNHANDLED];
  return createInputConfig({
    id: 'status',
    multiple: true,
    showSelectAll: false,
    value: staticInputValues?.status || new Set(nonStaticValue),
    type: InputType.Select2,
    label: 'tasks.status',
    placeholder: 'common.select',
    options: practitionerTasksHandlingStatusOptions,
    required: false,
    ...(inputExtensions?.status || {}),
  });
}

function createMessageCodeConfig(userFilters) {
  return createInputConfig({
    id: 'messageCodes',
    multiple: true,
    value: new Set(userFilters?.messageCodes || []),
    type: InputType.Select2,
    label: 'tasks.notification',
    placeholder: 'common.select',
    options: allNotificationTypes.map(type => ({ label: type, value: type })),
    required: false,
  });
}

function createProgramInputConfig(programs, inputExtensions, userFilters) {
  const programNames = new Set(programs.map(p => p.name));
  const programOptions = Array.from(programNames)
    .map(name => ({
      value: name,
      label: name
    }))
    .map(option => {
      option.sortField = localize(option.label).toLocaleLowerCase();
      return option;
    })
    .sort((o1, o2) => o1.sortField.localeCompare(o2.sortField));
  return createInputConfig({
    id: 'program',
    multiple: true,
    showSelectAll: false,
    value: new Set(userFilters?.program || []),
    type: InputType.Select2,
    label: 'common.program',
    placeholder: 'common.select',
    options: programOptions,
    required: false,
    ...(inputExtensions?.program || {}),
  });
}

function createObservationTypesInputConfig(state, terminologyTypes, inputExtensions, userFilters) {
  if (!state.observationTemplates) {
    return null;
  }
  const options = state.observationTemplates
    .map(name => ({
      value: name,
      label: findByKey(name, 'key', terminologyTypes, 'translation')
    }))
    .map(option => {
      option.sortField = localize(option.label).toLocaleLowerCase();
      return option;
    })
    .sort((o1, o2) => o1.sortField.localeCompare(o2.sortField));
  return createInputConfig({
    id: 'terminologyType',
    multiple: true,
    showSelectAll: false,
    value: new Set(userFilters?.terminologyType || []),
    type: InputType.Select2,
    label: 'common.type',
    placeholder: 'common.select',
    options,
    required: false,
    ...(inputExtensions?.terminologyType || {}),
  });
}
