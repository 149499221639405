<template>
  <VBtn
    :class="className"

    :title="title"

    :xSmall="$props.xSmall"
    :small="$props.small"
    :large="$props.large"
    :xLarge="$props.xLarge"

    :fab="$props.round"
    :disabled="$props.disabled || $props.loading"

    :ripple="false"
    :depressed="true"
    :tabindex="tabIndex"

    v-on="$listeners"
    @focus="onFocus"
  >
    <SpinnerLoading
      v-if="$props.loading"
      inheritColor
    />

    <slot v-else>
      <Icon
        v-if="$props.icon"
        :type="$props.icon"
        :class="styles.icon"
        :title="title"
      />
      <span
        v-if="$props.label && !$props.round"
        :class="styles.label"
      >
        {{ $localize($props.label) }}
      </span>
    </slot>
  </VBtn>
</template>

<script>
  import { VBtn } from 'vuetify/lib';

  import { Icon } from '@h4h/icons';
  import { SpinnerLoading } from '@h4h/loading';
  import { TabIndexSubscriberMixin } from '@h4h/tab-index-manager';

  import styles from './button.scss';

  export default {
    name: 'H4hButton',

    components: {
      SpinnerLoading,
      Icon,
      VBtn
    },

    mixins: [
      TabIndexSubscriberMixin
    ],

    props: {
      label: [String, Array, Number],
      disabled: Boolean,

      loading: {
        type: Boolean,
        default: false
      },

      // size // xSmall is old value, not to be used anymore
      xSmall: Boolean,
      small: Boolean,
      large: Boolean,
      xLarge: Boolean,

      // visual
      text: Boolean,      // transparent bg, no box-shadow, overrides outlined and noShadow
      outlined: Boolean,  // transparent bg without border.
      noShadow: Boolean,  // no box-shadow

      inverted: Boolean,
      primary: Boolean,
      secondary: Boolean,
      tertiary: Boolean,
      approve: Boolean,
      success: Boolean,
      warning: Boolean,
      destructive: Boolean,
      error: Boolean,
      info: Boolean,

      icon: String,
      tooltip: [String, Array],

      round: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        styles
      };
    },

    computed: {
      title() {
        const { label, tooltip, round } = this.$props;

        if (tooltip) {
          return this.$localize(tooltip);
        }
        else if (round) {
          return this.$localize(label);
        }

        return null;
      },

      className() {

        return [
          styles.button,
          this.sizeClass,
          this.colorClass,
          ...this.stateClasses
        ];
      },

      sizeClass() { // xSmall is old value, not to be used anymore
        const { xSmall, small, large, xLarge } = this.$props;

        switch (true) {
          case xLarge:
            return styles.xl;
          case large:
            return styles.l;
          case small:
            return styles.s;
          case xSmall:
            return styles.xs;
          default:
            return styles.m;
        }
      },

      colorClass() {
        const { inverted, primary, info, secondary, tertiary, success, approve, warning, error, destructive } = this.$props;

        // prioritize colors
        switch (true) {
          case inverted:
            return styles.inverted;
          case primary:
            return styles.primary;
          case tertiary:
            return styles.default;
          case error || destructive:
            return styles.error;
          case warning:
            return styles.warning;
          case success || approve:
            return styles.success;
          case info || secondary:
            return styles.info;
          default:
            return styles.default;
        }
      },

      stateClasses() {
        const { text, outlined, noShadow } = this.$props;

        if (text) {
          return [styles.text];
        }

        return [
          outlined && styles.outlined,
          !noShadow && styles.shadow
        ];
      }
    },

    methods: {
      onFocus($event) {
        this.$emit('focus', $event);

        this.tabIndexSubscriber__onFocus();
      }
    }
  };
</script>
