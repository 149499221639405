<template>
  <div
    ref="container"
    :class="styles.container"
  >
    <div
      ref="transition"
      :class="styles.transition"
    >
      <div
        :class="[styles.array, !_multipleItemsShown && styles.hidden]"
      >
        <span
          v-for="(value, ind) in formattedValue"
          :key="ind"
        >
          {{ value }}
        </span>

        <span
          v-if="formattedValue.length > 1"
          :class="styles.multipleItems"
          @click="toggle"
        >
          {{ $localize('common.less') }}
        </span>
      </div>

      <div
        :class="_multipleItemsShown && styles.hidden"
      >
        {{ formattedValue[0] }}
        <span
          v-if="formattedValue.length > 1"
          :class="styles.multipleItems"
          @click="toggle"
        >
          {{ `(+${ formattedValue.length - 1 })` }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import styles from './multipleItem.scss';
  import { ArrayProp } from '@h4h/classes';

  export default {
    name: 'H4hMultipleItem',

    props: {
      formattedValue: new ArrayProp({
        type: [String, Number],
        required: true
      }),

      multipleItemsShown: {
        required: true,
        type: Boolean
      }
    },

    data() {
      return {
        styles,
        localMultipleItemsShown: false
      };
    },

    computed: {
      _multipleItemsShown: {
        get() {
          return this.$props.multipleItemsShown ?? this.localMultipleItemsShown;
        },
        set(v) {
          this.localMultipleItemsShown = v;
          this.$emit('update:multipleItemsShown', v);
        }
      }
    },

    watch: {
      '_multipleItemsShown'() {
        this.$nextTick(() => {
          this.setContainerHeight();
        });
      },
    },

    methods: {
      toggle(e) {
        e.stopPropagation();
        this._multipleItemsShown = !this._multipleItemsShown;
      },

      setContainerHeight() {
        this.$refs.container.style.height = this.$refs.transition.clientHeight + 'px';
      }
    }
  };
</script>
