import { types } from './types';
import { PopupType } from '@h4h/popups';
import { PopupName } from '../../constants/popupName';
import { popups } from '../../services/popups';
import { AddressProvisionResult } from './models';
import { fetchCountries } from '../countries/utils';

export const actions = Object.freeze({
  showAddressDialog,
  close,
  save,
  closeWithPendingAddress,
});

/**
 *
 * @param commit
 * @param state
 * @param {AddressDialogInitModel} model
 */
async function showAddressDialog({ commit, dispatch }, model) {
  const countries = await fetchCountries(dispatch);
  commit(types.INIT_POPUP, { model, countries });
  const { id, closed } = await popups.show({
    name: PopupName.EditAddress,
    type: PopupType.Modal
  });
  commit(types.SET_POPUP_ID, id);
  const result = await closed;
  if (result.success && result.data) {
    return result.data;
  }
  return {};
}

async function save({ commit, getters, state }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.valid) {
    return;
  }
  popups.hide({
    id: state.popupId,
    result: {
      address: state.address.value,
      addressDialogResult: AddressProvisionResult.CONFIRM_ADDRESS
    },
  });
}

async function closeWithPendingAddress({ state }) {
  popups.hide({
    id: state.popupId,
    result: {
      addressDialogResult: AddressProvisionResult.WAIT_FOR_PENDING_USER_ADDRESS_REQUEST
    },
  });
}

async function close({ state }) {
  popups.hide({ id: state.popupId });
}

