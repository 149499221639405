import { AddressKind } from '../constants';

export class PoBoxAddressModel {
/**
 * Address config
 *
 * @param {Object}        config
 * @param {String}        config.id
 * @param {String}        config.addressType
 * @param {String}        config.city
 * @param {Country}       config.country
 * @param {String}        config.postalCode
 * @param {String}        config.poBox
 * @param {AddressKind}   config.addressKind
 **/
  constructor(config) {
    this.id = config.id;
    this.poBox = config.poBox;
    this.postalCode = config.postalCode;
    this.city = config.city;
    this.country = config.country;
    this.addressType = config.addressType;
    this.addressKind = AddressKind.PoBox;
  }

  isEmpty() {
    return ['id', 'city', 'country', 'poBox', 'postalCode'].every(field => !this[field]);
  }
}
