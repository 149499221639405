export class SupportedLanguagesModel {
  /**
   * SupportedLanguagesModel
   *
   * @param {Object}    config
   * @param {String[]}    config.supportedLanguagesIsoCodes
   */
  constructor(config) {
    this.supportedLanguagesIsoCodes = config.supportedLanguagesIsoCodes;
  }
}
