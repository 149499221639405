export const state = {
  fetched: false,
  loading: false,
  organizationSetting: null,
  callCenterSettings: null,
  callCenterSettingsloading: false,
  activePopupId: null,
  inputs: null,
  inputsMap: null,
  customForwardingLinkToDoctor: null,
  timezones: null,
};
