export const group = {
  groups: 'Groups',
  defaultForRole: 'Default role',
  addGroup: 'Add group',
  editGroup: 'Edit group',
  deleteGroup: 'Delete group',
  deleteGroupConsent: 'Do you want to delete the group?',
  Admins: 'Admins',
  POHs: 'POHs',
  Physicians: 'Physicians',
  atLeastOneGroupIsRequired: 'At least one group is required',
};
