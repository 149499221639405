import { Page } from '../../constants/page';
import { rootRedirect } from './redirects/rootRedirect';
import { appViews } from './routes/appViews';
import { SpecialPath } from './specialPath';
import { tenantRedirect } from './redirects/tenantRedirect';

export const routes = [
  {
    path: '/',
    name: Page.Root,
    beforeEnter: rootRedirect,
    component: () => import('../../components/containers/full/Full'),
    children: [
      {
        path: ':tenantId',
        name: Page.Tenant,
        beforeEnter: tenantRedirect,
        component: () => import('../../components/containers/EmptyRouterView'),
        children: appViews
      },
      {
        path: SpecialPath.Ingress.getPath(),
        name: SpecialPath.Ingress.page,
        component: () => import('../../components/views/app/ingress/IngressComponent'),
        meta: {
          title: 'tenant.authorize'
        }
      },
    ]
  },
  {
    path: SpecialPath.Page404.getPath(),
    name: SpecialPath.Page404.page,
    component: () => import('../../components/views/NotFound'),
    meta: {
      title: 'common.pageNotFound'
    }
  },
  {
    path: SpecialPath.Page403.getPath(),
    name: SpecialPath.Page403.page,
    component: () => import('../../components/views/Forbidden'),
    meta: {
      title: 'common.forbidden'
    }
  },
  {
    path: '*',
    redirect: {
      name: Page.NotFound
    }
  }
];
