import { createInputConfig, InputType } from '@h4h/inputs';

export function getPopupInputConfig() {
  return [
    createInputConfig({
      id: 'name',
      type: InputType.Text,
      label: 'common.filterName',
      inline: true,
      required: true,
    }),
    createInputConfig({
      id: 'defaultProfile',
      type: InputType.Checkbox,
      label: 'common.makeDefaultFilter',
    }),
  ];
}

export function getEditProfileInputConfig(profileName) {
  return [
    createInputConfig({
      id: 'name',
      type: InputType.Text,
      label: 'common.filterName',
      required: true,
      value: profileName,
    }),
  ];
}
