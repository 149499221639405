export const questionnaireTemplates = {
  questionnaireTemplates: 'Questionnaire templates',
  questionnaireTemplate: 'Questionnaire template',
  addQuestionnaireTemplate: 'Add questionnaire template',

  terminologyType: 'Terminology type',
  questionsCount: 'Questions count',
  questions: 'Questions',

  addQuestion: 'Add question',
  answerTerminologyUnit: 'Answer terminology unit',
  questionTerminologyCode: 'Question terminology code',
  answerType: 'Answer type',
  answersValues: 'Answer values',

  addAnswer: 'Add answer',
  answer: 'Answer',
  answers: 'Answers',
  atLeastOneAnswerIsRequired: 'At least one answer is required',
  constrains: 'Constrains',
  minMaxShouldBeDefinedOrNot: 'Min and Max should be defined or not defined at same time.',
  minShouldBeLessMax: 'Min should be less then Max.',
  thisQuestionAlreadyAdded: 'This question is already added',
};
