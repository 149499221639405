import { showRestError } from '../../../utils/errors';
import { types } from './types';
import { anamnesisSetting } from '../../../services/api/anamnesis/anamnesisSettings';

export const actions = Object.freeze({
  initPractiotionerAnamnesisSettings,
});

async function initPractiotionerAnamnesisSettings({ commit }) {
  commit(types.SET_ANAMNESIS_TEMPLATE_INFO_LOADED, false);
  const result = await anamnesisSetting.fetchOne();
  commit(types.SET_ANAMNESIS_TEMPLATE_INFO_LOADED, true);
  if (result.success) {
    commit(types.SET_ANAMNESIS_TEMPLATE_ID, result.data.questionnaireTemplateId);
  }
  else {
    showRestError(result.error, 'messages.cantFetchAnamnesisSettings');
  }
}

