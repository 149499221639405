export const troubleshooting = {
  troubleshootingReport: 'Rapport voor probleemoplossing',
  status: 'Status',
  url: 'URL',
  responseId: 'Trace id',
  response: 'Reactie',
  callcenterFlag: 'Callcenter vlag',
  tenantIdFlag: 'Tenant id vlag',
  payload: 'Laadvermogen',
  page: 'Bladzijde',
  captureOnlyErrors: 'Leg alleen fouten vast',
};
