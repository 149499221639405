<template>
  <div>
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />
    <div :class="styles.inputContainer">
      <NumberInput
        v-for="(item, index) in inputs"
        :key="item.id"
        v-bind="item"
        :groupId="$props.groupId"
        :class="styles.input"
        :max="1"
        :success="$props.success"
        :valid="$props.valid && inputs[index].valid"
        :inputStyle="styles.container"
        :data-testid="`field-${$props.name}`"
        @change="change($event, index)"
      />
    </div>
  </div>
</template>

<script>
  import { uniqueId } from 'lodash';

  import {
    inputProps,
    setInputValue,
    createInputConfig
  } from '../../utils';

  import { InputMixin } from '../../mixins';
  import { InputType } from '../../constants';

  import NumberInput from '../Number';
  import LabelGroup from '../labelGroup/LabelGroup';

  import styles from './codeInput.scss';

  export default {
    name: 'H4HCodeInput',

    components: {
      NumberInput,
      LabelGroup
    },

    mixins: [
      InputMixin
    ],

    props: {
      value: String,

      length: {
        type: Number,
        default: 6,
      },

      /** labels */
      info: inputProps.label,
      otherLabel: inputProps.label,

      /** flags */
      success: inputProps.booleanFalse,

      /** styles */
      labelStyle: inputProps.style
    },

    data() {
      return {
        styles,
        focused: false,
        inputs: this.createInputs(),
        id: uniqueId('code-input-')
      };
    },

    methods: {
      change(value, index) {
        setInputValue({ input: this.inputs[index], value });

        this.$emit('change', this.inputs.map(i => i.value).join(''));
      },

      createInputs() {
        const { length, name } = this.$props;

        return Array(length).fill(null).map((_, i) =>
          createInputConfig({
            id: `codeInput-${ i }`,
            type: InputType.Integer,
            name: `${ name }-${ i }`,
            required: true,
            valid: true,
            min: 0,
            max: 9
          })
        );
      }
    }
  };
</script>
