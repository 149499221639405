export const healthRecords = {
  phone: 'Telefoon',
  email: 'Email',
  reassurance: 'Geruststelling',
  // consultByPractitioner: 'Consult huisarts',
  consultByCardiologist: 'Consult behandelend cardioloog',
  consultByPhoneCardiologist: 'Telefonisch consult behandelend cardioloog',
  refToFirstAid: 'Verwijzing SEH',
  other: 'Anders',
  asystole: 'Asystolie',
  atrialFibrillation: 'AFIB',
  atrialFlutter: 'Aflutter',
  avBlockFirstDegree: '1e gr AV blok',
  avBlockSecondDegree: '2e gr AV blok',
  avBlockThirdDegree: '3e gr AV blok',
  indivisible: 'Onbeoordeelbaar',
  malfunction: 'Storing',
  pac: 'PAC',
  pvc: 'PVC',
  svt: 'SVT',
  sinusRhythm: 'Sinusritme',
  sinusJudgment: 'Sinusarrest',
  pause: 'Pauze (n.n.o.)',
  sinusbradycardie: 'Sinusbradycardie',
  sinustachycardie: 'Sinustachycardie',
  doubletPac: 'Doublet PAC',
  doubletPvc: 'Doublet PVC',
  avnrt: 'AVNRT',
  atrialTachycardia: 'Atriale Tachycardie',
  nsvt: 'NSVT',
  sustainedVt: 'Sustained VT',
  sa_block: 'SA blok',
  bigeminy: 'Bigemini',
};
