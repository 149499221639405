import { localize } from '../../services/localize';

export const getters = {
  avatarFileName,
  isAvatarFilePresent,
  hasAvatar
};

function avatarFileName(state) {
  if (isAvatarFilePresent(state)) {
    return state.avatarFile.name;
  }
  return localize('avatar.addPhoto');
}

function isAvatarFilePresent(state) {
  return state.avatarFile != null;
}

function hasAvatar(state) {
  return !!state.avatar;
}
