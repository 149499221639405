import { isString } from 'lodash';

import { EnumProp } from '@h4h/classes';

import { InputType, PhoneTypeOrganization, PhoneTypePerson } from '../constants';

export const inputProps = Object.freeze({
  name: String,

  type: new EnumProp({
    type: InputType
  }),

  tabindex: {
    type: Number,
    default: -1
  },

  // used to manage tabIndex via TabIndexManager subscription
  groupId: String,

  /** labels */
  label: {
    // String | String[]
    type: [String, Array],
    validator(value) {
      return isString(value) || (Array.isArray(value) && value.every(isString));
    }
  },

  /** flags */
  booleanFalse: {
    type: Boolean,
    default: false
  },

  booleanTrue: {
    type: Boolean,
    default: true
  },

  /** styles */
  // CSS class name in fact
  style: [String, Array]
});

export const selectProps = {
  value: [Object, String, Number, Boolean, Set],

  options: {
    type: Array,
    required: true
  },

  labelLimit: {
    type: Number,
    default: 1
  },

  minSearchLength: {
    type: Number,
    default: 2
  },

  /**
   * filter
   * (item, query) => boolean
   */
  filter: {
    type: Function,
    required: false
  },

  searchAction: {
    type: Function,
    default: null
  },

  /** labels */
  info: inputProps.label,
  otherLabel: inputProps.label,
  warningMessage: inputProps.label,
  validationMessage: inputProps.label,

  noDataMessage: {
    ...inputProps.label,
    default: 'person.patient.emptyMessageTitle'
  },

  otherGroupLabel: {
    ...inputProps.label,
    default: 'medication.allergyReasonStop.other'
  },

  placeholder: {
    ...inputProps.label,
    default: 'common.select'
  },

  /** flags */
  clearable: inputProps.booleanTrue,

  readonly: inputProps.booleanFalse,
  multiple: inputProps.booleanFalse,
  showSelectAllForGroups: inputProps.booleanFalse,
  groupValues: inputProps.booleanFalse,
  loading: inputProps.booleanFalse,
  disableOptionSort: inputProps.booleanFalse,
  returnObject: inputProps.booleanFalse,
  serverSideFilter: inputProps.booleanFalse,
  clearSearchOnChange: inputProps.booleanFalse,
  required: inputProps.booleanFalse
};

export const phoneProps = {
  validatePhoneNumber: {
    type: Function,
    required: false,
  },

  countryOptions: {
    type: Array,
    default: () => []
  },

  defaultCountryCode: {
    type: String,
    default: ''
  },

  phoneType: {
    type: String,
    default: null
  },

  phoneEnum: {
    type: Object,
    required: true,
    validator(value) {
      return value === PhoneTypeOrganization || value === PhoneTypePerson;
    }
  },

  /** flags */
  inline: inputProps.booleanFalse,
  multiple: inputProps.booleanFalse,
  showDefaultNumberOption: inputProps.booleanFalse
};

export const addressProps = {
  value: {
    type: Object,
    required: false,
  },

  customizations: {
    type: Object,
    required: false,
    default: () => ({})
  },

  countryOptions: {
    type: Array,
    default: () => []
  },

  defaultCountryCode: {
    type: String,
    default: ''
  }
};
