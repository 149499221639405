import { DurationModel } from './durationModel';
import { mapArray } from '../utils/mapUtils';

export class MonitoringPeriodModel {
  /**
   * MonitoringPeriodModel config
   *
   * @param {Object}                                        config
   * @param {DurationModel}                                 config.duration
   * @param {MonitoringPeriodObservationModel[]}            config.observations
   * @param {MonitoringPeriodQuestionnaireModel[]}          config.questionnaires
   * @param {String}                                        config.name
   * @param {String}                                        config.uuid
   * @param {Boolean}                                       config.hasNoDuration
   * @param {Boolean}                                       config.sendEmailOnEnrollment
   * @param {Boolean}                                       config.sendDevicesToPatients
   */
  constructor(config) {
    this.duration = config.duration ? new DurationModel(config.duration) : null;
    this.observations = mapArray(config.observations, MonitoringPeriodObservationModel);
    this.questionnaires = mapArray(config.questionnaires, MonitoringPeriodQuestionnaireModel);
    this.name = config.name;
    this.uuid = config.uuid;
    this.hasNoDuration = config.hasNoDuration;
    this.sendEmailOnEnrollment = config.sendEmailOnEnrollment;
    this.sendDevicesToPatients = config.sendDevicesToPatients;
  }
}

export class ProgramInputGroupModel {
  /**
   * ProgramInputGroupModel config
   *
   * @param {Object}    config
   * @param {String}    config.name
   * @param {String}    config.uuid
   */
  constructor(config) {
    this.name = config.name;
    this.uuid = config.uuid;
  }
}

export class ProgramInputModel {
  /**
   * ProgramInputModel config
   *
   * @param {Object}                        config
   * @param {String}                        config.cron
   * @param {String}                        config.name
   * @param {String}                        config.uuid
   * @param {ProgramInputGroupModel[]}      config.groups
   */
  constructor(config) {
    this.cron = config.cron;
    this.name = config.name;
    this.uuid = config.uuid;
    this.groups = mapArray(config.groups, ProgramInputGroupModel);
  }
}

export class MonitoringPeriodQuestionnaireModel extends ProgramInputModel {
  /**
   * MonitoringPeriodQuestionnaireTemplateModel config
   *
   * @param {Object}                        config
   * @param {String}                        config.cron
   * @param {String}                        config.questionnaireTemplateId
   * @param {String}                        config.name
   * @param {String}                        config.uuid
   * @param {ProgramInputGroupModel[]}      config.groups
   */
  constructor(config) {
    super(config);
    this.questionnaireTemplateId = config.questionnaireTemplateId;
  }
}

export class MonitoringPeriodObservationModel extends ProgramInputModel {
  /**
   * MonitoringPeriodObservationModel config
   *
   * @param {Object}                        config
   * @param {String}                        config.cron
   * @param {String}                        config.observationTemplateId
   * @param {String}                        config.name
   * @param {String}                        config.uuid
   * @param {ProgramInputGroupModel[]}      config.groups
   */
  constructor(config) {
    super(config);
    this.observationTemplateId = config.observationTemplateId;
  }
}

