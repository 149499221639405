import { noop } from 'lodash';

export class DropzoneAction {
  /**
   * @param {Object}    config
   *
   * @param {String}    [config.icon]
   * @param {String}    [config.label]
   * @param {Boolean}   [config.inline]
   * @param {Boolean}   [config.disabled]
   * @param {Function}  [config.clickAction]
   * @param {Function}  [config.dropContentAction]
   * @param {Function}  [config.dropElementAction]
   */
  constructor(config) {
    const { clickAction, dropContentAction, disabled, inline, label, icon, dropElementAction } = config;

    if (!clickAction && !dropContentAction && !dropElementAction) {
      throw new Error(`Invalid DropzoneAction config - ${ JSON.stringify(config) }`);
    }

    this.icon = icon;
    this.label = label;
    this.inline = inline;
    this.disabled = disabled;
    this.clickAction = clickAction || noop;
    this.dropContentAction = dropContentAction || noop;
    this.dropElementAction = dropElementAction || noop;
  }
}
