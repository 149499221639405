import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';
import { AnswerTypeOptions } from '../../../../constants/answerType';
import { without } from 'lodash';

export const mutations = {
  [types.SET_PRISTINE](state, value) {
    state.pristine = value;
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.INIT_POPUP](state, { popupId, terminologyTypes }) {
    state.popupId = popupId;
    state.inputs = getInputsConfig(terminologyTypes);
    state.inputsMap = getInputsMap(state.inputs);
    state.answers = [];
    state.pristine = true;
  },

  [types.ADD_ANSWER](state, answer) {
    state.pristine = false;
    state.answers.push(answer);
  },

  [types.REMOVE_ANSWER](state, answer) {
    state.pristine = false;
    state.answers = without(state.answers, answer);
  },
};

function getInputsConfig(terminologyTypes) {
  const questionTerminologyCode = createInputConfig({
    id: 'questionTerminologyCode',
    type: InputType.Typeahead,
    label: 'questionnaireTemplates.questionTerminologyCode',
    required: true,
    options: createTerminologyInputTypeOptions(terminologyTypes),
    formatter: i => i ? i.label : i
  });

  const answerType = createInputConfig({
    id: 'answerType',
    type: InputType.Select2,
    label: 'questionnaireTemplates.answerType',
    clearable: false,
    required: true,
    options: AnswerTypeOptions
  });

  const min = createInputConfig({
    id: 'min',
    type: InputType.Float,
    label: 'common.min',
    required: false,
  });

  const max = createInputConfig({
    id: 'max',
    type: InputType.Float,
    label: 'common.max',
    required: false,
  });

  const answerTerminologyUnit = createInputConfig({
    id: 'answerTerminologyUnit',
    type: InputType.Typeahead,
    label: 'questionnaireTemplates.answerTerminologyUnit',
    required: false,
    options: createTerminologyInputTypeOptions(terminologyTypes),
    formatter: i => i ? i.label : i
  });

  return [
    questionTerminologyCode,
    answerType,
    min,
    max,
    answerTerminologyUnit
  ];
}

function createTerminologyInputTypeOptions(terminologyTypes) {
  return terminologyTypes.map(type => ({
    label: type.translation,
    value: type.key
  }));
}
