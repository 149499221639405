import { types } from './types';
import { showRestError } from '../../../../utils/errors';
import { currentCallCenterUser } from '../../../../services/api/users/callCenterUsers';
import { SET_LOADING } from '../../../../utils/withLoadind';

export const actions = Object.freeze({
  fetchUser,
});

async function fetchUser({ commit }) {
  commit(SET_LOADING, true);
  const result = await currentCallCenterUser.fetchOne();
  commit(SET_LOADING, false);
  if (!result.success) {
    showRestError(result.error, 'common.cantFetchUserPreferences');
  }
  else {
    commit(types.SET_USER, result.data);
  }
  return result;
}
