import { mapValues } from 'lodash';
import { getInputsMap } from '@h4h/inputs';
import { AnswerType } from '../../../../constants/answerType';
import { QuestionDefinitionModel } from '../../../../model/questionnaireTemplateModel';
import { validateFloatInput } from '@h4h/inputs/src/utils/validations';

export const getters = {
  isValid,
  visibleInputs,
  model,
  isOptionsTableVisible,
  isNumeric,
  minMaxDefinedCheck,
  minMaxSynchronized,
};

function isValid(state) {
  const inputs = visibleInputs(state);
  let result = inputs.length && inputs.every(i => i.valid);
  if (result && isOptionsTableVisible(state)) {
    result = state.answers.length;
  }
  if (result && isNumeric(state)) {
    result = minMaxDefinedCheck(state) && minMaxSynchronized(state);
  }
  return result;
}

function model(state) {
  const definition = new QuestionDefinitionModel(mapValues(getInputsMap(visibleInputs(state)), i => i.value));
  if (definition.questionTerminologyCode) {
    definition.questionTerminologyCode = definition.questionTerminologyCode.value;
  }
  if (definition.answerTerminologyUnit) {
    definition.answerTerminologyUnit = definition.answerTerminologyUnit.value;
  }
  if (isOptionsTableVisible(state)) {
    definition.answersValueSet = state.answers;
  }
  return definition;
}

function isOptionsTableVisible(state) {
  return state.inputsMap &&
    state.inputsMap.answerType &&
    state.inputsMap.answerType.value === AnswerType.CHOICE;
}

function visibleInputs(state) {
  if (state.inputsMap) {
    const answerType = state.inputsMap.answerType;
    let inputs = [
      state.inputsMap.questionTerminologyCode,
      answerType,
    ];
    switch (answerType.value) {
      case AnswerType.FREE_STRING :
        inputs.push(state.inputsMap.answerTerminologyUnit);
        break;
      case AnswerType.INTEGER :
        inputs.push(state.inputsMap.min);
        inputs.push(state.inputsMap.max);
        inputs.push(state.inputsMap.answerTerminologyUnit);
        break;
      case AnswerType.NUMERIC :
        inputs.push(state.inputsMap.min);
        inputs.push(state.inputsMap.max);
        inputs.push(state.inputsMap.answerTerminologyUnit);
        break;
      case AnswerType.BOOLEAN :
        break;
      case AnswerType.CHOICE :
        break;
    }
    return inputs;
  }
  return [];
}

function isNumeric(state) {
  return state.inputsMap && state.inputsMap.answerType &&
    (state.inputsMap.answerType.value === AnswerType.NUMERIC || state.inputsMap.answerType.value === AnswerType.INTEGER);
}

function validMinMaxInputs(state) {
  return validateFloatInput(state.inputsMap.min) && validateFloatInput(state.inputsMap.max);
}

function minMaxDefinedCheck(state) {
  if (validMinMaxInputs(state)) {
    const minValue = state.inputsMap.min.value;
    const maxValue = state.inputsMap.max.value;
    if (minValue === null && maxValue !== null) {
      return false;
    }
    if (minValue !== null && maxValue === null) {
      return false;
    }
    return true;
  }
}

function minMaxSynchronized(state) {
  if (validMinMaxInputs(state)) {
    return state.inputsMap.min.value <= state.inputsMap.max.value;
  }
}
