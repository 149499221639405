import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { durationUnitOptions } from '../../../constants/durationUnit';
import { MonitoringPeriodInputModel } from '../models';
import { HAS_NO_DURATION_INPUT } from '../constants';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.INIT_FORM](state, { program }) {
    state.inputs = [];
    state.availablePeriods = [];
    state.supportedVendors = [];
    state.program = program;
    if (state.program) {
      state.inputs = getInputsConfig(state.program);
      state.availablePeriods = state.program.availablePeriods.map(period => mapToPeriodConfig(period));
      state.supportedVendors = state.program.supportedVendors;
    }
  }
};

/**
 *
 * @param {MonitoringPeriodModel} period
 * @return {MonitoringPeriodInputModel}
 */
function mapToPeriodConfig(period) {
  const hasNoDuration = createInputConfig({
    id: HAS_NO_DURATION_INPUT,
    label: 'programs.hasNoDuration',
    type: InputType.Checkbox,
    required: false,
    default: false,
    disabled: true,
    value: period.hasNoDuration,
  });
  const sendEmailOnEnrollment = createInputConfig({
    id: 'sendEmailOnEnrollment',
    label: 'programs.sendEmailOnEnrollment',
    type: InputType.Checkbox,
    required: false,
    default: false,
    disabled: true,
    value: period.sendEmailOnEnrollment,
  });
  const sendDevicesToPatients = createInputConfig({
    id: 'sendDevicesToPatients',
    type: InputType.Checkbox,
    required: false,
    value: period.sendDevicesToPatients,
    default: false,
    disabled: true,
    label: 'enrollments.giveOptionToSendDeviceToPatient',
  });
  const name = createTextInputConfig(period.name, 'name', 'common.name', InputType.Label);
  const durationValue = createTextInputConfig(period.duration ? period.duration.value : null, 'durationValue', 'programs.durationValue', InputType.Label);
  const durationUnit = createInputConfig({
    value: period.duration ? period.duration.unit : '',
    id: 'durationUnit',
    type: InputType.Select2,
    label: 'programs.durationUnit',
    disabled: true,
    options: durationUnitOptions
  });
  return new MonitoringPeriodInputModel({
    uuid: period.uuid,
    name,
    hasNoDuration,
    sendEmailOnEnrollment,
    sendDevicesToPatients,
    durationValue,
    durationUnit,
    inputs: [hasNoDuration, sendEmailOnEnrollment, sendDevicesToPatients, name, durationValue, durationUnit],
    observations: period.observations,
    questionnaires: period.questionnaires,
  });
}

/**
 *
 * @param {ProgramModel} program
 * @return {Object[]}
 */
function getInputsConfig(program) {
  const name = createTextInputConfig(program.name, 'name', 'common.name', InputType.Label);

  const basePeriodModel = program.availablePeriods.find(p => p.uuid === program.basePeriodUuid);
  const basePeriodName = basePeriodModel && basePeriodModel.name;

  const basePeriod = createTextInputConfig(basePeriodName, 'basePeriod', 'programs.basePeriod', InputType.Label);
  const notUseEhealthApp = createTextInputConfig(program.notUseEhealthApp, 'notUseEhealthApp', 'programs.doesntUseH4Happ', InputType.Checkbox);
  return [
    name,
    basePeriod,
    notUseEhealthApp,
  ];
}

function createTextInputConfig(value, id, label, inputType) {
  return createInputConfig({
    value,
    id,
    type: inputType,
    label,
    disabled: true,
  });
}

