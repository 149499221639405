export const greaterThanField = 'greaterThan';
export const lessThanField = 'lessThan';

export const TriggerConditionType = Object.freeze({
  greaterThan: greaterThanField,
  lessThan: lessThanField,
});

export class TriggerConditionModel {
  /**
   * TriggerConditionModel
   *
   * @param {Object}    config
   * @param {Number}    config.lessThan
   * @param {Number}    config.greaterThan
   */
  constructor(config) {
    this.greaterThan = config.greaterThan;
    this.lessThan = config.lessThan;
  }

  get isEmpty() {
    return this.greaterThan === null && this.lessThan === null;
  }

  /**
   *
   * @returns {TriggerConditionType}
   */
  get conditionType() {
    return this.lessThan === null ? TriggerConditionType.greaterThan : TriggerConditionType.lessThan;
  }

}
