import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { get } from 'lodash';
import { parseCronExpression } from '../../../../../components/cronEditor/store/helpers';
import { getCronFrequencyTypes } from '../../../../../components/cronEditor/store/types';
import { createCronInput } from '../../../../../components/cronEditor/store/cronInput';
import { setCronModelMutation } from '../../../../../components/cronEditor/store/mutations';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_OBSERVATION_TEMPLATES](state, observationTemplates) {
    state.observationTemplates = observationTemplates;
  },

  [types.SET_QUESTIONNAIRE_TEMPLATES](state, questionnaireTemplates) {
    state.questionnaireTemplates = questionnaireTemplates;
  },

  [types.SET_ENROLLMENT](state, enrollment) {
    state.enrollment = enrollment;
    state.observations = getObservations(enrollment);
    state.observationTemplates = [];
    state.questionnaires = getQuestionnaires(enrollment);
    state.questionnaireTemplates = [];
  },

  [types.SET_PATIENT](state, patient) {
    state.patient = patient;
  },

  [types.SET_PAGE_INITIALIZED](state, value) {
    state.pageInitialized = value;
  },

  [types.INIT_PAGE](state) {
    if (!state.enrollment) {
      state.inputs = [];
      return;
    }
    state.inputs = createInputs(state.enrollment);
  },
  [types.SET_TREATMENT_ADVICE_POPUP_ID](state, { popupId }) {
    state.treatmentAdvicePopupId = popupId;
  },
  [types.SET_TREATMENT_ADVICE](state, { treatmentAdviceText, hasFeedback }) {
    state.treatmentAdviceText = treatmentAdviceText;
    state.hasFeedback = hasFeedback;
  },
  [types.INIT_TREATMENT_ADVICE_AGREED_POPUP](state, { popupId }) {
    state.treatmentAdviceAgreedPopupId = popupId;
    state.treatmentAdviceAgreedComment = createInputConfig({
      id: 'treatmentAdviceAgreedComment',
      type: InputType.Textarea,
      required: true,
      value: null,
      label: 'common.comment',
    });
  },
  [types.INIT_POPUP](state, { popupId, programInput }) {
    state.editSchedule.popupId = popupId;
    state.editSchedule.programInput = programInput;
    // setup the cron editor settings
    const cronExpressionToParse = programInput.overriddenCron || programInput.templateLink.cron;
    const cronIsEditable = parseCronExpression(cronExpressionToParse).type !== getCronFrequencyTypes(true).Advanced;
    state.editSchedule.cronModel = createCronInput(cronIsEditable ? cronExpressionToParse : '');
  },
  [types.SET_CRON_MODEL]: setCronModelMutation,
};

/**
 *
 * @param {EnrollmentModel} enrollment
 */
function createInputs(enrollment) {
  const periodName = createInputConfig({
    id: 'periodName',
    type: InputType.Label,
    value: get(enrollment, 'monitoringPeriod.templateLink.name'),
    label: 'enrollments.monitoringPeriod',
  });

  return [periodName];
}

function getObservations(enrollment) {
  if (!enrollment || !enrollment.monitoringPeriod) {
    return [];
  }
  const period = enrollment.program.availablePeriods.find(period => period.uuid === enrollment.monitoringPeriod.templateLink.uuid);
  if (!period) {
    return [];
  }
  return period.observations;
}

function getQuestionnaires(enrollment) {
  if (!enrollment || !enrollment.monitoringPeriod) {
    return [];
  }
  const period = enrollment.program.availablePeriods.find(period => period.uuid === enrollment.monitoringPeriod.templateLink.uuid);
  if (!period) {
    return [];
  }
  return period.questionnaires;
}
