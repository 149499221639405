import { AddressKind } from '../constants';

export class AddressModel {
  /**
   * Address config
   *
   * @param {Object}        config
   * @param {String}        config.id
   * @param {String}        config.addressType
   * @param {String}        config.city
   * @param {Country}       config.country
   * @param {String}        config.number
   * @param {String}        config.numberExtension
   * @param {String}        config.postalCode
   * @param {String}        config.street
   * @param {AddressKind}   config.addressKind
   */
  constructor(config) {
    this.id = config.id;
    this.addressKind = AddressKind.Regular;
    this.addressType = config.addressType;
    this.city = config.city;
    this.country = config.country;
    this.number = config.number;
    this.numberExtension = config.numberExtension;
    this.postalCode = config.postalCode;
    this.street = config.street;
  }

  isEmpty() {
    return ['id', 'city', 'country', 'number', 'numberExtension', 'postalCode', 'street'].every(field => !this[field]);
  }
}
