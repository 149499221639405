import { isNumber, isString } from 'lodash';

/**
 * Class representing a float point Number
 * @extends Number
 */
export class Float extends Number {
  /**
     * Convert stringValue to Number and return instance of Float class
     * @param {String} stringValue - The string from API
     * @returns {Float|NaN|null} - instance of Float or null
     */
  static fromApi(stringValue) {
    if (!stringValue || !isString(stringValue)) {
      return null;
    }

    const value = +stringValue;

    if (isNaN(value)) {
      return value;
    }

    return new Float(value);
  }

  /**
     * Convert Number to string to be sent back to API
     * @param {Number} value - Float or Number value or primitive number
     * @returns {String|null} - number converted to string or null
     */
  static toApi(value) {
    if (!isNumber(value) || !Number.isFinite(+value)) {
      return null;
    }

    return value + '';
  }
}
