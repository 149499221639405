import { types } from './types';

export const mutations = {
  [types.SET_ANAMNESIS_TEMPLATE_ID](state, id) {
    state.anamnesisTemplateId = id;
  },
  [types.SET_ANAMNESIS_TEMPLATE_INFO_LOADED](state, value) {
    state.anamnesisTemplateInfoLoaded = value;
  },
};

