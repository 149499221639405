import { SpecialPath } from '../specialPath';
import { forceAuth, loginKeyName, setTenant } from '../../auth/auth';
import { Page } from '../../../constants/page';
import { EhRole } from '../../auth/ehRole';

export async function tenantRedirect(to, from, next) {
  const params = to.params;
  const tenantId = params.tenantId;
  const specialPathModel = Object.values(SpecialPath).find(m => m.path === tenantId);
  if (specialPathModel) {
    next({ name: specialPathModel.page });
    return;
  }

  setTenant(tenantId);

  const auth = forceAuth();
  try {
    await auth.login();
  }
  catch (error) {
    next(Page.Root);
    await auth.logout();
    return;
  }

  if (auth.keycloak.authenticated) {
    // this way we wan't prevent next situation:
    // - let's assume user is authenticated (let's assume this as account1), but goes to the ui root.
    // - ui redirects user to the ingress components
    // - user enters different (but also registered) email. Let it be account2.
    // - system accepts account2 and redirects user to the application.
    // - since user is still authenticated as account1, then no need to show login page and user proceeds to application (as account1)
    // => user is confused since he/she entered account2 but now is authenticated as account1.
    // so application performs logout in this situation.
    const username = auth.keycloak.tokenParsed.email;
    const usernameFromIngress = window.localStorage.getItem(loginKeyName);
    window.localStorage.removeItem(loginKeyName);
    if (usernameFromIngress && username !== usernameFromIngress) {
      await auth.logout();
    }
  }

  if (to.name !== Page.Tenant) {
    next();
    return;
  }

  const isAdmin = auth.hasRole(EhRole.Admin);
  const isPractitioner = auth.hasRole(EhRole.Physician) || auth.hasRole(EhRole.Poh);
  const isPatient = auth.hasRole(EhRole.Patient);
  const isCallCenterSupport = auth.hasRole(EhRole.CallCenterSupport);
  const isCallCenterAdmin = auth.hasRole(EhRole.CallCenterAdmin);
  const isLogisticUser = auth.hasRole(EhRole.LogisticsSupport);

  const ccRoleCount = (isCallCenterSupport ? 1 : 0) + (isCallCenterAdmin ? 1 : 0) + (isLogisticUser ? 1 : 0);
  if (ccRoleCount > 1) {
    next({ name: Page.ChooseCcRole, params });
  }
  else if (isAdmin && isPractitioner) {
    next({ name: Page.ChooseRole, params });
  }
  else if (isPractitioner) {
    next({ name: Page.Practitioner, params });
  }
  else if (isAdmin) {
    next({ name: Page.Admin, params });
  }
  else if (isPatient) {
    next({ name: Page.PatientLoginSuccess, params });
  }
  else if (isCallCenterSupport) {
    next({ name: Page.CallCenterPatientsList, params });
  }
  else if (isCallCenterAdmin) {
    next({ name: Page.CallCenterUsersList, params });
  }
  else if (isLogisticUser) {
    next({ name: Page.CallCenterLogisticUser, params });
  }
  else {
    next({ name: Page.Forbidden, params });
  }
}
