export const stopEnrollmentReasons = {
  consistent_arrhythmia_and_treated_optimally: 'Aritmie constant en optimaal behandeld',
  arrhythmia_can_not_be_determined: 'Aritmie kan niet worden vastgelegd',
  arrhythmia_is_cured: 'Geen aritmie meer',
  heart_failure_is_optimally_treated: 'Hartfalen optimaal ingesteld',
  heart_failure_is_cured: 'Geen hartfalen meer',
  hypertension_is_determined_diagnosed: 'Hypertensie geaccepteerd',
  hypertension_is_cured: 'Geen hypertensie meer',
  practitioner_no_added_value: 'Behandelaar: geen toegevoegd waarde',
  patient_no_added_value: 'Patiënt: geen toegevoegde waarde',
  patient_is_unsatisfied_with_ehealth_service: 'Patient is ontevreden over service van de remote monitoring',
  patient_is_unsatisfied_with_ehealth_technology: 'Patiënt is ontevreden over de techniek van de remote monitoring',
  ehealth_is_too_difficult_for_the_patient: 'Patiënt vindt remote monitoring te moeilijk voor gebruik',
  patient_thinks_it_is_too_expensive: 'Patiënt vindt het zonde van het eigen risico',
  patient_does_not_want_to_pay_for_ehealth: 'Patiënt wil niet zelf betalen voor de remote monitoring',
  patient_never_started_code_is_never_activated: 'Patiënt is nooit gestart / code niet geactiveerd',
  patient_moved_to_a_different_hospital: 'Verandering zorgaanbieder',
  patient_changed_their_health_insurance: 'Verandering zorgverzekeraar',
  patient_is_inactive: 'Patiënt is inactief',
};
