import { types } from './types';
import { getInputsMap } from '@h4h/inputs';
import { mapValues } from 'lodash';
import { ProgramCreationModel } from '../../../model/programModel';
import { programs } from '../../../services/api/programs/programs';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import { Page } from '../../../constants/page';
import { programsActions } from '../constants';
import { router } from '../../../services/router';
import { showRestError } from '../../../utils/errors';
import { InitPopupActionFullPath } from '../vendorPopup/constants';

const DoAddSupportedVendor = 'doAddSupportedVendor';
export const actions = Object.freeze({
  initForm,
  addPeriod,
  removePeriod,
  addObservation,
  removeObservation,
  addQuestionnaire,
  removeQuestionnaire,
  save,
  makePeriodPrimary,
  [DoAddSupportedVendor]: doAddSupportedVendor,
  removeSupportedVendor,
  addNewProgramInputGroup,
  linkProgramInputGroup,
  unlinkProgramInputGroup,
  upsertThreshold,
});

async function initForm({ commit }, treatmentAdviceEnabled) {
  commit(types.INIT_FORM, treatmentAdviceEnabled);
}

async function addPeriod({ commit }) {
  commit(types.ADD_PERIOD);
}

async function removePeriod({ commit }, period) {
  commit(types.REMOVE_PERIOD, period);
}

async function addObservation({ commit }, { period, observation }) {
  commit(types.ADD_OBSERVATION, { period, observation });
}

async function removeObservation({ commit }, { period, observation }) {
  commit(types.REMOVE_OBSERVATION, { period, observation });
}

async function addQuestionnaire({ commit }, { period, questionnaire }) {
  commit(types.ADD_QUESTIONNAIRE, { period, questionnaire });
}

async function removeQuestionnaire({ commit }, { period, questionnaire }) {
  commit(types.REMOVE_QUESTIONNAIRE, { period, questionnaire });
}

async function doAddSupportedVendor({ dispatch, commit, state }) {
  const { closed } = await dispatch(InitPopupActionFullPath, state.supportedVendors, { root: true });
  const vendor = await closed;
  if (vendor.success && vendor.data) {
    commit(types.ADD_SUPPORTED_VENDOR, vendor.data);
  }
}

async function removeSupportedVendor({ commit }, programVendorModel) {
  commit(types.REMOVE_SUPPORTED_VENDOR, programVendorModel);
}

async function makePeriodPrimary({ commit }, period) {
  commit(types.SET_PRIMARY_PERIOD, period);
}

async function save({ state, dispatch, commit, getters }) {
  if (!state.availablePeriods.length) {
    toast.error(localize('programs.pleaseAddAtLeastOnePeriod'));
  }
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const program = new ProgramCreationModel({
    ...mapValues(getInputsMap(state.inputs), i => i.value),
    availablePeriods: state.availablePeriods.map(period => period.toModel()),
    basePeriodName: state.primaryPeriod.name.value,
    supportedVendors: state.supportedVendors,
    observationThresholds: state.observationThresholds,
  });

  commit(types.SET_LOADING, true);
  const result = await programs.create(program);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.programSaved'));
    dispatch(programsActions.ReloadPrograms, {}, { root: true });
    router.push({ name: Page.AdminPrograms });
  }
  else {
    showRestError(result.error, 'messages.cantCreateProgram');
  }
  return result.success;
}

async function addNewProgramInputGroup({ commit }, { group, groups, programInput }) {
  commit(types.ADD_NEW_PROGRAM_INPUT_GROUP, { group, groups, programInput });
}

async function linkProgramInputGroup({ commit }, { group, programInput }) {
  commit(types.LINK_INPUT_GROUP, { group, programInput });
}

async function unlinkProgramInputGroup({ commit }, { group, programInput }) {
  commit(types.UNLINK_INPUT_GROUP, { group, programInput });
}

async function upsertThreshold({ commit }, threshold) {
  commit(types.UPSERT_THRESHOLD, threshold);
}

