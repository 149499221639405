import { get, trim, groupBy, isFunction } from 'lodash';

export function mapOption(option, labelSource = 'title') {
  if (isFunction(labelSource)) {
    return {
      label: labelSource(option),
      value: option
    };
  }
  return {
    label: get(option, labelSource),
    value: option
  };
}

export function getEnumOptions(_enum, labelPath) {
  if (labelPath) {
    labelPath += '.';
  }
  else {
    labelPath = '';
  }

  return Object.values(_enum).map(value => ({
    label: labelPath + value,
    value
  }));
}

export function getGroupedOptions(options, key = 'title') {
  const grouped = groupBy(options, option => trim(get(option, key)));

  return Object.keys(grouped).map(key => {
    return {
      label: key,
      group: grouped[key],
      value: key
    };
  });
}

export function findGroupedOption(options, value) {
  return options.find(o => o.value === trim(value));
}

export function getValueFromGroupedOption(input, path = 'id') {
  let value;
  if (input.value instanceof Set) {
    value = Array.from(input.value || []);
  }
  else {
    value = [input.value];
  }
  const filteredValues = value.filter(v => v);
  const selectedOptions = input?.options?.filter(o => filteredValues.includes(o.value)) || [];
  return selectedOptions.map(({ group }) => group).flat().map(o => path ? get(o, path) : o).filter(v => v);
}
