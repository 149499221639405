import { get } from 'lodash';
import { createInputConfig, InputType } from '@h4h/inputs';
import { EhAddressCustomizations } from './ehAddressCustomizations';

export function extractAddressReferenceInputs(addressRef) {
  const inputsMap = get(addressRef, '$refs.input.inputs') || {};
  return Object.values(inputsMap);
}

export function createAddressInputConfig(value = null, countryOptions, id, label, required, additions = null) {
  return createInputConfig({
    id,
    type: InputType.Address,
    value,
    required,
    label,
    countryOptions,
    customizations: EhAddressCustomizations,
    ...additions,
  });
}

/**
 *
 * @param {AddressModel} address1
 * @param {AddressModel} address2
 */
export function compareAddresses(address1, address2) {
  if (address1 === address2) {
    return true;
  }
  if (!address1 && !address2) { // in case compare null and undefined
    return true;
  }
  if (!address1 || !address2) { // in case compare null and undefined
    return false;
  }

  return (address1.addressType === address2.addressType) &&
    (address1.postalCode === address2.postalCode) &&
    (address1.number === address2.number) &&
    (address1.numberExtension === address2.numberExtension) &&
    (address1.street === address2.street) &&
    (address1.country?.code2 === address2.country?.code2);
}

