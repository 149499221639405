export class ColumnTooltip {
  /**
   * @param {Object}    config
   * @param {String}    config.icon
   * @param {String}    config.message
   * @param {String}    config.maxWidth
   */
  constructor(config) {
    const { icon, message, maxWidth } = config;

    this.icon = icon ? icon : 'info';
    this.message = message;
    this.maxWidth = maxWidth || 300;
  }
}
