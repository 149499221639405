import { types } from './types';
import { showRestError } from '../../../../../utils/errors';
import { questionnaireTemplates } from '../../../../../services/api/questionnaire/questionnaireTemplates';
import { observationDateField } from '../../../../../model/observation/observationModel';
import { patientQuestionnairePage } from '../../../../../services/api/questionnaire/questionnaire';
import { Page } from '../../../../../constants/page';
import { router } from '../../../../../services/router';

export const actions = Object.freeze({
  init,
  reload,
  initContainer,
  navigateToFirstRecord,
});

async function reload({ commit, state }) {
  if (!state.questionnaireTemplate) {
    commit(types.SET_QUESTIONNAIRES, []);
  }
  const patientUuid = state.patient.uuid;
  const params = {
    size: 999999,
    t: state.questionnaireTemplate.uuid,
    sort: observationDateField + ',desc',
  };

  commit(types.SET_QUESTIONNAIRES_LOADING, true);
  const result = await patientQuestionnairePage(patientUuid).fetchOne({ params });
  commit(types.SET_QUESTIONNAIRES_LOADING, false);

  if (result.success) {
    commit(types.SET_QUESTIONNAIRES, result.data.content);
  }
  else {
    commit(types.SET_QUESTIONNAIRES, []);
    showRestError(result.error, 'messages.cantFetchQuestionnaire');
  }
  return result.success;
}

async function initContainer({ state, commit }, { questionnaireTemplateUuid }) {
  // prevent redundand reload in case of navigating to same questionnaire from different enrollment
  if (state.questionnaireTemplate) {
    if (state.questionnaireTemplate.uuid === questionnaireTemplateUuid) {
      return;
    }
  }

  commit(types.SET_QUESTIONNAIRE_TEMPLATE_LOADING, true);
  const questionnaireTemplate = await questionnaireTemplates.get(questionnaireTemplateUuid);
  commit(types.SET_QUESTIONNAIRE_TEMPLATE_LOADING, false);
  if (questionnaireTemplate.success) {
    commit(types.SET_QUESTIONNAIRE_TEMPLATE, questionnaireTemplate.data);
  }
  else {
    showRestError(questionnaireTemplate.error, 'messages.cantFetchQuestionnaireTemplate');
  }
}

function navigateToFirstRecord({ state }) {
  return doNavigateToFirstRecord(state);
}

function doNavigateToFirstRecord(state) {
  if (state.questionnaires && state.questionnaires.length) {
    router.push({
      name: Page.PractitionerPatientEnrollmentsViewQuestionnaireEdit,
      params: {
        questionnaireUuid: state.questionnaires[0].uuid,
      }
    });
    return true;
  }
}

async function init({ commit, state }, { patient, routeName, monitoringPeriodActive }) {
  commit(types.INIT_PAGE, { patient });
  await reload({ commit, state });
  if (routeName === Page.PractitionerPatientEnrollmentsViewQuestionnaire) {
    if (doNavigateToFirstRecord(state)) {
      return;
    }
  }
  if (monitoringPeriodActive) {
    router.push({ name: Page.PractitionerPatientEnrollmentsViewQuestionnaireAdd });
  }
}

