import { PopupService } from '../classes';

import { PopupName } from '../constants';
import Modal from '../components/modal/Modal';
import TabbedPopup from '../components/TabbedPopup';
import LoadingPopup from '../components/LoadingPopup';

export const popups = new PopupService({
  [PopupName.Modal]: Modal,
  [PopupName.TabbedPopup]: TabbedPopup,
  [PopupName.LoadingPopup]: LoadingPopup
});
