import { types } from './types';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import { showRestError } from '../../../utils/errors';
import { labResultGroups } from '../../../services/api/labResults/labResultGroups';

export const actions = Object.freeze({
  initForm,
  save,
  addObservationTemplate,
  removeTemplate,
  fetch,
});

async function initForm({ commit }, labResultGroup) {
  commit(types.INIT_FORM, labResultGroup);
}

async function fetch({ state, commit }, uuid) {
  commit(types.SET_LOADING, true);
  const result = await labResultGroups.get(uuid);
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_LAB_RESULT_GROUP, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchLabResultGroup');
  }
  return state.labResultGroup;
}

async function removeTemplate({ commit }, template) {
  commit(types.REMOVE_TEMPLATE, template);
}

async function addObservationTemplate({ commit }, template) {
  commit(types.ADD_OBSERVATION_TEMPLATE, template);
}

async function save({ state, commit }) {
  commit(types.SET_LOADING, true);
  const result = await labResultGroups.updateOne(state.labResultGroup);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.labResultGroupSaved'));
  }
  else {
    showRestError(result.error, 'messages.cantUpdateLabResultGroup');
  }
  return result.success;
}
