<template>
  <div>
    <TextArea
      v-if="!inputConfig.hidden"
      v-bind="inputConfig"
      :class="inputStyles"
      @change="onChange"
    />
  </div>
</template>

<script>
  import TextArea from '../TextArea';
  import styles from './otherOption.scss';
  import { InputType } from '../../constants/inputType';

  export default {
    name: 'H4hOtherOption',

    components: {
      TextArea
    },

    props: {
      inputConfig: {
        type: Object,
        required: true
      },
      action: Function,
      parentType: InputType
    },

    data() {
      return {
        styles
      };
    },

    computed: {
      inputStyles() {
        switch (this.parentType) {
          case InputType.Radio:
            return styles.textAreaForRadio;
          case InputType.Select:
            return styles.textAreaForSelect;
          default:
            return null;
        }
      }
    },

    methods: {
      onChange(value) {
        this.$emit('change-other-option-input', value);
      }
    }
  };
</script>
