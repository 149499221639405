import { types } from './types';
import {
  healthRecordConclusion
} from '../../../../../../../services/api/health-record-conclusion/healthRecordConclusion';
import { findChildByKey } from '../../../../../../../terminology/terminologyHelper';
import observationTypesKardia from '../../../../../../../terminology/concepts/vendor/alivecor/kardia/alivecore-kardia.json';
import { showRestError } from '../../../../../../../utils/errors';
import { observationComments } from '../../../../../../../services/api/observations/observationComments';
import { toast } from '../../../../../../../services/toast';
import { localize } from '../../../../../../../services/localize';
import { mapValues } from 'lodash';
import { getInputsMap } from '@h4h/inputs';
import { observations } from '../../../../../../../services/api/observations/observations';
import { observationFiles } from '../../../../../../../services/api/observations/observationFiles';

export const actions = Object.freeze({
  initPage,
  loadConclusions,
  canSaveEcg,
  saveEcg,
  loadEcgPdf,
});

async function loadEcgPdf({ commit }, ecg) {
  commit(types.SET_ECG_PDF_LOADING, true);
  commit(types.SET_PDF, null);
  if (ecg.documentReference) {
    const result = await observationFiles.get(ecg.documentReference);
    if (result.success) {
      commit(types.SET_PDF, result.data);
    }
    else {
      showRestError(result.error, 'messages.cantFetchEcgData');
    }
  }
  commit(types.SET_ECG_PDF_LOADING, false);
}

async function saveEcg({ commit, state, getters }, { taskHandler }) {
  let result = false;
  commit(types.SET_PRISTINE, { inputs: getters.inputs, value: false });
  if (!getters.valid) {
    return;
  }
  commit(types.SET_SAVING, true);

  // update or create the comment if there's any value to the field
  let commentResult = null;
  const commentText = state.commentInput.value;
  if (commentText) {
    // update the existing comment
    if (state.commentEntity) {
      commentResult = await observationComments(state.ecg.uuid).update(
        state.commentEntity.uuid,
        { value: commentText }
      );
    }
    // create a new comment
    else {
      commentResult = await observationComments(state.ecg.uuid).create({
        value: commentText
      });
    }

    if (commentResult.success) {
      toast.success(localize('messages.observationCommentSaved'));
      commit(types.SET_COMMENT, commentResult.data);
    }
    else {
      showRestError(commentResult.error, 'messages.cantSaveObservationComment');
    }
  }

  // patch the observation data
  const payload = mapValues(getInputsMap(getters.ecgInputs), i => i.value);
  payload.conclusions = Array.from(payload.conclusions)
    .map(conclusionName => state.conclusions.find(c => c.name === conclusionName))
    .filter(x => !!x);

  const observationResult = await observations.patch(state.ecg.uuid, payload);
  if (observationResult.success) {
    toast.success(localize('messages.observationSaved'));
    if (taskHandler) {
      await taskHandler();
    }
    result = true;
  }
  else {
    showRestError(observationResult.error, 'messages.cantUpdateObservation');
  }

  commit(types.SET_SAVING, false);
  return result;
}

async function canSaveEcg({ commit, getters }) {
  commit(types.SET_PRISTINE, { inputs: getters.inputs, value: false });
  return getters.valid;
}

function initPage({ commit }, { commentEntity, ecg }) {
  commit(types.INIT_PAGE, { commentEntity, ecg });
}

async function loadConclusions({ commit }) {
  commit(types.SET_CONCLUSIONS, []);
  const result = await healthRecordConclusion(
    findChildByKey(observationTypesKardia, 'kardia.observation.ecg').key
  ).fetch();

  if (result.success) {
    const conclusions = result.data.sort((c1, c2) => c1.orderOfFollowing - c2.orderOfFollowing);
    commit(types.SET_CONCLUSIONS, conclusions);
  }
  else {
    showRestError(result.error, 'messages.cantFetchConclusions');
  }

  return result.success;
}

