import { types } from './types';
import { anamnesisSetting } from '../../../services/api/anamnesis/anamnesisSettings';
import { showRestError } from '../../../utils/errors';
import { AnamnesisSettings } from '../../../model/anamnesis/anamnesisSettings';
import { popups } from '../../../services/popups';

export const actions = Object.freeze({
  initForm,
  reload,
  remove,
  setAnamnesisTemplateId,
});

async function reload({ commit }) {
  await doReload(commit);
}

async function remove({ commit }) {
  const { confirmed } = await popups.confirm({
    message: 'anamnesis.pleaseConfirmRemoveAnamnesisTemplate',
  });
  if (confirmed) {
    return await update(commit, null);
  }
}

async function setAnamnesisTemplateId({ commit }, id) {
  return await update(commit, id);
}

async function initForm({ commit }) {
  await doReload(commit);
}

async function update(commit, id) {
  commit(types.SET_LOADING, true);

  const anamnesisSettings = new AnamnesisSettings({
    questionnaireTemplateId: id,
  });
  const result = await anamnesisSetting.updateOne(anamnesisSettings);

  commit(types.SET_LOADING, false);

  if (result.success) {
    await doReload(commit);
  }
  else {
    showRestError(result.error, 'messages.cantUpdateAnamnesis');
  }
  return result.success;
}

async function doReload(commit) {
  commit(types.SET_ANAMNESIS_TEMPLATE_ID, null);
  commit(types.SET_LOADING, true);

  const result = await anamnesisSetting.fetchOne();

  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_ANAMNESIS_TEMPLATE_ID, result.data.questionnaireTemplateId);
  }
  else {
    showRestError(result.error, 'messages.cantFetchAnamnesisSettings');
  }
}
