import { mapArray } from '../../utils/mapUtils';
import { ObservationThresholdSeverityConditionsModel } from './observationThresholdSeverityConditionsModel';
import { ObservationInputType } from '../../constants/observationInputType';

export class ObservationThresholdModel {
  /**
   * ObservationThresholdSeverityConditionsModel
   *
   * @param {Object}                                          config
   * @param {String}                                          config.uuid
   * @param {String}                                          config.observationTemplateUuid
   * @param {ObservationThresholdSeverityConditionsModel[]}   config.severityConditionPerDefinition
   */
  constructor(config) {
    this.uuid = config.uuid;
    this.observationTemplateUuid = config.observationTemplateUuid;
    this.severityConditionPerDefinition = mapArray(config.severityConditionPerDefinition, ObservationThresholdSeverityConditionsModel);
  }

  /**
   *
   * @param { ObservationTemplateModel } observationTemplate
   */
  createMissedConditions(observationTemplate) {
    const numericDefinitions = observationTemplate.definitions
      .filter(d => d.inputType === ObservationInputType.NUMERIC || d.inputType === ObservationInputType.INT);
    numericDefinitions.forEach(definition => {
      const hasDefinition = this.severityConditionPerDefinition.some(condition => condition.type === definition.type);
      if (!hasDefinition) {
        const model = new ObservationThresholdSeverityConditionsModel({
          inputType: definition.inputType,
          type: definition.type,
        });
        model.createDefaultSeverityConditionsList();
        this.severityConditionPerDefinition.push(model);
      }
    });
  }

  /**
   *
   * @param { ObservationTemplateModel } observationTemplate
   */
  resetThresholds(observationTemplate) {
    this.severityConditionPerDefinition = [];
    this.createMissedConditions(observationTemplate);
  }

  /**
   *
   * @param {string} type
   */
  findConditionByUnitType(type) {
    return this.severityConditionPerDefinition.find(sc => sc.type === type);
  }

  get isEmpty() {
    return this.severityConditionPerDefinition.every(condition => condition.isEmpty);
  }
}
