<template>
  <div>
    <LabelGroup
      v-if="$props.label"
      :class="[styles.labelMargin]"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="styles.title"
    />
    <div :class="styles.inlineInputs">
      <NumberInput
        v-bind="inputs.dayInput"
        name="day"
        :class="[styles.inputWidth, styles.flex1, styles.marginRight12]"
        :value="prependWithZero(value.day)"
        :disabled="$props.disabled"
        :pristine="inputs.dayInput.pristine && pristine"
        :valid="inputs.dayInput.valid && !!validDateRange"
        @change="handleChange({ input: inputs.dayInput, value: parseInt($event) })"
      />
      <NumberInput
        v-bind="inputs.monthInput"
        name="month"
        :class="[styles.inputWidth, styles.flex1, styles.marginRight12]"
        :value="prependWithZero(value.month)"
        :disabled="$props.disabled"
        :pristine="inputs.monthInput.pristine && pristine"
        :valid="inputs.monthInput.valid && (!!inputs.monthInput.value || !inputs.dayInput.value) && !!validDateRange"
        @change="handleChange({ input: inputs.monthInput, value: parseInt($event) })"
      />
      <NumberInput
        v-bind="inputs.yearInput"
        name="year"
        :class="[styles.inputWidth, styles.flex1]"
        :value="value.year"
        :disabled="$props.disabled"
        :pristine="inputs.yearInput.pristine && pristine"
        :valid="inputs.yearInput.valid && (!!inputs.yearInput.value || (!inputs.monthInput.value && !inputs.dayInput.value)) && !!validDateRange"
        @change="handleChange({ input: inputs.yearInput, value: parseInt($event) })"
      />
      <Button
        v-if="showTodayButton"
        outlined
        primary
        :class="styles.button"
        :label="'diagnose.useToday'"
        data-testid="button-today"
        @click="setDateByAction(true)"
      />
      <Button
        v-if="clearable"
        outlined
        primary
        :class="styles.button"
        :label="'diagnose.clearFields'"
        data-testid="button-clear"
        @click="setDateByAction(false)"
      />
    </div>
  </div>
</template>

<script>
  import NumberInput from '../Text';
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';
  import {
    createInputConfig,
    inputProps,
    updateInput,
    setInputValue
  } from '../../utils';

  import { InputType } from '../../constants';

  import { InputMixin } from '../../mixins';

  import { DatePartialModel } from '../../models/datePartialModel';

  import LabelGroup from '../labelGroup/LabelGroup';

  import styles from './datePartial.scss';
  import moment from 'moment';
  export default {
    name: 'H4hDatePartial',

    components: {
      NumberInput,
      LabelGroup
    },

    mixins: [
      InputMixin
    ],

    props: {
      value: {
        type: Object,
        required: true
      },

      /** flags */
      requiredYear: inputProps.booleanFalse,
      required: inputProps.booleanFalse,
      clearable: inputProps.booleanFalse,
      showTodayButton: inputProps.booleanFalse,
      validDateRange: inputProps.booleanTrue // when DatePartial is used twice in the same form and one date needs to be bigger than the other date
    },

    data: function() {
      const dayInput = createInputConfig({
        id: 'day',
        type: InputType.Integer,
        value: this.$props.value?.day ? this.$props.value.day : null,
        placeholder: 'dd',
        label: 'common.day',
        required: this.$props.required,
        min: 1,
        max: this.$props.value?.month
          ? moment(`${ this.$props.value?.year }-${ this.$props.value.month }`, 'YYYY-MM').daysInMonth()
          : 31
      });

      const monthInput = createInputConfig({
        id: 'month',
        type: InputType.Integer,
        value: this.$props.value?.month ? this.$props.value.month : null,
        placeholder: 'mm',
        label: 'common.month',
        required: this.$props.required,
        min: 1,
        max: 12
      });

      const yearInput = createInputConfig({
        id: 'year',
        type: InputType.Integer,
        value: this.$props.value?.year ? this.$props.value.year : null,
        placeholder: 'yyyy',
        required: this.$props.required || this.$props.requiredYear,
        label: 'common.year',
        min: 999,
        max: 9999
      });

      return {
        styles,
        inputStyles,
        inputs: {
          dayInput,
          monthInput,
          yearInput
        }
      };
    },

    methods: {
      prependWithZero(value) {
        // it applies to numbers that are smaller than 10 and it's for day and month inputs
        return (0 < value && value < 10)
          ? '0' + value
          : value;
      },

      setDateByAction(isToday) {
        const today = isToday ? new Date() : null;

        const initialDate = {
          year: today?.getFullYear() || null,
          month: (today?.getMonth() + 1) || null,
          day: today?.getDate() || null,
        };

        setInputValue({ input: this.inputs.dayInput, value: initialDate.day || null });
        setInputValue({ input: this.inputs.monthInput, value: initialDate.month || null });
        setInputValue({ input: this.inputs.yearInput, value: initialDate.year || null });

        this.$emit('change', new DatePartialModel(initialDate));
      },

      handleChange({ input, value }) {
        if (isNaN(value) || value === 0) {
          value = null;
        }

        setInputValue({ input, value });

        if (input.id === 'month') {
          updateInput({
            input: this.inputs.dayInput,
            patch: { max: moment(`${ this.inputs.yearInput.value }-${ value }`, 'YYYY-MM').daysInMonth() }
          });
        }

        if (input.id === 'year') {
          updateInput({
            input: this.inputs.dayInput,
            patch: { max: moment(`${ value }-${ this.inputs.monthInput.value }`, 'YYYY-MM').daysInMonth() }
          });
        }

        const initialDate = {
          ...this.$props.value,
          [input.id]: value
        };

        this.$emit('change', new DatePartialModel(initialDate));
      },
    }
  };
</script>