import { types } from './types';
import { showRestError } from '../../../../../../../../utils/errors';
import moment from 'moment';
import { ecgKey } from '../../../../../../../../model/observation/ecg';
import {
  optimizedPatientObservations,
  patientObservations
} from '../../../../../../../../services/api/observations/observations';
import { observationDateField } from '../../../../../../../../model/observation/observationModel';
import { ISO_FULL_DATE_FORMAT } from '../../../../../../../../constants/dateConstants';
import { loadDependedTasks } from '../../../observationTaskHelper';
import { mapDataTablePageConfigToSpringPageConfig } from '../../../../../../../../model/page';
import { OptimizedPageRequestParams } from '../../../../../../../../model/observation/optimizedObservationsPage';

export const actions = Object.freeze({
  initEcgObservationsList,
  navigateToEcg,
  reloadEcgObservationsListTasks,
  reload,
  clean,
  reloadEcgObservationsList,
  cleanPageInfo,
});

/**
 *
 * @param commit
 * @param state
 * @param dispatch
 * @param {PatientModel}    patient
 * @param {EnrollmentModel} enrollment
 * @param {String}          observationUuid
 * @returns {Promise<*>}
 */
async function reloadEcgObservationsListTasks({ commit, state }) {
  const observations = state.observationsPage?.content || [];
  if (observations.length) {
    const map = await loadDependedTasks(observations.map(observation => observation.uuid), state.enrollment.uuid, state.patient, {});
    commit(types.SET_OBSERVATION_TASK_MAP, map);
  }
  else {
    commit(types.SET_OBSERVATION_TASK_MAP, new Map);
  }
}

async function reload({ commit, state, dispatch }, dataTableConfig) {
  try {
    if (state.serverSideOptions?.page && state.serverSideOptions?.page === dataTableConfig?.page) {
      return;
    }
  }
  finally {
    commit(types.SET_PAGINATION_CONFIG, dataTableConfig);
  }
  commit(types.SET_OBSERVATION_TASK_MAP, new Map());
  const patientUuid = state.patient.uuid;
  const type = ecgKey;
  let params = {
    ...mapDataTablePageConfigToSpringPageConfig(state.serverSideOptions),
    type,
    sort: observationDateField + ',desc',
  };

  const { hasData, start, finish } = state.enrollment.getTotalDateRange();
  if (hasData) {
    params.from = moment(start).startOf('day').format(ISO_FULL_DATE_FORMAT);
    params.to = moment(finish || new Date()).endOf('day').format(ISO_FULL_DATE_FORMAT);
  }

  commit(types.SET_LOADING, true);
  const result = await patientObservations(patientUuid).fetchOne({ params });
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_OBSERVATIONS_PAGE, result.data);
    commit(types.SET_PAGE_INDEX, result.data.number + 1);
    dispatch('reloadEcgObservationsListTasks');
  }
  else {
    commit(types.SET_OBSERVATIONS_PAGE, null);
    showRestError(result.error, 'messages.cantFetchObservation');
  }
}

// this is just wrapper for reload function. Can be used on forms, where we already have the 'reload' method from another store
async function reloadEcgObservationsList({ dispatch }, payload) {
  await dispatch('reload', payload);
}

async function clean({ commit }) {
  commit(types.SET_OBSERVATIONS_PAGE, null);
  commit(types.SET_VALID_PAGE_FOR_OBSERVATION, null);
}

async function cleanPageInfo({ commit }) {
  commit(types.SET_VALID_PAGE_FOR_OBSERVATION, null);
}

async function initEcgObservationsList({ commit, dispatch }, { patient, enrollment, ecg }) {
  commit(types.INIT_PAGE, { enrollment, patient });
  await dispatch('navigateToEcg', { observationUuid: ecg.uuid });
}
async function navigateToEcg({ commit, dispatch, state }, { observationUuid }) {
  commit(types.SET_LOADING, true);
  const { start, finish } = state.enrollment.getTotalDateRange();
  const params = new OptimizedPageRequestParams({
    from: start,
    to: finish,
    observationUuid,
    type: ecgKey,
  });
  const result = await optimizedPatientObservations(state.patient.uuid).fetchOne({ params });
  commit(types.SET_LOADING, false);
  if (result.success) {
    commit(types.SET_OBSERVATIONS_PAGE, result.data.currentPage);
    commit(types.SET_VALID_PAGE_FOR_OBSERVATION, result.data);
    commit(types.SET_PAGE_INDEX, result.data.currentPage.number + 1);
    dispatch('reloadEcgObservationsListTasks');
    return;
  }
  else {
    commit(types.SET_OBSERVATIONS_PAGE, null);
    commit(types.SET_VALID_PAGE_FOR_OBSERVATION, null);
    showRestError(result.error, 'messages.cantFetchObservation');
  }
  await dispatch('reload');
}
