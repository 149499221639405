import { isBoolean, isFunction } from 'lodash';

export function isLoading(loading) {
  if (isBoolean(loading)) {
    return loading;
  }
  else if (isFunction(loading)) {
    return loading();
  }

  return false;
}
