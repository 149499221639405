import { EnrollmentProgramInputModel } from './enrollmentProgramInputModel';
import { DurationModel } from './durationModel';
import { mapArray, mapObject } from '../utils/mapUtils';
import moment from 'moment';

export class EnrollmentMonitoringPeriodModel {
  /**
   * EnrollmentMonitoringPeriodModel config
   *
   * @param {Object}                            config
   * @param {String}                            config.overriddenEnd
   * @param {String}                            config.plannedEnd
   * @param {EnrollmentMonitoringPeriodModel}   config.prev
   * @param {EnrollmentProgramInputModel[]}     config.programInputs
   * @param {String}                            config.start
   * @param {TemplateLinkMonitoringPeriodModel} config.templateLink
   * @param {String}                            config.uuid
   * @param {Boolean}                           config.sendDevicesToPatients
   */
  constructor(config) {
    this.overriddenEnd = config.overriddenEnd;
    this.plannedEnd = config.plannedEnd;
    this.prev = mapObject(config.prev, EnrollmentMonitoringPeriodModel);
    this.programInputs = mapArray(config.programInputs, EnrollmentProgramInputModel);
    this.start = config.start;
    this.templateLink = mapObject(config.templateLink, TemplateLinkMonitoringPeriodModel);
    this.uuid = config.uuid;
    this.sendDevicesToPatients = config.sendDevicesToPatients;
  }

  get realEndDate() {
    return this.overriddenEnd || this.plannedEnd;
  }

  get interval() {
    return [this.start, this.realEndDate];
  }

  get startDate() {
    if (this.start) {
      return moment(this.start).toDate();
    }
    return null;
  }

  /**
   *
   * @param {Date} date
   */
  containsDate(date) {
    if (moment(this.start).isAfter(date)) {
      return false;
    }
    if (this.realEndDate && moment(this.realEndDate).isBefore(date.getTime())) {
      return false;
    }
    return true;
  }
}

export class TemplateLinkMonitoringPeriodModel {
  /**
   * MonitoringPeriodModel config
   *
   * @param {Object}                                        config
   * @param {String}                                        config.uuid
   * @param {String}                                        config.name
   * @param {DurationModel}                                 config.duration
   */
  constructor(config) {
    this.duration = config.duration ? new DurationModel(config.duration) : null;
    this.name = config.name;
    this.uuid = config.uuid;
  }
}

