import { types } from './types';
import { certificationService } from '../../../services/api/version/certification';
import { showRestError } from '../../../utils/errors';
import { authorizationRemover } from '../authorizationRemover';

export const actions = Object.freeze({
  init,
});

async function init({ commit, state }) {
  if (state.initialized) {
    return;
  }
  commit(types.SET_INITIALIZED, true);
  commit(types.SET_PRODUCT_VERSION, null);
  commit(types.SET_LOADING, true);
  const result = await certificationService.fetchOne(authorizationRemover);

  if (result.success) {
    commit(types.SET_PRODUCT_VERSION, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchProductVersion');
  }
  commit(types.SET_LOADING, false);
}
