<template>
  <div
    :class="[
      styles.attachment,
      !$props.file.id && styles.attachmentNew,
      removed && styles.attachmentRemoved
    ]"
  >
    <div :class="styles.column">
      <Icon
        :class="[styles.icon, styles.pointer]"
        type="file"
        @click.stop="showFile"
      />
      <span
        :class="styles.filename"
        :title="file.name"
      >
        {{ file.name }}
      </span>
      <span
        v-if="file.size"
        :class="styles.fileSize"
      >
        {{ formatBytes(file.size, 1) }}
      </span>
    </div>
    <template v-if="!$props.disabled">
      <div
        v-if="$props.inlineLoading"
        :class="styles.column"
      >
        <VProgressCircular
          v-if="status === UploadStatus.Uploading"
          :size="20"
          :value="$props.uploadProgress.value"
        />
        <Button
          v-else-if="status === UploadStatus.Error"
          text
          round
          small
          icon="rotate-cw"
          :class="[styles.icon, styles.error, styles.iconStatus]"
          @click.stop="retry"
        />

        <Button
          v-if="status === UploadStatus.Uploading || status === UploadStatus.Error"
          text
          round
          small
          icon="x-circle-invert"
          :class="[styles.deleteIcon, styles.icon, styles.iconStatus]"
          @click.stop="removeFile"
        />
        <Icon
          v-else-if="status === UploadStatus.Complete"
          :class="[styles.iconCheck, styles.icon]"
          type="check"
        />
        <Button
          v-else-if="status === UploadStatus.Done"
          text
          round
          small
          icon="trash"
          data-testid="button-delete"
          :class="[styles.deleteIcon, styles.icon, styles.iconStatus]"
          @click.stop="removeFile"
        />
      </div>
      <div v-else>
        <Button
          v-if="!removed || allowRevert"
          text
          round
          small
          :icon="removed ? 'rotate-ccw' : 'x-circle-invert'"
          :class="[styles.fileItemIcon, styles.icon]"
          @click.stop="toggleRemove"
        />
      </div>

      <Button
        v-if="$props.additionalFileButtonIcon"
        text
        round
        small
        :icon="$props.additionalFileButtonIcon"
        :class="[styles.fileItemIcon, styles.icon]"
        @click="$emit('fileAction', file)"
      />
    </template>
  </div>
</template>

<script>
  import { VProgressCircular } from 'vuetify/lib';

  import { Icon } from '@h4h/icons';
  import { safeSetTimeout } from '@h4h/utils';

  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';
  import { CustomDocument } from '../../classes';

  import styles from './file.scss';
  import { formatBytes } from './utils';
  import { UploadStatus } from './uploadStatus';

  export default {
    name: 'H4hFileUpload',

    components: {
      Icon,
      VProgressCircular
    },

    mixins: [
      InputMixin
    ],

    props: {
      uploadProgress: Object,
      file: CustomDocument,

      downloadAction: Function,

      additionalFileButtonIcon: {
        type: String,
        required: false,
        default: null
      },

      showTickTime: {
        type: Number,
        default: 5000
      },

      /** labels */
      timer: inputProps.booleanFalse,
      disabled: inputProps.booleanFalse,
      allowRevert: inputProps.booleanFalse,
      inlineLoading: inputProps.booleanFalse
    },

    data() {
      return {
        styles,
        formatBytes,
        status: UploadStatus.Uploading,
        UploadStatus,
        removed: false
      };
    },

    watch: {
      'uploadProgress.value'(progress) {
        if (progress === null || this.status === UploadStatus.Done) {
          this.status = UploadStatus.Done;
        }
        else if (progress === 100) {
          this.status = UploadStatus.Complete;
          this.hideTick();
        }
        else if (progress === -1) {
          this.status = UploadStatus.Error;
        }
      }
    },

    methods: {
      toggleRemove() {
        this.removed = !this.$props.file.removed;
        this.removeFile();
      },

      removeFile() {
        this.$emit('toggleRemove', this.$props.file);
      },

      hideTick() {
        safeSetTimeout(() => this.status = UploadStatus.Done, this.$props.showTickTime);
      },

      retry() {
        this.$emit('retry', this.$props.file);
      },

      async showFile() {
        if (this.$props.file.id) {
          const result = await this.downloadFile();
          if (result.success) {
            result.data.view();
          }
        }
        else {
          this.$props.file.view();
        }
      },

      async downloadFile() {
        if (this.$props.downloadAction) {
          return this.$props.downloadAction(this.$props.file);
        }
        return {
          success: false,
          data: null
        };
      }
    }
  };
</script>
