import { mapArray } from '../../utils/mapUtils';
import { ObservationTemplateModel } from '../ObservationTemplateModel';

export class LabResultGroupModel {
  /**
   * LabResultGroupModel config
   *
   * @param {Object}                          config
   * @param {String}                          config.uuid
   * @param {String}                          config.name
   * @param {ObservationTemplateModel[]}      config.observationTemplates
   */
  constructor(config) {
    this.uuid = config.uuid;
    this.name = config.name;
    this.observationTemplates = mapArray(config.observationTemplates, ObservationTemplateModel);
    this.tenant = config.tenant || null;
  }
}
