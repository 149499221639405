import { toast } from '../../../services/toast';
import { types } from './types';
import { groups } from '../../../services/api/users/groups';
import { userGroups } from '../../../services/api/users/userGroups';
import { showRestError } from '../../../utils/errors';
import { localize } from '../../../services/localize';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { PopupType } from '@h4h/popups';

export const actions = Object.freeze({
  deleteGroup,
  reload,
  fetchGroupsOrganizationUsersOnly,
  fetchUserGroups,
  joinUserToGroup,
  excludeUserFromGroup,
  initGroupInput,
  showDeleteGroupPopup,
  hidePopup,
  onFilterChange,
  initPage,
});

async function initPage({ commit, state }) {
  commit(types.INIT_FORM);
  await reload({ commit, state });
}

async function onFilterChange({ commit }) {
  commit(types.UPDATE_FILTER);
}

async function showDeleteGroupPopup({ commit }, group) {
  const { id } = await popups.show({
    name: PopupName.DeleteGroupPopup,
    type: PopupType.Modal
  });
  commit(types.INIT_DELETE_GROUP_POPUP, {
    popupId: id,
    groupId: group.uuid,
  });
}

function hidePopup({ state }) {
  popups.hide({ id: state.deleteGroupPopupId });
}

async function deleteGroup({ state, commit, dispatch }, groupId) {
  commit(types.SET_LOADING, true);
  const result = await groups.delete(groupId);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.groupDeleted'));
    await dispatch('reload', null, { root: false });
    hidePopup({ state });
  }
  else {
    showRestError(result.error, 'messages.cantDeleteGroups');
  }

}

async function reload({ state, commit }) {
  commit(types.SET_LOADING, true);
  commit(types.SET_FETCHED, false);
  const result = await groups.fetch({ params: { 'organization-users-only': true } });
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_FETCHED, true);
    commit(types.SET_GROUPS, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchGroups');
  }
  commit(types.UPDATE_FILTER);
  // return any result anyway. Empty array is better then null.
  return state.groups;
}

async function fetchGroupsOrganizationUsersOnly({ state, commit }) {
  if (state.fetched) {
    return state.groups;
  }
  return reload({ state, commit });
}

async function fetchUserGroups({ commit, state }, { userId }) {
  const result = await userGroups(userId).fetch();
  if (result.success) {
    commit(types.SET_EDIT_USER_GROUPS, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchGroupsById');
  }
  return state.groupsUser;
}

async function joinUserToGroup(_, { userId, groupId }) {
  const result = await userGroups(userId).update(groupId);
  if (result.success) {
    toast.success(localize('messages.userGroupsUpdated'));
  }
  else {
    showRestError(result.error, 'messages.cantJoinUserToGroup');
  }
}

async function excludeUserFromGroup(_, { userId, groupId }) {
  const result = await userGroups(userId).delete(groupId);
  if (result.success) {
    toast.success(localize('messages.userGroupsUpdated'));
  }
  else {
    showRestError(result.error, 'messages.cantExcludeUserFromGroup');
  }
}

async function initGroupInput({ commit }) {
  commit(types.INIT_FORM, {});
}
