export const orgUser = {
  nameFilterText: 'Zoek een gebruiker',
  users: 'Gebruikers',
  addUser: 'Voeg gebruiker toe',
  editUser: 'Gebruiker bewerken',
  editUserGroups: 'Gebruikersgroepen bewerken',
  groupManagement: 'Groepen Beheer',
  groupsUserIn: 'Groep gebruiker in:',
  availableGroupsToJoin: 'Beschikbare groepen om toe te treden:',
  excludeUserFromGroup: 'Gebruiker van groep uitsluiten',
  joinUserToGroup: 'Voeg gebruiker toe aan groep',
  profile: 'Profiel',
  editProfile: 'Profiel Bewerken',
  bigCode: 'BIG code',
  userGroups: 'Gebruikersgroep',
  newPassword: 'Nieuw wachtwoord',
  repeatNewPassword: 'Herhaal nieuw wachtwoord',
  resetPassword: 'Reset wachtwoord',
  resetPasswordHint: '*Gebruiker ontvangt nieuwe e-mail met link om wachtwoord te wijzigen',
  setTemporaryPassword: 'Maak tijdelijk wachtwoord',
  saveTemporaryPassword: 'Bewaar tijdelijk wachtwoord',
  setTempPasswordHint: '*Gebruiker kan eenmaal inloggen met tijdelijk wachtwoord en kan dit zelf wijzigen door eerst in te loggen'
};
