import { types } from './types';
import { programHasEnrollments, programs } from '../../../services/api/programs/programs';
import { showRestError } from '../../../utils/errors';
import {
  ControlPlaneScriptModel,
  ProgramLimitedUpdateModel,
  ProgramUpdateModel,
  TreatmentAdviceScriptModel
} from '../../../model/programModel';
import { getInputsMap } from '@h4h/inputs';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import { programsActions } from '../constants';
import { router } from '../../../services/router';
import { Page } from '../../../constants/page';
import { mapValues } from 'lodash';
import { InitPopupActionFullPath } from '../vendorPopup/constants';

const DoAddSupportedVendor = 'doAddSupportedVendor';
export const actions = Object.freeze({
  initForm,
  addPeriod,
  removePeriod,
  addObservation,
  removeObservation,
  addQuestionnaire,
  removeQuestionnaire,
  save,
  makePeriodPrimary,
  [DoAddSupportedVendor]: doAddSupportedVendor,
  removeSupportedVendor,
  addNewProgramInputGroup,
  linkProgramInputGroup,
  unlinkProgramInputGroup,
  upsertThreshold,
});

async function save({ state, dispatch, commit, getters }) {
  if (!state.availablePeriods.length) {
    toast.error(localize('programs.pleaseAddAtLeastOnePeriod'));
  }
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  let treatmentAdviceScript = null;
  const treatmentAdviceScriptInput = state.inputs.find(input => input.id === 'treatmentAdviceScript');
  if (treatmentAdviceScriptInput && treatmentAdviceScriptInput.value) {
    treatmentAdviceScript = new TreatmentAdviceScriptModel({
      content: state.inputs.find(input => input.id === 'treatmentAdviceScript').value,
      renderTemplate: state.inputs.find(input => input.id === 'renderTemplate').value,
    });
  }
  let programUpdateModel = new ProgramLimitedUpdateModel({
    ...mapValues(getInputsMap(state.inputs), i => i.value),
    controlPlaneScript: new ControlPlaneScriptModel({
      content: state.inputs.find(input => input.id === 'controlPlaneScript').value,
    }),
    treatmentAdviceScript,
    observationThresholds: state.observationThresholds,
    availablePeriods: state.availablePeriods.map(period => period.toModel()),
  });
  if (state.fullEdit) {
    programUpdateModel = new ProgramUpdateModel({
      ...programUpdateModel,
      basePeriodName: state.primaryPeriod.name.value,
      supportedVendors: state.supportedVendors,
    });
  }

  commit(types.SET_LOADING, true);
  const result = await programs.update(state.program.uuid, programUpdateModel);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.programSaved'));
    dispatch(programsActions.ReloadPrograms, {}, { root: true });
    router.push({ name: Page.AdminProgramsView, params: { uuid: state.program.uuid } });
  }
  else {
    showRestError(result.error, 'messages.cantUpdateProgram');
  }
  return result.success;
}

async function upsertThreshold({ commit }, threshold) {
  commit(types.UPSERT_THRESHOLD, threshold);
}

async function initForm({ commit }, { uuid, treatmentAdviceEnabled }) {
  let fullEdit = false;
  commit(types.SET_LOADING, true);
  commit(types.INIT_FORM, { program: null, fullEdit, treatmentAdviceEnabled });

  let hasEnrollmentsResult = await programHasEnrollments(uuid).fetchOne();
  if (hasEnrollmentsResult.success) {
    fullEdit = !hasEnrollmentsResult.data.hasEnrollments;
  }
  else {
    showRestError(hasEnrollmentsResult.error, 'messages.cantFetchProgramEnrollmentsInfo');
  }

  let result = await programs.get(uuid);
  if (result.success) {
    commit(types.INIT_FORM, { program: result.data, fullEdit, treatmentAdviceEnabled });
  }
  else {
    showRestError(result.error, 'messages.cantFetchProgram');
  }
  commit(types.SET_LOADING, false);
}

async function addPeriod({ commit }) {
  commit(types.ADD_PERIOD);
}

async function removePeriod({ commit }, period) {
  commit(types.REMOVE_PERIOD, period);
}

async function addObservation({ commit }, { period, observation }) {
  commit(types.ADD_OBSERVATION, { period, observation });
}

async function removeObservation({ commit }, { period, observation }) {
  commit(types.REMOVE_OBSERVATION, { period, observation });
}

async function addQuestionnaire({ commit }, { period, questionnaire }) {
  commit(types.ADD_QUESTIONNAIRE, { period, questionnaire });
}

async function removeQuestionnaire({ commit }, { period, questionnaire }) {
  commit(types.REMOVE_QUESTIONNAIRE, { period, questionnaire });
}

async function doAddSupportedVendor({ dispatch, commit, state }) {
  const { closed } = await dispatch(InitPopupActionFullPath, state.supportedVendors, { root: true });
  const vendor = await closed;
  if (vendor.success && vendor.data) {
    commit(types.ADD_SUPPORTED_VENDOR, vendor.data);
  }
}

async function removeSupportedVendor({ commit }, programVendorModel) {
  commit(types.REMOVE_SUPPORTED_VENDOR, programVendorModel);
}

async function makePeriodPrimary({ commit }, period) {
  commit(types.SET_PRIMARY_PERIOD, period);
}

async function addNewProgramInputGroup({ commit }, { group, groups, programInput }) {
  commit(types.ADD_NEW_PROGRAM_INPUT_GROUP, { group, groups, programInput });
}

async function linkProgramInputGroup({ commit }, { group, programInput }) {
  commit(types.LINK_INPUT_GROUP, { group, programInput });
}

async function unlinkProgramInputGroup({ commit }, { group, programInput }) {
  commit(types.UNLINK_INPUT_GROUP, { group, programInput });
}

