export const getters = {
  isValid,
};

function isValid(state) {
  let inputsToValidate = [];
  // don't validate the cron unless needed
  if (!state.cronEnabled.value) {
    inputsToValidate = state.inputs.filter(i => i.id !== 'cron');
  }
  else {
    inputsToValidate = [...state.inputs];
  }
  return inputsToValidate && inputsToValidate.every(i => i.valid);
}
