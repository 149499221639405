import moment from 'moment';

export const getters = {
  canRequestNewCode
};

function canRequestNewCode(state) {
  const enrollment = state.enrollment;
  if (enrollment) {
    const plannedEnd = enrollment.monitoringPeriod?.plannedEnd;
    if (plannedEnd) {
      return moment(plannedEnd).endOf('day').isAfter(new Date());
    }
    return true;
  }
}
