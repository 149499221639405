export const troubleshooting = {
  troubleshootingReport: 'Troubleshooting report',
  status: 'Status',
  url: 'URL',
  responseId: 'Trace id',
  response: 'Response',
  callcenterFlag: 'Callcenter flag',
  tenantIdFlag: 'Tenant id flag',
  payload: 'Payload',
  page: 'Page',
  captureOnlyErrors: 'Capture only errors',
};
