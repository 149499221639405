import { isNil, invert } from 'lodash';

export class ApiEnumMapper {
  /**
   *
   * @param {String} name - enum name
   * @param {Object} map - API value to enum value map
   */
  constructor(name, map) {
    this._map = map;
    this._name = name;
    this._invertedMap = invert(map);
  }

  /**
   * Tries to map API value to enum value, logs an error and passes API value if the mapping is not defined
   *
   * @param {String|Number} value - API value
   * @returns {String|Number} - enum value or API value
   */
  fromApi(value) {
    if (Object.prototype.hasOwnProperty.call(this._map, value)) {
      return this._map[value];
    }

    if (!isNil(value)) {
      // eslint-disable-next-line no-console
      console.error(`No corresponding model mapping for ${ JSON.stringify(value) } in ${ this._name }`);
    }

    return value;
  }

  /**
   * Tries to map enum value to API value, logs an error and passes enum value if the mapping is not defined
   *
   * @param {String} value - enum value
   * @returns {String} - API value or enum value
   */
  toApi(value) {
    if (Object.prototype.hasOwnProperty.call(this._invertedMap, value)) {
      return this._invertedMap[value];
    }

    if (!isNil(value)) {
      // eslint-disable-next-line no-console
      console.error(`No corresponding API mapping for ${ JSON.stringify(value) } in ${ this._name }`);
    }

    return value;
  }
}
