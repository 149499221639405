export function getInputsMap(inputs) {
  if (!inputs) {
    return null;
  }

  const map = {};

  inputs.forEach(i => map[i.id] = i);

  return map;
}
