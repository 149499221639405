import { PractitionerTasksHandlingStatus } from '../../../../../constants/practitionerTasks';
import { pageablePractitionerTasks } from '../../../../../services/api/practitionerTasks/pageablePractitionerTasks';
import { toast } from '../../../../../services/toast';
import { localize } from '../../../../../services/localize';
import { isCallCenter } from '../../../../../services/auth/auth';
import { getTenantFromRoute } from '../../../../../services/router/tenantRouteUtils';
import { OrderedSeverities } from '../../../../../constants/severity';

export class ObservationTaskHelper {

  /**
   * @param {Map} observationTaskMap
   */
  constructor(observationTaskMap) {
    this.observationTaskMap = observationTaskMap;
  }

  hasUnhandledTasks(observationUuid) {
    return !!this.getUnhandledTasks(observationUuid).length;
  }

  getUnhandledTasks(uuid) {
    return this.getTasks(uuid).filter(task => task.status === PractitionerTasksHandlingStatus.UNHANDLED);
  }

  /**
   *
   * @param {String} observationUuid
   * @return [PractitionerTaskModel]
   */
  getTasks(observationUuid) {
    return this.observationTaskMap.get(observationUuid) || [];
  }

  getHighestSeverityValue(observationUuid) {
    const tasks = this.getTasks(observationUuid);
    let highestSeverityFound = null;
    if (tasks.length > 0) {
      for (const task of tasks) {
        if (OrderedSeverities.indexOf(highestSeverityFound) < OrderedSeverities.indexOf(task.severity)) {
          highestSeverityFound = task.severity;
        }
      }
    }
    return highestSeverityFound;
  }
}

export async function loadDependedTasks(observationExtIds, enrollmentUuid, patient, filters) {
  if (observationExtIds.length) {
    const params = {
      page: 0,
      size: 999999
    };
    const filter = {
      observationExtIds,
      ...filters
    };
    const contents = [];
    const tasksResponse = await pageablePractitionerTasks.create(filter, { params });
    if (tasksResponse.success) {
      contents.push(...tasksResponse.data.content);
    }
    else {
      toast.error(localize('messages.cantFetchTasks'), tasksResponse.error);
    }
    let tenantFromRoute = getTenantFromRoute();
    const map = new Map();
    contents.forEach(task => {
      if (isCallCenter() && !task.tenantId) {
        task.tenantId = tenantFromRoute;
      }
      task.patient = patient;
      const taskEnrollmentId = task.monitoringPeriod?.enrollment?.id;
      if (taskEnrollmentId === enrollmentUuid) {
        const observationUuid = task.observation?.payload?.uuid;
        if (observationUuid) {
          if (!map.has(observationUuid)) {
            map.set(observationUuid, []);
          }
          map.get(observationUuid).push(task);
        }
      }
    });
    return map;
  }
  return new Map();
}

