export const tenant = {
  navigateToAdminPage: 'Navigeer naar de beheerpagina',
  navigateToPractitionerPage: 'Navigeer naar de zorgverlener pagina',
  navigateToCallCenterAdminPage: 'Navigeer naar de beheerpagina',
  navigateToCallCenterSupportPage: 'Navigeer naar de pagina Ondersteuning',
  navigateToLogisticPage: 'Navigeer naar Logistieke pagina',
  authorize: 'Toestemming geven',
  noTenantInfoFound: 'E-mailadres niet gevonden',
  authorizeToX: 'Autoriseren om {0}',
  errorAuthorize: 'Fout autoriseren',
  errorGetEmailInfo: 'Fout bij ophalen van e-mailgegevens',
  fillInYourEmail: 'Vul hier uw e-mailadres in:',
  showAvailableOrganizations: 'Toon beschikbare zorginstellingen',
  country: 'Land',
};
