import { types } from './types';

export const mutations = {
  [types.SET_ACTIONS](state, actions) {
    state.actions = actions;
  },

  [types.SET_LOADING_ACTIONS](state, loadingActions) {
    state.loadingActions = loadingActions;
  },

  [types.SET_REQUEST_IS_PERFORMING](state, requestIsPerforming) {
    state.requestIsPerforming = requestIsPerforming;
  },
};

