<template>
  <div>
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />

    <div :class="styles.container">
      <Button
        small
        :tabindex="buttonTabindex"
        :disabled="disabledToday"
        :label="$props.buttonLabel"
        :data-testid="`button-today`"
        @click="setToday"
      />
      <Button
        text
        round
        icon="chevron-left"
        :large="$props.large"
        :disabled="isMinDay()"
        :data-testid="`button-previous-day`"
        @click="changeDay(-1)"
      />
      <div
        v-if="!isTodayControl"
        :class="[styles.dayContainer, $props.large && styles.largeInput, $props.inputStyle]"
      >
        <FlatPickr
          :id="id"
          ref="input"
          :config="config"
          :value="$props.value"
          :events="flatPickrEvents"
          :class="[inputStyles.input, $props.disableCalendar && styles.disableCalendar]"
          :tabindex="tabIndex"
          :disabled="$props.disabled"
          @on-change="onChange"
          @onOpen="tabIndexSubscriber__onFocus"
        />
      </div>
      <Button
        text
        round
        icon="chevron-right"
        :large="$props.large"
        :disabled="isMaxDay()"
        :data-testid="`button-next-day`"
        @click="changeDay(1)"
      />
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import moment from 'moment';
  import { uniqueId } from 'lodash';
  import 'flatpickr/dist/flatpickr.min.css';
  import FlatPickr from 'vue-flatpickr-component';

  import { Button } from '@h4h/button';
  import { DAY_FORMAT, formatDay } from '@h4h/date';
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';
  import { getLocalValues } from '../../utils/dateLocales';

  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';

  import LabelGroup from '../labelGroup/LabelGroup';

  import styles from './dayPicker.scss';

  Vue.use(FlatPickr);

  export default {
    name: 'H4hDayPicker',

    components: {
      Button,
      FlatPickr,
      LabelGroup
    },

    mixins: [
      InputMixin
    ],

    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      value: {
        type: Date,
        default: new Date(),
      },

      locale: {
        type: [String, Object],
      },

      dateFormat: {
        type: String,
        default: DAY_FORMAT
      },

      min: Date,
      max: Date,

      /** labels */
      info: inputProps.label,
      otherLabel: inputProps.label,

      // eslint-disable-next-line vue/require-prop-types
      buttonLabel: {
        ...inputProps.label,
        default: 'common.today'
      },

      /** flags */
      large: inputProps.booleanFalse,
      isTodayControl: inputProps.booleanFalse,
      disableCalendar: inputProps.booleanFalse,

      /** styles */
      labelStyle: inputProps.style,
      inputStyle: inputProps.style
    },

    data() {
      return {
        styles,
        inputStyles,
        id: uniqueId('date-input-'),
        flatPickrEvents: [
          'onOpen',
          'onChange'
        ],
        buttonTabindex: this.$props.tabindex - 0.1
      };
    },

    computed: {
      disabledToday() {
        return this.disabled || this.isToday;
      },
      config() {
        const { min, max, dateFormat } = this.$props;
        return {
          formatDate: formatDay,
          minDate: min,
          maxDate: max,
          time_24hr: true,
          dateFormat,
          wrap: true,
          locale: {
            firstDayOfWeek: 1,
            ...this.getLocal
          }
        };
      },

      isToday() {
        return this.isEqualDays(this.$props.value, new Date());
      },

      getLocal() {
        return this.$props.locale || getLocalValues();
      }
    },

    methods: {
      emitChange(value) {
        this.$emit('change', value);
      },

      onChange(value) {
        if (this.isEqualDays(this.$props.value, value[0])) {
          return;
        }
        const date = new Date(value[0]);
        const now = new Date();
        date.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
        this.emitChange(date);
      },

      setToday() {
        this.emitChange(moment().startOf('day').toDate());
      },

      changeDay(step) {
        if ((step === -1 && this.isMinDay()) || (step === 1 && this.isMaxDay())) {
          return;
        }
        const newValue = moment(this.$props.value).add(step, 'days').toDate();
        this.emitChange(newValue);
      },

      isEqualDays(date1, date2) {
        return moment(date1).isSame(date2, 'day');
      },

      isMinDay() {
        const { min, value } = this.$props;
        if (!min) {
          return false;
        }

        return this.isEqualDays(min, value);
      },

      isMaxDay() {
        const { max, value } = this.$props;
        if (!max) {
          return false;
        }

        return this.isEqualDays(max, value);
      }
    }
  };
</script>
