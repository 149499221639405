import moment from 'moment';

export class ObservationCommentModel {
  /**
   * ObservationCommentModel config
   *
   * @param {String}                    config.uuid
   * @param {String}                    config.observationUuid
   * @param {String}                    config.value
   * @param {String}                    config.modifiedOn
   * @param {String}                    config.modifiedBy
   */
  constructor(config) {
    config = config || {};
    this.uuid = config.uuid;
    this.observationUuid = config.observationUuid;
    this.value = config.value;
    this.modifiedOn = config.modifiedOn;
    this.modifiedBy = config.modifiedBy;
  }

  get modifiedOnAsDate() {
    if (this.modifiedOn) {
      return moment(this.modifiedOn).toDate();
    }
    return null;
  }

}
