import { types } from './types';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.UPDATE_USER_GROUPS](state, { groupsCurrent, groupsProposed }) {
    state.groupsCurrent = groupsCurrent;
    state.groupsProposed = groupsProposed;
  },
};