import { view } from './view';
import { edit } from './edit';

export const settings = {
  namespaced: true,
  modules: {
    view,
    edit,
  }
};
