import { mapObject } from '../../utils/mapUtils';
import { QuestionnaireModel } from './questionnaireModel';

export class AnamnesisModel {

  /**
   * Anamnesis
   *
   * @param {Object}    config
   * @param {QuestionnaireModel}    config.questionnaire
   */
  constructor(config) {
    this.questionnaire = mapObject(config.questionnaire, QuestionnaireModel);
  }
}
