export const state = {
  observationsLoading: false,
  loadingPercent: null,
  observationTemplate: null,
  patient: null,
  enrollment: null,

  period: null,
  intervalChooser: null,
  conclusionNames: null,
  conclusionBy: null,

  groupedObservations: [],
  observationTaskMap: new Map(),
};
