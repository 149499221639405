import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { ccRoleOptionsSupportedForSelect } from '../../../../services/auth/ehRole';
import { apiRoleMapper } from '../../../../services/auth/apiRoleMapper';
import { cleanPageNum, setPaginationConfigMutation, toServerPageOptions } from '../../../../model/page';
import { createMainFilterInput, filerConstraints } from '../../../../services/filter/utils/filterUtils';
import { filterInputs } from './getters';

function createFilterParams(state) {
  const params = {};

  filterInputs(state)
    .filter(input => input.value && input.valid)
    .forEach(input => {
      params[input.id] = input.value;
      if (input === state.roleFilterInput) {
        params[input.id] = apiRoleMapper.toApi(input.value);
      }
    });

  return params;
}

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_USERS_PAGE](state, value) {
    state.usersPage = value;
    Object.assign(state.serverSideOptions, toServerPageOptions(value));
  },

  [types.SET_PAGINATION_CONFIG]: setPaginationConfigMutation,

  [types.SET_ROLE_FILTER_VALUE](state, input) {
    state.roleFilterInput = input;
  },

  [types.INIT_FORM](state) {
    state.nameFilterInput = createInputConfig({
      id: 'lastName',
      label: 'common.name',
      type: InputType.Text,
      value: '',
      ...filerConstraints,
    });
    state.emailFilterInput = createInputConfig({
      id: 'email',
      label: 'common.email',
      type: InputType.Text,
      value: '',
      ...filerConstraints,
    });
    state.generalSearchInput = createMainFilterInput({
      id: 'multipleFilter'
    });

    state.roleFilterInput = createInputConfig({
      id: 'role',
      type: InputType.Select2,
      label: 'callCenter.roles',
      // multiple: true,
      options: ccRoleOptionsSupportedForSelect
    });
    state.filterParams = createFilterParams(state);
  },

  [types.UPDATE_FILTER_PARAMS](state) {
    state.filterParams = createFilterParams(state);
    cleanPageNum(state);
  }
};
