import { PDF_HEIGHT, PDF_LEFT_LOCATION, PDF_TOP_LOCATION, PDF_WIDTH } from '../constants/pdf';

export function download(filename, file) {
  const element = document.createElement('a');
  element.setAttribute('href', file);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(new Blob([file], { type: file.type || 'image/png' }));
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function openPdf(pdf, showInTab) {
  if (!pdf) {
    return;
  }

  window.open(
    URL.createObjectURL(pdf),
    '_blank',
    showInTab ? null : `width=${ PDF_WIDTH },height=${ PDF_HEIGHT },top=${ PDF_TOP_LOCATION },left=${ PDF_LEFT_LOCATION }`
  );
}

export async function openImage(image, showInTab) {
  if (!image) {
    return;
  }

  let img = new Image();
  img.src = await toBase64(image);

  let newTab = window.open(
    '',
    '_blank',
    showInTab ? null : `width=${ PDF_WIDTH },height=${ PDF_HEIGHT },top=${ PDF_TOP_LOCATION },left=${ PDF_LEFT_LOCATION }`
  );
  newTab.document.body.appendChild(img);
}