<template>
  <div :class="[styles.smartFilter, !$props.disableFlexEnd && styles.flexEnd]">
    <FilterRow
      :inputs="_inputs.slice(0, $props.numberOfShownFilters)"
      :setInputValueAction="setInputValueActionWithSearch"
      @applyFilters="filterAction"
    />

    <FilterPopover
      v-if="_inputs.length > $props.numberOfShownFilters"
      :inputs="_inputs.slice($props.numberOfShownFilters)"
      :setInputValueAction="setInputValueActionWithSearch"
      :hiddenFiltersWithValueCount="hiddenFiltersWithValueCount"
      :isServerSide="isServerSide"
      @applyFilters="filterAction"
    />

    <Button
      v-if="isServerSide"
      data-testid="button-search"
      primary
      label="common.search"
      @click="filterAction"
    />
    <slot name="additionalFilters"/>
  </div>
</template>

<script>
  import { debounce, isFunction } from 'lodash';

  import { setInputValue, isClientSideTableSearchInput } from '@h4h/inputs';

  import {
    isFilledInputValue,
    getClientSideTableSearchInput,
  } from '../../utils/filters';

  import styles from './filter.scss';
  import FilterRow from './FilterRow';
  import FilterPopover from './FilterPopover';

  export default {
    name: 'H4HSmartFilter',

    components: {
      FilterPopover,
      FilterRow,
    },

    props: {
      inputs: {
        type: [Array, Function],
        required: true
      },

      setInputValueAction: {
        type: Function,
        default: setInputValue
      },

      pagination: {
        type: Object
      },

      // Server side filter action only
      applyFilterAction: {
        type: Function,
        required: false
      },

      query: {
        type: String,
        default: undefined
      },

      numberOfShownFilters: {
        type: Number,
        required: false,
        default: 4
      },
      // for pages where we don't use Table component, but we need this component
      // we need to disable this styling, because it looks ugly
      disableFlexEnd: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    data() {
      return {
        styles,
        hiddenFiltersWithValueCount: this.getHiddenFiltersWithValueCount(),
      };
    },

    computed: {
      _inputs() {
        return this.getInputs();
      },

      searchInput() {
        return getClientSideTableSearchInput(this.inputs);
      },

      isServerSide() {
        return !!this.$props.applyFilterAction;
      }
    },

    watch: {
      query(value) {
        if (this.searchInput && value !== this.searchInput.value && value !== undefined) {
          setInputValue({
            input: this.searchInput,
            value
          });
        }
      }
    },

    methods: {
      getHiddenFiltersWithValueCount() {
        return this.getInputs().slice(this.$props.numberOfShownFilters)
          .filter(i => isFilledInputValue(i.value)).length;
      },

      getInputs() {
        if (Array.isArray(this.$props.inputs)) {
          return this.$props.inputs;
        }
        else if (isFunction(this.$props.inputs)) {
          return this.$props.inputs();
        }

        return [];
      },

      setInputValueActionWithSearch({ input, value }) {
        if (isClientSideTableSearchInput(input)) {
          this.onSearchInputChange({ input, value });
        }

        this.$props.setInputValueAction({ input, value });
      },

      filterAction() {
        /**
        * Pass whole Pagination object received from store to prevent
        * onOptionsChange trigger after initial data fetch
        **/

        this.$props.applyFilterAction({ ...this.$props.pagination, page: 1 });
        this.hiddenFiltersWithValueCount = this.getHiddenFiltersWithValueCount();
      },

      onSearchInputChange: debounce(function({ input, value }) {
        if (input.valid) {
          this.$emit('searchQueryChange', value || '');
          this.$emit('search');
        }
      }, 250)
    }
  };
</script>

