import { uniq, keyBy } from 'lodash';

/**
 * getRowMap
 *  returns a map of rows by row id
 *
 * @param {Object[]}  rows
 * @param {String}    idKey
 * @returns {*}
 */
export function getRowMap(rows, idKey) {
  return keyBy(rows, idKey);
}

/**
 * isExpandedRowGroup
 *  determines whether a row with children is "expanded"
 *    * is included in expandedGroupRowIds
 *    * has no parent row OR its parent is "expanded"
 *
 * @param {Object}    row
 * @param {String}    idKey
 * @param {String}    parentRowIdKey
 * @param {Object}    rowMap
 * @param {Object[]}  expandedRows
 *
 * @returns {Boolean}
 */
export function isExpandedGroupRow(row, idKey, parentRowIdKey, rowMap, expandedRows) {
  const parentRow = rowMap[row[parentRowIdKey]];

  if (!expandedRows.includes(row)) {
    return false;
  }

  if (!parentRow) {
    return true;
  }

  return isExpandedGroupRow(parentRow, idKey, parentRowIdKey, rowMap, expandedRows);
}

/**
 * isShownGroupRow
 *  determines whether a row is shown
 *    * either "root row" - doesn't have a parent row OR its parent is shown
 *
 * @param {Object}    row
 * @param {String}    idKey
 * @param {String}    parentRowIdKey
 * @param {Object}    rowMap
 * @param {Object[]}  expandedRows
 *
 * @returns {Boolean}
 */
export function isShownGroupRow(row, idKey, parentRowIdKey, rowMap, expandedRows) {
  if (!rowMap[row[idKey]]) {
    return false;
  }

  const parentRow = rowMap[row[parentRowIdKey]];
  return !parentRow || isExpandedGroupRow(parentRow, idKey, parentRowIdKey, rowMap, expandedRows);
}

/**
 * getRowsByParentIdMap
 *  returns a map of rows by their parent row ids
 *
 * @param {Object[]}  rows
 * @param {String}    parentRowIdKey
 *
 * @returns {Object}
 */
export function getRowsByParentIdMap(rows, parentRowIdKey) {
  const rowsByParentId = {};

  rows.forEach(row => {
    const pid = row[parentRowIdKey];

    if (!pid) {
      return;
    }

    if (!rowsByParentId[pid]) {
      rowsByParentId[pid] = [row];
    }
    else {
      rowsByParentId[pid].push(row);
    }
  });

  return rowsByParentId;
}

/**
 * getRowGroup
 *  returns the row and all its visible children (if any)
 *
 * @param {Object}    row
 * @param {String}    idKey
 * @param {String}    parentRowIdKey
 * @param {Object}    rowMap
 * @param {Object}    rowsByParentIdMap
 * @param {Object[]}  expandedRows
 *
 * @returns {Object[]}
 */
export function getRowGroup(row, idKey, parentRowIdKey, rowMap, rowsByParentIdMap, expandedRows) {
  const rowId = row[idKey];
  const rowChildren = rowsByParentIdMap[rowId];

  const group = [row];

  if (!rowChildren) {
    return group;
  }

  rowChildren
    .filter(childRow => isShownGroupRow(childRow, idKey, parentRowIdKey, rowMap, expandedRows))
    .forEach(childRow => {
      const subgroup = getRowGroup(childRow, idKey, parentRowIdKey, rowMap, rowsByParentIdMap, expandedRows);
      group.push(...subgroup);
    });

  return group;
}

/**
 * getRootRows
 *  returns "root" rows - the ones without parents
 *
 * @param {Object[]}  rows
 * @param {String}    parentRowIdKey
 *
 * @returns {Object[]}
 */
export function getRootRows(rows, parentRowIdKey) {
  return rows.filter(row => !row[parentRowIdKey]);
}

/**
 * getFilteredRowGroups
 *  returns rows that have no parents OR have expanded parents
 *
 * @param {Object[]}  rows
 * @param {String}    idKey
 * @param {String}    parentRowIdKey
 * @param {Object}    rowMap
 * @param {Object}    rowsByParentIdMap
 * @param {Object[]}  expandedRows
 *
 * @returns {Object[]}
 */
export function getFilteredRowGroups(rows, idKey, parentRowIdKey, rowMap, rowsByParentIdMap, expandedRows) {
  // @todo: Baizulin - cover with unit tests,add docs, simplify and optimize
  const orphansAndAllParents = rows
    .map(row => getOrphanOrRowWithParents(row, parentRowIdKey, rowMap))
    .flat();

  const filteredGroups = [];

  getOrphansAndRootParents(rows, parentRowIdKey, rowMap)
    .forEach(row => {
      let group = getRowGroup(row, idKey, parentRowIdKey, rowMap, rowsByParentIdMap, expandedRows);
      group = group.filter(row => orphansAndAllParents.includes(row));
      filteredGroups.push(...group);
    });

  return filteredGroups;
}

export function getOrphansAndRootParents(rows, parentRowIdKey, rowMap) {
  return uniq(
    rows
      .map(row => getOrphanOrRootParent(row, parentRowIdKey, rowMap))
      .filter(x => x)
  );
}

function getOrphanOrRootParent(row, parentRowIdKey, rowMap) {
  const parentRow = rowMap[row[parentRowIdKey]];

  if (!parentRow) {
    return row;
  }

  return getOrphanOrRootParent(parentRow, parentRowIdKey, rowMap);
}

function getOrphanOrRowWithParents(row, parentRowIdKey, rowMap, group = []) {
  const parentRow = rowMap[row[parentRowIdKey]];
  group = [row, ...group];

  if (!parentRow) {
    return group;
  }

  return getOrphanOrRowWithParents(parentRow, parentRowIdKey, rowMap, group);
}

/**
 * getNestingLevel
 *  calculates nesting level of a row - used to calculate left offset
 *
 * @param {Object}    row
 * @param {String}    idKey
 * @param {String}    parentRowIdKey
 * @param {Object}    rowMap
 *
 * @returns {Number}
 */
export function getNestingLevel(row, idKey, parentRowIdKey, rowMap) {
  const pid = row[parentRowIdKey];
  const parent = rowMap[pid];

  if (!parent) {
    return 0;
  }

  return 1 + getNestingLevel(parent, idKey, parentRowIdKey, rowMap);
}

/**
 * getAllChildren
 *  returns all children from a row's tree
 *
 * @param {Object} row
 * @param {Object} rowsByParentIdMap
 * @param {String} idKey
 *
 * @returns {Object[]}
 */
export function getAllChildren(row, rowsByParentIdMap, idKey) {
  const rowId = row[idKey];

  return (rowsByParentIdMap[rowId] || [])
    .map(childRow => ([
      childRow,
      ...getAllChildren(childRow, rowsByParentIdMap, idKey)
    ]))
    .flat();
}
