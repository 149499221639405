import { types } from './types';
import { createInputConfig, getInputsMap, InputType, setInputValue } from '@h4h/inputs';
import { PatientFilterFields } from './constants';
import { genderOptions } from '../../../constants/gender';
import { mapValues } from 'lodash';
import { cleanPageNum, setPaginationConfigMutation, toServerPageOptions } from '../../../model/page';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '@h4h/date';
import { createEhDateInputConfig } from '../../../utils/factories';
import { createMainFilterInput, shortFilerConstraints } from '../../../services/filter/utils/filterUtils';

function createFilterParams(state) {
  let inputsMap = getInputsMap(state.inputs.filter(input => !!input.value));
  const params = { ...mapValues(inputsMap, i => i.value) };
  if (params[PatientFilterFields.dateOfBirth]) {
    params[PatientFilterFields.dateOfBirth] = moment(params[PatientFilterFields.dateOfBirth]).format(ISO_DATE_FORMAT);
  }
  if (params[PatientFilterFields.genders]) {
    params[PatientFilterFields.genders] = Array.from(params[PatientFilterFields.genders]);
  }
  return params;
}

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PATIENTS_PAGE](state, value) {
    state.patientsPage = value;
    Object.assign(state.serverSideOptions, toServerPageOptions(value));
  },

  [types.SET_PAGINATION_CONFIG]: setPaginationConfigMutation,

  /**
   *
   * @param state
   * @param {TableFiltersProfile} tableFiltersProfile
   */
  [types.APPLY_FILTERS](state, tableFiltersProfile) {
    const userFilters = tableFiltersProfile?.filters;
    if (!userFilters) {
      return;
    }
    const inputsMap = getInputsMap(state.inputs);

    setInputValue({
      input: inputsMap[PatientFilterFields.name],
      value: userFilters[PatientFilterFields.name] || '',
    });
    setInputValue({
      input: inputsMap[PatientFilterFields.patientId],
      value: userFilters[PatientFilterFields.patientId] || '',
    });
    setInputValue({
      input: inputsMap[PatientFilterFields.dateOfBirth],
      value: userFilters[PatientFilterFields.dateOfBirth] ? new Date(userFilters[PatientFilterFields.dateOfBirth]) : null,
    });
    setInputValue({
      input: inputsMap[PatientFilterFields.email],
      value: userFilters[PatientFilterFields.email] || null,
    });
    setInputValue({
      input: inputsMap[PatientFilterFields.genders],
      value: new Set(userFilters[PatientFilterFields.genders] || []),
    });
    setInputValue({
      input: inputsMap[PatientFilterFields.includeArchived],
      value: userFilters[PatientFilterFields.includeArchived] || null,
    });
    state.filterParams = createFilterParams(state);
    state.lastXPatientsMode = evalLastXPatientsMode(state);
  },

  [types.INIT_FORM](state) {
    state.inputs = [
      createMainFilterInput({
        id: PatientFilterFields.name,
        label: 'common.name',
      }),
      createInputConfig({
        id: PatientFilterFields.patientId,
        type: InputType.Text,
        label: 'common.hisPatientId',
        ...shortFilerConstraints,
      }),
      createEhDateInputConfig({
        id: PatientFilterFields.dateOfBirth,
        label: 'common.dateOfBirth',
      }),
      createInputConfig({
        id: PatientFilterFields.email,
        type: InputType.Text,
        label: 'common.email',
        ...shortFilerConstraints,
      }),
      // createMainFilterInput({
      //   id: PatientFilterFields.name,
      // }),
      createInputConfig({
        id: PatientFilterFields.genders,
        type: InputType.Select2,
        label: 'gender.gender',
        multiple: true,
        options: genderOptions,
      }),
      createInputConfig({
        id: PatientFilterFields.includeArchived,
        type: InputType.Checkbox,
        label: 'patient.showArchivedPatients',
      }),
    ];
    state.filterParams = createFilterParams(state);
    state.lastXPatientsMode = evalLastXPatientsMode(state);
  },

  [types.UPDATE_FILTER_PARAMS](state) {
    cleanPageNum(state);
    state.filterParams = createFilterParams(state);
    state.lastXPatientsMode = evalLastXPatientsMode(state);
  }
};

function evalLastXPatientsMode(state) {
  const fields = [
    PatientFilterFields.name,
    PatientFilterFields.patientId,
    PatientFilterFields.email,
    PatientFilterFields.dateOfBirth,
    PatientFilterFields.includeArchived
  ];
  const someValue = state.inputs
    .filter(input => fields.indexOf(input.id) > -1)
    .some(input => !!input.value);
  let inputsMap = getInputsMap(state.inputs.filter(input => !!input.value));
  const someGender = inputsMap[PatientFilterFields.genders]?.value?.size > 0;
  return !(someValue || someGender);
}
