<template>
  <VTab
    :disabled="disabled"
    :tabindex="tabIndex"
    :to="to"
    @focus="onFocus"
    @change="onChange($event)"
  >
    <slot v-if="hasSlot('title')">
      <slot name="title"/>
    </slot>
    <span v-else>
      {{ $localize(title) }}
    </span>
    <Badge
      v-if="showIndicator"
      :class="styles.indicator"
      :color="indicatorColor"
      overlap
      dot
    />
  </VTab>
</template>

<script>
  import { hasSlot } from '@h4h/utils';

  import { VTab } from 'vuetify/lib';
  import { Badge } from '@h4h/badge';
  import { ComponentStyle } from '@h4h/theme';

  import styles from './tab.scss';
  import { TabIndexSubscriberMixin } from '@h4h/tab-index-manager';

  export default {
    name: 'H4hTab',

    components: {
      VTab,
      Badge
    },

    mixins: [
      TabIndexSubscriberMixin
    ],

    props: {
      disabled: Boolean,
      title: String,
      indicatorRed: Boolean,
      indicatorGreen: Boolean,
      to: Object
    },

    data() {
      return {
        ComponentStyle,
        styles
      };
    },

    computed: {
      showIndicator() {
        return this.$props.indicatorRed || this.$props.indicatorGreen;
      },

      indicatorColor() {
        const { indicatorRed, indicatorGreen } = this.$props;

        switch (true) {
          case indicatorRed:
            return ComponentStyle.Error;
          case indicatorGreen:
            return ComponentStyle.Success;
          default:
            return null;
        }
      }
    },

    methods: {
      hasSlot,
      onChange(value) {
        this.$emit('change', value);
      },

      onFocus($event) {
        this.$emit('focus', $event);
        this.tabIndexSubscriber__onFocus();
      }
    }
  };
</script>
