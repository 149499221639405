export class LocalTimeModel {
  /**
   * BloodPressure config
   *
   * @param {Object}  config
   * @param {Number}  config.hour
   * @param {Number}  config.minute
   * @param {Number}  config.nano
   * @param {Number}  config.second
   */
  constructor(config) {
    this.hour = config.hour;
    this.minute = config.minute;
    this.nano = config.nano;
    this.second = config.second;
  }
}
