export class HeaderAction {
  /**
   * @param {Object}    config
   * @param {String}    config.icon
   * @param {String}    config.title
   * @param {Function}  config.action
   * @param {String}    config.customClass
   * @param {String}    config.tooltipText

   */
  constructor(config) {
    const { icon, title, action, customClass, tooltipText } = config;

    if (!icon || !title || !action) {
      throw new Error(`Invalid HeaderAction config - ${ JSON.stringify(config) }`);
    }

    this.icon = icon;
    this.label = title;
    this.action = action;
    this.customClass = customClass;
    this.tooltipText = tooltipText;
  }
}
