import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';
import { without } from 'lodash';

export const mutations = {
  [types.INIT_FORM](state, { observationTemplate, terminologyTypes }) {
    if (!observationTemplate) {
      state.observationTemplate = null;
      state.definitions = [];
      state.inputs = [];
      state.inputsMap = {};
      return;
    }
    state.observationTemplate = observationTemplate;
    state.definitions = observationTemplate.definitions;
    state.inputs = getInputsConfig(observationTemplate, terminologyTypes);
    state.inputsMap = getInputsMap(state.inputs);
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.ADD_OBSERVATION_TEMPLATE_DEFINITION](state, definition) {
    state.definitions.push(definition);
  },

  [types.REMOVE_DEFINITION](state, definition) {
    state.definitions = without(state.definitions, definition);
  },
};

function getInputsConfig(observationTemplate, terminologyTypes) {
  const name = createTextInputConfig(observationTemplate.name, 'name', 'common.name', InputType.Text);
  let options = createTerminologyInputTypeOptions(terminologyTypes);
  const terminologyType = createInputConfig({
    id: 'terminologyType',
    label: 'observationTemplates.terminologyType',
    type: InputType.Typeahead,
    required: true,
    options,
    value: options.find(option => option.value === observationTemplate.terminologyType),
    formatter: i => i ? i.label : i
  });

  return [
    name,
    terminologyType,
  ];
}

function createTextInputConfig(value, id, label, inputType) {
  return createInputConfig({
    id,
    type: inputType,
    value,
    label,
    required: true,
  });
}

function createTerminologyInputTypeOptions(terminologyTypes) {
  return terminologyTypes.map(type => ({
    label: type.translation,
    value: type.key
  }));
}

