export class PopupTab {

  /**
   * PopupTab config
   *
   * @param {Object}     config
   * @param {Number}     config.id
   * @param {String}     config.title
   * @param {Boolean}    config.disabled
   * @param {Object}     config.props
   * @param {String}     config.tabGroupId
   */
  constructor(config) {
    // Will be emited on tabChange event
    this.id = config.id;
    // Translation key. The text which will be displayed in the tab
    this.title = config.title;
    // The name of the component that should be rendered (PopupName.js)
    this.name = config.name;
    // Should the tab be enabled/disabled
    this.disabled = config.disabled || false;
    // props for the PopupContent component
    this.props = config.props;
    // needed to enable tab index manager
    this.tabGroupId = config.tabGroupId;
  }
}
