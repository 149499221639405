/* global TASK_API_URL */

import { CrudService } from '../../../classes/crudService';
import { PageModel } from '../../../model/page';
import { LogisticTaskModel } from '../../../model/logistic/logsiticTaskModel';
import { getHeadersXTenantId } from '../../../utils/getHeaders';

export const pageableLogisticTasks = new CrudService(
  '/logistic/tasks',
  data => new PageModel(data, config => new LogisticTaskModel(config)),
  data => data,
  TASK_API_URL
);

export function handleLogisticTask(taskUuid, tenantId, paramsCustomization) {
  const action = 'sendDevice';
  const params = {
    action,
    ...paramsCustomization
  };
  const config = {
    ...getHeadersXTenantId(tenantId),
    params,
  };
  return new CrudService(
    '/logistic/tasks',
    data => data,
    data => data,
    TASK_API_URL
  ).update(taskUuid, {}, config);
}

