import { CrudService } from '../../../../classes/crudService';
import { MedicationModel } from '../../../../model/simpleAnamnesis/medicationModel';

export const medications = patientUserUuid => new CrudService(
  `patients/${ patientUserUuid }/medications`,
  data => new MedicationModel(data)
);

export const delMedication = new CrudService(
  'patients/medications',
);

export const editMedication = new CrudService(
  'patients/medications',
  data => new MedicationModel(data)
);
