/* global BASE_PATH */
export function getBasePath() {
  let basePath = BASE_PATH.trim();
  if (!basePath.endsWith('/')) {
    basePath += '/';
  }
  if (!basePath.startsWith('/')) {
    basePath = '/' + basePath;
  }
  return basePath;
}
