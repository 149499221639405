import { productLabel } from './productLabel';
import { udiDi } from './udi-di';

export const certification = {
  namespaced: true,
  modules: {
    productLabel,
    udiDi,
  }
};
