export const state = {
  loading: false,
  reason: null,

  // Popup - stop enrollment
  enrollments: null,
  popupId: null,

  endDateInput: null,
  reasonInput: null,
  stopEnrollmentReasonsInput: null,
};
