import { types } from './types';
import { popups } from '../../../../services/popups';
import { PopupName } from '../../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { questionnaireTemplates } from '../../../../services/api/questionnaire/questionnaireTemplates';
import { showRestError } from '../../../../utils/errors';

export const actions = Object.freeze({
  showChooseTemplatePopup,
  close,
  setPristine,
});

async function showChooseTemplatePopup({ commit }) {
  commit(types.INIT_FORM, null);
  const { id } = await popups.show({
    name: PopupName.ChooseQuestionnaireTemplateForAnamnesis,
    type: PopupType.Modal
  });
  commit(types.SET_POPUP_ID, id);

  commit(types.SET_LOADING, true);
  const result = await questionnaireTemplates.fetch();
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.INIT_FORM, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchQuestionnaireTemplates');
  }
}

async function close({ state }) {
  popups.hide({ id: state.popupId });
}

async function setPristine({ commit }) {
  commit(types.SET_PRISTINE, false);
}

