export const labResultGroups = {
  addLabResultGroup: 'Labresultaat groep toevoegen',
  labResultGroups: 'Labresultaat groepen',
  templates: 'Sjablonen',
  addTemplate: 'Voeg sjablonen toe',
  addObservationTemplate: 'Observatie sjabloon toevoegen',
  deleteLabResultGroup: 'Labresultaten groep verwijderen',
  name: 'Naam groep',
  deleteLabResultGroupMessage: 'Weet u zeker dat u deze labresultaat groep wilt verwijderen?',
  observationTemplate: 'Observatie sjabloon',
  terminologyType: 'Type terminologie'
};
