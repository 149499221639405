export class TreatmentAdviceModel {

  /**
   * TreatmentAdvice config
   *
   * @param {Object}    config
   * @param {String}    config.uuid
   * @param {String}    config.template
   * @param {String}    config.createdOn
   * @param {Boolean}   config.hasFeedback
   */
  constructor(config) {
    config = config || {};

    this.uuid = config.uuid;
    this.template = config.template;
    this.createdOn = config.createdOn;
    this.hasFeedback = config.hasFeedback;
  }
}
