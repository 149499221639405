export const PractitionerTasksType = Object.freeze({
  INFORMATION: 'INFORMATION',
  OBSERVATION: 'OBSERVATION',
  QUESTIONNAIRE: 'QUESTIONNAIRE'
});

export const PractitionerTasksHandlingType = Object.freeze({
  DONE_NOTHING: 'DONE_NOTHING',
  CONTACTED_PATIENT: 'CONTACTED_PATIENT'
});

export const practitionerTasksHandlingTypeOptions = [
  { label: 'tasks.handlePopupContactedPatientOption', value: PractitionerTasksHandlingType.CONTACTED_PATIENT },
  { label: 'tasks.handlePopupDoneNothingOption', value: PractitionerTasksHandlingType.DONE_NOTHING },
];

export const PractitionerTasksHandlingStatus = Object.freeze({
  HANDLED: 'HANDLED',
  UNHANDLED: 'UNHANDLED'
});

export const practitionerTasksHandlingStatusOptions = Object.keys(PractitionerTasksHandlingStatus).map(status => ({
  label: `tasks.${ status.toLowerCase() }`,
  value: status
}));

