import { types } from './types';
import { DeviceVendor } from '../../../constants/deviceVendor';
import { ProgramVendorModel } from '../../../model/programVendorModel';

export const mutations = {
  [types.INIT_POPUP](state, { popupId, existsSupportedVendors }) {
    state.popupId = popupId;
    state.vendors = Object.keys(DeviceVendor)
      .filter(vendor => !existsSupportedVendors.find(vendorModel => vendorModel.vendor === vendor))
      .map(vendor => new ProgramVendorModel({ vendor }));
  },
};

