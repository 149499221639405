import { popups } from '../../../services/popups';
import { PopupType } from '@h4h/popups';
import { PopupName } from '../../../constants/popupName';
import { types } from './types';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import {
  forwardTaskEmailTemplates,
  forwardTaskEmailTemplatesCallCenter,
  forwardTasks,
  forwardTasksCallCenter
} from '../../../services/api/practitionerTasks/forward/forwardTasks';
import { getHeadersXTenantId } from '../../../utils/getHeaders';
import { isCallCenter } from '../../../services/auth/auth';
import { showRestError } from '../../../utils/errors';
import { tenantAddress } from '../../../services/api/tenantAddress/tenantAddress';

export const actions = Object.freeze({
  showForwardTaskPopup,
  cancel,
  forwardTask,
  updateActivePhysician,
  fetchCallCenterBaseUrl,
  initDialogAfterShow,
});

async function initDialogAfterShow({ commit }, { organizationSetting }) {
  commit(types.SET_ORGANIZATION_SETTING, organizationSetting);
}

async function showForwardTaskPopup({ commit }, { tasks }) {
  let task = tasks[0];
  if (tasks.length > 1) {
    const { id, closed } = await popups.show({
      type: PopupType.Modal,
      name: PopupName.ChooseTask,
      props: { tasks }
    });
    commit(types.SET_CHOOSE_TASK_POPUP_ID, id);
    const result = await closed;
    if (result.success && result.data) {
      task = result.data;
    }
    else {
      return { success: false };
    }
  }

  commit(types.SET_PHYSICIAN, task.patient?.responsiblePhysicianId);
  commit(types.INIT_POPUP);
  const { id, closed } = await popups.show({
    type: PopupType.Modal,
    name: PopupName.ForwardTask,
    props: { task }
  });
  commit(types.SET_POPUP_ID, id);

  const emailTemplate = await fetchForwardEmailTemplate(task.tenantId);
  commit(types.UPDATE_EMAIL_TEMPLATE, emailTemplate);
  let result = await closed;
  if (result.success && result.data) {
    return result.data;
  }
  return { success: false };
}

async function updateActivePhysician({ commit }, physicianId) {
  commit(types.SET_PHYSICIAN, physicianId);
}

async function cancel({ state }) {
  popups.hide({ id: state.popupId });
}

async function fetchForwardEmailTemplate(tenantId) {
  let emailTemplateResponse;
  if (isCallCenter()) {
    let config = getHeadersXTenantId(tenantId);
    emailTemplateResponse = await forwardTaskEmailTemplatesCallCenter.fetchOne(config);
  }
  else {
    emailTemplateResponse = await forwardTaskEmailTemplates.fetchOne();
  }
  if (!emailTemplateResponse.success) {
    return toast.error(localize('messages.cantFetchEmailTemplate'), emailTemplateResponse.error);
  }
  return emailTemplateResponse.data;
}

async function fetchCallCenterBaseUrl({ commit }) {
  const callCenterTenantAddress = await tenantAddress.fetchOne({ params: { isCallCenter: true } });
  if (callCenterTenantAddress.success) {
    commit(types.SET_CALL_CENTER_BASE_URL, callCenterTenantAddress.data.baseUrl);
  }
  else {
    showRestError(callCenterTenantAddress.error, 'messages.cantFetchCallCenterTenantAddress');
  }
}

async function forwardTask({ state, commit }, data) {
  let isCallCenterCallFlag = isCallCenter();
  let model = {
    link: data.link,
    userUuid: state.physicianId,
    tenantId: data.task.tenantId,
    isCallCenterCallFlag: isCallCenterCallFlag,
  };
  let forwardTaskResponse;
  commit(types.SET_FORWARDING_IN_PROGRESS, true);
  if (isCallCenterCallFlag) {
    const config = getHeadersXTenantId(data.task.tenantId);
    forwardTaskResponse = await forwardTasksCallCenter(data.task.universalUuid).create(model, config);
  }
  else {
    forwardTaskResponse = await forwardTasks(data.task.id).create(model);
  }
  commit(types.SET_FORWARDING_IN_PROGRESS, false);

  if (!forwardTaskResponse.success) {
    return toast.error(localize('messages.cantForwardTask'), forwardTaskResponse.error);
  }
  else {
    toast.success(localize('messages.taskIsForwarded'));
  }
  popups.hide({
    id: state.popupId,
    result: {
      task: data.task,
      success: forwardTaskResponse.success,
    },
  });
  return forwardTaskResponse.success;
}
