export const types = Object.freeze({
  SET_LOADING: 'SET_LOADING',
  SET_PATIENT: 'SET_PATIENT',
  SET_ENROLLMENT: 'SET_ENROLLMENT',
  INIT_PAGE: 'INIT_PAGE',
  SET_PAGE_INITIALIZED: 'SET_PAGE_INITIALIZED',
  SET_OBSERVATION_TEMPLATES: 'SET_OBSERVATION_TEMPLATES',
  SET_QUESTIONNAIRE_TEMPLATES: 'SET_QUESTIONNAIRE_TEMPLATES',
  SET_TREATMENT_ADVICE_POPUP_ID: 'SET_TREATMENT_ADVICE_POPUP_ID',
  SET_TREATMENT_ADVICE: 'SET_TREATMENT_ADVICE',
  INIT_TREATMENT_ADVICE_AGREED_POPUP: 'INIT_TREATMENT_ADVICE_AGREED_POPUP',
  INIT_POPUP: 'INIT_POPUP',
  SET_CRON_MODEL: 'SET_CRON_MODEL',
});
