export const getters = {
  isValid,
  singleObservation,
  observationIsValidButNotInTheCurrentPeriod,
};

function isValid(state) {
  return state.date && state.date.valid &&
    state.inputGroups &&
    state.inputGroups.every(group => group.inputs.every(i => i.valid));
}

function singleObservation(state) {
  return state.inputGroups.length < 2;
}

function observationIsValidButNotInTheCurrentPeriod(state) {
  const monitoringPeriod = state.enrollment?.monitoringPeriod;
  const date = state.date?.value;
  if (state.date?.valid && monitoringPeriod && date) {
    return !monitoringPeriod.containsDate(date);
  }
  return false;
}

