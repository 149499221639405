import { types } from './types';
import { currentLanguage } from '../../../../services/api/i18n/currentLanguage';
import { supportedLanguages } from '../../../../services/api/i18n/supportedLanguages';
import { InitOptionsModel } from './models';
import { showRestError } from '../../../../utils/errors';
import { toast } from '../../../../services/toast';
import { localize } from '../../../../services/localize';

export const actions = Object.freeze({
  init,
  save,
});

async function init({ commit }) {
  commit(types.SET_LOADING, true);
  commit(types.INIT_FORM, null);
  try {
    const supportedLanguagesResult = await supportedLanguages.fetchOne();
    if (!supportedLanguagesResult.success) {
      showRestError(supportedLanguagesResult.error, 'messages.cantFetchSupportedLanguages');
      return;
    }
    const result = await currentLanguage.fetchOne();
    if (result.success) {
      commit(types.INIT_FORM, new InitOptionsModel({
        currentLanguage: result.data,
        supportedLanguages: supportedLanguagesResult.data
      }));
    }
    else {
      showRestError(result.error, 'messages.cantFetchCurrentLanguage');
    }
  }
  finally {
    commit(types.SET_LOADING, false);
  }
}

async function save({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid || state.loading) {
    return false;
  }
  const language = state.inputs[0].value;

  commit(types.SET_LOADING, true);
  const result = await currentLanguage.updateOne({}, {
    params: { language },
  });
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.currentLanguageUpdated'));
  }
  else {
    showRestError(result.error, 'messages.cantUpdateCurrentLanguage');
  }
  return result.success;
}

