export const types = Object.freeze({
  INIT_DIALOG: 'INIT_DIALOG',
  SET_POPUP_ID: 'SET_POPUP_ID',
  SET_INPUT_VALUE: 'SET_INPUT_VALUE',
  SET_PRISTINE: 'SET_PRISTINE',
  RESET_THRESHOLDS: 'RESET_THRESHOLDS',
  SET_SHOW_DELETED_THRESHOLDS: 'SET_SHOW_DELETED_THRESHOLDS',
  ADD_MISSING_ITEMS: 'ADD_MISSING_ITEMS',
  DELETE_ADDED_ITEM: 'DELETE_ADDED_ITEM',
  SET_ITEM_DELETE_STATE: 'SET_ITEM_DELETE_STATE',
  INPUT_STATE_CHANGED: 'INPUT_STATE_CHANGED',
});
