import { types } from './types';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },
  [types.SET_UDI_DI](state, udiDi) {
    state.udiDi = udiDi;
  },
};

