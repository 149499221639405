import { PhysicianModel } from '../../../../model/orgUserModel';

export const getters = {
  responsiblePhysician
};

export function responsiblePhysician(state) {
  if (state.responsiblePhysician) {
    return new PhysicianModel(state.responsiblePhysician);
  }
}

