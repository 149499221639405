import { createInputConfig, InputType, PhoneModel, PhoneTypePerson, validatePhoneInput } from '@h4h/inputs';
import { localize } from '../../services/localize';
import { getCountriesWithCallCodes } from '../../store/countries/callcodes/countriesWithCallCodes';

const NLCallCode = '31';
const SRCallCode = '381';
const otherCountryCode = 'other';
export const NLNumberWithoutCodeRegexp = /^0?(61|62|63|64|65|68)[0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/;
export const SRNumberWithoutCodeRegexp = /^0?(60|61|62|63|64|65|66|68|69|677|678)\d{5,9}$/;
export const CommonNumberWithoutCodeRegexp = /^[0-9]{8,12}$/;
export const NL = 'NL';
export const SR = 'RS';

function getEmptyPhone() {
  return new PhoneModel({
    primary: true,
    number: '',
    countryCode: NL,
    type: PhoneTypePerson.Mobile,
  });
}

function looksLikeNlNumber(number) {
  if (number) {
    return number.indexOf('31') === 0;
  }
  return false;
}

export function getInvalidShowInvalidValueExclamationForPhoneInput(input, valid) {
  if (input.type !== InputType.Phones) {
    return null;
  }
  if (valid) {
    return null;
  }
  if (!input.value?.length) {
    return null;
  }
  let phone = input.value[0];
  if (phone.countryCode === NL) {
    return null;
  }
  if (!CommonNumberWithoutCodeRegexp.test(phone.number)) {
    return null;
  }
  if (looksLikeNlNumber(phone.number)) {
    return localize('phone.pleaseChooseNL');
  }
  return null;
}

export function createPhoneValues(value) {
  if (!value) {
    return [getEmptyPhone()];
  }

  function tryParsePhone(value, { callCode, code2, additionalPrefix }) {
    if (value.indexOf(callCode) === 0) {
      let prefix = additionalPrefix || '';
      return new PhoneModel({
        primary: true,
        number: prefix + value.substring(callCode.length),
        countryCode: code2,
        type: PhoneTypePerson.Mobile,
      });
    }
    if (value.indexOf('+') === 0) {
      return tryParsePhone(value.substring(1), { callCode, code2, additionalPrefix });
    }
    return null;
  }

  const patterns = [
    { callCode: NLCallCode, code2: NL },
    { callCode: '+' + NLCallCode, code2: NL },
    { callCode: '06', code2: NL, additionalPrefix: '6' },
    { callCode: '+' + '06', code2: NL, additionalPrefix: '6' },
    { callCode: SRCallCode, code2: SR },
    { callCode: '+' + SRCallCode, code2: SR },
    ...getCountriesWithCallCodes(),
  ];
  for (let pattern of patterns) {
    let phone = tryParsePhone(value, pattern);
    if (phone) {
      return [phone];
    }
  }
  if (value.indexOf('+') === 0) {
    value = value.substring(1);
  }
  return [new PhoneModel({
    primary: true,
    number: value,
    countryCode: otherCountryCode,
    type: PhoneTypePerson.Mobile,
  })];
}

/**
 *
 * @param {Object} object
 * @param {String[]} fields
 */
export function convertPhonesToStrings(object, fields) {
  for (let field of fields) {
    object[field] = convertPhonesToString(object[field]);
  }
}

function convertPhonesToString(phones) {
  if (!Array.isArray(phones)) {
    return null;
  }
  if (!phones.length) {
    return null;
  }
  const phone = phones[0];
  if (!phone.number) {
    return null;
  }
  let countryModel = getCountriesWithCallCodes().find(country => country.code2 === phone.countryCode);
  if (!countryModel) {
    return '+' + phone.number;
  }
  return '+' + countryModel.callCode + phone.number;
}

function isValidPhoneNumber(countryCode, phoneWithoutCode) {
  if (!(countryCode && phoneWithoutCode)) {
    return false;
  }
  if (countryCode === NL) {
    return NLNumberWithoutCodeRegexp.test(phoneWithoutCode);
  }
  if (countryCode === SR) {
    return SRNumberWithoutCodeRegexp.test(phoneWithoutCode);
  }
  return CommonNumberWithoutCodeRegexp.test(phoneWithoutCode) && !looksLikeNlNumber(phoneWithoutCode);
}

function validateEhPhoneInput(input) {
  input.validationMessage = null;
  if (validatePhoneInput(input)) {
    const value = input.value[0];
    let valid = isValidPhoneNumber(value?.countryCode, value?.number) && isValidCountryCode(value?.countryCode, input.onlyStandardCountries);
    input.validationMessage = getInvalidShowInvalidValueExclamationForPhoneInput(input, valid);
    return valid;
  }
  return false;
}

function isValidCountryCode(countryCode, onlyStandardCountries) {
  if (onlyStandardCountries) {
    return countryCode === SR || countryCode === NL;
  }
  return true;
}

export function createPhoneInputConfig(phone = null, id, label, required, additions = null) {
  const value = createPhoneValues(phone);
  const inputConfig = createInputConfig({
    id,
    type: InputType.Phones,
    value,
    required,
    label,
    defaultCountryCode: NL,
    countryOptions: getCountriesWithCallCodes(),
    multiple: false,
    phonesLimit: 1,
    phoneEnum: PhoneTypePerson,
    showPhoneType: false,
    validate: validateEhPhoneInput,
    validatePhoneNumber,
    ...additions,
  });

  function validatePhoneNumber(input, index) {
    const phone = inputConfig.value[index];
    return isValidPhoneNumber(phone.countryCode, input.value, input) && isValidCountryCode(phone?.countryCode, additions?.onlyStandardCountries);
  }

  return inputConfig;
}

