import moment from 'moment';
import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig, getInputsMap, validateTextInput } from '@h4h/inputs';
import { EhPatterns } from '../../../utils/ehPatterns';
import { genderOptions } from '../../../constants/gender';
import { EhUserFieldsLengthConstraints } from '../../../utils/ehUserFieldsLengthConstraints';
import { createEhDateInputConfig } from '../../../utils/factories';
import { createAddressInputConfig } from '../../../utils/addressComponentUtils';
import { createPhoneInputConfig } from '../../../utils/phone/phoneComponentUtils';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.CLEAR_INPUTS](state) {
    state.inputs = null;
  },

  [types.INIT_FORM](state, { countries }) {
    const inputs = getInputsConfig(countries);

    state.inputs = inputs;
    state.inputsMap = getInputsMap(inputs);
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => {
      input.pristine = value;
    });
  },
};

function getInputsConfig(countries) {
  const emailId = 'email';
  const emailAgainId = 'emailAgain';

  const firstName = createTextInputConfig('firstName', 'common.firstName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.firstName);
  const lastName = createTextInputConfig('lastName', 'common.lastName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, true, EhUserFieldsLengthConstraints.lastName);
  const phone = createPhoneInputConfig(null, 'phone', 'common.telephoneNumber', true);
  const externalPatientId = createTextInputConfig('externalPatientId', 'common.hisPatientId', InputType.Text, null, true, EhUserFieldsLengthConstraints.externalPatientId);
  const patientNumber = createTextInputConfig('patientNumber', 'patient.patientNumber', InputType.Text, null, false, EhUserFieldsLengthConstraints.patientNumber);
  const email = createTextInputConfig(emailId, 'common.email', InputType.Text, EhPatterns.email, true, EhUserFieldsLengthConstraints.email);
  const emailAgain = {
    ...createTextInputConfig(emailAgainId, 'common.emailAgain', InputType.Text, EhPatterns.email, true, EhUserFieldsLengthConstraints.email),
    validate(input) {
      return validateTextInput(input) && email.value === input.value;
    },
    getStatePatch() {
      return { fakeFieldForUpdate: Math.random() };
    }
  };
  email.linkedInputs = [emailAgain];
  const middleName = createTextInputConfig('middleName', 'common.middleName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.middleName);
  const initials = createTextInputConfig('initials', 'common.initials', InputType.Text, EhPatterns.lettersDotsOnly, false, EhUserFieldsLengthConstraints.initials);
  const partnerName = createTextInputConfig('partnerName', 'common.partnerName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.partnerName);
  const partnerMiddleName = createTextInputConfig('partnerMiddleName', 'common.partnerMiddleName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.partnerMiddleName);

  const dateOfBirth = createEhDateInputConfig({
    id: 'dateOfBirth',
    required: true,
    label: 'common.dateOfBirth',
    validate(input) {
      return moment(input.value).isSameOrBefore(new Date());
    }
  });

  const gender = createInputConfig({
    id: 'gender',
    type: InputType.Select2,
    clearable: false,
    required: true,
    label: 'gender.gender',
    options: genderOptions,
  });
  const address = createAddressInputConfig(null, countries, 'address', 'common.postalAddress', false);

  return [
    externalPatientId,
    patientNumber,
    initials,
    firstName,
    middleName,
    lastName,
    partnerMiddleName,
    partnerName,
    dateOfBirth,
    gender,
    email,
    emailAgain,
    phone,
    address,
  ];
}

function createTextInputConfig(id, label, inputType, pattern, required = false, constraints) {
  return createInputConfig({
    id,
    type: inputType,
    pattern,
    required,
    label,
    ...constraints,
  });
}

