import { types } from './types';
import { showRestError } from '../../../utils/errors';
import {
  observationTemplates,
  observationTemplateUpdatable
} from '../../../services/api/observations/observationTemplates';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import { router } from '../../../services/router';
import { Page } from '../../../constants/page';
import { mapValues } from 'lodash';
import { observationTemplatesActions } from '../contants';

export const actions = Object.freeze({
  initForm,
  save,
  addObservationTemplateDefinition,
  removeDefinition,
});

async function initForm({ commit }, { uuid, terminologyTypes }) {
  commit(types.SET_LOADING, true);
  commit(types.INIT_FORM, { observationTemplate: null, terminologyTypes });
  try {
    let updatable = await observationTemplateUpdatable(uuid);
    if (updatable.success) {
      if (!updatable.data.updatable) {
        toast.error(localize('messages.observationTemplateIsNotUpdatable'));
        router.push({
          name: Page.AdminObservationTemplatesView,
          uuid
        });
        return;
      }
    }
    else {
      showRestError(updatable.error, 'messages.cantFetchObservationTemplateInfo');
      return;
    }
    let observationTemplate = await observationTemplates.get(uuid);
    if (observationTemplate.success) {
      commit(types.INIT_FORM, { observationTemplate: observationTemplate.data, terminologyTypes });
    }
    else {
      showRestError(observationTemplate.error, 'messages.cantFetchObservationTemplate');
    }
  }
  finally {
    commit(types.SET_LOADING, false);
  }
}

async function removeDefinition({ commit }, definition) {
  commit(types.REMOVE_DEFINITION, definition);
}

async function addObservationTemplateDefinition({ commit }, definition) {
  commit(types.ADD_OBSERVATION_TEMPLATE_DEFINITION, definition);
}

async function save({ state, commit, getters, dispatch }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid || state.loading) {
    return false;
  }
  const uuid = state.observationTemplate.uuid;

  const observationTemplate = {
    ...this.observationTemplate,
    ...mapValues(state.inputsMap, i => i.value),
    terminologyType: state.inputsMap.terminologyType.value.value,
    definitions: state.definitions,
  };

  commit(types.SET_LOADING, true);
  const result = await observationTemplates.update(uuid, observationTemplate);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.observationTemplateSaved'));
    dispatch(observationTemplatesActions.ReloadObservationTemplates, {}, { root: true });
    router.push({
      name: Page.AdminObservationTemplatesView,
      uuid: uuid,
    });
  }
  else {
    showRestError(result.error, 'messages.cantUpdateObservationTemplate');
  }
  return result.success;
}

