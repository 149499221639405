import { types } from './types';
import { observations } from '../../../../../../services/api/observations/observations';
import { showRestError } from '../../../../../../utils/errors';

export const actions = Object.freeze({
  initPage,
});

async function initPage({ commit }, { observationUuid, cleanBeforeExecute }) {
  if (cleanBeforeExecute) {
    commit(types.INIT_PAGE, null);
  }

  if (this.observation?.uuid === observationUuid) {
    return;
  }

  const result = await observations.get(observationUuid);
  if (result.success) {
    commit(types.INIT_PAGE, result.data);
  }
  else {
    commit(types.INIT_PAGE, null);
    showRestError(result.error, 'messages.cantFetchObservation');
  }
}
