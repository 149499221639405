import { InputMutations } from './mutations';
import { setInputLoadingAction, setInputValueAction, updateInputAction, updateLinkedInputsAction } from './actions';

/**
 * @typedef {Object} StoreConfig
 *
 * @property {Object} state
 * @property {Object} actions
 * @property {Object} mutations
 *
 * @property {Object} [getters]
 * @property {Object} [plugins]
 * @property {Object} [modules]
 */

/**
 * extends store config with default input actions and mutations
 *
 * @param {StoreConfig} storeConfig - Vuex store config
 * @returns {StoreConfig}
 */
export function withInputs(storeConfig) {
  const { actions, mutations } = storeConfig;

  return {
    ...storeConfig,

    actions: {
      updateLinkedInputs: updateLinkedInputsAction,
      setInputLoading: setInputLoadingAction,
      setInputValue: setInputValueAction,
      updateInput: updateInputAction,
      ...actions
    },

    mutations: {
      ...InputMutations,
      ...mutations
    }
  };
}
