<template>
  <div
    ref="container"
    :class="styles.container"
    @dragenter.stop.prevent="setActive"
    @dragover.stop.prevent="setActive"
    @dragleave.stop.prevent="setInactive"
  >
    <div :class="overlayClass">
      <div
        v-for="(action, index) in actions"
        :key="index"
        @click="$emit('click', $event)"
      >
        <Dropzone
          v-bind="action"
          :resetPadding="overlay"
          @dropFinish="onDrop"
          @click="action.clickAction"
          @dropContent="action.dropContentAction"
          @dropElement="action.dropElementAction"
        />
      </div>
    </div>

    <div :class="contentClass">
      <slot/>
    </div>
  </div>
</template>

<script>
  import { noop } from 'lodash';

  import { ArrayProp } from '@h4h/classes';

  import { inputProps } from '../../utils';

  import Dropzone from '../dropzone/Dropzone';
  import { DropzoneAction } from '../../classes/dropzoneAction';
  import { DropzoneMixins } from '../dropzone/dropzoneMixins';

  import styles from './dropzones.scss';

  export default {
    name: 'H4hDropzone',

    components: {
      Dropzone
    },

    mixins: [
      DropzoneMixins,
    ],

    props: {
      actions: new ArrayProp({
        type: DropzoneAction,
        required: true
      }),

      overlay: inputProps.booleanTrue
    },

    data() {
      return {
        noop,
        styles,
        active: false,
        showZones: false,
      };
    },

    computed: {
      overlayClass() {
        const classes = [styles.overlay];
        if (this.active) {
          classes.push(styles.active);
        }
        if (this.showZones) {
          classes.push(styles.visible);
        }
        return classes;
      },
      contentClass() {
        return (this.active || this.showZones) ? styles.transparent : null;
      }
    },

    methods: {
      onDrop() {
        this.active = false;
        this.showZones = false;
      }
    }
  };
</script>
