export const messages = {
  cantObtainTenantInfo: 'Can\'t obtain tenant info',
  cantFetchQuestionnaire: 'Can\'t fetch questionnaire',
  questionnaireUpdated: 'Questionnaire updated',
  cantUpdateQuestionnaire: 'Can\'t update questionnaire',
  cantCreateQuestionnaire: 'Can\'t create questionnaire',
  cantFetchAnamnesis: 'Can\'t fetch anamnesis',
  cantFetchAnamnesisSettings: 'Can\'t fetch anamnesis settings',
  anamnesisUpdated: 'Anamnesis updated',
  cantUpdateAnamnesis: 'Can\'t update anamnesis',
  cantFetchProfile: 'Can\'t fetch profile',
  cantFetchUserPreferences: 'Can\'t fetch user preferences',
  cantUpdateUserPreferences: 'Can\'t update user preferences',
  cantFetchAvatar: 'Can\'t fetch avatar',
  cantUpdateProfile: 'Can\'t update profile',
  profileUpdated: 'Profile updated',
  patientDetailsUpdated: 'Patient details updated',
  cantUpdatePatientDetails: 'Can\'t update patient details',
  cantCreatePatient: 'Can\'t create patient',
  cantLoadPatient: 'Can\'t load patient. Patient is archived or the profile can not be found.',
  cantFetchOrganizationSetting: 'Can\'t fetch organization setting',
  cantFetchTimezones: 'Can\'t fetch timezones',
  organizationSettingUpdated: 'Organization setting updated',
  callCenterSuccessfullyDisabled: 'Call-center disabled successfully',
  callCenterSuccessfullyEnabled: 'Call-center enabled successfully',
  cantDisableCallCenter: 'Can\'t disable call-center',
  cantEnableCallCenter: 'Can\'t enable call-center',
  cantCreateOrganizationSetting: 'Can\'t create organization setting',
  cantFetchObservation: 'Can\'t fetch observation',
  cantCreateObservation: 'Can\'t create observation',
  someOfObservationsWereNotSavedCorrectly: 'Sommige observaties zijn niet correct opgeslagen',
  observationSaved: 'Observation saved',
  cantFetchObservationTemplates: 'Can\'t fetch observation templates',
  cantFetchObservationTemplate: 'Can\'t fetch observation template',
  cantFetchObservationTemplateInfo: 'Can\'t fetch observation template info',
  cantCreateObservationTemplate: 'Can\'t create observation template',
  cantUpdateObservationTemplate: 'Can\'t update observation template',
  observationTemplateSaved: 'Observation template saved',
  observationTemplateIsNotUpdatable: 'Observation template cannot be updated',
  cantFetchQuestionnaireTemplates: 'Can\'t fetch questionnaire templates',
  cantFetchQuestionnaireTemplate: 'Can\'t fetch questionnaire template',
  cantCreateQuestionnaireTemplate: 'Can\'t create questionnaire template',
  questionnaireTemplateCreated: 'Questionnaire template created',
  questionnaireTemplateUpdated: 'Questionnaire template updated',
  questionnaireTemplateIsNotUpdatable: 'Questionnaire template is not updatable',
  cantFetchQuestionnaireTemplateInfo: 'Can\'t fetch questionnaire template info',
  cantDeleteGroups: 'Can\'t delete groups',
  cantFetchGroups: 'Can\'t fetch groups',
  medicalHistoryDeleted: 'Medical history deleted',
  medicalHistoryUpdated: 'Medical history updated',
  cantDeleteMedicalHistory: 'Can\'t delete medical history',
  cantUpdateMedicalHistory: 'Can\'t update medical history',
  cantCreateMedicalHistory: 'Can\'t create a medical history',
  cantFetchMedicalHistories: 'Can\'t fetch medical histories',
  allergyDeleted: 'Allergy deleted',
  allergyUpdated: 'Allergy updated',
  cantUpdateAllergy: 'Can\'t update allergy',
  cantDeleteAllergy: 'Can\'t delete allergy',
  cantCreateAllergy: 'Can\'t create allergy',
  cantSendTAFeedback: 'Can\'t send treatment advice feedback',
  sentTAFeedback: 'Treatment advice feedback is sent',
  cantFetchAllergies: 'Can\'t fetch allergies',
  medicationDeleted: 'Medication deleted',
  medicationUpdated: 'Medication updated',
  cantUpdateMedication: 'Can\'t update medication',
  cantDeleteMedication: 'Can\'t delete medication',
  cantCreateMedication: 'Can\'t create medication',
  cantFetchMedications: 'Can\'t fetch medications',
  cantFetchPrograms: 'Can\'t fetch programs',
  cantFetchProgram: 'Can\'t fetch program',
  cantFetchProgramEnrollmentsInfo: 'Can\'t fetch program enrollments info',
  programSaved: 'Program saved',
  cantCreateProgram: 'Can\'t create program',
  cantUpdateProgram: 'Can\'t update program',
  cantFetchEnrollments: 'Can\'t fetch enrollments',
  cantFetchEnrollment: 'Can\'t fetch enrollment',
  cantCreateEnrollment: 'Can\'t create enrollments',
  cantFetchPatients: 'Can\'t fetch patients',
  cantFetchOrgUsers: 'Can\'t fetch organization users',
  cantFetchGroupsById: 'Can\'t fetch user groups',
  cantJoinUserToGroup: 'Can\'t join user to group',
  cantExcludeUserFromGroup: 'Can\'t exclude user from group',
  patientSaved: 'Patient saved',
  patientUpdated: 'Patient updated',
  cantUpdatePatient: 'Can\'t update patient',
  cantFetchResponsiblePhysician: 'Can\'t fetch responsible physician',
  cantFetchResponsiblePhysicians: 'Can\'t fetch responsible physicians',
  cantFetchCallCenterSupportUsers: 'Can\'t fetch call-center support users',
  cantFetchOrganizationCallCenterSetting: 'Can\'t fetch organization call-center settings',
  cantFetchCallCenterTenantAddress: 'Can\'t fetch call-center tenant address',
  cantCreateUser: 'Can\'t create user',
  userSaved: 'User saved',
  userGroupsUpdated: 'User groups updated',
  cantFetchLabResult: 'Can\'t fetch lab result',
  labResultAdded: 'Lab result added',
  cantAddLabResult: 'Can\'t add lab result',
  groupSaved: 'Group saved',
  cantCreateGroup: 'Can\'t create group',
  groupUpdated: 'Group updated',
  cantUpdateGroup: 'Can\'t update group',
  groupDeleted: 'Group deleted',
  cantDeleteGroup: 'Can\'t delete group',
  passwordSuccessfullyUpdated: 'Password successfully updated',
  errorUpdatingPassword: 'Error updating password',
  passwordsMustBeTheSame: 'Passwords must be the same',
  passwordMustBeAtLeastXLettersLong: 'Password must be at least {0} letters long',
  passwordResetSuccessfully: 'Password reset successfully',
  errorResetPassword: 'Error reset password',
  errors: {
    userAlreadyExists: 'This email address is already in use',
    patientHisIdAlreadyUsed: 'The Patient ID is already in use. Provide a unique ID to save the patient file.',
    programInputGroupsMismatchException: 'Some observations haven\'t been entered',
  },
  taskHandled: 'Task successfully handled',
  cantHandleTask: 'Failed to fulfill task',
  cantFetchTasks: 'Can\'t fetch tasks',
  cantFetchTerminology: 'Can\'t fetch terminology types',
  cantFetchEcgData: 'Can\'t fetch ecg data',
  successfullySaved: 'Successfully saved',
  cantFetchObservationComments: 'Can\'t fetch notes',
  cantSaveObservationComment: 'Can\'t save note',
  observationCommentSaved: 'Note saved',
  enrollmentsStopped: 'Enrollments stopped',
  cantStopEnrollment: 'Can\'t stop enrollment',
  roleAddedSuccessfully: 'Role added successfully',
  roleRemovedSuccessfully: 'Role removed successfully',
  errorAddRole: 'Can\'t add role',
  errorRemoveRole: 'Can\'t remove role',
  cantFetchEmailTemplate: 'Can\'t fetch email template',
  taskIsForwarded: 'Task is forwarded',
  cantForwardTask: 'Can\'t forward task',
  cantFetchSupportedLanguages: 'Can\'t fetch supported languages',
  cantFetchCurrentLanguage: 'Can\'t fetch current language',
  currentLanguageUpdated: 'Current language updated',
  cantUpdateCurrentLanguage: 'Can\'t update current language',
  cantFetchLabResultGroups: 'Can\'t fetch lab result groups',
  labResultGroupDeleted: 'Lab result group deleted',
  cantDeleteLabResultGroup: 'Can\'t delete lab result group',
  labResultGroupSaved: 'Lab result group saved',
  cantCreateLabResultGroup: 'Can\'t create lab result group',
  cantFetchLabResultGroup: 'Can\'t fetch lab result group',
  cantUpdateLabResultGroup: 'Can\'t update lab result group',
  patientWasArchived: 'Patient was archived',
  cantArchivePatient: 'Can\'t archive patient',
  cantRestorePatient: 'Can\'t restore patient',
  cantUpdateObservationThresholds: 'Can\'t update observation thresholds',
  cantUpdateObservation: 'Can\'t update observation',
  cantUpdateProgramSchedule: 'Can\'t update the program schedule',
  cantFetchConclusions: 'Can\'t fetch health record conclusions',
  patientFileSaved: 'Patient file saved',
  enrollmentSaved: 'Enrollment saved',
  cantFetchProductVersion: 'Can\'t fetch product version',
  cantFetchUdiDi: 'Can\'t fetch UDI/DI',
  cantFetchEnvironmentProperties: 'Can\'t fetch environment properties',
};
