import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';

export const mutations = {

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.INIT_FORM](state, initOptionsModel) {
    state.inputs = getInputsConfig(initOptionsModel);
    state.inputsMap = getInputsMap(state.inputs);
  },
};

/**
 *
 * @param {InitOptionsModel} initOptionsModel
 */
function getInputsConfig(initOptionsModel) {
  if (!initOptionsModel) {
    return [];
  }
  return [createInputConfig({
    id: 'isoCode',
    type: InputType.Label,
    label: 'language.language',
    value: initOptionsModel.currentLanguage,
  })];
}

