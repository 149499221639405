/* global TASK_API_URL */

import { CrudService } from '../../../classes/crudService';
import { PractitionerTaskBatchModel, PractitionerTaskModel } from '../../../model/practitionerTaskModel';
import { PageModel } from '../../../model/page';
import { Severity } from '../../../constants/severity';
import { PractitionerTasksHandlingStatus } from '../../../constants/practitionerTasks';
import { mapArrayCustom } from '../../../utils/mapUtils';

export const pageablePractitionerTasks = new CrudService(
  'practitioner/tasks/pageable',
  data => new PageModel(data, toPractitionerTaskModel),
  data => data,
  TASK_API_URL
);
export const practitionerTasks = new CrudService(
  'practitioner/tasks',
  toPractitionerTaskModel,
  toPractitionerTaskRestEntity,
  TASK_API_URL
);

export const practitionerTasksBatch = new CrudService(
  'practitioner/tasks/batch',
  toPractitionerTaskBathModel,
  toPractitionerTaskBatchRestEntity,
  TASK_API_URL
);

export function toPractitionerTaskModel(data) {
  return !data ? null : new PractitionerTaskModel({
    ...data,
    status: data.handlingStatus || PractitionerTasksHandlingStatus.UNHANDLED,
    handlingType: data.handlingType,
    severity: data.severity || Severity.INFO,
  });
}

export function toPractitionerTaskRestEntity(model) {
  delete model.tenantId;
  return !model ? null : {
    ...model,
    handlingStatus: model.status,
    handlingType: model.handlingType,
  };
}

export function toPractitionerTaskBathModel(data) {
  return new PractitionerTaskBatchModel({
    tasks: mapArrayCustom(data.tasks, data => toPractitionerTaskModel(data))
  });
}

export function toPractitionerTaskBatchRestEntity(model) {
  return {
    tasks: mapArrayCustom(model.tasks, data => toPractitionerTaskRestEntity(data))
  };
}
