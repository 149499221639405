import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import { tempPassword } from './temp-password';
import { withInputs } from '@h4h/inputs';

export const editOrgUserProfile = withInputs({
  namespaced: true,

  state,
  actions,
  getters,
  mutations,
  modules: {
    tempPassword
  }
});
