import { AddressModel } from '@h4h/inputs';
import { CountryModel } from './countryModel';

export class EhAddressModel {
  /**
   * EhAddressModel config
   *
   * @param {Object}    config
   * @param {String}    config.city
   * @param {String}    config.country
   * @param {String}    config.number
   * @param {String}    config.numberExtension
   * @param {String}    config.postCode
   * @param {String}    config.street
   */
  constructor(config) {
    this.city = config.city;
    this.country = config.country;
    this.number = config.number;
    this.numberExtension = config.numberExtension;
    this.postCode = config.postCode;
    this.street = config.street;
  }
}

/**
 *
 * @param {EhAddressModel}address
 * @return {AddressModel}
 */
export function mapEhAddressToMpAddress(address) {
  if (!address) {
    return null;
  }
  return new AddressModel({
    ...address,
    postalCode: address.postCode,
    country: address.country ? new CountryModel({ code2: address.country }) : null
  });
}

/**
 *
 * @param {AddressModel}address
 * @return {EhAddressModel}
 */
export function mapMpAddressToEhAddress(address) {
  if (!address) {
    return null;
  }
  return new EhAddressModel({
    ...address,
    postCode: address.postalCode,
    country: address.country && address.country.code2 ? address.country.code2 : null
  });
}
