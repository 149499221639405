<template>
  <div
    v-if="isShown"
    :class="styles.actions"
  >
    <TableAction
      v-for="(action, index) in matchingActions"
      :key="index"
      :input="action.input"
      :disabled="action.isDisabled"
      :inputAction="action.inputAction"
      :initPopover="action.initPopover"
      :popoverClass="action.popoverClass"
      :popoverContent="action.popoverContent"

      @trigger="onTrigger(action)"
      @initPopover="onInitPopover(action)"
    >
      <template #default="{ onClick }">
        <Button
          text
          primary
          :round="!action.label"
          :data-testid="action.testId"
          :icon="action.icon"
          :label="action.label"
          :class="styles.button"
          :tooltip="action.tooltip"
          @click="onClick"
        />
      </template>
    </TableAction>
  </div>
  <div
    v-else
    :class="styles.noActionAvailableWrapper"
  >
    <Icon
      :class="styles.noBatchIcon"
      type="alert-triangle"
    />
    <span :class="styles.noBatchLabel">{{ $localize($props.noBatchActionsLabel) }}</span>
  </div>
</template>

<script>
  import { Icon } from '@h4h/icons';

  import { BatchAction } from '../../models/BatchAction';

  import TableAction from '../tableAction/TableAction';

  import styles from './batchActions.scss';
  import { ArrayProp } from '@h4h/classes';

  export default {
    name: 'H4hBatchActions',

    components: {
      TableAction,
      Icon
    },

    props: {
      actions: new ArrayProp({
        type: BatchAction,
        defaultValue: () => []
      }),

      noBatchActionsLabel: {
        type: String,
        default: 'table.noBatchActionsAvailable'
      },

      rows: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        styles,
        openPopover: false
      };
    },

    computed: {
      isShown() {
        return this.matchingActions.length;
      },

      shownActions() {
        return this.$props.actions.filter(b => b.show);
      },

      matchingActions() {
        if (!this.rows.length || !this.shownActions.length) {
          return [];
        }

        return this.shownActions.filter(action => this.isMatchingAllRows(action));
      },
    },

    methods: {
      getAvailableBatchActions(item) {
        return this.$props.actions.filter(a => a.matcher(item));
      },

      getMatchingRows(action) {
        return this.$props.rows.filter(row => action.matcher(row));
      },

      onTrigger(action) {
        action.action(
          this.getMatchingRows(action)
        );
      },
      async onInitPopover(action) {
        return action.initPopover(this.$props.item);
      },
      isMatchingAllRows(action) {
        return (
          action.matcher === BatchAction.alwaysMatch ||
          this.$props.rows.every(row => action.matcher(row))
        );
      }
    }
  };
</script>
