import l10n from 'flatpickr/dist/l10n';
import { createInputConfig, InputType } from '@h4h/inputs';
import { i18n } from '../services/i18n';

export function createEhPeriodInputConfig(cfg) {
  const config = cfg || {};
  const now = new Date();
  const then = new Date();
  then.setDate(then.getDate() - 7);

  return createEhDateInputConfig({
    id: 'period',
    max: now,
    range: true,
    value: [then, now],
    placeholder: 'tasks.period',
    ...config,
  });
}

export function createEhDateInputConfig(config) {
  return createInputConfig({
    type: InputType.Date,
    locale: l10n[i18n.locale],
    ...config,
  });
}

export function getDateRangeSeparator() {
  return (l10n[i18n.locale]?.rangeSeparator) || ' - ';
}
