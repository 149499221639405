import { types } from './types';
import { mapValues } from 'lodash';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import { showRestError } from '../../../utils/errors';
import { labResultGroups } from '../../../services/api/labResults/labResultGroups';

export const actions = Object.freeze({
  initForm,
  save,
  addObservationTemplate,
  removeTemplate,
});

async function initForm({ commit }, terminologyTypes) {
  commit(types.INIT_FORM, terminologyTypes);
}

async function removeTemplate({ commit }, template) {
  commit(types.REMOVE_TEMPLATE, template);
}

async function addObservationTemplate({ commit }, template) {
  commit(types.ADD_OBSERVATION_TEMPLATE, template);
}

async function save({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const labResultGroup = {
    ...mapValues(state.inputsMap, i => i.value),
    observationTemplates: state.templates,
  };

  commit(types.SET_LOADING, true);
  const result = await labResultGroups.create(labResultGroup);
  commit(types.SET_LOADING, false);
  if (result.success) {
    toast.success(localize('messages.labResultGroupSaved'));
  }
  else {
    showRestError(result.error, 'messages.cantCreateLabResultGroup');
  }
  return result.success;
}
