export const healthRecordContactedVia = Object.freeze({
  PHONE: {
    name: 'PHONE',
    translationKey: 'healthRecords.phone',
  },
  EMAIL: {
    name: 'EMAIL',
    translationKey: 'healthRecords.email',
  },
});

export const healthRecordAdvice = Object.freeze({
  CONSULT_BY_PHONE_WITH_TREATING_SPECIALIST: {
    name: 'CONSULT_BY_PHONE_WITH_TREATING_SPECIALIST',
    translationKey: 'healthRecords.consultByPhoneCardiologist',
  },
  CONSULT_BY_TREATING_CARDIOLOGIST: {
    name: 'CONSULT_BY_TREATING_CARDIOLOGIST',
    translationKey: 'healthRecords.consultByCardiologist',
  },
  REASSURANCE: {
    name: 'REASSURANCE',
    translationKey: 'healthRecords.reassurance',
  },
  REFERRAL_TO_FIRST_AID: {
    name: 'REFERRAL_TO_FIRST_AID',
    translationKey: 'healthRecords.refToFirstAid',
  },
  OTHER: {
    name: 'OTHER',
    translationKey: 'healthRecords.other',
  },
  // CONSULT_BY_GENERAL_PRACTITIONER: {
  //   name: 'CONSULT_BY_GENERAL_PRACTITIONER',
  //   translationKey: 'healthRecords.consultByPractitioner',
  // },
});

export const healthRecordConclusions = Object.freeze({
  SINUS_RHYTHM: {
    name: 'SINUS_RHYTHM',
    translationKey: 'healthRecords.sinusRhythm',
  },
  SINUS_BRADYCARDIA: {
    name: 'SINUS_BRADYCARDIA',
    translationKey: 'healthRecords.sinusbradycardie',
  },
  SINUS_TACHYCARDIA: {
    name: 'SINUS_TACHYCARDIA',
    translationKey: 'healthRecords.sinustachycardie',
  },
  ATRIAL_FIBRILLATION: {
    name: 'ATRIAL_FIBRILLATION',
    translationKey: 'healthRecords.atrialFibrillation',
  },
  ATRIAL_FLUTTER: {
    name: 'ATRIAL_FLUTTER',
    translationKey: 'healthRecords.atrialFlutter',
  },
  PAC: {
    name: 'PAC',
    translationKey: 'healthRecords.pac',
  },
  DOUBLET_PAC: {
    name: 'DOUBLET_PAC',
    translationKey: 'healthRecords.doubletPac',
  },
  PVC: {
    name: 'PVC',
    translationKey: 'healthRecords.pvc',
  },
  DOUBLET_PVC: {
    name: 'DOUBLET_PVC',
    translationKey: 'healthRecords.doubletPvc',
  },
  MALFUNCTION: {
    name: 'MALFUNCTION',
    translationKey: 'healthRecords.malfunction',
  },
  INDIVISIBLE: {
    name: 'INDIVISIBLE',
    translationKey: 'healthRecords.indivisible',
  },
  SVT: {
    name: 'SVT',
    translationKey: 'healthRecords.svt',
  },
  AVNRT: {
    name: 'AVNRT',
    translationKey: 'healthRecords.avnrt',
  },
  ATRIAL_TACHYCARDIA: {
    name: 'ATRIAL_TACHYCARDIA',
    translationKey: 'healthRecords.atrialTachycardia',
  },
  ASYSTOLE: {
    name: 'ASYSTOLE',
    translationKey: 'healthRecords.asystole',
  },
  AV_BLOCK_FIRST_DEGREE: {
    name: 'AV_BLOCK_FIRST_DEGREE',
    translationKey: 'healthRecords.avBlockFirstDegree',
  },
  AV_BLOCK_SECOND_DEGREE: {
    name: 'AV_BLOCK_SECOND_DEGREE',
    translationKey: 'healthRecords.avBlockSecondDegree',
  },
  AV_BLOCK_THIRD_DEGREE: {
    name: 'AV_BLOCK_THIRD_DEGREE',
    translationKey: 'healthRecords.avBlockThirdDegree',
  },
  PAUSE: {
    name: 'PAUSE',
    translationKey: 'healthRecords.pause',
  },
  SINUS_JUDGMENT: {
    name: 'SINUS_JUDGMENT',
    translationKey: 'healthRecords.sinusJudgment',
  },
  NSVT: {
    name: 'NSVT',
    translationKey: 'healthRecords.nsvt',
  },
  SUSTAINED_VT: {
    name: 'SUSTAINED_VT',
    translationKey: 'healthRecords.sustainedVt',
  },
  SA_BLOCK: {
    name: 'SA_BLOCK',
    translationKey: 'healthRecords.sa_block',
  },
  BIGEMINY: {
    name: 'BIGEMINY',
    translationKey: 'healthRecords.bigeminy',
  },
  OTHER: {
    name: 'OTHER',
    translationKey: 'healthRecords.other',
  },
});
