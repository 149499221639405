import { EnrollmentStatus } from '../../constants/enrollmentStatus';

export const enrollments = {
  enrollments: 'Programs',
  enrollment: 'Enrollment',
  enrollPatientToTheProgram: 'Enroll',
  addProgramToPatient: 'Add program to patient',
  stopEnrollment: 'Stop',
  enroll: 'Enroll',
  settingsType: 'Settings type',
  monitoringPeriod: 'Protocol',
  treatmentAdvice: 'Treatment advice',
  treatmentAdviceAgree: 'I agree with the advice',
  treatmentAdviceDisagree: 'I do not agree with the advice',
  stopConfirmQuestion: 'Are you sure want to end enrollment before duration is finished?',
  reason: 'Reason for ending',
  stopHeader: 'Reason for ending',
  stopDisclaimer: 'Please add the reason for ending {1} for {0} below',
  stoppingEnrollments: 'Stopping enrollments...',
  stoppedOn: 'Stopped on',
  active: 'Active',
  history: 'History',
  registrationDate: 'Registration date',
  deregistrationDate: 'Deregistration date',
  activationDate: 'Activation date',
  period: 'Period',
  dayShort: 'd',
  weekShort: 'w',
  yearShort: 'y',
  sendDevicesToPatients: 'Send device to patient',
  giveOptionToSendDeviceToPatient: 'Give option to send device to patient',
  confirmCurrentAddress: 'Confirm current address',
  updateCurrentAddress: 'Update current address',
  schedule: 'Schedule',
  standard: 'Standard',
  adjusted: 'Adjusted',
  inMonths: 'Months',
  weekOfMonth: 'Week of the month',
  onDay: 'Day',
  onDaysOfMonth: 'Days of month',
  onDaysOfWeek: 'Days of week',
  measureAt: 'At time of day',
  standardScheduleInfo: 'Standard schedule',
  editSchedule: 'Edit schedule',
  noSchemeAvailable: 'No scheme available',
  scheduleEditabilityInfo: 'We\'re not allowing the schedules to be edited if they don\'t have a schedule already set in the program they belong to',
  addAddress: 'Add address',
  requestPatientAddress: 'Request patient address',
  requestPatientAddressExplanation: 'The patient’s most recent address might not be connected to their profile. Click the button below to send a task to the patient in the app to request their address and to proceed with the enrollment. When the patient fills in their address, the device will be sent by the logistics department.',
  waitForPendingPatientAddressRequest: 'Wait for pending patient request',
  continueEnrollment: 'Proceed with enrollment',
  status: 'Status',
  adjustThresholdHint: 'You can adjust the standard thresholds.',
  schedules: 'Schedules',
  adjustScheduleHint: 'You can adjust the standard thresholds.',
  programOverview: 'Program Overview',
  [EnrollmentStatus.PENDING]: 'Pending',
  [EnrollmentStatus.ACTIVE]: 'Active',
  [EnrollmentStatus.DEACTIVATED]: 'Deactivated',
  stoppedBy: 'Stopped by',
  selectAPeriod: 'Select a period',
  measuringSchedule: 'Measuring schedule',
  cantCreateEnrollmentTheAddressOfThePatientIsUnknownAndNotRequested: 'Can\'t create enrollment(s), the address of the patient is unknown and not requested.',
  other: 'Other',
  otherPleaseSpecify: 'Other, please specify',
};
