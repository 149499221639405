import { ComponentStyle } from '@h4h/theme';

export class StrengthLevel {
  /**
   * @param {Object}    config
   *
   * @param {Number}    config.level
   * @param {String}    config.title
   * @param {String}    config.color
   * @param {Boolean}   [config.minRequirement]
   * @param {Number}    config.minDiversity
   * @param {Number}    config.minLength
   */
  constructor(config) {
    const { level, title, color, minRequirement, minDiversity, minLength } = config;

    if (!level || !title || !color || !minDiversity || !minLength) {
      throw new Error(`Invalid StrengthLevel config - ${ JSON.stringify(config) }`);
    }

    if (!Object.values(ComponentStyle).find(color)) {
      throw new Error(`Invalid color attribute - ${ JSON.stringify(config) }`);
    }

    this.level = level;
    this.title = title;
    this.color = color;
    this.minRequirement = !!minRequirement;
    this.minDiversity = minDiversity;
    this.minLength = minLength;
  }

  static validateStrengthLevels(levels) {
    // Make sure the first level has these two set to 0
    if (levels[0].minDiversity !== 0 || levels[0].minLength !== 0) {
      throw new Error('minDiversity and minLength must be 0 in the first level');
    }

    // Make sure only one level has minRequirement = true
    if (levels.filter(l => l.minRequirement).length !== 1) {
      throw new Error('minRequirement can be true only for one level');
    }

    // Check if level, minDiversity and minLength are progressive
    // The value of level from previous level must be lower to current
    // The values of minDiversity and minLength from previous level must be lower or equal to current
    levels.reduce(
      (previousValue, currentValue) => {
        const { level, minDiversity, minLength } = previousValue;
        if (level >= currentValue.level || minDiversity > currentValue.minDiversity || minLength > currentValue.minLength) {
          throw new Error('level, minDiversity, minLength must have higher value in higher level');
        }
        return currentValue;
      },
      {
        level: 0,
        minDiversity: 0,
        minLength: 0
      }
    );

    return true;
  }

}