import { types } from './types';
import { showRestError } from '../../../utils/errors';
import {
  questionnaireTemplates,
  questionnaireTemplatesUpdatable
} from '../../../services/api/questionnaire/questionnaireTemplates';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import { router } from '../../../services/router';
import { Page } from '../../../constants/page';
import { mapValues } from 'lodash';
import { questionnaireTemplatesActions } from '../contants';

export const actions = Object.freeze({
  initForm,
  save,
  addQuestion,
  removeQuestion,
});

async function initForm({ commit }, uuid) {
  commit(types.SET_LOADING, true);
  commit(types.INIT_FORM, null);
  try {
    let updatable = await questionnaireTemplatesUpdatable(uuid);
    if (updatable.success) {
      if (!updatable.data.updatable) {
        toast.error(localize('messages.questionnaireTemplateIsNotUpdatable'));
        router.push({
          name: Page.AdminQuestionnaireTemplatesView,
          uuid
        });
        return;
      }
    }
    else {
      showRestError(updatable.error, 'messages.cantFetchQuestionnaireTemplateInfo');
      return;
    }
    let questionnaireTemplate = await questionnaireTemplates.get(uuid);
    if (questionnaireTemplate.success) {
      commit(types.INIT_FORM, questionnaireTemplate.data);
    }
    else {
      showRestError(questionnaireTemplate.error, 'messages.cantFetchQuestionnaireTemplate');
    }
  }
  finally {
    commit(types.SET_LOADING, false);
  }
}

async function removeQuestion({ commit }, question) {
  commit(types.REMOVE_QUESTION, question);
}

async function addQuestion({ commit, state }, question) {
  const alreadyPresentQuestion = state.questions
    .find(q => q.questionTerminologyCode === question.questionTerminologyCode);
  if (alreadyPresentQuestion) {
    toast.error(localize('questionnaireTemplates.thisQuestionAlreadyAdded'));
  }
  else {
    commit(types.ADD_QUESTION, question);
  }
}

async function save({ state, commit, getters, dispatch }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid || state.loading) {
    return false;
  }
  const uuid = state.questionnaireTemplate.uuid;

  const questionnaireTemplate = {
    ...this.questionnaireTemplate,
    ...mapValues(state.inputsMap, i => i.value),
    questions: state.questions,
  };

  commit(types.SET_LOADING, true);
  const result = await questionnaireTemplates.update(uuid, questionnaireTemplate);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.questionnaireTemplateUpdated'));
    dispatch(questionnaireTemplatesActions.ReloadQuestionnaireTemplates, {}, { root: true });
    router.push({
      name: Page.AdminQuestionnaireTemplatesView,
      uuid: uuid,
    });
  }
  else {
    showRestError(result.error, 'messages.cantCreateQuestionnaireTemplate');
  }
  return result.success;
}

