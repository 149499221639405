import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig, getInputsMap, validateTextInput } from '@h4h/inputs';
import { EhPatterns } from '../../../../utils/ehPatterns';
import { ccRoleOptionsSupportedForSelect } from '../../../../services/auth/ehRole';
import { EhUserFieldsLengthConstraints } from '../../../../utils/ehUserFieldsLengthConstraints';
import { createPhoneInputConfig } from '../../../../utils/phone/phoneComponentUtils';
import { allowedCountryCodesForCC } from '../../constants';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.INIT_FORM](state, { countries, visibleCountry }) {
    const inputs = getInputsConfig(countries, visibleCountry);

    state.inputs = inputs;
    state.inputsMap = getInputsMap(inputs);
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },
};

function getInputsConfig(countries, userCountry) {
  const email = createTextInputConfig('email', 'common.email', InputType.Text, EhPatterns.email, true, EhUserFieldsLengthConstraints.email);
  const emailAgain = {
    ...createTextInputConfig('emailAgain', 'common.emailAgain', InputType.Text, EhPatterns.email, true, EhUserFieldsLengthConstraints.email),
    validate(input) {
      return validateTextInput(input) && email.value === input.value;
    },
    getStatePatch() {
      return { fakeFieldForUpdate: Math.random() };
    }
  };
  email.linkedInputs = [emailAgain];
  const firstName = createTextInputConfig('firstName', 'common.firstName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.firstName);
  const lastName = createTextInputConfig('lastName', 'common.lastName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, true, EhUserFieldsLengthConstraints.lastName);
  const middleName = createTextInputConfig('middleName', 'common.middleName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.middleName);
  const phone = createPhoneInputConfig(null, 'phone', 'common.telephoneNumber', true);
  const alarmPhoneNumber = createTextInputConfig('alarmPhoneNumber', 'common.alarmPhoneNumber', InputType.Text, EhPatterns.phoneNumberInternational, true, EhUserFieldsLengthConstraints.alarmPhoneNumber);
  const bigCode = createTextInputConfig('bigCode', 'common.bigCode', InputType.Text, null, false, EhUserFieldsLengthConstraints.bigCode);
  const initials = createTextInputConfig('initials', 'common.initials', InputType.Text, EhPatterns.lettersDotsOnly, false, EhUserFieldsLengthConstraints.initials);
  const title = createTextInputConfig('title', 'common.title', InputType.Text, EhPatterns.lettersOnly, false, EhUserFieldsLengthConstraints.title);
  const partnerMiddleName = createTextInputConfig('partnerMiddleName', 'common.partnerMiddleName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.partnerMiddleName);
  const partnerName = createTextInputConfig('partnerName', 'common.partnerName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.partnerName);
  let visibleCountryParamsExtension = {};
  if (userCountry) {
    visibleCountryParamsExtension = {
      disabled: true,
      value: userCountry,
    };
  }
  const countryOptions = countries
    .filter(country => !!userCountry || allowedCountryCodesForCC.includes(country.code2))
    .map(country => ({
      value: country.code2,
      label: `${ country.name } ${ country.code2 }`
    }));
  const visibleCountry = createInputConfig({
    id: 'visibleCountry',
    type: InputType.Select2,
    clearable: false,
    required: true,
    label: 'tenant.country',
    options: countryOptions,
    ...visibleCountryParamsExtension,
  });

  const role = createInputConfig({
    id: 'role',
    type: InputType.Select2,
    required: true,
    clearable: false,
    label: 'common.role',
    options: ccRoleOptionsSupportedForSelect,
  });

  return [
    initials,
    firstName,
    lastName,
    middleName,
    partnerName,
    partnerMiddleName,
    phone,
    alarmPhoneNumber,
    email,
    emailAgain,
    title,
    bigCode,
    visibleCountry,
    role,
  ];
}

function createTextInputConfig(id, label, inputType, pattern, required = false, constraints) {
  return createInputConfig({
    id,
    type: inputType,
    pattern,
    required,
    label,
    ...constraints,
  });
}
