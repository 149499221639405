export const getters = {
  users,
  filterInputs,
  valid,
};

export function filterInputs(state) {
  return [/* state.nameFilterInput, state.emailFilterInput, */state.roleFilterInput, state.generalSearchInput].filter(x => !!x);
}

function valid(state) {
  return filterInputs(state).every(input => input.valid);
}

function users(state) {
  if (state.usersPage) {
    return state.usersPage.content;
  }
  return null;
}
