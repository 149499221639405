export const Gender = Object.freeze({
  UNKNOWN: 'UNKNOWN',
  MALE: 'MALE',
  FEMALE: 'FEMALE'
});

export const genderOptions = Object.keys(Gender).map(gender => ({
  label: `gender.${ gender }`,
  value: gender
}));
