import moment from 'moment';
import { createEhDateInputConfig } from '../../../../../../../utils/factories';
import {
  ChosenPeriodGranularity,
  evalGranularityPeriod
} from '../../../../../../../components/period/periodHelper/chosenPeriodHelper';
import { createInputConfig, InputType } from '@h4h/inputs';

const defaultAmountOfMonths = 1;

export function periodInputConfig(enrollment) {
  let min = null;
  let max = moment().endOf('day').toDate();
  let start = moment(max).subtract(defaultAmountOfMonths, 'month').startOf('day').toDate();
  if (enrollment.monitoringPeriod) {
    min = moment(enrollment.monitoringPeriod.start).startOf('day').toDate();
    const realEndDate = enrollment.monitoringPeriod.realEndDate;
    if (realEndDate) {
      const endDate = moment(realEndDate).endOf('day').toDate();
      if (endDate < max) {
        max = endDate;
      }
    }
    start = moment(max).subtract(defaultAmountOfMonths, 'month').startOf('day').toDate();
    if (start < min) {
      start = min;
    }
  }
  return createEhDateInputConfig({
    id: 'observationsListPeriod',
    min,
    max,
    range: true,
    value: [start, max],
    inline: true,
    placeholder: 'tasks.period',
  });
}

export function intervalChooserConfig(period) {
  const options = [
    {
      label: 'cronEditor.day',
      value: ChosenPeriodGranularity.DAY,
    },
    {
      label: 'cronEditor.week',
      value: ChosenPeriodGranularity.WEEK,
    },
    {
      label: 'patients.month',
      value: ChosenPeriodGranularity.MONTH,
    },
    {
      label: 'patients.halfOfYear',
      value: ChosenPeriodGranularity.HALF_YEAR,
    },
  ];
  return createInputConfig({
    id: 'observationsListIntervalType',
    value: evalGranularityPeriod(period, options.map(option => option.value)),
    type: InputType.Radio,
    toggle: true,
    inline: true,
    options,
  });
}
