import { types } from './types';
import { createAddressInputConfig } from '../../utils/addressComponentUtils';

export const mutations = {
  [types.SET_PRISTINE](state, value) {
    state.address.pristine = value;
  },

  [types.SET_POPUP_ID](state, value) {
    state.popupId = value;
  },

  /**
   *
   * @param state
   * @param {AddressDialogInitModel} model
   * @param {CountryModel[]} countries
   */
  [types.INIT_POPUP](state, { model, countries }) {
    state.address = createAddressInputConfig(model.address, countries, 'address', 'common.postalAddress', true);
    state.mode = model.mode;
  },
};
