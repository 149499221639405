import { genericOptions } from '@h4h/utils';
import { ApiEnumMapper } from './apiEnumMapper';

/**
 * @name EnumOptionsModifiers
 * @property {String[]} [order] - overrides default locale-agnostic alphabetical order
 * @property {(option: EnumOption) => Object} [mapper] - maps enum options to extend functionality
 */

/**
 * @name EnumOption
 * @property {String} label - translation path
 * @property {any} value - actual value
 */

export class Enum {
  /**
   * Used to validate whether Enum was installed as a Vue plugin
   * before and store localize method after installing
   */
  static localize() {
    throw new Error('Enum has not been properly installed, please install localizer as an Enum plugin: `Enum.use(localizer);`');
  }

  /**
   * @param {Localizer} localizer
   */
  static use(localizer) {
    Enum.localize = localizer.localize;
  }

  /**
   * @param {EnumOption} a
   * @param {EnumOption} b
   * @param {String} comparedProp - enum value key by which the values will be ordered
   * @returns {number}
   */
  static compareOptions(a, b, comparedProp = 'label') {
    const labelA = Enum.localize(a[comparedProp]).toLowerCase();
    const labelB = Enum.localize(b[comparedProp]).toLowerCase();

    return (
      (a.value === genericOptions.Other) - (b.value === genericOptions.Other) ||
      (a.value === genericOptions.Unknown) - (b.value === genericOptions.Unknown) ||
      labelA.localeCompare(labelB)
    );
  }

  /**
   * @param {String} comparedProp - enum value key by which the values will be ordered
   * @returns {(a: EnumOption, b: EnumOption) => Number}
   */
  static createOptionComparator(comparedProp = 'label') {
    return (a, b) => Enum.compareOptions(a, b, comparedProp);
  }

  /**
   * @param {EnumOption[]} options - list of enum values
   * @param {String} orderProp - enum value key by which the values will be ordered
   * @returns {Array} - options ordered alphabetically - other & unknown are always last
   */
  static sortOptions(options, orderProp = 'label') {
    if (!options) {
      return [];
    }

    return options.slice().sort((a, b) => Enum.compareOptions(a, b, orderProp));
  }

  /**
   * options are initialized lazily in order not to
   * postpone enums creation after Enum bootstrap
   *
   * @returns {EnumOption[]}
   */
  get options() {
    return this._options || this._initOptions();
  }

  /**
   * @param {EnumOption[]} options
   */
  set options(options) {
    this._options = options;
  }

  /**
   * @type {EnumOption[]}
   */
  _options = null;

  /**
   * @param {String} name - enum name
   * @param {Object} obj - object representing enum
   * @param {Object} translationMapping - map enum values with translations
   * @param {Object} apiMapping - API value to enum value map
   * @param {EnumOptionsModifiers} [optionsModifiers]
   */
  constructor(name, obj, translationMapping, apiMapping, optionsModifiers) {
    this.name = name;
    this.translationMapping = translationMapping;
    this.apiEnumMapper = new ApiEnumMapper(name, apiMapping);
    this.optionsModifiers = optionsModifiers;
    this.obj = obj;
  }

  /**
   * @type {EnumOption[]}
   */
  _initOptions() {
    let options = (this.optionsModifiers?.order || Object.values(this.obj))
      .map(value => ({
        label: this.getTranslationKey(value),
        value
      }))
      .filter(x => x.label);

    if (this.optionsModifiers?.mapper) {
      options = options.map(this.optionsModifiers.mapper);
    }

    if (!this.optionsModifiers?.order) {
      options = Enum.sortOptions(options);
    }

    return this._options = options;
  }

  /**
   * Tries to map API value to enum value, logs an error and passes API value if the mapping is not defined
   *
   * @param {String|Number} value - API value
   * @returns {String|Number} - enum value or API value
   */
  fromApi = value => {
    return this.apiEnumMapper.fromApi(value);
  }

  /**
   * Tries to map enum value to API value, logs an error and passes enum value if the mapping is not defined
   *
   * @param {String} value - enum value
   * @returns {String} - API value or enum value
   */
  toApi = value => {
    return this.apiEnumMapper.toApi(value);
  }

  /**
   * @param {String} value - enum value
   * @returns {String} - empty string or translation key
   */
  getTranslationKey(value) {
    const translationKey = this.translationMapping[value];

    if (!translationKey) {
      // eslint-disable-next-line no-console
      console.error(`No translation key found for ${ JSON.stringify(value) } in ${ this.name }`);
      return '';
    }

    return translationKey;
  }

  /**
   * @param {String} value - enum value
   * @returns {String} - translated enum value
   */
  format = value => {
    return value && Enum.localize(this.getTranslationKey(value));
  }
}
