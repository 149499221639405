export class ProgramInputModel {
  /**
   * ProgramInputModel config
   *
   * @param {Object}           config
   * @param {String}           config.cron
   * @param {ProgramInputType} config.inputType
   * @param {String}           config.name
   * @param {String}           config.uuid
   */
  constructor(config) {
    this.cron = config.cron;
    this.inputType = config.inputType;
    this.name = config.name;
    this.uuid = config.uuid;
  }
}
