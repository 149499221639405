export const SET_LOADING = 'SET_LOADING';
export const setLoading = 'setLoading';

export function withLoading(storeConfig) {
  const { actions, mutations, state } = storeConfig;

  return {
    ...storeConfig,

    actions: {
      [setLoading]({ commit }, loading) {
        commit(SET_LOADING, loading);
      },
      ...actions,
    },

    mutations: {
      [SET_LOADING](state, loading) {
        state.loading = loading;
      },
      ...mutations,
    },

    state: {
      loading: false,
      ...state,
    }
  };
}
