import { types } from './types';
import { showRestError } from '../../../../utils/errors';
import { callCenterUsersPage } from '../../../../services/api/users/callCenterUsers';
import { mapDataTablePageConfigToSpringPageConfig } from '../../../../model/page';

export const actions = Object.freeze({
  reload,
  initForm,
  onFilterChange,
  setRoleFilter,
});

async function reload({ commit, state }, dataTableConfig) {
  commit(types.SET_PAGINATION_CONFIG, dataTableConfig);
  await reloadData({ commit, state });
}

function initForm({ commit }) {
  commit(types.INIT_FORM);
}

async function onFilterChange({ commit, state, getters }) {
  commit(types.UPDATE_FILTER_PARAMS);
  if (!getters.valid) {
    return;
  }
  await reloadData({ commit, state });
}

async function setRoleFilter({ commit }, input) {
  commit(types.SET_ROLE_FILTER_VALUE, input);
}

async function reloadData({ commit, state }) {
  let params = {
    ...mapDataTablePageConfigToSpringPageConfig(state.serverSideOptions),
    ...(state.filterParams || {}),
  };

  commit(types.SET_LOADING, true);
  const result = await callCenterUsersPage.fetchOne({ params });
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_USERS_PAGE, result.data);
  }
  else {
    commit(types.SET_USERS_PAGE, null);
    showRestError(result.error, 'messages.cantFetchUsers');
  }
}
