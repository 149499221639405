import { AnswerType } from '../../../../constants/answerType';

/**
 * @param {QuestionDefinitionModel}     questionDefinitionModel
 */
export function valueExtractorBuilder(questionDefinitionModel) {
  const definitionModel = questionDefinitionModel;

  /**
   * @param {QuestionnaireResponseItem} questionnaireResponseItem
   */
  function valueExtractor(questionnaireResponseItem) {
    switch (definitionModel.answerType) {
      case AnswerType.NUMERIC:
        return questionnaireResponseItem.valueNumeric;
      case AnswerType.BOOLEAN:
        return questionnaireResponseItem.valueBoolean;
      case AnswerType.INTEGER:
        return questionnaireResponseItem.valueInt;
      case AnswerType.FREE_STRING:
        return questionnaireResponseItem.valueFreeString;
      case AnswerType.CHOICE:
        return questionnaireResponseItem.valueTerminology;
    }
  }

  return valueExtractor;
}

/**
 * @param {QuestionDefinitionModel}     questionDefinitionModel
 */
export function valueSetterBuilder(questionDefinitionModel) {
  const definitionModel = questionDefinitionModel;

  /**
   * @param {QuestionnaireResponseItem} questionnaireResponseItem
   * @param value
   */
  function valueSetter(questionnaireResponseItem, value) {
    switch (definitionModel.answerType) {
      case AnswerType.NUMERIC:
        questionnaireResponseItem.valueNumeric = value;
        break;
      case AnswerType.BOOLEAN:
        questionnaireResponseItem.valueBoolean = value;
        break;
      case AnswerType.INTEGER:
        questionnaireResponseItem.valueInt = value;
        break;
      case AnswerType.FREE_STRING:
        questionnaireResponseItem.valueFreeString = value;
        break;
      case AnswerType.CHOICE:
        questionnaireResponseItem.valueTerminology = value;
        break;
    }
  }

  return valueSetter;
}
