<template>
  <Smart
    v-bind="$props"
    :input="_input"
    :action="setInputValue"
    @blur="onBlur"
    @focus="onFocus"
  />
</template>

<script>
  import Smart from './Smart';

  export default {
    name: 'H4hSmartFilter',

    components: {
      Smart
    },

    props: Smart.props,

    data() {
      return {
        focused: false,
        initialValid: null
      };
    },

    computed: {
      valid() {
        // prevent showing validation errors until input is blurred
        return (this.focused && this.initialValid) || this.input?.valid;
      },

      _input() {
        return {
          ...this.$props?.input,
          valid: this.valid
        };
      }
    },

    methods: {
      onFocus() {
        this.focused = true;
        this.initialValid = this.$props.input.valid;
      },

      onBlur() {
        this.focused = false;
      },

      setInputValue({ value }) {
        const { input, action } = this.$props;

        if (action) {
          action({ input, value });
        }
      }
    }
  };
</script>
