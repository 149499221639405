import { CrudService } from '../../../classes/crudService';
import { ProgramHasEnrollmentsModel, ProgramModel } from '../../../model/programModel';

export const programs = new CrudService(
  'programs',
  data => new ProgramModel(data),
  data => data
);

export const programHasEnrollments = programUuid => new CrudService(
  `programs/${ programUuid }/has-enrollments`,
  data => new ProgramHasEnrollmentsModel(data),
);

