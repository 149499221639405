export const LogisticTaskStatus = Object.freeze({
  UNHANDLED: 'UNHANDLED',
  HANDLED: 'HANDLED',
});

export const logisticTaskStatusOptions = Object.keys(LogisticTaskStatus).map(status => ({
  label: `tasks.${ status.toLowerCase() }`,
  value: status
}));

