<template>
  <Typeahead2
    v-bind="$props"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @change="$emit('change', $event)"
  >
    <template #selection="itemProps">
      <CountryFlag :country="itemProps.item.value.code2"/>
      {{ $localize(itemProps.item.label) }}
    </template>

    <template #itemLabel="itemProps">
      <CountryFlag :country="itemProps.item.value.code2"/>
      {{ $localize(itemProps.item.label) }}
    </template>
  </Typeahead2>
</template>

<script>
  import CountryFlag from 'vue-country-flag';

  import { InputMixin } from '../mixins';
  import { selectProps } from '../utils';

  import Typeahead2 from './newSelect/Typeahead2';

  export default {
    name: 'H4hCountrySelect',

    components: {
      Typeahead2,
      CountryFlag
    },

    mixins: [
      InputMixin
    ],

    props: selectProps
  };
</script>
