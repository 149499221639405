export const getters = {
  filterInputs,
  valid,
};

export function filterInputs(state) {
  return [/* state.nameFilterInput, state.emailFilterInput,*/ state.groupFilterInput, state.generalSearchInput].filter(x => !!x);
}

function valid(state) {
  return filterInputs(state).every(input => input.valid);
}
