export class EnvironmentModel {
  /**
   * EnvironmentModel
   *
   * @param {Object}    config
   * @param {String}    config.code
   */
  constructor(config) {
    this.code = config.code;
  }

}
