import { TabIndexSubscriberMixin } from '@h4h/tab-index-manager';

import { inputProps } from '../utils';

export const InputMixin = {
  ...TabIndexSubscriberMixin,

  props: {
    ...TabIndexSubscriberMixin.props,

    name: inputProps.name,

    /** labels */
    label: inputProps.label,

    /** flags */
    valid: inputProps.booleanTrue,
    pristine: inputProps.booleanTrue,
    disabled: inputProps.booleanFalse,
    autofocus: inputProps.booleanFalse,

    /** styles */
    labelStyle: inputProps.style
  }
};
