export const getters = {
  patients,
  patientsCount,
  filterInputs,
  valid,
};

function filterInputs(state) {
  return [state.filterInput].filter(x => !!x);
}

function valid(state) {
  return filterInputs(state).every(input => input.valid);
}

function patients(state) {
  if (state.patientsPage) {
    return state.patientsPage.content;
  }
  return [];
}

function patientsCount(state) {
  if (state.patientsPage) {
    return state.patientsPage.totalElements;
  }
}

