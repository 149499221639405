import { mapValues } from 'lodash';
import moment from 'moment';
import { toast } from '../../../services/toast';

import { types } from './types';
import { setInputValueAction } from '@h4h/inputs';
import { localize } from '../../../services/localize';
import { patients } from '../../../services/api/patients/patients';
import { showRestError } from '../../../utils/errors';
import { convertPhonesToStrings } from '../../../utils/phone/phoneComponentUtils';
import { checkNotSupportedPhoneCode } from '../../../utils/phone/notSupportedPhoneCodeWarning';
import { fetchCountries } from '../../countries/utils';

export const actions = Object.freeze({
  setInputValue,
  savePatient,
  initForm,
  clearInputs,
});

async function setInputValue({ commit, dispatch }, { input, value }) {
  const oldValue = input.value;
  await setInputValueAction({ commit, dispatch }, { input, value });
  checkNotSupportedPhoneCode(input, oldValue, value);
}

async function clearInputs({ commit }) {
  commit(types.CLEAR_INPUTS);
}

async function savePatient({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const patient = mapValues(state.inputsMap, i => i.value);
  convertPhonesToStrings(patient, ['phone']);

  commit(types.SET_LOADING, true);
  const result = await patients.create(patient);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.patientSaved'));
    return result.data;
  }
  else {
    showRestError(result.error, 'messages.cantCreatePatient');
    return null;
  }
}

async function initForm({ state, commit, dispatch }) {
  const countries = await fetchCountries(dispatch);
  commit(types.INIT_FORM, { countries });
  await setDefaultDateOfBirth({ state, commit, dispatch });
}

async function setDefaultDateOfBirth({ state, commit, dispatch }) {
  let dateOfBirthInput = state.inputs.filter(i => i.name === 'dateOfBirth')[0];
  let newDate = moment().subtract(60, 'years').toDate();
  await setInputValueAction({ commit, dispatch }, { input: dateOfBirthInput, value: newDate });
}

