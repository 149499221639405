const DefaultDataTableConfigTemplate = {
  'page': 1,
  'itemsPerPage': 10,
  'sortBy': [],
  'sortDesc': [],
  'groupBy': [],
  'groupDesc': [],
  'mustSort': false,
  'multiSort': false
};

export class DataTableConfigModel {
  /**
   * DataTableConfigModel config
   *
   * @param {Object}      config
   * @param {Number}      config.page
   * @param {Number}      config.itemsPerPage
   * @param {String[]}    config.sortBy
   * @param {Boolean[]}   config.sortDesc
   * @param {String[]}    config.groupBy
   * @param {Boolean[]}   config.groupDesc
   * @param {Boolean}     config.mustSort
   * @param {Boolean}     config.multiSort
   */
  constructor(config) {
    config = {
      ...DefaultDataTableConfigTemplate,
      ...(config || {}),
    };

    this.page = config.page;
    this.itemsPerPage = config.itemsPerPage;
    this.sortBy = [...config.sortBy];
    this.sortDesc = [...config.sortDesc];
    this.groupBy = [...config.groupBy];
    this.groupDesc = [...config.groupDesc];
    this.mustSort = config.mustSort;
    this.multiSort = config.multiSort;
  }
}

export class DataTableBuilderSortItem {
  /**
   * DataTableBuilderSortItem config
   *
   * @param {Object}      config
   * @param {String}      config.field
   * @param {Boolean}     config.sortAsc
   */
  constructor(config) {
    this.field = config.field;
    this.sortAsc = config.sortAsc;
  }
}

/**
 *
 * @param {DataTableBuilderSortItem[]} sortOptions
 */
export function buildSortedDataTableConfig(sortOptions, dataTableConfigOptions) {
  return new DataTableConfigModel({
    sortBy: sortOptions.map(item => item.field),
    sortDesc: sortOptions.map(item => !item.sortAsc),
    ...(dataTableConfigOptions || {}),
  });
}
