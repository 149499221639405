import { PopupName } from '../../../../../../constants/popupName';
import { types } from './types';
import { popups } from '@h4h/popups';
import { PopupType } from '@h4h/popups';

export const actions = Object.freeze({
  exportObservationsToPdf,
});

async function exportObservationsToPdf({ commit }) {
  const { id } = await popups.show({
    type: PopupType.Modal,
    name: PopupName.ExportObservationsToPdf,
  });
  commit(types.SET_POPUP_ID, id);
}

