import { DeviceVendor } from '../../../../../constants/deviceVendor';

export const getters = {
  isKardia,
  monitoringPeriodActive,
};

function isKardia(state) {
  const enrollment = state.enrollment;
  if (enrollment) {
    const vendorModel = enrollment
      .program
      .supportedVendors
      .find(vendorModel => vendorModel.vendor === DeviceVendor.ALIVECOR_KARDIA);
    return !!vendorModel;
  }
  return false;
}

function monitoringPeriodActive(state) {
  return !!(state.enrollment && state.enrollment.active);
}
