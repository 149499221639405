import { state } from './state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { view } from './view';
import { add } from './add';
import { observationPopup } from './observationPopup';
import { vendorPopup } from './vendorPopup';
import { questionnairePopup } from './questionnairePopup';
import { programInputGroupPopup } from './programInputGroupPopup';
import { observationThresholds } from './observationsThresholds';
import { edit } from './edit';
import { withInputs } from '@h4h/inputs';

export const programs = withInputs({
  namespaced: true,

  state,
  actions,
  getters,
  mutations,
  modules: {
    view,
    add,
    edit,
    observationPopup,
    vendorPopup,
    questionnairePopup,
    programInputGroupPopup,
    observationThresholds,
  }
});
