export const cronEditor = {
  day: 'Dag',
  week: 'Week',
  daily: 'Dagelijks',
  weekly: 'Wekelijks',
  monthly: 'Maandelijks',
  yearly: 'Jaarlijks',
  advanced: 'Geavanceerd',
  timeOfDay: 'Tijdstip',
  suffixSt: 'e',
  suffixNd: 'e',
  suffixRd: 'e',
  suffixTh: 'e',
  addTimeslot: 'Voeg tijdstip toe',
  measurementDays: 'Dag van de maand',
  addMeasurementDay: 'Meetdag toevoegen',
  frequency: 'Frequentie',
  invalid: 'De huidige instellingen zijn ongeldig.',
  days: 'Dagen',
  months: 'Maanden',
  seconds: 'Seconden',
  minutes: 'Minuten',
  hours: 'Uren',
  daysOfMonth: 'Dagen van de maand',
  daysOfWeek: 'Dagen van de week',
  validationSixParts: 'Een geldige cron-expressie moet bestaan uit 6 onderdelen.',
  validationValues: 'waarden:',
  validationFromTo: 'nummer van {from} tot {to}',
  validationSingleNumber: 'een enkel nummer',
  validationAListOfNumbers: 'een lijst van nummers',
  validationARangeOfNumbers: 'een reeks van nummers',
  validationAStepValue: 'stapwaarde',
  validationWeekdays: 'weekdagen: MON, TUE, WED, THU, FRI, SAT, SUN',
  validationAnyWeekday: 'elke werkdag',
  validationAListOfWeekdays: 'een lijst van weekdagen',
  validationARangeOfWeekdays: 'een reeks van weekdagen',
};