<template>
  <div
    :class="[
      styles.pills,
      inputStyles.container,
      $props.inline && inputStyles.inline
    ]"
  >
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />
    <PillsOptions
      ref="options"
      :value="optionsValue"
      :showSelectIcon="$props.showSelectIcon"
      :squaredButtons="$props.squaredButtons"
      :pillStyle="$props.pillStyle"
      :options="$props.options"
      :tabindex="tabIndex"
      :disabled="$props.disabled"
      :optionAll="$props.optionAll"
      :data-testid="`field-${$props.name}`"
      @change="onChange"
    />
  </div>
</template>

<script>
  import { uniqueId } from 'lodash';

  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';

  import LabelGroup from '../labelGroup/LabelGroup';

  import styles from './pills.scss';
  import PillsOptions from './PillsOptions';

  export default {
    name: 'H4hPills',

    components: {
      PillsOptions,
      LabelGroup
    },

    mixins: [
      InputMixin
    ],

    props: {
      value: [Object, String, Number, Boolean, Set],

      options: {
        type: Array,
        required: true
      },

      optionAll: Object,

      /** labels */
      info: inputProps.label,
      otherLabel: inputProps.label,
      placeholder: inputProps.label,

      /** flags */
      inline: inputProps.booleanFalse,
      showSelectIcon: inputProps.booleanTrue,
      squaredButtons: inputProps.booleanFalse,

      /** styles */
      labelStyle: inputProps.style,
      pillStyle: inputProps.style,
    },

    data() {
      return {
        styles,
        inputStyles,
        id: uniqueId('pills-'),
        optionsValue: this.getOptionsValue(this.$props.value)
      };
    },

    methods: {
      getOptionsValue(value) {
        const { options } = this.$props;
        if (this.isSelectedAll(value)) {
          return new Set(options.map(o => o.value));
        }
        return value;
      },

      isSelectedAll(value) {
        const { options, optionAll } = this.$props;
        if (!value || !optionAll) {
          return false;
        }
        const filtered = options.filter(({ value }) => value !== optionAll.value);

        return value.has(optionAll.value) || filtered.every(option => value.has(option.value));
      },

      onChange(value) {
        const { optionAll } = this.$props;
        this.optionsValue = this.getOptionsValue(value);
        const selectedAll = this.isSelectedAll(value);
        this.$emit('change', selectedAll ? new Set([optionAll.value]) : value);
      }
    }
  };
</script>
