import { ecgKeyName } from './ecgConstants';
import { Ecg } from './ecg';
import { ObservationModel } from './observationModel';

export function observationBuilder(config) {
  if (!config) {
    return null;
  }
  if (config.terminologyType === ecgKeyName) {
    return new Ecg(config);
  }
  else {
    return new ObservationModel(config);
  }
}
