import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';

export const mutations = {
  [types.SET_FETCHED](state, value) {
    state.fetched = value;
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_ALLERGIES](state, allergies) {
    state.allergies = allergies;
  },

  [types.INIT_ADD_POPUP](state, { popupId }) {
    state.popupId = popupId;
    state.allergy = createInputConfig({
      id: 'allergy',
      type: InputType.Textarea,
      required: true,
      value: null,
      label: 'patient.newAllergy',
    });
  },

  [types.INIT_EDIT_POPUP](state, { popupId, value }) {
    state.popupId = popupId;
    state.allergy = createInputConfig({
      id: 'editAllergy',
      type: InputType.Textarea,
      required: true,
      value: value,
      label: 'patient.editAllergy',
    });
  },

  [types.SET_EDITABLE_ALLERGY_UUID](state, value) {
    state.editableAllergyUuid = value;
  },

  [types.SET_PATIENT_USER_UUID](state, value) {
    state.patientUserUuid = value;
  },
};
