export class ForwardingTaskEmailTemplateModel {

  /**
   * ForwardingTaskEmailTemplateModel config
   *
   * @param {Object}    config
   * @param {String}    config.emailSubject
   * @param {String}    config.emailText
   * @param {String}    config.baseUrl

   */
  constructor(config) {
    config = config || {};
    this.emailSubject = config.emailSubject;
    this.emailText = config.emailText;
    this.baseUrl = config.baseUrl;
  }
}
