import { isNumber } from 'lodash';
import { ObservationValueModel } from '../observationValueModel';
import moment from 'moment';
import { HealthRecordConclusionModel } from '../healthRecordConclusionModel';
import { observationCreatedByTypes } from '../../constants/observationCreatedByTypes';
import { localize } from '../../services/localize';
import { mapArray, mapObject } from '../../utils/mapUtils';
import { EhRole } from '../../services/auth/ehRole';
import { OrgUserModel } from '../orgUserModel';

export const observationDateField = 'authored';

export class ObservationModel {
  /**
   * ObservationModel config
   *
   * @param {Object}                    config
   * @param {String[]}                  config.enrollmentIds
   * @param {ObservationValueModel[]}   config.observationValues
   *
   * @param {String}                    config.authored
   * @param {String}                    config.patientId
   * @param {String}                    config.terminologyType
   * @param {String}                    config.uuid
   * @param {Boolean}                   config.isIrrelevant
   * @param {String}                    config.contactedVia
   * @param {String}                    config.advice
   * @param {Object[]}                  config.conclusions
   * @param {OrgUserModel}              config.conclusionBy
   * @param {Object}                    config.observationCreatedBy
   */
  constructor(config) {
    config = config || {};
    this.enrollmentIds = Array.isArray(config.enrollmentIds) ? config.enrollmentIds : [];
    this.observationValues = [];
    if (Array.isArray(config.observationValues)) {
      this.observationValues = config.observationValues.map(v => new ObservationValueModel(v));
    }
    this[observationDateField] = config[observationDateField];
    this.patientId = config.patientId;
    this.terminologyType = config.terminologyType;
    this.uuid = config.uuid;
    this.isIrrelevant = config.isIrrelevant || false;
    this.contactedVia = config.contactedVia;
    this.advice = config.advice;
    if (Array.isArray(config.conclusions)) {
      this.conclusions = mapArray(config.conclusions, HealthRecordConclusionModel);
    }
    else if (config.conclusion) {
      this.conclusions = [mapObject(config.conclusion, HealthRecordConclusionModel)];
    }
    else {
      this.conclusions = [];
    }
    this.conclusionBy = mapObject(config.conclusionBy, OrgUserModel);
    this.observationCreatedBy = mapObject(config.observationCreatedBy, ObservationCreatedByModel);
  }

  /**
   * @param {String}            type
   * @return {ObservationValueModel}
   */
  getValue(type) {
    return this.observationValues.filter(v => v.type === type)[0];
  }

  /**
   * @param {String}            type
   * @param {String}            unitCode
   * @return {ObservationValueModel}
   */
  getOrCreateValue(type, unitCode) {
    let value = this.getValue(type);
    if (!value) {
      value = new ObservationValueModel({ type, unitCode });
      this.observationValues.push(value);
    }
    return value;
  }

  get date() {
    return moment(this.authored).toDate();
  }

  set date(value) {
    this.authored = moment(value).format();
  }

  // backward compatibility
  get observationDate() {
    return this.authored;
  }

  // backward compatibility
  set observationDate(value) {
    this.authored = value;
  }

  getNumericValues() {
    return this.observationValues.filter(observationValue => isNumber(observationValue.value));
  }

  get createdBy() {
    return this.observationCreatedBy?.asString || localize('observations.other');
  }

  get id() {
    return this.uuid;
  }

}

export class BatchObservationModel {
  /**
   * BatchObservationModel config
   *
   * @param {Object}                config
   * @param {ObservationModel[]}    config.observations
   */
  constructor(config) {
    this.observations = config.observations;
  }
}

export class ObservationCreatedByModel {
  /**
   *
   * @param {Object}    config
   * @param {String}    config.uuid
   * @param {String}    config.iamId
   * @param {String}    config.clientExtId
   * @param {String}    config.clientName
   * @param {String[]}  config.createdBy
   * @param {String}    config.firstName
   * @param {String}    config.lastName
   * @param {String}    config.middleName
   */
  constructor(config) {
    this.uuid = config.uuid;
    this.iamId = config.iamId;
    this.clientExtId = config.clientExtId;
    this.clientName = config.clientName;
    this.createdBy = config.createdBy;
    this.firstName = config.firstName;
    this.lastName = config.lastName;
    this.middleName = config.middleName;
  }

  get asString() {
    let label = '';
    // check if it is a patient
    if (this.createdBy.includes(observationCreatedByTypes.PATIENT)) {
      label += localize('patient.patient');
    }

    // check if it is a physician/poh/call center user
    const isPhysician = this.createdBy.includes(observationCreatedByTypes.PHYSICIAN);
    const isPOH = this.createdBy.includes(observationCreatedByTypes.POH);
    const isCallCenterUser = this.createdBy.includes(observationCreatedByTypes.CALL_CENTER_SUPPORT);
    if (isPhysician || isPOH || isCallCenterUser) {
      // create a string prefix
      label += [
        isPOH ? localize(`role.${ EhRole.Poh }`) : null,
        isPhysician ? localize(`role.${ EhRole.Physician }`) : null,
        isCallCenterUser ? localize('observations.callCenterUser') : null,
      ]
        .filter(s => !!s)
        .join(', ') + ': ';

      // add user info
      label += [this.firstName, this.middleName, this.lastName].filter(n => !!n).join(' ');
    }
    return label.length > 0 ? label : null;
  }
}
