<template>
  <div
    ref="container"
    :class="zoneClasses"
    :data-testid="`field-${$props.label}`"
    @click="$emit('click', $event)"
    @dragenter.prevent="setActive"
    @dragover.prevent="setActive"
    @dragleave.prevent="setInactive"
    @drop.stop.prevent="onDrop"
  >
    <slot>
      <div :class="[styles.dndContainer, inline && styles.dndInline, resetPadding && styles.withoutPadding]">
        <Icon
          v-if="icon"
          :class="styles.icon"
          :type="icon"
        />
        <span :class="styles.placeholder">
          <LabelTooltip
            :info="$props.info"
            :label="$props.label"
            :class="styles.label"
          />
        </span>
      </div>
    </slot>
  </div>
</template>

<script>
  import { Icon } from '@h4h/icons';

  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';

  import LabelTooltip from '../labelTooltip/LabelTooltip';

  import styles from './dropzone.scss';
  import { DropzoneMixins } from './dropzoneMixins';

  export default {
    name: 'H4hDropzone',

    components: {
      Icon,
      LabelTooltip
    },

    mixins: [
      InputMixin,
      DropzoneMixins
    ],

    props: {
      icon: {
        type: String,
        default: 'plus',
      },

      /** labels */
      // eslint-disable-next-line vue/require-prop-types
      label: {
        ...inputProps.label,
        default: 'common.action'
      },

      info: inputProps.label,

      /** flags */
      inline: inputProps.booleanFalse,
      resetPadding: inputProps.booleanFalse
    },

    data() {
      return {
        styles,
        active: false,
        showZones: false,
      };
    },

    computed: {
      zoneClasses() {
        const result = [styles.dndZone];
        if (this.showZones) {
          result.push(styles.dndVisible);
        }
        if (this.active) {
          result.push(styles.dndZoneActive);
        }
        if (this.disabled) {
          result.push(styles.disabled);
        }
        return result;
      }
    },
  };
</script>
