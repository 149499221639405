import { CrudService } from '../../../classes/crudService';
import { mapOrgUserModelToRestEntity, OrgUserModel } from '../../../model/orgUserModel';
import { PageModel } from '../../../model/page';

export const orgUsers = new CrudService(
  'organisation-users/',
  data => new OrgUserModel(data),
  mapOrgUserModelToRestEntity
);

export const orgUserAvatar = userId => new CrudService(
  `organisation-users/${ userId }/photo/`,
  data => data && data.base64Photo
);

export const orgUsersList = new CrudService(
  'organisation-users',
  data => new PageModel(data, model => new OrgUserModel(model))
);

export async function resetUserPassword(userId) {
  return new CrudService(`organisation-users/${ userId }/reset-passwords`).create({});
}

/**
 * Set temporary password for user
 *
 * @param {String} userId
 * @param {Object} config
 * @param {String} config.newPassword
 */
export async function setUserTemporaryPassword(userId, config) {
  return new CrudService(`organisation-users/${ userId }/temporary-passwords`).create(config);
}

/**
 * Send a request for a new activation email
 * @param {string} userUuid
 */
export async function sendOrgUserActivationEmail(userUuid) {
  return await new CrudService(`organisation-users/${ userUuid }/activation-links`).create();
}
