export class PhoneModel {
  /**
   * PhoneModel config
   *
   * @param {Object}                                   config
   * @param {Country}                                  config.countryCode
   * @param {Boolean}                                  config.primary
   * @param {String}                                   config.number
   * @param {PhoneTypePerson | PhoneTypeOrganization}  config.type
   * @param {String}                                   config.id
   * @param {String}                                   config.patientId
   * @param {Boolean}                                  config.sendReminder
   */
  constructor(config = {}) {
    this.id = config.id;
    this.countryCode = config.countryCode;
    this.primary = config.primary;
    this.number = config.number;
    this.type = config.type;
    this.sendReminder = config.sendReminder;
  }

  isEmpty() {
    return ['number'].every(field => !this[field]);
  }
}
