export const types = Object.freeze({
  SET_FETCHED: 'SET_FETCHED',
  SET_LOADING: 'SET_LOADING',
  SET_CC_SETTINGS_LOADING: 'SET_CC_SETTINGS_LOADING',
  SET_ORGANIZATION_SETTING: 'SET_ORGANIZATION_SETTING',
  SET_CC_SETTINGS: 'SET_CC_SETTINGS',
  INIT_FORM: 'INIT_FORM',
  SET_PRISTINE: 'SET_PRISTINE',
  SET_ADDRESS_COMPONENTS_PRISTINE: 'SET_ADDRESS_COMPONENTS_PRISTINE',
  INIT_POPUP: 'INIT_POPUP',
  SET_TIMEZONES: 'SET_TIMEZONES',
});
