import { CrudService } from '../../../classes/crudService';
import { RawEcgFile } from '../../../model/observation/rawEcgFile';

export const observationFiles = new CrudService(
  'observation-files',
  data => data,
  model => model
);

export const observationFilesRaw = new CrudService(
  'observation-files-raw',
  data => new RawEcgFile(data),
);

