export const state = {
  loading: false,
  patient: null,
  enrollments: [],
  enrollmentObsTemplatesMap: null,
  observationTemplates: [],
  enrollmentQuestionnaireTemplatesMap: null,
  questionnaireTemplates: [],
  programs: [],

  enrollmentsToStop: [],

  popupId: null,
  newEnrollmentDate: null,
  newEnrollmentAdvancedMode: null,
  newEnrollmentProgram: null,
  newEnrollmentPeriod: null,
  newEnrollmentSchedules: null,
  newEnrollmentSendDevicesToPatients: null,
  logisticsEnabled: false,

  navigationLinks: [],

  terminologyTypes: [],
  observationThresholds: [],
  observationThresholdInputsGroups: [],
  newEnrollmentUseCustomThresholds: null,

};
