<template>
  <div
    :class="[
      inputStyles.container,
      calendar && styles.calendar,
      $props.inline ? inputStyles.inline : styles.container
    ]"
  >
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />

    <label
      :for="id"
      :class="[
        $props.inputStyle,
        inputStyles.inputGroup,
        !$props.inline && styles.inputContainer,
        {
          focus: focused,
          disabled: $props.disabled,
          invalid: (!$props.pristine && !$props.valid) || errorMessage !== null,
          warning: $props.warning
        }
      ]"
    >
      <FlatPickr
        ref="input"
        :config="config"
        :value="$props.value"
        :events="flatPickrEvents"
        :class="inputStyles.input"
        :tabindex="tabIndex"
        :disabled="($props.disabled || $props.readonly)"
        :placeholder="$localize($props.placeholder)"
        :data-testid="`field-${$props.name}`"
        :autocomplete="autocomplete"
        :name="$props.name"
        @on-open="onFlatpickrOpen"
        @on-close="onFlatpickrClose"
        @input="onFlatpickrInput"
      />
      <div :class="[styles.icon, inputStyles.icon]">
        <Icon
          :type="time ? 'clock' : 'calendar'"
        />
      </div>
    </label>
    <div
      :class="inputStyles.errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';

  import { uniqueId } from 'lodash';
  import moment from 'moment';

  import 'flatpickr/dist/flatpickr.min.css';
  import 'flatpickr/dist/plugins/monthSelect/style.css';
  import FlatPickr from 'vue-flatpickr-component';
  import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

  import {
    DATE_FORMAT,
    DATETIME_FORMAT,
    formatDate,
    parseDate,
    parseDateTime,
    parseTime,
    TIME_FORMAT
  } from '@h4h/date';

  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';
  import { InputType } from '../../constants';

  import LabelGroup from '../labelGroup/LabelGroup';

  import styles from './dateInput.scss';

  import { getLocalValues } from '../../utils/dateLocales';
  import { validationHelper } from '@h4h/utils';
  import { ArrayProp } from '@h4h/classes';

  Vue.use(FlatPickr);

  export default {
    name: 'H4hDateInput',

    components: {
      FlatPickr,
      LabelGroup
    },

    mixins: [
      InputMixin,
      validationHelper
    ],

    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      value: [Date, Array],

      type: inputProps.type,

      autocomplete: {
        type: String,
        required: false,
        default: 'off'
      },

      min: Date,
      max: Date,

      locale: {
        type: [String, Object],
      },

      /** labels */
      info: inputProps.label,
      otherLabel: inputProps.label,
      placeholder: inputProps.label,

      /** flags */
      inline: inputProps.booleanFalse,
      warning: inputProps.booleanFalse,
      readonly: inputProps.booleanFalse,
      range: inputProps.booleanFalse,
      weekNumbers: inputProps.booleanFalse,

      /** styles */
      inputStyle: inputProps.style,

      allowedDates: new ArrayProp({
        type: Date,
        default: () => []
      }),

      large: inputProps.booleanFalse
    },

    data() {
      return {
        styles,
        inputStyles,
        preventFilling: false,
        focused: false,
        keepFocus: false,
        id: uniqueId('date-input-'),
        wasInputOpened: false,
        flatPickrEvents: [
          'onOpen',
          'onClose',
        ],
      };
    },

    computed: {
      date() {
        return this.$props.type === InputType.Date;
      },

      time() {
        return this.$props.type === InputType.Time;
      },

      dateTime() {
        return this.$props.type === InputType.DateTime;
      },

      calendar() {
        return this.$props.type === InputType.Calendar;
      },

      month() {
        return this.$props.type === InputType.MonthPicker;
      },

      format() {
        switch (true) {
          case this.date:
          case this.calendar:
            return DATE_FORMAT;
          case this.time:
            return TIME_FORMAT;
          case this.dateTime:
            return DATETIME_FORMAT;
          default:
            return null;
        }
      },

      plugins() {
        const { format } = this;
        if (this.month) {
          return [
            new monthSelectPlugin({
              altFormat: 'MM.Y',
              dateFormat: format,
              formatDate
            })
          ];
        }
        return [];
      },

      config() {
        const { format, time, dateTime, calendar, $props: { min, max, range, weekNumbers, allowedDates }, month, plugins } = this;

        // @todo: Baizulin - check formatting and parsing performance
        const config = {
          parseDate,
          formatDate,
          minDate: min,
          maxDate: max,
          time_24hr: true,
          allowInput: true,
          inline: calendar,
          noCalendar: time,
          dateFormat: format,
          enableTime: time || dateTime,
          altInput: !!month,
          plugins,
          mode: range ? 'range' : 'single',
          weekNumbers,
          clickOpens: false,
          getWeek: function(dateObj) {
            return moment(dateObj).isoWeek();
          },
          locale: {
            firstDayOfWeek: 1,
            ...this.getLocal
          }
        };

        if (this.hasAllowedDates) {
          config.enable = allowedDates;
        }

        return config;
      },

      flatpickr() {
        return this.$refs.input.fp;
      },

      getLocal() {
        return this.$props.locale || getLocalValues();
      },

      hasAllowedDates() {
        return !!this.$props.allowedDates?.length;
      }
    },
    mounted() {
      // flatpickr doesn't emit focus events, blur is made consistent with focus
      this.flatpickr.element.addEventListener('focus', this.onFocus);
      this.flatpickr.element.addEventListener('blur', this.onBlur);
      this.flatpickr.element.addEventListener('keydown', this.onKeyDown);
      this.flatpickr.element.addEventListener('click', this.onClick);

      if (this.$props.autofocus) {
        this.focus();
      }

      this.setSizingClass();
    },

    beforeDestroy() {
      this.flatpickr.element.removeEventListener('focus', this.onFocus);
      this.flatpickr.element.removeEventListener('blur', this.onBlur);
      this.flatpickr.element.removeEventListener('keydown', this.onKeyDown);
      this.flatpickr.element.removeEventListener('click', this.onClick);
    },

    methods: {
      setSizingClass() {
        const styleClass = this.$props.large ? this.styles.large : this.styles.standard;
        this.flatpickr.calendarContainer.classList.add(styleClass);
      },

      onFocus(e) {
        this.$emit('focus', e);
        this.focused = true;
      },

      onBlur(e) {
        this.validateRules(this.$props.value);
        if (!this.flatpickr.isOpen) {
          this.focused = false;
          this.wasInputOpened = false;
          this.$emit('blur', e);
        }
      },

      onClick() {
        this.flatpickr.open();
      },

      onKeyDown(e) {
        if (['Tab', 'Escape'].includes(e.code)) {
          return this.flatpickr.close();
        }

        return this.flatpickr.open();
      },

      onFlatpickrOpen() {
        this.focused = true;
        this.wasInputOpened = true;
        this.tabIndexSubscriber__onFocus();
      },

      onFlatpickrInput() {
        const curVal = this.$props.value;
        const newVal = this.getFlatpickrValue(true);
        if (this.isValidDate(newVal) && this.isNewValue(newVal, curVal)) {
          this.$emit('change', newVal);
          this.keepFocus = true;
          this.needToClose(newVal) && this.flatpickr.close();
        }
      },

      needToClose(newVal) {
        if (this.config.enableTime) {
          return false;
        }
        const { range } = this.$props;
        return !(range && newVal.length !== 2);
      },

      onFlatpickrClose() {
        // keep focus only if the datetime was already opened
        if (this.keepFocus && this.wasInputOpened) {
          this.focus();
        }
        else {
          this.flatpickr.element.blur();
        }

        const curVal = this.$props.value;
        const newVal = this.getFlatpickrValue();

        if (newVal !== curVal || (newVal && curVal && newVal.valueOf() !== curVal.valueOf())) {
          this.$emit('change', newVal);
        }

        this.$emit('blur', newVal);
      },

      focus() {
        this.$refs.input.$el.focus();
        this.focused = true;
        this.tabIndexSubscriber__onFocus();
      },

      // this one has to be a method because computed property's
      // return value caching prevents us from getting an actual value
      getFlatpickrValue(isStrict) {
        const { value } = this.flatpickr.element;

        if (!value) {
          return null;
        }

        const { time, dateTime, month, $props: { range, value: propsValue } } = this;

        if (range && this.flatpickr.selectedDates.length > 1) {
          const valuesFromArray = value.split(/ \D* ?/);
          return valuesFromArray.map(date => parseDate(date, undefined, isStrict));
        }
        else if (time) {
          const dateWithTime = moment(parseTime(value, undefined, isStrict));

          return propsValue
            ? moment(propsValue)
              .hours(dateWithTime.hours())
              .minutes(dateWithTime.minutes())
              .toDate()
            : dateWithTime.toDate();
        }
        else if (month) {
          return parseDate(value, null);
        }
        else if (!dateTime) {
          return parseDate(value, undefined, isStrict);
        }

        return parseDateTime(value, undefined, isStrict);
      },

      isNewValue(value, prevValue) {
        return (value && !prevValue || !value && prevValue) ||
          (!Array.isArray(value) && !moment(value).isSame(prevValue)) ||
          (Array.isArray(value) && value && value.some((val, index) => !moment(val).isSame(prevValue[index]))) ||
          (Array.isArray(value) && value.length !== prevValue.length);
      },

      // this has to be because during editing from keyboard we don't' want to set invalid temp values
      isValidDate(value) {
        const arrayOfDates = Array.isArray(value) ? value : [value];
        return arrayOfDates.every(date => {
          const mDate = moment(date);
          if (!mDate.isValid()) {
            return false;
          }
          else if (this.min && mDate.isBefore(this.min)) {
            return false;
          }
          else if (this.max && mDate.isAfter(this.max)) {
            return false;
          }
          return true;
        });
      },
    },
  };
</script>
