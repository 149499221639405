import { gender } from './gender';
import { group } from './group';
import { address } from './address';
import { common } from './common';
import { admin } from './admin/admin';
import { practitioner } from './practitioner';
import { messages } from './messages';
import { avatar } from './avatar';
import { patient } from './patient';
import { patients } from './patients';
import { appointments } from './appointments';
import { coreActivities } from './coreActivities';
import { correspondence } from './correspondence';
import { medication } from './medication';
import { timeline } from './timeline';
import { treatment } from './treatment';
import { orgUser } from './orgUser';
import { role } from './role';
import { country } from './country';
import { enums } from './enums';
import { enrollments } from './enrollments';
import { anamnesis } from './anamnesis';
import { observationTemplates } from './observationTemplates';
import { programs } from './programs';
import { tasks } from './tasks';
import { ecg } from './ecg';
import { questionnaireTemplates } from './questionnaireTemplates';
import { observations } from './observations';
import { callCenter } from './callCenter';
import { kardia } from './kardia/kardia';
import { table } from './table';
import { logistic } from './logistic';
import { tenant } from './tenant';
import { observationComment } from './observationComment';
import { language } from './language';
import { labResultGroups } from './labResultGroups';
import { organization } from './admin/organization';
import { questionnaire } from './questionnaire';
import { cronEditor } from './cronEditor';
import { healthRecords } from './healthRecords';
import { certification } from './certification';
import { person } from './person';
import { mandatoryPatientActions } from './mandatoryPatientActions';
import { phone } from './phone';
import { toastMessage } from './toastMessage';
import { troubleshooting } from './troubleshooting';
import { set } from 'lodash';
import { stopEnrollmentReasons } from './stopEnrollmentReasons';
import { notificationType } from '../../constants/notificationType';
import { pdf } from './pdf';

export const en = {
  logistic,
  tenant,
  anamnesis,
  address,
  admin,
  appointments,
  avatar,
  coreActivities,
  correspondence,
  common,
  table,
  country,
  ecg,
  enrollments,
  stopEnrollmentReasons,
  observations,
  questionnaire,
  enums,
  gender,
  group,
  medication,
  messages,
  orgUser,
  observationTemplates: observationTemplates,
  questionnaireTemplates,
  programs,
  patient,
  patients,
  practitioner,
  mandatoryPatientActions,
  role,
  timeline,
  treatment,
  tasks,
  callCenter,
  kardia,
  observationComment,
  language,
  labResultGroups,
  organization,
  cronEditor,
  healthRecords,
  certification,
  phone,
  person,
  toastMessage,
  troubleshooting,
  pdf,
};

set(en, notificationType.MONITORING_PERIOD_IS_ENDED,'The monitoring period has ended. Go to the patient page to review the results and average values.');
set(en, notificationType.PROGRAM_WAS_STOPPED,'Program {0} was stopped. Reason: {1}');
set(en, notificationType.PATIENT_IS_ENROLLED,'A new patient is enrolled');
set(en, notificationType.YOUR_PATIENT_HAS_SUBMITTED_QUESTIONNAIRE,'Your patient has submitted questionnaire');
set(en, notificationType.PATIENT_HAS_DISONNECTED_FROM_KARDIA,'Patient has been disconnected from Kardia Mobile');

