import { patients } from './patients';
import { users } from './users';

export const callCenter = {
  namespaced: true,
  modules: {
    patients,
    users
  }
};
