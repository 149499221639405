import { isNumber } from 'lodash';

import { PAGE_SIZE } from '@h4h/utils';

export class Pagination {

  /**
   * Pagination config
   *
   * @param {Object}            config
   * @param {Boolean}           config.empty
   * @param {Boolean}           config.first
   * @param {Boolean}           config.last
   * @param {Number}            config.totalElements
   * @param {Number}            config.totalPages
   * @param {Number}            config.page
   * @param {Number}            config.itemsPerPage
   * @param {Array}             config.sortBy
   * @param {Array}             config.sortDesc
   */
  constructor(config) {
    this.empty = config.empty;
    this.first = config.first;
    this.last = config.last;
    this.totalElements = config.totalElements;
    this.totalPages = config.totalPages;
    this.page = config.page;
    this.itemsPerPage = config.itemsPerPage;
    this.sortBy = config.sortBy;
    this.sortDesc = config.sortDesc;
  }

  static PAGE_SIZE = PAGE_SIZE;

  static fromApi(paginationProperties) {
    if (!paginationProperties) {
      return null;
    }

    const { number, size, totalElements, totalPages, sort } = paginationProperties;

    return new Pagination({
      totalElements,
      totalPages,
      page: isNumber(number) ? number + 1 : 0,
      itemsPerPage: size,
      sortBy: sort?.map(name => name.property) || [],
      sortDesc: sort?.map(name => name.direction === 'DESC') || [],
    });
  }

  static toApi(paginationParams) {
    const { page, itemsPerPage, sortBy, sortDesc } = paginationParams || {};
    return {
      page: page ? page - 1 : 0,
      sort: remapSorting(sortBy, sortDesc),
      size: itemsPerPage || Pagination.PAGE_SIZE,
    };
  }
}

function remapSorting(sortBy, sortDesc) {
  if (!sortBy || !sortBy.length) {
    return null;
  }
  return sortBy.map((sortName, index) => `${ sortName },${ sortDesc[index] ? 'desc' : 'asc' }`);
}
