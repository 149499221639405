import qs from 'qs';
import Axios from 'axios';
import { set } from 'lodash';

export const axios = Axios;

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.paramsSerializer = transformRequestOptions;

function transformRequestOptions(params) {
  return qs.stringify(params, { encode: false, indices: false });
}

axios.set = function(path, value) {
  set(axios, path, value);
};
