import { AddressModel, createInputConfig, InputType } from '@h4h/inputs';
import moment from 'moment';
import { ProductStatus } from '../../../constants/productStatus';
import { formatDate } from '@h4h/date';

export const getters = {
  inputs,
  address,
  linkToInstructions,
  serial,
  date,
  status,
};

function inputs() {
  const name = createInputConfig({
    id: 'name',
    type: InputType.Label,
    label: 'certification.nameDeveloper',
    value: 'Heart for Health ICT BV',
  });
  return [name];
}

function address() {
  return new AddressModel({
    city: 'Amsterdam',
    country: { code2: 'NL', name: 'Netherlands' },
    number: 12,
    postalCode: '1083 BA',
    street: 'Van Boshuizenstraat',
  });
}

function linkToInstructions() {
  return 'https://www.heartforhealth.com/gebruiksaanwijzingen';
}

function serial(state) {
  return state.productVersion?.version || '-';
}

function date(state) {
  let date = state.productVersion?.releaseDate;
  return date ? formatDate(moment(date).toDate()) : '-';
}

function status(state) {
  if (state.productVersion) {
    return state.productVersion.suffix;
  }
  return ProductStatus.UNKNOWN;
}

