import { EnrollmentMonitoringPeriodModel } from './enrollmentMonitoringPeriodModel';
import { mapArray, mapObject } from '../utils/mapUtils';
import { ProgramModel } from './programModel';
import { EnrollmentStatus } from '../constants/enrollmentStatus';
import { last, sortBy } from 'lodash';
import { ObservationThresholdModel } from './thresholds/observationThresholdModel';
import moment from 'moment';
import { OrgUserModel } from './orgUserModel';
import { ISO_DATE_FORMAT } from '@h4h/date';

class CreateEnrollmentOverriddenCronsModel {
  /**
   *
   * @param {Object} config
   * @param {String} config.overriddenCron
   * @param {String} config.programInputUuid
   */
  constructor(config) {
    config = config || {};
    this.overriddenCron = config.overriddenCron;
    this.programInputUuid = config.programInputUuid;
  }
}

export class CreateEnrollmentModel {
  /**
   * CreateEnrollmentModel config
   *
   * @param {Object}    config
   * @param {String}    config.patientUuid
   * @param {String}    config.programUuid
   * @param {String}    config.monitoringPeriodUuid
   * @param {String}    config.startDate
   * @param {Object}    config.observationThresholds
   * @param {Object}    config.programInputOverriddenCrons
   * @param {Boolean}                         config.sendDeviceToPatient
   */
  constructor(config) {
    config = config || {};

    this.patientUuid = config.patientUuid;
    this.programUuid = config.programUuid;
    this.monitoringPeriodUuid = config.monitoringPeriodUuid;
    this.startDate = config.startDate;
    this.observationThresholds = config.observationThresholds;
    this.programInputOverriddenCrons = mapArray(config.programInputOverriddenCrons, CreateEnrollmentOverriddenCronsModel);
    this.sendDeviceToPatient = config.sendDeviceToPatient;
  }
}

export class EnrollmentModel {
  /**
   * EnrollmentModel config
   *
   * @param {Object}                          config
   * @param {EnrollmentMonitoringPeriodModel} config.currentMonitoringPeriod
   * @param {String}                          config.patientUuid
   * @param {String}                          config.activationDate
   * @param {ProgramModel}                    config.program
   * @param {String}                          config.uuid
   * @param {String}                          config.enrollmentStatus
   * @param {String}                          config.monitoringPeriods
   * @param {String}                          config.stopReason
   * @param {OrgUserModel}                    config.stoppedBy
   * @param {ObservationThresholdModel[]}     config.observationThresholds
   * @param {Boolean}                         config.sendDeviceToPatient //deprecated - dont use it. Remove in future
   */
  constructor(config) {
    config = config || {};

    this.currentMonitoringPeriod = mapObject(config.currentMonitoringPeriod, EnrollmentMonitoringPeriodModel);
    this.patientUuid = config.patientUuid;
    this.activationDate = config.activationDate;
    this.program = mapObject(config.program, ProgramModel);
    this.uuid = config.uuid;
    this.enrollmentStatus = config.enrollmentStatus;
    this.monitoringPeriods = mapArray(config.monitoringPeriods, EnrollmentMonitoringPeriodModel);
    this.stopReason = config.stopReason;
    this.stoppedBy = mapObject(config.stoppedBy, OrgUserModel);
    this.observationThresholds = mapArray(config.observationThresholds, ObservationThresholdModel);
    this.sendDeviceToPatient = config.sendDeviceToPatient; // deprecated - dont use it
  }

  get active() {
    return !!(this.enrollmentStatus !== EnrollmentStatus.DEACTIVATED && this.currentMonitoringPeriod);
  }

  get monitoringPeriod() {
    if (this.currentMonitoringPeriod) {
      return this.currentMonitoringPeriod;
    }
    return last(sortBy(this.monitoringPeriods, 'realEndDate'));
  }

  get endIsOverridden() {
    return !!this.monitoringPeriod?.overriddenEnd && this.stoppedBy;
  }

  containsDate(date) {
    if (this.monitoringPeriod && this.monitoringPeriod.containsDate(date)) {
      return true;
    }
    return this.monitoringPeriods.some(monitoringPeriod => monitoringPeriod.containsDate(date));
  }

  getTotalDateRange() {
    if (!this.monitoringPeriods.length) {
      return { hasData: false };
    }
    let noFinish = false;
    if (this.active) {
      if (!this.monitoringPeriod.plannedEnd) {
        noFinish = true;
      }
    }
    const starts = this.monitoringPeriods
      .map(period => period.start)
      .map(date => moment(date).toDate())
      .sort();
    const ends = this.monitoringPeriods
      .map(period => period.realEndDate)
      .filter(date => !!date)
      .map(date => moment(date).toDate())
      .sort();
    return {
      hasData: true,
      start: starts[0],
      finish: noFinish || !ends.length ? null : ends[ends.length - 1],
    };
  }
}

export class StopEnrollmentModel {
  /**
   * StopEnrollmentModel config
   *
   * @param {Object}                          config
   * @param {String}                          config.reason
   * @param {String[]}                        config.enrollmentUuids
   * @param {Date}                            config.endDate
   */
  constructor({ reason, enrollmentUuids, endDate }) {
    this.reason = reason;
    this.enrollmentUuids = enrollmentUuids;
    this.endDate = endDate;
    if (endDate) {
      const end = moment(endDate);
      if (moment().isSame(end,'day')) {
        this.endDate = null;
      }
      else {
        this.endDate = end.format(ISO_DATE_FORMAT);
      }
    }
  }
}
