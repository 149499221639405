<template>
  <div
    ref="container"
    :class="[styles.container, _isFullTextShown ? styles.containerGrid : styles.containerFlex]"
  >
    <div
      ref="truncated"
      :class="!_isFullTextShown && styles.truncated"
    >
      {{ formattedValue }}
    </div>
    <div
      v-show="shouldExpand"
      ref="action"
      :class="[styles.showButton, !_isFullTextShown && styles.showButtonMargin]"
      @click="toggle"
    >
      {{ $localize(_isFullTextShown ? 'common.less' : 'common.more') }}
    </div>
  </div>
</template>

<script>
  import styles from './truncatedText.scss';
  import { tdLineHeight } from '../tableRow/tableRow.scss';

  export default {
    name: 'H4hTruncatedText',

    props: {
      formattedValue: {
        type: String,
        required: false,
        default: '', // handle null
      },
      fullTextShown: {
        type: Boolean,
        required: false,
        default: null, // null, so true/false from parent always overrides local value
      },
    },

    data() {
      return {
        styles,
        tdLineHeight,
        localFullTextShown: false,
        isMounted: false,
        charSpace: 0
      };
    },

    computed: {
      shouldExpand() {
        return this.formattedValue && this.formattedValue.length > this.charSpace;
      },

      _isFullTextShown: { // use fullTextShown.sync from parent, or use local state
        get() {
          return this.$props.fullTextShown ?? this.localFullTextShown;
        },
        set(v) {
          this.localFullTextShown = v;
          this.$emit('update:fullTextShown', v);
        }
      }
    },

    watch: {
      '_isFullTextShown'() {
        this.$nextTick(() => { // can only get new height after prop has updated
          this.setContainerHeight();
        });
      },
    },

    mounted() {
      // https://jefrydco.id/en/blog/safe-access-vue-refs-undefined/#safe-way
      const refKeys = ['container', 'truncated', 'action'];
      const waitForRefs = setInterval(() => {
        const allRefsLoaded = refKeys.every(ref => this.$refs[ref]);
        if (allRefsLoaded) {
          this.isMounted = true;
          this.setContainerHeight();
          clearInterval(waitForRefs);
        }
      }, 50);

      // container is divided by 16 because base font size is 1rem which is default 16px (depending on user settings)
      // we need it to calculate how many characters can fit in a cell
      this.charSpace = Math.floor(this.$refs.container.clientWidth / 16); // can't show half characters
    },

    methods: {
      toggle(e) {
        e.stopPropagation();
        this._isFullTextShown = !this._isFullTextShown;
      },
      setContainerHeight() {
        this.$refs.container.style.height = this._isFullTextShown
          ? this.$refs.truncated.clientHeight + this.$refs.action.clientHeight + 'px'
          : this.tdLineHeight;
      }
    }
  };
</script>
