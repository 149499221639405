import { types } from './types';
import { createMainFilterInput } from '../../../services/filter/utils/filterUtils';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_FETCHED](state, value) {
    state.fetched = value;
  },

  [types.SET_QUESTIONNAIRE_TEMPLATES](state, values) {
    state.questionnaireTemplates = values;
  },

  [types.INIT_PAGE](state) {
    state.filterInput = createMainFilterInput();
  },

  [types.UPDATE_FILTER](state) {
    state.filteredRows = (state.questionnaireTemplates || []).filter(questionnaireTemplate => {
      const filter = state.filterInput?.value.trim();
      if (filter && state.filterInput.valid) {
        return questionnaireTemplate.title.toLowerCase().includes(filter.toLowerCase());
      }
      return true;
    });
  },

};
