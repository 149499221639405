export const state = {
  loading: false,
  usersPage: null,
  nameFilterInput: null,
  emailFilterInput: null,
  generalSearchInput: null,
  roleFilterInput: null,
  serverSideOptions: {},
  filterParams: null,
};
