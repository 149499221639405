import { types } from './types';
import { getInputsMap } from '@h4h/inputs';
import { popups } from '../../../../../../services/popups';
import { PopupName } from '../../../../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { ObservationValueModel } from '../../../../../../model/observationValueModel';
import { BatchObservationModel, ObservationModel } from '../../../../../../model/observation/observationModel';
import { ObservationInputType } from '../../../../../../constants/observationInputType';
import { batchPatientObservations } from '../../../../../../services/api/observations/observations';
import { showRestError } from '../../../../../../utils/errors';
import { toast } from '../../../../../../services/toast';
import { localize } from '../../../../../../services/localize';
import { getGroupedProgramInputInfo, GroupedProgramInputTypes, observationCommentFieldName } from './utils';
import { CreationStatus } from '../../../../../../constants/creationStatus';
import { observationComments } from '../../../../../../services/api/observations/observationComments';

export const actions = Object.freeze({
  initDialog,
  close,
  save,
  closeChooseGroupPopup,
  chooseObservationGroup,
  chooseObservationWithoutGroup,
});

async function initDialog({ commit }, {
  observationTemplate,
  observationTemplates,
  terminologyTypes,
  patientId,
  enrollment
}) {
  const groupedObservationsInfo = getGroupedProgramInputInfo(observationTemplate, enrollment, GroupedProgramInputTypes.OBSERVATIONS);
  let group = groupedObservationsInfo.groups[0];
  if (groupedObservationsInfo.isNeedShowChooseGroupDialog()) {
    const { id, closed } = await popups.show({
      name: PopupName.ChooseObservationGroup,
      type: PopupType.Modal
    });
    commit(types.INIT_OBSERVATION_GROUPS_DIALOG, {
      observationGroups: groupedObservationsInfo.groups,
      hasProgramInputWithoutGroups: groupedObservationsInfo.hasProgramInputWithoutGroups,
      popupId: id,
    });
    const data = (await closed).data;
    if (!data) {
      return {
        closed: new Promise(resolve => resolve({ success: false }))
      };
    }
    group = data.group;
  }
  // no need group functionality for only one item in group
  if (group && group.programInputs.length < 2) {
    group = null;
  }
  const { id, closed } = await popups.show({
    name: PopupName.NewObservation,
    type: PopupType.Modal
  });
  commit(types.INIT_DIALOG, {
    id,
    observationTemplate,
    group,
    observationTemplates,
    terminologyTypes,
    patientId,
    enrollment
  });
  return { closed };
}

function close({ state }) {
  popups.hide({ id: state.popupId });
}

function getObservationValues(inputs, observationTemplate) {
  const inputsMap = getInputsMap(inputs);
  return observationTemplate.definitions
    .map(definition => {
      const input = inputsMap[definition.type];
      if (input) {
        /* eslint-disable no-inner-declarations */
        function provide(type) {
          if (type === definition.inputType) {
            return input.value;
          }
        }

        return new ObservationValueModel({
          type: definition.type,
          unitCode: definition.unitCode,
          valueBoolean: provide(ObservationInputType.BOOLEAN),
          valueDateTime: provide(ObservationInputType.DATE_TIME),
          valueInt: provide(ObservationInputType.INT),
          valueLocalDate: provide(ObservationInputType.LOCAL_DATE),
          valueLocalDateTime: provide(ObservationInputType.LOCAL_DATE_TIME),
          valueLocalTime: provide(ObservationInputType.LOCAL_TIME),
          valueNumeric: provide(ObservationInputType.NUMERIC),
          valueReference: provide(ObservationInputType.REFERENCE),
          valueString: provide(ObservationInputType.STRING),
        });
      }
    })
    .filter(value => !!value);
}

function closeChooseGroupPopup({ state }) {
  popups.hide({ id: state.observationGroupsPopupsId });
}

function chooseObservationGroup({ state }, group) {
  popups.hide({
    id: state.observationGroupsPopupsId,
    result: { group },
  });
}

function chooseObservationWithoutGroup({ state }) {
  popups.hide({
    id: state.observationGroupsPopupsId,
    result: {},
  });
}

async function save({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid || state.loading) {
    return false;
  }

  const observations = state.inputGroups.map(inputGroup => {
    const observationModel = new ObservationModel({
      patientId: state.patientId,
      terminologyType: inputGroup.observationTemplate.terminologyType,
      observationValues: getObservationValues(inputGroup.inputs, inputGroup.observationTemplate)
    });
    const observationDate = state.date.value;
    if (observationDate.getTime() !== state.originalObservationDate.getTime()) {
      observationModel.observationDate = observationDate;
    }
    return observationModel;
  });
  const model = new BatchObservationModel({ observations });

  commit(types.SET_LOADING, true);
  const result = await batchPatientObservations(state.patientId).create(model);
  commit(types.SET_LOADING, false);

  if (!result.success) {
    showRestError(result.error, 'messages.cantCreateObservation');
    return;
  }
  const resultWithError = result.data.results.find(result => result.status !== CreationStatus.SUCCESS);
  if (resultWithError) {
    toast.error(localize('messages.someOfObservationsWereNotSavedCorrectly'));
  }
  const comment = state.additionalInputs.find(input => input.id === observationCommentFieldName)?.value;
  if (comment) {
    const promises = result.data.results
      .filter(result => result.status === CreationStatus.SUCCESS)
      .map(result => result.uuid)
      .map(uuid => observationComments(uuid).create({
        value: comment
      }));
    const commentResults = await Promise.all(promises);
    const commentResultsError = commentResults.filter(result => !result.success).map(result => result.error)[0];
    if (commentResultsError) {
      showRestError(commentResultsError, 'messages.cantSaveObservationComment');
    }
  }
  if (resultWithError) {
    return;
  }

  toast.success(localize('messages.observationSaved'));

  popups.hide({
    id: state.popupId,
    result: result.success
  });
}
