import { ProductStatus } from '../../constants/productStatus';

export const certification = {
  nameDeveloper: 'Name developer',
  ehealthPortalInformation: 'eHealth Portal information',
  ehealthPortal: 'eHealth Portal',
  serialNumber: 'SERIAL NUMBER',
  releaseDate: 'Release date:',
  userManual: 'User manual',
  status: 'Status:',
  [ProductStatus.UNKNOWN]: 'Unknown',
  [ProductStatus.PRODUCTION]: '',
  [ProductStatus.IN_DEVELOPMENT]: 'In Development',
  [ProductStatus.WITHDRAWN]: 'Withdrawn',
  [ProductStatus.RECALLED]: 'Recalled',
};
