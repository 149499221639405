<template>
  <div
    :class="styles.app"
    data-app
  >
    <router-view/>
    <Popups :service="popups"/>
  </div>
</template>

<script>
  import { Popups } from '@h4h/popups';

  import '../../../styles/main.scss';
  import '../../../styles/grid.scss';
  import { popups } from '../../../services/popups';

  import styles from './app.scss';

  export default {
    name: 'MpApp',

    components: {
      Popups
    },

    data() {
      return {
        styles,
        popups
      };
    }
  };
</script>
