import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { findByKey } from '../../../../../../filters/findByKey';
import { kardiaConnectionOptions } from '../../../../../../constants/kardia/kardiaConnectionStatus';
import { localize } from '../../../../../../services/localize';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_KARDIA_REQUEST_IS_PERFORMING](state, value) {
    state.kardiaRequestIsPerforming = value;
  },

  [types.SET_KARDIA_PATIENT](state, kardiaPatient) {
    state.kardiaPatient = kardiaPatient;
    if (kardiaPatient) {
      const connectionStatus = createInputConfig({
        id: 'connectionStatus',
        type: InputType.Label,
        value: localize(findByKey(kardiaPatient.connectionStatus, 'value', kardiaConnectionOptions, 'label')),
        label: 'kardia.kardiaConnectionStatus',
      });
      state.inputs = [connectionStatus];
    }
    else {
      state.inputs = [];
    }
  },

  [types.INIT](state, { patientUuid, enrollment }) {
    state.enrollment = enrollment;
    state.patientUuid = patientUuid;
    state.kardiaPatient = null;
    state.inputs = [];
  },

};
