export const types = Object.freeze({
  INIT_POPUP: 'INIT_POPUP',
  SET_POPUP_ID: 'SET_POPUP_ID',
  UPDATE_EMAIL_TEMPLATE: 'UPDATE_EMAIL_TEMPLATE',
  SET_PHYSICIAN: 'SET_PHYSICIAN',
  SET_CALL_CENTER_BASE_URL: 'SET_CALL_CENTER_BASE_URL',
  SET_CHOOSE_TASK_POPUP_ID: 'SET_CHOOSE_TASK_POPUP_ID',
  SET_FORWARDING_IN_PROGRESS: 'SET_FORWARDING_IN_PROGRESS',
  SET_ORGANIZATION_SETTING: 'SET_ORGANIZATION_SETTING',
});
