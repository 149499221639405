export class AvatarFileModel {
  /**
   * AvatarFileModel config
   *
   * @param {Object}      config
   * @param {String}      config.name
   * @param {String}      config.content
   * @param {Number}      config.size
   */
  constructor(config) {
    config = config || {};
    this.name = config.name;
    this.content = config.content;
    this.size = config.size || 0;
  }
}
