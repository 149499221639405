export function disable(role) {
  return '-' + role;
}

export class RoleState {
  /**
   *
   * @param {String}  role
   * @param {Boolean} disabled
   */
  constructor({ role, disabled }) {
    this.role = role;
    this.disabled = disabled;
  }
}

/**
 *
 * @param {String[]}rawRoles
 * @return {RoleState[]}
 */
export function parseRoles(rawRoles) {
  return rawRoles.map(rawRole => {
    if (rawRole.startsWith('-')) {
      return new RoleState({
        role: rawRole.substring(1),
        disabled: true,
      });
    }
    return new RoleState({
      role: rawRole,
      disabled: false,
    });
  });
}
