import Vue from 'vue';
import { isNil } from 'lodash';
import VueRouter from 'vue-router';
import { parseRoles } from './routerPermissions';

Vue.use(VueRouter);

// todo - make PR to add this functionality to h4h router in packages
export class Router extends VueRouter {
  constructor(config) {
    super({
      mode: 'history',
      base: config.basePath,
      routes: config.routes,
      linkActiveClass: 'open active',
      scrollBehavior: () => ({ x: 0, y: 0 })
    });

    this.getAuth = config.getAuth || (() => config.auth);
    this.beforeEachHandler = config.beforeEachHandler;

    this.beforeEach(async(to, from, next) => {
      if (this.beforeEachHandler) {
        this.beforeEachHandler(to, from);
      }
      if (!before(to, next)) {
        return;
      }

      const roles = this.getRouteMeta('roles', to);

      if (!roles || !roles.length) {
        return next();
      }

      const auth = this.getAuth();
      if (!auth) {
        return next({
          name: config.forbiddenPage
        });
      }

      const authResult = await auth.login();
      if (!authResult.success || !this._checkRoles(roles)) {
        return next({
          name: config.forbiddenPage
        });
      }

      next();
    });
  }

  getRouteMeta(prop, route = this.currentRoute) {
    return route
      .matched
      .map(r => r.meta[prop])
      .filter(m => !isNil(m))
      .reverse();
  }

  // check that the user has at least one role from the list
  // of roles per each matched route with role guards
  _checkRoles(rolesPerMatchedRoute) {
    const auth = this.getAuth();
    return rolesPerMatchedRoute.every(matchedRouteRoles => {
      const roles = parseRoles(matchedRouteRoles);
      const enabledRoles = roles.filter(state => !state.disabled).map(state => state.role);
      const disabledRoles = roles.filter(state => state.disabled).map(state => state.role);
      // reject if has at least one disabled
      if (disabledRoles.length) {
        if (disabledRoles.some(role => auth.hasRole(role))) {
          return false;
        }
      }
      // allow if has at least one enabled
      if (enabledRoles.length) {
        return enabledRoles.some(role => auth.hasRole(role));
      }
      return true;
    });
  }
}

function before(to, next) {
  const before = to
    .matched
    .map(r => r.meta.before)
    .reverse()
    .find(b => !!b);

  return before ? before(to, next) : true;
}
