// this will be the first most left column if the table can be reordered by drag&drop
export const sortableColumnHeader = mapHeader({
  label: '',
  valueKey: '_sort',
});

export const selectColumnHeader = mapHeader({
  label: '',
  valueKey: '_select',
});

export const expandColumnHeader = mapHeader({
  label: '',
  valueKey: '_expand',
});

// this will be the last column if the table has row actions
export const actionsColumnHeader = mapHeader({
  label: '',
  valueKey: '_actions'
});

const ACTIONABLE_HEADERS = [
  actionsColumnHeader,
  expandColumnHeader,
  selectColumnHeader,
  sortableColumnHeader,
];

export const isActionableHeader = header => ACTIONABLE_HEADERS.includes(header);

export function mapHeader(column, { isSortedBy, desc } = {}) {
  return {
    // Mapped
    ...(column.header || {}),
    desc,
    isSortedBy,
    text: column.label,
    value: column.valueKey,
    sortKey: column.sortKey || column.valueKey,
    sort: column.header?.sort || (column.sortable && column.formatter && ((a, b) => {
      // If we have multipleItemsCell we use only the first item when sorting columns
      const formattedA = column.isMultipleItemsCell ? column.formatter(a)?.[0] : column.formatter(a);
      const formattedB = column.isMultipleItemsCell ? column.formatter(b)?.[0] : column.formatter(b);

      return (formattedA || '').localeCompare(formattedB || '');
    })),

    // Clones or adds key as undefined - is this intended?
    tooltip: column.tooltip,
    actions: column.actions,
    subLabel: column.subLabel,
    sortable: column.sortable,
    formatter: column.formatter,
    isMultipleItemsCell: column.isMultipleItemsCell,
    isTruncatedTextCell: column.isTruncatedTextCell,
  };
}
