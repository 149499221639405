<template>
  <div
    :class="[
      styles.select,
      inputStyles.container,
      $props.inline && inputStyles.inline
    ]"
  >
    <div
      v-if="$props.label || $props.showSelectAll"
      :class="[styles.titleBlock, $props.inline && styles.inlineTitle]"
    >
      <label
        v-if="$props.label"

        ref="label"
        :class="[inputStyles.label, $props.labelStyle]"

        @click="focus"
      >
        {{ $localize($props.label) }}
      </label>

      <button
        v-if="$props.showSelectAll && dropdown"
        :class="[styles.buttonLink, $props.selectAllStyle]"
        :disabled="($props.disabled || $props.readonly)"
        @click="toggleAll"
      >
        {{ $localize(toggleAllLabel) }}
      </button>
    </div>

    <div
      v-if="dropdown"
      v-mp-click-outside="close"
      :class="[$props.inputStyle, inputStyles.inputGroup, styles.inputGroup]"
    >
      <div
        ref="dropdown"
        :disabled="$props.disabled"
        :tabindex="$props.tabindex"
        :data-testid="`dropdown-${$props.name}`"
        :class="[
          styles.toggle,
          styles.wrapContent,
          (focused || expanded) && 'focus',
          $props.inputStyle,
          inputStyles.inputGroup,
          $props.disabled && 'disabled',
          !$props.pristine && !$props.valid && !expanded && 'invalid'
        ]"

        @keydown="onKeydown"
        @click="toggleDropdown"
        @blur="onBlur"
        @focus="onFocus"
      >
        <div
          :class="[
            styles.title,
            styles.wrapContent,
            'e2eDropdownTitle',
          ]"
        >
          <slot
            name="title"
            :title="title"
          >
            {{ title }}
          </slot>
        </div>
        <div
          ref="buttons"
          :class="styles.buttons"
        >
          <button
            v-if="$props.clearable"
            :class="styles.clear"
            :disabled="($props.disabled || $props.readonly)"
            @click="clearValue"
          >
            <Icon type="x-circle-invert"/>
          </button>
        </div>
      </div>

      <div :class="[styles.dropdown, expanded && styles.visible, $props.dropdownClass]">
        <SelectOptions
          ref="options"
          :value="$props.value"
          :options="$props.options"
          :tabindex="$props.tabindex"
          :disabled="$props.disabled"
          :multiple="$props.multiple"
          :optionsScroll="optionsScroll"
          @change="onChange"
          @close="close"
        >
          <template
            v-if="hasSlot('option')"
            slot="option"
            slot-scope="option"
          >
            <slot
              name="option"
              v-bind="option"
            />
          </template>
        </SelectOptions>
      </div>
    </div>

    <SelectOptions
      v-else
      :id="$props.id"
      ref="options"
      :value="$props.value"
      :options="$props.options"
      :tabindex="$props.tabindex"
      :disabled="$props.disabled"
      :multiple="$props.multiple"
      :inline="$props.inline"
      :checkboxStyle="$props.checkboxStyle"
      :optionsScroll="optionsScroll"
      :horizontal="$props.horizontal"
      :class="[$props.disabled && styles.disabled]"
      :linkedInputs="$props.linkedInputs"
      @change="onChange"
      @close="close"
      @change-other-option-input="onOtherChange"
    >
      <Checkbox
        v-if="$props.options && $props.options.length && $props.showSelectAll"
        :label="toggleAllLabel"
        name="select-all"
        :readonly="$props.readonly"
        :value="selectedAll"
        :class="[styles.checkbox, checkboxStyles.selectAll, styles.padding, $props.selectAllStyle]"
        @change="toggleAll"
      />
      <template
        v-if="$scopedSlots.option"
        slot="option"
        slot-scope="option"
      >
        <slot
          name="option"
          v-bind="option"
        />
      </template>
    </SelectOptions>
  </div>
</template>

<script>
  import { Icon } from '@h4h/icons';
  import { hasSlot } from '@h4h/utils';
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';

  import styles from './select.scss';
  import checkboxStyles from '../checkbox/checkbox.scss';
  import { isOptionActive } from './utils';
  import SelectOptions from './SelectOptions';
  import Checkbox from '../checkbox/Checkbox';
  import { ArrayProp } from '@h4h/classes';

  const handledKeys = new Set([
    'Space', 'Enter', 'ArrowDown', 'ArrowUp', 'Tab', 'Escape'
  ]);

  export default {
    name: 'H4hSelect',

    components: {
      SelectOptions,
      Checkbox,
      Icon,
    },

    mixins: [
      InputMixin
    ],

    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      id: String,
      value: [Object, String, Number, Boolean, Set],

      options: {
        type: Array,
        required: true
      },

      linkedInputs: new ArrayProp({
        type: Object,
        required: false
      }),

      titleFormatter: Function,

      /** labels */
      placeholder: inputProps.label,

      /** flags */
      inline: inputProps.booleanFalse,
      multiple: inputProps.booleanFalse,
      readonly: inputProps.booleanFalse,
      horizontal: inputProps.booleanFalse, // horizontal should be true only when dropdown is false
      dropdown: inputProps.booleanTrue,
      clearable: inputProps.booleanTrue,
      optionsScroll: inputProps.booleanTrue,
      showSelectAll: inputProps.booleanTrue,

      /** styles */
      inputStyle: inputProps.style,
      dropdownClass: inputProps.style,
      checkboxStyle: inputProps.style,
      selectAllStyle: inputProps.style
    },

    data() {
      return {
        styles,
        inputStyles,
        checkboxStyles,
        expanded: false,
        focusedOptionIndex: null,
        focused: false,
      };
    },

    computed: {
      title() {
        const { options, multiple, placeholder } = this.$props;
        let label = '';
        const titleFormatter = option => this.titleFormatter ? this.titleFormatter(option) : this.$localize(option.label);
        if (!multiple) {
          const option = this.options.find(o => isOptionActive(o, this.$props));
          label = option && titleFormatter(option);
        }
        else {
          label = options
            .filter(o => isOptionActive(o, this.$props))
            .map(o => titleFormatter(o))
            .join(', ');
        }

        return label || this.$localize(placeholder);
      },

      selectedAll() {
        const { value, options } = this.$props;
        return options.length === value?.size;
      },

      toggleAllLabel() {
        const { options, multiple } = this.$props;
        if (!multiple || !options.length) {
          return null;
        }
        return this.selectedAll ? 'common.deselectAll' : 'common.selectAll';
      }
    },

    methods: {
      hasSlot,
      focus() {
        this.$props.dropdown && this.$refs.dropdown.focus();
        this.$emit('focus', new FocusEvent('focus'));
      },

      open() {
        if (!this.$props.disabled) {
          this.expanded = true;
        }
      },

      close(e) {
        if (e && e.target === this.$refs.label) {
          return;
        }

        this.expanded = false;
        this.focusedOptionIndex = null;
        this.$emit('blur', new FocusEvent('blur'));
      },

      toggleDropdown() {
        if (this.$props.disabled) {
          return;
        }

        this.expanded ? this.close() : this.open();
      },

      onKeydown(e) {
        if (!handledKeys.has(e.code)) {
          return;
        }

        switch (e.code) {
          case 'Tab':
          case 'Escape':
            return this.close();

          case 'Space':
          case 'Enter':
            e.preventDefault();
            return this.expanded
              ? this.$refs.options.toggleFocusedOption()
              : this.open();

          case 'ArrowDown':
            e.preventDefault();
            return this.$refs.options.focusNextOption();

          case 'ArrowUp':
            e.preventDefault();
            return this.$refs.options.focusPreviousOption();
        }
      },

      onChange(value) {
        if (!this.$props.readonly) {
          this.$emit('change', value);

          if (!this.$props.multiple) {
            this.close();
          }
        }
      },

      onOtherChange(config) {
        this.$emit('change-other-option-input', config);
      },

      toggleAll() {
        this.$refs.options.toggleAllOptions();
      },

      onFocus(e) {
        this.$emit('focus', e);
        this.focused = true;
      },

      onBlur(e) {
        // prevent blur on select buttons click
        const buttonNodes = Array.from(this.$refs.buttons?.childNodes || []);
        if (buttonNodes.indexOf(e.relatedTarget) !== -1) {
          return;
        }
        this.focused = false;
        this.$emit('blur', e);
      },

      clearValue(e) {
        e.stopPropagation();
        this.$refs.dropdown.focus();
        if (this.$props.multiple) {
          return this.onChange(new Set());
        }
        this.onChange(null);
      }
    }
  };
</script>
