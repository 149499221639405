import { types } from './types';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_FETCHED](state, value) {
    state.fetched = value;
  },

  [types.SET_LAB_RESULT_GROUPS](state, values) {
    state.labResultGroups = values;
  },

};
