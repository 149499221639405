import { types } from './types';
import { QuestionnaireWrapper } from '../../../../../../components/questionnaire/wrapper/questionnaireWrapper';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_LOADING_QUESTIONNAIRE](state, value) {
    state.loadingQuestionnaire = value;
  },

  [types.SET_QUESTIONNAIRE](state, questionnaire) {
    state.questionnaire = questionnaire;
  },

  [types.INIT_FORM](state, { questionnaireTemplate, questionnaire, terminologyTypes }) {
    state.questionnaireTemplate = questionnaireTemplate;
    state.terminologyTypes = terminologyTypes;
    const wrapper = new QuestionnaireWrapper(questionnaireTemplate, questionnaire);
    state.questionnaire = questionnaire;
    state.inputs = wrapper.getInputs(terminologyTypes);
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

};
