import { localize } from '../../../services/localize';

/**
 * Defines ruleset message types
 */
export const rulesetInfoMessageTypes = Object.freeze({
  MESSAGE: 'MESSAGE',
  RULESET: 'RULESET',
});

/**
 * Generates some info text for the provided ruleset.
 *
 * @param {Object} ruleset
 * @param {Object} ruleset.seconds
 * @param {Object} ruleset.minutes
 * @param {Object} ruleset.hours
 * @param {Object} ruleset.daysOfMonth
 * @param {Object} ruleset.months
 * @param {Object} ruleset.daysOfWeek
 *
 * @returns {String}
 */
export function generateRulesetInfo(ruleset) {
  let info = [];

  info.push({
    type: rulesetInfoMessageTypes.MESSAGE,
    text: localize('cronEditor.validationSixParts'),
  });

  // helper to create a ruleset object
  const createRuleset = text => ({
    type: rulesetInfoMessageTypes.RULESET,
    text,
    rules: [],
  });

  for (const partialRulesetKey of Object.keys(ruleset)) {
    let rulesetObject = null;
    // determine the label
    switch (partialRulesetKey) {
      case 'seconds':
        rulesetObject = createRuleset(localize('cronEditor.seconds'));
        break;
      case 'minutes':
        rulesetObject = createRuleset(localize('cronEditor.minutes'));
        break;
      case 'hours':
        rulesetObject = createRuleset(localize('cronEditor.hours'));
        break;
      case 'daysOfMonth':
        rulesetObject = createRuleset(localize('cronEditor.daysOfMonth'));
        break;
      case 'months':
        rulesetObject = createRuleset(localize('cronEditor.months'));
        break;
      case 'daysOfWeek':
        rulesetObject = createRuleset(localize('cronEditor.daysOfWeek'));
        break;
      default:
        throw new Error(`Unknown property: ${ partialRulesetKey }`);
    }

    // append the rules
    const partialRuleset = ruleset[partialRulesetKey];
    // check for allowed values
    if (partialRuleset.allowedValues) {
      rulesetObject.rules.push(`${
        localize('cronEditor.validationValues')
      } ${
        partialRuleset.allowedValues
          .map(v => `'${ v }'`)
          .join(', ')
      }`);
    }
    // check for range info
    if (partialRuleset.range) {
      rulesetObject.rules.push(
        localize(
          'cronEditor.validationFromTo',
          partialRuleset.range,
        )
      );
    }
    // check for single numeric values
    if (partialRuleset.numericSingle) {
      rulesetObject.rules.push(localize('cronEditor.validationSingleNumber'));
    }
    // check for a list of numeric values
    if (partialRuleset.numericList) {
      rulesetObject.rules.push(localize('cronEditor.validationAListOfNumbers'));
    }
    // check for a range of numeric values
    if (partialRuleset.numericRange) {
      rulesetObject.rules.push(localize('cronEditor.validationARangeOfNumbers'));
    }
    // check for a stepping of numeric values
    if (partialRuleset.numericSteps) {
      rulesetObject.rules.push(localize('cronEditor.validationAStepValue'));
    }
    // check for weekdays
    if (
      partialRuleset.weekdaySingle ||
      partialRuleset.weekdayList ||
      partialRuleset.weekdayRange
    ) {
      rulesetObject.rules.push(localize('cronEditor.validationWeekdays'));
    }
    // check for a single weekday
    if (partialRuleset.weekdaySingle) {
      rulesetObject.rules.push(localize('cronEditor.validationAnyWeekday'));
    }
    // check for a list of weekdays
    if (partialRuleset.weekdayList) {
      rulesetObject.rules.push(localize('cronEditor.validationAListOfWeekdays'));
    }
    // check for a range of weekdays
    if (partialRuleset.weekdayRange) {
      rulesetObject.rules.push(localize('cronEditor.validationARangeOfWeekdays'));
    }

    // push the rules
    info.push(rulesetObject);
  }

  return info;
}