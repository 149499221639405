import { types } from './types';
import { toast } from '../../../../services/toast';
import { setUserTemporaryPassword } from '../../../../services/api/users/orgUsers';
import { localize } from '../../../../services/localize';
import { showRestError } from '../../../../utils/errors';

export const actions = Object.freeze({
  setTemporaryPassword,
  init,
});

async function setTemporaryPassword({ commit, state, getters }, id) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  commit(types.SET_PASSWORD_IS_UPDATING, true);

  let result = await setUserTemporaryPassword(id, { newPassword: state.password.value });
  if (result.success) {
    toast.success(localize('messages.passwordSuccessfullyUpdated'));
    commit(types.INIT);
  }
  else {
    showRestError(result.error, 'messages.errorUpdatingPassword');
  }

  commit(types.SET_PASSWORD_IS_UPDATING, false);
}

async function init({ commit }) {
  commit(types.INIT);
}

