import { CrudService } from '../../../classes/crudService';
import { LabResultGroupModel } from '../../../model/labResult/labResultGroupModel';

export const labResultGroupsList = new CrudService(
  'lab-result-group/all',
  data => new LabResultGroupModel(data),
);

export const labResultGroups = new CrudService(
  'lab-result-group',
  data => new LabResultGroupModel(data),
);
