// this function should split words by space if length of word is greather of 50
export function shortenNameForDropdown(name) {
  const maxLength = 40;
  if (name?.length > maxLength) {
    const words = name.split(' ');
    let result = [];
    for (let i = 0; i < words.length; i++) {
      let currentWord = words[i];
      if (currentWord.length > maxLength) {
        while (currentWord.length > maxLength) {
          result.push(currentWord.slice(0, maxLength));
          currentWord = currentWord.slice(maxLength);
        }
      }
      result.push(currentWord);
    }
    return result.filter(s => s.length).join(' ');
  }
  return name;
}

