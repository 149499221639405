export class IsUpdatable {
  /**
   * IsUpdatable config
   *
   * @param {Object}            config
   * @param {Boolean}            config.updatable
   */
  constructor(config) {
    this.updatable = config.updatable;
  }
}