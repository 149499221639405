import { types } from './types';
import { popups } from '../../../../../services/popups';
import { showRestError } from '../../../../../utils/errors';
import { PopupName } from '../../../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { toast } from '../../../../../services/toast';
import { localize } from '../../../../../services/localize';
import { stopEnrollments } from '../../../../../services/api/enrollments/enrollments';
import { StopEnrollmentModel } from '../../../../../model/enrollmentModel';
import { otherPredefinedReason } from './stopEnrollmentReasonsCodes';

export const actions = Object.freeze({
  stopProgramEnrollments,
  initStopPopup,
  closeStopPopup,
});

async function initStopPopup({ commit }, enrollments) {
  commit(types.INIT_POPUP, enrollments);
  const { id } = await popups.show({
    name: PopupName.EnrollmentStop,
    type: PopupType.Modal,
    props: { hideCloseButton: true }
  });
  commit(types.SET_POPUP_ID, id);
}

async function closeStopPopup({ state, commit }) {
  if (state.popupId) {
    await popups.hide({ id: state.popupId });
    commit(types.CLEAN_STATE);
  }
}

async function stopProgramEnrollments({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.valid) {
    return false;
  }
  commit(types.SET_LOADING, true);
  // popup swap
  popups.hide({ id: state.popupId });

  const { id: loadingPopupId } = await popups.show({
    name: PopupName.LoadingPopup,
    type: PopupType.Modal,
    props: {
      label: 'enrollments.stoppingEnrollments',
      hideCloseButton: true
    }
  });
  let reason = state.reasonInput.value;
  if (state.stopEnrollmentReasonsInput.value !== otherPredefinedReason) {
    let label = state.stopEnrollmentReasonsInput.options
      .find(option => option.value === state.stopEnrollmentReasonsInput.value)
      .label;
    reason = localize(label);
  }
  // stop the programs one by one
  const result = await stopEnrollments(new StopEnrollmentModel({
    enrollmentUuids: state.enrollments.map(enrollment => enrollment.uuid),
    reason,
    endDate: state.endDateInput.value,
  }));
  if (result.success) {
    toast.success(localize('messages.enrollmentsStopped'));
    await popups.hide({ id: loadingPopupId });
  }
  else {
    showRestError(result.error, 'messages.cantStopEnrollment');
  }
  commit(types.SET_LOADING, false);
  return result.success;
}

