import { ComponentStyle } from '@h4h/theme';

/**
 * Strength Level Config:
 *
 * The number of elements in the array will define the number of bars on screen.
 *
 * levels have to be sequential integers
 * title will be displayed on screen for each level
 * color defines the color of the bars under the input
 * minDiversity defines how many different types of chars have to be included
 * minLength is the required lenght of the password required for each level
 * minRequirement is the lowest level required for the password to be accepted
 */
export const defaultStrengthLevels = [
  {
    level: 1,
    title: 'passwordStrength.veryWeak',
    color: ComponentStyle.Danger,
    minDiversity: 0,
    minLength: 0
  },
  {
    level: 2,
    title: 'passwordStrength.weak',
    color: ComponentStyle.Warning,
    minDiversity: 2,
    minLength: 6
  },
  {
    level: 3,
    title: 'passwordStrength.normal',
    color: ComponentStyle.Success,
    // minRequirement should be true in just one level
    minRequirement: true,
    minDiversity: 3,
    minLength: 10
  },
  {
    level: 4,
    title: 'passwordStrength.strong',
    color: ComponentStyle.Success,
    minDiversity: 4,
    minLength: 12
  },
  {
    level: 5,
    title: 'passwordStrength.veryStrong',
    color: ComponentStyle.Success,
    minDiversity: 4,
    minLength: 14
  }
];

export const PasswordRequirement = Object.freeze({
  LowerCase: 'LowerCase',
  UpperCase: 'UpperCase',
  Number: 'Number',
  Symbol: 'Symbol',
  Length: 'Length'
});

/**
 *
 * @param {String}  password
 * @param {String}  [allowedSymbols]
 *
 * @returns {Object}
 * Sample: {
 *   level: 3,
 *   title: 'passwordStrength.strong',
 *   contains: ['lowercase', 'uppercase', 'symbol', 'number'],
 *   length: 13,
 *   color: ComponentStyle.Success,
 *   numberOfLevels: 5
 * }
 */
export function passwordStrength(password, strengthLevels, allowedSymbols = '#?!@$%^&*-') {

  const passwordCopy = password || '';

  const rules = [
    {
      regex: '[a-z]',
      requirement: PasswordRequirement.LowerCase
    },
    {
      regex: '[A-Z]',
      requirement: PasswordRequirement.UpperCase
    },
    {
      regex: '[0-9]',
      requirement: PasswordRequirement.Number
    },
    {
      regex: `[${ allowedSymbols }]`,
      requirement: PasswordRequirement.Symbol
    }
  ];

  let strength = {};

  strength.numberOfLevels = strengthLevels.length;
  strength.length = passwordCopy.length;

  // Find out which rules are met
  strength.contains = getMetRules(rules, passwordCopy);

  // get the highest fulfilled level
  const { level, title, color } = getHighestFulfilledLevel(strength, strengthLevels);
  Object.assign(strength, { level, title, color });

  const minLength = getMinLength(strengthLevels);
  let hints = getHints(strength, minLength);

  return { strength, hints };
}

export function getMetRules(rules, password) {
  return rules
    .filter(rule => new RegExp(rule.regex).test(password))
    .map(rule => rule.requirement);
}

export function getHighestFulfilledLevel(strength, strengthLevels) {
  return strengthLevels
    .filter(option => strength.contains.length >= option.minDiversity)
    .filter(option => strength.length >= option.minLength)
    .sort((o1, o2) => o2.level - o1.level)
    .shift();
}

export function getHints(strength, minLength) {
  let hints = [
    {
      text: ['passwordStrength.charLength', minLength],
      requirement: PasswordRequirement.Length,
      value: false,
    },
    {
      text: 'passwordStrength.1Number',
      requirement: PasswordRequirement.Number,
      value: false,
    },
    {
      text: 'passwordStrength.1Lower',
      requirement: PasswordRequirement.LowerCase,
      value: false,
    },
    {
      text: 'passwordStrength.1Upper',
      requirement: PasswordRequirement.UpperCase,
      value: false,
    },
    {
      text: 'passwordStrength.1Special',
      requirement: PasswordRequirement.Symbol,
      value: false,
    }
  ];

  hints.forEach(
    hint => hint.value = strength.contains.includes(hint.requirement) ? true : false
  );
  hints.find(
    ({ requirement }) => requirement === PasswordRequirement.Length
  ).value = strength.length >= minLength ? true : false;

  return hints;
}

export function getMinLength(strengthLevels) {
  return strengthLevels.find(o => o.minRequirement).minLength;
}
