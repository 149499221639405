import { ID_KEY, PARENT_ROW_ID_KEY } from '../constants/idKeys';
import BatchActions from '../components/batchActions/BatchActions';

export const Shared = {
  props: {
    // The field on each item object that designates a unique key.
    // The value of this property has to be unique for each item.
    idKey: {
      type: String,
      default: ID_KEY
    },

    // signals that this row is nested under some other
    parentRowIdKey: {
      type: String,
      default: PARENT_ROW_ID_KEY
    },

    // toggles row grouping on or off
    groupedRows: {
      type: Boolean,
      default: false
    },

    // the array of columns to display
    columns: {
      type: Array,
      required: true
    },

    // the array of actions -> API passed with [{ icon: string, show: boolean, action: function, label: string }]
    actions: {
      validator: prop => Array.isArray(prop) || prop === null
    },

    // the array of filter inputs
    filterInputs: {
      type: [Array, Function],
      required: false
    },

    // the array of expanded table rows
    expanded: {
      type: [Array],
      required: false
    },

    batchActions: BatchActions.props.actions,

    // shows the select checkboxes in both the header and rows
    showSelect: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasActions() {
      return !!this.$props.actions?.length;
    },

    hasRowSelectionColumn() {
      return (this.$props.showSelect || !!this.$props.batchActions?.length);
    },
  }
};
