import { state } from './state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { multipleFiltersProfiles } from '@h4h/multiple-table-filters';

export const preferences = {
  namespaced: true,

  state,
  actions,
  getters,
  mutations,
  modules: {
    multipleFiltersProfiles
  }
};
