import { list } from './list';
import { add } from './add';
import { edit } from './edit';

export const groups = {
  namespaced: true,
  modules: {
    list,
    add,
    edit,
  }
};
