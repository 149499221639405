export const anamnesis = {
  anamnesis: 'Anamnesis',
  anamnesisTemplate: 'Anamnesis template',
  chooseAnamnesisTemplate: 'Choose anamnesis template',
  openAnamnesisTemplate: 'Open anamnesis template',
  removeAnamnesisTemplate: 'Remove anamnesis template',
  pleaseConfirmRemoveAnamnesisTemplate: 'Please confirm removing anamnesis template',
  commentByMedicalPersonnel: 'Comment by medical personnel',

  risk: 'Risk',
  target: 'Target',
  feedback: 'Feedback',
  disclaimer: 'Disclaimer',
  sendFeedback: 'Send feedback',
  riskCalculations: 'Risk calculations',
  agreeWithResults: 'I agree with the results',
  preferableMedications: 'Preferable medications',
  disagreeWithResults: 'I disagree with the results',
  pleaseProvideYourFeedback: 'Please provide your feedback',
  hypertensionMedications: 'Hypertension medications',
  cholesterolMedications: 'Cholesterol medications',

  loadingMedications: 'Loading medication suggestions',

  if70orOlder: 'If 70 or older',
  prescriptions: 'Prescriptions',
  mostPrescribed: 'Most prescribed',
  // treatmentSteps: 'Treatment steps',
  patientCategory: 'Patient category',
  addPrescriptions: 'Add prescriptions',
  editPrescriptions: 'Edit prescriptions',
  prescriptionPrompt: 'What would you prescribe considering this suggestion?',

  // item labels by linkId (anamnesis):
  _397669002: 'What is your age?',
  _263495000: 'What is your gender?',
  _397731000: 'What is your ethnicity?',
  _410667008: 'What is your height?',
  _73211009: 'Diabetes mellitus',
  _439724007: 'First degree relatives with premature CVD (1)',
  _42399005: 'Renal failure syndrome (Kidney Failure)',
  _266995000: 'History of cardiovascular disease',
  _194828000: 'Angina pectoris',
  _399211009: 'History of myocardial infarction',
  _84114007: 'Heart failure',
  _74627003: 'Diabetic complication',
  _69896004: 'Active rheumatoid arthritis',
  _49436004: 'Atrial fibrillation',
  _399957001: 'Peripheral arterial disease',

  // item labels by linkId (hypertension questionnaire):
  _365981007: 'Do you smoke?',
  _726527001: 'What is your weight?',
  _68130003: 'Physical activity',

  // Placeholders for hypertension questionnaire fields:
  ph_365981007: 'Do you smoke?',
  ph_726527001: 'What is your weight?',
  ph_68130003: 'Physical activity',

  // translations of options in dropdowns:
  _248153007: 'Male',
  _248152002: 'Female',

  _315240009: 'African origin',
  _414152003: 'European origin',

  _moreThan150min: 'More than 150 min',
  _between75and150min: 'Between 75 and 150 min',
  _lessThan75min: 'Less than 75 min'
};
