import { KardiaPatientModel } from '../../../model/kardia/kardiaPatientModel';
import { CrudService } from '../../../classes/crudService';

export const kardiaPatients = new CrudService(
  '/alivecor/kardia/patients',
  data => new KardiaPatientModel(data)
);

/**
 *
 * @param {String} patientUuid
 */
export function requestNewConnectionCodeForKardiaPatient(patientUuid) {
  return new CrudService(
    `/alivecor/kardia/patients/${ patientUuid }/connection-code-requests`
  ).create({});
}
