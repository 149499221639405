import { question } from './question';
import { answer } from './answer';

export const popup = {
  namespaced: true,
  modules: {
    question,
    answer,
  }
};
