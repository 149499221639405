export const callCenter = {
  addRole: 'Add role',
  addUser: 'Add User',
  admin: 'Admin',
  availableRoles: 'Available roles',
  currentRoles: 'Current roles',
  editUserRoles: 'Edit user roles',
  filterUsers: 'Search user',
  profile: 'Profile',
  removeRole: 'Remove role',
  roleManagement: 'Role management',
  roles: 'Roles',
  tenantNote: 'You\'re currently viewing page for: {0}',
  userDetails: 'User details',
  users: 'Users',
};
