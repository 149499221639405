import { types } from './types';
import { getEditProfileInputConfig, getPopupInputConfig } from './forms';
import Vue from 'vue';

export const mutations = {
  /**
   *
   * @param state
   * @param {MultipleFiltersProfilesConfig} config
   * @param {TableFiltersProfile[]}         profiles
   */
  [types.INIT_FORM](state, { config, profiles }) {
    Vue.set(state.dataPerTable,config.tableId,{
      inputs: getPopupInputConfig(),
      profiles,
    });
  },
  [types.SET_PRISTINE](state, { inputs, pristine }) {
    inputs.forEach(input => input.pristine = pristine);
  },

  [types.SET_POPUP_ID](state, popupId) {
    state.popupId = popupId;
  },

  [types.INIT_PROFILE_EDITOR](state, profile) {
    state.editProfileInputs = getEditProfileInputConfig(profile.name);
  },
};

