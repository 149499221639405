import { Page } from '../../../constants/page';
import { EhRole } from '../../auth/ehRole';

export const LogisticUserRoutes = {
  path: 'logistic-user',
  name: Page.CallCenterLogisticUser,
  redirect: { name: Page.CallCenterLogisticUserTasks },
  component: () => import('../../../components/views/app/logistic-user/LogisticUserRoot'),
  meta: {
    title: 'logistic.logistic',
    roles: [EhRole.LogisticsSupport]
  },
  children: [
    {
      path: 'tasks',
      name: Page.CallCenterLogisticUserTasks,
      component: () => import('../../../components/views/app/logistic-user/tasks/Tasks'),
      props: true,
      meta: {
        title: 'logistic.logisticTasks',
      },
    },
    {
      path: 'patients',
      name: Page.CallCenterLogisticUserPatients,
      component: () => import('../../../components/views/app/logistic-user/patients/Patients'),
      redirect: { name: Page.CallCenterLogisticUserPatientsList },
      meta: {
        title: 'patient.patients'
      },
      children: [
        {
          path: 'list',
          name: Page.CallCenterLogisticUserPatientsList,
          component: () => import('../../../components/views/app/logistic-user/patients/list/LogisticPatientsList'),
          meta: {
            title: 'patient.patients'
          }
        },
        {
          path: 'view/:userId',
          redirect: { name: Page.CallCenterLogisticUserPatientDetails },
          props: true,
          name: Page.CallCenterLogisticUserPatientsView,
          component: () => import('../../../components/views/app/logistic-user/patients/view/LogisticViewPatient'),
          meta: {
            title: 'patient.patient',
          },
          children: [
            {
              path: 'details',
              name: Page.CallCenterLogisticUserPatientDetails,
              component: () => import('../../../components/views/app/logistic-user/patients/view/details/LogisticPatientDetailsComponent'),
              meta: {
                title: 'patient.patientDetails',
              },
            },
            {
              path: 'programs',
              name: Page.CallCenterLogisticUserPatientEnrollments,
              component: () => import('../../../components/views/app/logistic-user/patients/view/programs/LogisticPatientProgramsComponent'),
              meta: {
                title: 'enrollments.enrollments',
              },
            },
          ]
        }
      ]
    }]
};
