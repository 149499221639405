import { map, compact } from 'lodash';
import { Sortable } from 'sortablejs';

import { safeSetTimeout } from '@h4h/utils';

import styles from '../components/tableRow/tableRow.scss';

export const SortableRows = {
  props: {
    // Adds drag & drop functionality to the table rows
    sortable: {
      type: Boolean,
      default: false
    },

    // a group option
    sortableGroup: {
      type: String,
      default: ''
    },

    // sortable option
    draggableSort: {
      type: Boolean,
      default: true
    },

    draggableTableType: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      sortable__instance: null,
      sortable__timeout: null
    };
  },

  mounted() {
    this.sortable__update();
  },

  beforeDestroy() {
    this.sortable__destroy();
  },

  methods: {
    sortable__body() {
      return this.$el.querySelector('.v-data-table__wrapper tbody');
    },

    sortable__rows() {
      return this.sortable__body().querySelectorAll('tr');
    },

    sortable__init() {
      this.sortable__destroy();

      if (this.sortable__timeout) {
        clearTimeout(this.sortable__timeout);
      }

      this.sortable__timeout = safeSetTimeout(() => {
        this.sortable__setIds();

        this.sortable__instance = new Sortable(this.sortable__body(), {
          swapThreshold: .5,
          handle: '.' + styles.sortAnchor,
          filter: '.ignore-elements',
          draggable: '[id]:not(.ignore-elements)',
          sort: this.$props.draggableSort,
          group: this.$props.sortableGroup,

          onAdd: this.sortable__onAdd,
          onEnd: this.sortable__onEnd,
          onSort: this.sortable__onSort,
          onClone: this.sortable__onClone,
          onStart: this.sortable__onStart
        });
      });
    },

    sortable__update() {
      if (this.$props.sortable) {
        this.forceUpdate();
        this.sortable__init();
      }
      else {
        this.sortable__destroy();
      }
    },

    sortable__destroy() {
      if (this.sortable__instance) {
        this.sortable__instance.destroy();
        this.sortable__instance = null;
      }
    },

    sortable__setIds() {
      // set row id and table type as data attribute
      this.sortable__body().setAttribute('data-table-source', this.$props.draggableTableType || '');
    },

    sortable__onClone({ item }) {
      // get all widths before modifying DOM to prevent unnecessary reflows
      const tds = Array.from(item.querySelectorAll('td'));
      const widths = tds.map(td => td.getBoundingClientRect().width);

      // hardcoded width needs to be set for each column because dragged item has
      // position: fixed which makes it lose auto-calculated td widths
      tds.forEach((td, i) => {
        td.style.width = widths[i] + 'px';
      });
    },

    sortable__onSort({ from, to }) {
      if (from !== to) {
        return;
      }

      const fromItemIds = map(from.querySelectorAll('tr'), row => row.getAttribute('id'));
      this.$emit('sort', compact(fromItemIds));
    },

    sortable__onAdd({ item, to, from }) {
      this.$emit(
        'move',
        {
          id: item.getAttribute('id'),
          listType: to.getAttribute('data-table-source'),
          prevListType: from.getAttribute('data-table-source')
        }
      );
    },

    sortable__onStart() {
      // hack - can't implement it via CSS
      document.body.style.cursor = 'grabbing';
    },

    sortable__onEnd({ item }) {
      // remove hardcoded widths
      // https://github.com/SortableJS/Sortable/issues/347

      document.body.style.cursor = '';

      item.querySelectorAll('td').forEach(td => {
        td.removeAttribute('style');
      });

      this.$emit('end', item);
    }
  }
};
