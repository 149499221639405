export class TenantAddressModel {
  /**
   * TenantAddressModel config
   *
   * @param {Object}      config
   * @param {String}      config.baseUrl
   */
  constructor(config) {
    config = config || {};

    this.baseUrl = config.baseUrl;
  }
}
