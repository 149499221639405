import { urlRegex } from './wysiwygUtils';

const decimalRegex = '(\\d+)';
const floatRegex = '(\\d+(?:,\\d+)?)';

export const Patterns = Object.freeze({
  Date: /\d{2}-\d{2}-\d{4}/,
  Email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  Initials: /^((?<!\s)\D){0,10}$/,
  Text: /^$|^[^0-9.]*$/,
  Letters: /^[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+$/,
  Number: /^[0-9]*$/,
  LettersNumbers: /^[0-9A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]+$/,
  IntRangeOrIntValue: createRangeRegex(decimalRegex),
  FloatRangeOrFloatValue: createRangeRegex(floatRegex),
  Url: urlRegex
});

function createRangeRegex(item) {
  return new RegExp(`^${ item }(?:-${ item })?$`);
}
