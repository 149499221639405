import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';

export const mutations = {
  [types.INIT_FORM](state, terminologyTypes) {
    state.templates = [];
    state.inputs = getInputsConfig(terminologyTypes);
    state.inputsMap = getInputsMap(state.inputs);
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.ADD_OBSERVATION_TEMPLATE](state, template) {
    state.templates.push(template);
  },

  [types.REMOVE_TEMPLATE](state, template) {
    const idx = state.templates.indexOf(template);
    if (idx > -1) {
      state.templates.splice(idx, 1);
    }
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  }
};

function getInputsConfig() {
  const name = createTextInputConfig('name', 'common.name', InputType.Text);

  return [
    name,
  ];
}

function createTextInputConfig(id, label, inputType) {
  return createInputConfig({
    id,
    type: inputType,
    label,
    required: true,
  });
}
