export const state = {
  loading: false,
  orgUsersPage: null,
  nameFilterInput: null,
  emailFilterInput: null,
  groupFilterInput: null,
  generalSearchInput: null,
  filterParams: null,
  serverSideOptions: {},
};
