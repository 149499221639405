import Vue from 'vue';

import { i18n } from './i18n';

Vue.prototype.$l = localize;
Vue.prototype.$localize = localize; // compatibility with packages (medical portal)

function firstParameterIsAnArray(list) {
  if (Array.isArray(list) && list.length === 1) {
    if (Array.isArray(list[0])) {
      return true;
    }
  }
  return false;
}

/**
 * @param {string} message - either vue-i18n template or a simple string
 * @param {Array} [rest] - one or two more arguments. If the first of them is a string it will be used as a locale id,
 *                         the last one should be an array or an object and will be used to fil in the template expressions
 * @returns string
 */
export function localize(message, ...rest) {
  let key = message;
  let params = rest;
  if (!firstParameterIsAnArray(params)) {
    params = [params];
  }
  if (Array.isArray(message)) {
    key = message[0];
    params = [message.slice(1)];
  }
  if (i18n.te(key)) {
    return i18n.t(key, ...params);
  }

  return key;
}

export function localizeIfExistsOrDefault(message, defaultMessage, ...rest) {
  if (message == null) {
    return defaultMessage;
  }
  if (i18n.te(message)) {
    return i18n.t(message, ...rest);
  }
  return defaultMessage;
}

export function localizeEnumConstant(value, options) {
  const label = options.find(option => option.value === value)?.label;
  return label ? localize(label) : value;
}
