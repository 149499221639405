export const types = Object.freeze({
  UPDATE_FILTER_PARAMS: 'UPDATE_FILTER_PARAMS',
  SET_POPUP_ID: 'SET_POPUP_ID',
  SET_TASKS_FOR_HANDLE: 'SET_TASKS_FOR_HANDLE',
  SET_PROGRAMS: 'SET_PROGRAMS',
  INIT: 'INIT',
  SET_LOADING: 'SET_LOADING',
  SET_TASKS_PAGE: 'SET_TASKS_PAGE',
  SET_PAGINATION_CONFIG: 'SET_PAGINATION_CONFIG',
  SET_SELECTED_TASKS: 'SET_SELECTED_TASKS',
});
