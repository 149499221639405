import { toast } from '../../services/toast';
import { localize } from '../../services/localize';
import {
  pageablePractitionerTasks,
  practitionerTasksBatch
} from '../../services/api/practitionerTasks/pageablePractitionerTasks';
import { callCenterPractitionerTasks } from '../../services/api/practitionerTasks/callCenterPractitionerTasks';
import { callCenterPatientsBundle } from '../../services/api/patients/callCenterPatietns';
import { patientsBundle } from '../../services/api/patients/patients';

import { PopupName } from '../../constants/popupName';
import { popups } from '../../services/popups';

import { types } from './types';
import { PopupType } from '@h4h/popups';
import { callCenterPrograms } from '../../services/api/programs/callCenterPrograms';
import { programs } from '../../services/api/programs/programs';
import moment from 'moment';
import { getHeadersXTenantId } from '../../utils/getHeaders';
import { PractitionerTaskBatchModel, PractitionerTaskModel } from '../../model/practitionerTaskModel';
import { mapDataTablePageConfigToSpringPageConfig } from '../../model/page';
import { Ecg, ecgKey } from '../../model/observation/ecg';
import { observationFilesRaw } from '../../services/api/observations/observationFiles';
import { showRestError } from '../../utils/errors';
import {
  practitionerTasksObservationTerminologyTypes
} from '../../services/api/practitionerTasks/practitionerTasksObservationTerminologyTypes';
import {
  callCenterTasksObservationTerminologyTypes
} from '../../services/api/practitionerTasks/callCenterTasksObservationTerminologyTypes';
import { setInputValueAction } from '@h4h/inputs';
import { getUserTableDefaultFiltersProfile } from '../profile/preferences/utils';
import { setCountryCodeParamIfNeed } from '../i18n/countryCode/utils';

const callCenterTenantId = 'call-center';

/**
 * Wrap actions into a proxy that automatically passes in
 * the task list object model based on the task list type.
 */
function listTypeActionProxy(fn) {
  return async function(context, { listType, payload }) {
    const listObject = context.state.taskLists[listType];
    return await fn(context, { listObject, payload });
  };
}

/**
 * Action, initalizes the list model and loads the programs
 */
async function initializeList({ state, commit, dispatch }, { listType, tenantId, terminologyTypes }) {
  commit(types.INIT_LIST_MODEL, listType);
  commit(types.SET_TENANT_ID, tenantId);
  const [tableFiltersProfile] = await Promise.all([
    getUserTableDefaultFiltersProfile(dispatch, state.taskLists[listType].userFiltersTableId),
    loadPrograms({ state, commit, tenantId }),
    loadObservationTerminologyTypes({ state, commit, dispatch, tenantId })
  ]);
  // when the programs are loaded proceed with the list init
  commit(types.POPULATE_LIST_MODEL, { listType, terminologyTypes, tableFiltersProfile });
}

/**
 * Loads all relevant programs.
 * Won't fire the request twice if loading is already in progress!
 */
async function loadPrograms({ state, commit, tenantId }) {

  let programsList = [];
  const programsProvider = tenantId === callCenterTenantId ? callCenterPrograms : programs;

  // check if we're already fetching programs
  let programsPromise;
  if (state.programsLoading) {
    programsPromise = state.programsLoading;
  }
  else {
    programsPromise = programsProvider.fetch();
    commit(types.SET_PROGRAMS_LOADING, programsPromise);
  }
  // wait for the response
  const programsResponse = await programsPromise;
  if (programsResponse.success) {
    programsList = programsResponse.data;
  }
  else {
    toast.error(localize('messages.cantFetchPrograms'), programsResponse.error);
  }

  // commit the relevant data
  commit(types.SET_PROGRAMS, programsList);
  commit(types.SET_PROGRAMS_LOADING, null);
}

/**
 * Loads all observation templates.
 * Won't fire the request twice if loading is already in progress!
 */
async function loadObservationTerminologyTypes({ state, commit, dispatch, tenantId }) {

  let terminologyTypesList = [];
  const isCallCenter = tenantId === callCenterTenantId;
  const terminologyTypesListProvider = isCallCenter ? callCenterTasksObservationTerminologyTypes : practitionerTasksObservationTerminologyTypes;

  // check if we're already fetching terminology types
  let terminologyTypesPromise;
  if (state.observationTemplatesLoading) {
    terminologyTypesPromise = state.observationTemplatesLoading;
  }
  else {
    const params = {};
    if (isCallCenter) {
      await setCountryCodeParamIfNeed(dispatch, params);
    }
    terminologyTypesPromise = terminologyTypesListProvider.fetch({ params });
    commit(types.SET_OBSERVATION_TEMPLATES_LOADING, terminologyTypesPromise);
  }
  // wait for the response
  const terminologyTypesResponse = await terminologyTypesPromise;
  if (terminologyTypesResponse.success) {
    terminologyTypesList = terminologyTypesResponse.data;
  }
  else {
    toast.error(localize('messages.cantFetchObservationTemplates'), terminologyTypesResponse.error);
  }

  // commit the relevant data
  commit(types.SET_OBSERVATION_TEMPLATES, terminologyTypesList);
  commit(types.SET_OBSERVATION_TEMPLATES_LOADING, null);
}

/**
 * Proxied action. Updates tasks list selection.
 */
const selectionUpdate = listTypeActionProxy(
  async function({ commit }, { listObject, payload }) {
    commit(types.SET_SELECTED_TASKS, { listObject, value: payload });
  }
);

/**
 * Proxied action. Sets relevant input's value and reloads the list data.
 */
const setFilterLastDays = listTypeActionProxy(
  async function({ commit, state, dispatch }, { listObject, payload }) {
    const input = listObject.inputsMap.period;
    const finish = input.max;
    const start = moment(finish).add(-payload, 'd').startOf('d').toDate();
    await setInputValueAction({ commit, dispatch }, { input, value: [start, finish] });
    commit(types.UPDATE_FILTER_PARAMS, { listObject });
    await reloadData({ commit, state, dispatch }, { listObject });
  }
);

/**
 * Proxied action. Sets relevant input's value and reloads the list data.
 */
const onFilterChange = listTypeActionProxy(
  async function({ commit, state, dispatch }, { listObject }) {
    commit(types.UPDATE_FILTER_PARAMS, { listObject });
    await reloadData({ commit, state, dispatch }, { listObject });
  }
);

/**
 * Proxied action. Reloads the tasks list data.
 */
const reload = listTypeActionProxy(
  async function({ commit, state, dispatch }, { listObject, payload: { dataTableConfig, taskId = null } }) {
    commit(types.SET_CURRENT_FORWARDING_TASK_ID, taskId);
    const config = dataTableConfig || listObject.tableOptions;
    commit(types.SET_PAGINATION_CONFIG, { listObject, value: config });
    await reloadData({ commit, state, dispatch }, { listObject });
  }
);

const onProfileActivated = listTypeActionProxy(
  async function({ commit, dispatch }, { listObject, payload }) {
    commit(types.APPLY_FILTERS, { listObject, profile: payload });
    await dispatch('reload', { listType: listObject.type, payload: {} });
  }
);

async function addEcgFilesToTasks(tasks) {
  const ecgObservationsTasks = tasks.filter(task => task.observation?.payload?.terminologyType === ecgKey);
  if (!ecgObservationsTasks.length) {
    return;
  }
  const promises = ecgObservationsTasks
    .map(task => {
      const ecg = new Ecg(task.observation?.payload);
      let config = {};
      if (task.tenantId) {
        config = getHeadersXTenantId(task.tenantId);
      }
      return observationFilesRaw.get(ecg.documentReference, config);
    });
  const results = await Promise.all(promises);
  ecgObservationsTasks.forEach((task, index) => {
    const result = results[index];
    task.rawEcg = null;
    if (result.success) {
      if (!result.data.empty) {
        task.rawEcg = result.data;
      }
    }
    else {
      showRestError(result.error, 'messages.cantFetchEcgData');
    }
  });
}

/**
 * Performs a data reload on a tasks list.
 */
async function reloadData({ commit, state, dispatch }, { listObject }) {
  let params = {
    ...mapDataTablePageConfigToSpringPageConfig(listObject.serverSideOptions || {}),
    ...(listObject.filterParams || {}),
  };

  commit(types.SET_LIST_LOADING, { listObject, value: true });
  const isCallCenter = state.tenantId === callCenterTenantId;
  const tasksProvider = isCallCenter ? callCenterPractitionerTasks : pageablePractitionerTasks;
  if (isCallCenter) {
    await setCountryCodeParamIfNeed(dispatch, params);
  }
  const tasksResponse = await tasksProvider.fetchOne({ params });
  if (!tasksResponse.success) {
    toast.error(localize('messages.cantFetchTasks'), tasksResponse.error);
    commit(types.SET_LIST_LOADING, { listObject, value: false });
    commit(types.SET_TASKS_PAGE, { listObject, value: null });
    return;
  }

  let tasks = tasksResponse.data.content;

  let patientIamIds = new Set();
  const enrollmentsIds = new Map();
  tasks.forEach(item => {
    const enrollment = item.monitoringPeriod && item.monitoringPeriod.enrollment;
    patientIamIds.add(enrollment ? item.monitoringPeriod.enrollment.patientIamId : item.patientIamId);
    if (enrollment && enrollment.id) {
      if (item.observation && item.observation.payload) { // only for observations
        if (!enrollmentsIds.has(item.tenantId)) {
          enrollmentsIds.set(item.tenantId, new Set());
        }
        enrollmentsIds.get(item.tenantId).add(enrollment.id);
      }
    }
  });

  const [patients] = await Promise.all([
    fetchTasksPatients(Array.from(patientIamIds).join(','), state.tenantId),
    addEcgFilesToTasks(tasks)
  ]);

  if (patients && patients.length > 0) {
    tasks.forEach(task => {
      patients.forEach(patient => {
        if ((patient.iamId === task.patientIamId) ||
          (task.monitoringPeriod &&
            task.monitoringPeriod.enrollment &&
            (patient.iamId === task.monitoringPeriod.enrollment.patientIamId))) {
          task.patient = patient;
        }
      });
    });
  }
  await addEcgFilesToTasks(tasks);

  commit(types.SET_LIST_LOADING, { listObject, value: false });
  commit(types.SET_TASKS_PAGE, { listObject, value: tasksResponse.data });
  commit(types.SET_SELECTED_TASKS, { listObject, value: [] });
}

/**
 * Non-action. Fetches patients list.
 * @param {Object} params
 * @param {String} tenantId
 */
async function fetchTasksPatients(params, tenantId) {
  const patientsProvider = tenantId === callCenterTenantId ? callCenterPatientsBundle : patientsBundle;
  const patientsResponse = await patientsProvider.fetch({
    params: {
      iam: params
    }
  });
  if (!patientsResponse.success) {
    return toast.error(localize('messages.cantFetchTasks'), patientsResponse.error);
  }
  return patientsResponse.data;
}

/* Global task handling functions */

/**
 * Shows the task batch handling popup.
 * @param {Object}  context
 * @param {Object}  payload
 * @param {Array}   payload.tasks
 */
async function showHandleTaskPopup({ commit }, { tasks }) {
  commit(types.SET_HANDLING, false);
  const { id, closed } = await popups.show({
    type: PopupType.Modal,
    name: PopupName.HandleTask,
  });
  commit(types.SET_POPUP_ID, id);
  commit(types.SET_TASKS_FOR_HANDLE, tasks);
  return await closed;
}

/**
 * Sets the tasks to be handled in the future.
 * @param {Object}  context
 * @param {Object}  payload
 * @param {Array}   payload.tasks
 */
async function setTasksForHandle({ commit }, { tasks }) {
  commit(types.SET_POPUP_ID, null);
  commit(types.SET_TASKS_FOR_HANDLE, tasks);
}

/**
 * Handles the batch selected tasks.
 * @param {Object}  context
 * @param {Object}  data
 */
async function handleTask({ state, commit }, data) {
  commit(types.SET_HANDLING, true);
  const tenants = new Map();
  state.tasksForHandle
    .map(task => new PractitionerTaskModel({
      ...task,
      id: task.universalUuid,
      handlingType: data.handlingType,
    }))
    .forEach(modifiedTask => {
      const key = modifiedTask.tenantId;
      if (!tenants.has(key)) {
        tenants.set(key, []);
      }
      tenants.get(key).push(modifiedTask);
    });
  const promises = [];
  for (let key of tenants.keys()) {
    const tasks = tenants.get(key);
    const params = data.tenantId === callCenterTenantId ? getHeadersXTenantId(key) : null;
    promises.push(practitionerTasksBatch.updateOne(new PractitionerTaskBatchModel({ tasks }), params));
  }
  const results = await Promise.all(promises);
  const hasSuccess = results.some(result => result.success);
  const hasErrors = results.some(result => !result.success);
  if (hasErrors) {
    results
      .filter(result => !result.success)
      .forEach(result => toast.error(localize('messages.cantHandleTask'), result.error));
  }
  if (hasSuccess && !hasErrors) {
    toast.success(localize('messages.taskHandled'));
  }
  if (hasSuccess && state.popupId) {
    popups.hide({ id: state.popupId, result: true });
  }
  commit(types.SET_HANDLING, false);
  return true;
}

export const actions = Object.freeze({
  onFilterChange,
  initializeList,
  reload,
  onProfileActivated,
  showHandleTaskPopup,
  handleTask,
  setTasksForHandle,
  selectionUpdate,
  setFilterLastDays,
});
