import { getPreferencesAction, getTableFiltersAction, setPreferencesAction } from './actions';

/**
 *
 * @param {Function} dispatch
 * @returns {UserPreferences}
 */
export async function getPreferences(dispatch) {
  return await dispatch('profile/preferences/' + getPreferencesAction, {}, { root: true });
}

/**
 *
 * @param {Function} dispatch
 * @param {String}   tableId
 * @returns {TableFiltersProfile}
 */
export async function getUserTableDefaultFiltersProfile(dispatch, tableId) {
  return await dispatch('profile/preferences/' + getTableFiltersAction, tableId, { root: true });
}

/**
 *
 * @param {Function} dispatch
 * @param {UserPreferences} preferences
 * @returns {UserPreferences}
 */
export async function setPreferences(dispatch, preferences) {
  return await dispatch('profile/preferences/' + setPreferencesAction, preferences, { root: true });
}
