import { generateRulesetValidatorForPartialCron } from './partialValidatorGenerator';
import { generateRulesetInfo } from './rulesetInfoGenerator';
import { daysOfMonthRuleset } from './partials/daysOfMonth';
import { daysOfWeekRuleset } from './partials/daysOfWeek';
import { hoursRuleset } from './partials/hours';
import { minutesRuleset } from './partials/minutes';
import { monthsRuleset } from './partials/months';
import { secondsRuleset } from './partials/seconds';
import { daysOfWeekAndMonthValidator } from './daysOfWeekAndMonthValidator';

export {
  getCronRulesetInfo,
  validateCronExpressionObject,
};

/**
 * Represents a full cron expression validation ruleset
 */
const cronValidationRuleset = Object.freeze({
  seconds: secondsRuleset,
  minutes: minutesRuleset,
  hours: hoursRuleset,
  daysOfMonth: daysOfMonthRuleset,
  months: monthsRuleset,
  daysOfWeek: daysOfWeekRuleset,
});

/**
 * The current ruleset info text
 */
const getCronRulesetInfo = () => generateRulesetInfo(cronValidationRuleset);

/**
 * Represents an object containing all of the validators based on the ruleset provided
 */
const cronRulesetPartialValidators = Object.freeze(
  Object.keys(cronValidationRuleset).reduce(
    // reduce to a single object
    (acc, key) => ({
      ...acc,
      [key]: generateRulesetValidatorForPartialCron(cronValidationRuleset[key]),
    }),
    // initial value
    {}
  ),
);

/**
 *
 * @param {Object} cronObject
 * @param {String} cronObject.seconds
 * @param {String} cronObject.minutes
 * @param {String} cronObject.hours
 * @param {String} cronObject.daysOfMonth
 * @param {String} cronObject.months
 * @param {String} cronObject.daysOfWeek
 *
 * @returns {Boolean}
 */
function validateCronExpressionObject(cronObject) {
  // check the basic validity of the object and check if theres a validator for every key
  for (const key of Object.keys(cronObject)) {
    if (!cronObject[key] || cronObject[key].length < 1 || !cronRulesetPartialValidators[key]) {
      return false;
    }
  }
  // validate all of the parts of the object
  const partialsAreValid = Object.keys(cronRulesetPartialValidators)
    .map(key => cronRulesetPartialValidators[key](cronObject[key]))
    .every(v => v === true);

  // additional validators
  const daysAreValid = daysOfWeekAndMonthValidator(cronObject);

  // final step
  return [partialsAreValid, daysAreValid].every(v => v === true);
}
