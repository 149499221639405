export const AnswerType = Object.freeze({
  BOOLEAN: 'BOOLEAN',
  NUMERIC: 'NUMERIC',
  INTEGER: 'INTEGER',
  FREE_STRING: 'FREE_STRING',
  CHOICE: 'CHOICE',
});

export const AnswerTypeOptions = Object.keys(AnswerType).map(unit => ({
  label: `enums.${ unit }`,
  value: unit
}));

export const AnswerOptionType = Object.freeze({
  NUMERIC: 'NUMERIC',
  INTEGER: 'INTEGER',
  FREE_STRING: 'FREE_STRING',
  TERMINOLOGY: 'TERMINOLOGY',
});

export const AnswerOptionTypeOptions = Object.keys(AnswerOptionType).map(unit => ({
  label: `enums.${ unit }`,
  value: unit
}));

