import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { cleanPageNum, setPaginationConfigMutation, toServerPageOptions } from '../../../model/page';
import { createMainFilterInput, filerConstraints } from '../../../services/filter/utils/filterUtils';
import { filterInputs } from './getters';

function createFilterParams(state) {
  const params = {};

  filterInputs(state)
    .filter(input => input.value && input.valid)
    .forEach(input => {
      params[input.id] = input.value;
      if (input === state.groupFilterInput) {
        params[input.id] = input.value.uuid;
      }
    });
  return params;
}

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_ORG_USERS_PAGE](state, value) {
    state.orgUsersPage = value;
    Object.assign(state.serverSideOptions, toServerPageOptions(value));
  },

  [types.SET_PAGINATION_CONFIG]: setPaginationConfigMutation,

  [types.SET_GROUP_FILTER_VALUE](state, input) {
    state.groupFilterInput = input;
  },

  [types.INIT_FORM](state) {
    state.nameFilterInput = createInputConfig({
      id: 'lastName',
      label: 'common.name',
      type: InputType.Text,
      value: '',
      ...filerConstraints,
    });
    state.emailFilterInput = createInputConfig({
      id: 'email',
      label: 'common.email',
      type: InputType.Text,
      value: '',
      ...filerConstraints,
    });
    state.generalSearchInput = createMainFilterInput({
      id: 'multipleFilter'
    });
    state.filterParams = createFilterParams(state);
  },

  [types.UPDATE_FILTER_PARAMS](state) {
    state.filterParams = createFilterParams(state);
    cleanPageNum(state);
  }

};

