import { mapValues } from 'lodash';
import { TaskListTypes } from '../../constants/taskListTypes';

export const state = {
  programsLoading: false,
  programs: null,
  observationTemplatesLoading: false,
  observationTemplates: null,
  tenantId: null,
  listInitialized: false,

  // prepare the reactive state keys for all list types
  taskLists: mapValues(TaskListTypes, () => null),

  currentForwardingTaskId: null,
  tasksForHandle: [],
  handling: false,
};
