export const regexValidators = Object.freeze({
  isNumericValue: /^[0-9]{1,2}$/i,
  isListOfNumericValues: /^[0-9]{1,2}(,[0-9]{1,2})*$/i,
  isStepOfNumericValues: /^([0-9]{1,2}|\*)\/([0-9]{1,2})$/,
  isRangeOfNumericValues: /^[0-9]{1,2}-[0-9]{1,2}$/i,
  isASingleWeekday: /^(MON|TUE|WED|THU|FRI|SAT|SUN)$/i,
  isAnArrayOfWeekdays: /^(MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))*$/i,
  isARangeOfWeekdays: /^(MON|TUE|WED|THU|FRI|SAT|SUN)-(MON|TUE|WED|THU|FRI|SAT|SUN)$/i,
});

export const cronValidDaysOfWeek = Object.freeze([
  'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT',
]);

export const minutesRange = { from: 0, to: 59 };
export const hoursRange = { from: 0, to: 23 };
export const daysOfMonthRange = { from: 1, to: 31 };
export const monthsRange = { from: 1, to: 12 };