/* global PRODUCTION */

import Vue from 'vue';
import Vuex, { Store } from 'vuex';

import { modules, plugins } from '../store';

Vue.use(Vuex);

export const store = new Store({
  modules,
  plugins,

  strict: !PRODUCTION
});
