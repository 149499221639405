<template>
  <div
    :class="inputStyles.addressFields"
  >
    <div :class="styles.row">
      <div
        v-if="showDefaultNumberOption"
        :class="styles.icon"
      >
        <div :disabled="!$props.value.number">
          <Icon
            type="star"
            :fill="item.primary ? 'gold' : null"
            :stroke="item.primary ? 'gold' : null"
            @click="setPrimary(!item.primary)"
          />
        </div>
      </div>
      <div :class="styles.inputContainer">
        <CountrySelect
          v-bind="inputs.countryCode"
          :name="`countryCode${ $props.index }`"
          :groupId="$props.groupId"
          :class="styles.country"
          :placeholder="countryCode"
          :value="getCountryItemByCode2(countryCode)"
          :disabled="$props.disabled || inputs.countryCode.disabled"
          :filter="filterPhones"
          @change="handleChange({ input: inputs.countryCode, value: $event })"
        />
      </div>
      <div :class="styles.numberContainer">
        <TextInput
          v-bind="inputs.number"
          :name="`number${ $props.index }`"
          :groupId="$props.groupId"
          :value="item.number"
          :valid="inputs.number.valid"
          :pristine="$props.pristine"
          :disabled="$props.disabled || inputs.number.disabled"
          @change="handleChange({ input: inputs.number, value: $event })"
        />
      </div>
      <div
        v-if="showPhoneType"
        :class="styles.inputContainer"
      >
        <Radio
          v-bind="inputs.type"
          :value="item.type"
          :options="options"
          :groupId="$props.groupId"
          name="type"
          :inline="true"
          :disabled="$props.disabled"
          @change="handleChange({ input: inputs.type, value: $event })"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { get } from 'lodash';

  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import {
    Patterns,
    inputProps,
    phoneProps,
    updateInput,
    validateInput,
    setInputValue,
    createInputConfig
  } from '../../utils';

  import { PhoneModel } from '../../models';
  import { InputMixin } from '../../mixins';
  import { phoneTypeOrganizationOptions } from '../../constants/phoneTypeOrganization';
  import { getHomeOption, InputType, PhoneTypeOrganization, phoneTypePersonOptions } from '../../constants';

  import TextInput from '../Text';
  import Radio from '../radio/Radio';
  import CountrySelect from '../CountrySelect';

  import { filterPhones } from './phone.utils';
  import styles from './phone.scss';

  export default {
    name: 'H4hPhone',

    components: {
      CountrySelect,
      TextInput,
      Radio
    },

    mixins: [
      InputMixin
    ],

    props: {
      ...phoneProps,

      value: {
        type: Object,
        required: true
      },

      item: Object,

      index: Number,

      /** flags */
      first: inputProps.booleanFalse,

      // @todo: Baizulin - find out why those two are inverted in Phones.vue
      required: inputProps.booleanTrue,
      showPhoneType: inputProps.booleanFalse
    },

    data: function() {
      const { phoneEnum } = this.$props;
      const homeOption = getHomeOption(phoneEnum);

      let validatePhoneNumber = null;
      if (this.validatePhoneNumber) {
        validatePhoneNumber = input => this.validatePhoneNumber(input, this.index);
      }

      return {
        inputStyles,
        styles,
        filterPhones,
        options: (this.$props.phoneEnum === PhoneTypeOrganization ? phoneTypeOrganizationOptions : phoneTypePersonOptions).map(i => ({
          ...i, dataTestId: `${ i.value }${ this.$props.index }`
        })),
        inputs: {
          number: createInputConfig({
            id: 'number',
            type: InputType.Text,
            value: this.$props.value?.number || '',
            pattern: Patterns.Number,
            tabindex: this.$props.tabindex + 0.1,
            required: this.$props.required,
            validate: validatePhoneNumber,
          }),

          countryCode: createInputConfig({
            id: 'countryCode',
            type: InputType.Select,
            options: this.getCountriesAndCallCodes(this.$props.countryOptions || []),
            placeholder: get(this.$props.value, 'countryCode') || this.$props.defaultCountryCode,
            tabindex: this.$props.tabindex + 0.2,
            required: this.$props.required,
            value: get(this.$props.value, 'countryCode') || this.$props.defaultCountryCode,
            clearable: true
          }),

          primary: createInputConfig({
            id: 'primary',
            type: InputType.Checkbox,
            value: this.$props.value && this.$props.value.primary || false
          }),

          type: createInputConfig({
            id: 'type',
            type: InputType.Label,
            value: get(this.$props.value, 'type') || (this.$props.index === 1 ? homeOption : phoneEnum.Mobile)
          })
        }
      };
    },

    computed: {
      countryCode() {
        const { value, defaultCountryCode } = this.$props;

        return value.countryCode || defaultCountryCode;
      }
    },

    watch: {
      required() {
        const { required } = this.$props;
        const { number, countryCode } = this.inputs;

        updateInput({ input: number, required });
        updateInput({ input: countryCode, required });
      }
    },

    beforeMount() {
      if (this.$props.showPhoneType && !this.$props.value.type) {
        const phones = { ...this.value, type: this.$props.phoneEnum.Mobile };
        this.$emit('change', { phone: new PhoneModel(phones), index: this.index });
      }
    },

    methods: {
      getCountriesAndCallCodes(countries) {
        return countries && countries.map(country => ({
          label: `(+${ country.callCode })`,
          value: country
        }));
      },

      getCountryItemByCode2(code2) {
        return this.inputs.countryCode.options.find(o => o.value.code2 === code2);
      },

      setPrimary(value) {
        const phones = { ...this.value, primary: value };
        this.$emit('change', { phone: new PhoneModel(phones), index: this.index });
      },

      handleChange({ input, value }) {
        setInputValue({ input, value });
        const phones = {
          ...this.value,
          [input.name]: input === this.inputs.countryCode
            ? value.code2
            : value
        };
        this.$emit('change', { phone: new PhoneModel(phones), index: this.index });
        if (input !== this.inputs.number) {
          // lets validate number after country or phonetype changed
          this.inputs.number.valid = validateInput(this.inputs.number);
        }
      }
    }
  };
</script>
