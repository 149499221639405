export const programs = {
  programs: 'Programs',
  program: 'Program',
  addProgram: 'Add program',
  availablePeriodsCount: 'Available periods count',
  availablePeriods: 'Available periods',
  pleaseAddAtLeastOnePeriod: 'Please add at least one monitoring period',
  basePeriod: 'Base period',
  durationUnit: 'Duration unit',
  durationValue: 'Duration value',
  controlPlaneScript: 'Control plane script',
  treatmentAdviceScript: 'Treatment advice script',
  treatmentAdviceTemplate: 'Treatment advice template',
  cron: 'Cron schedule',
  observations: 'Observations',
  addObservation: 'Add observation',
  makePrimaryPeriod: 'Make period primary',
  primaryPeriod: 'Primary period',
  devices: 'Devices',
  addDevice: 'Add device',
  questionnaires: 'Questionnaires',
  addQuestionnaire: 'Add questionnaire',
  groups: 'Groups',
  hasNoDuration: 'This period has no end date',
  sendEmailOnEnrollment: 'Send email notification',
  includeSchedule: 'Include schedule',
  noSchedule: 'No schedule',
  doesntUseH4Happ: 'Doesn’t use H4H app',
  selectAProgram: 'Select a program',
  pdfViewer: 'PDF Viewer',
};
