export const getters = {
  hasAddress,
  patientIsLoading,
};

function hasAddress(state) {
  return !!(state.patient?.address);
}

function patientIsLoading(state) {
  return state.loading;
}
