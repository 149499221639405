import { MonitoringPeriodModel } from '../../model/monitoringPeriodModel';
import { DurationModel } from '../../model/durationModel';

export class MonitoringPeriodInputModel {
  /**
   * MonitoringPeriodInputModel config
   *
   * @param {Object}              config
   * @param {String}              config.uuid
   * @param {Boolean}             config.generateFakeUuid
   * @param {Object}              config.hasNoDuration
   * @param {Object}              config.sendEmailOnEnrollment
   * @param {Object}              config.sendDevicesToPatients
   * @param {Object}              config.name
   * @param {Object}              config.durationValue
   * @param {Object}              config.durationUnit
   * @param {Object[]}            config.inputs
   * @param {Object[]}            config.observations
   * @param {Object[]}            config.questionnaires
   */
  constructor(config) {
    this.hasNoDuration = config.hasNoDuration;
    this.sendEmailOnEnrollment = config.sendEmailOnEnrollment;
    this.sendDevicesToPatients = config.sendDevicesToPatients;
    this.name = config.name;
    this.durationValue = config.durationValue;
    this.durationUnit = config.durationUnit;
    this.inputs = config.inputs;
    this.observations = config.observations;
    this.questionnaires = config.questionnaires;
    this.pristine = true;
    this.uuid = config.uuid;
    this.generateFakeUuid = config.generateFakeUuid;
    if (config.generateFakeUuid) {
      this.uuid = '' + Math.random();
    }
  }

  isValid() {
    return this.inputs && this.inputs.every(i => i.valid);
  }

  setPeriodAndInputsPristine(value) {
    this.inputs.forEach(input => input.pristine = value);
    this.pristine = value;
  }

  toModel() {
    return new MonitoringPeriodModel({
      uuid: this.generateFakeUuid ? null : this.uuid,
      observations: this.observations,
      questionnaires: this.questionnaires,
      name: this.name.value,
      hasNoDuration: this.hasNoDuration.value,
      sendEmailOnEnrollment: this.sendEmailOnEnrollment.value,
      sendDevicesToPatients: this.sendDevicesToPatients.value,
      duration: new DurationModel({
        value: this.durationValue.value,
        unit: this.durationUnit.value,
      })
    });
  }
}
