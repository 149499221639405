import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';

export const mutations = {
  [types.INIT_FORM](state, terminologyTypes) {
    state.definitions = [];
    state.inputs = getInputsConfig(terminologyTypes);
    state.inputsMap = getInputsMap(state.inputs);
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.ADD_OBSERVATION_TEMPLATE_DEFINITION](state, definition) {
    state.definitions.push(definition);
  },

  [types.REMOVE_DEFINITION](state, definition) {
    const idx = state.definitions.indexOf(definition);
    if (idx > -1) {
      state.definitions.splice(idx, 1);
    }
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  }
};

/**
 *
 * @return {Object[]}
 */
function getInputsConfig(terminologyTypes) {
  const name = createTextInputConfig('name', 'common.name', InputType.Text);
  const terminologyType = createInputConfig({
    id: 'terminologyType',
    label: 'observationTemplates.terminologyType',
    type: InputType.Typeahead,
    required: true,
    options: createTerminologyInputTypeOptions(terminologyTypes),
    formatter: i => i ? i.label : i
  });

  return [
    name,
    terminologyType,
  ];
}

function createTextInputConfig(id, label, inputType) {
  return createInputConfig({
    id,
    type: inputType,
    label,
    required: true,
  });
}

function createTerminologyInputTypeOptions(terminologyTypes) {
  return terminologyTypes.map(type => ({
    label: type.translation,
    value: type.key
  }));
}
