<template>
  <div
    :class="[
      $props.inline && styles.dropdownContentInline,
      ($props.optionsScroll && !$props.horizontal) && styles.dropdownScroll,
      $props.horizontal ? styles.horizontal : styles.dropdownContent,
      $props.checkboxStyle
    ]"
  >
    <slot/>
    <template v-if="hasSlot('option')">
      <div
        v-for="(option, index) in options"
        :key="index"
        @click="toggleOption(option)"
      >
        <slot
          name="option"
          v-bind="createOptionSlotScope(option, index)"
        />
      </div>
    </template>

    <template v-else-if="!$props.multiple">
      <SelectOption
        v-for="(option, index) in options"

        :key="index"
        v-bind="createOptionSlotScope(option, index)"
        @click="toggleOption(option)"
      />
    </template>

    <template v-else>
      <Checkbox
        v-for="(option, index) in options"

        :key="getOptionKey(option)"
        :label="option.label"
        :subLabels="option.subLabels"
        :cardStyle="option.cardStyle"
        :value="isActive(option)"
        :class="[styles.checkbox, isFocused(index) && 'hover', styles.padding]"
        :colorClass="option.colorClass"
        :disabled="$props.disabled || option.disabled"
        @change="toggleOption(option)"
      />

      <OtherOption
        v-if="otherOptionInputConfig"
        :inputConfig="otherOptionInputConfig"
        :parentType="InputType.Select"
        @change-other-option-input="onOtherChange"
      />
    </template>
  </div>
</template>

<script>
  import { isObject, last } from 'lodash';

  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { hasSlot } from '@h4h/utils';
  import { ArrayProp } from '@h4h/classes';

  import Checkbox from '../checkbox/Checkbox';

  import styles from './select.scss';
  import { isOptionActive } from './utils';
  import SelectOption from './SelectOption';
  import { isOtherOptionInputWithParentId } from '../../utils/factories';
  import OtherOption from '../otherOption/OtherOption';
  import { inputProps } from '../../utils';
  import { InputType } from '../../constants/inputType';

  export default {
    name: 'H4hSelect',

    components: {
      SelectOption,
      Checkbox,
      OtherOption
    },

    props: {
      value: [Object, String, Number, Boolean, Set],
      id: String,

      tabindex: inputProps.tabindex,

      options: {
        type: Array,
        required: true
      },

      linkedInputs: new ArrayProp({
        type: Object,
        required: false
      }),

      /** flags */
      inline: inputProps.booleanFalse,
      disabled: inputProps.booleanFalse,
      multiple: inputProps.booleanFalse,
      // horizontal should be true only when dropdown is false
      horizontal: inputProps.booleanFalse,
      optionsScroll: inputProps.booleanFalse,

      /** styles */
      checkboxStyle: inputProps.style
    },

    data() {
      return {
        styles,
        inputStyles,
        InputType,
        expanded: false,
        focusedOptionIndex: null
      };
    },

    computed: {
      focusableIndexes() {
        if (this.$props.multiple) {
          return this.options.map((_, i) => i);
        }

        const indexes = [];
        this.options.forEach((o, i) => !this.isActive(o) && indexes.push(i));

        return indexes;
      },

      otherOptionInputConfig() {
        return this.$props.linkedInputs?.find(i => isOtherOptionInputWithParentId(i, this.$props.id));
      }
    },

    methods: {
      hasSlot,

      createOptionSlotScope(option, index) {
        return {
          option,
          active: this.isActive(option),
          focused: this.isFocused(index)
        };
      },

      selectOption(option) {
        if (this.$props.disabled) {
          return;
        }

        if (this.isActive(option)) {
          return this.$emit('close');
        }

        const { multiple, value } = this.$props;

        if (!multiple) {
          this.$emit('change', option.value);
          return;
        }

        if (!value) {
          this.$emit('change', new Set([option.value]));
        }
        else if (!value.has(option.value)) {
          this.$emit('change', new Set([...value, option.value]));
        }
      },

      toggleAllOptions() {
        const { options, multiple, value } = this.$props;
        if (!multiple || this.$props.disabled) {
          return;
        }

        if (value && value.size === options.length) {
          return this.$emit('change', new Set([]));
        }
        this.$emit('change', new Set(options.map(o => o.value)));
      },

      deselectOption(option) {
        const { disabled, multiple, value } = this.$props;

        if (disabled) {
          return;
        }

        if (!multiple && this.isActive(option)) {
          return this.$emit('close');
        }

        const newValue = new Set(value);
        newValue.delete(option.value);

        this.$emit('change', newValue);
      },

      toggleOption(option) {
        if (this.$props.disabled) {
          return;
        }

        if (this.isActive(option)) {
          this.deselectOption(option);
        }
        else {
          this.selectOption(option);
        }
      },

      isActive(option) {
        const { multiple, value } = this.$props;

        return isOptionActive(option, { value, multiple });
      },

      isFocused(optionIndex) {
        return optionIndex === this.focusedOptionIndex;
      },

      // @todo: Baizulin - implement auto scroll in dropdown similar to Typeahead
      focusNextOption() {
        const { focusableIndexes } = this;

        if (this.focusedOptionIndex === null) {
          this.focusedOptionIndex = focusableIndexes[0];
        }
        else if (this.focusedOptionIndex !== last(focusableIndexes)) {
          const index = focusableIndexes.findIndex(i => i === this.focusedOptionIndex);
          this.focusedOptionIndex = focusableIndexes[index + 1];
        }
      },

      focusPreviousOption() {
        const { focusableIndexes } = this;

        if (this.focusedOptionIndex === null) {
          this.focusedOptionIndex = last(focusableIndexes);
        }
        else if (this.focusedOptionIndex !== focusableIndexes[0]) {
          const index = focusableIndexes.findIndex(i => i === this.focusedOptionIndex);
          this.focusedOptionIndex = focusableIndexes[index - 1];
        }
      },

      toggleFocusedOption() {
        if (this.focusedOptionIndex === null) {
          return;
        }

        this.toggleOption(this.options[this.focusedOptionIndex]);
      },

      getOptionKey({ value }) {
        if (value && isObject(value)) {
          if (value.id) {
            return value.id;
          }
          if (value.label) {
            return value.label;
          }
        }
        return value;
      },

      onOtherChange(value) {
        this.$emit('change-other-option-input', { input: this.otherOptionInputConfig, value });
      }
    }
  };
</script>
