import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { mapDataTablePageConfigToSpringPageConfig } from '../../../model/page';
import { pageableLogisticPatients } from '../../../services/api/patients/logisticPatients';

export const actions = Object.freeze({
  reload,
  initForm,
  onFilterChange,
});

async function reload({ commit, state }, dataTableConfig) {
  commit(types.SET_PAGINATION_CONFIG, dataTableConfig);
  await reloadData({ commit, state });
}

function initForm({ commit }) {
  commit(types.INIT_FORM);
}

async function onFilterChange({ commit, state, getters }) {
  commit(types.UPDATE_FILTER_PARAMS);
  if (!getters.valid) {
    return;
  }
  await reloadData({ commit, state });
}

async function reloadData({ commit, state }) {
  let params = {
    ...mapDataTablePageConfigToSpringPageConfig(state.serverSideOptions),
    ...(state.filterParams || {}),
  };

  commit(types.SET_LOADING, true);
  const result = await pageableLogisticPatients.fetchOne({ params });
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_PATIENTS_PAGE, result.data);
  }
  else {
    commit(types.SET_PATIENTS_PAGE, null);
    showRestError(result.error, 'messages.cantFetchPatients');
  }
}
