import { types } from './types';
import { createInputConfig, InputType, validateDateInput } from '@h4h/inputs';
import { ObservationInputType } from '../../../../../../constants/observationInputType';
import { findByKey } from '../../../../../../filters/findByKey';
import moment from 'moment';
import { createEhDateInputConfig } from '../../../../../../utils/factories';
import { observationCommentFieldName, ObservationTemplateInputGroup } from './utils';

function getInputGroups(observationTemplate, group, observationTemplates, terminologyTypes) {
  if (!group) {
    return [createObservationTemplateInputGroup(observationTemplate, terminologyTypes)];
  }
  else {
    return group.programInputs
      .map(o => o.observationTemplateId)
      .map(observationTemplateId => observationTemplates.find(ot => ot.uuid === observationTemplateId))
      .filter(ot => !!ot)
      .map(ot => createObservationTemplateInputGroup(ot, terminologyTypes));
  }
}

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.INIT_OBSERVATION_GROUPS_DIALOG](state, { observationGroups, popupId, hasProgramInputWithoutGroups }) {
    state.observationGroups = observationGroups;
    state.observationGroupsPopupsId = popupId;
    state.hasProgramInputWithoutGroups = hasProgramInputWithoutGroups;
  },

  [types.SET_PRISTINE](state, value) {
    state.inputGroups.forEach(inputGroup => inputGroup.inputs.forEach(input => input.pristine = value));
    state.date.pristine = value;
  },

  [types.INIT_DIALOG](state, {
    id,
    observationTemplate,
    group,
    observationTemplates,
    terminologyTypes,
    patientId,
    enrollment
  }) {
    state.enrollment = enrollment;
    state.popupId = id;
    state.originalObservationDate = moment(new Date()).seconds(0).toDate();
    state.observationTemplate = observationTemplate;
    state.patientId = patientId;
    state.inputGroups = getInputGroups(observationTemplate, group, observationTemplates, terminologyTypes);
    state.monitoringPeriodStartDate = enrollment.monitoringPeriod.start;

    // we gave 2 hours to perform measurement. This should be more than enough
    let max = moment().add(2, 'hour').toDate();
    let min = null;
    let range = enrollment.getTotalDateRange();
    if (range.hasData) {
      // round seconds UP to avoid seconds rounding (don't mix with endOf('s'))
      min = moment(range.start).add(1, 's').startOf('s').toDate();
      if (range.finish) {
        max = moment.min(moment(max), moment(range.finish)).toDate();
      }
    }
    let value = state.originalObservationDate;
    if (moment(value).isAfter(max)) {
      value = max;
    }
    if (min && moment(value).isBefore(min)) {
      value = min;
    }
    state.date = createEhDateInputConfig({
      id: 'observationDate',
      type: InputType.DateTime,
      required: true,
      value,
      label: 'patients.observationDate',
      min,
      max,
      validate: createDateTimeValidator(enrollment),
    });
    state.additionalInputs = [
      createInputConfig({
        id: observationCommentFieldName,
        value: null,
        required: false,
        label: 'ecg.comment',
        type: InputType.Textarea,
      })
    ];
  },

};

/**
 *
 * @param {EnrollmentModel} enrollment
 */
function createDateTimeValidator(enrollment) {
  return function validateCurrentTime(input) {
    if (!validateDateInput(input)) {
      return false;
    }
    if (new Date().getTime() < input.value.getTime()) {
      return false;
    }
    return enrollment.containsDate(input.value);
  };
}

/**
 * @param {ObservationTemplateModel}        observationTemplateModel
 * @param {TerminologyTranslationModel[]}   terminologyTypes
 */
function getInputsConfig(observationTemplateModel, terminologyTypes) {
  return observationTemplateModel.definitions
    .map(definition => toInputConfig(definition, terminologyTypes))
    .filter(input => !!input);
}

/**
 * @param {ObservationDefinitionModel}      definition
 * @param {TerminologyTranslationModel[]}   terminologyTypes
 */
function toInputConfig(definition, terminologyTypes) {
  switch (definition.inputType) {
    case ObservationInputType.NUMERIC:
      return createNumericInput(definition, terminologyTypes);
    case ObservationInputType.INT:
      return createIntInput(definition, terminologyTypes);
    case ObservationInputType.BOOLEAN:
      return createBooleanInput(definition, terminologyTypes);
    case ObservationInputType.STRING:
      return createStringInput(definition, terminologyTypes);
    case ObservationInputType.LOCAL_TIME:
      return createLocalTimeInput(definition, terminologyTypes);
    case ObservationInputType.LOCAL_DATE_TIME:
      return createLocalDateTimeInput(definition, terminologyTypes);
    case ObservationInputType.LOCAL_DATE:
      return createLocalDateInput(definition, terminologyTypes);
    case ObservationInputType.DATE_TIME:
      return createDateTimeInput(definition, terminologyTypes);
  }
  return null;
}

/**
 * @param {ObservationDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createNumericInput(definition, terminologyTypes) {
  return createInputConfig({
    id: definition.type,
    type: InputType.Float,
    required: true,
    label: translateTerminology(definition.type, terminologyTypes),
    min: definition.min,
    max: definition.max,
    unit: translateTerminology(definition.unitCode, terminologyTypes),
  });
}

/**
 * @param {ObservationDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createIntInput(definition, terminologyTypes) {
  return createInputConfig({
    id: definition.type,
    type: InputType.Integer,
    required: true,
    label: translateTerminology(definition.type, terminologyTypes),
    min: definition.min,
    max: definition.max,
    unit: translateTerminology(definition.unitCode, terminologyTypes),
  });
}

/**
 * @param {ObservationDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createBooleanInput(definition, terminologyTypes) {
  return createInputConfig({
    id: definition.type,
    type: InputType.Checkbox,
    required: false,
    value: false,
    label: translateTerminology(definition.type, terminologyTypes),
  });
}

/**
 * @param {ObservationDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createStringInput(definition, terminologyTypes) {
  return createInputConfig({
    id: definition.type,
    type: InputType.String,
    required: true,
    label: translateTerminology(definition.type, terminologyTypes),
    min: definition.min,
    max: definition.max,
  });
}

/**
 * @param {ObservationDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createLocalTimeInput(definition, terminologyTypes) {
  return createInputConfig({
    id: definition.type,
    type: InputType.Time,
    required: true,
    label: translateTerminology(definition.type, terminologyTypes),
  });
}

/**
 * @param {ObservationDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createLocalDateTimeInput(definition, terminologyTypes) {
  return createEhDateInputConfig({
    id: definition.type,
    type: InputType.DateTime,
    required: true,
    label: translateTerminology(definition.type, terminologyTypes),
  });
}

/**
 * @param {ObservationDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createLocalDateInput(definition, terminologyTypes) {
  return createEhDateInputConfig({
    id: definition.type,
    type: InputType.Date,
    required: true,
    label: translateTerminology(definition.type, terminologyTypes),
  });
}

/**
 * @param {ObservationDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createDateTimeInput(definition, terminologyTypes) {
  return createEhDateInputConfig({
    id: definition.type,
    type: InputType.DateTime,
    required: true,
    label: translateTerminology(definition.type, terminologyTypes),
  });
}

/**
 *
 * @param {String}                        type
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function translateTerminology(type, terminologyTypes) {
  return findByKey(type, 'key', terminologyTypes, 'translation');
}

function createObservationTemplateInputGroup(observationTemplate, terminologyTypes) {
  return new ObservationTemplateInputGroup({
    observationTemplate,
    inputs: getInputsConfig(observationTemplate, terminologyTypes),
  });
}

