export const labResultGroups = {
  addLabResultGroup: 'Add lab result group',
  labResultGroups: 'Lab result groups',
  templates: 'Templates',
  addTemplate: 'Add templates',
  addObservationTemplate: 'Add observation template',
  deleteLabResultGroup: 'Delete lab result group',
  name: 'Name group',
  deleteLabResultGroupMessage: 'Are you sure you want to delete this lab result group?',
  observationTemplate: 'Observation template',
  terminologyType: 'Terminology type'
};
