import TableRow from '../components/tableRow/TableRow';
import { Shared } from './shared';
import { SearchableRows } from './searchableRows';
import SmartFilter from '../components/filters/SmartFilter';
import BatchActions from '../components/batchActions/BatchActions';
import { PAGE_SIZE } from '@h4h/utils/src/constants/pageSize';

export const TableProps = {
  props: {
    // table id used as a key for storing table filters in userFilters
    userFiltersTableId: String,

    idKey: Shared.props.idKey,
    parentRowIdKey: Shared.props.parentRowIdKey,
    itemKey: {
      // COMPATIBILITY: Widespread use for tableItems, instead of idKey/parentIdKey from shared props.
      // Table.vue > _uniqKey.
      type: String
    },

    // the array of items to display
    items: {
      required: true,
      validator(value) {
        return Array.isArray(value) || value === null;
      }
    },

    // text shown when no items are provided to the component
    noDataMessage: {
      type: [String, Array],
      default: () => ['common.noEntity', 'common.records']
    },

    // show heading
    heading: [String, Array],

    // if true and no items are provided, then a loading text will be shown
    // it is used to display a progress bar while fetching data.
    loading: Boolean,

    // a zebra-striped table
    striped: {
      type: Boolean,
      default: false
    },

    // disables sorting completely
    columnSorting: {
      type: Boolean,
      default: true
    },

    // shows the select checkboxes in both the header and rows
    showSelect: {
      type: Boolean,
      default: false
    },

    // changes selection mode to single select
    singleSelect: {
      type: Boolean,
      default: false
    },

    // enables item selection on row click
    rowClickSelect: {
      type: Boolean,
      default: false
    },

    // selected rows
    selected: {
      type: Array,
      default() {
        return [];
      }
    },

    // expanded rows
    expanded: {
      type: Array,
      default() {
        return [];
      }
    },

    // shows the expand toggle in default rows
    showExpand: {
      type: Boolean,
      default: false
    },

    // changes expansion mode to single expand
    singleExpand: {
      type: Boolean,
      default: false
    },

    selectableKey: {
      type: String,
      default: 'isSelectable'
    },

    expandableKey: {
      type: String,
      default: 'isExpandable'
    },

    // disables pagination completely
    pagination: {
      type: Boolean,
      default: false
    },

    // the functions that calls on each item that is used to determine if it is selectable/expandable or not
    isSelectableFn: TableRow.props.isSelectableFn,
    isExpandableFn: TableRow.props.isExpandableFn,

    // @todo: Baizulin - refactor to be an event
    optionsChange: Function,

    // initial pagination and sorting options
    // It is useful in case in case user goes away from page and returns and we want preserve pagination and sorting
    initialOptions: {
      type: Object,
      default: () => ({
        itemsPerPage: PAGE_SIZE,
      })
    },
    serverSideOptions: Object,

    columns: Shared.props.columns,

    // row props
    actions: Shared.props.actions,
    itemClass: TableRow.props.itemClass,
    highlightRow: TableRow.props.highlightRow,

    // search in heading
    noResultsMessage: SearchableRows.props.noResultsMessage,

    // filter props
    filterInputs: Shared.props.filterInputs,
    setFilterValueAction: {
      type: Function,
      required: false
    },
    applyFilterAction: SmartFilter.props.applyFilterAction,
    defaultTableFilters: Shared.props.defaultTableFilters,

    numberOfShownFilters: {
      type: Number,
      required: false,
      default: 4
    },

    batchActions: BatchActions.props.actions,
    showOnlySelectedInput: {
      type: Object
    },
    showOnlySelectedInputAction: {
      type: Function
    },
    columnBorder: Boolean,
    borderless: Boolean,
    expandAll: Boolean,
    noBatchActionsLabel: String,
    hideBatchHeader: Boolean,
    resetPage: {
      type: Boolean,
      default: true
    }
  },
};
