export const genericOptions = Object.freeze({
  Other: 'Other',
  Unknown: 'Unknown'
});

// TODO: Irina - Update all dropdowns that have all/empty option
export const allOption = {
  label: 'common.all',
  value: null
};

export const emptyOption = {
  label: 'common.empty',
  value: null
};
