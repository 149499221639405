import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig, getInputsMap, Patterns } from '@h4h/inputs';
import { EhPatterns } from '../../../utils/ehPatterns';
import { EhUserFieldsLengthConstraints } from '../../../utils/ehUserFieldsLengthConstraints';
import { localize } from '../../../services/localize';
import { createDisableDirectLoginInputConfig } from '../disableDirectLogin';
import { createPhoneInputConfig } from '../../../utils/phone/phoneComponentUtils';

export const mutations = {
  [types.INIT_FORM](state, { readOnly, organizationSetting }) {
    const inputs = getInputsConfig(state.profile || {}, readOnly, organizationSetting);
    state.inputs = inputs;
    state.inputsMap = getInputsMap(inputs);
  },
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_AVATAR](state, value) {
    state.avatar = value;
  },
  [types.SET_AVATAR_IS_LOADING](state, value) {
    state.avatarIsLoading = value;
  },

  [types.SET_PROFILE](state, profile) {
    state.profile = profile;
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

};

function getInputsConfig(profile, readOnly, organizationSetting) {
  const inputType = readOnly ? InputType.Label : InputType.Text;
  const bigCode = createTextInputConfig(profile.bigCode, 'bigCode', 'common.bigCode', inputType, null, false, false, EhUserFieldsLengthConstraints.bigCode);
  const email = createTextInputConfig(profile.email, 'email', 'common.email', inputType, EhPatterns.email, true, false, EhUserFieldsLengthConstraints.email);
  let phone;
  if (readOnly) {
    phone = createTextInputConfig(profile.phone, 'phone', 'common.phoneNumber', inputType, EhPatterns.phoneNumberInternational, true, false);
  }
  else {
    phone = createPhoneInputConfig(profile.phone, 'phone', 'common.telephoneNumber', true);
  }
  const alarmPhoneNumber = createTextInputConfig(profile.alarmPhoneNumber, 'alarmPhoneNumber', 'common.alarmPhoneNumber', inputType, EhPatterns.phoneNumberInternational, true, false);
  const initials = createTextInputConfig(profile.initials, 'initials', 'common.initials', inputType, Patterns.Initials, false, false, EhUserFieldsLengthConstraints.initials);
  const firstName = createTextInputConfig(profile.firstName, 'firstName', 'common.firstName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, !!profile.firstName, false, EhUserFieldsLengthConstraints.firstName);
  const middleName = createTextInputConfig(profile.middleName, 'middleName', 'common.middleName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, false, EhUserFieldsLengthConstraints.middleName);
  const lastName = createTextInputConfig(profile.lastName, 'lastName', 'common.lastName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, true, false, EhUserFieldsLengthConstraints.lastName);
  const partnerName = createTextInputConfig(profile.partnerName, 'partnerName', 'common.partnerName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, false, EhUserFieldsLengthConstraints.partnerName);
  const partnerMiddleName = createTextInputConfig(profile.partnerMiddleName, 'partnerMiddleName', 'common.partnerMiddleName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, false, EhUserFieldsLengthConstraints.partnerMiddleName);
  const additional = [];
  if (organizationSetting.showDisableDirectLoginSsoDropdown) {
    let disableDirectLogin;
    if (inputType === InputType.Label) {
      const directLoginEnabled = localize(profile.disableDirectLogin ? 'common.no' : 'common.yes');
      disableDirectLogin = createTextInputConfig(directLoginEnabled, 'disableDirectLogin', 'common.directLoginEnabled', inputType);
    }
    else {
      disableDirectLogin = createDisableDirectLoginInputConfig({
        value: profile.disableDirectLogin,
        disabled: inputType !== InputType.Text,
      });
    }
    additional.push(disableDirectLogin);
  }
  return [
    bigCode,
    email,
    phone,
    alarmPhoneNumber,
    initials,
    firstName,
    lastName,
    middleName,
    partnerName,
    partnerMiddleName,
    ...additional,
  ];
}

function createTextInputConfig(value = null, id, label, inputType, pattern, required = false, disabled = false, constraints) {
  return createInputConfig({
    id,
    type: inputType,
    pattern,
    value,
    required,
    label,
    disabled: disabled,
    ...constraints,
  });
}
