export const getters = {
  isValid,
  logisticsEnabled,
};

function isValid(state) {
  return state.inputs && state.inputs.every(i => i.valid);
}

function logisticsEnabled(state) {
  return state.callCenterSettings?.logisticsEnabled || false;
}
