export class InitOptionsModel {
  /**
   * InitOptionsModel
   *
   * @param {Object}    config
   * @param {String}        config.currentLanguage
   * @param {SupportedLanguagesModel}     config.supportedLanguages
   */
  constructor(config) {
    this.currentLanguage = config.currentLanguage;
    this.supportedLanguages = config.supportedLanguages;
  }
}
