import { EhRole } from '../../services/auth/ehRole';
import { forceAuth } from '../../services/auth/auth';

export const getters = {
  isIframe,
  isReadOnlyUser,
};

function isIframe(state) {
  const auth = forceAuth();
  return state.iframeMode || auth.hasRole(EhRole.SsoUser);
}

export function isReadOnlyUser() {
  const auth = forceAuth();
  return auth.hasRole(EhRole.AumcReadUser);
}

