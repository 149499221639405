import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';
import { AnswerOptionType, AnswerOptionTypeOptions } from '../../../../constants/answerType';

export const mutations = {
  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.INIT_POPUP](state, { popupId, terminologyTypes }) {
    state.popupId = popupId;
    state.inputs = getInputsConfig(terminologyTypes);
    state.inputsMap = getInputsMap(state.inputs);
  },
};

/**
 *
 * @param {[]}                  terminologyTypes
 * @return {*[]}
 */
function getInputsConfig(terminologyTypes) {
  // now we decided that CHOICE can have only terminology type. Other types : string, number etc aren't allowed
  const answerType = createInputConfig({
    id: 'type',
    type: InputType.Select2,
    value: AnswerOptionType.TERMINOLOGY,
    label: 'questionnaireTemplates.answerType',
    clearable: false,
    required: true,
    options: AnswerOptionTypeOptions,
  });

  const answerFreeString = createTextInputConfig('answerFreeString', 'questionnaireTemplates.answer', InputType.Text);
  const answerInt = createTextInputConfig('answerInt', 'questionnaireTemplates.answer', InputType.Integer);
  const answerNumeric = createTextInputConfig('answerNumeric', 'questionnaireTemplates.answer', InputType.Float);
  const answerTerminology = createInputConfig({
    id: 'answerTerminology',
    type: InputType.Typeahead,
    label: 'questionnaireTemplates.answer',
    required: true,
    options: createTerminologyInputTypeOptions(terminologyTypes),
    formatter: i => i ? i.label : i
  });

  return [
    answerType,
    answerFreeString,
    answerInt,
    answerNumeric,
    answerTerminology
  ];
}

function createTextInputConfig(id, label, inputType, required = true) {
  return createInputConfig({
    id,
    type: inputType,
    label,
    required,
  });
}

function createTerminologyInputTypeOptions(terminologyTypes) {
  return terminologyTypes.map(type => ({
    label: type.translation,
    value: type.key
  }));
}
