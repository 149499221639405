import { types } from './types';

export const mutations = {
  [types.SET_AVATAR_IS_LOADING](state, value) {
    state.avatarIsLoading = value;
  },

  [types.SET_AVATAR_FILE](state, file) {
    state.avatarFile = file;
  },

  [types.SET_AVATAR](state, avatar) {
    state.avatar = avatar;
  }

};
