import { types } from './types';
import { EhealthPortalProductName, udiDiService } from '../../../services/api/version/certification';
import { showRestError } from '../../../utils/errors';
import { authorizationRemover } from '../authorizationRemover';

export const actions = Object.freeze({
  initUdiDi,
});

async function initUdiDi({ commit }) {
  commit(types.SET_UDI_DI, null);
  commit(types.SET_LOADING, true);
  const udiDiResult = await udiDiService.fetchOne({
    ...authorizationRemover,
    params: { product: EhealthPortalProductName }
  });
  if (udiDiResult.success) {
    let errorMessage = udiDiResult.data.errorMessage;
    let udiDi = udiDiResult.data.udiDi;
    if (errorMessage) {
      showRestError(new Error(errorMessage), 'messages.cantFetchUdiDi');
    }
    else if (!udiDi) {
      showRestError(new Error(' UDI/DI info is not available'), 'messages.cantFetchUdiDi');
    }
    else {
      commit(types.SET_UDI_DI, udiDiResult.data);
    }
  }
  else {
    showRestError(udiDiResult.error, 'messages.cantFetchUdiDi');
  }
  commit(types.SET_LOADING, false);
}
