import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';

export const mutations = {
  [types.INIT_FORM](state, questionnaireTemplate) {
    state.questionnaireTemplate = questionnaireTemplate;
    state.questions = questionnaireTemplate.questions;
    state.inputs = getInputsConfig(questionnaireTemplate);
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_UPDATABLE](state, value) {
    state.updatable = value;
  },

};

/**
 *
 * @param {QuestionnaireTemplateModel} questionnaireTemplate
 * @return {*[]}
 */
function getInputsConfig(questionnaireTemplate) {
  return [
    createInputConfig({
      id: 'title',
      type: InputType.Label,
      label: 'common.name',
      value: questionnaireTemplate.title
    }),
  ];
}

