export class RequestResult {

  /**
   * @param {Boolean}       success
   * @param {Object|Array}  data
   * @param {Object}        error
   */
  constructor({ success, data , error }) {
    this.success = success;
    this.data = data;
    this.error = error;
  }
}

export async function tryRequest(request, dataMapper = data => data) {
  try {
    const response = await request();
    const data = dataMapper(response.data);

    return new RequestResult({
      success: true,
      data
    });
  }
  catch (error) {
    /* eslint-disable-next-line no-console */
    console.error('API error', error);

    return new RequestResult({
      success: false,
      error
    });
  }
}
