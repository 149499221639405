<template>
  <div
    :class="inputStyles.container"
  >
    <div
      v-if="menuActive"
      :class="styles.overlay"
      @mousedown="closeMenu"
    />
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />

    <div
      ref="input"
      :class="styles.select"
      :data-testid="`dropdownList-${$props.name}`"
    >
      <div
        v-if="!isValueSelected"
        :class="[styles.placeholder, disabled && styles.placeholderDisabled]"
      >
        {{ $localize(placeholder) }}
      </div>
      <component
        :is="component"
        ref="componentRef"
        dense
        v-bind="$props"
        placeholder=""
        :allowOverflow="false"
        :error="error || isString(errorMessage)"
        :value="getSelectedValue()"
        :items="reorderedOptions"
        :label="$localize($props.label)"
        :menuProps="menuProps"
        :clearable="clearable && multiple"
        :filter="filterAction"
        :noFilter="serverSideFilter"
        :searchInput.sync="searchQuery"
        :itemValue="getValueFromOption"
        :tabindex="tabIndex"
        itemText="label"
        :data-testid="`dropdown-${$props.name}`"
        @change="onChange"
        @blur="onBlur"
        @focus="onFocus"
        @click="onClick"
        @keydown="onKeyDown"
      >
        <template #item="itemProps">
          <slot
            name="item"
            v-bind="itemProps"
          >
            <CommonSelectListItem
              :multiple="multiple"
              :disabled="disabled"
              :hidden="hideOptions"
              :itemProps="itemProps"
              :name="$props.name"
            >
              <template
                v-if="hasSlot('itemLabel')"
                #itemLabel
              >
                <slot
                  name="itemLabel"
                  v-bind="itemProps"
                />
              </template>
            </CommonSelectListItem>
          </slot>
        </template>

        <template #selection="selectionProps">
          <slot
            name="selection"
            v-bind="selectionProps"
            :clearable="clearable"
            @click:close="removeOption(selectionProps.item)"
          >
            <Chip
              v-if="labelLimit >= selectionProps.index + 1"
              outlined
              :label="false"
              :close="clearable"
              @click:close="removeOption(selectionProps.item)"
            >
              <slot
                name="chipSelectionContent"
                v-bind="selectionProps"
              >
                {{ $localize(selectionProps.item.label) }}
              </slot>
            </Chip>
            <span
              v-if="labelLimit === selectionProps.index"
              :class="styles.others"
            >
              {{ getOthersLabel() }}
            </span>
          </slot>
        </template>

        <template #prepend-item>
          <div :class="styles.prependBlock">
            <VListItem
              v-if="!getHideSearch()"
              :class="[styles.prependItem, styles.search]"
            >
              <TextInput
                ref="innerInput"
                icon="search"
                name="search"
                :value="searchQuery"
                :groupId="menuActive ? $props.groupId : null"
                placeholder="common.search"
                :class="styles.searchInput"
                @change="setQuery"
                @blur="onBlurInner"
                @keydown="innerKeyDown"
              />
              <SpinnerLoading
                v-if="loading"
                primary
              />
            </VListItem>

            <div v-if="multiple && filteredOptions.length">
              <VListItem
                :class="styles.prependItem"
                ripple
                :inputValue="allOptionSelected"
                @click="toggleAll"
              >
                <Checkbox
                  label="common.selectAll"
                  name="select-all"
                  :class="[styles.slotContent]"
                  :value="allOptionSelected"
                  :disabled="$props.disabled"
                />
              </VListItem>
            </div>
          </div>
        </template>
        <template #no-data>
          <VListItem v-if="showNoResult">
            <div :class="styles.noResult">
              {{ $localize(noDataMessage) }}
            </div>
          </VListItem>
          <div v-else/>
        </template>
      </component>
    </div>
    <div
      v-if="errorMessage && !disabled"
      :class="inputStyles.errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  import { uniq, groupBy, debounce, isEqual, isNil, isString } from 'lodash';
  import { VListItem } from 'vuetify/lib';

  import { Chip } from '@h4h/chip';
  import { SpinnerLoading } from '@h4h/loading';
  import { hasSlot, safeSetTimeout } from '@h4h/utils';
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { selectProps } from '../../utils';
  import { InputMixin } from '../../mixins';

  import TextInput from '../Text';
  import Radio from '../radio/Radio';
  import Checkbox from '../checkbox/Checkbox';
  import LabelGroup from '../labelGroup/LabelGroup';

  import styles, { zIndex } from './select.scss';
  import CommonSelectListItem from './components/CommonSelectListItem';

  export default {
    name: 'H4hSelectTemplate',

    components: {
      LabelGroup,
      TextInput,
      Chip,
      Radio,
      Checkbox,
      VListItem,
      SpinnerLoading,
      CommonSelectListItem,
    },

    mixins: [
      InputMixin
    ],

    props: {
      ...selectProps,
      errorMessage: String,
      component: {
        type: Function
      },
      input: Object
    },

    data() {
      let contentClass = styles.dropdown;
      if (this.name) {
        contentClass += ' e2eMenu-' + this.name;
      }
      return {
        menuProps: {
          bottom: true,
          offsetY: true,
          maxHeight: 440,
          contentClass,
          zIndex,
          closeOnClick: true,
        },
        zIndex,
        styles,
        hasSlot,
        inputStyles,
        searchQuery: '',
        menuActive: false,
        reorderedOptions: this.prepareOptions(),
        listenerParams: { passive: false, capture: true },
        selectedAllGroups: [],
        inputConfig: this.$props.input,
        isString
      };
    },
    computed: {
      selectValue() {
        return this.getSelectedValue();
      },
      isValueSelected() {
        if (this.multiple) {
          return this.selectValue && this.selectValue.length;
        }
        return this.selectValue || this.options.some(o => o.value === this.selectValue);
      },
      error() {
        return !this.valid && !this.pristine && this.required;
      },
      hintError() {
        return this.error && this.validationMessage ;
      },
      showNoResult() {
        return !this.searchQueryIsEmpty && !this.loading && !this.isSearchQueryUnderLimit;
      },
      searchQueryIsEmpty() {
        return isNil(this.searchQuery) || this.searchQuery.length === 0;
      },
      allOptionSelected() {
        return this.filteredOptions.every(o => this.$props.value?.has(o.value));
      },
      filteredOptions() {
        // needs for toggle all
        if (this.serverSideFilter) {
          return this.options;
        }
        return this.options.filter(option => !option.disabled && this.filterAction(option));
      },
      hideOptions() {
        return this.reorderedOptions.length === 0 && this.isSearchQueryUnderLimit && !this.multiple;
      },
      isSearchQueryUnderLimit() {
        // allow to fetch options after search is dropped
        if (!this.serverSideFilter || this.searchQueryIsEmpty) {
          return false;
        }
        return this.searchQuery.length < this.$props.minSearchLength;
      },

      translatedOtherGroupLabel() {
        return this.$localize(this.$props.otherGroupLabel);
      }
    },

    watch: {
      menuActive: {
        handler(newValue, oldValue) {
          this.prepareOptions();

          // if the user closes the menu and the input was focused
          // reset the focus to keep it in the tab flow
          // otherwise the menu is open and the inner input should be focused
          if (!newValue && oldValue && this.$refs.componentRef.isFocused) {
            this.focus();
          }
          else {
            this.setInnerInputFocus();
          }
          this.preventOthersScroll();
        },
      },
      options() {
        this.prepareOptions();
      }
    },

    beforeMount() {
      if ((this.$props.multiple || !this.value) && !this.value?.length && !this.value?.size) {
        this.loadOptions();
      }
    },

    methods: {
      focus() {
        this.$refs.componentRef.focus();
      },
      isAllGroupOptionSelected(groupName) {
        return this.filteredOptions.every(o => o.group !== groupName || this.$props.value?.has(o.value));
      },
      toggleAll() {
        const values = this.selectValue || [];
        const optionValues = this.filteredOptions.map(o => o.value);
        if (this.allOptionSelected) {
          const newValue = values.filter(r => !optionValues.includes(r));
          return this.onChange(newValue);
        }
        return this.onChange(uniq([ ...values, ...optionValues]));
      },
      removeOption(item) {
        if (!this.multiple) {
          return this.onChange(null);
        }
        const value = this.returnObject ? item : item.value;
        return this.onChange(this.selectValue.filter(v => v !== value));
      },
      setQuery(searchQuery) {
        if (this.searchQuery !== searchQuery) {
          this.searchQuery = searchQuery;
          this.searchChange();
        }
      },
      searchChange: debounce(function() {
        if (this.serverSideFilter && !this.isSearchQueryUnderLimit) {
          this.$emit('search', this.searchQuery);
        }
      }, 250),
      innerKeyDown(e) {
        // needs for keeping tabindex navigation
        if (e.code === 'Tab') {
          this.$refs.componentRef.focus();
          this.$refs.componentRef.blur();
        }
      },
      setInnerInputFocus() {
        safeSetTimeout(() => this.$refs.innerInput?.focus(), 50);
      },
      getHideSearch() {
        // needs to detect ref changes
        this.menuActive = !!this.$refs.componentRef?.isMenuActive;
        // only autocomplete have filter property
        return !this.$refs.componentRef?.filter;
      },
      onChange(value) {
        if (this.clearSearchOnChange) {
          this.searchQuery = '';
        }
        if (this.multiple) {
          // get current value with internal Select All items
          const oldValue = this.getSelectedValue();

          // once OnChange occurred - some item was added or removed from selected
          const added = value.filter(o => !oldValue.includes(o))?.[0];
          const removed = oldValue.filter(o => !value.includes(o))?.[0];

          // checking if added/removed value is ordinary item or internal Select All item
          if (this.isSelectAllGroupOption(added || removed)) {
            const wasSelected = !!added;
            const groupOptions = this.getFilteredGroupOptions(added || removed)
              .filter(g => this.filteredOptions.includes(g))
              .map(o => o.value);

            // filter out internal select all options from emitted value
            const newValue = oldValue.filter(o => !this.isSelectAllGroupOption(o));

            // adding/removing group options once internal Select All item was selected/deselected
            return this.$emit(
              'change',
              new Set(wasSelected
                ? newValue.concat(groupOptions)
                : newValue.filter(o => !groupOptions.includes(o)))
            );
          }

          if (!value?.length && !value?.size) {
            this.loadOptions();
          }
          // changed value is ordinary item - just filtering out internal select all options
          return this.$emit('change', new Set(Array.from(value).filter(o => !this.isSelectAllGroupOption(o))));
        }
        // if value and search query is cleared and server side search is active - load first page options
        if (!value) {
          this.loadOptions();
        }
        this.$emit('change', value);
      },
      onFocus(e) {
        this.$emit('focus', e);
        this.onFocusInner();
      },
      onFocusInner() {
        this.tabIndexSubscriber__onFocus();
      },
      onBlur() {
        this.prepareOptions();
        this.$emit('blur', this.value);
      },
      onBlurInner() {
        this.$emit('blur', this.value);
      },
      onClick(e) {
        this.$emit('click', e);
        this.setInnerInputFocus();
      },
      onKeyDown(e) {
        this.$emit('keydown', e);
        this.setInnerInputFocus();
      },
      reorderOptions(options) {
        const values = this.value instanceof Set ? Array.from(this.value) : [this.value];
        this.reorderedOptions = options.slice();
        if (!this.disableOptionSort) {
          return this.reorderedOptions.sort((o1, o2) => values.indexOf(o2.value) - values.indexOf(o1.value));
        }
        return this.reorderedOptions;
      },
      prepareOptions() {
        const options = this.reorderOptions(this.options);
        if (!this.groupValues) {
          return options;
        }
        const result = [];
        const grouped = groupBy(options, option => option.group);

        Object.keys(grouped).forEach(key => {
          // there is a case where key === 'undefined' for items without group
          if (key === 'undefined' || !grouped[key].length) {
            return;
          }

          // not using key for localizing in case group is an array
          const group = grouped[key][0].group;
          const localized = this.$localize(group);
          result.push({
            header: localized,
            groupValue: group
          });

          if (this.$props.showSelectAllForGroups && this.$props.multiple) {
            result.push({
              group: localized,
              label: 'common.selectAll',
              value: group,
              groupValue: group,
              isSelectAll: true
            });
            result.push({ divider: true, groupValue: group });
          }

          result.push(...grouped[key]);
        });

        // place items without group at the end with group name Other
        if (grouped.undefined?.length) {
          result.push({ header: this.translatedOtherGroupLabel });

          result.push(...(grouped.undefined || []).map(item => ({
            ...item,
            group: this.translatedOtherGroupLabel
          })));
        }

        this.reorderedOptions = result;
        return result;
      },
      getOthersLabel() {
        let count = this.selectValue.length - this.labelLimit;

        // ignore select all items
        if (this.multiple) {
          count -= this.getSelectAllGroupSelectedOptions().length;
        }

        if (count < 1) {
          return null;
        }
        if (count === 1) {
          return `(+ 1 ${ this.$localize('common.others').toLowerCase() })`;
        }
        return `(+ ${ this.$localize('common.othersCount', count) })`;
      },
      getSelectAllGroupSelectedOptions() {
        if (this.$props.value?.size && this.$props.showSelectAllForGroups && this.$props.multiple) {
          return this.reorderedOptions.filter(({ value, isSelectAll }) => {
            return isSelectAll && (this.allOptionSelected || this.areAllVisibleGroupOptionsSelected(value));
          });
        }

        return [];
      },
      getGroupItemByValue(value) {
        return this.reorderedOptions.find(o => o.value === value);
      },
      isSelectAllGroupOption(group) {
        const groupItem = this.getGroupItemByValue(group);
        return groupItem?.isSelectAll;
      },
      getFilteredGroupOptions(value) {
        // group name can be an array
        return this.filteredOptions.filter(o => isEqual(o.group, value));
      },
      areAllVisibleGroupOptionsSelected(group) {
        return this.getFilteredGroupOptions(group).every(o => this.$props.value?.has(o.value));
      },
      getSelectedValue() {
        // using in method because it make us able to cancel change and reset inner select state value
        if (this.multiple) {
          const selectAllFromGroupItems = this.getSelectAllGroupSelectedOptions().map(o => o.value);
          return this.$props.value && Array.from(this.$props.value).concat(selectAllFromGroupItems) || [];
        }
        return this.value;
      },
      getValueFromOption(option) {
        // vuetifyjs search value in option recursively
        // and we need prevent it for cases when value contain another property value inside
        if (option.label && option.value !== undefined) {
          return option.value;
        }
        return option;
      },
      preventDefault(e) {
        if (this.$refs.componentRef) {
          const content = this.$refs.componentRef.getContent();
          if (!content.contains(e.target)) {
            e.preventDefault();
          }
        }
      },
      closeMenu() {
        this.$refs.componentRef.blur();
      },
      preventOthersScroll() {
        if (this.menuActive) {
          window.addEventListener('wheel', this.preventDefault, this.listenerParams);
        }
        else {
          window.removeEventListener('wheel', this.preventDefault, this.listenerParams);
        }
      },

      isMatchingLabel(label) {
        if (!label || this.searchQueryIsEmpty) {
          return this.searchQueryIsEmpty;
        }

        return this.$localize(label).toLowerCase().includes(this.searchQuery.toLowerCase());
      },

      filterItemFunction(item) {
        return this.$props.filter
          ? this.$props.filter(item, this.searchQuery)
          : this.isMatchingLabel(item.label);
      },

      filterGroupFunction(item) {
        return this.isMatchingLabel(item.group);
      },

      isGroupShown(group) {
        return this.filterGroupFunction({ group }) ||
          this.getFilteredGroupOptions(group).some(i => this.filterItemFunction(i));
      },

      filterAction(item) {
        // it's a divider or Select all item, display only if group name matches search
        if (!item.label && !item.header || item.label === 'common.selectAll') {
          return this.isGroupShown(item.groupValue);
        }

        // it's a regular item
        if (item.label) {
          return this.filterItemFunction(item) ||
            // do not show items without group if search is ${otherGroupLabel} (ux request)
            item.group !== this.translatedOtherGroupLabel && this.filterGroupFunction(item);
        }

        // it's a group item, display only if group name itself or some of it's children match search
        return this.isGroupShown(item.groupValue);
      },

      async loadOptions() {
        // fetch only if there's searchAction
        // the typeahead input has serverSideFilter

        if (!this.searchAction || !this.serverSideFilter || this.searchQuery) {
          return;
        }
        await this.searchAction({ query: this.searchQuery, input: this.inputConfig });
      }
    }
  };
</script>
