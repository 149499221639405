<template>
  <Popover
    ref="popover"
    :offsetY="true"
    :closeOnContentClick="false"
    :closeOnClick="canClosePopoverOnClick"
    :left="true"
    :zIndex="popoverZIndex"
    @change="onPopoverStateChange"
  >
    <template #openPopover>
      <Button
        text
        round
        icon="save"
        data-testid="button-save"
      />
    </template>

    <div :class="styles.popover">
      <div :class="styles.popoverHeader">
        <div :class="[styles.headerText]">
          {{ $localize('common.savedFilters') }}
        </div>
        <div
          :class="styles.closePopover"
        >
          <Button
            text
            round
            xLarge
            icon="x"
            data-testid="button-hide"
            @click="hidePopover"
          />
        </div>
      </div>
      <div
        v-if="dataPerTable"
        :class="styles.popoverContent"
      >
        <Smart
          v-for="input in dataPerTable.inputs"
          :key="input.id"
          :input="input"
          :action="setInputValue"
        />
        <Button
          primary
          label="common.save"
          data-testid="button-save"
          @click="saveNew"
        />
        <Table
          data-testid="table-orderedProfiles"
          :class="styles.table"
          :items="orderedProfiles"
          :columns="columns"
          :actions="rowActions"
        >
          <template #[`item.defaultProfile`]="cell">
            <div :class="styles.icon">
              <Icon
                type="star"
                :fill="cell.value ? 'gold' : null"
                :stroke="cell.value ? 'gold' : null"
                @click="toggleFilter(cell.item)"
              />
            </div>
          </template>
          <template #[`item.name`]="cell">
            <a
              :class="styles.link"
              @click.prevent="activateProfile(cell.item)"
            >
              {{ cell.value }}
            </a>
          </template>
        </Table>
      </div>
    </div>
  </Popover>
</template>

<script>
  import { Button } from '@h4h/button';
  import { Smart } from '@h4h/inputs';
  import { ComponentStyle } from '@h4h/theme';
  import popoverStyles from '@h4h/popover/src/components/popover/popover.scss';

  import styles, { popoverZIndex } from './multipleFiltersProfilesPopover.scss';
  import { Table } from '@h4h/table';
  import { Popover } from '@h4h/popover';
  import { MultipleProfilesMixin } from './multipleProfilesMixin';

  export default {
    name: 'H4hMultipleFiltersProfilesPopover',
    components: {
      Button,
      Popover,
      Smart,
      Table,
    },

    mixins: [MultipleProfilesMixin],

    props: {
      filterInputs: {
        type: Array,
        required: true
      },
    },

    data() {
      return {
        styles,
        popoverStyles,
        popoverZIndex,
        ComponentStyle,
        isPopoverShown: false,
      };
    },

    computed: {
      popover() {
        return this.$refs.popover;
      },
      canClosePopoverOnClick() {
        return !this.getState('popupId');
      },
      dataPerTable() {
        const tableId = this.$props.multipleFiltersProfilesConfig.tableId;
        return this.getState(`dataPerTable.${ tableId }`);
      },
      orderedProfiles() {
        // default profile should be always on top
        const defaultProfiles = this.dataPerTable.profiles.filter(p => p.defaultProfile);
        const nonDefaultProfiles = this.dataPerTable.profiles.filter(p => !p.defaultProfile);
        return [...defaultProfiles, ...nonDefaultProfiles];
      },
      columns() {
        return [
          { label: '', valueKey: 'defaultProfile', header: { width: 30 } },
          { label: 'common.filterName', valueKey: 'name' },
        ];
      },
      rowActions() {
        return [
          {
            icon: 'edit-2',
            label: 'common.edit',
            show: () => true,
            action: this.editProfile,
            dataTestId: 'button-edit'
          },
          {
            icon: 'trash',
            label: 'common.delete',
            show: () => true,
            action: this.deleteProfile,
            dataTestId: 'button-delete'
          },
        ];
      },
    },

    methods: {
      hidePopover() {
        this.popover.hide();
      },
      onPopoverStateChange(isPopoverVisible) {
        if (isPopoverVisible) {
          this.dispatch('init', this.$props.multipleFiltersProfilesConfig);
        }
      },
      async saveNew() {
        const payload = {
          config: this.multipleFiltersProfilesConfig,
          filterInputs: this.filterInputs,
        };
        await this.dispatch('saveNew', payload);
      },
      toggleFilter(profile) {
        this.dispatch('switchDefaultProfileState', {
          config: this.$props.multipleFiltersProfilesConfig,
          profile
        });
      },
      editProfile(profile) {
        this.dispatch('editProfile', {
          config: this.$props.multipleFiltersProfilesConfig,
          profile
        });
      },
      deleteProfile(profile) {
        this.dispatch('deleteProfile', {
          config: this.$props.multipleFiltersProfilesConfig,
          profile
        });
      },
      async activateProfile(profile) {
        const success = await this.dispatch('activateProfile', {
          config: this.$props.multipleFiltersProfilesConfig,
          profile
        });
        if (success) {
          this.popover.hide();
        }
      },
    }
  };
</script>
