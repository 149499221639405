export function encodeObservationType(type, observationId) {
  if (observationId) {
    return btoa(type + ';' + observationId);
  }
  else {
    return btoa(type);
  }
}

export function decodeObservationType(data) {
  let decoded = atob(data);
  return {
    type: decoded.split(';')[0],
    uuid: decoded.split(';')[1],
  };
}
