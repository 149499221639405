/* global API_URL */

import { axios } from '../services/axios';
import { tryRequest } from '../utils/tryRequest';

export class CrossTenantCrudService {
  /**
   *
   * @param {String}    endpointPath
   * @param {Function}  [dataMapper]  - map data received from the API
   * @param {Function}  [modelMapper] - map data sent to API
   * @param {String}    baseUrl
   */
  constructor(
    endpointPath,
    dataMapper = data => data,
    modelMapper = model => model,
    baseUrl = API_URL
  ) {
    if (!endpointPath.endsWith('/')) {
      endpointPath += '/';
    }

    this.path = endpointPath;
    this.dataMapper = dataMapper;
    this.modelMapper = modelMapper;
    this.baseUrl = baseUrl;
  }

  transformConfig(config = null) {
    return {
      baseURL: this.baseUrl,
      ...(config || {})
    };
  }

  async fetch(config = null) {
    return tryRequest(
      () => axios.get(this.path, this.transformConfig(config)),
      data => {
        let result = [];
        Object.keys(data).forEach(key => {
          result = result.concat(data[key].map(o => this.dataMapper(o, key)));
        });
        return result;
      }
    );
  }

  async fetchOne(config = null) {
    return tryRequest(
      () => axios.get(this.path, this.transformConfig(config)),
      this.dataMapper
    );
  }

  async get(id) {
    return tryRequest(
      () => axios.get(this.path + id, this.transformConfig()),
      this.dataMapper
    );
  }

  async create(model) {
    return tryRequest(
      () => axios.post(this.path, this.modelMapper(model), this.transformConfig()),
      this.dataMapper
    );
  }

  async update(id, model) {
    return tryRequest(
      () => axios.put(this.path + id, this.modelMapper(model), this.transformConfig()),
      this.dataMapper
    );
  }

  async updateOne(model) {
    return tryRequest(
      () => axios.put(this.path, this.modelMapper(model), this.transformConfig()),
      this.dataMapper
    );
  }

  async delete(id) {
    return tryRequest(
      () => axios.delete(this.path + id, this.transformConfig())
    );
  }
}
