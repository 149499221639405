<template>
  <div
    ref="container"
    tabindex="-1"
    :class="[
      styles.container,
      inputStyles.container,
      $props.inline && inputStyles.inline,
      !$props.pristine && !$props.valid && 'invalid'
    ]"
  >
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />

    <div
      :class="[inputStyles.inputGroup, {
        focus: focused,
        invalid: !$props.pristine && !$props.valid,
        warning: $props.warning
      }]"
    >
      <div :class="styles.inputRow">
        <Icon
          v-if="loading"
          type="loader"
          :class="styles.loader"
        />

        <Icon
          v-else-if="$props.icon"
          :type="$props.icon"
          :class="styles.icon"
        />

        <div :class="styles.inputContainer">
          <template v-if="multiple">
            <Pill
              v-for="(item, index) in value"

              :key="index"
              :active="true"
              :class="styles.pill"
              :label="$props.formatter(item)"
              :disabled="!!$props.disabled"

              @iconClick="removeItem(item)"
            />
          </template>

          <input
            ref="input"
            type="text"
            autocomplete="off"

            :value="query"
            :name="$props.name"
            :tabindex="tabIndex"
            :placeholder="$localize($props.placeholder)"
            :class="[styles.input, inputStyles.input]"
            :disabled="$props.disabled"
            :data-testid="`field-${$props.name}`"
            @blur="onBlur"
            @focus="onFocus"
            @input="onInput"

            @keydown="onKeydown"
            @click="toggleDropdown"
          />
        </div>

        <Icon
          v-show="focused && query && !$props.disabled"
          type="x-circle-invert"
          :class="styles.clear"
          @click="clear"
        />
      </div>

      <div
        v-if="expanded && suggestions && suggestions.length"
        ref="dropdown"
        :class="[selectStyles.dropdown, selectStyles.visible]"
      >
        <div :class="[selectStyles.dropdownContent, selectStyles.dropdownScroll]">
          <div
            v-for="(suggestion, index) in suggestions"

            :key="index"
            :class="[selectStyles.option, isFocused(index) && 'hover']"

            @click="select(suggestion)"
          >
            <slot :suggestion="suggestion">
              {{ $props.formatter(suggestion) }}
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { debounce, isEqual } from 'lodash';

  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';

  import Pill from '../pills/Pill';
  import LabelGroup from '../labelGroup/LabelGroup';
  import selectStyles from '../select/select.scss';

  import styles from './typeahead.scss';

  const handledKeys = new Set([
    'Enter', 'ArrowDown', 'ArrowUp', 'Tab', 'Escape'
  ]);

  export default {
    name: 'H4hTypeahead',

    components: {
      Pill,
      LabelGroup
    },

    mixins: [
      InputMixin
    ],

    props: {
      value: {
        validator: () => true
      },

      options: {
        type: Array,
        required: true
      },

      formatter: {
        type: Function,
        default: i => i
      },

      predicate: {
        type: Function,
        default: (option, query, formatter) => {
          query = query.toLowerCase();
          option = formatter(option).toLowerCase();
          return option.includes(query);
        }
      },

      icon: {
        type: [String, Boolean],
        default: 'search'
      },

      searchAction: {
        type: Function,
        default: null
      },

      /** labels */
      additionalText: String,

      info: inputProps.label,
      otherLabel: inputProps.label,
      placeholder: inputProps.label,

      /** flags */
      inline: inputProps.booleanFalse,
      warning: inputProps.booleanFalse,
      multiple: inputProps.booleanFalse,

      /** styles */
      labelStyle: inputProps.style
    },

    data() {
      return {
        styles,
        inputStyles,
        selectStyles,
        loading: false,
        focused: false,
        expanded: false,
        query: this.updateQuery(),
        focusedSuggestionIndex: null,
        lastFocusedSuggestionIndex: null
      };
    },

    computed: {
      suggestions() {
        const { options, formatter, predicate, multiple, value } = this.$props;

        if (!this.query || !options || !options.length) {
          return null;
        }

        return options.filter(option => {
          if (!predicate(option, this.query, formatter)) {
            return false;
          }

          if (!multiple) {
            return true;
          }

          // 'isEqual' instead 'has' because server side pagination always return a new object like option
          return !value || !Array.from(value).some(v => isEqual(v, option));
        });
      }
    },

    watch: {
      value: function() {
        this.updateQuery();
      }
    },

    beforeMount() {
      this.loadOptions = debounce(this.loadOptions, 400);
    },

    updated() {
      if (this.focusedSuggestionIndex === this.lastFocusedSuggestionIndex) {
        return;
      }

      this.lastFocusedSuggestionIndex = this.focusedSuggestionIndex;

      const scrollContainer = this.$refs.dropdown;

      if (!scrollContainer) {
        return;
      }

      const focused = scrollContainer.querySelector('.focus');

      if (!focused) {
        return;
      }

      const scrollBorders = getElementScrollBorders(scrollContainer);
      const elBoundaries = getElementBoundaries(focused);

      if (elBoundaries.top < scrollBorders.top) {
        scrollContainer.scrollTop = elBoundaries.top;
      }
      else if (elBoundaries.bottom > scrollBorders.bottom) {
        scrollContainer.scrollTop = scrollBorders.top + elBoundaries.bottom - scrollBorders.bottom;
      }
    },

    methods: {
      focus() {
        this.$refs.input.focus();
      },

      blur() {
        this.$refs.input.blur();
        this.close();
      },

      open() {
        this.expanded = true;
      },

      close() {
        this.expanded = false;
      },

      toggleDropdown() {
        if (this.$props.disabled) {
          return;
        }

        this.expanded ? this.close() : this.open();
      },

      select(suggestion) {
        const { value, multiple } = this.$props;

        if (multiple) {
          const newValue = new Set(value || []);
          newValue.add(suggestion);
          this.onChange(newValue);
          return;
        }

        this.onChange(suggestion);
        this.close();
      },

      clear() {
        this.query = '';
        this.focus();
      },

      isFocused(suggestionIndex) {
        return suggestionIndex === this.focusedSuggestionIndex;
      },

      onFocus(e) {
        if (this.focused) {
          return;
        }

        this.focused = true;
        this.$emit('focus', e);
        this.$refs.input.setSelectionRange(0, 999);

        this.tabIndexSubscriber__onFocus();
      },

      onBlur(e) {
        if (!this.focused) {
          return;
        }

        // tabindex="-1" needs to be present on container so we can detect that blur was
        // caused by interacting with Typeahead itself and "prevent" it by focusing back
        if (e.relatedTarget === this.$refs.container) {
          return this.focus();
        }

        this.focused = false;
        this.$emit('blur', e);

        if (this.$props.multiple) {
          this.query = '';
          return;
        }

        if (!this.query) {
          this.onChange(null);
        }
        else {
          this.updateQuery();
        }
      },

      onKeydown(e) {
        if (!handledKeys.has(e.code)) {
          return;
        }

        switch (e.code) {
          case 'Tab':
            return this.blur();

          case 'Escape':
            e.preventDefault();
            return this.query
              ? this.clear()
              : this.blur();

          case 'Enter':
            e.preventDefault();
            return this.expanded
              ? this.selectFocusedSuggestion()
              : this.open();

          case 'ArrowDown':
            e.preventDefault();
            return this.focusNextSuggestion();

          case 'ArrowUp':
            e.preventDefault();
            return this.focusPreviousSuggestion();
        }
      },

      onInput(e) {
        this.query = e.target.value;
        this.focusedSuggestionIndex = null;
        this.$emit('input', e);
        this.loadOptions();
        this.open();
      },

      onChange(newValue) {
        this.updateQuery(newValue);

        if (newValue !== this.$props.value) {
          this.$emit('change', newValue);
        }
      },

      focusNextSuggestion() {
        if (this.focusedSuggestionIndex === null) {
          this.focusedSuggestionIndex = 0;
        }
        else if (this.focusedSuggestionIndex < this.suggestions.length - 1) {
          this.focusedSuggestionIndex++;
        }
      },

      focusPreviousSuggestion() {
        if (this.focusedSuggestionIndex === null) {
          this.focusedSuggestionIndex = this.suggestions.length - 1;
        }
        else if (this.focusedSuggestionIndex > 0) {
          this.focusedSuggestionIndex--;
        }
      },

      selectFocusedSuggestion() {
        if (this.focusedSuggestionIndex !== null) {
          this.select(this.suggestions[this.focusedSuggestionIndex]);
        }
      },

      updateQuery(value = this.$props.value) {
        const { multiple, formatter, additionalText } = this.$props;

        if (multiple) {
          return this.query = '';
        }

        // if user selected the same suggestion as was in this.$props.value we need to
        // manually update the query because value watcher won't be triggered
        return this.query = [formatter(value), additionalText].filter(x => x).join(' — ');
      },

      async loadOptions() {
        if (!this.searchAction || !this.query) {
          return;
        }

        this.loading = true;
        await this.searchAction(this.query);
        this.loading = false;

      },

      removeItem(item) {
        if (this.$props.disabled) {
          return;
        }
        const newValue = new Set(this.$props.value);
        newValue.delete(item);

        this.onChange(newValue);
      }
    }
  };

  function getElementScrollBorders(el) {
    return {
      top: el.scrollTop,
      bottom: el.scrollTop + el.clientHeight
    };
  }

  function getElementBoundaries(el) {
    return {
      top: el.offsetTop,
      bottom: el.offsetTop + el.clientHeight
    };
  }
</script>
