<template>
  <div :class="[styles.container, $props.selected && styles.selectedRow]">
    <TableAction
      v-for="(action, index) in $props.actions"
      :key="index"
      :left="true"
      :input="action.input"
      :disabled="action.isDisabled"
      :inputAction="action.inputAction"
      :initPopover="action.initPopover"
      :popoverClass="action.popoverClass"
      :popoverContent="action.popoverContent"
      :attach="action.attach"

      @trigger="onTrigger(action)"
      @initPopover="onInitPopover(action)"
      @popoverChange="onPopoverChange($event, action)"
    >
      <template #default="{ onClick }">
        <Tooltip
          bottom
          right
          :zIndex="styles.tooltipZindex"
        >
          <template
            #activatorContent
          >
            <Button
              text
              round
              medium
              tertiary
              :icon="action.icon"
              :disabled="action.isDisabled"
              :data-testid="action.dataTestId"
              @click="onClick"
            />
          </template>
          <template #info>
            <span>{{ $localize(action.label) }}</span>
          </template>
        </Tooltip>
      </template>
    </TableAction>
  </div>
</template>

<script>
  import TableAction from '../tableAction/TableAction';
  import Tooltip from '../../../../tooltip/src/components/Tooltip';

  import styles from './rowActions.scss';

  export default {
    name: 'H4hTableRowActions',

    components: {
      TableAction,
      Tooltip
    },

    props: {
      // the array of items to display
      actions: {
        validator: prop => Array.isArray(prop) || prop === null
      },

      item: {
        type: Object,
        required: true
      },

      selected: Boolean
    },

    data() {
      return {
        styles,
      };
    },
    methods: {
      onTrigger(action) {
        action.action(this.$props.item);
      },
      async onInitPopover(action) {
        return action.initPopover(this.$props.item);
      },
      onPopoverChange(active, action) {
        this.$emit('popoverChange', { active, action });
      }
    }
  };
</script>
