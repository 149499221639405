<template>
  <div :class="$props.basic ? styles.basicList : styles.dropdown">
    <template v-for="(option, index) in $props.actions">
      <div
        v-if="showItem(option)"
        :key="index"
        :class="[
          $props.basic ? styles.basicListItem : styles.option,
          option.styles
        ]"
        :disabled="!option.onClick || option.disabled"
        :data-testid="option.dataTestId"
        v-on="option.onClick ? { click: option.onClick } : null"
      >
        <Icon
          v-if="option.icon"
          :type="option.icon"
        />
        <span>{{ $localize(option.title) }}</span>
      </div>
    </template>
  </div>
</template>

<script>
  import { isBoolean } from 'lodash';

  import styles from './popoverList.scss';

  export default {
    name: 'H4hPopoverList',

    props: {
      actions: {
        type: Array,
      },
      basic: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        styles,
      };
    },

    methods: {
      showItem(action) {
        if (isBoolean(action.show)) {
          return action.show;
        }
        return true;
      }
    }
  };
</script>
