import { types } from './types';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },
  [types.SET_ENVIRONMENT](state, environment) {
    state.environment = environment;
  },
};

