import { types } from './types';
import { createInputConfig, InputType, setInputValue, validateInput } from '@h4h/inputs';
import { durationUnitOptions } from '../../../constants/durationUnit';
import { MonitoringPeriodInputModel } from '../models';
import { HAS_NO_DURATION_INPUT } from '../constants';
import { without } from 'lodash';
import { flatten } from 'lodash';
import { ProgramInputGroupModel } from '../../../model/monitoringPeriodModel';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_INPUT_VALUE](state, { input, value }) {
    // behaviour - if noDuration is set then validate duration value and unit, and no in other case
    if (input.id === HAS_NO_DURATION_INPUT) {
      state.availablePeriods.forEach(period => {
        if (period.inputs.includes(input)) {
          period.durationValue.required = !value;
          period.durationValue.valid = validateInput(period.durationValue);
          period.durationValue.disabled = value;
          period.durationUnit.required = !value;
          period.durationUnit.valid = validateInput(period.durationUnit);
          period.durationUnit.disabled = value;
          if (value) {
            period.durationValue.value = null;
            period.durationUnit.value = null;
            period.durationValue.pristine = true;
            period.durationUnit.pristine = true;
          }
        }
      });
    }
    setInputValue({ input, value });
  },

  [types.INIT_FORM](state, { program, fullEdit, treatmentAdviceEnabled }) {
    state.fullEdit = fullEdit;
    state.inputs = [];
    state.availablePeriods = [];
    state.supportedVendors = [];
    state.observationThresholds = [];
    state.program = program;
    state.primaryPeriod = null;
    state.observationGroups = [];
    state.questionnaireGroups = [];
    if (state.program) {
      state.inputs = getInputsConfig(state.program, treatmentAdviceEnabled, fullEdit);
      state.availablePeriods = state.program.availablePeriods.map(period => mapToPeriodConfig(period, fullEdit));
      state.supportedVendors = state.program.supportedVendors;
      state.observationThresholds = state.program.observationThresholds;
      state.primaryPeriod = state.availablePeriods.find(period => period.uuid === program.basePeriodUuid);
      state.observationGroups = getGroups(program.availablePeriods, period => period.observations);
      state.questionnaireGroups = getGroups(program.availablePeriods, period => period.questionnaires);
    }
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
    state.availablePeriods.forEach(period => period.setPeriodAndInputsPristine(value));
  },

  [types.ADD_PERIOD](state) {
    state.availablePeriods.push(createPeriodConfig());
    if (!state.primaryPeriod) {
      state.primaryPeriod = state.availablePeriods[0];
    }
  },

  [types.REMOVE_PERIOD](state, period) {
    state.availablePeriods = without(state.availablePeriods, period);
    if (state.primaryPeriod === period) {
      state.primaryPeriod = state.availablePeriods[0];
    }
  },

  [types.ADD_SUPPORTED_VENDOR](state, programVendorModel) {
    state.supportedVendors.push(programVendorModel);
  },

  [types.REMOVE_SUPPORTED_VENDOR](state, programVendorModel) {
    state.supportedVendors = without(state.supportedVendors, programVendorModel);
  },

  [types.ADD_NEW_PROGRAM_INPUT_GROUP](state, { groups, group, programInput }) {
    programInput.groups.push(group);
    groups.push(group);
  },

  [types.LINK_INPUT_GROUP](state, { group, programInput }) {
    programInput.groups.push(group);
  },

  [types.UNLINK_INPUT_GROUP](state, { group, programInput }) {
    programInput.groups = without(programInput.groups, group);
  },

  [types.ADD_OBSERVATION](state, { period, observation }) {
    period.observations.push(observation);
    period.pristine = false;
  },

  [types.REMOVE_OBSERVATION](state, { period, observation }) {
    period.observations = without(period.observations, observation);
    period.pristine = false;
  },

  [types.ADD_QUESTIONNAIRE](state, { period, questionnaire }) {
    period.questionnaires.push(questionnaire);
    period.pristine = false;
  },

  [types.REMOVE_QUESTIONNAIRE](state, { period, questionnaire }) {
    period.questionnaires = without(period.questionnaires, questionnaire);
    period.pristine = false;
  },

  [types.SET_PRIMARY_PERIOD](state, period) {
    state.primaryPeriod = period;
  },

  [types.UPSERT_THRESHOLD](state, threshold) {
    const oldThreshold = state.observationThresholds.find(t => t.observationTemplateUuid === threshold.observationTemplateUuid);
    if (oldThreshold) {
      const index = state.observationThresholds.indexOf(oldThreshold);
      if (threshold.isEmpty) {
        state.observationThresholds.splice(index, 1);
      }
      else {
        state.observationThresholds[index] = threshold;
      }
    }
    else {
      if (!threshold.isEmpty) {
        state.observationThresholds.push(threshold);
      }
    }
    state.observationThresholds = [...state.observationThresholds];
  },

};

/**
 *
 * @param {MonitoringPeriodModel} period
 * @param {Boolean} fullEdit
 * @return {MonitoringPeriodInputModel}
 */
function mapToPeriodConfig(period, fullEdit) {
  const hasNoDuration = createInputConfig({
    id: HAS_NO_DURATION_INPUT,
    label: 'programs.hasNoDuration',
    type: InputType.Checkbox,
    required: false,
    disabled: !fullEdit,
    value: period.hasNoDuration,
  });
  const sendEmailOnEnrollment = createInputConfig({
    id: 'sendEmailOnEnrollment',
    label: 'programs.sendEmailOnEnrollment',
    type: InputType.Checkbox,
    required: false,
    disabled: false,
    value: period.sendEmailOnEnrollment,
  });
  const sendDevicesToPatients = createInputConfig({
    id: 'sendDevicesToPatients',
    type: InputType.Checkbox,
    required: false,
    value: period.sendDevicesToPatients,
    disabled: false,
    label: 'enrollments.giveOptionToSendDeviceToPatient',
  });
  const name = createTextInputConfig(period.name, 'name', 'common.name', fullEdit ? InputType.Text : InputType.Label);

  const durationInputRequired = !period.hasNoDuration;
  const durationInputDisabled = period.hasNoDuration;
  const durationValue = createTextInputConfig(period.duration ? period.duration.value : null, 'durationValue', 'programs.durationValue', fullEdit ? InputType.Integer : InputType.Label, {
    required: durationInputRequired,
    disabled: durationInputDisabled,
  });
  const durationUnit = createInputConfig({
    value: period.duration ? period.duration.unit : null,
    id: 'durationUnit',
    type: InputType.Select2,
    label: 'programs.durationUnit',
    clearable: !durationInputRequired,
    required: durationInputRequired,
    disabled: !fullEdit || durationInputDisabled,
    options: durationUnitOptions
  });

  return new MonitoringPeriodInputModel({
    uuid: period.uuid,
    name,
    hasNoDuration,
    sendEmailOnEnrollment,
    sendDevicesToPatients,
    durationValue,
    durationUnit,
    inputs: [hasNoDuration, sendEmailOnEnrollment, sendDevicesToPatients, name, durationValue, durationUnit],
    observations: period.observations,
    questionnaires: period.questionnaires,
  });
}

/**
 *
 * @return {MonitoringPeriodInputModel}
 */
function createPeriodConfig() {
  const hasNoDuration = createInputConfig({
    id: HAS_NO_DURATION_INPUT,
    label: 'programs.hasNoDuration',
    type: InputType.Checkbox,
    required: false,
    value: false,
    default: false,
  });
  const sendEmailOnEnrollment = createInputConfig({
    id: 'sendEmailOnEnrollment',
    label: 'programs.sendEmailOnEnrollment',
    type: InputType.Checkbox,
    required: false,
    value: false,
    default: false,
  });
  const sendDevicesToPatients = createInputConfig({
    id: 'sendDevicesToPatients',
    type: InputType.Checkbox,
    required: false,
    value: false,
    label: 'enrollments.giveOptionToSendDeviceToPatient',
  });
  const name = createTextInputConfig(null, 'name', 'common.name', InputType.Text);
  const durationValue = createTextInputConfig(null, 'durationValue', 'programs.durationValue', InputType.Integer, true);
  const durationUnit = createInputConfig({
    id: 'durationUnit',
    type: InputType.Select2,
    label: 'programs.durationUnit',
    options: durationUnitOptions,
    clearable: false,
    required: true,
  });
  return new MonitoringPeriodInputModel({
    generateFakeUuid: true,
    name,
    durationValue,
    durationUnit,
    hasNoDuration,
    sendEmailOnEnrollment,
    sendDevicesToPatients,
    inputs: [hasNoDuration, sendEmailOnEnrollment, sendDevicesToPatients, name, durationValue, durationUnit],
    observations: [],
    questionnaires: [],
  });
}

/**
 *
 * @param {ProgramModel} program
 * @param {Boolean} treatmentAdviceEnabled
 * @param {Boolean} fullEdit
 * @return {Object[]}
 */
function getInputsConfig(program, treatmentAdviceEnabled, fullEdit) {
  const name = createTextInputConfig(program.name, 'name', 'common.name', InputType.Label);

  const basePeriodModel = program.availablePeriods.find(p => p.uuid === program.basePeriodUuid);
  const basePeriodName = basePeriodModel && basePeriodModel.name;

  const basePeriod = createTextInputConfig(basePeriodName, 'basePeriod', 'programs.basePeriod', InputType.Label);
  const controlPlaneScript = createTextInputConfig(program.controlPlaneScript.content, 'controlPlaneScript', 'programs.controlPlaneScript', InputType.Textarea);
  let treatmentAdviceInputs = [];
  if (treatmentAdviceEnabled) {
    // treatmentAdvice functionality is temporary disabled
    // treatmentAdviceInputs = [
    //   createTextInputConfig((program.treatmentAdviceScript || {}).content, 'treatmentAdviceScript', 'programs.treatmentAdviceScript', InputType.Textarea),
    //   createTextInputConfig((program.treatmentAdviceScript || {}).renderTemplate, 'renderTemplate', 'programs.treatmentAdviceTemplate', InputType.Textarea),
    // ];
  }
  let notUseEhealthAppExtensions = { disabled: !fullEdit };
  const notUseEhealthApp = createTextInputConfig(program.notUseEhealthApp, 'notUseEhealthApp', 'programs.doesntUseH4Happ', InputType.Checkbox, notUseEhealthAppExtensions);
  return [
    name,
    basePeriod,
    controlPlaneScript,
    ...treatmentAdviceInputs,
    notUseEhealthApp,
  ];
}

function createTextInputConfig(value, id, label, inputType, extensions) {
  return createInputConfig({
    value,
    id,
    type: inputType,
    label,
    required: inputType !== InputType.Checkbox,
    ...extensions,
  });
}

/**
 *
 * @param {MonitoringPeriodModel[]} availablePeriods
 * @param {Function} provider
 * @return {ProgramInputGroupModel[]}
 */
function getGroups(availablePeriods, provider) {
  let objects = flatten(availablePeriods.map(period => provider(period)));
  const groups = flatten(objects.map(object => object.groups));
  const names = Array.from(new Set(groups.map(group => group.name)));
  return names.map(name => new ProgramInputGroupModel({ name }));
}
