import { CrudService } from '../../../classes/crudService';
import { UserPreferences } from '../../../model/userPreferences';

export const userPreferences = new CrudService(
  'user-preferences',
  data => new UserPreferences(data),
);

export const ccUserPreferences = new CrudService(
  'call-center/user-preferences',
  data => new UserPreferences(data),
);

