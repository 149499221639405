import { types } from './types';
import { mapValues } from 'lodash';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import { router } from '../../../services/router';
import { Page } from '../../../constants/page';
import { showRestError } from '../../../utils/errors';
import { questionnaireTemplatesActions } from '../contants';
import { questionnaireTemplates } from '../../../services/api/questionnaire/questionnaireTemplates';

export const actions = Object.freeze({
  initForm,
  save,
  addQuestion,
  removeQuestion,
});

async function initForm({ commit }) {
  commit(types.INIT_FORM);
}

async function removeQuestion({ commit }, question) {
  commit(types.REMOVE_QUESTION, question);
}

async function addQuestion({ commit, state }, question) {
  const alreadyPresentQuestion = state.questions
    .find(q => q.questionTerminologyCode === question.questionTerminologyCode);
  if (alreadyPresentQuestion) {
    toast.error(localize('questionnaireTemplates.thisQuestionAlreadyAdded'));
  }
  else {
    commit(types.ADD_QUESTION, question);
  }
}

async function save({ state, commit, getters, dispatch }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const questionnaireTemplate = {
    ...mapValues(state.inputsMap, i => i.value),
    questions: state.questions,
  };

  commit(types.SET_LOADING, true);
  const result = await questionnaireTemplates.create(questionnaireTemplate);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.questionnaireTemplateCreated'));
    dispatch(questionnaireTemplatesActions.ReloadQuestionnaireTemplates, {}, { root: true });
    router.push({ name: Page.AdminQuestionnaireTemplates });
  }
  else {
    showRestError(result.error, 'messages.cantCreateQuestionnaireTemplate');
  }
  return result.success;
}
