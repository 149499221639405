<template>
  <div :class="styles.smartContainer">
    <div :class="styles.smartComponent">
      <Select
        v-if="isSelectInput($props.input)"

        v-bind="commonProps"
        :dropdownClass="$props.dropdownClass"
        :checkboxStyle="$props.checkboxStyle"
        :options="$props.input.options"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @change-other-option-input="onOtherOptionChange"
      />

      <Select2
        v-else-if="isSelect2Input($props.input) || isBooleanInput($props.input)"
        v-bind="commonProps"
        :options="
          isSelect2Input($props.input)
            ? $props.input.options
            : booleanInputOptions
        "
        @blur="onBlur"
        @focus="onFocus"
        @change="isSelect2Input($props.input)
          ? onChange($event)
          : onBooleanInputChange($event)
        "
        @search="onSearch"
      />

      <Typeahead2
        v-else-if="isTypeahead2Input($props.input)"
        v-bind="commonProps"
        :searchAction="$props.searchAction || $props.input.searchAction"
        :input="$props.input"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @search="onSearch"
      />

      <CountrySelect
        v-if="isCountrySelectInput($props.input)"

        v-bind="commonProps"

        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
      />

      <Radio
        v-else-if="isRadio($props.input)"
        v-bind="commonProps"

        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @change-other-option-input="onOtherOptionChange"
      />

      <DateInput
        v-else-if="isKindOfDateInput($props.input)"

        v-bind="commonProps"

        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
      />

      <TextInput
        v-else-if="isTextInput($props.input)"

        v-bind="commonProps"

        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        @change="onChange"
      />

      <NumberInput
        v-else-if="isIntegerInput($props.input) || isFloatInput($props.input)"

        v-bind="commonProps"

        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        @change="onChange"
      />

      <Typeahead
        v-else-if="isTypeahead($props.input)"

        v-bind="commonProps"
        :searchAction="$props.searchAction"

        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @input="onInput"
      />

      <TextArea
        v-else-if="isTextAreaInput($props.input)"

        v-bind="commonProps"
        :rows="$props.rows || $props.input.rows"

        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @input="onInput"
      />

      <Wysiwyg
        v-else-if="isWysiwyg($props.input)"

        v-bind="commonProps"

        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @input="onInput"
      />

      <DateRange
        v-else-if="isDateRange($props.input)"

        v-bind="commonProps"

        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
      />

      <Checkbox
        v-else-if="isCheckboxInput($props.input)"
        v-bind="commonProps"
        @change="onChange"
        @input="onInput"
      />

      <DayPicker
        v-else-if="isDayPicker($props.input)"
        v-bind="commonProps"
        @change="onChange"
      />

      <Pills
        v-else-if="isPills($props.input)"
        v-bind="commonProps"
        @change="onChange"
      />

      <Address
        v-else-if="isAddress($props.input)"
        v-bind="commonProps"
        @change="onChange"
      />

      <Phones
        v-else-if="isPhone($props.input)"
        v-bind="commonProps"
        @change="onChange"
      />

      <File
        v-else-if="isFile($props.input)"
        v-bind="commonProps"
        @change="onChange"
        @fileAction="onFileAction"
        @toggleRemove="onToggleRemove"
        @toast="onToast"
      />

      <Label
        v-else-if="isLabel($props.input)"
        v-bind="commonProps"
        @click="onClick"
      />

      <TreeView
        v-else-if="isTreeView($props.input)"
        v-bind="commonProps"
        @change="onChange"
      />

      <PasswordStrength
        v-else-if="isPasswordStrengthInput($props.input)"
        v-bind="commonProps"

        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        @change="onChange"
      />

      <CodeInput
        v-else-if="isCodeInput($props.input)"
        v-bind="commonProps"

        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        @change="onChange"
      />

      <DatePartial
        v-else-if="isDatePartial($props.input)"
        v-bind="commonProps"
        @change="onChange"
      />

      <transition name="validation">
        <div
          v-if="showValidationMessage"
          :class="[styles.message, styles.validationMessage]"
        >
          <Icon type="alert-circle"/>
          {{ $localize($props.input.validationMessage) }}
        </div>
      </transition>
      <transition name="warning">
        <div
          v-if="$props.input && $props.input.warningMessage"
          :class="[styles.message, styles.warningMessage]"
        >
          <Icon type="alert-triangle"/>
          {{ $localize($props.input.warningMessage) }}
        </div>
      </transition>
      <transition name="hint">
        <div
          v-if="$props.input && $props.input.message"
          :class="[styles.message, hintError && styles.hintError]"
        >
          <Icon
            type="info"
          />
          {{ $localize($props.input.message) }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import { isBoolean, isNil } from 'lodash';

  import { TabIndexSubscriberMixin } from '@h4h/tab-index-manager';

  import {
    isFile,
    isPills,
    isLabel,
    isRadio,
    isPhone,
    isAddress,
    isWysiwyg,
    isDayPicker,
    isDateRange,
    isTextInput,
    isTypeahead,
    isFloatInput,
    isSelectInput,
    isIntegerInput,
    isBooleanInput,
    isTextAreaInput,
    isCheckboxInput,
    isKindOfDateInput,
    isCountrySelectInput,
    isSelect2Input,
    isTypeahead2Input,
    isTreeView,
    isPasswordStrengthInput,
    isCodeInput,
    isDatePartial
  } from '../utils/matchers';

  import { inputProps } from '../utils/props';

  import TextInput from './Text';
  import File from './file/File';
  import Label from './label/Label';
  import TextArea from './TextArea';
  import Pills from './pills/Pills';
  import Radio from './radio/Radio';
  import Phones from './phone/Phones';
  import NumberInput from './Number';
  import Select from './select/Select';
  import Wysiwyg from './wysiwyg/Wysiwyg';
  import Address from './address/Address';
  import Select2 from './newSelect/Select2';
  import Checkbox from './checkbox/Checkbox';
  import TreeView from './treeView/TreeView';
  import CountrySelect from './CountrySelect';
  import DateInput from './dateInput/DateInput';
  import Typeahead from './typeahead/Typeahead';
  import DateRange from './dateRange/DateRange';
  import DayPicker from './dayPicker/DayPicker';
  import Typeahead2 from './newSelect/Typeahead2';
  import PasswordStrength from './passwordStrength/PasswordStrength';
  import CodeInput from './codeInput/CodeInput';

  import styles from './smart.scss';
  import DatePartial from './datePartial/DatePartial';

  export default {
    name: 'H4hSmartInput',

    components: {
      Typeahead2,
      Select2,
      CountrySelect,
      NumberInput,
      TextInput,
      DateInput,
      Typeahead,
      DateRange,
      DayPicker,
      Checkbox,
      TextArea,
      Wysiwyg,
      Address,
      Select,
      Phones,
      Radio,
      Label,
      Pills,
      File,
      TreeView,
      PasswordStrength,
      CodeInput,
      DatePartial
    },

    props: {
      input: Object,
      action: Function,

      /**
       * @deprecated
       * @todo: Baizulin - get rid of deprecated tabindex implementation
       */
      tabindex: inputProps.tabindex,
      groupId: TabIndexSubscriberMixin.props.groupId,

      icon: {
        type: [String, Boolean],
        default: undefined
      },

      /** labels */
      label: inputProps.label,
      info: inputProps.label,
      otherLabel: inputProps.label,

      /** flags */
      inline: inputProps.booleanFalse,
      disabled: inputProps.booleanFalse,
      autofocus: inputProps.booleanFalse,

      /** styles */
      labelStyle: inputProps.style,
      inputStyle: inputProps.style,

      /** pass-through of input-specific props */
      rows: TextArea.props.rows,
      dropdownClass: Select.props.dropdownClass,
      checkboxStyle: Select.props.checkboxStyle,
      searchAction: Typeahead2.props.searchAction
    },

    data() {
      return {
        styles,
        booleanInputOptions: [
          { label: 'common.yes', value: true },
          { label: 'common.no', value: false }
        ]
      };
    },

    computed: {
      commonProps() {
        const {
          input,
          groupId, tabindex,
          disabled, inline, label,
          info, icon, otherLabel,
          inputStyle, labelStyle,
          autofocus
        } = this.$props;

        return {
          ...input,
          ref: 'input',
          autofocus: input.autofocus || autofocus,
          label: input.label || label,
          groupId: groupId || input.groupId,
          tabindex: isNil(input.tabindex)
            ? tabindex
            : input.tabindex,

          icon: input.icon || icon,
          inline: inline,
          otherLabel: otherLabel || input.otherLabel,
          labelStyle,
          inputStyle: input.inputStyle || inputStyle,
          info: input.info || info,
          disabled: input.disabled || disabled,
          warning: !!input.warningMessage
        };
      },

      showValidationMessage() {
        const { input } = this.$props;
        return input && input.validationMessage && !input.valid && !input.pristine;
      },

      error() {
        return !this.input.valid && !this.input.pristine;
      },
      hintError() {
        return this.error && this.input.validationMessage;
      },
    },

    methods: {
      isFile,
      isLabel,
      isPills,
      isRadio,
      isPhone,
      isAddress,
      isWysiwyg,
      isTextInput,
      isTypeahead,
      isDayPicker,
      isDateRange,
      isFloatInput,
      isSelectInput,
      isIntegerInput,
      isBooleanInput,
      isCheckboxInput,
      isTextAreaInput,
      isTypeahead2Input,
      isSelect2Input,
      isKindOfDateInput,
      isCountrySelectInput,
      isTreeView,
      isPasswordStrengthInput,
      isCodeInput,
      isDatePartial,

      onInput(value) {
        this.$emit('input', value);
      },

      onChange(value) {
        const { action, input } = this.$props;
        if (action) {
          action({ input, value });
        }
        else {
          this.$emit('change', value);
        }
      },

      onOtherOptionChange(config) {
        const { action } = this.$props;

        if (action) {
          action(config);
        }
        else {
          this.$emit('change-other-option-input', config);
        }
      },

      onSearch(query) {
        const { searchAction, input } = this.$props;
        const action = searchAction || input.searchAction;
        if (action) {
          action({ input, query });
        }
        else {
          this.$emit('search', query);
        }
      },

      onFocus(e) {
        this.$emit('focus', e);
      },

      onBlur(e) {
        this.$emit('blur', e);
      },

      onClick(e) {
        this.$emit('click', e);
      },

      onBooleanInputChange(value) {
        const parsed = {
          true: true,
          false: false
        }[value];

        this.onChange(
          isBoolean(parsed)
            ? parsed
            : value
        );
      },

      focus() {
        this.$refs.input.focus?.();
      },

      onFileAction(e) {
        this.$emit('fileAction', e);
      },

      onToast(toasts) {
        this.$emit('toast', toasts);
      },

      onToggleRemove(id) {
        const { input } = this.$props;
        if (input.toggleRemoveAction) {
          input.toggleRemoveAction({ input, id });
        }
      },
    }
  };
</script>
