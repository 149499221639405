<template>
  <div :class="styles.labelGroup">
    <div :class="styles.label">
      <LabelTooltip
        :info="$props.info"
        :label="$props.label"
        :labelStyle="$props.labelStyle"
      />
    </div>
    <div :class="styles.othersLabel">
      {{ $localize($props.otherLabel) }}
    </div>
  </div>
</template>

<script>
  import { inputProps } from '../../utils';

  import LabelTooltip from '../labelTooltip/LabelTooltip';

  import styles from './labelGroup.scss';

  export default {
    name: 'H4hLabelGroup',

    components: {
      LabelTooltip
    },

    props: {
      /** labels */
      info: inputProps.label,
      label: inputProps.label,
      otherLabel: inputProps.label,

      /** styles */
      labelStyle: inputProps.style
    },

    data() {
      return {
        styles
      };
    }
  };
</script>

