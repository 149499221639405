export class CronObjectModel {
  /**
   * CronObjectModel config
   *
   * @param {Object}    config
   * @param {String}    config.minute
   * @param {String}    config.hour
   * @param {String}    config.day
   * @param {String}    config.month
   * @param {String}    config.dayOfWeek
   */
  constructor(config) {
    this.second = '0';
    this.minute = config.minute;
    this.hour = config.hour || null;
    this.day = config.day || null;
    this.month = config.month || null;
    this.dayOfWeek = config.dayOfWeek || null;
  }

  toCronString() {
    if (Object.keys(this).some(k => this[k] === null)) {
      return null;
    }
    else {
      return `${ this.second } ${ this.minute } ${ this.hour } ${ this.day } ${ this.month } ${ this.dayOfWeek }`;
    }
  }
}

export class CronInputModel {
  /**
   * CronInputModel config
   *
   * @param {Object}                config
   * @param {cronFrequencyTypes}    config.type
   * @param {String}                config.cron
   * @param {Boolean}               config.valid
   */
  constructor(config) {
    this.type = config.type;
    this.cron = config.cron;
    this.valid = config.valid;
  }

  get value() {
    return this.cron;
  }

  set value(value) {
    this.cron = value;
  }
}

