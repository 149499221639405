import { types } from './types';
import { mapValues } from 'lodash';
import { ObservationDefinitionModel } from '../../../model/ObservationTemplateModel';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { PopupType } from '@h4h/popups';

export const actions = Object.freeze({
  initPopup,
  save,
  cancel,
});

async function initPopup({ commit }, terminologyTypes) {
  const { id, closed } = await popups.show({
    name: PopupName.AddObservationTemplateDefinition,
    type: PopupType.Modal
  });
  commit(types.INIT_POPUP, { popupId: id, terminologyTypes: terminologyTypes });
  return { closed };
}

async function save({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }
  const definition = new ObservationDefinitionModel({
    ...mapValues(state.inputsMap, i => i.value),
    type: state.inputsMap.type.value.value,
    unitCode: state.inputsMap.unitCode.value.value,
  });
  popups.hide({ id: state.popupId, result: definition });
}

async function cancel({ state }) {
  popups.hide({ id: state.popupId });
}
