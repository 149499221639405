<template>
  <VTabItem
    :disabled="disabled"
    :eager="eager"
  >
    <slot>
      {{ content }}
    </slot>
  </VTabItem>
</template>

<script>
  import { VTabItem } from 'vuetify/lib';

  export default {
    name: 'H4hTabContent',
    components: {
      VTabItem,
    },
    props: {
      disabled: Boolean,
      eager: Boolean,
      content: String,
    },
  };
</script>
