import { MultipleFiltersProfilesConfig } from './model/MultipleFiltersProfilesConfig';
import { get } from 'lodash';

export const MultipleProfilesMixin = {
  props: {
    multipleFiltersProfilesConfig: {
      type: MultipleFiltersProfilesConfig,
      required: true,
    },
  },
  computed: {
    storeNamespace() {
      return this.$props.multipleFiltersProfilesConfig.storeNamespace;
    },
    actionsNamespace() {
      return this.storeNamespace.replaceAll('.', '/');
    },
  },
  methods: {
    async dispatch(action, payload) {
      return await this.$store.dispatch(`${ this.actionsNamespace }/${ action }`, payload, { root: true });
    },
    async setInputValue(payload) {
      await this.dispatch('setInputValue', payload);
    },
    getState(path) {
      return get(this.$store.state, `${ this.storeNamespace }.${ path }`);
    }
  }

};
