import { Severity } from '../../../../../constants/severity';
import { greaterThanField, lessThanField } from '../../../../../model/thresholds/triggerConditionModel';
import { makeSeverityConditionId } from './SeverityConditionGroup';

export const SeverityConditionsTranslationMap = Object.freeze({
  [makeSeverityConditionId(Severity.HIGH, greaterThanField)]: 'observationTemplates.criticalWhenValueHigherThan',
  [makeSeverityConditionId(Severity.HIGH, lessThanField)]: 'observationTemplates.criticalWhenValueIsLessThan',
  [makeSeverityConditionId(Severity.MEDIUM, greaterThanField)]: 'observationTemplates.deviatingWhenAValueOfGreaterThan',
  [makeSeverityConditionId(Severity.MEDIUM, lessThanField)]: 'observationTemplates.deviatingWhenValueIsLessThan',
});
export const SeverityConditionsLabelTranslationMap = Object.freeze({
  [makeSeverityConditionId(Severity.HIGH, greaterThanField)]: 'observationTemplates.upperLimit',
  [makeSeverityConditionId(Severity.HIGH, lessThanField)]: 'observationTemplates.lowerLimit',
  [makeSeverityConditionId(Severity.MEDIUM, greaterThanField)]: 'observationTemplates.warningHigh',
  [makeSeverityConditionId(Severity.MEDIUM, lessThanField)]: 'observationTemplates.warningLow',
});
export const SeverityConditionsSortOrder = [
  makeSeverityConditionId(Severity.HIGH, lessThanField),
  makeSeverityConditionId(Severity.MEDIUM, lessThanField),
  makeSeverityConditionId(Severity.MEDIUM, greaterThanField),
  makeSeverityConditionId(Severity.HIGH, greaterThanField),
];

