export class ProductVersionModel {
  /**
   * Country
   *
   * @param {Object}    config
   * @param {String}    config.version
   * @param {String}    config.suffix
   * @param {String}    config.releaseDate
   */
  constructor(config) {
    this.version = config.version;
    this.suffix = config.suffix;
    this.releaseDate = config.releaseDate;
  }

}

export class UdiDiModel {
  /**
   * Country
   *
   * @param {Object}    config
   * @param {String}    config.productName
   * @param {String}    config.productVersion
   * @param {String}    config.udiDi
   * @param {String}    config.errorMessage
   * @param {String}    config.status
   */
  constructor(config) {
    this.productName = config.productName;
    this.productVersion = config.productVersion;
    this.udiDi = config.udiDi;
    this.errorMessage = config.errorMessage;
    this.status = config.status;
  }

}
