import { types } from './types';
import { showRestError } from '../../utils/errors';
import { terminology } from '../../services/api/terminology/terminology';

export const actions = Object.freeze({
  fetchTerminologyTypes,
});

async function fetchTerminologyTypes({ commit, rootState, state }) {
  let locale = rootState.preferences.localeId ? rootState.preferences.localeId : 'en';
  if (state.terminologyTypes && state.terminologyTypes.length > 0) {
    return state.terminologyTypes;
  }

  const result = await terminology.fetchOne({ params: { 'l': locale } });
  if (!result.success) {
    showRestError(result.error, 'messages.cantFetchTerminology');
    return [];
  }
  else {
    let terminologyTypes = result.data.i18n;
    commit(types.SET_TERMINOLOGY_TYPES, terminologyTypes);
    return terminologyTypes;
  }
}
