import moment from 'moment';
import Cookie from 'js-cookie';
import { MenuState } from '../constants/menuState';

import { i18n } from '../services/i18n';
import { currentCcLanguage, currentLanguage } from '../services/api/i18n/currentLanguage';
import { isCallCenter } from '../services/auth/auth';

const localeId = 'nl';
setLocale(localeId);

export const preferences = {
  namespaced: true,

  state: {
    menuState: Cookie.get('menuState') || MenuState.Expanded,
    localeId,
    localeIsSet: false,
  },

  actions: {
    setLocale({ commit }, localeId) {
      commit('setLocale', localeId);
    },

    async loadLanguageSettings({ commit }) {
      const provider = isCallCenter() ? currentCcLanguage : currentLanguage;
      const result = await provider.fetchOne();
      if (result.success) {
        const isoCode = result.data;
        commit('setLocale', isoCode);
      }
      commit('setLocaleIsSet');
    },

    setMenuState({ commit }, menuState) {
      commit('setMenuState', menuState);
    }
  },

  mutations: {
    setLocale(state, locale) {
      state.localeId = locale;
      setLocale(locale);
    },

    setLocaleIsSet(state) {
      state.localeIsSet = true;
    },

    setMenuState(state, menuState) {
      state.menuState = menuState;
      Cookie.set('menuState', menuState);
    }
  }
};

function setLocale(localeId) {
  i18n.locale = localeId;
  moment.locale(localeId);

  Cookie.set('locale', localeId);
}
