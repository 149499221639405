import { download, openPdf, openImage } from '../utils/fileUtils';

export class CustomDocument {
  /**
   * Document config
   *
   * @param {Object}  config
   * @param {File}    config.file
   * @param {String}  config.name
   * @param {String}  [config.id]
   * @param {String}  [config.type]
   * @param {Number}  [config.size]
   * @param {Boolean} [config.removed] indicates that an existing file was removed
   */
  constructor(config) {
    this.id = config.id;
    this.name = config.name;
    this.type = config.type?.toLowerCase();
    this.file = config.file;
    this.size = config.size;
    this.removed = config.removed;
  }

  static fromApi(config) {
    if (!config) {
      return null;
    }

    const name = config.name || config.fileName || 'Document.pdf';
    const { id, blob, removed } = config;

    const type = name.split('.').slice(-1)[0];

    // we don't need to fill file if blob is not defined to avoid
    // errors while calling view method
    const file = blob
      ? new File([blob], name, { type: `application/${ type }` })
      : null;

    return new CustomDocument({
      id,
      name,
      type,
      file,
      size: !id && file.size,
      removed
    });
  }

  static fromFile(file) {
    return CustomDocument.fromApi({
      blob: file,
      name: file.name
    });
  }

  static fromBase64(base64) {
    if (!base64) {
      return null;
    }

    const binary = atob(base64.replace(/\s/g, ''));
    const length = binary.length;

    const blob = new Uint8Array(new ArrayBuffer(length));

    for (let i = 0; i < length; i++) {
      blob[i] = binary.charCodeAt(i);
    }

    return CustomDocument.fromApi({ blob });
  }

  // if showInTab true will be opened as tab, otherwise as new window
  view = async showInTab => {
    switch (this.type.toLowerCase()) {
      case 'pdf':
        openPdf(this.file, showInTab);
        break;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
        await openImage(this.file, showInTab);
        break;
      default:
        this.download();
        break;
    }
  }

  download = () => {
    download(this.name, URL.createObjectURL(this.file));
  }
}
