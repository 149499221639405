import { regexValidators } from '../constants';
import { everyNumericListMemberIsValid, isValidNumericRange, isValidStepExpression, isValidWeekdaysRange, numberIsInRange } from '../helpers';

/**
 *
 * @param {Object} [ruleset]
 *
 * @param {Array} [ruleset.allowedValues]
 *
 * @param {Object} [ruleset.range]
 * @param {Boolean} [ruleset.range.from=-Infinity]
 * @param {Boolean} [ruleset.range.to=+Infinity]
 *
 * @param {Boolean} [ruleset.numericSingle=false]
 *
 * @param {Boolean} [ruleset.numericList=false]
 *
 * @param {Boolean} [ruleset.numericRange=false]
 *
 * @param {Boolean} [ruleset.numericSteps=false]
 *
 * @param {Boolean} [ruleset.weekdaySingle=false]
 *
 * @param {Boolean} [ruleset.weekdayList=false]
 *
 * @param {Boolean} [ruleset.weekdayRange=false]
 */
export function generateRulesetValidatorForPartialCron(ruleset) {
  // merge the ruleset with the default ruleset
  const finalRuleset = {
    allowedValues: null || ruleset.allowedValues,
    range: {
      ...{ from: -Infinity, to: +Infinity },
      ...(ruleset.range ? ruleset.range : {}),
    },
    numericSingle: false || ruleset.numericSingle,
    numericList: false || ruleset.numericList,
    numericRange: false || ruleset.numericRange,
    numericSteps: false || ruleset.numericSteps,
    weekdaySingle: false || ruleset.weekdaySingle,
    weekdayList: false || ruleset.weekdayList,
    weekdayRange: false || ruleset.weekdayRange,
  };

  // create a validation function
  return partial => {
    // check if it exists at all
    if (!partial) {
      return false;
    }
    // check if the cron is already in allowed values
    if (
      finalRuleset.allowedValues &&
      finalRuleset.allowedValues.indexOf(partial) >= 0
    ) {
      return true;
    }
    // check if it can be a numeric value
    else if (
      finalRuleset.numericSingle &&
      regexValidators.isNumericValue.test(partial)
    ) {
      return numberIsInRange(
        partial,
        finalRuleset.range,
      );
    }
    // check if it can be a numeric list
    else if (
      finalRuleset.numericList &&
      regexValidators.isListOfNumericValues.test(partial)
    ) {
      return everyNumericListMemberIsValid(
        n => numberIsInRange(
          n,
          finalRuleset.range,
        )
      )(partial);
    }
    // check if it can be a numeric range
    else if (
      finalRuleset.numericRange &&
      regexValidators.isRangeOfNumericValues.test(partial)
    ) {
      return isValidNumericRange(
        partial,
        n => numberIsInRange(
          n,
          finalRuleset.range,
        )
      );
    }
    // check if it can be a stepping
    else if (
      finalRuleset.numericSteps &&
      regexValidators.isStepOfNumericValues.test(partial)
    ) {
      return isValidStepExpression(
        partial,
        true,
        n => numberIsInRange(
          n,
          finalRuleset.range,
        )
      );
    }
    // check if it can be a weekday
    else if (
      finalRuleset.weekdaySingle &&
      regexValidators.isASingleWeekday.test(partial)
    ) {
      return true;
    }
    // check if it can be a list of weekdays
    else if (
      finalRuleset.weekdayList &&
      regexValidators.isAnArrayOfWeekdays.test(partial)
    ) {
      return true;
    }
    // check if it's a range of weekdays
    else if (
      finalRuleset.weekdayRange &&
      regexValidators.isARangeOfWeekdays.test(partial)
    ) {
      return isValidWeekdaysRange(partial);
    }

    // if all else fails return false
    return false;
  };
}