export const getters = {
  isValid,
  username,
};

function isValid(state) {
  return state.input && state.input.valid;
}

function username(state) {
  return state.input && state.input.value;
}
