import { mapValues } from 'lodash';
import { TableFiltersProfiles } from '@h4h/multiple-table-filters';

export class UserPreferences {
  /**
   * UserPreferences config
   *
   * @param {Object}    config
   * @param {String[]}  config.last10Patients
   * @param {Object}    config.tableFilters
   * @param {Object}    config.tableFilterProfiles
   */
  constructor(config) {
    this.last10Patients = Array.from(config.last10Patients || []);
    this.tableFilterProfiles = mapValues({ ...config.tableFilterProfiles }, v => new TableFiltersProfiles(v));
  }

  pushPatientToLast10Patients(uuid) {
    const list = this.last10Patients.filter(x => x !== uuid);
    list.unshift(uuid);
    this.last10Patients = list.slice(0, 10);
  }

  setTableFilterProfiles(tableId, tableFilterProfiles) {
    this.tableFilterProfiles[tableId] = new TableFiltersProfiles(tableFilterProfiles);
  }
}
