import { Page } from '../../../constants/page';
import { EhRole } from '../../auth/ehRole';
import { enrollmentsRoutes } from './practitioner/patients/enrollments/enrollmentsRoutes';
import { simpleAnamnesisRoutes } from './practitioner/patients/simpleAnamnesis/simpleAnamnesisRoutes';
import { TaskRoutes } from './taskRoutes';
import { DashboardRoutes } from './dashboardRoutes';
import { disable } from '../routerPermissions';

export const PractitionerRoutes = {
  path: 'practitioner',
  name: Page.Practitioner,
  redirect: { name: Page.PractitionerPatients },
  component: () => import('../../../components/views/app/practitioner/PractitionerRoot'),
  meta: {
    title: 'practitioner.practitioner',
    roles: [EhRole.Poh, EhRole.Physician, EhRole.CallCenterSupport, EhRole.RKZUser]
  },
  children: [
    {
      path: 'call-center',
      name: Page.CallCenterPatients,
      component: () => import('../../../components/views/app/call-center-support/Patients'),
      redirect: { name: Page.CallCenterPatientsList },
      meta: {
        title: 'patient.patients',
        roles: [disable(EhRole.SsoUser)]
      },
      children: [
        {
          path: 'patients-list',
          name: Page.CallCenterPatientsList,
          component: () => import('../../../components/views/app/call-center-support/list/PractitionerPatientsList'),
          meta: {
            title: 'patient.patients',
          }
        },
      ]
    },
    {
      path: 'troubleshooting-report',
      name: Page.CallCenterTroubleShootingReport,
      component: () => import('../../../components/troubleshooting/report/TroubleshootingReport'),
      meta: {
        title: 'troubleshooting.troubleshootingReport',
      },
    },
    {
      path: 'patients',
      name: Page.PractitionerPatients,
      component: () => import('../../../components/views/app/practitioner/patients/Patients'),
      redirect: { name: Page.PractitionerPatientsList },
      meta: {
        title: 'patient.patients'
      },
      children: [
        {
          path: 'list',
          name: Page.PractitionerPatientsList,
          component: () => import('../../../components/views/app/practitioner/patients/list/PractitionerPatientsList'),
          meta: {
            title: 'patient.patients',
            roles: [disable(EhRole.SsoUser)]
          }
        },
        {
          path: 'add',
          name: Page.PractitionerAddPatient,
          component: () => import('../../../components/views/app/practitioner/patients/add/PractitionerAddPatient'),
          meta: {
            title: 'patient.addPatient',
            roles: [disable(EhRole.SsoUser)]
          }
        },
        {
          path: 'view/:userId',
          redirect: { name: Page.PractitionerPatientDetails },
          props: true,
          name: Page.PractitionerViewPatient,
          component: () => import('../../../components/views/app/practitioner/patients/view/PractitionerViewPatient'),
          meta: {
            title: 'patient.patient',
          },
          children: [
            {
              path: 'details',
              name: Page.PractitionerPatientDetails,
              component: () => import('../../../components/views/app/practitioner/patients/view/details/PatientDetailsComponent'),
              meta: {
                title: 'patient.patientDetails',
              },
            },
            {
              path: 'edit',
              name: Page.PatientEditDetails,
              component: () => import('../../../components/views/app/practitioner/patients/view/details/PatientEditDetailsComponent'),
              meta: {
                title: 'patient.patientDetails',
              },
            },
            enrollmentsRoutes,
            simpleAnamnesisRoutes,
            {
              path: 'anamnesis',
              name: Page.PractitionerPatientAnamnesis,
              component: () => import('../../../components/views/app/practitioner/patients/view/anamnesis/Anamnesis'),
              meta: {
                title: 'anamnesis.anamnesis',
              },
            }
          ]
        }
      ]
    },
    TaskRoutes,
    DashboardRoutes,
    {
      path: 'profile',
      name: Page.PractitionerProfile,
      redirect: { name: Page.PractitionerProfileView },
      component: () => import('../../../components/views/app/practitioner/profile/PractitionerProfileRoot'),
      meta: {
        title: 'common.profile',
        roles: [disable(EhRole.SsoUser)]
      },
      children: [
        {
          path: 'view',
          name: Page.PractitionerProfileView,
          component: () => import('../../../components/views/app/practitioner/profile/ViewPractitionerProfile'),
          meta: {
            title: 'common.profile',
          }
        },
        {
          path: 'edit',
          name: Page.PractitionerProfileEdit,
          component: () => import('../../../components/views/app/practitioner/profile/EditPractitionerProfile'),
          meta: {
            title: 'common.profile',
          }
        }
      ]
    },
    {
      path: 'organization-setting',
      name: Page.PractitionerOrganizationSetting,
      redirect: { name: Page.PractitionerOrganizationSettingView },
      component: () => import('../../../components/views/app/practitioner/organization-setting/PractitionerOrganizationSettingRoot'),
      meta: {
        title: 'common.organizationSetting',
        roles: [disable(EhRole.SsoUser)]
      },
      children: [
        {
          path: 'view',
          name: Page.PractitionerOrganizationSettingView,
          component: () => import('../../../components/views/app/practitioner/organization-setting/ViewPractitionerOrganizationSetting'),
          meta: {
            title: 'common.organizationSetting',
          }
        },
      ]
    },
    {
      path: 'troubleshooting-report',
      name: Page.PractitionerTroubleShootingReport,
      component: () => import('../../../components/troubleshooting/report/TroubleshootingReport'),
      meta: {
        title: 'troubleshooting.troubleshootingReport',
      },
    },
    {
      path: 'users',
      name: Page.PractitionerOrgUsers,
      component: () => import('../../../components/views/app/practitioner/user/PractitionerOrgUsers'),
      redirect: { name: Page.PractitionerOrgUsersList },
      meta: {
        title: 'orgUser.users',
        roles: [disable(EhRole.SsoUser)]
      },
      children: [
        {
          path: 'list',
          name: Page.PractitionerOrgUsersList,
          component: () => import('../../../components/views/app/practitioner/user/list/PractitionerOrgUsersList'),
          meta: {
            title: 'orgUser.users'
          }
        },
        {
          path: 'profile/:userId',
          name: Page.PractitionerOrgUserOverview,
          redirect: { name: Page.PractitionerViewOrgUserProfile },
          props: true,
          component: () => import('../../../components/views/app/practitioner/user/view/PractitionerOrgUserOverview'),
          meta: {
            title: 'orgUser.profile',
          },
          children: [
            {
              path: 'view',
              name: Page.PractitionerViewOrgUserProfile,
              props: true,
              component: () => import('../../../components/views/app/practitioner/user/view/profile/PractitionerOrgUserProfile'),
              meta: {
                title: 'orgUser.profile',
              }
            }
          ]
        }
      ]
    },
  ]
};
