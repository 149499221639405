import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { patientAvatar } from '../../../services/api/patients/patients';

export const actions = Object.freeze({
  fetchAvatar,
});

async function fetchAvatar({ commit }, patientId) {
  commit(types.SET_AVATAR, null);
  commit(types.SET_AVATAR_IS_LOADING, true);
  const result = await patientAvatar(patientId).fetchOne();
  commit(types.SET_AVATAR_IS_LOADING, false);
  if (result.success) {
    commit(types.SET_AVATAR, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchAvatar');
  }
}
