import { cloneDeep } from 'lodash';

export class PageModel {
  /**
   * PageModel config
   *
   * @param {Object}    config
   * @param modelMapper mapper for map to model
   * @param {Object[]}  config.content
   * @param {Boolean}   config.empty
   * @param {Boolean}   config.first
   * @param {Boolean}   config.last
   * @param {Number}    config.number
   * @param {Number}    config.numberOfElements
   * @param {Object}    config.pageable
   * @param {Number}    config.size
   * @param {Object}    config.sort
   * @param {Number}    config.totalElements
   * @param {Number}    config.totalPages
   */
  constructor(config, modelMapper = model => model) {
    config = config || {};

    this.content = config.content ? config.content.map(modelMapper) : config.content;
    this.empty = config.empty;
    this.first = config.first;
    this.last = config.last;
    this.number = config.number;
    this.numberOfElements = config.numberOfElements;
    this.pageable = config.pageable;
    this.size = config.size;
    this.sort = config.sort;
    this.totalElements = config.totalElements;
    this.totalPages = config.totalPages;
  }
}

export function mapDataTablePageConfigToSpringPageConfig(config) {
  if (!config?.page) {
    return {
      page: 0,
      size: 10,
      sort: []
    };
  }
  const result = {
    page: config.page - 1,
    size: config.itemsPerPage || 10,
    sort: cloneDeep(config.sortBy || [])
  };
  for (let i = 0; i < result.sort.length; i++) {
    result.sort[i] += ',' + (config.sortDesc[i] ? 'desc' : 'asc');
  }
  return result;
}

export function toServerPageOptions(page) {
  return { totalElements: page?.totalElements || 0 };
}

export function setPaginationConfigMutation(state, value) {
  Object.assign(state.serverSideOptions, value);
}

export function setPageIndexMutation(state, page) {
  state.serverSideOptions = {
    ...state.serverSideOptions,
    page,
  };
}

export function cleanPageNum(state) {
  // avoid empty options
  if (state.serverSideOptions?.page > 1) {
    // deep watch not always work, so we recreate the object
    state.serverSideOptions = {
      ...state.serverSideOptions,
      page: 1,
    };
  }
}

