import { EhRole } from '../../../services/auth/ehRole';
import { apiRoleMapper } from '../../../services/auth/apiRoleMapper';

export const getters = {
  profileGroups,
  areProfileInputsValid,
  isAdmin,
};

function profileGroups(state) {
  const groups = state.profile.groups;
  if (groups == null) {
    return '';
  }

  let groupsSorted = [...groups].sort((a, b) => a.name.localeCompare(b.name));

  return groupsSorted.map(a => a.name).join(', ');
}

function isAdmin(state) {
  const groups = state.profile?.groups || [];
  const admin = apiRoleMapper.toApi(EhRole.Admin);
  return groups.some(group => group.roles.some(role => role === admin));
}

function areProfileInputsValid(state) {
  return state.inputs && state.inputs.every(i => i.valid);
}
