import { types } from './types';
import { GroupsActions } from '../../../constants/groups';

export const actions = Object.freeze({
  initEditUserGroupsForm,
  joinUserToGroup,
  excludeUserFromGroup
});

async function initEditUserGroupsForm({ commit, dispatch }, userId) {
  commit(types.SET_LOADING, true);
  const groupsAll = await dispatch(GroupsActions.FetchGroupsWithoutPatient, {}, { root: true });
  const groupsCurrent = await dispatch(GroupsActions.FetchUserGroups, { userId: userId }, { root: true });

  const inFirstArrayOnly = (arr1, arr2) =>
    arr1.filter(a => !arr2.some(b => a.uuid === b.uuid));

  let groupsProposed = inFirstArrayOnly(groupsAll, groupsCurrent);
  commit(types.UPDATE_USER_GROUPS, { groupsCurrent, groupsProposed });
  commit(types.SET_LOADING, false);
}

async function joinUserToGroup({ state, commit, dispatch }, { userId, itemId }) {
  commit(types.SET_LOADING, true);
  await dispatch(GroupsActions.JoinUserToGroup, { userId, groupId: itemId }, { root: true });
  await dispatch('orgUsers/editOrgUserProfile/fetchProfile', userId, { root: true });
  await initEditUserGroupsForm({ state, commit, dispatch }, userId);
  commit(types.SET_LOADING, false);
}

async function excludeUserFromGroup({ state, commit, dispatch }, { userId, itemId }) {
  commit(types.SET_LOADING, true);
  await dispatch(GroupsActions.ExcludeUserFromGroup, { userId, groupId: itemId }, { root: true });
  await dispatch('orgUsers/editOrgUserProfile/fetchProfile', userId, { root: true });
  await initEditUserGroupsForm({ state, commit, dispatch }, userId);
  commit(types.SET_LOADING, false);
}