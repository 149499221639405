import { CrudService } from '../../../classes/crudService';
import {
  mapOrganizationSettingModelToApiModel,
  OrganizationCallCenterSettingModel,
  OrganizationSettingModel
} from '../../../model/organizationSettingModel';

export const organizationSetting = new CrudService(
  'organisation-setting',
  data => new OrganizationSettingModel(data),
  mapOrganizationSettingModelToApiModel
);

export const callCenterSetting = new CrudService(
  'organisation-setting/call-center/settings',
  data => new OrganizationCallCenterSettingModel(data)
);

export const organizationSettingDisableCallCenter = new CrudService(
  'organisation-setting/call-center/disable'
);

export const organizationSettingEnableCallCenter = new CrudService(
  'organisation-setting/call-center/enable'
);

export function setLogisticsEnableState(enabled) {
  return new CrudService('/organisation-setting/logistics/state').updateOne({}, {
    params: { enabled }
  });
}
