/* global KEYCLOAK */

import { AuthService } from '@h4h/auth';
import { apiRoleMapper } from './apiRoleMapper';
import Axios from 'axios';
import { EhRole } from './ehRole';
import { getBasePath } from '../basePath';

export const callCenterTenantId = 'call-center';

export const loginKeyName = 'last-login';

export function isCallCenter() {
  // new algorithm
  let auth = getAuth();
  if (auth) {
    return auth.hasRole(EhRole.CallCenterSupport) || auth.hasRole(EhRole.CallCenterAdmin) || auth.hasRole(EhRole.LogisticsSupport);
  }
  // old way
  return getTenant() === callCenterTenantId;
}

export function isLogistic() {
  return isCallCenter() && getAuth()?.hasRole(EhRole.LogisticsSupport);
}

export function isRkz() {
  return getAuth()?.hasRole(EhRole.RKZUser);
}

let auth;
let previousTenant;
let tenant;
let tenantInitialized;
let keycloakAuthServer;

class EhealthAuthService extends AuthService {
  logout() {
    if (this.keycloak.authenticated) {
      this.keycloak.logout({
        redirectUri: getReturnUri()
      });
    }
  }
}

export function getTenant() {
  if (!tenantInitialized) {
    tenant = window.location.pathname.substr(getBasePath().length).split('/')[0];
    tenantInitialized = true;
  }
  return tenant;
}

export function setTenant(value) {
  window.console.log('set tenant', value);
  tenantInitialized = true;
  tenant = value;
  setXTenantIdDefaultHeader(tenant);
  Axios.defaults.headers.common['X-call-center-flag'] = isCallCenter();
}

export function setXTenantIdDefaultHeader(value) {
  Axios.defaults.headers.common['X-Tenant-ID'] = value;
  Axios.defaults.headers.common['X-call-center-flag'] = (getTenant() === callCenterTenantId) ||
    [EhRole.LogisticsSupport, EhRole.CallCenterAdmin, EhRole.CallCenterSupport].some(role => auth?.hasRole(role));
}

export function getAuth() {
  return auth;
}

export function getReturnUri(tenant = null) {
  let origin = window.location.origin;
  const localhosts = ['http://localhost', 'https://localhost'];
  if (!localhosts.some(localhost => origin.indexOf(localhost) === 0)) {
    // don't use redirect for local development
    if (keycloakAuthServer) {
      origin = new URL(keycloakAuthServer).origin;
    }
  }
  return origin + getBasePath() + (tenant || '');
}

export function forceAuth() {
  function buildAuth() {
    previousTenant = getTenant();

    auth = new EhealthAuthService(
      getAuthServiceConfig(),
      role => apiRoleMapper.toApi(role)
    );

    return auth;
  }
  if (!auth) {
    return buildAuth();
  }
  if (previousTenant !== getTenant()) {
    return buildAuth();
  }
  return auth;
}

function getAuthServiceConfig() {
  return Axios.get(KEYCLOAK).then(data => {
    keycloakAuthServer = data.data['auth-server-url'];

    return {
      keycloak: {
        url: keycloakAuthServer,
        realm: data.data.realm,
        clientId: data.data.resource,
      }
    };
  });
}
