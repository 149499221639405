import { apiRoleMapper } from '../services/auth/apiRoleMapper';
import { UserModel } from './userModel';
import { localizeIfExistsOrDefault } from '../services/localize';
import { formatName } from '../utils/formatters/fullNameFormatter';

export class OrgUserModel extends UserModel {
  /**
   * OrgUserModel config
   *
   * @param {Object}      config
   * @param {String}      config.email
   * @param {String}      config.firstName
   * @param {String}      config.gender
   * @param {String}      config.iamId
   * @param {String}      config.initials
   * @param {String}      config.lastName
   * @param {String}      config.middleName
   * @param {String}      config.partnerMiddleName
   * @param {String}      config.partnerName
   * @param {String}      config.phone
   * @param {String}      config.alarmPhoneNumber
   * @param {String}      config.uuid
   * @param {String}      config.bigCode
   * @param {Set<Object>} config.groups
   * @param {String}      config.title
   * @param {Boolean}     config.disableDirectLogin
   */
  constructor(config) {
    super(config);
    config = config || {};
    this.bigCode = config.bigCode;
    this.groups = config.groups;
    this.title = config.title;
    this.disableDirectLogin = config.disableDirectLogin;
  }

  get groupsString() {
    return this.groups.map(group => localizeIfExistsOrDefault('group.' + group.name, group.name))
      .sort()
      .join(', ');
  }

  // used as key-value for sorting
  get 'userGroups.group.name'() {
    return this.groupsString;
  }

  get fullName() {
    return formatName(this.firstName, this.middleName, this.lastName);
  }
}

export class PhysicianModel {
  /**
   * PhysicianModel config
   *
   * @param {Object}      config
   * @param {String}      config.email
   * @param {String}      config.firstName
   * @param {String}      config.alarmPhoneNumber
   * @param {Object[]}    config.groups
   * @param {String}      config.iamId
   * @param {String}      config.middleName
   * @param {String}      config.lastName
   * @param {String}      config.uuid
   * @param {Boolean}     config.disableDirectLogin
   */
  constructor(config) {
    config = config || {};
    this.email = config.email;
    this.firstName = config.firstName;
    this.iamId = config.iamId;
    this.middleName = config.middleName;
    this.lastName = config.lastName;
    this.uuid = config.uuid;
    this.groups = config.groups;
    this.alarmPhoneNumber = config.alarmPhoneNumber;
    this.disableDirectLogin = config.disableDirectLogin;
  }

  formatName(ordering) {
    return formatName(this.firstName, this.middleName, this.lastName, ordering);
  }
}

/**
 * OrgUserModel mapper
 *
 * @param {OrgUserModel}    model
 */
export function mapOrgUserModelToRestEntity(model) {
  return {
    ...model,
    role: apiRoleMapper.toApi(model.role),
    groups: Array.from(model.groups),
  };
}

