import { PopupName } from '../../../../../../constants/popupName';
import { types } from './types';
import { showRestError } from '../../../../../../utils/errors';
import { enrollmentObservationThresholds } from '../../../../../../services/api/enrollments/enrollmentsObservationThresholds';
import { popups } from '../../../../../../services/popups';
import { PopupType } from '@h4h/popups';

export const actions = Object.freeze({
  updateThresholds,
});

/**
 *
 * @param commit
 * @param {UpdateThresholdModel} updateThresholdModel
 */
async function updateThresholds({ commit }, updateThresholdModel) {
  commit(types.SET_UPDATING_THRESHOLDS, true);
  const { id } = await popups.show({
    type: PopupType.Modal,
    name: PopupName.LoadingPopup,
    props: {
      label: 'observationTemplates.pleaseWaitWhileObservationThresholdsAreUpdating',
      hideCloseButton: true
    }
  });
  const result = await enrollmentObservationThresholds(updateThresholdModel.enrollmentUuid).updateOne(updateThresholdModel.observationThreshold);
  popups.hide({ id });
  commit(types.SET_UPDATING_THRESHOLDS, false);

  if (!result.success) {
    showRestError(result.error, 'messages.cantUpdateObservationThresholds');
  }
  return result;
}

