import { observations } from './observations';
import { enrollments } from './enrollments';

export const file = {
  namespaced: true,
  modules: {
    observations,
    enrollments,
  }
};
