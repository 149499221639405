<template>
  <div :style="modalWidth">
    <Tabs
      :value="tabs[0].id"
      :borderBottom="false"
      :class="styles.tabsHeader"
    >
      <template slot="tab">
        <Tab
          v-for="t in $props.tabs"
          :key="t.id"
          :title="t.title"
          :disabled="!!t.disabled"
          :groupId="activeGroupId"
          @change="changeTab(t.id)"
        />
        <Button
          v-if="showClose"
          :class="styles.tabbedClose"
          :groupId="activeGroupId"
          inverted
          round
          xLarge
          text
          icon="x"
          data-testid="button-close"
          @click="close"
        />
      </template>
      <template slot="tabItems">
        <TabContent
          v-for="t in $props.tabs"
          :key="t.id"
        >
          <!-- A PopupContent.vue component fits here -->
          <component
            :is="component(t)"
            v-bind="{ ...t.props, showClose: false }"
          />
        </TabContent>
      </template>
    </Tabs>
  </div>
</template>

<script>
  import { ArrayProp } from '@h4h/classes';
  import { Tabs, Tab, TabContent } from '@h4h/tabs';

  import { PopupTab } from '../classes/popupTab';

  import styles from '../styles/popup.scss';

  export default {
    name: 'H4hTabbedPopup',

    components: {
      TabContent,
      Tabs,
      Tab
    },

    inject: ['popupClose'],

    props: {
      tabs: new ArrayProp({
        type: PopupTab,
        default: () => new PopupTab({
          id: -1,
          title: 'Dummy tab'
        })
      }),

      onClose: {
        type: Function,
      },

      closeAbsolutePosition: {
        type: Boolean,
      },

      showClose: {
        type: Boolean,
        default: true
      },

      // Fixed width for the container.
      // Prevents the popup changing width when switching between tabs.
      width: {
        type: Number,
        default: null
      },

      groupId: {
        type: String
      }
    },

    data() {
      return {
        styles
      };
    },

    computed: {
      modalWidth() {
        if (this.$props.width) {
          return `width: ${ this.$props.width }px !important`;
        }
        return '';
      },

      activeGroupId() {
        return this.$props.tabs.find(t => !t.disabled)?.tabGroupId;
      }
    },

    methods: {
      close() {
        this.onClose ? this.onClose() : this.popupClose();
      },

      component(tab) {
        return this.$popups.getComponent(tab.name);
      },

      changeTab(tabId) {
        this.$emit('changeTab', tabId);
      }
    }
  };
</script>
