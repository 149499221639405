import { CrudService } from '../../../classes/crudService';
import { QuestionnaireModel } from '../../../model/anamnesis/questionnaireModel';
import { PageModel } from '../../../model/page';
import { BatchCreationResultModel } from '../../../model/BatchCreationResultModel';

export function patientQuestionnairePage(uuid) {
  return new CrudService(
    `patients/${ uuid }/questionnaires`,
    data => new PageModel(data, cfg => new QuestionnaireModel(cfg)),
  );
}

export function patientQuestionnaire(uuid) {
  return new CrudService(
    `patients/${ uuid }/questionnaires`,
    data => new QuestionnaireModel(data),
  );
}

export const batchPatientQuestionnaire = patientUuid => new CrudService(
  `/patients/${ patientUuid }/questionnaires/batch`,
  data => new BatchCreationResultModel(data),
  model => model
);

export const questionnaires = new CrudService(
  'questionnaires',
  data => new QuestionnaireModel(data),
);

