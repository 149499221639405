export const InputType = Object.freeze({
  // number inputs
  Float: 'Float',
  Integer: 'Integer',

  // text inputs
  Text: 'Text',
  Label: 'Label',
  Textarea: 'Textarea',
  Typeahead: 'Typeahead',

  // select inputs
  Radio: 'Radio',
  Pills: 'Pills',
  Select: 'Select',
  Select2: 'Select2',
  Typeahead2: 'Typeahead2',
  Boolean: 'Boolean',
  Checkbox: 'Checkbox',
  RadioButton: 'RadioButton',
  CountrySelect: 'CountrySelect',

  // date/time inputs
  Date: 'Date',
  Time: 'Time',
  Calendar: 'Calendar',
  DateTime: 'DateTime',
  DateRange: 'DateRange',
  DayPicker: 'DayPicker',
  MonthPicker: 'MonthPicker',
  DatePartial: 'DatePartial',

  // rich inputs
  File: 'File',
  Phones: 'Phones',
  Address: 'Address',
  Wysiwyg: 'Wysiwyg',
  TreeView: 'TreeView',
  PasswordStrength: 'PasswordStrength',
  CodeInput: 'CodeInput'
});
