export const Page = Object.freeze({
  Root: 'Root',
  Tenant: 'Tenant',
  Ingress: 'Ingress',
  NotFound: 'NotFound',
  Forbidden: 'Forbidden',
  PatientLoginSuccess: 'PatientLoginSuccess',

  Iframe: 'Iframe',
  IframeByExternalPatientId: 'IframeByExternalPatientId',

  Practitioner: 'Practitioner',
  PractitionerProfile: 'PractitionerProfile',
  PractitionerProfileView: 'PractitionerProfileView',
  PractitionerProfileEdit: 'PractitionerProfileEdit',
  PractitionerOrganizationSetting: 'PractitionerOrganizationSetting',
  PractitionerOrganizationSettingView: 'PractitionerOrganizationSettingView',
  PractitionerTroubleShootingReport: 'PractitionerTroubleShootingReport',
  PractitionerDashboard: 'PractitionerDashboard',
  PractitionerTasks: 'PractitionerTasks',
  PractitionerTasksList: 'PractitionerTasksList',

  PractitionerPatients: 'PractitionerPatients',
  PractitionerAddPatient: 'PractitionerAddPatient',
  PractitionerViewPatient: 'PractitionerViewPatient',
  PractitionerPatientsList: 'PractitionerPatientsList',

  PractitionerPatientDetails: 'PractitionerPatientDetails',
  PractitionerPatientAnamnesis: 'PractitionerPatientAnamnesis',

  PractitionerPatientEnrollments: 'PractitionerPatientEnrollments',
  PractitionerPatientEnrollmentsAutoEnroll: 'PractitionerPatientEnrollmentsAutoEnroll',
  PractitionerPatientEnrollmentsList: 'PractitionerPatientEnrollmentsList',
  PractitionerPatientEnrollmentsView: 'PractitionerPatientEnrollmentView',
  PractitionerPatientEnrollmentsViewOverview: 'PractitionerPatientEnrollmentsViewOverview',
  PractitionerPatientEnrollmentsViewObservation: 'PractitionerPatientEnrollmentsViewObservation',
  PractitionerPatientEnrollmentsViewObservationSingle: 'PractitionerPatientEnrollmentsViewObservationSingle',
  PractitionerPatientEnrollmentsViewTerminologyType: 'PractitionerPatientEnrollmentsViewTerminologyType',
  PractitionerPatientEnrollmentsViewQuestionnaire: 'PractitionerPatientEnrollmentsViewQuestionnaire',
  PractitionerPatientEnrollmentsViewQuestionnaireAdd: 'PractitionerPatientEnrollmentsViewQuestionnaireAdd',
  PractitionerPatientEnrollmentsViewQuestionnaireAddChooser: 'PractitionerPatientEnrollmentsViewQuestionnaireAddChooser',
  PractitionerPatientEnrollmentsViewQuestionnaireAddSingle: 'PractitionerPatientEnrollmentsViewQuestionnaireAddSingle',
  PractitionerPatientEnrollmentsViewQuestionnaireAddMultiple: 'PractitionerPatientEnrollmentsViewQuestionnaireAddMultiple',
  PractitionerPatientEnrollmentsViewQuestionnaireEdit: 'PractitionerPatientEnrollmentsViewQuestionnaireEdit',

  PractitionerOrgUsers: 'PractitionerOrgUsers',
  PractitionerOrgUsersList: 'PractitionerOrgUsersList',
  PractitionerOrgUserOverview: 'PractitionerOrgUserOverview',
  PractitionerViewOrgUserProfile: 'PractitionerViewOrgUserProfile',

  Admin: 'Admin',
  AdminHome: 'AdminHome',
  AdminProfile: 'AdminProfile',
  AdminProfileView: 'AdminProfileView',
  AdminProfileEdit: 'AdminProfileEdit',
  AdminOrganizationSetting: 'AdminOrganizationSetting',
  AdminOrganizationSettingView: 'AdminOrganizationSettingView',
  AdminOrganizationSettingEdit: 'AdminOrganizationSettingEdit',
  AdminTroubleShootingReport: 'AdminTroubleShootingReport',
  AdminLanguageSettingEdit: 'AdminLanguageSettingEdit',

  AdminAnamnesis: 'AdminAnamnesis',
  AdminAnamnesisSettings: 'AdminAnamnesisSettings',

  AdminOrgUsers: 'AdminOrgUsers',
  AdminOrgUsersList: 'AdminOrgUsersList',
  AdminAddUser: 'AdminAddUser',
  AdminOrgUserOverview: 'AdminOrgUserOverview',
  AdminViewOrgUserProfile: 'AdminViewOrgUserProfile',
  AdminEditOrgUserProfile: 'AdminEditOrgUserProfile',
  AdminEditOrgUserGroups: 'AdminEditOrgUserGroups',

  AdminGroups: 'AdminGroups',
  AdminGroupsList: 'AdminGroupsList',
  AdminAddGroup: 'AdminAddGroup',
  AdminEditGroup: 'AdminEditGroup',

  AdminObservationTemplates: 'AdminObservationTemplates',
  AdminObservationTemplatesList: 'AdminObservationTemplatesList',
  AdminObservationTemplatesAdd: 'AdminObservationTemplatesAdd',
  AdminObservationTemplatesView: 'AdminObservationTemplatesView',
  AdminObservationTemplatesEdit: 'AdminObservationTemplatesEdit',

  AdminQuestionnaireTemplates: 'AdminQuestionnaireTemplates',
  AdminQuestionnaireTemplatesList: 'AdminQuestionnaireTemplatesList',
  AdminQuestionnaireTemplatesAdd: 'AdminQuestionnaireTemplatesAdd',
  AdminQuestionnaireTemplatesView: 'AdminQuestionnaireTemplatesView',
  AdminQuestionnaireTemplatesEdit: 'AdminQuestionnaireTemplatesEdit',

  AdminLabResultGroups: 'AdminLabResultGroups',
  AdminLabResultGroupsList: 'AdminLabResultGroupsList',
  AdminLabResultGroupsAdd: 'AdminLabResultGroupsAdd',
  AdminLabResultGroupsEdit: 'AdminLabResultGroupsEdit',

  AdminPrograms: 'AdminPrograms',
  AdminProgramsList: 'AdminProgramsList',
  AdminProgramsAdd: 'AdminProgramsAdd',
  AdminProgramsView: 'AdminProgramsView',
  AdminProgramsEdit: 'AdminProgramsEdit',

  CallCenterLogisticUser: 'CallCenterLogisticUser',
  CallCenterLogisticUserTasks: 'CallCenterLogisticUserTasks',
  CallCenterLogisticUserPatients: 'CallCenterLogisticUserPatients',
  CallCenterLogisticUserPatientsList: 'CallCenterLogisticUserPatientsList',
  CallCenterLogisticUserPatientsView: 'CallCenterLogisticUserPatientsView',
  CallCenterLogisticUserPatientDetails: 'CallCenterLogisticUserPatientDetails',
  CallCenterLogisticUserPatientEnrollments: 'CallCenterLogisticUserPatientEnrollments',

  CallCenterPatients: 'CallCenterPatientsPatients',
  CallCenterPatientsList: 'CallCenterPatientsPatientsList',
  CallCenterTroubleShootingReport: 'CallCenterTroubleShootingReport',

  CallCenterAdmin: 'CallCenterAdmin',
  CallCenterUsers: 'CallCenterUsers',
  CallCenterUsersList: 'CallCenterUsersList',
  CallCenterAddUser: 'CallCenterAddUser',
  CallCenterUserOverview: 'CallCenterUserOverview',
  CallCenterUserViewProfile: 'CallCenterUserViewProfile',
  CallCenterUserEditProfile: 'CallCenterUserEditProfile',
  CallCenterUserRoleManagement: 'CallCenterUserRoleManagement',
  CallCenterAdminTroubleShootingReport: 'CallCenterAdminTroubleShootingReport',

  CallCenterAdminLanguageSettingEdit: 'CallCenterAdminLanguageSettingEdit',

  PatientEditDetails: 'PatientEditDetails',

  ChooseRole: 'ChooseRole',
  ChooseCcRole: 'ChooseCcRole',

  SimpleAnamnesis: 'SimpleAnamnesis',
});
