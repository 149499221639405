export const address = {
  postalCode: 'Postcode',
  city: 'Stad',
  country: 'Land',
  number: 'Nummer',
  numberExtension: 'Nummer extensie',
  street: 'Straat',
  enterPostalAddressInformation: 'Voer postadresgegevens in',
  changePostalAddressInformation: 'Wijzig postadresgegevens',
};
