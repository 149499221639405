import moment from 'moment';
import { createEhDateInputConfig } from '../../../../../../../utils/factories';

const defaultAmountOfMonths = 1;

export function periodInputConfig(enrollment) {
  let finish = moment().endOf('day').toDate();
  let start = moment(finish).subtract(defaultAmountOfMonths, 'month').startOf('day').toDate();
  if (enrollment.monitoringPeriod) {
    start = moment(enrollment.monitoringPeriod.start).toDate();
    finish = enrollment.monitoringPeriod.realEndDate || finish;
  }
  return createEhDateInputConfig({
    id: 'observationsListPeriod',
    range: true,
    value: [start, finish],
    inline: true,
    placeholder: 'tasks.period',
  });
}
