import { toast } from '../../../services/toast';

import { types } from './types';
import { localize } from '../../../services/localize';
import { showRestError } from '../../../utils/errors';
import {
  getMandatoryPatientAddressActions,
  sendMandatoryPatientActionAddressRequest
} from '../../../services/api/mandatory-patient-actions/mandatoryPatientActions';

export const actions = Object.freeze({
  loadActions,
  sendAddressRequest,
});

async function loadActions({ commit }, { patientId }) {
  commit(types.SET_ACTIONS, null);
  commit(types.SET_LOADING_ACTIONS, true);
  const result = await getMandatoryPatientAddressActions(patientId);
  commit(types.SET_LOADING_ACTIONS, false);

  if (result.success) {
    commit(types.SET_ACTIONS, result.data);
  }
  else {
    showRestError(result.error, 'mandatoryPatientActions.cantFetchMandatoryPatientActions');
  }
}

async function sendAddressRequest({ commit }, { patientId }) {
  commit(types.SET_REQUEST_IS_PERFORMING, true);
  const result = await sendMandatoryPatientActionAddressRequest(patientId);
  commit(types.SET_REQUEST_IS_PERFORMING, false);
  if (result.success) {
    toast.success(localize('mandatoryPatientActions.selfRegistrationRequestIsCreated'));
    await loadActions({ commit }, { patientId });
  }
  else {
    showRestError(result.error, 'mandatoryPatientActions.cantCreateMandatoryPatientActions');
  }
  return result;
}

