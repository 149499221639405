import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { createMainFilterInput } from '../../../services/filter/utils/filterUtils';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_FETCHED](state, value) {
    state.fetched = value;
  },

  [types.SET_OBSERVATION_TEMPLATES](state, values) {
    state.observationTemplates = values;
  },

  [types.INIT_PAGE](state, { terminologyTypes }) {
    state.filterInput = createMainFilterInput({
      label: 'common.name',
    });
    if (Array.isArray(terminologyTypes) && terminologyTypes.length) {
      state.terminologyTypeFilter = createInputConfig({
        id: 'terminologyTypeFilter',
        type: InputType.Typeahead2,
        multiple: true,
        label: 'observationTemplates.terminologyType',
        options: terminologyTypes.map(terminologyType => ({
          label: terminologyType.translation,
          value: terminologyType.key,
        })),
      });
    }
    else {
      state.terminologyTypeFilter = null;
    }
  },
  [types.UPDATE_FILTER](state) {
    state.filteredRows = (state.observationTemplates || []).filter(observationTemplate => {
      const filter = state.filterInput?.value.trim();
      if (filter && state.filterInput.valid) {
        if (!observationTemplate.name.toLowerCase().includes(filter.toLowerCase())) {
          return false;
        }
      }
      const terminologyTypes = state.terminologyTypeFilter?.value;
      if (terminologyTypes?.size) {
        return terminologyTypes.has(observationTemplate.terminologyType);
      }
      return true;
    });
  },

};
