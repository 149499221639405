<template>
  <div
    ref="popupContent"
    :class="[styles.popupContentContainer, $props.class]"
    :style="initialWidth ? { 'max-width': initialWidth, 'min-width': initialWidth } : ''"
  >
    <slot
      name="header"
      :close="close"
      :headerClass="headerClass"
      :titleClass="styles.title"
      :subtitleClass="styles.subtitle"
    >
      <div
        v-if="hasHeaderContent"
        :class="headerClass"
      >
        <slot
          name="headingContent"
          :titleClass="styles.title"
          :subtitleClass="styles.subtitle"
        >
          <div :class="[styles.heading, 'popupHeading']">
            <div :class="styles.title">
              <slot
                name="heading"
                :close="close"
              >
                <!-- DEPRECATE:
                  Many components use template #heading,
                  - Prefer heading/subheading props for simple text changes.
                  - Prefer headingContent slot for overriding entire heading before actions.
                  Removes need for deeply nested slots
                -->
                {{ $localize($props.heading) }}
              </slot>
            </div>
            <div :class="styles.subtitle">
              <slot
                name="subheading"
                :close="close"
              >
                {{ $localize($props.subheading) }}
              </slot>
            </div>
          </div>
        </slot>
        <slot
          name="headingAction"
          :close="close"
        >
          <Button
            v-if="showClose"
            :class="styles.close"
            inverted
            round
            xLarge
            text
            icon="x"
            :groupId="groupId"
            data-testid="button-close-01"
            @click="close"
          />
        </slot>
      </div>
    </slot>

    <!-- Used for form steps and other similar features -->
    <div
      v-if="hasSlot('subheader')"
      :class="[styles.subheader, 'popupSubheader']"
    >
      <slot
        name="subheader"
        :titleClass="styles.title"
        :subtitleClass="styles.subtitle"
      />
    </div>

    <slot
      v-if="loading"
      name="loading"
    >
      <Loading
        :label="loadingLabel"
        :class="styles.loading"
      />
    </slot>

    <template v-else>
      <div v-if="hasSlot('alert')">
        <slot name="alert"/>
      </div>
      <div
        v-if="hasSlot('content')"
        :class="[styles.content, 'popupContent']"
      >
        <slot name="content"/>
      </div>
      <div
        v-if="hasSlot('footer')"
        :class="[styles.footer, 'popupFooter']"
      >
        <slot name="footer"/>
      </div>
    </template>
  </div>
</template>

<script>
  import { Loading } from '@h4h/loading';
  import { hasSlot } from '@h4h/utils';

  import styles from './popupContent.scss';

  export default {
    name: 'H4hPopupContent',

    components: {
      Loading
    },

    inject: ['popupClose'],

    props: {
      heading: {
        type: [String, Array],
      },

      subheading: {
        type: [String, Array],
      },

      loading: {
        type: Boolean
      },

      loadingLabel: {
        type: [String, Array],
        default: ''
      },

      onClose: {
        type: Function,
      },

      showClose: {
        type: Boolean,
        default: true
      },

      light: {
        type: Boolean,
        default: false
      },
      groupId: {
        type: String
      }
    },

    data() {
      return {
        styles,
        hasSlot,
        initialWidth: ''
      };
    },

    computed: {
      hasHeaderContent() {
        return this.$props.showClose ||
          this.hasSlot('heading') ||
          this.hasSlot('headingAction') ||
          this.$props.heading ||
          this.$props.subheading;
      },

      headerClass() {
        return [
          styles.header,
          this.$props.light ? styles.headerLight : styles.headerPrimary,
          'popupHeader'
        ];
      }
    },

    // https://medical-it-revolution.atlassian.net/browse/MP20-3740
    // Fixing the issue where the modal was extending in width as more items are selected (added) in a multi-select dropdown
    updated() {
      if (!this.initialWidth && !this.loading) {
        this.initialWidth = window.getComputedStyle(
          this.$refs.popupContent).getPropertyValue('width');
      }
    },

    methods: {
      close(...args) {
        this.onClose ? this.onClose(...args) : this.popupClose();
        this.$emit('close');
      }
    }
  };
</script>
