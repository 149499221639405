import { types } from './types';
import { mapValues } from 'lodash';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { MonitoringPeriodQuestionnaireModel } from '../../../model/monitoringPeriodModel';
import { createCronModelAction } from '../../../components/cronEditor/store/actions';

export const actions = Object.freeze({
  initPopup,
  save,
  cancel,
  setCronModel: createCronModelAction(types.SET_CRON_MODEL),
});

async function initPopup({ commit }, { questionnaireTemplates, period }) {
  const { id, closed } = await popups.show({
    name: PopupName.EditProgramQuestionnaire,
    type: PopupType.Modal
  });
  commit(types.INIT_POPUP, { popupId: id, questionnaireTemplates, period });
  return { closed };
}

async function save({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const questionnaire = new MonitoringPeriodQuestionnaireModel({
    ...mapValues(state.inputsMap, i => i.value),
  });
  if (!state.cronEnabled.value) {
    questionnaire.cron = null;
  }

  popups.hide({ id: state.popupId, result: questionnaire });
}

async function cancel({ state }) {
  popups.hide({ id: state.popupId });
}
