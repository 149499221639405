export const organization = {
  callCenterDisablePopupHeading: 'Disable callcenter?',
  callCenterDisablePopupConsent1: 'Are you sure you want to disable the callcenter?',
  callCenterDisablePopupConsent2: `Once the callcenter is disabled tasks, measurements and patient information
    will no longer be visible for the healthcare professionals at callcenter`,
  callCenterEnabled: 'Callcenter enabled',
  callCenterEnablePopupHeading: 'Enable callcenter?',
  callCenterEnablePopupConsent1: 'Are you sure you want to enable the callcenter?',
  callCenterEnablePopupConsent2: `Once the callcenter is enabled tasks, measurements and patient information
    will be visible for the healthcare professionals at callcenter`,
  organization: 'Organization',
  organizationInformation: 'Organization information',
  customForwardingLinkToDoctor: 'Custom forwarding link to doctor',
  customForwardingLinkToDoctorHint: 'Custom links for forwarding tasks to org-users: https://example.com/patients/{externalPatientId} where {externalPatientId} is variable filled by system',
  extraServices: 'Extra services',
  logisticsDevicesEnabled: 'Logistics devices enabled',
  logisticsDevicesEnabledHint: 'Once logistics is enabled, name and address of the patient will be visible to logistics',
  disableLogistics: 'Disable logistics?',
  disableLogisticsText: 'Once logistics is disabled, name and address will no longer be visible to logistics.',
  enableLogistics: 'Enable logistics?',
  enableLogisticsText: 'Once logistics is enabled, name and address will be visible to logistics.',

  cantDisableLogistics: 'Can\'t disable logistics',
  logisticsDisabled: 'Logistics disabled',
  cantEnableLogistics: 'Can\'t enable logistics',
  logisticsEnabled: 'Logistics enabled',
};
