import { types } from './types';
import { mapValues } from 'lodash';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import { router } from '../../../services/router';
import { Page } from '../../../constants/page';
import { showRestError } from '../../../utils/errors';
import { observationTemplates } from '../../../services/api/observations/observationTemplates';
import { observationTemplatesActions } from '../contants';

export const actions = Object.freeze({
  initForm,
  save,
  addObservationTemplateDefinition,
  removeDefinition,
});

async function initForm({ commit }, terminologyTypes) {
  commit(types.INIT_FORM, terminologyTypes);
}

async function removeDefinition({ commit }, definition) {
  commit(types.REMOVE_DEFINITION, definition);
}

async function addObservationTemplateDefinition({ commit }, definition) {
  commit(types.ADD_OBSERVATION_TEMPLATE_DEFINITION, definition);
}

async function save({ state, commit, getters, dispatch }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const observationTemplate = {
    ...mapValues(state.inputsMap, i => i.value),
    terminologyType: state.inputsMap.terminologyType.value.value,
    definitions: state.definitions,
  };

  commit(types.SET_LOADING, true);
  const result = await observationTemplates.create(observationTemplate);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.observationTemplateSaved'));
    dispatch(observationTemplatesActions.ReloadObservationTemplates, {}, { root: true });
    router.push({ name: Page.AdminObservationTemplatesList });
  }
  else {
    showRestError(result.error, 'messages.cantCreateObservationTemplate');
  }
  return result.success;
}
