import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';

export const mutations = {
  [types.INIT_FORM](state, labResultGroup) {
    state.templates = [];
    state.inputs = getInputsConfig(labResultGroup);
    state.inputsMap = getInputsMap(state.inputs);
  },

  [types.SET_LAB_RESULT_GROUP](state, value) {
    state.labResultGroup = value;
  },

  [types.ADD_OBSERVATION_TEMPLATE](state, template) {
    state.labResultGroup.observationTemplates.push(template);
  },

  [types.REMOVE_TEMPLATE](state, template) {
    const idx = state.labResultGroup.observationTemplates.indexOf(template);
    if (idx > -1) {
      state.labResultGroup.observationTemplates.splice(idx, 1);
    }
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  }
};

function getInputsConfig(labResultGroup) {
  const name = createTextInputConfig('name', 'common.name', InputType.Text, labResultGroup.name, true);
  return [
    name
  ];
}

function createTextInputConfig(id, label, inputType, value, disabled) {
  return createInputConfig({
    id,
    type: inputType,
    label,
    required: true,
    value,
    disabled
  });
}

