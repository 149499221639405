<template>
  <div
    :class="[styles.header, {
      [styles.sortable]: $props.sortable,
      [styles.sortedBy]: $props.isSortedBy,
      [styles.desc]: $props.desc,
      [styles.right]: $props.align === 'right'
    }]"
    :style="{ width: _width }"
  >
    <div :class="styles.label">
      <Tooltip
        v-if="hasTooltip"
        :bottom="true"
        :maxWidth="$props.tooltip.maxWidth"
      >
        <template #activatorContent>
          {{ $localize($props.label) }}
          <Icon :type="$props.tooltip.icon"/>
        </template>

        <template #info>
          <span>{{ $localize($props.tooltip.message) }}</span>
        </template>
      </Tooltip>

      <template v-else>
        {{ $localize($props.label) }}
      </template>

      <Button
        v-if="$props.sortable"
        type="arrow-up"
        icon="arrow-up"
        small
        text
        round
        :class="[styles.iconButton, styles.sortIcon]"
        @click="changeSorting"
      />

      <Button
        v-for="(action, index) in $props.actions"
        :key="index"
        :type="action.icon"
        :icon="action.icon"
        small
        text
        round
        :tooltip="action.tooltipText"
        :title="$localize(action.title)"
        :class="[styles.iconButton, action.customClass]"
        :data-testid="`button-${index}`"
        @click="action.action()"
      />
    </div>

    <div
      v-if="$props.subLabel"
      :class="styles.subLabel"
    >
      {{ $localize($props.subLabel) }}
    </div>
  </div>
</template>

<script>
  import { isNumber } from 'lodash';

  import { Tooltip } from '@h4h/tooltip';
  import { ArrayProp } from '@h4h/classes';

  import { ColumnTooltip, HeaderAction } from '../../models';

  import styles from './columnHeader.scss';

  export default {
    name: 'H4hTableColumnHeader',

    components: {
      Tooltip
    },

    props: {
      label: {
        type: [String, Array],
        required: true
      },

      sortable: {
        type: Boolean,
        default: false
      },

      actions: new ArrayProp({
        type: HeaderAction
      }),

      desc: Boolean,
      align: String,
      tooltip: {
        type: ColumnTooltip,
        required: false
      },
      subLabel: String,
      isSortedBy: Boolean,
      width: [Number, String]
    },

    data() {
      return {
        styles
      };
    },

    computed: {
      hasTooltip() {
        return this.$props?.tooltip?.message;
      },

      _width() {
        const { width } = this.$props;

        // HACK: we need to manually set width on the content of the header cell to
        // ensure the column does not collapse the content below that width
        // we also want to allow using percentage-based values though -
        // they will be passed as strings and we don't need to resort to hacking in that case
        return isNumber(width)
          ? width + 'px'
          : null;
      }
    },

    methods: {
      changeSorting() {
        if (this.$props.sortable) {
          this.$emit('changeColumnSorting');
        }
      },
    }
  };
</script>
