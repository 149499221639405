import { list } from './list';
import { viewPatient } from './view';

export const logisticPatients = {
  namespaced: true,
  modules: {
    list,
    viewPatient,
  }
};
