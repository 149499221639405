import { orderBy } from 'lodash';
import { mapObject } from '../../utils/mapUtils';

export class AxiosErrorRequest {

  /**
   * AxiosErrorRequest config
   *
   * @param {Object}      config
   * @param {String}      config.route
   * @param {String}      config.url
   * @param {Object}      config.payload
   * @param {String}      config.tenantIdFlag
   * @param {String}      config.callcenterFlag
   * @param {String}      config.method

   */
  constructor({ payload, tenantIdFlag, callcenterFlag, method, route, url }) {
    this.payload = payload;
    this.tenantIdFlag = tenantIdFlag;
    this.callcenterFlag = callcenterFlag;
    this.method = method;
    this.route = route;
    this.url = url;
  }
}

export class AxiosErrorResponse {

  /**
   * AxiosErrorResponse config
   *
   * @param {Object}      config
   * @param {Number}      config.status
   * @param {String}      config.statusText
   * @param {String}      config.h4hTraceId
   * @param {Object}      config.response
   */
  constructor({ status, h4hTraceId, response, statusText }) {
    this.status = status;
    this.h4hTraceId = h4hTraceId;
    this.response = response;
    this.statusText = statusText;
  }
}

export class AxiosError {

  /**
   * AxiosError config
   *
   * @param {Object}              config
   * @param {AxiosErrorRequest}   config.request
   * @param {AxiosErrorResponse}  config.response
   * @param {Date}                config.date
   * @param {boolean}             config.success
   */
  constructor({ response, request, date, success }) {
    this.response = new AxiosErrorResponse(response);
    this.request = new AxiosErrorRequest(request);
    this.date = mapObject(date, Date);
    this.success = success;
  }

  get id() {
    return this.date ? this.date.getTime() : '';
  }
}

/**
 *
 * @param {AxiosError} error
 */
export function saveAxiosError(error) {
  let errors = loadAxiosErrors();
  errors.unshift(error);
  if (errors.length > 25) {
    errors = errors.slice(0, 25);
  }
  window.localStorage.setItem('axios-errors', JSON.stringify(errors));
}

/**
 *
 * @returns {[AxiosError]}
 */
export function loadAxiosErrors() {
  let axiosErrors = window.localStorage.getItem('axios-errors');
  if (axiosErrors) {
    let list = JSON.parse(axiosErrors).map(object => new AxiosError(object));
    return orderBy(list, ['date'], ['desc']);
  }
  return [];
}

export function removeAxiosErrorByIndex(index) {
  let axiosErrors = loadAxiosErrors();
  axiosErrors.splice(index,1);
  window.localStorage.setItem('axios-errors', JSON.stringify(axiosErrors));
}

/**
 *
 * @returns {boolean}
 */
export function isCaptureOnlyErrors() {
  return window.localStorage.getItem('capture-only-axios-errors') === 'true';
}

/**
 *
 * @param {boolean} value
 */
export function setCaptureOnlyErrors(value) {
  window.localStorage.setItem('capture-only-axios-errors', value);
}
