import { CrudService } from '../../../../classes/crudService';
import { MedicalHistoryModel } from '../../../../model/simpleAnamnesis/medicalHistoryModel';

export const medicalHistories = patientUserUuid => new CrudService(
  `patients/${ patientUserUuid }/medical-histories`,
  data => new MedicalHistoryModel(data)
);

export const delMedicalHistory = new CrudService(
  'patients/medical-histories',
);

export const editMedicalHistory = new CrudService(
  'patients/medical-histories',
  data => new MedicalHistoryModel(data)
);
