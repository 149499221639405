import { apiRoleMapper } from '../services/auth/apiRoleMapper';
import { localizeIfExistsOrDefault } from '../services/localize';

export class GroupModel {
  /**
   * GroupModel config
   *
   * @param {Object}    config
   * @param {String}    config.defaultForRole
   * @param {String}    config.iamId
   * @param {Boolean}   config.immutable
   * @param {String}    config.name
   * @param {String[]}  config.roles
   * @param {String}    config.uuid
   */
  constructor(config) {
    config = config || {};
    this.defaultForRole = apiRoleMapper.fromApi(config.defaultForRole);
    this.iamId = config.iamId;
    this.immutable = config.immutable;
    this.name = config.name;
    this.roles = [];
    if (Array.isArray(config.roles)) {
      this.roles = config.roles.map(role => apiRoleMapper.fromApi(role));
    }
    this.uuid = config.uuid;
  }

  get rolesString() {
    return this.roles
      .map(role => localizeIfExistsOrDefault('role.' + role, role))
      .sort()
      .join(', ');
  }
}

/**
 * GroupModel mapper
 *
 * @param {GroupModel}    model
 */
export function mapGroupModelToRestEntity(model) {
  model = model || {};
  let entity = {
    ...model,
    defaultForRole: apiRoleMapper.toApi(model.defaultForRole),
    roles: (Array.from(model.roles || [])).map(ehRole => apiRoleMapper.toApi(ehRole)),
  };
  return entity;
}

/**
 * Convert list of groups to options list (for HTML <select>)
 *
 * @param {GroupModel[]}    models
 */
export function mapGroupModelsToOptions(models) {
  return models.map(model => ({
    label: localizeIfExistsOrDefault(`group.${ model.name }`, model.name),
    value: model.uuid
  }));
}

