<template>
  <div :class="styles.actionBar">
    <slot
      v-for="action in $props.actions"
      :name="getWysiwygActionSlots(action).item"
      v-bind="action"
    >
      <div
        :key="action.id"
        :class="[styles.action, $props.disabled && 'disabled']"
        :title="$localize(action.label)"
        @click="onActionClick($event, action)"
      >
        <slot :name="getWysiwygActionSlots(action).content">
          {{ $localize(action.label) }}
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
  import { WysiwygActionType, wysiwygActionsList, getWysiwygActionSlots } from '../../utils/wysiwygUtils';

  import styles from './wysiwyg.scss';

  export default {
    name: 'H4hWysiwygActionBar',

    props: {
      actions: {
        type: Array,
        default: () => wysiwygActionsList,
        validator(value) {
          return Array.isArray(value) && value.every(action => {
            return action.id && action.label && WysiwygActionType[action.type];
          });
        }
      },

      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        styles
      };
    },

    methods: {
      onActionClick($event, action) {
        if (!this.$props.disabled) {
          this.$emit('action', action);
        }
      },

      getWysiwygActionSlots
    }
  };
</script>
