export const observationTemplates = {
  observationTemplates: 'Observatie sjablonen',
  addObservationTemplate: 'Observatie sjabloon toevoegen',
  observationTemplate: 'Observatie sjabloon',
  terminologyType: 'Type terminologie',
  definitionsCount: 'Definities tellen mee',
  definitions: 'Definities',
  addDefinition: 'Definitie toevoegen',
  inputType: 'Invoertype',
  unitCode: 'Eenheidscode',
  limits: 'Grenswaarden',
  criticalWhenValueHigherThan: 'Kritisch wanneer waarde hoger dan',
  criticalWhenValueIsLessThan: 'Kritisch wanneer waarde lager dan',
  deviatingWhenAValueOfGreaterThan: 'Afwijkend wanneer waarde hoger dan',
  deviatingWhenValueIsLessThan: 'Afwijkend wanneer waarde lager dan',
  resetDefaultLimits: 'Reset standaard grenswaarden',
  xMustNotBeLowerThanY: '`{0}` mag niet lager zijn dan `{1}`',
  upperLimit: 'Bovengrens',
  warningHigh: 'Waarschuwing hoog',
  warningLow: 'Waarschuwing laag',
  lowerLimit: 'Ondergrens',
  oppositeLimit: '(tot {0} {1})',
  pleaseWaitWhileObservationThresholdsAreUpdating: 'Een ogenblik geduld terwijl de observatiedrempels worden bijgewerkt',
  showRemovedLimits: 'Toon verwijderde limieten',
  hideRemovedLimits: 'Verberg verwijderde limieten',
  addMissingLimits: 'Voeg ontbrekende limieten toe',
};
