import { types } from './types';
import { environmentService } from '../../services/api/version/environment';
import { authorizationRemover } from '../certification/authorizationRemover';
import { showRestError } from '../../utils/errors';

export const actions = Object.freeze({
  initEnvironment,
});

async function initEnvironment({ commit }) {
  commit(types.SET_ENVIRONMENT, null);
  commit(types.SET_LOADING, true);
  const environment = await environmentService.fetchOne({ ...authorizationRemover });
  if (environment.success) {
    commit(types.SET_ENVIRONMENT, environment.data);
  }
  else {
    showRestError(environment.error, 'messages.cantFetchEnvironmentProperties');
  }
  commit(types.SET_LOADING, false);
}
