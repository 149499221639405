export const validationHelper = {
  props: {
    rules: Array,
  },
  data: () => ({
    errorMessage: null,
    isValid: true
  }),
  methods: {
    /**
     * Will validate all rules of the input
     * If there is a provided value then it will use that one otherwise
     * it will use the actual value from the input used in v-model
     */
    validateRules(providedValue) {
      if (this.rules) {
        for (let rule of this.rules) {
          const result = rule(providedValue || this.$props.value);
          if (result !== true) {
            this.errorMessage = this.$localize(result);
            this.isValid = result;
            return;
          }
          else {
            this.errorMessage = null;
            this.isValid = result;
          }
        }
      }
    }
  }
};
