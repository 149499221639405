import { mapArray } from '../utils/mapUtils';

export class QuestionnaireTemplateModel {
  /**
   * ObservationTemplateModel config
   *
   * @param {Object}                       config
   * @param {String}                       config.title
   * @param {String}                       config.uuid
   * @param {QuestionDefinitionModel[]}    config.questions
   */
  constructor(config) {
    this.title = config.title;
    this.uuid = config.uuid;
    this.questions = mapArray(config.questions, QuestionDefinitionModel);
  }
}

export class QuestionDefinitionModel {
  /**
   * QuestionDefinitionModel config
   *
   * @param {Object}                config
   * @param {String}                config.answerTerminologyUnit
   * @param {AnswerType}            config.answerType
   * @param {AnswerOptionModel[]}   config.answersValueSet
   * @param {Number}                config.max
   * @param {Number}                config.min
   * @param {String}                config.questionTerminologyCode
   *
   */
  constructor(config) {
    this.answerTerminologyUnit = config.answerTerminologyUnit;
    this.answerType = config.answerType;
    this.answersValueSet = mapArray(config.answersValueSet, AnswerOptionModel);
    this.max = config.max;
    this.min = config.min;
    this.questionTerminologyCode = config.questionTerminologyCode;
  }
}

export class AnswerOptionModel {
  /**
   * AnswerOptionModel config
   *
   * @param {Object}            config
   * @param {String}            config.answerFreeString
   * @param {Number}            config.answerInt
   * @param {Number}            config.answerNumeric
   * @param {String}            config.answerTerminology
   * @param {AnswerOptionType}  config.type
   */
  constructor(config) {
    this.answerFreeString = config.answerFreeString;
    this.answerInt = config.answerInt;
    this.answerNumeric = config.answerNumeric;
    this.answerTerminology = config.answerTerminology;
    this.type = config.type;
  }
}

