<template>
  <div :class="styles.container">
    <div :class="[styles.spinner, $props.primary && styles.primary]"/>
  </div>
</template>

<script>
  import styles from './spinnerLoading.scss';

  export default {
    name: 'H4hSpinnerLoading',

    props: {
      primary: Boolean
    },

    data() {
      return {
        styles
      };
    }
  };
</script>
