import { observationBuilder } from './observationBuilder';
import { PageModel } from '../page';
import { mapObject } from '../../utils/mapUtils';
import { GroupedObservationModel } from './groupedObservationModel';
import { observationDateField } from './observationModel';
import moment from 'moment';
import { ISO_FULL_DATE_FORMAT } from '../../constants/dateConstants';

export class OptimizedObservationPageModel {

  /**
   * @param {ObservationModel}  currentObservation
   * @param {ObservationModel}  previousObservation
   * @param {ObservationModel}  nextObservation
   * @param {PageModel}         currentPage
   */
  constructor({ currentObservation, previousObservation, nextObservation, currentPage }) {
    this.currentObservation = observationBuilder(currentObservation);
    this.previousObservation = observationBuilder(previousObservation);
    this.nextObservation = observationBuilder(nextObservation);
    this.currentPage = new PageModel(currentPage, config => observationBuilder(config));
  }

}

export class OptimizedGroupedObservationPageModel {

  /**
   * @param {ObservationModel}  currentObservation
   * @param {ObservationModel}  previousObservation
   * @param {ObservationModel}  nextObservation
   * @param {PageModel}         currentPage
   */
  constructor({ currentObservation, previousObservation, nextObservation, currentPage }) {
    this.currentObservation = mapObject(currentObservation, GroupedObservationModel);
    this.previousObservation = mapObject(previousObservation, GroupedObservationModel);
    this.nextObservation = mapObject(nextObservation, GroupedObservationModel);
    this.currentPage = new PageModel(currentPage, config => new GroupedObservationModel(config));
  }

}

export class OptimizedPageRequestParams {

  /**
   * @param {Date}              from
   * @param {Date}              to
   * @param {String}            observationUuid
   * @param {string}            type
   */
  constructor({
    from,
    to,
    observationUuid,
    type,
  }) {
    this.type = type;
    this.size = 10;
    this.sort = observationDateField + ',desc';
    this.observationUuid = observationUuid;
    this.from = moment(from || new Date()).startOf('day').format(ISO_FULL_DATE_FORMAT);
    this.to = moment(to || new Date()).endOf('day').format(ISO_FULL_DATE_FORMAT);
  }
}

