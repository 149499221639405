import { uniqueId, debounce } from 'lodash';

import { fixedColumnBorder, fixedColumnTdZIndex, fixedColumnThZIndex } from './stickyColumns.scss';

import { Shared } from './shared';

export const StickyColumns = {
  props: {
    fixColumnStart: {
      type: Number,
      default: 0
    },

    fixColumnEnd: {
      type: Number,
      default: 0
    },

    showSelect: Shared.props.showSelect,
    actions: Shared.props.actions
  },

  data() {
    return {
      stickyCols__id: uniqueId('stickyCols-'),
      stickyCols__styleEl: null
    };
  },

  computed: {
    stickyCols__element() {
      return this.$el;
    },

    stickyCols__leading() {
      let leading = this.$props.fixColumnStart;

      if (this.hasRowSelectionColumn && leading) {
        leading++;
      }

      return leading;
    },

    stickyCols__trailing() {
      return this.$props.fixColumnEnd;
    },

    hasActions: Shared.computed.hasActions,
    hasRowSelectionColumn: Shared.computed.hasRowSelectionColumn
  },

  mounted() {
    // can't use debounce on method definition since it will be shared across multiple instances
    this.stickyCols__update = debounce(
      this.stickyCols__update,
      250,
      { leading: true, maxWait: 50, trailing: true }
    );

    this.stickyCols__update();
  },

  updated() {
    this.$nextTick(() => {
      this.stickyCols__update();
    });
  },

  beforeDestroy() {
    this.stickyCols__destroy();
  },

  methods: {
    stickyCols__init() {
      this.stickyCols__element.id = this.stickyCols__id;

      if (this.stickyCols__styleEl) {
        return;
      }

      this.stickyCols__styleEl = document.createElement('style');
      this.stickyCols__styleEl.type = 'text/css';

      document.head.appendChild(this.stickyCols__styleEl);
      window.addEventListener('resize', this.stickyCols__update);
    },

    stickyCols__destroy() {
      if (!this.stickyCols__styleEl) {
        return;
      }

      this.stickyCols__styleEl.remove();
      this.stickyCols__styleEl = null;
      window.removeEventListener('resize', this.stickyCols__update);
    },

    stickyCols__update() {
      if (!this.stickyCols__leading && !this.stickyCols__trailing) {
        return this.stickyCols__destroy();
      }
      else {
        this.stickyCols__init();
      }

      // @todo: Baizulin - optimize, no need to update styles if returned value hasn't changed
      this.stickyCols__styleEl.innerHTML = generateFixedColumnStyles(
        this.stickyCols__id,
        this.stickyCols__element.querySelector('tr'),
        this.stickyCols__leading,
        this.stickyCols__trailing,
        this.hasActions
      );
    }
  }
};

// @todo: Baizulin - optimize, no need to recalculate anything if widths haven't changed
// @todo: Baizulin - optimize, get rid of potential reflows/repaints
export function generateFixedColumnStyles(tableId, row, leading, trailing, hasActions) {
  const columns = Array.from(row.querySelectorAll('th,td'));

  if (!columns.length) {
    return '';
  }

  const fixedColumns = getFixedColumns(columns, row, leading, trailing, hasActions);

  let styles = '';

  styles += generateStickyStyles(fixedColumns, tableId);
  styles += generateThStyles(fixedColumns, tableId);
  styles += generateTdStyles(fixedColumns, tableId);
  styles += generateColumnStyles(fixedColumns, tableId);

  return styles;
}

function calculateOffsets(columns, rowWidth) {
  let leftCumulative = 0;
  let rightCumulative = rowWidth;

  return columns.map(col => {
    const left = leftCumulative;

    leftCumulative += col.offsetWidth;
    rightCumulative -= col.offsetWidth;

    return {
      left,
      right: rightCumulative
    };
  });
}

function getFixedColumns(columns, row, leading, trailing, hasActions) {
  const lastLeftFixedIndex = leading - 1;
  const lastRightFixedIndex = columns.length - trailing;

  const offsets = calculateOffsets(columns, row.offsetWidth);

  return columns
    .map((col, index) => {
      if (index > lastLeftFixedIndex && index < lastRightFixedIndex) {
        return null;
      }

      const { left, right } = offsets[index];
      const isLastColumn = columns.length - 1 === index;
      const isActionsColumn = hasActions && isLastColumn;

      if (index <= lastLeftFixedIndex) {
        return {
          index,
          position: 'left',
          offset: left,
          hasBorder: index === lastLeftFixedIndex && !isLastColumn
        };
      }
      else {
        return {
          index,
          position: 'right',
          offset: right,
          hasBorder: index === lastRightFixedIndex && !isActionsColumn
        };
      }
    })
    .filter(x => x);
}

function generateStickyStyles(columns, tableId) {
  const selector = columns
    .map(({ index }) => [generateThSelector(tableId, index), generateTdSelector(tableId, index)])
    .flat()
    .join(',');

  return selector + '{position: sticky;background: inherit;}';
}

function generateThStyles(columns, tableId) {
  const selector = columns
    .map(({ index }) => generateThSelector(tableId, index))
    .join(',');

  return selector + `{z-index: ${ fixedColumnThZIndex };}`;
}

function generateTdStyles(columns, tableId) {
  const selector = columns
    .map(({ index }) => generateTdSelector(tableId, index))
    .join(',');

  return selector + `{z-index: ${ fixedColumnTdZIndex };}`;
}

function generateColumnStyles(columns, tableId) {
  return columns
    .map(({ index, position, offset, hasBorder }) => {
      const selector = [generateThSelector(tableId, index), generateTdSelector(tableId, index)].join(',');
      return selector + `{${ generateColumnStyleProperties(position, offset, hasBorder) }}`;
    })
    .join('');
}

function generateThSelector(id, index) {
  return generateColumnSelector(id, index, 'th');
}

function generateTdSelector(id, index) {
  return generateColumnSelector(id, index, 'td');
}

function generateColumnSelector(id, index, tag) {
  return `#${ id } ${ tag }:nth-child(${ index + 1 })`;
}

function generateColumnStyleProperties(position, offset, hasBorder) {
  let styles = `${ position }: ${ offset }px;`;

  if (hasBorder) {
    styles += `border-${ position === 'left' ? 'right' : 'left' }: ${ fixedColumnBorder };`;
  }

  return styles;
}
