import Vue from 'vue';

export function getLocalValues() {
  const localize = Vue.prototype.$localize;
  const shorthand = Array.from(['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'], i => localize(`common.shorthandDays.${ i.toLowerCase() }`));
  const longhand = Array.from(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], i => localize(`common.longhandMonths.${ i.toLowerCase() }`));
  return {
    weekdays: { shorthand },
    months: { longhand },
    weekAbbreviation: ''
  };
}
