import { mapValues } from 'lodash';

import { toast } from '../../../../services/toast';

import { types } from './types';
import { localize } from '../../../../services/localize';
import { showRestError } from '../../../../utils/errors';
import { callCenterUsers } from '../../../../services/api/users/callCenterUsers';
import { apiRoleMapper } from '../../../../services/auth/apiRoleMapper';
import { router } from '../../../../services/router';
import { Page } from '../../../../constants/page';
import { convertPhonesToStrings } from '../../../../utils/phone/phoneComponentUtils';
import { fetchCountries } from '../../../countries/utils';
import { getCurrentCallCenterUser } from '../current/utils';

export const actions = Object.freeze({
  saveUser,
  initForm,
});

async function saveUser({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const user = mapValues(state.inputsMap, i => i.value);
  convertPhonesToStrings(user, ['phone']);

  user.role = apiRoleMapper.toApi(user.role);

  commit(types.SET_LOADING, true);
  const result = await callCenterUsers.create(user);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.userSaved'));
    router.push({ name: Page.CallCenterUsersList });
  }
  else {
    showRestError(result.error, 'messages.cantCreateUser');
  }
  return result.success;
}

async function initForm({ commit, dispatch }) {
  const [countries, currentUserResult] = await Promise.all([
    fetchCountries(dispatch),
    getCurrentCallCenterUser(dispatch),
  ]);
  let visibleCountry = null;
  if (currentUserResult.success) {
    visibleCountry = currentUserResult.data.visibleCountry;
  }
  commit(types.INIT_FORM, { countries, visibleCountry });
}
