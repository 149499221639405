import { types } from './types';
import { InputType, validateDateInput } from '@h4h/inputs';
import { QuestionnaireWrapper } from '../../../../../../components/questionnaire/wrapper/questionnaireWrapper';
import { QuestionnaireModel } from '../../../../../../model/anamnesis/questionnaireModel';
import { ObservationTemplateInputGroup } from '../../observation/popup/utils';
import { decodeTemplatesGuids } from './groups/utils';
import { uniq } from 'lodash';
import { without } from 'lodash';
import { createEhDateInputConfig } from '../../../../../../utils/factories';
import moment from 'moment';

function getInputGroups(questionnaireTemplate, questionnaireTemplateUuids, questionnaireTemplates, terminologyTypes, patient) {
  if (!questionnaireTemplateUuids) {
    return [createQuestionnaireTemplateInputGroup(questionnaireTemplate, terminologyTypes, patient)];
  }
  else {
    const rest = uniq(without(decodeTemplatesGuids(questionnaireTemplateUuids), questionnaireTemplate.uuid));
    return [questionnaireTemplate.uuid, ...rest]
      .map(uuid => questionnaireTemplates.find(ot => ot.uuid === uuid))
      .filter(ot => !!ot)
      .map(ot => createQuestionnaireTemplateInputGroup(ot, terminologyTypes, patient));
  }
}

function validateCurrentTime(input) {
  if (validateDateInput(input)) {
    return new Date().getTime() >= input.value.getTime();
  }
}

function createQuestionnaireTemplateInputGroup(questionnaireTemplate, terminologyTypes, patient) {
  const questionnaire = new QuestionnaireModel({
    patientUuid: patient.uuid,
    questionnaireTemplateId: questionnaireTemplate.uuid,
    questionnaireIdempotencyKey: '' + Math.random(),
  });
  const wrapper = new QuestionnaireWrapper(questionnaireTemplate, questionnaire);
  const inputGroup = new ObservationTemplateInputGroup({
    questionnaireTemplate,
    inputs: wrapper.getInputs(terminologyTypes),
  });
  inputGroup.questionnaire = questionnaire;
  return inputGroup;
}

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.INIT_FORM](state, {
    questionnaireTemplate,
    questionnaireTemplateUuids,
    questionnaireTemplates,
    patient,
    terminologyTypes,
    enrollment
  }) {
    state.patient = patient;
    state.inputGroups = getInputGroups(questionnaireTemplate, questionnaireTemplateUuids, questionnaireTemplates, terminologyTypes, patient, state.originalObservationDate);
    state.monitoringPeriodStartDate = enrollment.monitoringPeriod.start;
    state.originalQuestionnaireDate = moment(new Date()).seconds(0).toDate();
    state.date = createEhDateInputConfig({
      id: 'observationDate',
      type: InputType.DateTime,
      required: true,
      value: state.originalQuestionnaireDate,
      label: 'patients.questionnaireDate',
      max: moment().add(2, 'hour').toDate(), // we gave 2 hours to perform measurement. This should be more than enough
      validate: validateCurrentTime
    });
  },

  [types.SET_PRISTINE](state, value) {
    state.inputGroups.forEach(ig => ig.inputs.forEach(input => input.pristine = value));
    state.date.pristine = value;
  },

};
