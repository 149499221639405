/* global PRODUCTION */
import { types } from './types';
import { showRestError } from '../../utils/errors';
import { programs } from '../../services/api/programs/programs';

export const actions = Object.freeze({
  fetchPrograms,
  reload,
  initPage,
  onFilterChange,
});

async function initPage({ commit, state }) {
  commit(types.INIT_PAGE);
  await fetchPrograms({ commit, state });
}

async function onFilterChange({ commit }) {
  commit(types.UPDATE_FILTER);
}

async function load(state, commit) {
  if (state.fetched) {
    return state.programs;
  }

  commit(types.SET_LOADING, true);
  commit(types.SET_FETCHED, false);
  const result = await programs.fetch();
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_FETCHED, true);
    if (PRODUCTION) { // there is a lot of programms on dev. It is not a problem for QA, but on local development it slowdowns the app.
      commit(types.SET_PROGRAMS, result.data);
    }
    else {
      commit(types.SET_PROGRAMS, result.data.slice(0, 100));
    }
  }
  else {
    showRestError(result.error, 'messages.cantFetchPrograms');
  }
  commit(types.UPDATE_FILTER);
  // return any result anyway. Empty array is better then null.
  return state.programs;
}

async function fetchPrograms({ state, commit }) {
  return await load(state, commit);
}

async function reload({ state, commit }) {
  commit(types.SET_FETCHED, false);
  return await load(state, commit);
}
