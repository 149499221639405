import { types } from './types';
import { createInputConfig, getInputsMap, InputType, validateTextInput } from '@h4h/inputs';
import { EhPatterns } from '../../../utils/ehPatterns';
import { EhUserFieldsLengthConstraints } from '../../../utils/ehUserFieldsLengthConstraints';
import moment from 'moment';
import { createEhDateInputConfig } from '../../../utils/factories';
import { genderOptions } from '../../../constants/gender';
import { createAddressInputConfig } from '../../../utils/addressComponentUtils';
import { createPhoneInputConfig } from '../../../utils/phone/phoneComponentUtils';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.INIT_FORM](state, { patient, countries }) {
    const inputs = getInputsConfig(patient || {}, InputType.Text, countries);
    state.inputs = inputs;
    state.inputsMap = getInputsMap(inputs);
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => {
      input.pristine = value;
    });
  },

  [types.INIT_PATIENT_ARCHIVING_POPUP](state, { popupId, patientUuid }) {
    state.patientArchivingPopupId = popupId;
    state.currentPatientUuid = patientUuid;
  },

  [types.SET_PATIENT_FINAL_ARCHIVING_WARNING_POPUP](state, { popupId }) {
    state.patientFinalArchivingWarningPopupId = popupId;
  },

  [types.CLEAR_INPUTS](state) {
    state.inputs = null;
  },
};

function getInputsConfig(patient, inputType, countries) {
  const externalPatientId = createTextInputConfig(patient.externalPatientId, 'externalPatientId', 'common.hisPatientId', InputType.Text, null, false, !!patient.externalPatientId);
  const patientNumber = createTextInputConfig(patient.patientNumber, 'patientNumber', 'patient.patientNumber', InputType.Text, null, false, !!patient.patientNumber);
  const initials = createTextInputConfig(patient.initials, 'initials', 'common.initials', inputType, EhPatterns.lettersDotsOnly, false, false, EhUserFieldsLengthConstraints.initials);
  const firstName = createTextInputConfig(patient.firstName, 'firstName', 'common.firstName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, !!patient.firstName, false, EhUserFieldsLengthConstraints.firstName);
  const middleName = createTextInputConfig(patient.middleName, 'middleName', 'common.middleName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, false, EhUserFieldsLengthConstraints.middleName);
  const lastName = createTextInputConfig(patient.lastName, 'lastName', 'common.lastName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, true, false, EhUserFieldsLengthConstraints.lastName);
  const phone = createPhoneInputConfig(patient.phone, 'phone', 'common.telephoneNumber', true);
  const email = createTextInputConfig(patient.email, 'email', 'common.email', inputType, EhPatterns.email, true, false, EhUserFieldsLengthConstraints.email);
  const emailAgain = {
    ...createTextInputConfig(patient.email, 'emailAgain', 'common.emailAgain', inputType, EhPatterns.email, true, false, EhUserFieldsLengthConstraints.email),
    validate(input) {
      return validateTextInput(input) && email.value === input.value;
    },
    getStatePatch() {
      return { fakeFieldForUpdate: Math.random() };
    }
  };
  email.linkedInputs = [emailAgain];
  const partnerMiddleName = createTextInputConfig(patient.partnerMiddleName, 'partnerMiddleName', 'common.partnerMiddleName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, false, EhUserFieldsLengthConstraints.partnerMiddleName);
  const partnerName = createTextInputConfig(patient.partnerName, 'partnerName', 'common.partnerName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, false, EhUserFieldsLengthConstraints.partnerName);
  const address = createAddressInputConfig(patient.address, countries, 'address', 'common.postalAddress', false);

  const dateOfBirth = createEhDateInputConfig({
    label: 'common.dateOfBirth',
    value: patient.dateOfBirthAsDate,
    disabled: false,
    id: 'dateOfBirth',
    required: true,
    validate: input => {
      return moment(input.value).isSameOrBefore(new Date());
    }
  });

  const gender = createInputConfig({
    id: 'gender',
    type: InputType.Select2,
    clearable: false,
    required: true,
    label: 'gender.gender',
    options: genderOptions,
    value: patient.gender,
  });

  return [
    externalPatientId,
    patientNumber,
    initials,
    firstName,
    middleName,
    lastName,
    partnerMiddleName,
    partnerName,
    dateOfBirth,
    gender,
    email,
    emailAgain,
    phone,
    address,
  ];
}

function createTextInputConfig(value = null, id, label, inputType, pattern, required = false, disabled = false, constraints) {
  return createInputConfig({
    id,
    type: inputType,
    pattern,
    value,
    required,
    label,
    disabled: disabled,
    ...constraints,
  });
}
