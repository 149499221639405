export const AddressProvisionResult = Object.freeze({
  CONFIRM_ADDRESS: 'CONFIRM_ADDRESS',
  WAIT_FOR_PENDING_USER_ADDRESS_REQUEST: 'WAIT_FOR_PENDING_USER_ADDRESS_REQUEST',
});

export const AddressDialogMode = Object.freeze({
  CREATE_NEW: 'CREATE_NEW',
  UPDATE: 'UPDATE',
});

export class AddressDialogInitModel {
  /**
   *
   * @param {Object} params
   * @param {String} params.mode //AddressDialogMode
   * @param {AddressModel} params.address
   */
  constructor(params) {
    this.mode = params.mode;
    this.address = params.address || null;
  }
}
