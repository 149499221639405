export class AnamnesisInitModel {
  /**
   * AnamnesisInitModel
   *
   * @param {Object}                        config
   * @param {String}                        config.patientId
   * @param {String}                        config.anamnesisTemplateId
   * @param {TerminologyTranslationModel[]} config.terminologyTypes
   */
  constructor(config) {
    this.patientId = config.patientId;
    this.anamnesisTemplateId = config.anamnesisTemplateId;
    this.terminologyTypes = config.terminologyTypes;
  }
}

export class AnamnesisComponentObjectsModel {
  /**
   * AnamnesisComponentObjectsModel
   *
   * @param {Object}                        config
   * @param {String}                        config.patientId
   * @param {QuestionnaireTemplateModel}    config.questionnaireTemplate
   * @param {AnamnesisModel}                config.anamnesis
   * @param {TerminologyTranslationModel[]} terminologyTypes
   */
  constructor(config) {
    this.patientId = config.patientId;
    this.questionnaireTemplate = config.questionnaireTemplate;
    this.anamnesis = config.anamnesis;
    this.terminologyTypes = config.terminologyTypes;
  }
}
