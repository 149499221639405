import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { translations } from '../locales/index';

Vue.use(VueI18n);
const LOCALE = 'en';
export const i18n = new VueI18n({
  locale: LOCALE,
  fallbackLocale: LOCALE,

  messages: translations
});
