export function filterPhones(item, queryText) {
  const query = queryText || '';
  const searchFields = [item.label, item.value.code2, item.value.code3, item.value.name]
    .filter(i => i)
    .map(i => i.toLowerCase());
  if (!searchFields.length) {
    return true;
  }

  return searchFields.some(field => field.includes(query.toLowerCase()));
}
