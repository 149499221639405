import { types } from './types';
import { cleanPageNum, setPaginationConfigMutation, toServerPageOptions } from '../../../../model/page';
import { createMainFilterInput, filerConstraints } from '../../../../services/filter/utils/filterUtils';
import { PatientFilterFields } from '../../../patients/list/constants';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';
import { createEhDateInputConfig } from '../../../../utils/factories';
import { genderOptions } from '../../../../constants/gender';
import { mapValues } from 'lodash';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '@h4h/date';

function createFilterParams(state) {
  let inputsMap = getInputsMap(state.inputs.filter(input => !!input.value));
  const params = { ...mapValues(inputsMap, i => i.value) };
  if (params[PatientFilterFields.dateOfBirth]) {
    params[PatientFilterFields.dateOfBirth] = moment(params[PatientFilterFields.dateOfBirth]).format(ISO_DATE_FORMAT);
  }
  if (params[PatientFilterFields.genders]) {
    params[PatientFilterFields.genders] = Array.from(params[PatientFilterFields.genders]);
  }
  return params;
}

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PATIENTS_PAGE](state, value) {
    state.patientsPage = value;
    Object.assign(state.serverSideOptions, toServerPageOptions(value));
  },

  [types.SET_PAGINATION_CONFIG]: setPaginationConfigMutation,

  [types.SET_TABLE_OPTIONS](state, value) {
    state.tableOptions = value;
  },

  [types.INIT_FORM](state) {
    state.inputs = [
      createMainFilterInput({
        id: PatientFilterFields.name,
        label: 'common.name',
      }),
      createInputConfig({
        id: PatientFilterFields.callCenterPatientId,
        type: InputType.Text,
        label: 'common.hisPatientId',
        ...filerConstraints,
      }),
      createEhDateInputConfig({
        id: PatientFilterFields.dateOfBirth,
        label: 'common.dateOfBirth',
      }),
      createInputConfig({
        id: PatientFilterFields.email,
        type: InputType.Text,
        label: 'common.email',
        ...filerConstraints,
      }),
      // createMainFilterInput({
      //   id: PatientFilterFields.name,
      // }),
      createInputConfig({
        id: PatientFilterFields.genders,
        type: InputType.Select2,
        label: 'gender.gender',
        multiple: true,
        options: genderOptions,
      }),
      createInputConfig({
        id: PatientFilterFields.includeArchived,
        type: InputType.Checkbox,
        label: 'patient.showArchivedPatients',
      }),
    ];
    state.filterParams = createFilterParams(state);
  },

  [types.UPDATE_FILTER_PARAMS](state) {
    state.filterParams = createFilterParams(state);
    cleanPageNum(state);
  }
};

