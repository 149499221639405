<template>
  <div
    :class="inputStyles.addressFields"
  >
    <div :class="styles.row">
      <div
        v-if="$props.showType"
        :class="styles.inputContainer"
      >
        <Select2
          v-bind="inputs.addressType"
          :groupId="$props.groupId"
          @change="onChange({ input: inputs.addressType, value: $event })"
        />
      </div>
    </div>

    <div :class="styles.row">
      <div :class="styles.inputContainer">
        <LabelGroup
          :label="'address.street'"
        />
        <TextInput
          v-bind="inputs.street"

          :placeholder="' '"
          :groupId="$props.groupId"

          :valid="$props.valid || inputs.street.valid"
          :disabled="$props.disabled || inputs.street.disabled"
          :pristine="$props.pristine && inputs.street.pristine"

          @change="onChange({ input: inputs.street, value: $event })"
        />
      </div>
      <div :class="styles.inputsInSameRow">
        <LabelGroup
          :label="'common.number'"
        />
        <TextInput
          v-bind="inputs.number"

          :placeholder="' '"
          :groupId="$props.groupId"

          :valid="$props.valid || inputs.number.valid"
          :disabled="$props.disabled || inputs.number.disabled"
          :pristine="$props.pristine && inputs.number.pristine"

          @change="onChange({ input: inputs.number, value: $event })"
        />
      </div>
      <div :class="styles.inputsInSameRow">
        <LabelGroup
          :label="'address.numberExtension'"
        />
        <TextInput
          v-bind="inputs.numberExtension"

          :placeholder="' '"
          :groupId="$props.groupId"

          :valid="$props.valid || inputs.numberExtension.valid"
          :disabled="$props.disabled || inputs.numberExtension.disabled"
          :pristine="$props.pristine && inputs.numberExtension.pristine"

          @change="onChange({ input: inputs.numberExtension, value: $event })"
        />
      </div>
    </div>

    <div :class="styles.row">
      <div :class="styles.inputsInSameRow">
        <LabelGroup
          :label="'address.postalCode'"
        />
        <TextInput
          v-bind="inputs.postalCode"

          :placeholder="' '"
          :groupId="$props.groupId"

          :valid="$props.valid || inputs.postalCode.valid"
          :disabled="$props.disabled || inputs.postalCode.disabled"
          :pristine="$props.pristine && inputs.postalCode.pristine"

          @change="onChange({ input: inputs.postalCode, value: $event })"
        />
      </div>
      <div :class="styles.inputContainer">
        <LabelGroup
          :label="'address.city'"
        />
        <TextInput
          v-bind="inputs.city"

          :placeholder="' '"
          :groupId="$props.groupId"

          :valid="$props.valid || inputs.city.valid"
          :disabled="$props.disabled || inputs.city.disabled"
          :pristine="$props.pristine && inputs.city.pristine"

          @change="onChange({ input: inputs.city, value: $event })"
        />
      </div>
    </div>
    <div :class="styles.row">
      <div :class="styles.inputContainer">
        <LabelGroup
          :label="'address.country'"
        />
        <Typeahead2
          v-bind="inputs.country"

          :placeholder="'common.select'"
          :groupId="$props.groupId"

          :valid="$props.valid || inputs.country.valid"
          :disabled="$props.disabled || inputs.country.disabled"
          :pristine="$props.pristine && inputs.country.pristine"

          @change="onChange({ input: inputs.country, value: $event })"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapValues, values } from 'lodash';

  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { InputMixin } from '../../mixins';
  import { AddressModel } from '../../models';
  import { addressProps, inputProps, setInputValue } from '../../utils';

  import TextInput from '../Text';
  import Select2 from '../newSelect/Select2';
  import Typeahead2 from '../newSelect/Typeahead2';
  import LabelGroup from '../labelGroup/LabelGroup';

  import {
    initField,
    patchValidation,
    getSelectedCountry,
    formatCountryOptions,
    getAddressCommonConfig,
    getSelectedAddressType
  } from './utils';

  import styles from './address.scss';

  export default {
    name: 'H4hRegularAddress',

    components: {
      LabelGroup,
      Select2,
      Typeahead2,
      TextInput
    },

    mixins: [
      InputMixin
    ],

    props: {
      value: addressProps.value,
      customizations: addressProps.customizations,
      countryOptions: addressProps.countryOptions,
      defaultCountryCode: addressProps.defaultCountryCode,

      /** flags */
      showType: inputProps.booleanFalse
    },

    data: function() {
      return {
        inputStyles,
        styles,
        inputs: {
          ...getAddressCommonConfig(this.$props),
          street: initField(this.$props.value, 'street', 'address.street', 0.1, true, this.$props.tabindex, this.$props.customizations),
          number: initField(this.$props.value,'number', 'common.number', 0.2, true, this.$props.tabindex, this.$props.customizations),
          numberExtension: initField(this.$props.value,'numberExtension', 'address.numberExtension', 0.3, false, this.$props.tabindex, this.$props.customizations),
        }
      };
    },

    computed: {
      addressInputs() {
        return values(this.inputs || {}).filter(input => input.id !== 'addressType' && input.id !== 'addressKind');
      },
    },

    watch: {
      value(newValue) {
        this.applyNewValue(newValue);
      }
    },

    mounted() {
      this.addressInputs.forEach(i => i.originalRequired = i.required);
      const {
        inputs: { country, addressType },
        $props: { countryOptions, value, defaultCountryCode }
      } = this;

      const selectedCountry = getSelectedCountry(value, countryOptions, defaultCountryCode);
      const selectedAddressType = getSelectedAddressType(value);

      if (countryOptions !== country.options) {
        country.options = formatCountryOptions(countryOptions);
      }

      if (country.value !== selectedCountry) {
        setInputValue({
          input: country,
          value: selectedCountry
        });
      }
      setInputValue({
        input: addressType,
        value: selectedAddressType,
      });

      patchValidation(this.addressInputs, this.required);
    },

    methods: {
      onChange({ input, value }) {
        setInputValue({ input, value });

        patchValidation(this.addressInputs, this.required);
        const address = mapValues(this.inputs, input => input.value);

        if (this.$props.value) {
          address.id = this.$props.value.id;
        }

        this.$emit('change', new AddressModel(address));
      },

      applyNewValue(newValue) {
        const innerInputsIds = Object.values(this.inputs);
        innerInputsIds.forEach(input => {
          if (input === this.inputs.country) {
            return this.updateInnerCountryInput(newValue?.[input.id]?.code2);
          }
          this.updateInnerInput(input, newValue?.[input.id]);
        });

        patchValidation(this.addressInputs, this.required);
      },

      updateInnerInput(input, value) {
        setInputValue({ input, value });
      },

      updateInnerCountryInput(code2) {
        const input = this.inputs.country;
        const selectedOption = input.options.find(o => o.value.code2 === (code2));
        setInputValue({ input, value: selectedOption?.value });
      }
    }
  };
</script>
