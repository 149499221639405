export const getters = {
  isValid,
  availableGroups,
};

function isValid(state) {
  return state.inputs && state.inputs.every(i => i.valid);
}

function availableGroups(state) {
  if (!state.groups) {
    return [];
  }
  return state.groups.filter(group => !state.programInput.groups.some(g => g === group));
}
