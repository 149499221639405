import { Subscription } from '@h4h/classes';
import { deleteItem, numberOrDefault } from '@h4h/utils';

export class TabIndexManager {
  /**
   * Base tab index to use in any Form instances
   * A rather high value was chosen intentionally to prevent conflicts with non-migrated forms
   *
   * @type {Number}
   */
  static BASE_TAB_INDEX = 10000;

  /**
   * Tab index to be assigned to components that should not be selectable by tab navigation
   *
   * @type {Number}
   */
  static INACTIVE_TAB_INDEX = -1;

  #baseTabIndex = TabIndexManager.BASE_TAB_INDEX;

  /**
   * @type {TabIndexSubscriber[]}
   */
  #subscribers = [];

  /**
   * @type {Map<TabIndexSubscriber, Number>}
   */
  #tabIndexBySubscriber = new Map();

  /**
   * @type {Map<String, Set<TabIndexSubscriber>>}
   */
  #subscribersByGroupId = new Map();

  /**
   * @type {TabIndexSubscriber | null}
   */
  #activeSubscriber = null;

  /**
   * @param {Object} [config]
   * @param {Number} [config.baseTabIndex]
   */
  constructor(config) {
    this.#baseTabIndex = numberOrDefault(config?.baseTabIndex, TabIndexManager.BASE_TAB_INDEX);
  }

  /**
   * @param {TabIndexSubscriber} subscriber
   * @returns {Subscription}
   */
  subscribe(subscriber) {
    this.#subscribers.push(subscriber);

    const { groupId } = subscriber;

    if (groupId) {
      if (!this.#subscribersByGroupId.has(groupId)) {
        this.#subscribersByGroupId.set(groupId, new Set());
      }

      this.#subscribersByGroupId.get(groupId).add(subscriber);
    }

    this.#update();

    return new Subscription(() => this.#unsubscribe(subscriber));
  }

  /**
   * Activating a subscriber will result in generating and setting a positive tabIndex to the said subscriber and
   * all subscribers that share the same groupId
   *
   * Should be called when the subscriber's component receives focus
   *
   * `deactivate` method to be called on blur is not implemented purposely to allow forms (groups) to keep tabIndex even
   * when the user clicks away from the input - this allows us to gracefully continue tab navigation after misclicks
   * or interactions with rich components, e.g. dropdowns, typeaheads and so on
   *
   * @param {TabIndexSubscriber} subscriber
   */
  activate(subscriber) {
    this.#activeSubscriber = subscriber;
    this.#update();
  }

  /**
   * @param {TabIndexSubscriber} subscriber
   * @returns {void}
   */
  #unsubscribe(subscriber) {
    if (!this.#subscribers.includes(subscriber)) {
      return;
    }

    deleteItem(this.#subscribers, i => i === subscriber);

    const { groupId } = subscriber;

    if (groupId) {
      const group = this.#subscribersByGroupId.get(groupId);

      if (group.size === 0) {
        this.#subscribersByGroupId.delete(groupId);
      }
      else {
        group.delete(subscriber);
      }
    }

    this.#update();
  }

  /**
   * find differences between last and current states and update respective subscribers
   *
   * @returns {void}
   */
  #update() {
    const newTabIndexBySubscriberMap = this.#buildTabIndexBySubscriberMap();

    // we do not need to update subscribers that are present in current map and absent in new one
    // since this can happen only after a subscriber was removed in `#unsubscribe` method
    Array.from(newTabIndexBySubscriberMap.keys()).forEach(sub => {
      const newTabIndex = newTabIndexBySubscriberMap.get(sub);
      const currentTabIndex = this.#tabIndexBySubscriber.get(sub);

      if (newTabIndex !== currentTabIndex) {
        sub.next(newTabIndex);
      }
    });

    this.#tabIndexBySubscriber = newTabIndexBySubscriberMap;
  }

  /**
   * currently using `#baseTabIndex` for *all* active subscribers in hopes that navigation order will match DOM order.
   * if this doesn't work we will have to implement support of custom tabIndexes for subscribers
   *
   * @returns {Map<TabIndexSubscriber, Number>}
   */
  #buildTabIndexBySubscriberMap() {
    const tabIndexBySubscriber = new Map();

    this.#subscribers.forEach(sub => tabIndexBySubscriber.set(sub, TabIndexManager.INACTIVE_TAB_INDEX));

    if (this.#activeSubscriber?.groupId) {
      const group = this.#subscribersByGroupId.get(this.#activeSubscriber.groupId);
      group.forEach(sub => this.#addToTabIndexMap(tabIndexBySubscriber, sub));
    }
    else if (this.#activeSubscriber) {
      this.#addToTabIndexMap(tabIndexBySubscriber, this.#activeSubscriber);
    }

    return tabIndexBySubscriber;
  }

  /**
   * @param {Map<TabIndexSubscriber, Number>} map
   * @param {TabIndexSubscriber} subscriber
   * @returns {void}
   */
  #addToTabIndexMap(map, subscriber) {
    map.set(subscriber, this.#baseTabIndex + subscriber.tabIndex);
  }
}
