import { mapValues } from 'lodash';

import { toast } from '../../services/toast';
import { profile } from '../../services/api/profile/profile';

import { types } from './types';
import { localize } from '../../services/localize';
import { showRestError } from '../../utils/errors';

export const actions = Object.freeze({
  updateProfile,
  fetchProfile,
  initViewForm,
  initEditForm,
});

async function fetchProfile({ commit }) {
  commit(types.SET_LOADING, true);
  const result = await profile.fetchOne();
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_PROFILE, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchProfile');
  }
}

async function updateProfile({ state, commit, getters }) {
  if (!getters.isValid) {
    return false;
  }

  const updatedProfile = {
    ...state.profile,
    ...mapValues(state.inputsMap, i => i.value)
  };

  commit(types.SET_LOADING, true);
  const result = await profile.updateOne(updatedProfile);
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_PROFILE, result.data);
    toast.success(localize('messages.profileUpdated'));
  }
  else {
    showRestError(result.error, 'messages.cantUpdateProfile');
  }
  return result.success;
}

async function initViewForm({ commit }) {
  commit(types.INIT_FORM, { readOnly: true });
}

async function initEditForm({ commit }) {
  commit(types.INIT_FORM, { readOnly: false });
}

