<template>
  <div
    v-if="$props.pageCount > 1"
    :class="[styles.pagination, 'tablePagination']"
  >
    <Button
      small
      round
      icon="chevron-left"
      :class="[styles.button, styles.buttonArrow]"
      :disabled="!hasPrevPage"
      @click="onPrevClick"
    />

    <template
      v-for="(p, index) in pageButtons"
    >
      <span
        v-if="p === skippedRange"
        :key="index"
        :class="styles.button"
      >
        {{ p }}
      </span>
      <Button
        v-else
        :key="index"
        small
        :label="p"
        :primary="p === page"
        :class="styles.button"
        :disabled="p === page"
        @click="setPage(p)"
      />
    </template>

    <Button
      small
      round
      icon="chevron-right"
      :class="[styles.button, styles.buttonArrow]"
      :disabled="!hasNextPage"
      @click="onNextClick"
    />

    <div :class="styles.inputBlock">
      {{ $localize('table.jumpToPage') }}
      <NumberInput
        ref="input"
        inline
        v-bind="input"
        :class="styles.inputContainer"
        :inputStyle="styles.input"
        @input="onInputChange"
        @keyup.enter.native="onInputEnterPress"
      />

      <Button
        small
        label="table.go"
        :class="styles.button"
        :disabled="!input.valid"
        @click="setPage(input.value)"
      />
    </div>
  </div>
</template>

<script>
  import {
    InputType,
    validateInput,
    setInputValue,
    createInputConfig,
    Number as NumberInput,
  } from '@h4h/inputs';

  import styles from './pagination.scss';
  import { getPageButtons, SKIPPED_RANGE } from './paginationUtils';

  export default {
    name: 'H4hTablePagination',

    components: {
      NumberInput,
    },

    model: {
      prop: 'page',
      event: 'pageChange'
    },

    props: {
      page: {
        type: Number,
        required: true
      },

      pageCount: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        styles,
        maxPageButtons: 7,
        skippedRange: SKIPPED_RANGE,
        input: createInputConfig({
          id: 'paginationInput',
          type: InputType.Integer,
          required: true,
          max: this.$props.pageCount,
          min: 1
        })
      };
    },

    computed: {
      hasPrevPage() {
        return this.$props.page > 1;
      },

      hasNextPage() {
        const { page, pageCount } = this.$props;
        return page < pageCount;
      },

      pageButtons() {
        const { page, pageCount } = this.$props;
        return getPageButtons(page, pageCount, 7);
      }
    },

    watch: {
      '$props.pageCount'(value) {
        this.input.max = value;
        this.input.valid = validateInput(this.input);
      },
      '$props.page'(value) {
        this.input.value = value;
        this.input.valid = validateInput(this.input);
      }
    },

    methods: {
      setPage(page) {
        this.$emit('pageChange', page);
        this.input.value = page;
        this.input.valid = validateInput(this.input);
      },

      onPrevClick() {
        if (this.hasPrevPage) {
          this.setPage(this.$props.page - 1);
        }
      },

      onNextClick() {
        if (this.hasNextPage) {
          this.setPage(this.$props.page + 1);
        }
      },

      onInputChange(value) {
        setInputValue({
          input: this.input,
          value
        });
      },

      onInputEnterPress() {
        const { valid, value } = this.input;

        if (valid) {
          this.setPage(value);
          this.$refs.input.blur();
        }
      }
    }
  };
</script>
