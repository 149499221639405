import { formatName } from '../../utils/formatters/fullNameFormatter';
import { mapObject } from '../../utils/mapUtils';
import { localize } from '../../services/localize';

export class LogisticTaskModel {
  /**
   * LogisticTaskModel config
   *
   * @param {Object}                          config
   * @param {String}                          config.createdOn
   * @param {String}                          config.message
   * @param {String}                          config.messageCode
   * @param {LogisticMonitoringPeriodModel}   config.monitoringPeriod
   * @param {String}                          config.patientUuid
   * @param {LogisticTaskStatus}              config.status
   * @param {String}                          config.tenantDependentUuid
   * @param {String}                          config.tenantId
   */
  constructor(config) {
    this.createdOn = config.createdOn;
    this.message = config.message;
    this.messageCode = config.messageCode;
    this.monitoringPeriod = mapObject(config.monitoringPeriod, LogisticMonitoringPeriodModel);
    this.patientUuid = config.patientUuid;
    this.status = config.status;
    this.tenantDependentUuid = config.tenantDependentUuid;
    this.tenantId = config.tenantId;
  }

  get 'tenantSettings.tenantName'() {
    return this.tenantId;
  }

  get patientName() {
    return this.monitoringPeriod?.enrollment?.patientName || '';
  }

  get value() {
    if (this.messageCode) {
      return localize(this.messageCode);
    }
    return this.message;

  }
}

export class LogisticMonitoringPeriodModel {
  /**
   * LogisticMonitoringPeriodModel config
   *
   * @param {Object}                config
   * @param {LogisticEnrollmentModel}                config.enrollment
   */
  constructor(config) {
    this.enrollment = mapObject(config.enrollment, LogisticEnrollmentModel);
  }
}

export class LogisticEnrollmentModel {
  /**
   * LogisticEnrollmentModel config
   *
   * @param {Object}                config
   * @param {String}                config.patientFirstName
   * @param {String}                config.patientMiddleName
   * @param {String}                config.patientLastName
   * @param {String}                config.programName
   */
  constructor(config) {
    this.patientFirstName = config.patientFirstName;
    this.patientMiddleName = config.patientMiddleName;
    this.patientLastName = config.patientLastName;
    this.programName = config.programName;
  }

  get patientName() {
    return formatName(this.patientFirstName, this.patientMiddleName, this.patientLastName);
  }
}

