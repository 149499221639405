import { Page } from '../../../constants/page';
import { EhRole } from '../../auth/ehRole';

export const CallCenterAdminRoutes = {
  path: 'call-center-admin',
  name: Page.CallCenterAdmin,
  redirect: { name: Page.CallCenterUsers },
  component: () => import('../../../components/views/app/call-center-admin/CallCenterAdminRoot'),
  meta: {
    title: 'callCenter.admin',
    roles: [EhRole.CallCenterAdmin]
  },
  children: [
    {
      path: 'users',
      name: Page.CallCenterUsers,
      component: () => import('../../../components/views/app/call-center-admin/users/CallCenterUsers'),
      redirect: { name: Page.CallCenterUsersList },
      meta: {
        title: 'callCenter.users',
      },
      children: [
        {
          path: 'list',
          name: Page.CallCenterUsersList,
          component: () => import('../../../components/views/app/call-center-admin/users/list/CallCenterUsersList'),
          meta: {
            title: 'callCenter.users'
          }
        },
        {
          path: 'add',
          name: Page.CallCenterAddUser,
          component: () => import('../../../components/views/app/call-center-admin/users/add/CallCenterAddUser'),
          meta: {
            title: 'callCenter.addUser',
          }
        },
        {
          path: 'profile/:userId',
          name: Page.CallCenterUserOverview,
          redirect: { name: Page.CallCenterUserViewProfile },
          props: true,
          component: () => import('../../../components/views/app/call-center-admin/users/profile/CallCenterUserOverview'),
          meta: {
            title: 'callCenter.profile',
          },
          children: [
            {
              path: 'view',
              name: Page.CallCenterUserViewProfile,
              props: true,
              component: () => import('../../../components/views/app/call-center-admin/users/profile/CallCenterUserViewProfile'),
              meta: {
                title: 'callCenter.profile',
              }
            },
            {
              path: 'edit',
              name: Page.CallCenterUserEditProfile,
              props: true,
              component: () => import('../../../components/views/app/call-center-admin/users/profile/CallCenterUserEditProfile'),
              meta: {
                title: 'callCenter.profile',
              }
            },
            {
              path: 'roles',
              name: Page.CallCenterUserRoleManagement,
              props: true,
              component: () => import('../../../components/views/app/call-center-admin/users/profile/CallCenterUserRoleManagement'),
              meta: {
                title: 'callCenter.roleManagement',
              }
            },
          ]
        }
      ]
    },
    {
      path: 'troubleshooting-report',
      name: Page.CallCenterAdminTroubleShootingReport,
      component: () => import('../../../components/troubleshooting/report/TroubleshootingReport'),
      meta: {
        title: 'troubleshooting.troubleshootingReport',
      },
    },
    {
      path: 'language-settings',
      name: Page.CallCenterAdminLanguageSettingEdit,
      component: () => import('../../../components/views/app/call-center-admin/i18n/EditLanguageSettings'),
      meta: {
        title: 'language.language',
      },
    }
  ]
};
