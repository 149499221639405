import { memoizeAsync } from './memoizeAsync';

/**
 * Creates a Proxy that "exposes" separate exports of a chunk as
 * async getters, allowing strict autocompletion of exports
 *
 * Mostly useful for chunks that consist of Vue components
 *
 * **Note:** it is crucial to define @type of the chunk constant
 * in order to make use of strict autocompletion, see example below
 *
 * @param {String} chunkName
 * @param {() => Promise<Module>} chunk
 * @param {() => Boolean} [allowed]
 *
 * @example
 *   /**
 *     * @type {Record<keyof typeof import('./someModule'), () => Promise<Vue.Component>>}
 *     *\/
 *   const SomeModuleChunk = createChunkProxy(
 *     'SomeModuleChunk',
 *     () => import('./someModule'),
 *     appConfig.hasModule
 *   );
 *
 *   // now SomeModuleChunk.SomeModuleExport can be used as a Vue component
 */
export function createChunkProxy(chunkName, chunk, allowed) {
  const loadChunk = createChunkLoader(chunkName, chunk, allowed);

  return new Proxy({}, {
    get(_, componentName) {
      return () => loadExport(
        loadChunk,
        chunkName,
        componentName
      );
    }
  });
}

/**
 * Loads the chunk, then extracts a specific exported module from it
 *
 * @param {() => Promise<Module>} loadChunk
 * @param {String} chunkName
 * @param {String} componentName
 *
 * @returns {Promise<Vue.Component>}
 */
async function loadExport(loadChunk, chunkName, componentName) {
  const module = await loadChunk();
  const component = module[componentName];

  if (!component) {
    const errorMessage = `there is no ${ componentName } component in ${ chunkName }`;
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
  else {
    console.debug(`using ${ componentName } from ${ chunkName }`);
  }

  return module[componentName];
}

/**
 * Creates a memoized async function that loads the chunk
 *
 * @param {String} chunkName
 * @param {() => Promise<Module>} chunk
 * @param {() => Boolean} [allowed]
 *
 * @returns {() => Promise<Module>}
 */
function createChunkLoader(chunkName, chunk, allowed) {
  return memoizeAsync(async() => {
    try {
      console.log(`loading ${ chunkName }`);

      if (allowed && !allowed()) {
        console.log('Chunk not accessable');
        // return empty module stub
        return {};
      }

      // we do not need to bother about simultaneous fetches
      // or caching - Webpack handles both of those
      const module = await chunk();

      console.log(`loaded ${ chunkName }`);

      return module;
    }
    catch (e) {
      console.error(`failed to load ${ chunkName }`, e);
      throw e;
    }
  });
}
