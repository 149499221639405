import { types } from './types';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.INIT_FORM](state, { patientUuid, popupId }) {
    state.popupId = popupId;
    state.patientUuid = patientUuid;
  },
};

