export const EhUserFieldsLengthConstraints = Object.freeze({
  firstName: { max: 100 },
  lastName: { max: 100 },
  initials: { max: 30 },
  middleName: { max: 50 },
  partnerName: { max: 100 },
  partnerMiddleName: { max: 50 },
  email: { max: 127 },
  bigCode: { max: 10 },
  title: { max: 50 },
  alarmPhoneNumber: { max: 50 },
  externalPatientId: { max: 20 },
  patientNumber: { max: 20 },
  ssn: { max: 20 },
});
