import { types } from './types';
import { patients } from '../../../services/api/patients/patients';
import { showRestError } from '../../../utils/errors';
import { mapValues } from 'lodash';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import { setInputValueAction } from '@h4h/inputs';
import { PopupType } from '@h4h/popups/src/constants/popupType';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { patientArchiving } from '../../../services/api/patients/archiving/archiving';
import { Page } from '../../../constants/page';
import { router } from '../../../services/router';
import { PatientModel } from '../../../model/patientModel';
import { mapMpAddressToEhAddress } from '../../../model/addressModel';
import { convertPhonesToStrings } from '../../../utils/phone/phoneComponentUtils';
import { checkNotSupportedPhoneCode } from '../../../utils/phone/notSupportedPhoneCodeWarning';
import { fetchCountries } from '../../countries/utils';

export const actions = Object.freeze({
  initEditForm,
  updatePatientDetails,
  showPatientArchivingPopup,
  showPatientFinalArchivingWarningPopup,
  hidePatientArchivingPopup,
  hideFinalArchivingWarningPopup,
  archivePatient,
  setInputValue,
  clearInputs,
  updatePatientAddress,
});

async function setInputValue({ commit, dispatch }, { input, value }) {
  const oldValue = input.value;
  await setInputValueAction({ commit, dispatch }, { input, value });
  checkNotSupportedPhoneCode(input, oldValue, value);
}

async function clearInputs({ commit }) {
  commit(types.CLEAR_INPUTS);
}

async function showPatientArchivingPopup({ commit }, patientUuid) {
  const { id } = await popups.show({
    name: PopupName.PatientArchivingPopup,
    type: PopupType.Modal
  });
  commit(types.INIT_PATIENT_ARCHIVING_POPUP, {
    popupId: id,
    patientUuid,
  });
}

async function showPatientFinalArchivingWarningPopup({ commit }) {
  const { id } = await popups.show({
    name: PopupName.PatientFinalArchivingWarningPopup,
    type: PopupType.Modal
  });
  commit(types.SET_PATIENT_FINAL_ARCHIVING_WARNING_POPUP, {
    popupId: id,
  });
}

function hidePatientArchivingPopup(_, patientArchivingPopupId) {
  popups.hide({ id: patientArchivingPopupId });
}

function hideFinalArchivingWarningPopup(_, { patientFinalArchivingWarningPopupId, patientArchivingPopupId }) {
  popups.hide({ id: patientFinalArchivingWarningPopupId });
  hidePatientArchivingPopup(_, patientArchivingPopupId);
}

async function archivePatient(_, { currentPatientUuid, patientFinalArchivingWarningPopupId, patientArchivingPopupId }) {
  const result = await patientArchiving(currentPatientUuid);

  if (result.success) {
    toast.success(localize('messages.patientWasArchived'));
    hideFinalArchivingWarningPopup(_, { patientFinalArchivingWarningPopupId, patientArchivingPopupId });
    router.push({ name: Page.PractitionerPatientsList });
  }
  else {
    showRestError(result.error, 'messages.cantArchivePatient');
  }
}

async function updatePatientDetails({ state, commit, getters }, patientUuid) {
  commit(types.SET_PRISTINE, false);
  if (!getters.arePatientDetailsInputsValid) {
    return false;
  }

  const patient = mapValues(state.inputsMap, i => i.value);
  convertPhonesToStrings(patient, ['phone']);

  commit(types.SET_LOADING, true);
  const result = await patients.update(patientUuid, patient);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.patientDetailsUpdated'));
  }
  else {
    showRestError(result.error, 'messages.cantUpdatePatientDetails');
  }
  return result.success;
}

/**
 *
 * @param _
 * @param {UpdatePatientAddressModel} model
 */
async function updatePatientAddress(_, model) {
  const patient = new PatientModel({
    ...model.patient,
    address: mapMpAddressToEhAddress(model.newAddress),
  });

  const { id } = await popups.show({
    name: PopupName.LoadingPopup,
    type: PopupType.Modal,
    props: {
      label: 'common.pleaseWait',
      hideCloseButton: true
    }
  });
  const result = await patients.update(patient.uuid, patient);
  popups.hide({ id });

  if (result.success) {
    toast.success(localize('messages.patientDetailsUpdated'));
  }
  else {
    showRestError(result.error, 'messages.cantUpdatePatientDetails');
  }
  return {
    ...result,
    patient: result.data,
  };
}

async function initEditForm({ commit, dispatch }, patient) {
  const countries = await fetchCountries(dispatch);
  commit(types.INIT_FORM, { patient, countries });
}
