import { isEqual, isMatchWith } from 'lodash';

import { SET_INPUT_VALUE, UPDATE_INPUT } from './mutations';

/**
 * @typedef {Function} UpdateInputAction
 *
 * @param {{ dispatch: Function, commit: Function }} store
 * @param {{ input: InputConfig, patch: Partial<InputConfig> }} params
 */

/**
 * creates an action that updates input and dispatches `updateLinkedInputs` action
 *
 * @param {String} [updateInputMutationType]
 * @returns {UpdateInputAction}
 */
function createUpdateInputAction(updateInputMutationType = UPDATE_INPUT) {
  return async function updateInput({ dispatch, commit }, { input, patch }) {
    commit(updateInputMutationType, { input, patch });
    await dispatch('updateLinkedInputs', input);
  };
}

/**
 * @typedef {Function} SetInputValueAction
 *
 * @param {{ dispatch: Function, commit: Function }} store
 * @param {{ input: InputConfig, value: Any }} params
 */

/**
 * creates an action that updates input value if needed and dispatches `updateLinkedInputs` action
 *
 * @param {String} [setInputValueMutationType]
 * @returns {SetInputValueAction}
 */
export function createSetInputValueAction(setInputValueMutationType = SET_INPUT_VALUE) {
  return async function setInputValue({ dispatch, commit }, { input, value }) {
    if (value !== input.value) {
      commit(setInputValueMutationType, { input, value });
    }
    await dispatch('updateLinkedInputs', input);
  };
}

/**
 * @typedef {Function}  UpdateLinkedInputsAction
 *
 * @param {{ state: Object, commit: Function }} store
 * @param {InputConfig} input
 *
 * @example
 * updateLinkedInputs({ state, commit }, input);
 */

/**
 * creates and action that updates linked inputs
 *
 * @param {String} [updateInputMutationType]
 * @returns {UpdateLinkedInputsAction}
 */
export function createUpdateLinkedInputsAction(updateInputMutationType = UPDATE_INPUT) {
  return async function updateLinkedInputs({ state, commit }, input) {
    if (!input.linkedInputs?.length) {
      return;
    }

    const linkedInputs = input.linkedInputs.slice();
    let linkedInput;

    /* eslint-disable no-cond-assign */
    while (linkedInput = linkedInputs.shift()) {
      const patch = await linkedInput.getStatePatch({ state }, linkedInput);

      if (!patch || isMatchWith(linkedInput, patch, isEqual)) {
        continue;
      }

      commit(updateInputMutationType, { input: linkedInput, patch });
      await updateLinkedInputs({ state, commit }, linkedInput);
    }
  };
}

/**
 * @type {UpdateInputAction}
 */
export const updateInputAction = createUpdateInputAction();

/**
 * @type {SetInputValueAction}
 */
export const setInputValueAction = createSetInputValueAction();

/**
 * @type {UpdateLinkedInputsAction}
 */
export const updateLinkedInputsAction = createUpdateLinkedInputsAction();

/**
 * A shortcut action to apply 'loading' prop patch
 *
 * @param {{ commit: Function }} store
 * @param {{ input: InputConfig, loading: Boolean }} params
 */
export function setInputLoadingAction({ commit }, { input, loading }) {
  commit(UPDATE_INPUT, { input, patch: { loading } });
}
