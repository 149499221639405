import { localizeIfExistsOrDefault } from '../services/localize';

export class CountryModel {
  /**
   * Country
   *
   * @param {Object}    config
   * @param {String}    config.code2
   * @param {String}    config.callCode
   * @param {String}    config.countryName
   */
  constructor({ code2, callCode, countryName }) {
    this.code2 = code2;
    this.callCode = callCode;
    this.countryName = countryName;
  }

  get name() {
    if (this.countryName) {
      return this.countryName;
    }
    return localizeIfExistsOrDefault('country.' + this.code2, this.code2);
  }

}

export class EhCountryModel {
  /**
   * Country
   *
   * @param {Object}    config
   * @param {String}    config.code
   * @param {String}    config.countryName
   * @param {String}    config.phoneValidationPattern
   */
  constructor({ code, countryName, phoneValidationPattern, }) {
    this.code = code;
    this.countryName = countryName;
    this.phoneValidationPattern = phoneValidationPattern;

  }

}

