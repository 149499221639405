import { LocalTimeModel } from './localTimeModel';

export class ObservationValueModel {
  /**
   * ObservationValueModel config
   *
   * @param {Object}            config
   * @param {String}            config.type
   * @param {String}            config.unitCode
   *
   * @param {Boolean}           config.valueBoolean
   * @param {String}            config.valueString
   * @param {Number}            config.valueInt
   * @param {Number}            config.valueNumeric
   *
   * @param {String}            config.valueDateTime
   * @param {String}            config.valueLocalDate
   * @param {String}            config.valueLocalDateTime
   * @param {LocalTimeModel}    config.valueLocalTime
   * @param {String}            config.valueReference
   */
  constructor(config) {
    this.type = config.type;
    this.unitCode = config.unitCode;

    this.valueBoolean = config.valueBoolean;
    this.valueString = config.valueString;
    this.valueInt = config.valueInt;
    this.valueNumeric = config.valueNumeric;

    this.valueDateTime = config.valueDateTime;
    this.valueLocalDate = config.valueLocalDate;
    this.valueLocalDateTime = config.valueLocalDateTime;
    this.valueLocalTime = config.valueLocalTime ? new LocalTimeModel(config.valueLocalTime) : null;
    this.valueReference = config.valueReference;
  }

  get value() {
    return [
      this.valueBoolean,
      this.valueString,
      this.valueInt,
      this.valueNumeric,

      this.valueDateTime,
      this.valueLocalDate,
      this.valueLocalDateTime,
      this.valueLocalTime,
      this.valueReference
    ].find(value => value !== null);
  }
}
