import { state } from './state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { chooseTemplatePopup } from './choose-template-popup';

export const settings = {
  namespaced: true,

  state,
  actions,
  getters,
  mutations,
  modules: {
    chooseTemplatePopup
  }
};
