import { i18n } from '../services/i18n';
import { localize } from '../services/localize';
import { get } from 'lodash';
import { toast } from '../services/toast';

function tryGetHttpErrorDescription(httpErrorCode) {
  let key = 'messages.errors.' + httpErrorCode;
  if (i18n.te(key)) {
    return i18n.t(key);
  }
  return httpErrorCode;
}

/**
 * A shortcut for showing error. Tries automatically extract http error if present
 * @param {Error} error - error occurred
 * @param {String} messageKey - message key to show
 * @param {Object} values - message interpolation values
 */

export function showRestError(error, messageKey, values) {
  let message = localize(messageKey, values);
  let errorCode = get(error, 'response.data.message');
  if (errorCode) {
    // there is low possibility that we receive not a string : null or object
    let separator = ' ';
    message = message || '';
    if (typeof message == 'string') {
      message = message.trim();
      if (message.length && !message.endsWith('.')) {
        separator = '. ';
      }
    }
    // anyway code info
    let httpErrorDescription = tryGetHttpErrorDescription(errorCode);
    if (httpErrorDescription) {
      toast.error(httpErrorDescription);
      return;
    }
    message += separator + httpErrorDescription;
  }
  toast.error(message, error);
}
