import { types } from './types';
import { showRestError } from '../../../../utils/errors';
import { createEnrollments, fetchEnrollments } from '../../../../services/api/enrollments/enrollments';
import { ProgramsActions } from '../../../../constants/programs';
import { PopupName } from '../../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { popups } from '../../../../services/popups';
import { CreateEnrollmentModel } from '../../../../model/enrollmentModel';
import { createSetInputValueAction } from '@h4h/inputs';
import { observationTemplates } from '../../../../services/api/observations/observationTemplates';
import { questionnaireTemplates } from '../../../../services/api/questionnaire/questionnaireTemplates';
import { Page } from '../../../../constants/page';
import { createResetThresholdInputAction } from '../../../../components/observation/thresholds/dialog/components/ThresholdInputsGroup';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '@h4h/date';
import { localize } from '../../../../services/localize';
import { toast } from '../../../../services/toast';
import { createCronModelAction } from '../../../../components/cronEditor/store/actions';
import { createInputStateChangeAction } from '../../../../components/observation/thresholds/dialog/components/InputStateChange';
import { orderBy } from 'lodash';

const FetchPatientEnrollments = 'fetchPatientEnrollments';
export const actions = Object.freeze({
  init,
  [FetchPatientEnrollments]: fetchPatientEnrollments,
  showDialog,
  hideDialog,
  enrollPatient,
  setProgram: createSetInputValueAction(types.SET_PROGRAM),
  setMonitoringPeriod: createSetInputValueAction(types.SET_MONITORING_PERIOD),
  setEnrollmentsToStop,
  resetThresholds,
  resetThresholdInput: createResetThresholdInputAction(),
  deleteThresholdItem,
  resetThresholdItem,
  setPatient,
  setCronModel: createCronModelAction(types.SET_CRON_MODEL),
  inputStateChange: createInputStateChangeAction(types.INPUT_STATE_CHANGED),
});

function resetThresholdItem({ commit, dispatch }, group) {
  if (group.deleted) {
    commit(types.SET_ITEM_DELETE_STATE, { group, value: false });
  }
  else {
    createResetThresholdInputAction()({ commit, dispatch }, group.input);
  }
}

function deleteThresholdItem({ commit }, group) {
  commit(types.SET_ITEM_DELETE_STATE, { group, value: true });
}

async function setPatient({ commit }, patient) {
  commit(types.SET_PATIENT, patient);
}

async function init({ commit, dispatch }, patient) {
  commit(types.SET_PATIENT, patient);
  commit(types.SET_LOADING, true);

  const programs = await dispatch(ProgramsActions.FetchPrograms, {}, { root: true });
  commit(types.SET_PROGRAMS, programs);

  commit(types.SET_LOADING, false);
}

async function fetchPatientEnrollments({ commit, state }, patientUuid) {
  commit(types.SET_ENROLLMENTS, []);
  commit(types.SET_ENROLLMENTS_TO_STOP, []);
  const result = await fetchEnrollments(patientUuid);
  if (result.error) {
    showRestError(result.error, 'messages.cantFetchEnrollments');
  }
  const data = orderBy(result.data,['monitoringPeriod.startDate'],['desc']);
  commit(types.SET_ENROLLMENTS, data);
  await fetchObservationTemplatesForEnrollments({ commit, state });
  await fetchQuestionnaireTemplatesForEnrollments({ commit, state });
  await setNavLinksForPatient(commit, state);

  return result.success;
}

async function fetchObservationTemplatesForEnrollments({ commit, state }) {
  let enrollmentObsTemplatesMap = new Map();
  let enrollments = state.enrollments;
  let observationTemplatesToRequest = new Set();
  for (let enrollment of enrollments) {
    if (enrollment.monitoringPeriod) {
      let period = enrollment.program.availablePeriods.find(period => period.uuid === enrollment.monitoringPeriod.templateLink.uuid);
      if (period && period.observations) {
        let obsTemplateUuids = period.observations.map(o => o.observationTemplateId);
        obsTemplateUuids.forEach(obsTemplateUuid => {
          observationTemplatesToRequest.add(obsTemplateUuid);
        });
        enrollmentObsTemplatesMap.set(enrollment.uuid, obsTemplateUuids);
      }
    }
  }
  commit(types.SET_ENROLLMENT_OBS_TEMPLATES_MAP, enrollmentObsTemplatesMap);

  await fetchObservationTemplates({ commit }, observationTemplatesToRequest);
}

async function fetchObservationTemplates({ commit }, observationTemplateIds) {
  commit(types.SET_LOADING, true);
  const result = await observationTemplates.fetch({
    params: {
      id: observationTemplateIds
    }
  });
  commit(types.SET_LOADING, false);
  if (result.success) {
    commit(types.SET_OBSERVATION_TEMPLATES, result.data);
    return result.data;
  }

  showRestError(result.error, 'messages.cantFetchObservationTemplates');
  return [];
}

async function fetchQuestionnaireTemplatesForEnrollments({ commit, state }) {
  let enrollmentQuestionnaireTemplatesMap = new Map();
  let enrollments = state.enrollments;
  let questionnaireTemplatesToRequest = new Set();
  for (let enrollment of enrollments) {
    if (enrollment.monitoringPeriod) {
      let period = enrollment.program.availablePeriods.find(period => period.uuid === enrollment.monitoringPeriod.templateLink.uuid);
      if (period && period.questionnaires) {
        let questionnaireTemplateUuids = period.questionnaires.map(o => o.questionnaireTemplateId);
        questionnaireTemplateUuids.forEach(questionnaireTemplateUuid => {
          questionnaireTemplatesToRequest.add(questionnaireTemplateUuid);
        });
        enrollmentQuestionnaireTemplatesMap.set(enrollment.uuid, questionnaireTemplateUuids);
      }
    }
  }
  commit(types.SET_ENROLLMENT_QUESTIONNAIRE_TEMPLATES_MAP, enrollmentQuestionnaireTemplatesMap);

  await fetchQuestionnaireTemplates({ commit }, questionnaireTemplatesToRequest);
}

async function fetchQuestionnaireTemplates({ commit }, questionnaireTemplateIds) {
  commit(types.SET_LOADING, true);
  const result = await questionnaireTemplates.fetch({
    params: {
      id: questionnaireTemplateIds
    }
  });
  commit(types.SET_LOADING, false);
  if (result.success) {
    commit(types.SET_QUESTIONNAIRE_TEMPLATES, result.data);
    return result.data;
  }

  showRestError(result.error, 'messages.cantFetchQuestionnaireTemplates');
  return [];
}

async function hideDialog({ state }) {
  popups.hide({ id: state.popupId });
}
async function showDialog({ commit, getters }, { terminologyTypes, logisticsEnabled }) {
  const { id } = await popups.show({
    name: PopupName.AddNewEnrollment,
    type: PopupType.Modal
  });
  commit(types.INIT_POPUP, {
    popupId: id,
    programs: getters.availablePrograms,
    terminologyTypes,
    logisticsEnabled,
  });
}

async function enrollPatient({ commit, state, dispatch, getters }) {
  commit(types.SET_PRISTINE, { inputs: getters.inputsToValidate, value: false });
  if (!getters.valid) {
    return;
  }
  commit(types.SET_LOADING, true);
  let observationThresholds = [];
  if (getters.thresholdsVisible) {
    observationThresholds = state.observationThresholdInputsGroups
      .map(inputsGroup => inputsGroup.getPersonalizedThresholdModel())
      .filter(x => !!x);
  }

  const programInputOverriddenCrons = state.newEnrollmentSchedules
    .filter(s => s.scheduleType.value === types.ADJUSTED_SCHEDULE)
    .map(s => ({
      overriddenCron: s.cronSettings.cron,
      programInputUuid: s.programInput.uuid,
    }));

  const startDate = moment(state.newEnrollmentDate.value).format(ISO_DATE_FORMAT);
  const result = await createEnrollments.create(new CreateEnrollmentModel({
    patientUuid: state.patient.uuid,
    programUuid: state.newEnrollmentProgram.value,
    monitoringPeriodUuid: state.newEnrollmentPeriod.value,
    startDate,
    observationThresholds,
    programInputOverriddenCrons,
    sendDeviceToPatient: state.newEnrollmentSendDevicesToPatients?.value || false,
  }));
  commit(types.SET_LOADING, false);
  if (result.success) {
    popups.hide({ id: state.popupId });
    dispatch(FetchPatientEnrollments, state.patient.uuid);
    toast.success(localize('messages.enrollmentSaved'));
  }
  else {
    showRestError(result.error, 'messages.cantCreateEnrollment');
  }
}

async function setNavLinksForPatient(commit, state) {
  commit(types.SET_NAV_LINKS, []);
  let links = [
    {
      key: 'patient.patientDetails',
      name: 'patient.patientDetails',
      page: Page.PractitionerPatientDetails
    },
    {
      key: 'patient.patientFile',
      name: 'patient.patientFile',
      page: Page.SimpleAnamnesis
    },
    {
      key: 'anamnesis.anamnesis',
      name: 'anamnesis.anamnesis',
      page: Page.PractitionerPatientAnamnesis
    },
  ];

  let enrollmentLinks = await produceEnrollmentLinks(state);

  commit(types.SET_NAV_LINKS, [...links, ...enrollmentLinks]);
}

async function produceEnrollmentLinks(state) {

  let links = [];

  function addLink(newLink) {
    links = [...links, newLink];
  }

  addLink({
    name: 'enrollments.enrollments',
    page: Page.PractitionerPatientEnrollments
  });

  const activeEnrollments = state.enrollments.filter(e => e.active);
  for (let enrollment of activeEnrollments) {
    addLink({
      key: enrollment.program.name,
      text: enrollment.program.name,
      page: Page.PractitionerPatientEnrollmentsView,
      params: { enrollmentId: enrollment.uuid },
      expandable: true,
      parentKey: 'enrollments.enrollments',
      suffix: enrollment.active ? null : 'x-circle',
    });

    let observationTemplateUuids = state.enrollmentObsTemplatesMap.get(enrollment.uuid);
    state.observationTemplates
      .filter(ot => observationTemplateUuids.includes(ot.uuid))
      .forEach(ot => {
        addLink({
          key: enrollment.program.name + '-observation-' + ot.name,
          text: ot.name,
          page: Page.PractitionerPatientEnrollmentsViewObservation,
          params: { observationTemplateUuid: ot.uuid, enrollmentId: enrollment.uuid, },
          parentKey: enrollment.program.name + enrollment.uuid,
          icon: 'bar-chart-2',
        });
      });
    let questionnaireTemplateUuids = state.enrollmentQuestionnaireTemplatesMap.get(enrollment.uuid);
    state.questionnaireTemplates
      .filter(qt => questionnaireTemplateUuids.includes(qt.uuid))
      .forEach(qt => {
        addLink({
          key: enrollment.program.name + '-questionnaire-' + qt.title,
          text: qt.title,
          page: Page.PractitionerPatientEnrollmentsViewQuestionnaire,
          params: { questionnaireTemplateUuid: qt.uuid, enrollmentId: enrollment.uuid, },
          parentKey: enrollment.program.name + enrollment.uuid,
          icon: 'help-circle',
        });
      });
  }

  return links;
}

async function setEnrollmentsToStop({ commit }, enrollments) {
  commit(types.SET_ENROLLMENTS_TO_STOP, enrollments);
}

function resetThresholds({ commit }, observationThresholdInputsGroup) {
  commit(types.RESET_THRESHOLDS, observationThresholdInputsGroup);
}

