import { mapValues, without } from 'lodash';
import { types } from './types';
import { getInputsMap } from '@h4h/inputs';
import { physicians } from '../../../../services/api/users/physicians';
import { showRestError } from '../../../../utils/errors';
import { toast } from '../../../../services/toast';
import { localize } from '../../../../services/localize';
import { updatePatientResponsiblePhysician } from '../../../../services/api/patients/patients';
import { PatientUpdateRequestModel } from '../../../../model/patientModel';
import { getHeadersXTenantId } from '../../../../utils/getHeaders';
import { callCenterSupportUsers } from '../../../../services/api/users/callCenterUsers';
import { NameOrdering } from '../../../../constants/nameOrdering';
import { getCurrentCallCenterSettings, getCurrentOrganizationSettings } from '../../../organization-setting/utils';
import { getCurrentCallCenterUser } from '../../../callCenter/users/current/utils';

export const actions = Object.freeze({
  save,
  init,
  getPhysiciansList,
  fetchCcSupportUsers,
});

async function save({ state, commit, getters }) {
  commit(types.SET_LOADING, false);
  if (!getters.isValid) {
    return false;
  }

  const model = mapValues(getInputsMap(state.inputs), i => i.value);
  const patientUpdateRequest = new PatientUpdateRequestModel(model);

  commit(types.SET_LOADING, true);
  const result = await updatePatientResponsiblePhysician(state.patientId, patientUpdateRequest);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.patientUpdated'));
  }
  else {
    showRestError(result.error, 'messages.cantUpdatePatient');
  }
  return result.success;
}

async function fetchCcSupportUsers(tenant, dispatch) {
  let params = {};
  if (tenant) {
    // call-center mode
    params = getHeadersXTenantId(tenant);
    const currentCallCenterUser = await getCurrentCallCenterUser(dispatch);
    if (!currentCallCenterUser.success) {
      return [];
    }
    params.params = { country: currentCallCenterUser.data.visibleCountry };
  }
  else {
    // tenant mode
    const ccSettings = await getCurrentCallCenterSettings(dispatch);
    if (!ccSettings || !ccSettings?.callCenterEnabled) {
      return [];
    }
    const orgSettings = await getCurrentOrganizationSettings(dispatch);
    if (!orgSettings) {
      return [];
    }
    params.params = { country: orgSettings.tenantCountry };
  }
  const resultCc = await callCenterSupportUsers.fetch(params);
  if (resultCc.success) {
    return resultCc.data;
  }
  else {
    showRestError(resultCc.error, 'messages.cantFetchCallCenterSupportUsers');
    return [];
  }
}

async function init({ commit, dispatch }, {
  patientId,
  responsiblePhysicianId,
  tenant,
  label,
  isFetchCallCenterSupportUsers
}) {
  commit(types.INIT_FORM, patientId);
  commit(types.SET_PHYSICIANS_LOADING, true);
  let result;
  let physiciansList = [];

  let ccSupportUsers = [];
  if (isFetchCallCenterSupportUsers) {
    ccSupportUsers = await fetchCcSupportUsers(tenant, dispatch);
  }

  if (tenant) {
    result = await physicians.fetch(getHeadersXTenantId(tenant));
  }
  else {
    result = await physicians.fetch();
  }
  commit(types.SET_PHYSICIANS_LOADING, false);
  if (result.success) {
    physiciansList = result.data;
  }
  else {
    showRestError(result.error, 'messages.cantFetchResponsiblePhysicians');
  }

  commit(types.SET_DATA, {
    responsiblePhysicianId,
    physicians: physiciansList,
    sortedPhysiciansForSelect: getPhysiciansList(responsiblePhysicianId, physiciansList, ccSupportUsers),
    label,
  });
}

const physicianCustomNameFormat = NameOrdering.LastNameFirstNameMiddleName;

function getPhysiciansList(responsiblePhysicianId, physicians, ccSupportUsers) {
  if (!physicians.length) {
    return physicians;
  }
  let formatedPhysicians = physicians.map(physician => {
    let label;
    if (responsiblePhysicianId === physician.uuid) {
      label = localize('tasks.currentResponsiblePhysician', [physician.formatName(physicianCustomNameFormat)]);
    }
    else {
      label = physician.formatName(physicianCustomNameFormat);
    }
    return {
      value: physician.uuid,
      physician: physician,
      ccSupportUser: false,
      label
    };
  });

  let formattedCcSupportUsers = [];
  if (ccSupportUsers) {
    formattedCcSupportUsers = ccSupportUsers.map(ccSupportUser => ({
      value: ccSupportUser.uuid,
      physician: ccSupportUser,
      ccSupportUser: true,
      label: localize('tasks.ccSupportUser', [ccSupportUser.formatName(physicianCustomNameFormat)]),
    }));
  }

  let sortedPhysiciansAndCcSupportUsers = formatedPhysicians.concat(formattedCcSupportUsers).sort((x, y) => x.label.localeCompare(y.label));

  let currentRespPhys = sortedPhysiciansAndCcSupportUsers.find(option => option.value === responsiblePhysicianId);
  let withoutCurrentRespPhys = without(sortedPhysiciansAndCcSupportUsers, currentRespPhys);
  if (currentRespPhys) {
    return [currentRespPhys, ...withoutCurrentRespPhys];
  }
  return withoutCurrentRespPhys;
}
