import { QuestionnaireWrapper } from '../../components/questionnaire/wrapper/questionnaireWrapper';
import { QuestionnaireModel } from '../../model/anamnesis/questionnaireModel';
import { mapValues } from 'lodash';
import { getInputsMap } from '@h4h/inputs';

export const getters = {
  valid,
  updatedQuestionnaire,
};

function valid(state) {
  return state.inputs.every(i => i.valid) && state.secondaryInputs.every(i => i.valid);
}

function updatedQuestionnaire(state) {
  const questionnaire = new QuestionnaireModel({
    ...state.questionnaire,
    ...mapValues(getInputsMap(state.secondaryInputs), i => i.value),
  });
  questionnaire.authored = null;
  new QuestionnaireWrapper(state.questionnaireTemplate, questionnaire).updateValuesFromInputs(state.inputs);
  return questionnaire;
}

