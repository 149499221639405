import { DeviceVendor } from '../../../constants/deviceVendor';

export const getters = {
  isValid,
  canAddSupportedVendor,
};

function isValid(state) {
  return state.inputs && state.inputs.every(i => i.valid) &&
    state.availablePeriods.length &&
    !state.availablePeriods.some(period => !period.isValid()) &&
    state.primaryPeriod;
}

function canAddSupportedVendor(state) {
  return state.fullEdit && state.supportedVendors.length < Object.keys(DeviceVendor).length;
}
