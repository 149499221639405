import { ProgramInputModel } from './programInputModel';

export class EnrollmentProgramInputModel {
  /**
   * EnrollmentProgramInputModel config
   *
   * @param {Object}            config
   * @param {String}            config.overriddenCron
   * @param {String}            config.overriddenEnd
   * @param {ProgramInputModel} config.templateLink
   * @param {String}            config.uuid
   */
  constructor(config) {
    config = config || {};

    this.overriddenCron = config.overriddenCron;
    this.overriddenEnd = config.overriddenEnd;
    this.templateLink = config.templateLink ? new ProgramInputModel(config.templateLink) : null;
    this.uuid = config.uuid;
  }
}
