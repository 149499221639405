import { Page } from '../../../../../../constants/page';

export const simpleAnamnesisRoutes = {
  path: 'simple-anamnesis',
  name: Page.SimpleAnamnesis,
  component: () => import('../../../../../../components/views/app/practitioner/patients/view/simpleAnamnesis/SimpleAnamnesis'),
  meta: {
    title: 'patient.patientFile',
  },
};
