import { PopupName } from '../../../../../../../constants/popupName';
import { types } from './types';
import { showRestError } from '../../../../../../../utils/errors';
import {
  groupedPatientObservations,
  observations,
  optimizedGroupedPatientObservations
} from '../../../../../../../services/api/observations/observations';
import moment from 'moment';
import { ISO_FULL_DATE_FORMAT } from '../../../../../../../constants/dateConstants';
import { observationDateField } from '../../../../../../../model/observation/observationModel';
import { ObservationCommentsActions } from '../../../../../../../constants/observationComments';
import { popups, PopupType } from '@h4h/popups';
import { loadDependedTasks } from '../../observationTaskHelper';
import { toast } from '../../../../../../../services/toast';
import { localize } from '../../../../../../../services/localize';
import { uniqBy } from 'lodash';
import { observationsStorePath } from '../constants';
import { mapDataTablePageConfigToSpringPageConfig } from '../../../../../../../model/page';
import { OptimizedPageRequestParams } from '../../../../../../../model/observation/optimizedObservationsPage';

const reloadTasksAction = 'reloadTasks';
export const actions = Object.freeze({
  cleanStore,
  init,
  reload,
  reloadTable,
  [reloadTasksAction]: reloadTasks,
  setIrrelevantState,
  tableOptionsUpdated,
  setSelectedObservationInTable,
  selectionUpdate,
});

async function cleanStore({ commit }) {
  commit(types.CLEAN, null);
}

async function init({ commit, dispatch, state }, payload) {
  commit(types.INIT_PAGE, payload);
  await dispatch('setSelectedObservationInTable', state.selectedObservationUuid);
}

async function setSelectedObservationInTable({ commit, state, dispatch }, observationUuid) {
  if (observationUuid) {
    const observationAlreadyPresentOnPage = (state.groupedObservationsPage?.content || [])
      .map(gro => gro.observation)
      .some(observation => observation.uuid === observationUuid);
    if (observationAlreadyPresentOnPage) {
      return;
    }
    commit(types.SET_OBSERVATIONS_LOADING, true);

    const { start, finish } = state.enrollment.getTotalDateRange();
    const params = new OptimizedPageRequestParams({
      from: start,
      to: finish,
      observationUuid,
      type: state.observationTemplate.terminologyType,
    });
    const result = await optimizedGroupedPatientObservations(state.patient.uuid).fetchOne({ params });
    commit(types.SET_OBSERVATIONS_LOADING, false);
    if (result.success) {
      const currentPage = result.data.currentPage;
      commit(types.SET_GROUPED_OBSERVATIONS_PAGE, currentPage);
      commit(types.SET_PAGE_INDEX, currentPage.number + 1);
      await Promise.all([
        dispatch(ObservationCommentsActions.ReloadComments, (currentPage?.content || []).map(gro => gro.observation), { root: true }),
        dispatch(reloadTasksAction),
      ]);
      return;
    }
    else {
      commit(types.SET_GROUPED_OBSERVATIONS_PAGE, null);
      showRestError(result.error, 'messages.cantFetchObservation');
    }
  }
  await dispatch('reload');
}

async function reloadTable({ dispatch }, payload) {
  await dispatch('reload', payload);
}

async function reload({ commit, state, dispatch }, dataTableConfig) {
  // prevent reload from table optionsChange method
  if (dataTableConfig?.page && dataTableConfig?.page === state.serverSideOptions?.page) {
    return;
  }
  commit(types.SET_PAGINATION_CONFIG, dataTableConfig);

  commit(types.SET_OBSERVATION_TASK_MAP, new Map());
  commit(types.SET_SELECTED_OBSERVATIONS, []);

  commit(types.SET_GROUPED_OBSERVATIONS_PAGE, null);

  const patientUuid = state.patient.uuid;
  const terminologyType = state.observationTemplate.terminologyType;
  const params = {
    type: terminologyType,
    ...mapDataTablePageConfigToSpringPageConfig(state.serverSideOptions),
    sort: observationDateField + ',desc',
  };

  let period = state.period.value;
  if (period) {
    if (!Array.isArray(period)) { // in case only one date is selected
      period = [period, period];
    }
    params.from = moment(period[0]).startOf('day').format(ISO_FULL_DATE_FORMAT);
    params.to = moment(period[1]).endOf('day').format(ISO_FULL_DATE_FORMAT);
  }
  const conclusionNames = state.conclusionNames?.value;
  if (conclusionNames?.size) {
    params.conclusionNames = Array.from(conclusionNames);
  }
  const conclusionBy = state.conclusionBy?.value;
  if (state.conclusionBy?.valid && conclusionBy?.length) {
    params.conclusionBy = conclusionBy;
  }

  commit(types.SET_OBSERVATIONS_LOADING, true);
  const result = await groupedPatientObservations(patientUuid).fetchOne({ params });

  if (result.success) {
    commit(types.SET_GROUPED_OBSERVATIONS_PAGE, result.data);
    commit(types.SET_PAGE_INDEX, result.data.number + 1);
    await Promise.all([
      dispatch(ObservationCommentsActions.ReloadComments, (state.groupedObservationsPage?.content || []).map(gro => gro.observation), { root: true }),
      dispatch(reloadTasksAction),
    ]);
  }
  else {
    commit(types.SET_GROUPED_OBSERVATIONS_PAGE, null);
    showRestError(result.error, 'messages.cantFetchObservation');
  }
  commit(types.SET_OBSERVATIONS_LOADING, false);
  return result.success;
}

async function reloadTasks({ state, commit }) {
  const filters = {};
  const observationExtIds = (state.groupedObservationsPage?.content || [])
    .map(gro => gro.observation)
    .map(observation => observation.uuid);
  const map = await loadDependedTasks(observationExtIds, state.enrollment.uuid, state.patient, filters);
  commit(types.SET_OBSERVATION_TASK_MAP, map);
}

async function tableOptionsUpdated({ dispatch }, payload) {
  await dispatch('reload', payload);
}

async function setIrrelevantState({ dispatch }, { groupedObservation, isIrrelevant }) {
  const { id } = await popups.show({
    name: PopupName.LoadingPopup,
    type: PopupType.Modal,
    props: {
      label: 'common.pleaseWait',
      hideCloseButton: true,
    }
  });
  const uniqObservations = uniqBy([groupedObservation.observation, ...groupedObservation.linkedObservation], 'uuid');
  const results = await Promise.all(uniqObservations.map(observation => observations.patch(observation.uuid, { isIrrelevant })));
  popups.hide({ id });
  const allSuccess = results.every(result => result.success);
  const allError = results.every(result => !result.success);
  if (allSuccess) {
    if (isIrrelevant) {
      toast.success(localize('observations.observationIsHidden'));
    }
    else {
      toast.success(localize('observations.observationIsVisible'));
    }
  }
  else {
    results.filter(result => !result.success).forEach(result => {
      showRestError(result.error, 'messages.cantUpdateObservation');
    });
  }
  if (!allError) {
    await Promise.all([
      dispatch('reload', this.serverSideOptions),
      dispatch(observationsStorePath + '/graph/reload', {}, { root: true }),
    ]);
  }
}

async function selectionUpdate({ commit }, selection) {
  commit(types.SET_SELECTED_OBSERVATIONS, selection);
}
