import { toast } from '../../../services/toast';
import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { localize } from '../../../services/localize';
import {
  delMedication,
  editMedication,
  medications
} from '../../../services/api/simpleAnamnesis/medications/medications';
import { popups } from '../../../services/popups';
import { PopupType } from '@h4h/popups';
import { PopupName } from '../../../constants/popupName';

export const actions = Object.freeze({
  deleteMedication,
  reload,
  fetchMedications,
  showAddDialog,
  showEditDialog,
  createMedication,
  updateMedication,
});

async function deleteMedication({ state, commit }, medicationId) {
  commit(types.SET_LOADING, true);
  const result = await delMedication.delete(medicationId);
  commit(types.SET_LOADING, false);
  if (result.success) {
    toast.success(localize('messages.medicationDeleted'));
    await reload({ state, commit });
  }
  else {
    showRestError(result.error, 'messages.cantDeleteMedication');
  }
}

async function reload({ state, commit }) {
  commit(types.SET_LOADING, true);
  commit(types.SET_FETCHED, false);
  const result = await medications(state.patientUserUuid).fetch();
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_FETCHED, true);
    commit(types.SET_MEDICATIONS, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchMedications');
  }
  // return any result anyway. Empty array is better then null.
  return state.medications;
}

async function fetchMedications({ state, commit }, patientUserUuid) {
  commit(types.SET_LOADING, true);
  commit(types.SET_PATIENT_USER_UUID, patientUserUuid);
  const result = await medications(patientUserUuid).fetch();
  commit(types.SET_LOADING, false);
  if (result.success) {
    commit(types.SET_MEDICATIONS, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchMedications');
  }
  return state.medications;
}

async function showAddDialog({ commit }) {
  const { id } = await popups.show({
    name: PopupName.AddNewMedication,
    type: PopupType.Modal
  });
  commit(types.INIT_ADD_POPUP, {
    popupId: id,
  });
}

async function showEditDialog({ commit }, item) {
  const { id } = await popups.show({
    name: PopupName.EditMedication,
    type: PopupType.Modal
  });
  commit(types.SET_EDITABLE_MEDICATION_UUID, item.uuid);
  commit(types.INIT_EDIT_POPUP, {
    popupId: id,
    value: item.value,
  });
}

async function createMedication({ state, commit, getters }) {
  if (!getters.valid) {
    return;
  }
  commit(types.SET_LOADING, true);
  const result = await medications(state.patientUserUuid).create({
    value: state.medication.value
  });
  if (result.success) {
    popups.hide({ id: state.popupId });
    await reload({ state, commit });
    toast.success(localize('messages.patientFileSaved'));
  }
  else {
    showRestError(result.error, 'messages.cantCreateMedication');
  }
}

async function updateMedication({ state, commit, getters }) {
  if (!getters.valid) {
    return;
  }
  commit(types.SET_LOADING, true);
  const result = await editMedication.update(
    state.editableMedicationUuid,
    {
      value: state.medication.value
    });
  if (result.success) {
    popups.hide({ id: state.popupId });
    toast.success(localize('messages.medicationUpdated'));
    await reload({ state, commit });
  }
  else {
    showRestError(result.error, 'messages.cantUpdateMedication');
  }
}
