/* global TASK_API_URL */

import { CrudService } from '../../../classes/crudService';

export const callCenterTasksObservationTerminologyTypes = new CrudService(
  '/call-center/observation-terminology-types',
  data => data,
  data => data,
  TASK_API_URL
);
