import { toast } from '../../services/toast';
import { localize } from '../../services/localize';

import { PopupName } from '../../constants/popupName';
import { popups } from '../../services/popups';

import { types } from './types';
import { PopupType } from '@h4h/popups';
import { callCenterPrograms } from '../../services/api/programs/callCenterPrograms';
import { handleLogisticTask, pageableLogisticTasks } from '../../services/api/logistic/logisticTasks';
import { mapDataTablePageConfigToSpringPageConfig } from '../../model/page';
import { logisticCountryParam, setCountryCodeParamIfNeed } from '../i18n/countryCode/utils';

/**
 * Action, initalizes the list model and loads the programs
 */
async function init({ commit, state },) {
  if (!state.inputs.length) {
    await loadPrograms(commit);
    // when the programs are loaded proceed with the list init
    commit(types.INIT);
  }
}

async function loadPrograms(commit) {
  let programsList = [];
  const { success, data, error } = await callCenterPrograms.fetch();
  if (success) {
    programsList = data;
  }
  else {
    toast.error(localize('messages.cantFetchTasks'), error);
  }
  commit(types.SET_PROGRAMS, programsList);
}

async function selectionUpdate({ commit }, payload) {
  commit(types.SET_SELECTED_TASKS, payload);
}

const reloadDataAction = 'reloadData';

async function onFilterChange({ commit, dispatch }) {
  commit(types.UPDATE_FILTER_PARAMS);
  dispatch(reloadDataAction);
}

async function reload({ commit, dispatch }, payload) {
  commit(types.SET_PAGINATION_CONFIG, payload);
  dispatch(reloadDataAction);
}

/**
 * Performs a data reload on a tasks list.
 */
async function reloadData({ commit, state, dispatch }) {
  commit(types.SET_TASKS_PAGE, null);
  let params = {
    ...mapDataTablePageConfigToSpringPageConfig(state.serverSideOptions),
    ...(state.filterParams || {}),
  };
  await setCountryCodeParamIfNeed(dispatch, params, logisticCountryParam);
  commit(types.SET_LOADING, true);
  const { success, error, data } = await pageableLogisticTasks.fetchOne({ params });
  if (!success) {
    toast.error(localize('messages.cantFetchTasks'), error);
    commit(types.SET_LOADING, false);
    return;
  }

  commit(types.SET_LOADING, false);
  commit(types.SET_TASKS_PAGE, data);
  commit(types.SET_SELECTED_TASKS, []);
}

/**
 * Shows the task batch handling popup.
 * @param {Object}  context
 * @param {Object}  payload
 * @param {Array}   payload.tasks
 */
async function showHandleTaskPopup({ commit }, { tasks }) {
  const { id, closed } = await popups.show({
    type: PopupType.Modal,
    name: PopupName.HandleLogisticTask,
  });
  commit(types.SET_POPUP_ID, id);
  commit(types.SET_TASKS_FOR_HANDLE, tasks);
  return await closed;
}

/**
 * Sets the tasks to be handled in the future.
 * @param {Object}  context
 * @param {Object}  payload
 * @param {Array}   payload.tasks
 */
async function setTasksForHandle({ commit }, { tasks }) {
  commit(types.SET_POPUP_ID, null);
  commit(types.SET_TASKS_FOR_HANDLE, tasks);
}

/**
 * Handles the batch selected tasks.
 * @param {Object}  context
 */
async function handleTasks({ state, dispatch }) {
  const paramsCustomization = {};
  await setCountryCodeParamIfNeed(dispatch, paramsCustomization, logisticCountryParam);
  const promises = state.tasksForHandle
    .map(task => handleLogisticTask(task.tenantDependentUuid, task.tenantId, paramsCustomization));
  const results = await Promise.all(promises);
  const hasSuccess = results.some(result => result.success);
  const hasErrors = results.some(result => !result.success);
  if (hasErrors) {
    results
      .filter(result => !result.success)
      .forEach(result => toast.error(localize('messages.cantHandleTask'), result.error));
  }
  if (hasSuccess && !hasErrors) {
    toast.success(localize('messages.taskHandled'));
  }
  if (hasSuccess && state.popupId) {
    popups.hide({ id: state.popupId, result: true });
  }
  return true;
}

async function closeDialog({ state }) {
  popups.hide({ id: state.popupId, result: false });
}

export const actions = Object.freeze({
  init,
  onFilterChange,
  reload,
  [reloadDataAction]: reloadData,
  selectionUpdate,

  showHandleTaskPopup,
  handleTasks,
  closeDialog,
  setTasksForHandle,
});
