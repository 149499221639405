import moment from 'moment';
import { formatDate } from '@h4h/date';

export function sameDatepickerValue(value1, value2) {
  function getValueHash(value) {
    if (!Array.isArray(value)) {
      value = [value];
    }
    return value
      .filter(x => !!x)
      .map(v => moment(v).toDate())
      .map(date => formatDate(date))
      .join(',');
  }

  return getValueHash(value1) === getValueHash(value2);
}
