import { CrudService } from '../../../classes/crudService';
import { PageModel } from '../../../model/page';
import { BatchCreationResultModel } from '../../../model/BatchCreationResultModel';
import { GroupedObservationModel } from '../../../model/observation/groupedObservationModel';
import { observationBuilder } from '../../../model/observation/observationBuilder';
import {
  OptimizedGroupedObservationPageModel,
  OptimizedObservationPageModel
} from '../../../model/observation/optimizedObservationsPage';

export const observations = new CrudService(
  'observations',
  data => observationBuilder(data),
);

export const patientObservations = patientUuid => new CrudService(
  `patients/${ patientUuid }/observations`,
  data => new PageModel(data, config => observationBuilder(config)),
  model => model
);
export const groupedPatientObservations = patientUuid => new CrudService(
  `patients/${ patientUuid }/grouped-observations`,
  data => new PageModel(data, config => new GroupedObservationModel(config)),
  model => model
);
export const batchPatientObservations = patientUuid => new CrudService(
  `/patients/${ patientUuid }/observations/batch`,
  data => new BatchCreationResultModel(data),
  model => model
);

export const optimizedPatientObservations = patientUuid => new CrudService(
  `patients/${ patientUuid }/observations/optimized`,
  data => new OptimizedObservationPageModel(data),
  model => model
);

export const optimizedGroupedPatientObservations = patientUuid => new CrudService(
  `patients/${ patientUuid }/grouped-observations/optimized`,
  data => new OptimizedGroupedObservationPageModel(data),
  model => model
);
