export class UserModel {
  /**
   * UserModel config
   *
   * @param {Object}    config
   * @param {String}    config.email
   * @param {String}    config.firstName
   * @param {String}    config.gender
   * @param {String}    config.iamId
   * @param {String}    config.initials
   * @param {String}    config.lastName
   * @param {String}    config.middleName
   * @param {String}    config.partnerMiddleName
   * @param {String}    config.partnerName
   * @param {String}    config.phone
   * @param {String}    config.alarmPhoneNumber
   * @param {String}    config.uuid
   */
  constructor(config) {
    config = config || {};
    this.email = config.email;
    this.firstName = config.firstName;
    this.gender = config.gender;
    this.iamId = config.iamId;
    this.initials = config.initials;
    this.lastName = config.lastName;
    this.middleName = config.middleName;
    this.partnerMiddleName = config.partnerMiddleName;
    this.partnerName = config.partnerName;
    this.phone = config.phone;
    this.alarmPhoneNumber = config.alarmPhoneNumber;
    this.uuid = config.uuid;
  }
}
