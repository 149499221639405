import { types } from './types';
import { patientsBundle } from '../../../services/api/patients/patients';
import { showRestError } from '../../../utils/errors';
import { getPreferences, setPreferences } from '../../profile/preferences/utils';
import { UserPreferences } from '../../../model/userPreferences';

export const actions = Object.freeze({
  reload,
  pushPatientId,
});

async function pushPatientId({ commit, state, dispatch }, patientIamId) {
  let preferences = await getPreferences(dispatch);
  preferences = new UserPreferences(preferences);
  preferences.pushPatientToLast10Patients(patientIamId);
  await setPreferences(dispatch, preferences);
  await reloadData({ commit, state, dispatch });
}

async function reload({ commit, state, dispatch }) {
  await reloadData({ commit, state, dispatch });
}

async function reloadData({ commit, dispatch }) {
  let preferences = await getPreferences(dispatch);
  let ids = preferences?.last10Patients || [];
  if (!ids.length) {
    commit(types.SET_PATIENTS, null);
    return;
  }
  commit(types.SET_LOADING, true);
  const params = { iam: ids };
  const result = await patientsBundle.fetch({ params });
  commit(types.SET_LOADING, false);

  if (result.success) {
    const data = ids
      .map(iamId => result.data.find(patient => patient.iamId === iamId))
      .filter(patient => !!patient);
    commit(types.SET_PATIENTS, data);
    if (data.length !== ids.length) {
      preferences = new UserPreferences(preferences);
      preferences.last10Patients = data.map(patient => patient.iamId);
      await setPreferences(dispatch, preferences);
    }
  }
  else {
    commit(types.SET_PATIENTS_PAGE, null);
    showRestError(result.error, 'messages.cantFetchPatients');
  }
}
