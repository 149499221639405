import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';
import { without } from 'lodash';

export const mutations = {
  [types.INIT_FORM](state, questionnaireTemplate) {
    if (!questionnaireTemplate) {
      state.questionnaireTemplate = null;
      state.questions = [];
      state.inputs = [];
      state.inputsMap = {};
      return;
    }
    state.questionnaireTemplate = questionnaireTemplate;
    state.questions = questionnaireTemplate.questions;
    state.inputs = getInputsConfig(questionnaireTemplate);
    state.inputsMap = getInputsMap(state.inputs);
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.ADD_QUESTION](state, question) {
    state.questions.push(question);
  },

  [types.REMOVE_QUESTION](state, question) {
    state.questions = without(state.questions, question);
  },

};

/**
 *
 * @param {QuestionnaireTemplateModel} questionnaireTemplate
 * @return {*[]}
 */
function getInputsConfig(questionnaireTemplate) {
  return [
    createInputConfig({
      id: 'title',
      type: InputType.Text,
      label: 'common.name',
      required: true,
      value: questionnaireTemplate.title
    }),
  ];
}

