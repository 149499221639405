import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { findByKey } from '../../../filters/findByKey';

export const mutations = {
  [types.INIT_FORM](state, { observationTemplate, terminologyTypes }) {
    state.observationTemplate = observationTemplate;
    if (state.observationTemplate) {
      state.inputs = getInputsConfig(state.observationTemplate, terminologyTypes);
      state.definitions = state.observationTemplate.definitions;
    }
  },
  [types.CLEAR_FORM](state) {
    state.inputs = [];
    state.definitions = [];
    state.observationTemplate = null;
    state.loading = false;
    state.updatable = false;
  },
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },
  [types.SET_UPDATABLE](state, value) {
    state.updatable = value;
  },
};

/**
 *
 * @param {ObservationTemplateModel} observationTemplate
 * @return {Object[]}
 */
function getInputsConfig(observationTemplate, terminologyTypes) {
  const name = createTextInputConfig(observationTemplate.name, 'name', 'common.name', InputType.Label);
  const terminologyType = createTextInputConfig(
    findByKey(observationTemplate.terminologyType, 'key', terminologyTypes, 'translation'),
    'terminologyType',
    'observationTemplates.terminologyType',
    InputType.Label
  );
  return [
    name,
    terminologyType,
  ];
}

function createTextInputConfig(value, id, label, inputType) {
  return createInputConfig({
    value,
    id,
    type: inputType,
    label,
  });
}

