export const HEADER_SLOT_NAME_PREFIX = 'header.';
export const ITEM_SLOT_NAME_PREFIX = 'item.';
export const INPUT_SLOT_NAME_PREFIX = 'input.';

export function getFilteredSlotNames(vm, filter) {
  return [
    ...Object.keys(vm.$slots).filter(filter),
    ...Object.keys(vm.$scopedSlots).filter(filter)
  ];
}

export function getHeaderSlotNames(vm) {
  return getFilteredSlotNames(vm, isHeaderSlot);
}

export function isHeaderSlot(slotName) {
  return slotName?.startsWith(HEADER_SLOT_NAME_PREFIX);
}

export function getItemSlotNames(vm) {
  return getFilteredSlotNames(vm, isItemSlot);
}

export function isItemSlot(slotName) {
  return slotName?.startsWith(ITEM_SLOT_NAME_PREFIX);
}

export function createCellSlotName(key) {
  return ITEM_SLOT_NAME_PREFIX + key;
}

export function createInputSlotName(key) {
  return INPUT_SLOT_NAME_PREFIX + key;
}
