import { CrudService } from '../../../../classes/crudService';

export async function patientArchiving(userUuid) {
  return new CrudService(
    `/patients/${ userUuid }/archive`,
    data => data
  ).create();
}

export async function patientReactivate(userUuid) {
  return new CrudService(
    `/patients/${ userUuid }/reactivate`,
    data => data
  ).create();
}
