import { types } from './types';
import { router } from '../../services/router';
import { Page } from '../../constants/page';
import { setLoading } from '../../utils/withLoadind';
import { findPatientByExternalPatientId } from '../../services/api/patients/patients';
import { showRestError } from '../../utils/errors';

export const actions = Object.freeze({
  init,
  initByExternalPatientId,
});

async function init({ commit }, userId) {
  commit(types.SET_IFRAME_MODE, true);
  router.push({
    name: Page.PractitionerPatientEnrollmentsList,
    params: { userId },
  });
}

async function initByExternalPatientId({ commit, dispatch }, externalPatientId) {
  commit(types.SET_IFRAME_MODE, true);
  commit(types.SET_PATIENT_NOT_FOUND, false);
  dispatch(setLoading, true);
  const result = await findPatientByExternalPatientId(externalPatientId);
  dispatch(setLoading, false);
  if (result.success) {
    const { uuid } = result.data;
    router.push({
      name: Page.PractitionerPatientEnrollmentsList,
      params: { userId: uuid },
    });
  }
  else {
    commit(types.SET_PATIENT_NOT_FOUND, true);
    showRestError(result.error, 'messages.cantLoadPatient');
  }
}

