import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { labResultGroupsList } from '../../../services/api/labResults/labResultGroups';
import { labResultGroups } from '../../../services/api/labResults/labResultGroups';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';

export const actions = Object.freeze({
  fetch,
  reload,
  remove,
});

async function reload({ commit, state }) {
  commit(types.SET_FETCHED, false);
  return await fetch({ commit, state });
}

async function fetch({ commit, state }) {
  if (state.fetched) {
    return state.labResultGroups;
  }

  commit(types.SET_LOADING, true);
  const result = await labResultGroupsList.fetch();
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_FETCHED, true);
    commit(types.SET_LAB_RESULT_GROUPS, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchLabResultGroups');
  }
  return state.labResultGroups;
}

async function remove({ commit, state }, labResultGroup) {
  commit(types.SET_LOADING, true);
  const result = await labResultGroups.delete(labResultGroup.uuid);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.labResultGroupDeleted'));
    await reload({ state, commit });
  }
  else {
    showRestError(result.error, 'messages.cantDeleteLabResultGroup');
  }
  return state.labResultGroups;
}

