import { showRestError } from '../../utils/errors';
import { types } from './types';
import { countries } from '../../services/api/country/countries';

export const actions = Object.freeze({
  fetchCountries
});

let promise = null;

async function fetchCountries({ commit, state, getters }) {
  if (state.countriesList) {
    return getters.countries;
  }
  if (!promise) {
    promise = countries.fetch();
    commit(types.SET_LOADING, true);
    const result = await promise;
    if (result.success) {
      commit(types.SET_COUNTRIES, result.data);
    }
    else {
      showRestError(result.error, 'country.cantLoadCountries');
    }
    commit(types.SET_LOADING, false);
  }
  else {
    // duplicate logic to anyway have something in address list
    const result = await promise;
    if (result.success) {
      commit(types.SET_COUNTRIES, result.data);
    }
  }
  return getters.countries;
}
