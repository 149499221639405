export class EcgDrawModel {
  /**
   * EcgDrawModel
   *
   * @param {Object}      config
   * @param {Number[]}    config.values
   * @param {Number}      config.amplitudeResolution
   * @param {Number}      config.durationInSeconds
   * @param {Number}      config.totalDurationInSeconds
   * @param {Number}      config.width
   * @param {Number}      config.height
   * @param {Number}      config.scale
   */
  constructor(config) {
    this.values = config.values;
    this.durationInSeconds = config.durationInSeconds;
    this.amplitudeResolution = config.amplitudeResolution;
    this.totalDurationInSeconds = config.totalDurationInSeconds;
    this.width = config.width;
    this.height = config.height;
    this.scale = config.scale || 1;
  }
}
