import { types } from './types';
import { mapValues } from 'lodash';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { ProgramInputGroupModel } from '../../../model/monitoringPeriodModel';

export const actions = Object.freeze({
  initPopup,
  save,
  cancel,
});

async function initPopup({ commit }, { groups, programInput }) {
  const { id, closed } = await popups.show({
    name: PopupName.ProgramInputGroupPopup,
    type: PopupType.Modal
  });
  commit(types.INIT_POPUP, { popupId: id, groups, programInput });
  return { closed };
}

async function save({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return null;
  }
  const group = new ProgramInputGroupModel({
    ...mapValues(state.inputsMap, i => i.value),
  });
  popups.hide({ id: state.popupId, result: group });
  return group;
}

async function cancel({ state }) {
  popups.hide({ id: state.popupId });
}
