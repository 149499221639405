import { types } from './types';
import { showRestError } from '../../../../utils/errors';
import {
  addUserRole,
  callCenterUsers,
  removeUserRole,
  resetUserPassword
} from '../../../../services/api/users/callCenterUsers';
import { toast } from '../../../../services/toast';
import { localize } from '../../../../services/localize';
import { apiRoleMapper } from '../../../../services/auth/apiRoleMapper';
import { router } from '../../../../services/router';
import { Page } from '../../../../constants/page';
import { mapValues } from 'lodash';
import { convertPhonesToStrings } from '../../../../utils/phone/phoneComponentUtils';
import { fetchCountries } from '../../../countries/utils';

export const actions = Object.freeze({
  initViewPage,
  initEditPage,
  fetchProfile,
  updateProfile,
  resetPassword,
  addRole,
  removeRole
});

async function initViewPage({ commit, dispatch }, userId) {
  await dispatch('fetchProfile', userId);
  const countries = await fetchCountries(dispatch);
  commit(types.INIT_FORM, { readOnly: true, countries });
}

async function initEditPage({ commit, dispatch }, userId) {
  await dispatch('fetchProfile', userId);
  const countries = await fetchCountries(dispatch);
  commit(types.INIT_FORM, { readOnly: false, countries });
}

async function fetchProfile({ commit }, userId) {
  commit(types.SET_LOADING, true);
  const result = await callCenterUsers.get(userId);
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_PROFILE, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchProfile');
  }
}

async function resetPassword({ commit }, userId) {
  commit(types.SET_LOADING, true);
  let result = await resetUserPassword(userId);
  commit(types.SET_LOADING, false);
  if (result.success) {
    toast.success(localize('messages.passwordResetSuccessfully'));
  }
  else {
    showRestError(result.error, 'messages.errorResetPassword');
  }
}

async function updateProfile({ state, commit, getters }, userId) {
  commit(types.SET_PRISTINE, false);
  if (!getters.areProfileInputsValid) {
    return false;
  }

  const updatedProfile = {
    ...state.profile,
    ...mapValues(state.inputsMap, i => i.value)
  };
  convertPhonesToStrings(updatedProfile, ['phone']);

  commit(types.SET_LOADING, true);
  const result = await callCenterUsers.update(userId, updatedProfile);
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_PROFILE, result.data);
    toast.success(localize('messages.profileUpdated'));

    // we can update country during profile edit. If Yes, then use can become unavailable,
    // since he and current user are in different countries. To check it , we fetch user after update
    // - if there is an error, then 99% we changed the country and it means we just need to redirect to users list
    const newUserProfile = await callCenterUsers.get(userId);
    if (newUserProfile.success) {
      router.push({ name: Page.CallCenterUserViewProfile, params: { userId: userId } });
    }
    else {
      router.push({ name: Page.CallCenterUsersList });
    }
  }
  else {
    showRestError(result.error, 'messages.cantUpdateProfile');
  }
  return result.success;
}

async function addRole({ commit }, { userId, itemId }) {
  commit(types.SET_LOADING, true);
  let result = await addUserRole(userId, apiRoleMapper.toApi(itemId));
  commit(types.SET_LOADING, false);
  if (result.success) {
    toast.success(localize('messages.roleAddedSuccessfully'));
  }
  else {
    showRestError(result.error, 'messages.errorAddRole');
  }

  await fetchProfile({ commit }, userId);
}

async function removeRole({ commit }, { userId, itemId }) {
  commit(types.SET_LOADING, true);
  let result = await removeUserRole(userId, apiRoleMapper.toApi(itemId));
  commit(types.SET_LOADING, false);
  if (result.success) {
    toast.success(localize('messages.roleRemovedSuccessfully'));
  }
  else {
    showRestError(result.error, 'messages.errorRemoveRole');
  }

  await fetchProfile({ commit }, userId);
}
