export const anamnesis = {
  anamnesis: 'Anamnese',
  anamnesisTemplate: 'Anamnese sjabloon',
  chooseAnamnesisTemplate: 'Kies een anamnese-sjabloon',
  openAnamnesisTemplate: 'Anamnese-sjabloon openen',
  removeAnamnesisTemplate: 'Anamnese-sjabloon verwijderen',
  pleaseConfirmRemoveAnamnesisTemplate: 'Bevestig a.u.b. het verwijderen van de anamnese-sjabloon',
  commentByMedicalPersonnel: 'Commentaar van medisch personeel',

  risk: 'Risico',
  target: 'Doelwit',
  feedback: 'Feedback',
  disclaimer: 'Disclaimer',
  sendFeedback: 'Stuur feedback',
  riskCalculations: 'Risicoberekeningen',
  preferableMedications: 'Voorkeur medicijnen',
  agreeWithResults: 'Ik ben het eens met het advies',
  pleaseProvideYourFeedback: 'Geef alstublieft uw feedback',
  disagreeWithResults: 'Ik ben het niet eens met het advies',
  hypertensionMedications: 'Hypertensie medicatie',
  cholesterolMedications: 'Cholesterol medicatie',

  loadingMedications: 'Medicatiesuggesties laden',

  if70orOlder: 'Als 70 of ouder',
  prescriptions: 'Voorschriften',
  mostPrescribed: 'Meest voorgeschreven',
  // treatmentSteps: 'Behandeling stappen',
  patientCategory: 'Patiëntcategorie',
  editPrescriptions: 'Wijzig voorschriften',
  addPrescriptions: 'Voeg voorschriften toe',
  prescriptionPrompt: 'Wat zou u overwegen gezien deze suggestie?',

  // item labels by linkId (anamnesis):
  _397669002: 'Leeftijd',
  _263495000: 'Geslacht',
  _397731000: 'Etniciteit',
  _410667008: 'Lengte',
  _73211009: 'Diabetes',
  _439724007: 'Eerstegraads familielid met hart- en vaatziekte',
  _42399005: 'Chronische nierschade',
  _266995000: 'Cardiovasculaire voorgeschiedenis',
  _194828000: 'Angina pectoris',
  _399211009: 'Myocard infarct in de voorgeschiedenis',
  _84114007: 'Hartfalen',
  _74627003: 'Diabetes met orgaanfalen',
  _69896004: 'Reumatische artritis',
  _49436004: 'Atriumfibrilleren',
  _399957001: 'Perifeer arterieel vaatlijden',

  // item labels by linkId (hypertension questionnaire):
  _365981007: 'Roken',
  _726527001: 'Gewicht',
  _68130003: 'Hoeveel beweegt u per week (sporten, wandelen of fietsen)?', // 'Lichamelijke activiteit',

  // Placeholders for hypertension questionnaire fields:
  ph_365981007: 'Rookt u?',
  ph_726527001: 'Wat is uw gewicht?',
  ph_68130003: 'Hoeveel beweegt u?',

  // translations of options in dropdowns:
  _248153007: 'Man',
  _248152002: 'Vrouw',

  _315240009: 'Afrikaanse origine',
  _414152003: 'Europese origine',

  _moreThan150min: 'Meer dan 150 minuten',
  _between75and150min: 'Tussen 75 en 150 minuten',
  _lessThan75min: 'Minder dan 75 minuten'
};
