<template>
  <div>
    <TextInput
      v-bind="$props"
      :password="true"
      :clearable="true"
      :data-testid="`field-${$props.name}`"
      @change="change"
    />
    <div :class="styles.strengthBarWrap">
      <div :class="styles.strengthBar">
        <div
          v-for="index in strength.numberOfLevels"
          :key="index"
          :class="[styles.segment, segmentColor(index)]"
        />
      </div>
      <label :class="styles.strengthLevel">
        {{ $localize(strength.title) }}
      </label>
    </div>
    <ul :class="styles.hintList">
      <li
        v-for="(hint, index) in hints"
        :key="index"
        :class="styles.hint"
      >
        <Icon
          :type="hint.value ? 'check' : 'x'"
          :class="hint.value ? styles.green : styles.red"
        />
        <div :class="styles.hintText">
          {{ $localize(hint.text) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import { Icon } from '@h4h/icons';
  import { ComponentStyle } from '@h4h/theme';

  import { InputMixin } from '../../mixins';

  import TextInput from '../Text';

  import styles from './passwordStrength.scss';
  import { StrengthLevel } from './models/StrengthLevel';
  import { passwordStrength, defaultStrengthLevels } from './passwordStrengthUtils';

  export default {
    name: 'H4hPasswordStrength',

    components: {
      TextInput,
      Icon
    },

    mixins: [
      InputMixin
    ],

    props: {
      ...TextInput.props,

      // We can customize rules. Check defaultStrengthLevels for more info.
      strengthLevels: {
        type: Array,
        default: function() {
          return defaultStrengthLevels;
        },
        validator(levels) {
          return StrengthLevel.validateStrengthLevels(levels);
        }
      }
    },

    data() {
      const { strength, hints } = passwordStrength(this.$props.value, this.$props.strengthLevels);
      return {
        styles,
        strength: strength,
        hints: hints
      };
    },

    methods: {
      change(value) {
        this.$emit('change', value);
        const { strength, hints } = passwordStrength(value, this.$props.strengthLevels);
        this.strength = strength;
        this.hints = hints;
      },

      focus() {
        this.$refs.input.focus();
      },

      segmentColor(index) {
        if (index <= this.strength.level) {
          switch (this.strength.color) {
            case ComponentStyle.Danger:
              return this.styles.red;
            case ComponentStyle.Warning:
              return this.styles.orange;
            case ComponentStyle.Success:
              return this.styles.green;
          }
        }
        else {
          return this.styles.gray;
        }
      }
    }
  };
</script>
