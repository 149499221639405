import { mapValues } from 'lodash';

export class TableFiltersProfile {

  /**
   *
   * @param {String}  id
   * @param {String}  name
   * @param {Boolean} defaultProfile
   * @param {Object}  filters
   */
  constructor({ id, name, defaultProfile, filters }) {
    this.id = id;
    this.name = name;
    this.defaultProfile = !!defaultProfile;
    this.filters = mapValues(filters, value => {
      if (value instanceof Set) {
        return Array.from(value);
      }
      return value;
    });
  }
}

export class TableFiltersProfiles {

  /**
   *
   * @param {TableFiltersProfile[]} profiles
   */
  constructor({ profiles }) {
    // need to copy  profiles for load from JSON (1) and change profiles not not mutation that was created from another profiles from store
    this.profiles = Array.from(profiles || []).map(profile => new TableFiltersProfile(profile));
  }

  /**
   *
   * @param {TableFiltersProfile} profile
   */
  validateDefaultProfile(profile) {
    // only one profile can be default
    if (profile.defaultProfile) {
      this.profiles.forEach(p => {
        if (p.defaultProfile && p.id !== profile.id) {
          p.defaultProfile = false;
        }
      });
    }
  }

  /**
   *
   * @param {TableFiltersProfile} profile
   */
  profileExists(profile) {
    return this.profiles.some(p => p.name === profile.name);
  }

  getDefaultProfile() {
    return this.profiles.find(p => p.defaultProfile) || null;
  }
}
