import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig, getInputsMap } from '@h4h/inputs';
import { EhPatterns } from '../../utils/ehPatterns';
import { EhUserFieldsLengthConstraints } from '../../utils/ehUserFieldsLengthConstraints';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PROFILE](state, profile) {
    state.profile = profile;
  },
  [types.INIT_FORM](state, { readOnly }) {
    const inputs = getInputsConfig(state.profile || {}, readOnly ? InputType.Label : InputType.Text);

    state.inputs = inputs;
    state.inputsMap = getInputsMap(inputs);
  },
};

function getInputsConfig(profile, inputType) {
  const initials = createTextInputConfig(profile.initials, 'initials', 'common.initials', inputType, EhPatterns.lettersDotsOnly, false, EhUserFieldsLengthConstraints.initials);
  const firstName = createTextInputConfig(profile.firstName, 'firstName', 'common.firstName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, !!profile.firstName, EhUserFieldsLengthConstraints.firstName);
  const middleName = createTextInputConfig(profile.middleName, 'middleName', 'common.middleName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.middleName);
  const lastName = createTextInputConfig(profile.lastName, 'lastName', 'common.lastName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, true, EhUserFieldsLengthConstraints.lastName);
  const alarmPhoneNumber = createTextInputConfig(profile.alarmPhoneNumber, 'alarmPhoneNumber', 'common.alarmPhoneNumber', inputType, EhPatterns.phoneNumberInternational, true,EhUserFieldsLengthConstraints.alarmPhoneNumber);
  const partnerMiddleName = createTextInputConfig(profile.partnerMiddleName, 'partnerMiddleName', 'common.partnerMiddleName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.partnerMiddleName);
  const partnerName = createTextInputConfig(profile.partnerName, 'partnerName', 'common.partnerName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.partnerName);

  return [
    initials,
    firstName,
    lastName,
    middleName,
    partnerName,
    partnerMiddleName,
    alarmPhoneNumber,
  ];
}

function createTextInputConfig(value = null, id, label, inputType, pattern, required = false, constraints) {
  return createInputConfig({
    id,
    type: inputType,
    pattern,
    value,
    required,
    label,
    ...constraints,
  });
}
