export const observationCreatedByTypes = Object.freeze({
  PATIENT: 'PATIENT',
  PHYSICIAN: 'PHYSICIAN',
  POH: 'POH',
  CALL_CENTER_SUPPORT: 'CALL_CENTER_SUPPORT',
  SYSTEM: 'SYSTEM',
  OTHER_USER_FROM_TENANT: 'OTHER_USER_FROM_TENANT',
  OTHER_FROM_CALL_CENTER: 'OTHER_FROM_CALL_CENTER',
  CLIENT: 'CLIENT',
  OTHER: 'OTHER',
  CREATED_BY_IS_BLANK: 'CREATED_BY_IS_BLANK',
});