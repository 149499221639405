import { CrudService } from '../../../classes/crudService';

export const photo = new CrudService(
  'profile/photo',
  data => {
    return data && data.base64Photo;
  },
  model => {
    return {
      base64Photo: model
    };
  }
);
