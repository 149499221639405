import { state } from './state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { add } from './add';
import { edit } from './edit';

export const questionnaire = {
  namespaced: true,

  state,
  actions,
  getters,
  mutations,
  modules: {
    add,
    edit,
  }
};
