import { CrudService } from '../../../../classes/crudService';
import { ForwardingTaskEmailTemplateModel } from '../../../../model/forwardingTaskEmailTemplateModel';

export const forwardTaskEmailTemplates = new CrudService(
  '/email-templates/task-forwarding',
  data => new ForwardingTaskEmailTemplateModel(data),
  model => model
);

export const forwardTaskEmailTemplatesCallCenter = new CrudService(
  '/call-center/email-templates/task-forwarding',
  data => new ForwardingTaskEmailTemplateModel(data),
  model => model
);

export const forwardTasks = taskId => new CrudService(
  `/tasks/${ taskId }/forwardings`,
  data => data,
  model => model
);

export const forwardTasksCallCenter = taskId => new CrudService(
  `/call-center/tasks/${ taskId }/forwardings`,
  data => data,
  model => model
);
