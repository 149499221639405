import { types } from './types';
import { showRestError } from '../../../../utils/errors';
import { physicians } from '../../../../services/api/users/physicians';

export const actions = Object.freeze({
  initResponsiblePhysician,
});

async function initResponsiblePhysician({ commit }, responsiblePhysicianId) {
  commit(types.SET_RESPONSIBLE_PHYSICIAN, null);
  if (!responsiblePhysicianId) {
    return null;
  }

  commit(types.SET_LOADING, true);
  const result = await physicians.get(responsiblePhysicianId);
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_RESPONSIBLE_PHYSICIAN, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchResponsiblePhysician');
  }
  return result.success;
}
