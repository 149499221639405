export const observations = {
  observations: 'Observaties',
  observation: 'Observatie',
  addObservation: 'Observatie toevoegen',
  chooseObservationGroup: 'Kies een groep',
  chooseObservationsHelpText: 'Het is mogelijk om meer dan één type meting in te voeren. Kies welke metingen u wilt invoeren.',
  makeIrrelevant: 'Maak irrelevant',
  makeRelevant: 'Maak relevant',
  measurements: 'Metingen',
  observationNote: 'Observatie notitie',
  measuredBy: 'Meting door',
  callCenterUser: 'Call center gebruiker',
  other: 'Andere',
  observationIsNotInTheCurrentPeriodHelpText: 'Deze meting wordt niet getoond in de grafiek omdat de meting dag niet valt tussen de start- en einddatum van deze periode.',
  observationIsHidden: 'Observatie is verborgen.',
  observationIsVisible: 'Observatie is zichtbaar.',
  allMeasurements: 'Alle metingen',
  pdf: 'PDF',
};
