import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { state } from './state';
import { ecg } from './ecg';

export const single = {
  namespaced: true,

  actions,
  getters,
  mutations,
  state,
  modules: {
    ecg
  }
};
