import { get, round, flatten, isEqual, isNil, isFunction, isBoolean, isObject, isNumber, isString, isInteger, inRange } from 'lodash';

import { isValidDate } from '../../../date';
import { InputType } from '../constants/inputType';
import { AddressModel, DatePartialModel, PhoneModel, PoBoxAddressModel } from '../models';
import { Patterns } from './patterns';
import { isKindOfDateInput } from './matchers';

const numberRangePatterns = [Patterns.IntRangeOrIntValue, Patterns.FloatRangeOrFloatValue];

export function validateInput(i) {
  if (!i || !InputType[i.type]) {
    return false;
  }

  if (isFunction(i.validate)) {
    return i.validate(i);
  }

  if (isKindOfDateInput(i)) {
    return validateDateInput(i);
  }

  switch (i.type) {
    case InputType.Boolean:
      return validateBooleanInput(i);
    case InputType.Text:
    case InputType.Wysiwyg:
    case InputType.Textarea:
    case InputType.CodeInput:
      return validateTextInput(i);
    case InputType.Float:
      return validateFloatInput(i);
    case InputType.Integer:
      return validateIntegerInput(i);
    case InputType.Radio:
    case InputType.Select:
    case InputType.CountrySelect:
    case InputType.Select2:
    case InputType.Typeahead2:
      return validateSelectInput(i);
    case InputType.Typeahead:
      return validateTypeaheadInput(i);
    case InputType.Address:
      return validateAddressInput(i);
    case InputType.Phones:
      return validatePhoneInput(i);
    case InputType.TreeView:
      return validateTreeViewInput(i);
    case InputType.File:
      return validateFileInput(i);
    case InputType.Checkbox:
      return validateCheckboxInput(i);
    case InputType.Label:
      return true;
    case InputType.DatePartial:
      return validateDatePartialInputs(i);
    default:
      return false;
  }
}

export function validateAddressInput(i) {
  if (!i) {
    return false;
  }

  if (i.value) {
    if (!(i.value instanceof AddressModel || i.value instanceof PoBoxAddressModel)) {
      return false;
    }
    const isRegular = i.value instanceof AddressModel;
    const fields = isRegular
      ? ['city', 'street', 'postalCode', 'number', 'country']
      : ['city', 'postalCode', 'poBox', 'country'];

    const someFilled = [...fields, 'numberExtension'].some(field => i.value[field]);
    return fields.every(field => {
      const constraints = get(i.customizations, field) || {};
      const pattern = constraints.pattern || get(i.patterns, field);
      let value = i.value[field];
      if (isNumber(value)) {
        value = '' + value;
      }
      let required;
      if (isBoolean(constraints.required)) {
        required = constraints.required;
      }
      else {
        required = i.required || someFilled;
      }
      if (field === 'country') {
        if (value === null) {
          return !required;
        }
        else {
          return isObject(value);
        }
      }
      else {
        return validateText(value, required, constraints.min, constraints.max, pattern);
      }
    });
  }
  else if (isNaN(i.value)) {
    return false;
  }
  else if (isNil(i.value) && i.required) {
    return false;
  }
  return true;
}

export function validateBooleanInput(i) {
  if (!i) {
    return false;
  }

  if (!isBoolean(i.value)) {
    return i.value === null && !i.required;
  }

  return true;
}

function validateText(value, required, min, max, pattern) {
  if (!isString(value)) {
    return value === null && !required;
  }

  if (pattern && value !== '') {
    return numberRangePatterns.includes(pattern)
      ? validateNumberRange(value, pattern)
      : pattern.test(value);
  }

  if (value.trim() === '') {
    return !required;
  }

  return (
    (!min || value.length >= min) &&
    (!max || value.length <= max)
  );
}

export function validateTextInput(i) {
  if (!i) {
    return false;
  }

  return validateText(i.value, i.required, i.min, i.max, i.pattern);
}

function validatePhoneModel(value, required, pattern) {
  if (!(value instanceof PhoneModel)) {
    return false;
  }

  if (!value || value.isEmpty()) {
    return !required || !isNaN(value);
  }

  return validateText(value.number, required, null, null, pattern || Patterns.Number);
}

export function validatePhoneInput(i) {
  if (!i) {
    return false;
  }
  const { required, value, someRequired, pattern } = i;
  if (value && Array.isArray(value)) {
    if (someRequired) {
      return value.some(model => validatePhoneModel(model, required, pattern));
    }
    return value.every(model => validatePhoneModel(model, required, pattern));
  }
  return validatePhoneModel(value, required, pattern);
}

export function validateDatePartialInputs(i) {
  if (!i) {
    return false;
  }
  if (!(i.value instanceof DatePartialModel)) {
    return false;
  }

  if (i.value.year === null) {
    return !i.requiredYear && !i.required && !i.value.month && !i.value.day;
  }

  if (i.value.month === null) {
    return !i.value.day && !i.required && isInteger(i.value.year) && inRange(i.value.year, 999, 9999);
  }

  return (i.value.day ? isInteger(i.value.day) : !i.required) &&
    isInteger(i.value.year) &&
    isInteger(i.value.month) &&
    isValidDate(createDateFromPartialDate(i.value));
}

/*
  Checks if the date is valid or not, receives the values from DatePartial input and creates a Date object.

  If it is an invalid Date (e.g 2023 February 31st), the Javascript Date constructor normalizes the date and
  returns a valid date (2023 March 3rd).
  If the entered values equal to the normalized date the date is valid.
*/
export function createDateFromPartialDate({ year, month, day = null }) {
  const normalizedDate = day ? new Date(year, month - 1, day) : new Date(year, month - 1);

  if (
    (normalizedDate.getFullYear() === year && inRange(year, 999, 9999)) &&
    normalizedDate.getMonth() === (month - 1) &&
    (!day || normalizedDate.getDate() === day)
  ) {
    return normalizedDate;
  }

  return undefined;
}

export function validateDateInput(i) {
  if (!i) {
    return false;
  }

  if (i.value === null) {
    return !i.required;
  }

  return flatten([i.value]).every(value =>
    isValidDate(value) &&
    (!i.min || value >= i.min) &&
    (!i.max || value <= i.max)
  );
}

export function validateFloatInput(i) {
  if (!i) {
    return false;
  }

  if (!isNumber(i.value)) {
    return i.value === null && !i.required;
  }

  const value = toNumberPrimitive(i.value);
  const min = toNumberPrimitive(i.min);
  const max = toNumberPrimitive(i.max);

  return Number.isFinite(value) &&
    (!Number.isFinite(min) || value >= min) &&
    (!Number.isFinite(max) || value <= max);
}

/**
 * Converts object instances to primitive numbers
 *
 * @param {any} value
 * @returns {number|any}
 */
function toNumberPrimitive(value) {
  return value instanceof Number ? +value : value;
}

export function validateIntegerInput(i) {
  // this is because round(null) returns 0 and the validation is not correct
  return validateFloatInput(i) && (
    i.value === null || round(i.value) === i.value
  );
}

// exposed for testing purposes
export const selectInputValidators = {
  single(i) {
    const selectedOption = i.returnObject
      ? i.options.find(o => isEqual(o, i.value))
      : i.options.find(o => isEqual(o.value, i.value));

    if (!selectedOption) {
      return i.value === null && !i.required;
    }

    return true;
  },

  multipleSet(i) {
    if (!(i.value instanceof Set)) {
      return i.value === null && !i.required;
    }

    if (!i.value.size) {
      return !i.required;
    }

    const possibleValues = new Set(i.options.map(o => o.value));
    return Array.from(i.value).every(v => possibleValues.has(v));
  }
};

export function validateSelectInput(i) {
  if (!i || !i.options) {
    return false;
  }

  return i.multiple
    ? selectInputValidators.multipleSet(i)
    : selectInputValidators.single(i);
}

export function validateTypeaheadInput(i) {
  if (!i || !i.options) {
    return false;
  }

  if (!i.value) {
    return i.value === null && !i.required;
  }

  const optionsSet = new Set(i.options);

  if (!i.multiple) {
    return optionsSet.has(i.value);
  }

  if (!(i.value instanceof Set)) {
    return false;
  }

  if (!i.value.size) {
    return !i.required;
  }

  return Array.from(i.value).every(o => optionsSet.has(o));
}

export function validateNumberRange(inputValue, pattern) {
  if (!pattern.test(inputValue)) {
    return false;
  }

  const [, x, y] = pattern.exec(inputValue) || [];
  if (x && y) {
    return parseFloat(x) < parseFloat(y);
  }
  return true;
}

export function validateTreeViewInput(i) {
  if (!i || !i.options) {
    return false;
  }

  if (!i.value?.length) {
    return !i.required;
  }

  const treeMap = getTreeViewMap(i.options);

  return i.value.every(path => get(treeMap, path));
}

function getTreeViewMap(children) {
  const treeMap = {};

  children?.forEach(child => {
    treeMap[child.id] = getTreeViewMap(child.children);
  });

  return treeMap;
}

// max files, max size and file types validation is implemented inside File component
export function validateFileInput(i) {
  // Min files check
  if (i.min && i.value?.length && i.min > i.value.length) {
    return false;
  }

  // Not required and all other checks passed
  if (!i.required) {
    return true;
  }

  // Required and all other checks passed
  return !!i.value?.length;
}

export function validateCheckboxInput(i) {
  return !i.required ? true : !!i.value;
}
