export const ObservationSource = Object.freeze({
  Blood: 'Blood',
  Urine: 'Urine'
});

export const BloodObservationTemplate = Object.freeze({
  Cholesterol: 'Cholesterol',
  LDL: 'LDL',
  HDL: 'HDL',
  Creatinine: 'Creatinine',
  // eGFR: 'eGFR'
});

export const UrineObservationTemplate = Object.freeze({
  ACR: 'ACR', // Albumine creatinine ratio
  Amylase: 'Amylase',
  Calcium: 'Calcium'
});

export const ObservationType = Object.freeze({
  ECG: 'ECG',
  COMMON: 'Common',
});
