export const ChipStyle = Object.freeze({
  Primary: 'Primary',
  Danger: 'Danger',
  Default: 'Default',
  Success: 'Success',
  Warning: 'Warning',
  Dark: 'Dark',
  Transparent: 'Transparent',
  Cancel: 'Cancel'
});
