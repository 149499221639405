<template>
  <div
    :class="[
      styles.option,
      $props.focused && 'hover',
      $props.active && 'active',
      'e2eSelectOption',
    ]"
    :data-testid="$props.option.testId"

    @click="onClick"
  >
    <slot v-bind="slotScope">
      <div :class="$props.option.subLabel && styles.label">
        {{ $localize($props.option.label) }}
      </div>
      <div
        v-if="$props.option.subLabel"
        :class="styles.subLabel"
      >
        {{ $localize($props.option.subLabel) }}
      </div>
    </slot>
  </div>
</template>

<script>
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { inputProps } from '../../utils';

  import styles from './select.scss';

  export default {
    name: 'H4hSelectOption',

    props: {
      option: {
        type: Object,
        required: true
      },

      /** flags */
      active: inputProps.booleanFalse,
      focused: inputProps.booleanFalse
    },

    data() {
      return {
        styles,
        inputStyles
      };
    },

    computed: {
      slotScope() {
        const { option, active, focused } = this.$props;

        return {
          option,
          active,
          focused
        };
      }
    },

    methods: {
      onClick($event) {
        this.$emit('click', $event);
      }
    }
  };
</script>
