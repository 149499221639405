import { mapArray } from '../utils/mapUtils';
import { ObservationInputTypeOptions } from '../constants/observationInputType';
import { localize } from '../services/localize';

export class ObservationTemplateModel {
  /**
   * ObservationTemplateModel config
   *
   * @param {Object}                          config
   * @param {String}                          config.name
   * @param {String}                          config.uuid
   * @param {String}                          config.terminologyType // a h4h terminology code for this observation
   * @param {ObservationDefinitionModel[]}    config.definitions
   * @param {String}                          config.tenant
   */
  constructor(config) {
    this.name = config.name;
    this.uuid = config.uuid;
    this.terminologyType = config.terminologyType;
    this.definitions = mapArray(config.definitions, ObservationDefinitionModel);
    this.tenant = config.tenant ? config.tenant : null;
  }
}

export class ObservationDefinitionModel {
  /**
   * ObservationDefinitionModel config
   *
   * @param {Object}                  config
   * @param {ObservationInputType}    config.inputType
   * @param {Number}                  config.max
   * @param {Number}                  config.min
   * @param {String}                  config.type  // a h4h terminology code for this particular observation component
   * @param {String}                  config.unitCode
   */
  constructor(config) {
    this.inputType = config.inputType;
    this.max = config.max;
    this.min = config.min;
    this.type = config.type;
    this.unitCode = config.unitCode;
  }

  get inputTypeString() {
    const it = ObservationInputTypeOptions.find(mit => mit.value === this.inputType);
    if (it) {
      return localize(it.label);
    }
    return this.inputType;
  }
}
