export const programs = {
  programs: 'Programma\'s',
  program: 'Programma',
  addProgram: 'Programma toevoegen',
  availablePeriodsCount: 'Beschikbare periodes tellen mee',
  availablePeriods: 'Beschikbare periodes',
  pleaseAddAtLeastOnePeriod: 'Voeg ten minste één periode toe',
  basePeriod: 'Basisperiode',
  durationUnit: 'Duur eenheid',
  durationValue: 'Duur',
  controlPlaneScript: 'Control plane script',
  treatmentAdviceScript: 'Script voor behandeladvies',
  treatmentAdviceTemplate: 'Sjabloon voor behandeladvies',
  cron: 'Cron-schema',
  observations: 'Observaties',
  addObservation: 'Observatie toevoegen',
  makePrimaryPeriod: 'Maak de periode primair',
  primaryPeriod: 'Primaire periode',
  devices: 'Apparaten',
  addDevice: 'Apparaat toevoegen',
  questionnaires: 'Vragenlijsten',
  addQuestionnaire: 'Vragenlijst toevoegen',
  groups: 'Groepen',
  hasNoDuration: 'Deze periode heeft geen einddatum',
  sendEmailOnEnrollment: 'Verstuur mailnotificatie',
  includeSchedule: 'Voeg schema toe',
  noSchedule: 'Geen schema',
  doesntUseH4Happ: 'Maakt geen gebruik van de H4H-app',
  selectAProgram: 'Selecteer een programma',
  pdfViewer: 'PDF Viewer',
};
