export const tasks = {
  pageTitle: 'Tasks',

  searchTasks: 'Search tasks',
  loadingTasks: 'Loading tasks',
  noTasksFound: 'No tasks found',

  taskType: 'Task type',
  program: 'Program',
  values: 'Values',
  notification: 'Notification',

  myPatients: 'My patients',
  allPatients: 'All patients',
  viewProfile: 'View profile',

  severity: 'Severity',
  urgency: 'Task severity',
  period: 'Period',

  status: 'Status',
  closed: 'Closed',
  open: 'Open',
  handled: 'Handled',
  unhandled: 'Unhandled',

  low: 'Normal',
  high: 'Critical',
  medium: 'Abnormal',
  info: 'Informative',
  unclassified: 'Unclassified',

  handle: 'Handle',
  created: 'Created',
  reassign: 'Reassign',
  completed: 'Completed',
  assignedTo: 'Assigned to',
  taskHistory: 'Task history',
  handlePopupMessage: 'How do you want to handle these tasks?',
  handlePopupHeader: 'Handle',
  handlePopupDoneNothingOption: 'Done nothing',
  handlePopupContactedPatientOption: 'Contacted patient',
  handleLogisticPopupMessage: 'Do you want to handle these tasks?',
  infoMessage: 'For your patient, %{lastName}, %{firstName} monitoring period has ended. Check the observations to determine the following steps.',
  information: 'Information',
  observation: 'Observation',
  message: 'Message',
  forward: 'Forward',
  forwarded: 'Forwarded',
  forwardTaskPopupHeader: 'Forward task',
  forwardTask: 'Forward task',
  recipient: 'Recipient',
  currentResponsiblePhysician: '{0} (current treating physician)',
  ccSupportUser: '{0} (Call-center support user)',

  average: 'Average',
  saveAndHandle: 'Save and handle',
  pleaseChooseTask: 'Please choose task',
  taskIsForwarded: 'Task is forwarded',

  informationalNotifications: 'Informational notifications',
  _7days: '7 days',
  _14days: '14 days',
  _30days: '30 days',
};
