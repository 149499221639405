import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export const countries = {
  namespaced: true,

  state,
  actions,
  getters,
  mutations
};
