import { AddressDialogMode } from './models';

export const getters = {
  valid,
  isNewAddress,
};

function valid(state) {
  return state.address && state.address.valid;
}

function isNewAddress(state) {
  return state.mode === AddressDialogMode.CREATE_NEW;
}

