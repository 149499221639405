<template>
  <div :class="styles.container">
    <div :class="styles.spinner">
      <div :class="styles.rect1"/>
      <div :class="styles.rect2"/>
      <div :class="styles.rect3"/>
      <div :class="styles.rect4"/>
      <div :class="styles.rect5"/>
    </div>

    <div
      v-if="$props.label"
      :class="styles.label"
    >
      {{ $localize($props.label) }}
    </div>
  </div>
</template>

<script>
  import styles from './loading.scss';

  export default {
    name: 'H4hLoading',

    props: {
      label: [String, Array]
    },

    data() {
      return {
        styles
      };
    }
  };
</script>
