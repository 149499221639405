import { OTHER_OPTION_INPUT_ID, OTHER_OPTION_VALUE } from '@h4h/utils';

import { InputType } from '../constants';

import { validateInput } from './validations';

/**
 * @typedef {Object} InputConfig
 *
 * @property {String}           id
 * @property {InputType}        type
 *
 * @property {*}                value
 * @property {String}           name
 * @property {Boolean}          required
 *
 * @property {Boolean}          valid
 * @property {Boolean}          ignored
 * @property {Boolean}          pristine
 * @property {Boolean}          disabled
 *
 * @property {String}           [icon]
 *
 * @property {String|String[]}  [label]
 * @property {String|String[]}  [placeholder]
 *
 * @property {String|String[]}  [info]
 * @property {String|String[]}  [message]
 * @property {String|String[]}  [warningMessage]
 * @property {String|String[]}  [validationMessage]
 */

/**
 * @param {Object}              config
 *
 * @param {String}              config.id
 * @param {InputType}           config.type
 *
 * @param {*}                   [config.value]
 * @param {String}              [config.name]
 * @param {Boolean}             [config.required]
 *
 * @param {Boolean}             [config.ignored]
 * @param {Boolean}             [config.pristine]
 * @param {Boolean}             [config.disabled]
 *
 * @param {String}              [config.icon]
 *
 * @param {String|String[]}     [config.label]
 * @param {String|String[]}     [config.placeholder]
 *
 * @param {String|String[]}     [config.info]
 * @param {String|String[]}     [config.message]
 * @param {String|String[]}     [config.warningMessage]
 * @param {String|String[]}     [config.validationMessage]
 * @param {Boolean}             [config.prependWithZero]
 *
 * @returns {InputConfig}
 */
export function createInputConfig(config) {
  const { id } = config;

  /**
   * @type {InputConfig}
   */
  const inputConfig = {
    name: id,
    icon: null,
    info: null,
    label: null,
    valid: false,
    ignored: false,
    pristine: true,
    disabled: false,
    required: false,
    message: null,
    warningMessage: null,
    validationMessage: null,
    autofocus: false,

    ...config,

    value: config.value !== undefined ? config.value : null,
    placeholder: getPlaceholder(config)
  };

  inputConfig.valid = validateInput(inputConfig);

  return inputConfig;
}

const INPUTS_WITHOUT_DEFAULT_PLACEHOLDER = [
  InputType.Text,
  InputType.Float,
  InputType.Integer,
  InputType.Textarea
];

const DROPDOWN_INPUTS = [
  InputType.Select,
  InputType.Select2,
  InputType.Typeahead,
  InputType.Typeahead2
];

export function getPlaceholder({ placeholder, label, type }) {
  if (placeholder) {
    return placeholder;
  }

  if (INPUTS_WITHOUT_DEFAULT_PLACEHOLDER.includes(type)) {
    return null;
  }

  if (DROPDOWN_INPUTS.includes(type)) {
    return 'common.select';
  }

  if (InputType.DateTime === type) {
    return 'common.dateTimeFormat';
  }

  if (InputType.Date === type) {
    return 'common.dateFormat';
  }

  if (InputType.Time === type) {
    return 'common.timeFormat';
  }

  if (InputType.File === type) {
    return 'common.dropFileOrBrowse';
  }

  return label || null;
}

/**
 * @param {Object}        config
 * @param {String|Object} config.locale
 */
export function createPeriodInputConfig(config) {
  config = config || {};
  const now = new Date();
  const then = new Date();
  then.setDate(then.getDate() - 7);

  return createInputConfig({
    id: 'period',
    max: now,
    range: true,
    value: [then, now],
    type: InputType.Date,
    placeholder: 'tasks.period',
    locale: config.locale,
  });
}

export const TABLE_SEARCH_INPUT_ID = 'TABLE_SEARCH_INPUT_ID';

// please use this config only for Client Side Search!
// If you need table search with filtering on server side -
// use regular search and apply filters only on Search button click

export function createClientSideTableSearchInputConfig(config = {}) {
  return createInputConfig({
    id: 'search',
    icon: 'search',
    type: InputType.Text,
    label: 'common.search',
    placeholder: 'common.search',
    [TABLE_SEARCH_INPUT_ID]: true,

    ...config,
  });
}

export function createServerSideTableSearchInputConfig(config = {}) {
  return createInputConfig({
    id: 'search',
    icon: 'search',
    type: InputType.Text,
    label: 'common.search',
    placeholder: 'common.search',

    ...config,
  });
}

export function isClientSideTableSearchInput(input) {
  return !!input[TABLE_SEARCH_INPUT_ID];
}

export function createOtherOptionInput(linkedInputId) {
  const id = OTHER_OPTION_INPUT_ID + linkedInputId;
  return createInputConfig({
    id,
    type: InputType.Textarea,
    required: false,
    hidden: true,
    async getStatePatch({ state }) {
      const otherOptionSelected = isOtherOptionSelected(state.inputsMap[linkedInputId]);

      const patch = {
        required: otherOptionSelected,
        hidden: !otherOptionSelected
      };

      if (!otherOptionSelected) {
        patch.value = null;
        patch.pristine = true;
      }

      return patch;
    }
  });
}

export function isOtherOptionSelected(input) {
  return input.multiple
    ? Array.from(input.value).includes(OTHER_OPTION_VALUE)
    : input.value === OTHER_OPTION_VALUE;
}

export function isOtherOptionInputWithParentId(input, parentId) {
  return input.id === (OTHER_OPTION_INPUT_ID + parentId);
}

export function isOtherOptionInput(input) {
  return input.id.includes(OTHER_OPTION_INPUT_ID);
}
