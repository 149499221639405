import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig, getInputsMap, validateTextInput } from '@h4h/inputs';
import { EhPatterns } from '../../../utils/ehPatterns';
import { mapGroupModelsToOptions } from '../../../model/groupModel';
import { ehRoleOptionsSupportedForSelect } from '../../../services/auth/ehRole';
import { EhUserFieldsLengthConstraints } from '../../../utils/ehUserFieldsLengthConstraints';
import { createDisableDirectLoginInputConfig } from '../disableDirectLogin';
import { createPhoneInputConfig } from '../../../utils/phone/phoneComponentUtils';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.INIT_FORM](state, { groups, organizationSetting }) {
    const inputs = getInputsConfig(groups, organizationSetting);

    state.inputs = inputs;
    state.inputsMap = getInputsMap(inputs);
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.CLEAR_INPUTS](state) {
    state.inputs = [];
    state.inputsMap = {};
  },
};

function getInputsConfig(groups, organizationSetting) {
  const bigCode = createTextInputConfig('bigCode', 'common.bigCode', InputType.Text, null, false, EhUserFieldsLengthConstraints.bigCode);
  const email = createTextInputConfig('email', 'common.email', InputType.Text, EhPatterns.email, true, EhUserFieldsLengthConstraints.email);
  const emailAgain = {
    ...createTextInputConfig('emailAgain', 'common.emailAgain', InputType.Text, EhPatterns.email, true, EhUserFieldsLengthConstraints.email),
    validate(input) {
      return validateTextInput(input) && email.value === input.value;
    },
    getStatePatch() {
      return { fakeFieldForUpdate: Math.random() };
    }
  };
  email.linkedInputs = [emailAgain];
  const firstName = createTextInputConfig('firstName', 'common.firstName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.firstName);
  const initials = createTextInputConfig('initials', 'common.initials', InputType.Text, EhPatterns.lettersDotsOnly, false, EhUserFieldsLengthConstraints.initials);
  const lastName = createTextInputConfig('lastName', 'common.lastName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, true, EhUserFieldsLengthConstraints.lastName);
  const middleName = createTextInputConfig('middleName', 'common.middleName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.middleName);
  const partnerMiddleName = createTextInputConfig('partnerMiddleName', 'common.partnerMiddleName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.partnerMiddleName);
  const partnerName = createTextInputConfig('partnerName', 'common.partnerName', InputType.Text, EhPatterns.lettersHyphensSpacesApostropheOnly, false, EhUserFieldsLengthConstraints.partnerName);
  const phone = createPhoneInputConfig(null, 'phone', 'common.telephoneNumber', true);
  const alarmPhoneNumber = createTextInputConfig('alarmPhoneNumber', 'common.alarmPhoneNumber', InputType.Text, EhPatterns.phoneNumberInternational, true);
  const title = createTextInputConfig('title', 'common.title', InputType.Text, EhPatterns.lettersOnly, false, EhUserFieldsLengthConstraints.title);

  const role = createInputConfig({
    id: 'role',
    type: InputType.Select2,
    clearable: false,
    required: true,
    label: 'common.role',
    options: ehRoleOptionsSupportedForSelect,
  });

  const groupsList = createInputConfig({
    id: 'groups',
    type: InputType.Pills,
    label: 'common.groups',
    options: mapGroupModelsToOptions(groups),
    value: new Set(),

    // since pills dont have own validator
    validate: () => true
  });

  const additional = [];
  if (organizationSetting.showDisableDirectLoginSsoDropdown) {
    additional.push(createDisableDirectLoginInputConfig());
  }

  return [
    initials,
    firstName,
    lastName,
    middleName,
    partnerName,
    partnerMiddleName,
    phone,
    alarmPhoneNumber,
    email,
    emailAgain,
    title,
    bigCode,
    ...additional,
    role,
    groupsList,
  ];
}

function createTextInputConfig(id, label, inputType, pattern, required = false, constraints) {
  return createInputConfig({
    id,
    type: inputType,
    pattern,
    required,
    label,
    ...constraints,
  });
}
