import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import {
  healthRecordAdvice,
  healthRecordConclusions,
  healthRecordContactedVia
} from '../../../../../../../constants/healthRecords';
import { localize } from '../../../../../../../services/localize';
import { isReadOnlyUser } from '../../../../../../iframe/getters';

const conclusionsId = 'conclusions';
const adviceId = 'advice';
export const mutations = {
  [types.SET_SAVING](state, value) {
    state.saving = value;
  },

  [types.SET_CONCLUSIONS](state, conclusions) {
    state.conclusions = conclusions;
  },

  [types.SET_PRISTINE](state, { inputs, value }) {
    inputs.forEach(input => {
      input.pristine = value;
    });
  },

  [types.SET_ECG_PDF_LOADING](state, value) {
    state.ecgPdfLoading = value;
  },
  [types.SET_PDF](state, value) {
    state.pdf = value;
  },

  [types.SET_COMMENT](state, commentEntity) {
    state.commentEntity = commentEntity;
  },

  [types.INIT_PAGE](state, { commentEntity = null, ecg }) {
    const { conclusions, contactedVia, advice } = ecg;
    state.ecg = ecg;
    state.commentEntity = commentEntity;
    state.commentInput = createInputConfig({
      id: 'comment',
      value: commentEntity?.value || '',
      required: conclusions.map(c => c.name).includes('OTHER') || advice === 'OTHER',
      label: 'ecg.note',
      otherLabel: 'common.optional',
      type: InputType.Text,
      getStatePatch({ state }) {
        const conclusion = state.conclusion.value;
        const advice = state.advice.value;
        return { required: conclusion.has('OTHER') || advice === 'OTHER' };
      }
    });

    let conclusionInputType = InputType.Select2;
    let conclusionValue = new Set(conclusions.map(conclusion => conclusion.name));
    const conclusionOptions = getKnownConclusionsOptions(state.conclusions);
    if (isReadOnlyUser()) {
      conclusionInputType = InputType.Label;
      conclusionValue = conclusions.map(conclusion => conclusion.name)
        .map(conclusionName => conclusionOptions.find(option => option.value === conclusionName)?.label || conclusionName)
        .join(', ');
    }
    state.conclusion = createInputConfig({
      id: conclusionsId,
      type: conclusionInputType,
      required: false,
      multiple: true,
      label: 'ecg.conclusion',
      placeholder: 'ecg.selectAnOption',
      options: conclusionOptions,
      value: conclusionValue,
      linkedInputs: [state.commentInput],
    });

    state.contactedVia = createInputConfig({
      id: 'contactedVia',
      type: InputType.Select2,
      required: false,
      label: 'ecg.contactedVia',
      placeholder: 'ecg.selectAnOption',
      options: getHealthRecordSelectOptionsFor(healthRecordContactedVia),
      value: contactedVia,
    });

    state.advice = createInputConfig({
      id: adviceId,
      type: InputType.Select2,
      required: false,
      label: 'ecg.advice',
      placeholder: 'ecg.selectAnOption',
      options: getHealthRecordSelectOptionsFor(healthRecordAdvice),
      value: advice,
      linkedInputs: [state.commentInput],
    });
  }
};

/**
 * Maps health record types in {name, translationKey} format to {label, value} format
 * @param {Object} hrObject
 *
 * @returns {{label:string, value:string}[]}
 */
function getHealthRecordSelectOptionsFor(hrObject) {
  return Object.keys(hrObject).map(key => ({
    value: hrObject[key].name,
    label: localize(hrObject[key].translationKey),
  }));
}

/**
 * Maps an array of conclusions that were fetched from the server to
 * the locally known object of conclusions.
 * If an unknown conclusion is found then no translation is provided.
 * @param {Object[]} conclusions
 *
 * @returns {{label:string, value:string}[]}
 */
function getKnownConclusionsOptions(conclusions) {
  const knownConclusionsOptions = getHealthRecordSelectOptionsFor(healthRecordConclusions);
  return conclusions.map(c => {
    return knownConclusionsOptions.find(kc => kc.value === c.name) ||
      { label: c.name, value: c.name };
  });
}

