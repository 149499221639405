export const state = {
  programsLoading: false,
  programs: null,
  serverSideOptions: {},
  loading: false,
  tasksPage: null,
  selectedTasks: [],
  tasksForHandle: [],
  inputs: [],
  inputsMap: { },
  filterParams: null,
};
