/* global TASK_API_URL */

import { CrossTenantCrudService } from '../../../classes/crossTenantCrudService';
import { PractitionerTaskModel } from '../../../model/practitionerTaskModel';
import { PractitionerTasksHandlingStatus } from '../../../constants/practitionerTasks';
import { Severity } from '../../../constants/severity';
import { PageModel } from '../../../model/page';

export const callCenterPractitionerTasks = new CrossTenantCrudService(
  '/call-center/practitioner/tasks',
  data => new PageModel(data, toPractitionerTaskModel),
  toPractitionerTaskRestEntity,
  TASK_API_URL
);

export function toPractitionerTaskModel(data, tenant) {
  return !data ? null : new PractitionerTaskModel({
    ...data,
    severity: data.severity || Severity.INFO,
    status: data.handlingStatus || PractitionerTasksHandlingStatus.UNHANDLED, // maybe rename in future to be more consistent with api
    tenant,
  });
}

export function toPractitionerTaskRestEntity(model) {
  return !model ? null : {
    ...model,
    handlingStatus: model.status,// maybe rename in future to be more consistent with api
  };
}
