const handlers = new Map();
const toBind = new Map();

// @todo: Baizulin - think about a transparent contract
export const clickOutside = {
  name: 'mp-click-outside',

  bind(el, binding, vnode) {
    // setTimeout is used to prevent triggering outside click
    // on the same event that lead to display of a popover
    toBind.set(
      el,
      setTimeout(() => {
        toBind.delete(el);
        handlers.set(el, vnode.context[binding.expression]);

        if (handlers.size === 1) {
          document.addEventListener('click', onDocumentClick);
        }
      }, 0)
    );
  },

  unbind(el) {
    if (toBind.has(el)) {
      clearTimeout(toBind.get(el));
      toBind.delete(el);
    }

    handlers.delete(el);

    if (!handlers.size) {
      document.removeEventListener('click', onDocumentClick);
    }
  }
};

function onDocumentClick(e) {
  const { target } = e;

  handlers.forEach(function(cb, el) {
    if (el !== target && !el.contains(target)) {
      cb(e);
    }
  });
}
