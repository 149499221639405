import styles from '../../styles/exports.scss';

export const BaseColor = Object.freeze({
  ColorPrimary: {
    name: '$color-primary',
    value: styles.colorPrimary
  },
  ColorSecondary: {
    name: '$color-secondary',
    value: styles.colorSecondary
  },
  ColorTertiary: {
    name: '$color-tertiary',
    value: styles.colorTertiary
  },
  ColorQuarternary: {
    name: '$color-quarternary',
    value: styles.colorQuarternary
  },
});
export const MonochromeColor = Object.freeze({
  White: {
    name: '$white',
    value: styles.colorWhite
  },
  Gray: {
    name: '$gray',
    value: styles.colorGray
  },
  Black: {
    name: '$black',
    value: styles.colorBlack
  },
});
export const StatusColor = Object.freeze({
  ColorAlert: {
    name: '$color-alert',
    value: styles.colorAlert
  },
  ColorError: {
    name: '$color-error',
    value: styles.colorError
  },
  ColorSuccess: {
    name: '$color-success',
    value: styles.colorSuccess
  },
});
export const PrimaryColor = Object.freeze({
  ColorPrimary03: {
    name: '$color-primary--03',
    value: styles.colorPrimary03
  },
  ColorPrimary05: {
    name: '$color-primary--05',
    value: styles.colorPrimary05
  },
  ColorPrimary10: {
    name: '$color-primary--10',
    value: styles.colorPrimary10
  },
  ColorPrimary20: {
    name: '$color-primary--20',
    value: styles.colorPrimary20
  },
  ColorPrimary30: {
    name: '$color-primary--30',
    value: styles.colorPrimary30
  },
  ColorPrimary40: {
    name: '$color-primary--40',
    value: styles.colorPrimary40
  },
  ColorPrimary50: {
    name: '$color-primary--50',
    value: styles.colorPrimary50
  },
  ColorPrimary60: {
    name: '$color-primary--60',
    value: styles.colorPrimary60
  },
  ColorPrimary70: {
    name: '$color-primary--70',
    value: styles.colorPrimary70
  },
  ColorPrimary80: {
    name: '$color-primary--80',
    value: styles.colorPrimary80
  },
  ColorPrimary90: {
    name: '$color-primary--90',
    value: styles.colorPrimary90
  },
});
export const SecondaryColor = Object.freeze({
  ColorSecondary03: {
    name: '$color-secondary--03',
    value: styles.colorSecondary03
  },
  ColorSecondary05: {
    name: '$color-secondary--05',
    value: styles.colorSecondary05
  },
  ColorSecondary10: {
    name: '$color-secondary--10',
    value: styles.colorSecondary10
  },
  ColorSecondary20: {
    name: '$color-secondary--20',
    value: styles.colorSecondary20
  },
  ColorSecondary30: {
    name: '$color-secondary--30',
    value: styles.colorSecondary30
  },
  ColorSecondary40: {
    name: '$color-secondary--40',
    value: styles.colorSecondary40
  },
  ColorSecondary50: {
    name: '$color-secondary--50',
    value: styles.colorSecondary50
  },
  ColorSecondary60: {
    name: '$color-secondary--60',
    value: styles.colorSecondary60
  },
  ColorSecondary70: {
    name: '$color-secondary--70',
    value: styles.colorSecondary70
  },
  ColorSecondary80: {
    name: '$color-secondary--80',
    value: styles.colorSecondary80
  },
  ColorSecondary90: {
    name: '$color-secondary--90',
    value: styles.colorSecondary90
  },
});
export const TertiaryColor = Object.freeze({
  ColorTertiary03: {
    name: '$color-tertiary--03',
    value: styles.colorTertiary03
  },
  ColorTertiary05: {
    name: '$color-tertiary--05',
    value: styles.colorTertiary05
  },
  ColorTertiary10: {
    name: '$color-tertiary--10',
    value: styles.colorTertiary10
  },
  ColorTertiary20: {
    name: '$color-tertiary--20',
    value: styles.colorTertiary20
  },
  ColorTertiary30: {
    name: '$color-tertiary--30',
    value: styles.colorTertiary30
  },
  ColorTertiary40: {
    name: '$color-tertiary--40',
    value: styles.colorTertiary40
  },
  ColorTertiary50: {
    name: '$color-tertiary--50',
    value: styles.colorTertiary50
  },
  ColorTertiary60: {
    name: '$color-tertiary--60',
    value: styles.colorTertiary60
  },
  ColorTertiary70: {
    name: '$color-tertiary--70',
    value: styles.colorTertiary70
  },
  ColorTertiary80: {
    name: '$color-tertiary--80',
    value: styles.colorTertiary80
  },
  ColorTertiary90: {
    name: '$color-tertiary--90',
    value: styles.colorTertiary90
  },
});
export const QuarternaryColor = Object.freeze({
  ColorQuarternary03: {
    name: '$color-quarternary--03',
    value: styles.colorQuarternary03
  },
  ColorQuarternary05: {
    name: '$color-quarternary--05',
    value: styles.colorQuarternary05
  },
  ColorQuarternary10: {
    name: '$color-quarternary--10',
    value: styles.colorQuarternary10
  },
  ColorQuarternary20: {
    name: '$color-quarternary--20',
    value: styles.colorQuarternary20
  },
  ColorQuarternary30: {
    name: '$color-quarternary--30',
    value: styles.colorQuarternary30
  },
  ColorQuarternary40: {
    name: '$color-quarternary--40',
    value: styles.colorQuarternary40
  },
  ColorQuarternary50: {
    name: '$color-quarternary--50',
    value: styles.colorQuarternary50
  },
  ColorQuarternary60: {
    name: '$color-quarternary--60',
    value: styles.colorQuarternary60
  },
  ColorQuarternary70: {
    name: '$color-quarternary--70',
    value: styles.colorQuarternary70
  },
  ColorQuarternary80: {
    name: '$color-quarternary--80',
    value: styles.colorQuarternary80
  },
  ColorQuarternary90: {
    name: '$color-quarternary--90',
    value: styles.colorQuarternary90
  },
});
export const GrayColor = Object.freeze({
  ColorGray00: {
    name: '$gray--00',
    value: styles.colorGray00
  },
  ColorGray01: {
    name: '$gray--01',
    value: styles.colorGray01
  },
  ColorGray03: {
    name: '$gray--03',
    value: styles.colorGray03
  },
  ColorGray05: {
    name: '$gray--05',
    value: styles.colorGray05
  },
  ColorGray10: {
    name: '$gray--10',
    value: styles.colorGray10
  },
  ColorGray20: {
    name: '$gray--20',
    value: styles.colorGray20
  },
  ColorGray30: {
    name: '$gray--30',
    value: styles.colorGray30
  },
  ColorGray40: {
    name: '$gray--40',
    value: styles.colorGray40
  },
  ColorGray50: {
    name: '$gray--50',
    value: styles.colorGray50
  },
  ColorGray60: {
    name: '$gray--60',
    value: styles.colorGray60
  },ColorGray70: {
    name: '$gray--70',
    value: styles.colorGray70
  },
  ColorGray80: {
    name: '$gray--80',
    value: styles.colorGray80
  },
  ColorGray90: {
    name: '$gray--90',
    value: styles.colorGray90
  },
});
export const SupportColor = Object.freeze({
  ColorSupportYellow: {
    name: '$support--yellow',
    value: styles.colorSupportYellow
  },
  ColorSupportYellowOrange: {
    name: '$support--yellow-orange',
    value: styles.colorSupportYellowOrange
  },
  ColorSupportOrange: {
    name: '$support--orange',
    value: styles.colorSupportOrange
  },
  ColorSupportOrangeRed: {
    name: '$support--orange-red',
    value: styles.colorSupportOrangeRed
  },
  ColorSupportRed: {
    name: '$support--red',
    value: styles.colorSupportRed
  },
  ColorSupportRedMagenta: {
    name: '$support--red-magenta',
    value: styles.colorSupportRedMagenta
  },
  ColorSupportMagenta: {
    name: '$support--magenta',
    value: styles.colorSupportMagenta
  },
  ColorSupportBlueMagenta: {
    name: '$support--blue-magenta',
    value: styles.colorSupportBlueMagenta
  },
  ColorSupportBlue: {
    name: '$support--blue',
    value: styles.colorSupportBlue
  },
  ColorSupportCyan: {
    name: '$support--cyan',
    value: styles.colorSupportCyan
  },
  ColorSupportGreenCyan: {
    name: '$support--green-cyan',
    value: styles.colorSupportGreenCyan
  },
  ColorSupportGreen: {
    name: '$support--green',
    value: styles.colorSupportGreen
  },
});
export const OtherColor = Object.freeze({
  ColorMainDark: {
    name: '$main-dark',
    value: styles.colorMainDark
  },
  ColorLogo: {
    name: '$logoColor',
    value: styles.colorLogo
  },
  ColorSub1: {
    name: '$sub1',
    value: styles.colorSub1
  },
  ColorSub2: {
    name: '$sub2',
    value: styles.colorSub2
  },
  ColorBlueDark1: {
    name: '$blue-dark1',
    value: styles.colorBlueDark1
  },
  ColorBlueDark2: {
    name: '$blue-dark2',
    value: styles.colorBlueDark2
  },
  ColorDropdownLink: {
    name: '$dropdownLinkColor_hover',
    value: styles.colorDropdownLink
  }
});
export const Colors = Object.freeze({
  BaseColor: {
    name: 'Base colors',
    value: Object.values(BaseColor)
  },
  MonochromeColor: {
    name: 'Monochrome colors',
    value: Object.values(MonochromeColor)
  },
  StatusColor: {
    name: 'Status colors',
    value: Object.values(StatusColor)
  },
  PrimaryColor: {
    name: 'Primary colors',
    value: Object.values(PrimaryColor)
  },
  SecondaryColor: {
    name: 'Secondary colors',
    value: Object.values(SecondaryColor)
  },
  TertiaryColor: {
    name: 'Tertiary colors',
    value: Object.values(TertiaryColor)
  },
  QuarternaryColor: {
    name: 'Quarternary colors',
    value: Object.values(QuarternaryColor)
  },
  GrayColor: {
    name: 'Gray colors',
    value: Object.values(GrayColor)
  },
  SupportColor: {
    name: 'Support colors',
    value: Object.values(SupportColor)
  },
  OtherColor: {
    name: 'Other colors',
    value: Object.values(OtherColor)
  }
});
export const colorOptions = Object.values(Colors);
