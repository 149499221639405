export const stopEnrollmentReasons = {
  consistent_arrhythmia_and_treated_optimally: 'Consistent arrhythmia and treated optimally',
  arrhythmia_can_not_be_determined: 'Arrhythmia can not be determined',
  arrhythmia_is_cured: 'Arrhythmia is cured',
  heart_failure_is_optimally_treated: 'Heart failure treatment sufficiently optimized',
  heart_failure_is_cured: 'Heart failure is cured',
  hypertension_is_determined_diagnosed: 'Hypertension is accepted',
  hypertension_is_cured: 'Hypertension is cured',
  practitioner_no_added_value: 'Practitioner: no added value',
  patient_no_added_value: 'Patient: no added value',
  patient_is_unsatisfied_with_ehealth_service: 'Patient is unsatisfied with eHealth service',
  patient_is_unsatisfied_with_ehealth_technology: 'Patient is unsatisfied with eHealth technology',
  ehealth_is_too_difficult_for_the_patient: 'eHealth is too difficult for the patient',
  patient_thinks_it_is_too_expensive: 'Patient thinks it is too expensive',
  patient_does_not_want_to_pay_for_ehealth: 'Patient does not want to pay for eHealth',
  patient_never_started_code_is_never_activated: 'Patient never started / code is never activated',
  patient_moved_to_a_different_hospital: 'Patient moved to a different care provider',
  patient_changed_their_health_insurance: 'Patient changed their health insurance',
  patient_is_inactive: 'Patient is inactive',
};
