export class EnhancedQuestionnaireResponseItemModel {
  /**
   * EnhancedQuestionnaireResponseItemModel config
   *
   * @param {Object}                     config
   * @param {QuestionnaireResponseItem}  config.questionnaireResponseItem
   * @param {Object}                     config.value
   */
  constructor(config) {
    this.questionnaireResponseItem = config.questionnaireResponseItem;
    this.value = config.value;
  }
}
