<template>
  <div>
    <Popup
      v-for="popup in list"

      :id="popup.id"
      :key="popup.id"
      :type="popup.type"
      :name="popup.name"
      :dataTestId="popup.dataTestId"
      :props="popup.props"
      :closeOnClickOutside="popup.closeOnClickOutside"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import Popup from './Popup';

  export default {
    name: 'H4hPopups',

    components: {
      Popup
    },

    computed: mapState('popups', ['list']),

    created() {
      window.addEventListener('keydown', this.onKeydown);
    },

    beforeDestroy() {
      window.removeEventListener('keydown', this.onKeydown);
    },

    methods: {
      onKeydown(e) {
        if (e.key === 'Escape' && this.list.length) {
          this.$popups.hide({ id: this.list[this.list.length - 1].id });
        }
      }
    }
  };
</script>
