import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { QuestionnaireModel } from '../../model/anamnesis/questionnaireModel';
import { QuestionnaireWrapper } from '../../components/questionnaire/wrapper/questionnaireWrapper';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  /**
   *
   * @param state
   * @param {AnamnesisComponentObjectsModel} anamnesisComponentObjectsModel
   */
  [types.SET_ANAMNESIS_DATA](state, anamnesisComponentObjectsModel) {
    if (!anamnesisComponentObjectsModel) {
      state.patientId = null;
      state.questionnaireTemplate = null;
      state.questionnaire = null;
      state.anamnesis = null;
      state.inputs = [];
      state.secondaryInputs = [];
      state.terminologyTypes = [];
      return;
    }
    const questionnaireTemplate = anamnesisComponentObjectsModel.questionnaireTemplate;
    state.patientId = anamnesisComponentObjectsModel.patientId;
    state.questionnaireTemplate = questionnaireTemplate;
    state.anamnesis = anamnesisComponentObjectsModel.anamnesis;
    state.questionnaire = state.anamnesis.questionnaire;
    if (!state.questionnaire) {
      state.questionnaire = new QuestionnaireModel({
        questionnaireTemplateId: questionnaireTemplate.uuid,
        patientUuid: anamnesisComponentObjectsModel.patientId,
        questionnaireIdempotencyKey: '' + Math.random(),
      });
    }
    const wrapper = new QuestionnaireWrapper(questionnaireTemplate, state.questionnaire);
    state.inputs = wrapper.getInputs(anamnesisComponentObjectsModel.terminologyTypes);
    state.terminologyTypes = anamnesisComponentObjectsModel.terminologyTypes;

    state.secondaryInputs = [createInputConfig({
      id: 'commentByMedicalPersonnel',
      type: InputType.Textarea,
      required: false,
      value: state.questionnaire.commentByMedicalPersonnel || null,
      label: 'anamnesis.commentByMedicalPersonnel',
    })];
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

};
