export const questionnaireTemplates = {
  questionnaireTemplates: 'Vragenlijst Sjablonen',
  questionnaireTemplate: 'Sjabloon voor vragenlijst',
  addQuestionnaireTemplate: 'Vragenlijst sjabloon toevoegen',

  terminologyType: 'Type terminologie',
  questionsCount: 'Vragen tellen',
  questions: 'Vragen',

  addQuestion: 'Vraag toevoegen',
  answerTerminologyUnit: 'Terminologie-eenheid beantwoorden',
  questionTerminologyCode: 'Vraag terminologiecode',
  answerType: 'Antwoordtype',
  answersValues: 'Antwoordwaarden',

  addAnswer: 'Antwoord toevoegen',
  answer: 'Antwoord',
  answers: 'Antwoorden',
  atLeastOneAnswerIsRequired: 'Er is minimaal één antwoord vereist',
  constrains: 'Beperkingen',
  minMaxShouldBeDefinedOrNot: 'Min en Max moeten worden gedefinieerd of niet tegelijkertijd worden gedefinieerd.',
  minShouldBeLessMax: 'Min zou minder moeten zijn dan Max.',
  thisQuestionAlreadyAdded: 'Deze vraag is al toegevoegd',
};
