export const group = {
  groups: 'Groepen',
  defaultForRole: 'Standaardrol',
  addGroup: 'Groep toevoegen',
  editGroup: 'Groep bewerken',
  deleteGroup: 'Groep verwijderen',
  deleteGroupConsent: 'Wilt u de groep verwijderen?',
  Admins: 'Beheerders',
  POHs: 'POHs',
  Physicians: 'Artsen',
  atLeastOneGroupIsRequired: 'Er is minimaal één groep vereist',
};
