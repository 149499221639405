import { mapArray } from '../utils/mapUtils';

export class MandatoryPatientAction {
  /**
   * MandatoryPatientAction config
   *
   * @param {Object}        config
   * @param {String}        config.actionId
   * @param {Appearance[]}  config.appearance
   * @param {Field[]}       config.fields
   * @param {String}        config.subtitle
   * @param {String}        config.title
   */
  constructor(config) {
    this.actionId = config.actionId;
    this.appearance = mapArray(config.appearance, Appearance) ;
    this.fields = mapArray(config.fields, Field);
    this.numberExtension = config.numberExtension;
    this.subtitle = config.subtitle;
    this.title = config.title;
  }
}

export class Appearance {
  /**
   * Appearance config
   *
   * @param {Object}    config
   * @param {String}    config.color
   */
  constructor(config) {
    this.color = config.color;
  }
}

export class Field {
  /**
   * MandatoryPatientAction config
   *
   * @param {Object}        config
   * @param {String}        config.inputType [ NUMERIC, INT, STRING, ENUM, BOOLEAN, LOCAL_DATE, LOCAL_TIME, LOCAL_DATE_TIME, DATE_TIME ]
   * @param {String}        config.label
   * @param {String}        config.name
   * @param {Number}        config.order
   * @param {Object[]}      config.possibleValues
   * @param {Validation[]}  config.validation
   */
  constructor(config) {
    this.inputType = config.inputType;
    this.label = config.label;
    this.name = config.name;
    this.order = config.order;
    this.possibleValues = config.possibleValues;
    this.validation = mapArray(config.validation, Validation);
  }
}

export class Validation {
  /**
   * Validation config
   *
   * @param {Object}    config
   * @param {String}    config.maxLength
   * @param {String}    config.regex
   * @param {Boolean}   config.required
   */
  constructor(config) {
    this.maxLength = config.maxLength;
    this.regex = config.regex;
    this.required = config.required;
  }
}

