import { uniqueId } from 'lodash';

import { PopupType, PopupName } from '../constants';

/**
 * @returns {Object} - Vuex module
 */
export function createStoreModule() {
  return {
    namespaced: true,

    state: {
      list: []
    },

    actions: {
      /**
       * @param commit
       * @param {Object} config
       * @param {String} config.name
       * @param {String} config.type
       * @param {Object} [config.props]
       */
      show({ commit }, config) {
        const popupConfig = {
          ...config,
          id: uniqueId('popup-')
        };

        commit('show', popupConfig);

        return popupConfig;
      },

      /**
       * @param store                              store context
       * @param {Object}          modalProps       Object with props compatible with src/components/modal/Modal.vue
       *  @param {String}         props.dataTestId
       *  @param {String|Array}   props.title
       *  @param {String|Array}   props.subtitle
       *  @param {String|Array}   props.message
       *  @param {Object[]}       props.buttons
       *  @param {String}         props[className]
       *  @param {ComponentStyle} props[modalStyle]
       *  @param {Boolean}        props[isMsgHtml]
       */
      showModal({ commit }, { dataTestId, title, subtitle, message, buttons, className, modalStyle, isMsgHtml }) {
        const popupConfig = {
          id: uniqueId('popup-'),
          type: PopupType.Modal,
          name: PopupName.Modal,
          dataTestId,
          props: {
            title,
            subtitle,
            message,
            buttons,
            className,
            isMsgHtml,
            modalStyle // Not used currently.
          }
        };

        commit('show', popupConfig);

        return popupConfig;
      },

      hide({ commit }, { id, result }) {
        commit('hide', { id, result });
      }
    },

    mutations: {
      show(state, popupConfig) {
        state.list.push(popupConfig);
      },

      hide(state, { id }) {
        state.list = state.list.filter(p => p.id !== id);
      }
    }
  };
}
