import { localizeIfExistsOrDefault } from '../services/localize';
import { mapArray } from '../utils/mapUtils';
import { ehRoleOptions } from '../services/auth/ehRole';
import { apiRoleMapper } from '../services/auth/apiRoleMapper';
import { formatName } from '../utils/formatters/fullNameFormatter';

export class CallCenterUserModel {
  /**
   * UserModel config
   *
   * @param {Object}      config
   * @param {String}      config.uuid
   * @param {String}      config.email
   * @param {String}      config.firstName
   * @param {String}      config.gender
   * @param {String}      config.iamId
   * @param {String}      config.lastName
   * @param {String}      config.middleName
   * @param {String}      config.phone
   * @param {String}      config.roles
   *
   * @param {String}      config.alarmPhoneNumber
   * @param {String}      config.bigCode
   * @param {String}      config.initials
   * @param {String}      config.partnerMiddleName
   * @param {String}      config.partnerName
   * @param {String}      config.title
   * @param {String}      config.visibleCountry
   */
  constructor(config) {
    config = config || {};
    this.email = config.email;
    this.firstName = config.firstName;
    this.gender = config.gender;
    this.iamId = config.iamId;
    this.lastName = config.lastName;
    this.middleName = config.middleName;
    this.phone = config.phone;
    this.roles = mapArray(config.roles, String);
    this.uuid = config.uuid;

    this.alarmPhoneNumber = config.alarmPhoneNumber;
    this.bigCode = config.bigCode;
    this.initials = config.initials;
    this.partnerMiddleName = config.partnerMiddleName;
    this.partnerName = config.partnerName;
    this.title = config.title;
    this.visibleCountry = config.visibleCountry;

  }

  get rolesString() {
    return this.roles
      .map(role => localizeRole(role))
      .sort()
      .join(', ');
  }

  // used as key-value for sorting
  get 'userRoles.role'() {
    return this.rolesString;
  }

  hasRole(ehRole) {
    return this.roles.some(role => apiRoleMapper.fromApi(role) === ehRole);
  }

  formatName(ordering) {
    return formatName(this.firstName, this.middleName, this.lastName, ordering);
  }
}

function localizeRole(roleName) {
  let options = ehRoleOptions
    .filter(opt => opt.value === apiRoleMapper.fromApi(roleName))
    .map(opt => opt.label);
  return localizeIfExistsOrDefault(options[0], roleName);
}
