export const getters = {
  observationThresholds,
};

function observationThresholds(state) {
  const program = state.program;
  if (program) {
    return program.observationThresholds || [];
  }
  return [];
}
