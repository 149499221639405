import { otherPredefinedReason } from './stopEnrollmentReasonsCodes';

export function stopEnrollmentInputs(state) {
  let stopEnrollmentReasonsInput = state.stopEnrollmentReasonsInput;
  if (stopEnrollmentReasonsInput.value === otherPredefinedReason) {
    return [state.endDateInput, stopEnrollmentReasonsInput, state.reasonInput];
  }
  else {
    return [state.endDateInput, stopEnrollmentReasonsInput];
  }
}
