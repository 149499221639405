<template>
  <div :class="styles.reviewPdfContainer">
    <div
      v-if="!$props.pdf"
      :class="styles.reviewPdfError"
    >
      {{ $localize('toastMessage.cantDownloadEntity', 'ehr.file') }}

      <Icon
        :class="styles.reviewPdfRetryDL"
        type="rotate-cw"
        :title="$localize('common.retry')"
        @click="$emit('retry')"
      />
    </div>

    <div
      v-else
      :class="[styles.reviewPdf, $props.pdfViewClass]"
    >
      <embed :src="fileSource"/>
    </div>
  </div>
</template>

<script>
  import styles from './pdfView.scss';

  export default {
    name: 'H4hPdfView',

    props: {
      pdf: {
        type: Blob,
      },
      pdfViewClass: String
    },

    data() {
      return {
        styles
      };
    },

    computed: {
      fileSource() {
        return URL.createObjectURL(this.$props.pdf) + '#toolbar=1&navpanes=0&scrollbar=0&messages=0&view=FitH&pagemode=none';
      }
    }
  };
</script>
