import { NameOrdering } from '../../constants/nameOrdering';

export function fullName({ firstName, middleName, lastName }) {
  return formatName(firstName, middleName, lastName);
}

export function formatName(firstName, middleName, lastName, ordering) {
  let formattedName = [];
  ordering = ordering || NameOrdering.FirstNameMiddleNameLastName;
  switch (ordering) {
    case NameOrdering.FirstNameMiddleNameLastName:
      formattedName = [firstName, middleName, lastName];
      break;
    case NameOrdering.FirstNameLastNameMiddleName:
      formattedName = [firstName, lastName, middleName];
      break;
    case NameOrdering.LastNameMiddleNameFirstName:
      formattedName = [lastName, middleName, firstName];
      break;
    case NameOrdering.LastNameFirstNameMiddleName:
      formattedName = [lastName, firstName, middleName];
      break;
  }

  return formattedName
    .filter(name => !!name)
    .join(' ');
}
