import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { state } from './state';
import { withInputs } from '@h4h/inputs';

export const stop = withInputs({
  namespaced: true,

  actions,
  getters,
  mutations,
  state
});
