let mouseY;
let isIframeProvider;
document.addEventListener('mousemove', event => {
  mouseY = event.clientY;
});
export function evalPopupTopForIframeIfNeed(offsetForSubstract) {
  if (isIframeProvider && isIframeProvider() && mouseY) {
    const top = (mouseY - (offsetForSubstract || 0));
    if (top > 0) {
      return {
        customTop: (mouseY - (offsetForSubstract || 0)) + 'px',
      };
    }
  }
  return {
  };
}

export function evalPopupTopForIframeIfNeedPlugin(store) {
  isIframeProvider = () => store.getters['iframe/isIframe'];
}
