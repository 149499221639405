export class TabIndexSubscriber {
  /**
   * @param {(tabIndex: number) => void} next
   * @param {Object} [options]
   * @param {String} [options.groupId]
   * @param {String} [options.tabIndex]
   */
  constructor(next, options = {}) {
    this.next = next;
    this.groupId = options.groupId;
    this.tabIndex = options.tabIndex || 0;
  }
}
