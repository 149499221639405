import { types } from './types';
import { popups } from '../../../../services/popups';
import { PopupName } from '../../../../constants/popupName';
import { PopupType } from '@h4h/popups';

export const actions = Object.freeze({
  initPopup,
  save,
  cancel,
});

async function initPopup({ commit }, { terminologyTypes, answers }) {
  const { id, closed } = await popups.show({
    name: PopupName.AddQuestionDefinitionAnswer,
    type: PopupType.Modal
  });
  commit(types.INIT_POPUP, {
    popupId: id,
    terminologyTypes,
    answers
  });
  return { closed };
}

async function save({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }
  popups.hide({ id: state.popupId, result: getters.model });
}

async function cancel({ state }) {
  popups.hide({ id: state.popupId });
}
