import { list } from './list';
import { add } from './add';
import { editObservationTemplatePopup } from './editObservationTemplatePopup';
import { deleteLabResultGroupPopup } from './deleteLabResultGroupPopup';
import { edit } from './edit';

export const labResultGroups = {
  namespaced: true,
  modules: {
    list,
    add,
    editObservationTemplatePopup,
    deleteLabResultGroupPopup,
    edit,
  }
};
