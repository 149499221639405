import { EhPatterns } from './ehPatterns';

export const EhAddressCustomizations = Object.freeze({
  street: { max: 50 },
  city: { max: 50 },
  number: { max: 10 },
  numberExtension: {
    max: 30,
    required: false,
  },
  postalCode: {
    max: 30,
    required: true,
    pattern: EhPatterns.postalCode,
  },
});

