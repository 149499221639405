/* global BASE_PATH */

import { Page } from '../../constants/page';

import { forceAuth, setXTenantIdDefaultHeader } from '../auth/auth';

import { routes } from './routes';
import { Router } from './routerImpl';

function beforeEachHandler(to) {
  if (to.params.tenantId) {
    setXTenantIdDefaultHeader(to.params.tenantId);
  }
}

export const router = new Router({
  forbiddenPage: Page.Forbidden,
  basePath: BASE_PATH,
  getAuth: forceAuth,
  routes,
  beforeEachHandler,
});
