<script>
  import { isNil, omitBy } from 'lodash';
  import feather from 'feather-icons';

  import { icons } from '../icons';

  import './styles.scss';

  export default {
    name: 'H4hIcon',

    props: {
      type: {
        type: String,
        required: true
      },

      fill: {
        type: String
      },

      stroke: {
        type: String
      },

      strokeWidth: {
        type: Number
      },

      strokeLinecap: {
        type: String
      },

      strokeLinejoin: {
        type: String
      },

      title: {
        type: [String, Array]
      },

      disabled: Boolean
    },

    computed: {
      commonAttrs() {
        const {
          fill,
          stroke,
          strokeWidth,
          strokeLinecap,
          strokeLinejoin
        } = this.$props;

        return omitBy({
          fill,
          stroke,
          'stroke-width': strokeWidth,
          'stroke-linecap': strokeLinecap,
          'stroke-linejoin': strokeLinejoin
        }, isNil);
      }
    },

    render(createElement) {
      const { type, disabled } = this.$props;

      const customIcon = icons[type];
      const featherIcon = feather.icons[type];

      let iconComponent = '';

      if (customIcon) {
        iconComponent = createElement(customIcon.icon, {
          attrs: {
            ...customIcon.attrs,
            ...this.commonAttrs
          },
          class: 'mp-icon__content'
        });
      }
      else if (featherIcon) {
        iconComponent = createElement('svg', {
          attrs: {
            ...featherIcon.attrs,
            ...this.commonAttrs,
          },
          class: `${ featherIcon.attrs.class } mp-icon__content`,
          domProps: {
            innerHTML: featherIcon.contents
          }
        });
      }
      else {
        // eslint-disable-next-line no-console
        console.error(`Unknown icon "${ JSON.stringify(type) }". See available icons: ${ window.location.origin }/icons-showcase`);
      }

      return createElement(
        'i',
        {
          class: ['mp-icon', `mp-icon__${ type }`, disabled && 'disabled'],
          attrs: {
            title: this.$localize(this.$props.title),
          },
          on: this.$listeners,
        },
        [iconComponent]
      );
    }
  };
</script>
