<template>
  <div :class="inputStyles.container">
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />

    <div
      :class="[
        styles.inputGroup,
        inputStyles.inputGroup,
        {
          disabled: $props.disabled,
          invalid: !$props.pristine && !$props.valid,
          warning: $props.warning
        }
      ]"
    >
      <slot name="actionBar">
        <WysiwygDefaultActionBar
          :actions="actions"
          :disabled="$props.disabled"
          @action="onAction"
        />
      </slot>

      <div
        ref="input"
        :class="[inputStyles.input, styles.input]"
        :tabindex="tabIndex"
        @focus="tabIndexSubscriber__onFocus"
        @keydown="onKeyDown"
        @mousedown="onMouseDown"
      >
        <div
          ref="content"
          contenteditable="true"
          :class="styles.content"
          :data-testid="`field=${$props.name}`"
          @input="onChange"
          @click="onContentClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { get, debounce, isElement } from 'lodash';

  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import {
    urlRegex,
    getSelection,
    TAG_ID_PREFIX,
    WysiwygActionType,
    getSelectionRange,
    insertElementAtRange,
    convertCustomTagsToHtml,
    convertCustomTagsToBbCode
  } from '../../utils/wysiwygUtils';

  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';

  import TextArea from '../TextArea';
  import LabelGroup from '../labelGroup/LabelGroup';

  import styles from './wysiwyg.scss';
  import WysiwygTag, { UserAction } from './WysiwygTag';
  import WysiwygDefaultActionBar from './WysiwygDefaultActionBar';

  export default {
    name: 'H4hWysiwyg',

    components: {
      WysiwygDefaultActionBar,
      LabelGroup
    },

    mixins: [
      InputMixin
    ],

    props: {
      ...TextArea.props,

      tags: Array,

      value: String,

      actions: WysiwygDefaultActionBar.props.actions,

      /** labels */
      missingTagInfo: {
        type: String,
        default: ''
      },

      /** flags */
      displayMissingTags: inputProps.booleanTrue
    },

    data() {
      return {
        styles,
        inputStyles,
        lastRange: null,
        customTagElements: new Set(),
        customTagInstances: new Set(),
        // remember last user action to pass it down to
        // WysiwygTag.selectionChange if the selection changes
        lastUserAction: null
      };
    },

    computed: {
      input() {
        return this.$refs.input;
      },

      content() {
        return this.$refs.content;
      },

      allowedTagIds() {
        return !this.$props.displayMissingTags
          ? this.$props.tags.map(tag => tag.id)
          : null;
      }
    },

    watch: {
      ['$props.value']() {
        const { value } = this.$props;

        if (value !== this.getValue()) {
          this.setValue(value);
        }
      },

      ['$props.disabled']() {
        this.updateDisabledStatus();
      }
    },

    mounted() {
      this.updateDisabledStatus();

      this.setValue(this.$props.value);
      this.content.addEventListener('cut', this.onCut);
      this.content.addEventListener('copy', this.onCopy);
      this.content.addEventListener('paste', this.onPaste);
      document.addEventListener('selectionchange', this.onDocumentSelectionChange);
      this.$emit('change', this.getValue());
    },

    destroyed() {
      this.customTagInstances.forEach(this.unregisterCustomTag);

      this.content.removeEventListener('cut', this.onCut);
      this.content.removeEventListener('copy', this.onCopy);
      this.content.removeEventListener('paste', this.onPaste);
      document.removeEventListener('selectionchange', this.onDocumentSelectionChange);
    },

    methods: {
      // this one has to be a method because computed property's
      // return value caching prevents us from getting an actual value
      getHtml() {
        return get(this.content, 'innerHTML');
      },

      getValue() {
        const html = this.getHtml();
        return html && convertCustomTagsToBbCode(html);
      },

      setValue(value) {
        this.content.innerHTML = value ? convertCustomTagsToHtml(value, this.allowedTagIds) : '';
        this.initCustomTags(this.content);
      },

      focus() {
        if (this.lastRange) {
          this.restoreLastRange();
        }
        else {
          this.content.focus();
        }
      },

      restoreLastRange() {
        const selection = getSelection();
        selection.removeAllRanges();
        selection.addRange(this.lastRange);
      },

      updateDisabledStatus() {
        this.content.contentEditable = !this.$props.disabled;
      },

      registerCustomTag(instance) {
        this.customTagInstances.add(instance);
        this.customTagElements.add(instance.$el);
      },

      unregisterCustomTag(instance) {
        this.customTagInstances.delete(instance);
        this.customTagElements.delete(instance.$el);
        instance.discard();
      },

      isCustomTag(el) {
        return this.customTagElements.has(el);
      },

      /**
       * Checks whether el is an Element, exposed for testing purposes
       * @param el
       * @returns {el is Element}
       */
      isElement(el) {
        return isElement(el);
      },

      isLineBreak(el) {
        return this.isElement(el) && el.nodeName === 'BR';
      },

      isListElement(el) {
        return this.isElement(el) && el.nodeName === 'LI';
      },

      /**
       * Checks whether el is an Element that is rendered as a block
       * @param el
       * @returns {boolean}
       */
      isBlockElement(el) {
        return this.isElement(el) && window.getComputedStyle(el).display === 'block';
      },

      /**
       * Checks whether el is not an element or a line break or an element with empty content
       * @param el
       * @returns {boolean}
       */
      isEmpty(el) {
        return !this.isElement(el) || this.isLineBreak(el) || Array.from(el.children)
          .every(child => this.isEmpty(child));
      },

      initCustomTags(el) {
        el
          .querySelectorAll(`[data-tag-id^="${ TAG_ID_PREFIX }"]`)
          .forEach(node => {
            const id = node.getAttribute('data-tag-id').substr(TAG_ID_PREFIX.length);
            node.replaceWith(this.createTagElement(id));
          });
      },

      createLineBreak() {
        return document.createElement('br');
      },

      createTagElement(id) {
        let tagConfig = this.$props.tags.find(t => t.id === id);

        if (!tagConfig) {
          tagConfig = {
            id,
            missingTag: true,
            label: id,
            info: this.$props.missingTagInfo
          };
        }
        const instance = new Vue({
          ...WysiwygTag,
          propsData: {
            ...tagConfig,
            onRemove: this.onCustomTagRemove,
            isDisabled: () => this.$props.disabled
          }
        });

        instance.$mount();
        this.registerCustomTag(instance);

        return instance.$el;
      },

      insertCustomTag(id) {
        this.focus();
        this.insertElement(this.createTagElement(id));
        this.onChange();
      },

      insertElement(el) {
        if (!this.lastRange) {
          const range = getSelectionRange();
          this.lastRange = range || null;
        }
        insertElementAtRange(el, this.lastRange);

        this.lastRange.setStartAfter(el);
        this.lastRange.setEndAfter(el);
      },

      extractContents(nodes) {
        const fragment = new DocumentFragment();

        // we have to copy childNodes list because append mutates the original list
        Array.from(nodes).forEach(node => fragment.append(node));

        return fragment;
      },

      onContentClick(e) {
        if (e.target.localName === 'a') {
          this.openInNewTab(e.target.href);
        }
      },

      onChange() {
        this.linkify();
        this.removeSpans();
        this.replaceDivsWithBrs();
        this.addLineBreaks();
        this.addTrailingLineBreak();
        this.cleanOrphanedCustomTags();
        this.cleanupCustomTags();

        this.$emit('change', this.getValue());
      },

      removeSpans() {
        this.content.querySelectorAll('span').forEach(node => {
          if (Array.from(this.customTagElements).find(el => el.contains(node))) {
            return;
          }

          node.remove();
        });
      },

      replaceDivsWithBrs() {
        Array.from(this.content.querySelectorAll('div')).forEach(node => {
          if (this.isCustomTag(node)) {
            return;
          }

          const fragment = this.extractContents(node.childNodes);

          if (!this.isLineBreak(fragment.lastChild)) {
            fragment.append(this.createLineBreak());
          }

          node.replaceWith(fragment);
        });
      },

      linkify: debounce(function() {
        this.findSelectedNodes().map(node => {
          const newNode = document.createElement('div');
          let match = null;
          let text = node.data;
          let textBefore = '';
          let textAfter = '';

          // eslint-disable-next-line no-cond-assign
          while (match = urlRegex.exec(text)?.[0]) {
            const link = this.createLink(match);
            [textBefore, textAfter] = node.data.split(match);

            text = textAfter;
            newNode.appendChild(document.createTextNode(textBefore));
            newNode.appendChild(link);
          }

          textAfter && newNode.appendChild(document.createTextNode(textAfter));
          newNode.children.length && node.parentNode.replaceChild(newNode, node);
        });
      }, 500),

      addLineBreaks() {
        // add line breaks before each block element to prevent cursor issues with custom tags at the end of the line
        Array.from(this.content.children)
          .filter(el =>
            this.isBlockElement(el) &&
            el !== this.content.firstChild &&
            !this.isLineBreak(el.previousSibling)
          )
          .forEach(el => el.before(this.createLineBreak()));
      },

      addTrailingLineBreak() {
        if (!this.isLineBreak(this.content.lastChild)) {
          this.content.append(this.createLineBreak());
        }
        // remove br tag from content if there isn't any other content left
        if (this.content.innerHTML === document.createElement('br').outerHTML) {
          this.content.innerHTML = '';
        }
      },

      cleanOrphanedCustomTags() {
        Array.from(this.customTagInstances)
          .filter(i => !this.content.contains(i.$el))
          .forEach(this.unregisterCustomTag);
      },

      cleanupCustomTags() {
        Array.from(this.customTagInstances)
          .forEach(i => i.cleanup());
      },

      checkIncompatibleTags(tag, actionTag) {
        const incompatibleTags = ['h1', 'h2'];

        return incompatibleTags.includes(tag) && incompatibleTags.includes(actionTag);
      },

      findSelectedNodes() {
        const selection = getSelection();

        const iterator = document.createNodeIterator(
          this.$refs.content,
          NodeFilter.SHOW_ALL, // no pre-filter
          {
            acceptNode: function(node) { // filter out suitable nodes from selection
              return selection.containsNode(node) && node.nodeName === '#text'
                ? NodeFilter.FILTER_ACCEPT
                : NodeFilter.FILTER_REJECT;
            }
          }
        );

        // getting result as array
        const nodes = [];
        while (iterator.nextNode()) {
          if (nodes.length === 0 && iterator.referenceNode !== this.lastRange.startContainer) {
            continue;
          }

          nodes.push(iterator.referenceNode);

          if (iterator.referenceNode === this.lastRange.endContainer) {
            break;
          }
        }

        return nodes;
      },

      replaceNodeWithOtherNode(nodeToReplace, tag, keepNode) {
        if (!nodeToReplace) {
          return;
        }

        const newNode = document.createElement(tag);
        keepNode
          ? newNode.appendChild(nodeToReplace.cloneNode(true))
          : newNode.append(this.extractContents(nodeToReplace.childNodes));
        nodeToReplace.replaceWith(newNode);
      },

      mapNode(node, tag) {
        if (node.parentNode?.localName === 'div') {
          this.replaceNodeWithOtherNode(node, tag, true);
          return;
        }

        if (this.checkIncompatibleTags(node.parentNode?.localName, tag)) {
          this.replaceNodeWithOtherNode(node.parentNode, node.parentNode.localName === tag ? 'div' : tag, false);
          return;
        }

        this.mapNode(node.parentNode, tag);
      },

      openInNewTab(url) {
        const win = window.open(url, '_blank');
        win.focus();
      },

      createLink(link, linkText = link) {
        const node = document.createElement('a');

        node.title = linkText;
        node.href = this.getClickableLink(link);
        node.classList.add(styles.link);

        node.innerText = linkText;

        return node;
      },

      getClickableLink(link) {
        return link.startsWith('http')
          ? link
          : `http://${ link }`;
      },

      onAction(action) {
        if (!this.lastRange) {
          return;
        }

        // restore the last selection before applying the action
        // this is needed because click event listener is invoked
        // *after* contenteditable loses focus and thus the selection
        this.focus();

        if (action.type === WysiwygActionType.CustomTag) {
          return this.insertCustomTag(action.id);
        }

        if (action.command === 'formatBlock') {
          this.findSelectedNodes().forEach(node => this.mapNode(node, action.tag));
        }
        else if (action.command === 'createLink') {
          const linkNode = this.createLink(action.link, action.linkText);
          this.insertElement(linkNode);
        }
        else {
          document.execCommand(action.command, false);
        }

        this.onChange();
        this.focus();
      },

      onCopy(e) {
        e.preventDefault();

        // Range.cloneContents returns DocumentFragment which doesn't provide a method to get its HTML
        const fragment = this.lastRange.cloneContents();
        const el = document.createElement('div');
        el.appendChild(fragment);

        const convertedHtml = convertCustomTagsToBbCode(el.innerHTML);
        e.clipboardData.setData('text/plain', convertedHtml);
      },

      onCut(e) {
        this.onCopy(e);
        this.lastRange.deleteContents();
      },

      onPaste(e) {
        e.preventDefault();

        const html = convertCustomTagsToHtml(
          e.clipboardData.getData('text/plain')
        );

        if (!html) {
          return;
        }

        const el = document.createElement('div');
        el.innerHTML = html;
        this.initCustomTags(el);

        const fragment = new DocumentFragment();
        // we have to copy childNodes list because append mutates the original list
        Array.from(el.childNodes).forEach(node => fragment.append(node));

        insertElementAtRange(fragment, this.lastRange);
        this.onChange();
      },

      onCustomTagRemove() {
        this.onChange();
      },

      onDocumentSelectionChange() {
        if (this.content && this.content.contains(getSelection().baseNode)) {
          const range = getSelectionRange();
          this.lastRange = range || null;
        }

        this.customTagInstances.forEach(instance => instance.onSelectionChange(this.lastUserAction));
        this.lastUserAction = null;
      },

      onMouseDown() {
        this.lastUserAction = UserAction.Mouse;
      },

      onKeyDown(e) {
        this.lastUserAction = UserAction.Keyboard;

        switch (e.code) {
          case 'Enter':
            return this.onEnter(e);
          case 'Tab':
            return this.onTab(e);
        }
      },

      onEnter(e) {
        const { startContainer: start } = getSelectionRange();

        if (this.isListElement(start) || this.isListElement(start.parentNode)) {
          return;
        }

        e.stopPropagation();
        e.preventDefault();

        this.insertElement(this.createLineBreak());

        this.onChange();
      },

      onTab(e) {
        e.stopPropagation();
        e.preventDefault();

        // simulate tabulation - insert four &nbsp; elements
        this.insertElement(document.createTextNode('\u00A0\u00A0\u00A0\u00A0'));
        this.onChange();
      }
    }
  };
</script>
