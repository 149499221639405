import { allergies } from './allergies';
import { medicalHistories } from './medicalHistories';
import { medications } from './medications';

export const simpleAnamnesis = {
  namespaced: true,
  modules: {
    allergies,
    medicalHistories,
    medications,
  }
};
