/**
 * Validates the days of month and days of week against each other since ? is allowed only in one of those
 * @param {Object} cronObject
 * @param {String} cronObject.seconds
 * @param {String} cronObject.minutes
 * @param {String} cronObject.hours
 * @param {String} cronObject.daysOfMonth
 * @param {String} cronObject.months
 * @param {String} cronObject.daysOfWeek
 *
 * @returns {Boolean}
 */
export function daysOfWeekAndMonthValidator(cronObject) {
  if (cronObject.daysOfMonth === '?' && cronObject.daysOfWeek === '?') {
    return false;
  }
  return true;
}