import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';

export const mutations = {

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.INIT_FORM](state, initOptionsModel) {
    state.inputs = getInputsConfig(initOptionsModel);
    state.inputsMap = getInputsMap(state.inputs);
  },
};

/**
 *
 * @param {InitOptionsModel} initOptionsModel
 */
function getInputsConfig(initOptionsModel) {
  if (!initOptionsModel) {
    return [];
  }
  return [createInputConfig({
    id: 'isoCode',
    type: InputType.Select2,
    required: true,
    clearable: false,
    label: 'language.language',
    value: initOptionsModel.currentLanguage,
    options: initOptionsModel.supportedLanguages.supportedLanguagesIsoCodes.map(code => ({
      testId: code,
      label: code,
      value: code,
    }))
  })];
}

