import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { setLoading } from '../../../utils/withLoadind';
import { countryCode } from '../../../services/api/i18n/countryCode';
import { cantFetchEntity } from '../../../utils/i18n/cantFetchEntitiy';

export const actions = Object.freeze({
  fetchCountryCode,
});

async function fetchCountryCode({ state, commit, dispatch }) {
  if (state.countryCode) {
    return state.countryCode;
  }

  dispatch(setLoading, true);
  const result = await countryCode.fetchOne();
  dispatch(setLoading, false);
  if (result.success) {
    commit(types.SET_COUNTRY_CODE, result.data);
  }
  else {
    showRestError(result.error, cantFetchEntity('tenant.country'));
  }
  return state.countryCode;
}

