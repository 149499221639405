import { get } from 'lodash';
import { mapArray } from '../utils/mapUtils';
import {
  encodeObservationType
} from '../components/views/app/practitioner/patients/view/enrollments/view/terminologyType/observationIdEncode';
import { formatName } from '../utils/formatters/fullNameFormatter';
import { localize } from '../services/localize';
import { ecgKey } from './observation/ecg';

// due to different taskservice/ehbackend codebase we use different field for sorting.
export const CallCenterTenantPatientSortFieldName = 'monitoringPeriod.enrollment.patient.lastName';
export const RegularTenantPatientSortFieldName = 'monitoringPeriod.enrollment.patientInfo.lastName';

export class PractitionerTaskModel {
  /**
   * PractitionerTask config
   *
   * @param {String}                    config.id
   * @param {String}                    config.patientUuid
   * @param {String}                    config.patientIamId
   * @param {Object}                    config.monitoringPeriod
   * @param {String}                    config.type
   * @param {String}                    config.status
   * @param {String}                    config.handlingType
   * @param {String}                    config.severity
   * @param {Object}                    config.observation
   * @param {String}                    config.ownPatient
   * @param {String}                    config.comment
   * @param {String}                    config.message
   * @param {String}                    config.messageCode
   * @param {String}                    config.messageCodePayload
   * @param {String}                    config.date
   * @param {Object}                    config.patient
   * @param {String}                    config.program
   * @param {String}                    config.createdOn
   * @param {String}                    config.observationDate
   * @param {String}                    config.tenantId
   * @param {String}                    config.tenantDependentUuid
   * @param {Boolean}                   config.forwarded
   * @param {RawEcgFile}                config.rawEcg
   */
  constructor(config) {
    this.id = config.id;
    this.monitoringPeriod = config.monitoringPeriod;
    this.patientUuid = (config.patientUuid) ? config.patientUuid : get(this.monitoringPeriod, 'enrollment.patientUuid');
    this.patientIamId = config.patientIamId;
    this.type = config.type;
    this.status = config.status;
    this.handlingType = config.handlingType;
    this.severity = config.severity;
    this.observation = config.observation;
    this.ownPatient = config.ownPatient;
    this.comment = config.comment;
    this.message = config.message;
    this.messageCode = config.messageCode;
    this.messageCodePayload = config.messageCodePayload;
    this.date = config.date;
    this.patient = config.patient;
    this.program = config.program;
    this.createdOn = config.createdOn;
    this.observationDate = config.observationDate;
    this.tenantId = config.tenantId;
    this.tenantDependentUuid = config.tenantDependentUuid;
    this.forwarded = config.forwarded;
    this.rawEcg = config.rawEcg;
  }

  get universalUuid() {
    return this.tenantDependentUuid || this.id;
  }

  get patientName() {
    if (!this.hasPatient) {
      return '';
    }
    return formatName(this.patient.firstName, this.patient.middleName, this.patient.lastName);
  }

  get patientNameFromEnrollment() {
    return formatName(this.monitoringPeriod?.enrollment?.patientFirstName, this.monitoringPeriod?.enrollment?.patientMiddleName, this.monitoringPeriod?.enrollment?.patientLastName);
  }

  get programName() {
    return get(this.monitoringPeriod, 'enrollment.programName');
  }

  // used as key-value for sorting
  get 'monitoringPeriod.enrollment.patientFirstName'() {
    return this.patientName;
  }

  get 'tenantSettings.tenantName'() {
    return this.tenantId;
  }

  // used as key-value for sorting
  get [CallCenterTenantPatientSortFieldName]() {
    return this.patientName;
  }

  // used as key-value for sorting
  get 'monitoringPeriod.enrollment.programName'() {
    return this.programName;
  }

  get hasPatient() {
    return !!this.patient;
  }

  get observationTerminologyType() {
    return get(this.observation, 'payload.terminologyType');
  }

  get isEcg() {
    return this.observationTerminologyType === ecgKey;
  }

  get observationTerminologyTypeEncoded() {
    return encodeObservationType(this.observationTerminologyType, get(this.observation, 'payload.uuid'));
  }

  get translatedMessage() {
    if (!this.messageCode) {
      return this.message;
    }
    let payload = [];
    if (this.messageCodePayload) {
      const messageCodePayload = JSON.parse(this.messageCodePayload);
      if (Array.isArray(messageCodePayload)) {
        payload = messageCodePayload;
      }
      else if (messageCodePayload) {
        payload = [messageCodePayload];
      }
    }
    return localize(this.messageCode, payload);
  }

  get enrollmentTenantDependedId() {
    if (this.tenantId) {
      return this.monitoringPeriod?.enrollment?.tenantDependentUuid || this.monitoringPeriod?.enrollment?.id;
    }
    return this.monitoringPeriod?.enrollment?.id;
  }

  get handlingStatus() {
    return this.status;
  }
}

export class PractitionerTaskBatchModel {
  /**
   * PractitionerTaskBatch config
   *
   * @param {Array}                    config.tasks
   */
  constructor(config) {
    this.tasks = mapArray(config.tasks, PractitionerTaskModel);
  }

}
