import { types } from './types';
import { currentLanguage } from '../../../../services/api/i18n/currentLanguage';
import { supportedLanguages } from '../../../../services/api/i18n/supportedLanguages';
import { showRestError } from '../../../../utils/errors';
import { InitOptionsModel } from '../edit/models';

export const actions = Object.freeze({
  init,
});

async function init({ commit }) {
  commit(types.SET_LOADING, true);
  commit(types.INIT_FORM, null);
  try {
    const supportedLanguagesResult = await supportedLanguages.fetchOne();
    if (!supportedLanguagesResult.success) {
      showRestError(supportedLanguagesResult.error, 'messages.cantFetchSupportedLanguages');
      return;
    }
    const result = await currentLanguage.fetchOne();
    if (result.success) {
      commit(types.INIT_FORM, new InitOptionsModel({
        currentLanguage: result.data,
        supportedLanguages: supportedLanguagesResult.data
      }));
    }
    else {
      showRestError(result.error, 'messages.cantFetchCurrentLanguage');
    }
  }
  finally {
    commit(types.SET_LOADING, false);
  }
}
