import { CrossTenantPatientModel } from '../../../model/patientModel';
import { CrudService } from '../../../classes/crudService';
import { PageModel } from '../../../model/page';

export const callCenterPatientsBundle = new CrudService(
  '/call-center/patients/list',
  data => new CrossTenantPatientModel(data)
);

export const callCenterPatients = new CrudService(
  '/call-center/patients',
  data => new PageModel(data, model => new CrossTenantPatientModel(model))
);

