import { getAuth } from '../auth/auth';
import { AxiosError, isCaptureOnlyErrors, saveAxiosError } from './axiosError';

const UNAUTHORIZED = 401;

let router;

/**
 *
 * @param {Object} config
 * @param {Router} config.router
 */
export function initErrorConfig(config) {
  router = config.router;
}

export function handleSuccess(response) {
  if (!isCaptureOnlyErrors()) {
    const axiosError = new AxiosError({
      request: {
        callcenterFlag: response.config.headers['X-call-center-flag'],
        tenantIdFlag: response.config.headers['X-Tenant-ID'],
        method: response?.config?.method,
        payload: response?.config?.data,
        route: router?.currentRoute?.fullPath,
        url: response.request.responseURL,
      },
      response: {
        h4hTraceId: response.headers['x-h4h-traceid'],
        status: response.status,
        statusText: response.statusText,
      },
      date: new Date(),
      success: true,
    });
    saveAxiosError(axiosError);
  }
  return response;
}

function handleCommonError(error) {
  const axiosError = new AxiosError({
    request: {
      callcenterFlag: error.response.config.headers['X-call-center-flag'],
      tenantIdFlag: error.response.config.headers['X-Tenant-ID'],
      method: error.response?.config?.method,
      payload: error.response?.config?.data,
      route: router?.currentRoute?.fullPath,
      url: error.request.responseURL,
    },
    response: {
      h4hTraceId: error.response.headers['x-h4h-traceid'],
      response: error.request.response,
      status: error.request.status,
      statusText: error.request.statusText,
    },
    date: new Date(),
    success: false
  });
  // we don't want to store unauthorized status
  if (axiosError.response.statusText === 'Unauthorized') {
    return;
  }
  saveAxiosError(axiosError);
}

export function handleErrors(error) {
  handleCommonError(error);
  const auth = getAuth();
  if (auth && error.response && error.response.status === UNAUTHORIZED) {
    auth.logout();
  }

  return Promise.reject(error);
}
