<template>
  <div :class="styles.container">
    <label
      v-if="$props.commonLabel || $props.info"
      :class="[inputStyles.label, $props.labelStyle]"
    >
      <LabelTooltip
        :info="$props.info"
        :label="$props.commonLabel"
      />
    </label>
    <div :class="styles.inputsContainer">
      <Smart
        v-for="(input, index) in _inputs"
        :key="input.id"
        :input="input"
        :groupId="groupId"
        :rows="input.rows"
        :checkboxStyle="input.checkboxStyle"
        :action="setInputValue"
        :class="[input.size, styles.inputStyle]"
        :inputStyle="getStyle(index)"
        @blur="$emit('blur', $event)"
      />
    </div>
  </div>
</template>

<script>
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { InputType } from '../../constants';

  import Smart from '../Smart';
  import LabelTooltip from '../labelTooltip/LabelTooltip';

  import styles from './smartGroup.scss';

  /*
    The component should receive list of inputs and make them look like
    one input. Input types that should be used are:
      Text, Float, Date, Select, Integer
    Avoid using RadioButtons, Checkboxes and other inputs with multiple options
    Single inputs can be used too
  */

  export const SUPPORTED_INPUT_TYPES = [
    InputType.Float,
    InputType.Text,
    InputType.Select2,
    InputType.Typeahead2,
    InputType.Select,
    InputType.Date,
    InputType.DateTime,
    InputType.DateRange,
    InputType.Integer,
    InputType.Time
  ];

  export default {
    name: 'H4hSmartGroup',

    components: {
      Smart,
      LabelTooltip
    },

    props: {
      ...Smart.props,

      inputs: {
        type: Array,
        required: true,
        validator: inputsProps => {
          if (inputsProps?.length === 1) {
            return true;
          }
          return inputsProps.every(inputProps => SUPPORTED_INPUT_TYPES.includes(inputProps.type));
        },
      },

      commonLabel: {
        type: String
      },
    },

    data() {
      return {
        styles,
        inputStyles
      };
    },

    computed: {
      _inputs() {
        const { disabled, rows, commonLabel, tabindex, checkboxStyle, groupId } = this.$props;

        return this.$props.inputs.map(i => ({
          ...i,
          label: commonLabel ? '' : i.label,
          disabled,
          checkboxStyle,
          rows,
          tabindex,
          groupId
        }));
      }
    },

    methods: {
      getStyle(index) {
        if (this.$props.inputs.length === 1) {
          return '';
        }

        switch (index) {
          case 0:
            return styles.firstChild;
          case this.$props.inputs.length - 1:
            return styles.lastChild;
          default:
            return styles.noBorderRadius;
        }
      },

      setInputValue({ input, value },) {
        const { action, inputs } = this.$props;
        const findInput = inputs.find(i => i.id === input.id);

        if (action) {
          action({ input: findInput, value });
        }
      }
    }
  };
</script>
