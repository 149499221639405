import { get, fromPairs, mapValues, isNumber } from 'lodash';

import { Shared } from './shared';
import { getClientSideTableSearchInput } from '../utils/filters';

// convert to string by default
function defaultFormatter(value) {
  if (!value && !isNumber(value)) {
    return '';
  }

  return String(value);
}

export const SearchableRows = {
  props: {
    columns: Shared.props.columns,
    filterInputs: Shared.props.filterInputs,

    // text shown when search prop is used and there are no results
    noResultsMessage: {
      type: [String, Array],
      default: () => ['common.noMatchingEntity', 'common.records']
    }
  },

  data() {
    return {
      searchableRows__query: '',
      searchableRows__appliedQuery: ''
    };
  },

  computed: {
    searchableRows__columnsMap() {
      return fromPairs(this.$props.columns.map(c => [c.valueKey, c]));
    },

    searchableRows__formattersMap() {
      return mapValues(this.searchableRows__columnsMap, c => c.formatter || defaultFormatter);
    }
  },

  watch: {
    filterInputs() {
      const newFilterValue = getClientSideTableSearchInput(this.$props.filterInputs)?.value;
      if (newFilterValue !== this.searchableRows__query) {
        this.searchableRows__setQuery(newFilterValue);
        this.searchableRows__apply();
      }
    }
  },

  methods: {
    searchableRows__setQuery(query) {
      this.searchableRows__query = query?.toLowerCase() || '';
    },

    searchableRows__apply() {
      this.searchableRows__appliedQuery = this.searchableRows__query;
    },

    searchableRows__getFilteredShownRows(rows) {
      if (!rows.length || !this.searchableRows__appliedQuery) {
        return rows;
      }

      return rows.filter(row => this.searchableRows__rowIncludesQuery(row));
    },

    searchableRows__rowIncludesQuery(row) {
      return this.$props.columns.some(({ valueKey, isMultipleItemsCell }) =>
        this.searchableRows__rowValueIncludesQuery(row, valueKey, isMultipleItemsCell)
      );
    },

    searchableRows__rowValueIncludesQuery(row, path, isMultipleItemsCell = false) {
      const value = get(row, path);

      // simulate Vuetify's cell interface
      const formattedValue = this.searchableRows__formattersMap[path](value, { item: row });

      return isMultipleItemsCell
        ? formattedValue.some(v => v.includes(this.searchableRows__appliedQuery))
        : formattedValue?.toLowerCase().includes(this.searchableRows__appliedQuery);
    }
  }
};
