export const KardiaConnectionStatus = Object.freeze({
  PENDING: 'PENDING',
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
});

export const kardiaConnectionOptions = Object.keys(KardiaConnectionStatus).map(status => ({
  label: `kardia.${ status }`,
  value: status
}));

