import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';
import { without } from 'lodash';

export const mutations = {
  [types.INIT_FORM](state) {
    state.questions = [];
    state.inputs = getInputsConfig();
    state.inputsMap = getInputsMap(state.inputs);
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.ADD_QUESTION](state, question) {
    state.questions.push(question);
  },

  [types.REMOVE_QUESTION](state, question) {
    state.questions = without(state.questions, question);
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  }
};

/**
 *
 * @return {Object[]}
 */
function getInputsConfig() {
  const title = createTextInputConfig('title', 'common.name', InputType.Text);
  return [
    title,
  ];
}

function createTextInputConfig(id, label, inputType) {
  return createInputConfig({
    id,
    type: inputType,
    label,
    required: true,
  });
}
