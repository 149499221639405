<template>
  <div ref="container">
    <Popover
      ref="popover"
      :openOnClick="false"
      :closeOnClick="true"
      :closeOnContentClick="false"
      :offsetOverflow="true"
      :attach="getAttachElement()"
      :offsetY="true"
      :bottom="true"
      :left="$props.left"
      @change="onPopoverChange($event)"
    >
      <template #openPopover>
        <slot
          name="default"
          v-bind="{ onClick }"
        />
      </template>

      <div :class="[styles.popoverContent, popoverClass]">
        <Loading
          v-if="loading"
          :label="loadingLabel"
          :class="styles.loading"
        />
        <div
          v-else
        >
          <Smart
            :input="$props.input"
            :action="$props.inputAction"
          />
          <Button
            primary
            label="common.save"
            :class="styles.button"
            data-testid="button-save"
            @click="onSaveClick"
          />
        </div>
      </div>
    </Popover>
  </div>
</template>

<script>
  import { Smart } from '@h4h/inputs';
  import { Popover } from '@h4h/popover';

  import styles from './tableAction.scss';
  import Loading from '../../../../loading/src/components/Loading.vue';

  export default {
    name: 'H4hTableAction',

    components: {
      Popover,
      Smart,
      Loading
    },

    props: {
      disabled: {
        type: Boolean,
        default: false
      },

      popoverContent: {
        type: Boolean,
        default: false
      },

      left: {
        type: Boolean,
        default: false
      },
      loadingLabel: {
        type: [String, Array],
        default: ''
      },

      // should be set to false for popover if table is inside popup
      attach: {
        type: Boolean,
        default: true
      },

      // action properties
      input: Object,
      inputAction: Function,
      initPopover: Function,
      popoverClass: String
    },

    data() {
      return {
        styles,
        loading: false
      };
    },

    methods: {
      getAttachElement() {
        return this.$props.attach && this.$refs.container;
      },

      async onClick($event) {
        const { disabled, popoverContent } = this.$props;

        $event.preventDefault();
        $event.stopPropagation();

        if (disabled) {
          return;
        }

        if (popoverContent) {
          await this.$refs.popover.show();
          if (this.$listeners.initPopover) {
            this.loading = true;
            await this.$listeners.initPopover();
            this.loading = false;
          }
        }
        else {
          this.$emit('trigger');
        }
      },

      onSaveClick() {
        this.$emit('trigger');
        this.$refs.popover.hide();
      },

      onPopoverChange(active) {
        this.$emit('popoverChange', active);
      }

    }
  };
</script>
