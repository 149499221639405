import { mapValues } from 'lodash';

import { toast } from '../../../services/toast';

import { types } from './types';
import { localize } from '../../../services/localize';
import { showRestError } from '../../../utils/errors';
import { orgUsers } from '../../../services/api/users/orgUsers';
import { GroupsActions } from '../../../constants/groups';
import { router } from '../../../services/router';
import { Page } from '../../../constants/page';
import { convertPhonesToStrings } from '../../../utils/phone/phoneComponentUtils';

export const actions = Object.freeze({
  saveOrgUser,
  initForm,
  clearInputs,
});

async function saveOrgUser({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const orgUser = {
    disableDirectLogin: false,
    ...mapValues(state.inputsMap, i => i.value),
  };
  convertPhonesToStrings(orgUser, ['phone']);

  commit(types.SET_LOADING, true);
  const result = await orgUsers.create(orgUser);
  commit(types.SET_LOADING, false);

  if (result.success) {
    toast.success(localize('messages.userSaved'));
    router.push({ name: Page.AdminOrgUsersList });
  }
  else {
    showRestError(result.error, 'messages.cantCreateUser');
  }
  return result.success;
}

async function initForm({ commit, dispatch }, { organizationSetting }) {
  const groups = await dispatch(GroupsActions.FetchGroupsWithoutPatient, {}, { root: true });
  commit(types.INIT_FORM, { groups, organizationSetting });
}

async function clearInputs({ commit }) {
  commit(types.CLEAR_INPUTS);
}

