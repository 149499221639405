export const patients = {
  // pageTitle: 'Patients',
  //
  anamnesis: 'Anamnesis',
  updateAnamnesis: 'Update anamnesis',
  loadingAnamnesis: 'Loading anamnesis',
  //
  questionnaire: 'Question list',
  updateQuestionnaire: 'Update questionnaire',
  loadingQuestionnaire: 'Loading questionnaire',
  updatingQuestionnaire: 'Updating questionnaire',
  //
  // from: 'From',
  // form: 'Form',
  // period: 'Period',
  patient: 'Patient',
  // filters: 'Filters',
  // through: 'Through',
  // smoking: 'Smoking',
  // currentWeight: 'Current weight',
  // physicalActivity: 'Physical activity',
  // absence: 'Absence',
  // programs: 'Programs',
  // addedPrograms: 'Added programs',
  // newPrograms: 'New programs',
  // allPatients: 'All patients',
  // onlyMyPatients: 'Only my patients',
  // alphabeticalOrder: 'Alphabetical order',
  // addPatient: 'Add patient',
  // newPatient: 'New patient',
  labResults: 'Lab results',
  // frequencies: 'Frequencies',
  // diagnostics: 'Diagnostics',
  // medicalDossier: 'Medical dossier',
  // dossier: 'Dossier',
  // observations: 'Observaties',
  // showQrCode: 'Show QR-code',
  // hypertension: 'Hypertension',
  // hypertensionProgram: 'Hypertension Program',
  bloodPressure: 'Blood pressure',
  // weight: 'Weight',
  systolic: 'Systolic',
  avgSystolic: 'Avg. Systolic',
  diastolic: 'Diastolic',
  avgDiastolic: 'Avg. Diastolic',
  // avgWeight: 'Avg. Weight',
  // createPatient: 'Create patient',
  measuredValue: 'Measured value',
  addLabResult: 'Add lab result',
  editLabResult: 'Edit lab result',
  deleteLabResult: 'Delete lab result',
  addBloodPressure: 'Add blood pressure',
  // addWeight: 'Add weight',
  // faq: 'Frequently asked questions',
  // searchPatients: 'Search patients',
  observationDate: 'Observation date',
  questionnaireDate: 'Questionnaire date',
  // noPatientsFound: 'No patients found',
  // treatmentAdvice: 'Treatment advice',
  // loadingPatients: 'Loading patients',
  // editInformation: 'Edit information',
  // observationTemplate: 'Observation template',
  // creatingPatient: 'Creating a patient',
  // basicInformation: 'Basic information',
  loadingLabResults: 'Loading lab results',
  addingObservation: 'Adding an observation',
  // patientInformation: 'Patient information',
  // loadingPatientData: 'Loading patient data',
  // lifestyleMonitoring: 'Lifestyle monitoring',
  // hypertensionMonitoring: 'Hypertension monitoring',
  // absenceInfo: 'If a patient is absent due to a vacation or a hospital admission, you can disable notifications by entering the relevant period.',
  // activatePatientPrompt: 'The patient has not yet activated the account for the Heart for Health app. Show the patient the QR code and have it scanned in the patient\'s app.',
  //
  // treatmentHistory: 'Treatment history',
  //
  loadingBloodPressureHistory: 'Loading blood pressure history',
  bloodPressureHistory: 'Blood pressure history',
  //
  // loadingWeightHistory: 'Loading weight history',
  // weightHistory: 'Weight history',
  //
  // loadingSubProgram: 'Loading Sub Programs',
  // subProgram: 'Sub Program\'s',
  // subProgramActivate: 'Activate',
  // subProgramDeactivate: 'Deactivate',
  // subProgramPageDescription: 'On this page you can activate or deactivate the patient\'s subprogram. Based on the program, the patient receives reminders in the app to take observations. You will also receive a notification in the tasks tab when the weekly average falls into the red (higher than 170 mmHG) or orange (between 136 and 170 mmHG) category. When the observation week ends or the evaluation moment has arrived during the monitoring period, you will also be informed about this in the task tab.',
  // subProgramMeetweek: 'Observation Week',
  // subProgramMeetweekDescription: 'Invite your patient to measure his blood pressure twice a day for 7 days. Activate the program to get started.',
  // subProgramMonitoringsperiod: 'Monitoring Period',
  // subProgramMonitoringsperiodDescription: 'Invite your patient to start measuring his blood pressure for 12 weeks. Activate the program to get started.',
  //
  // loadingECG: 'Loading ECG',
  ecg: 'ECG',
  ecgHistory: 'ECG history',
  // ecgDownloadFailed: 'ECG download failed',
  // noEcgObservationsFound: 'No ECG observations found',
  //
  // currentDiagnoses: 'Current Diagnoses',
  // cardiologicalHistory: 'Cardiological History',
  // generalHistory: 'General History',
  // medications: 'Medications',
  // allergies: 'Allergies',
  // carePlan: 'Care Plan',
  // decursus: 'Decursus',
  // summary: 'Summary',
  month: 'Month',
  quarterOfYear: 'Quarter of a year',
  halfOfYear: 'Half a year',
  // deletePatient: 'Delete patient',
  // deletePatientMessage: 'Are you sure you want to delete this patient?',
  archivePatient: 'Archive patient',
  archivePatientMessage: 'Are you sure you want to archive this patient?',
  archivePatientFinalWarningMessage: 'Archived patients are not available in the platform, all programs are stopped when a patient is archived. Do you really want to archive this patient?',
  years: 'years',
  noDataAvailablePleaseSpecifyAtLeastXSymbolsInFilter: 'No data available. Please specify at least {0} symbols in filter.',
  yourLastXVisitedPatientFiles: 'Your last 10 visited patient files',
};
