export const getters = {
  patients,
  filterInputs,
  valid,
};

function filterInputs(state) {
  return state.inputs.filter(x => !!x);
}

function valid(state) {
  return filterInputs(state).every(input => input.valid);
}

function patients(state) {
  if (state.patientsPage) {
    return state.patientsPage.content;
  }
  return null;
}

