export class CreationResultModel {
  /**
   * CreationStatusModel
   *
   * @param {Object}    config
   * @param {String}    config.status
   * @param {String}    config.uuid
   */
  constructor(config) {
    this.status = config.status;
    this.uuid = config.uuid;
  }

}
