import { state } from './state';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { withInputs } from '@h4h/inputs';

export const logisticTasks = withInputs({
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
});
