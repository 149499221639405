import { mapArray } from '../../utils/mapUtils';
import { TriggerConditionListItemModel } from './triggerConditionListItemModel';
import { Severity } from '../../constants/severity';
import { TriggerConditionModel } from './triggerConditionModel';
import { ChangedStatus } from '../../constants/changedStatus';

export class TriggerConditionsModel {
  /**
   * TriggerConditionsModel
   *
   * @param {Object}                            config
   * @param {TriggerConditionListItemModel[]}   config.list
   */

  constructor(config) {
    this.list = mapArray(config.list, TriggerConditionListItemModel);
  }

  setOrderValue() {
    this.list.forEach((value, index) => value.order = index);
  }

  get isEmpty() {
    return this.list.every(model => model.isEmpty);
  }

  get nonDeletedList() {
    return this.list.filter(model => model.changedStatus !== ChangedStatus.DELETED);
  }

  createDefaultSeverityConditions() {
    this.list = [
      new TriggerConditionListItemModel({
        severity: Severity.HIGH,
        condition: new TriggerConditionModel({
          greaterThan: 0,
        }),
      }),
      new TriggerConditionListItemModel({
        severity: Severity.MEDIUM,
        condition: new TriggerConditionModel({
          greaterThan: 0,
        }),
      }),
      new TriggerConditionListItemModel({
        severity: Severity.MEDIUM,
        condition: new TriggerConditionModel({
          lessThan: 0,
        }),
      }),
      new TriggerConditionListItemModel({
        severity: Severity.HIGH,
        condition: new TriggerConditionModel({
          lessThan: 0,
        }),
      }),
    ];
    this.setOrderValue();
  }
}
