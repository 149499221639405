import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig } from '@h4h/inputs';
import { validateTextInput } from '@h4h/inputs/src/utils/validations';
import { Password } from '../../../../constants/password';

export const mutations = {
  [types.SET_PASSWORD_IS_UPDATING](state, value) {
    state.passwordIsUpdating = value;
  },

  [types.SET_PRISTINE](state, value) {
    state.password.pristine = value;
    state.passwordConfirm.pristine = value;
  },

  [types.INIT](state) {
    function isPasswordValid(value) {
      if (state.password.pristine) {
        return true;
      }
      return (value || '').length >= Password.Length;
    }

    function isPasswordConfirmValid(value) {
      if (state.passwordConfirm.pristine) {
        return true;
      }
      return (value || '').length >= Password.Length && (state.password.value === value);
    }

    state.passwordIsUpdating = false;
    state.password = createInputConfig({
      id: 'password',
      type: InputType.Text,
      password: true,
      required: true,
      label: 'orgUser.newPassword',
      placeholder: 'orgUser.newPassword',
      validate: input => {
        return validateTextInput(input) && isPasswordValid(input.value);
      }
    });
    state.passwordConfirm = createInputConfig({
      id: 'passwordConfirm',
      type: InputType.Text,
      password: true,
      required: true,
      label: 'orgUser.repeatNewPassword',
      validate: input => {
        return validateTextInput(input) && isPasswordConfirmValid(input.value);
      }
    });
  },
};
