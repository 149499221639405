<template>
  <Table
    v-bind="$props"
    data-testid="table-withUserFilters"
    :applyFilterAction="_applyFilterAction"
    :setFilterValueAction="_setFilterValueAction"
    @input="$emit('input', $event)"
  >
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotScope"
    >
      <slot
        :name="name"
        v-bind="slotScope"
      />
    </template>

    <template
      v-for="(_, name) in $slots"
      :slot="name"
    >
      <slot :name="name"/>
    </template>
  </Table>
</template>

<script>
  import { debounce } from 'lodash';

  import Table from '../table/Table';
  import { TableProps } from '../../mixins';
  import { getInputValues } from '../../utils/tableWithUserFilters';

  export default {
    name: 'H4hTableWithUserFilters',

    components: {
      Table
    },

    props: {
      ...TableProps.props,
      setUserFilters: {
        type: Function,
        required: true
      }
    },

    computed: {
      userFiltersValue() {
        return getInputValues(this.$props.filterInputs);
      }
    },

    methods: {
      _applyFilterAction(config) {
        this.$props.applyFilterAction(config);
        this.$props.setUserFilters({
          tableId: this.$props.userFiltersTableId,
          tableFiltersValue: this.userFiltersValue
        });
      },

      _setFilterValueAction(config) {
        this.$props.setFilterValueAction(config);
        if (this.$props.applyFilterAction) {
          return;
        }

        this.setUserFiltersForClientSideFiltering();
      },

      setUserFiltersForClientSideFiltering: debounce(function() {
        this.$props.setUserFilters({
          tableId: this.$props.userFiltersTableId,
          tableFiltersValue: this.userFiltersValue
        });
      }, 10000),
    }
  };
</script>
