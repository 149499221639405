import { ProductStatus } from '../../constants/productStatus';

export const certification = {
  nameDeveloper: 'Naam ontwikkelaar',
  ehealthPortalInformation: 'eHealth Portal informatie',
  ehealthPortal: 'eHealth Portal',
  serialNumber: 'SERIENUMMER',
  releaseDate: 'Publicatie datum:',
  userManual: 'Gebruikershandleiding',
  status: 'Status:',
  [ProductStatus.UNKNOWN]: 'Onbekend',
  [ProductStatus.PRODUCTION]: '',
  [ProductStatus.IN_DEVELOPMENT]: 'In Development',
  [ProductStatus.WITHDRAWN]: 'Teruggetrokken',
  [ProductStatus.RECALLED]: 'Teruggeroepen',
};
