import { activationEmailTypes } from '../constants/activationEmailTypes';
import { sendPatientActivationEmail } from '../services/api/patients/patients';
import { sendCallcenterUserActivationEmail } from '../services/api/users/callCenterUsers';
import { sendOrgUserActivationEmail } from '../services/api/users/orgUsers';

export async function sendActivationEmail(type, uuid) {
  switch (type) {
    case activationEmailTypes.PATIENT:
      return await sendPatientActivationEmail(uuid);
    case activationEmailTypes.CALL_CENTER_USER:
      return await sendCallcenterUserActivationEmail(uuid);
    case activationEmailTypes.ORG_USER:
      return await sendOrgUserActivationEmail(uuid);
    default:
      throw new Error('Invalid activation mail type.');
  }
}