import { localize } from '../../services/localize';
import { toast } from '../../services/toast';
import { showRestError } from '../../utils/errors';
import { sendActivationEmail } from '../../utils/sendActivationEmail';
import { types } from './types';

export const actions = Object.freeze({
  send
});

async function send({ commit }, { type, uuid }) {
  commit(types.SET_LOADING, true);
  const result = await sendActivationEmail(type, uuid);
  if (result.success) {
    toast.success(localize('common.activationEmailSent'));
  }
  else {
    showRestError(result.error, 'common.activationEmailFailed');
  }
  commit(types.SET_LOADING, false);
}