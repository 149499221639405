// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__app--3ZbkB {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./app.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ","sourcesContent":[":local {\n  .app {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "app__app--3ZbkB"
};
export default ___CSS_LOADER_EXPORT___;
