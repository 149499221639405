import { EhAddressModel, mapEhAddressToMpAddress, mapMpAddressToEhAddress } from './addressModel';
import { mapObject } from '../utils/mapUtils';

export class OrganizationSettingModel {
  /**
   * OrganizationSetting config
   *
   * @param {Object}          config
   * @param {String}          config.defaultTimeZone
   * @param {EhAddressModel}  config.address
   * @param {String}          config.phoneNumber
   * @param {String}          config.biography
   * @param {String}          config.clinicName
   * @param {String}          config.replyLink
   * @param {Boolean}         config.treatmentAdviceEnabled
   * @param {Boolean}         config.showDisableDirectLoginSsoDropdown
   * @param {Boolean}         config.customForwardingLinkToDoctor
   * @param {String}          config.bsnRegexp
   * @param {Boolean}         config.applyDutchDeepBsnCheck
   * @param {String}          config.tenantCountry
   */
  constructor(config) {
    this.defaultTimeZone = config.defaultTimeZone;
    this.address = mapEhAddressToMpAddress(mapObject(config.address, EhAddressModel));
    this.phoneNumber = config.phoneNumber;
    this.biography = config.biography;
    this.clinicName = config.clinicName;
    this.replyLink = config.replyLink || '';
    this.treatmentAdviceEnabled = config.treatmentAdviceEnabled;
    this.showDisableDirectLoginSsoDropdown = config.showDisableDirectLoginSsoDropdown === true || config.showDisableDirectLoginSsoDropdown === 'true';
    this.customForwardingLinkToDoctor = config.customForwardingLinkToDoctor;
    this.bsnRegexp = config.bsnRegexp;
    this.applyDutchDeepBsnCheck = config.applyDutchDeepBsnCheck;
    this.tenantCountry = config.tenantCountry;
  }
}

export class OrganizationCallCenterSettingModel {
  /**
   * OrganizationCallCenterSetting config
   *
   * @param {Object}          config
   * @param {String}          config.tenantName
   * @param {Boolean}         config.callCenterEnabled
   * @param {Boolean}         config.logisticsEnabled
   */
  constructor(config) {
    config = config || {};
    this.tenantName = config.tenantName;
    this.callCenterEnabled = config.callCenterEnabled;
    this.logisticsEnabled = config.logisticsEnabled;
  }
}

export function mapOrganizationSettingModelToApiModel(model) {
  return {
    ...model,
    replyLink: model.replyLink || '',
    address: mapMpAddressToEhAddress(model.address),
  };
}

