export const ToastType = Object.freeze({
  Success: 'Success',
  Error: 'Error',
  Warning: 'Warning',
});

export class ToastRequest {

  /**
   *
   * @param {String}            messageCode
   * @param {ToastType|String}  toastType
   */
  constructor({ messageCode, toastType }) {
    this.messageCode = messageCode;
    this.toastType = toastType;
  }
}

export class MultipleFiltersProfilesConfig {

  /**
   *
   * @param {String}    storeNamespace
   * @param {String}    tableId
   * @param {() => TableFiltersProfiles}                getProfileModel
   * @param {(profiles: TableFiltersProfiles) => void}  saveProfileModel
   * @param {(profile: TableFiltersProfile) => void}    activateProfile
   * @param {(toastRequest: ToastRequest) => void}      toast
   */
  constructor({ storeNamespace, tableId, getProfileModel, saveProfileModel, activateProfile, toast }) {
    this.storeNamespace = storeNamespace;
    this.tableId = tableId;
    this.getProfileModel = getProfileModel;
    this.saveProfileModel = saveProfileModel;
    this.activateProfile = activateProfile;
    if (toast) {
      this.toast = toast;
    }
    else {
      this.toast = payload => {
        window.console.log(payload);
      };
    }
  }
}
