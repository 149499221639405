import { list } from './list';
import { view } from './view';
import { add } from './add';
import { popup } from './popup';
import { edit } from './edit';

export const observationTemplates = {
  namespaced: true,
  modules: {
    list,
    view,
    add,
    edit,
    popup,
  }
};
