import { createInputConfig, InputType } from '@h4h/inputs';

// disableDirectLogin is very specific property - we show it as enable direct login, but on backend
// it is disableDirectLogin, so this is the reason why the pairs (yes/no) and (true/false) are flipped
// in the options array
export function createDisableDirectLoginInputConfig(customizations) {
  return createInputConfig({
    id: 'disableDirectLogin',
    type: InputType.Select2,
    clearable: false,
    required: true,
    label: 'common.directLoginEnabled',
    options: [
      { label: 'common.yes', value: false },
      { label: 'common.no', value: true },
    ],
    ...customizations,
  });
}
