import { state } from './state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { withInputs } from '@h4h/inputs';
import { graph } from './graph';
import { table } from './table';

export const list = withInputs({
  namespaced: true,

  state,
  actions,
  getters,
  mutations,
  modules: {
    graph,
    table,
  }
});
