<template>
  <VDataTable
    :key="key"
    ref="table"
    v-model="selectedRows"
    :expanded.sync="expandedRows"
    :options.sync="options"

    :fixedHeader="true"
    :hideDefaultFooter="true"
    :hideDefaultHeader="true"

    :headers="_columns"

    :items="shownItems"
    :itemKey="_uniqKey"

    :sortBy="columnSort__sortBy"
    :sortDesc="columnSort__sortDesc"

    :disableSort="!sortingEnabled"
    :disablePagination="!$props.pagination"

    :noDataText="$localize($props.noDataMessage)"
    :noResultsText="$localize($props.noResultsMessage)"

    :singleExpand="$props.singleExpand"
    :singleSelect="$props.singleSelect"
    :selectableKey="$props.selectableKey"

    :serverItemsLength="serverSideOptions && serverSideOptions.totalElements"
    :loading="loading"

    :class="[
      styles.table,
      'inlineInputs',
      $props.striped && styles.striped,
      $props.borderless && styles.borderless,
      $props.columnBorder && styles.columnBorder,
      hasActions && styles.withActionsColumn,
      hasPopoverRow && styles.hasPopoverRow,
      hideLastRowBorder && styles.borderlessLastRow,
      !$props.pagination && styles.tableWithoutFooter,
    ]"

    @update:page="onOptionsChange(options)"
    @update:sort-by="onOptionsChange(options)"
    @update:sort-desc="onOptionsChange(options)"
  >
    <!-- HACKY! This overrides any previously defined slots as, prefer to pass slot (plus data used within) explicitly from the default!
    Reverted as quick fix for some parts relying on this. -->
    <slot
      v-for="(_, name) in $slots"
      :name="name"
    />
    <template
      v-for="(_, name) in $scopedSlots"
      #[name]="slotScope"
    >
      <slot
        :name="name"
        v-bind="slotScope"
      />
    </template>

    <template #top="topProps">
      <slot
        name="top"
        v-bind="topProps"
      >
        <Heading
          v-if="showHeader"
          ref="headingTable"
          :label="$props.heading"
        >
          <template #actionButtons>
            <slot name="actionButtons"/>
          </template>
          <template #filter>
            <slot name="filter">
              <SmartFilter
                v-if="filterInputs && filterInputs.length"
                :inputs="filterInputs.filter(i => !i.hidden)"
                :setInputValueAction="setFilterValueAction"
                :applyFilterAction="applyFilterAction"
                :numberOfShownFilters="numberOfShownFilters"
                :query="searchableRows__query"
                :multipleFiltersProfilesConfig="$props.multipleFiltersProfilesConfig"
                :pagination="serverSideOptions"
                @search="searchableRows__apply"
                @searchQueryChange="searchableRows__setQuery"
              >
                <template #additionalFilters>
                  <slot name="additionalFilters"/>
                </template>
              </SmartFilter>
            </slot>
          </template>
        </Heading>

        <div
          v-if="selectedRows.length && !$props.singleSelect && !hideBatchHeader"
          ref="selectedItems"
          :class="[styles.containerHeading, styles.stickyHeader]"
        >
          <div :class="styles.containerHeadingContent">
            <div :class="styles.selectedItems">
              <Chip
                small
                :type="ChipStyle.Success"
                :class="styles.selectedItemsChip"
              >
                {{ selectedRows.length }}
              </Chip>
              <span :class="styles.selectedItemsLabel">
                {{ $localize('common.itemsSelected').toLowerCase() }}
              </span>
            </div>

            <Smart
              v-if="$props.showOnlySelectedInput"
              :class="styles.showSelectedInput"
              :input="$props.showOnlySelectedInput"
              :action="$props.showOnlySelectedInputAction"
            />

            <BatchActions
              v-if="$props.batchActions.length"
              :rows="selectedRows"
              :noBatchActionsLabel="$props.noBatchActionsLabel"
              :actions="$props.batchActions"
              :colored="$props.batchActionsColor"
              :showLabel="$props.batchActionsText"
            />
          </div>
        </div>
      </slot>
    </template>

    <template #header="header">
      <slot
        name="header"
        v-bind="header"
      >
        <TableHeader
          :headers="_columns"
          :allRowsSelected="allRowsSelected"
          :someRowsSelected="header.props.someItems"

          :sortingEnabled="sortingEnabled"
          :showSelect="_showSelect && !$props.singleSelect"

          @checkboxChange="toggleSelectAll()"
          @changeColumnSorting="columnSort__changeColumnSorting"
        >
          <template
            v-for="slotName in headerSlotNames"
            #[slotName]="slotScope"
          >
            <slot
              :name="slotName"
              v-bind="slotScope"
            />
          </template>
        </TableHeader>
      </slot>
    </template>

    <template
      #item="{ item, index, isSelected, isExpanded, select, expand, headers }"
    >
      <slot
        name="item"
        v-bind="{ item, index, isSelected, isExpanded, select, expand, headers, attrs: rowProps, on: rowListeners }"
      >
        <TableRow
          :key="item[_uniqKey]"
          :item="item"
          :headers="_columns"
          :actions="$props.actions"
          :itemKey="_uniqKey"
          :itemClass="$props.itemClass"
          :highlightRow="$props.highlightRow"

          :isExpanded="isExpanded || groupedRows__isRowExpanded(item)"
          :isSelected="isSelected"

          :showSelect="_showSelect && isItemSelectable"
          :rowClickSelect="$props.rowClickSelect"
          :showExpand="_showExpand && isItemExpandable"
          :getNestingLevel="getNestingLevel"

          :isPopoverRow="isPopoverRow(item)"

          v-on="rowListeners"

          @toggleSelect="toggleSelect(item, isSelected, select)"
          @toggleExpand="toggleExpand(item, isExpanded, expand)"
          @popoverChange="onPopoverChange"
        >
          <template
            v-for="slotName in itemSlotNames"
            #[slotName]="slotScope"
          >
            <slot
              :name="slotName"
              v-bind="slotScope"
            />
          </template>
        </TableRow>
      </slot>
    </template>

    <template #expanded-item="slotProps">
      <tr
        v-if="hasExpansionContent"
        :class="styles.expansionSlotRow"
      >
        <td :colspan="slotProps.headers.length">
          <slot
            name="item.expansion"
            v-bind="slotProps.item"
          />
        </td>
      </tr>
      <slot
        name="expanded-item"
        v-bind="{ ...slotProps, attrs: expandedItemRowProps, on: rowListeners }"
      />
    </template>

    <template
      v-if="pagination"
      #footer="footer"
    >
      <slot
        name="footer"
        v-bind="footer"
      >
        <Pagination
          :page="footer.props.pagination.page"
          :pageCount="footer.props.pagination.pageCount"
          @pageChange="setPage"
        />
      </slot>
    </template>
  </VDataTable>
</template>

<script>
  import { VDataTable } from 'vuetify/lib';

  import { Smart } from '@h4h/inputs';
  import { hasSlot } from '@h4h/utils';
  import { Chip, ChipStyle } from '@h4h/chip';

  import { uniqBy, debounce, uniqueId, get } from 'lodash';

  import { Shared } from '../../mixins/shared';
  import { GroupedRows } from '../../mixins/groupedRows';
  import { SortableRows } from '../../mixins/sortableRows';
  import { StickyColumns } from '../../mixins/stickyColumns';
  import { ColumnSorting } from '../../mixins/columnSorting';
  import { SearchableRows } from '../../mixins/searchableRows';
  import { getHeaderSlotNames, getItemSlotNames } from '../../utils/slotUtils';
  import { mapHeader, actionsColumnHeader, sortableColumnHeader, expandColumnHeader, selectColumnHeader } from '../../utils/tableColumns';

  import Heading from '../heading/Heading';
  import TableRow from '../tableRow/TableRow';
  import rowStyles from '../tableRow/tableRow.scss';
  import Pagination from '../pagination/Pagination';
  import SmartFilter from '../filters/SmartFilter';
  import TableHeader from '../tableHeader/TableHeader';
  import BatchActions from '../batchActions/BatchActions';

  import styles from './table.scss';
  import { TableProps } from '../../mixins/tableProps';

  // @todo: Baizulin - implement a transparent dependency on localization service
  export default {
    name: 'H4hTable',

    components: {
      BatchActions,
      TableHeader,
      VDataTable,
      Pagination,
      TableRow,
      Heading,
      Smart,
      Chip,
      SmartFilter
    },

    mixins: [
      GroupedRows,
      SortableRows,
      StickyColumns,
      ColumnSorting,
      SearchableRows
    ],

    props: {
      ...TableProps.props,
      // always show the bulk row for selected rows count, even when the filters don't apply for the selected rows
      keepBulkRow: {
        type: Boolean,
        required: false
      }
    },

    data() {
      const tableId = uniqueId('table-');

      return {
        tableId,
        hasSlot, // must register on component for `this` scope
        stickyCol__id: tableId,
        styles,
        key: null,
        ChipStyle,
        rowStyles,
        options: {},
        lastItems: null,
        lastOptions: null,
        selectedRows: this.selected,
        // must be empty for selectable groupedRows to work
        // otherwise Vuetify will render empty rows between actual ones
        expandedRows: [],
        popoverRow: null, // { rowID, action }
      };
    },

    computed: {
      showHeader() {
        return this.$props.heading || this.hasSlot('actionButtons') || this.$props.filterInputs?.length;
      },

      hideLastRowBorder() {
        const { borderless, serverSideOptions, pagination } = this.$props;

        return borderless && pagination && serverSideOptions?.totalPages <= 1;
      },

      _uniqKey() { // REFACTOR: officially idKey, but many components use itemKey
        return this.$props?.itemKey || this.$props?.idKey;
      },

      _items() {
        // prevent page lock due to Vuetify.Table incorrectly handling null
        return this.$props.items || [];
      },

      _columns() {
        // Custom actionable columns instead of vuetify columns
        const { columns, sortable } = this.$props;
        const { _showSelect, _showExpand, hasActions } = this;

        return [
          // prepend action columns
          sortable && sortableColumnHeader,
          _showSelect && selectColumnHeader,
          _showExpand && expandColumnHeader,

          // add data columns
          ...columns
            .map(column => mapHeader(column, this.columnSort__getColumnSorting(column))),

          // append row actions
          hasActions && actionsColumnHeader
        ].filter(Boolean);
      },

      // WARNING: Don't pass these to VDataTable!
      // Vuetify internally prepends columns for expand and select in undesired order.
      _showSelect() {
        return this.hasRowSelectionColumn && !!this._items.length && !!this.selectableRows.length;
      },

      _showExpand() {
        return this.$props.showExpand || this.$props.groupedRows;
      },

      hasExpansionContent() {
        // only generate the extra row with slot if the slot inside has content
        // prevents empty rows
        return this.hasSlot('item.expansion') && this.$scopedSlots['item.expansion']().length > 0;
      },

      rowProps() {
        return {
          itemKey: this._uniqKey, // slot user should prevent duplicates
          actions: this.$props.actions,
          itemClass: this.$props.itemClass,
          highlightRow: this.$props.highlightRow,
          headers: this._columns,
        };
      },

      expandedItemRowProps() {
        // expanded items are only for styling, use groupedRows if you need to track selection/expansion individually
        return {
          ...this.rowProps,
          showSelect: false,
          showExpand: false,
          nestingLevel: 1, // expanding items can't be combined with groupedRows atm, so the parent is always 0 and expansion child is always 1 by default
        };
      },

      rowListeners() {
        // shared between expansion slot and base row
        return {
          click: e => {
            this.$emit('click:row', e);
          },
          dblclick: e => {
            this.$emit('dblclick:row', e);
          },
          contextmenu: e => {
            this.$emit('contextmenu:row', e);
          },
        };
      },

      hasPopoverRow() {
        return this.activePopoverRowId !== null;
      },

      activePopoverRowId() {
        return this.popoverRow ? this.popoverRow.rowId : null;
      },

      sortingEnabled() {
        // @todo: Baizulin - implement sorting of groupedRows tables
        const { columnSorting, groupedRows } = this.$props;
        return columnSorting && !groupedRows;
      },

      shownItems() {
        let shownItems = this._items;

        if (!this.$props.serverSideOptions) {
          shownItems = this.searchableRows__getFilteredShownRows(shownItems);
        }

        shownItems = this.groupedRows__getFilteredShownRows(shownItems);

        return shownItems;
      },

      headerSlotNames() {
        return getHeaderSlotNames(this);
      },

      itemSlotNames() {
        return getItemSlotNames(this);
      },

      // SortableRows mixin overrides
      sortable__element() {
        return this.$refs.table.$el;
      },

      sortable__rowIds() {
        return this._items.map(this.getRowId);
      },

      // StickyColumns mixin overrides
      stickyCols__element() {
        return this.$refs.table.$el;
      },

      stickyCols__trailing() {
        let trailing = this.$props.fixColumnEnd;

        if (!Number.isFinite(trailing)) {
          return 0;
        }

        if (this.hasActions) {
          trailing++;
        }

        return trailing;
      },

      // GroupedRows mixin overrides
      groupedRows__items() {
        return this._items;
      },

      allRowsSelected() {
        const allSelectable = this.selectableRows;
        const rowsSet = new Set(this.selectedRows);
        return allSelectable.every(row => rowsSet.has(row));
      },

      selectableRows() {
        return this._items.filter(this.isItemSelectable);
      },

      hasActions: Shared.computed.hasActions,
      hasRowSelectionColumn: Shared.computed.hasRowSelectionColumn
    },

    watch: {
      serverSideOptions: {
        handler() {
          this.updateOptions(this.serverSideOptions);
        },
        deep: true,
      },

      columnSort__sortBy(value) {
        this.options.sortBy = value;
      },

      columnSort__sortDesc(value) {
        this.options.sortDesc = value;
      },

      _items: {
        handler(newValue) {
          if (newValue && !this.serverSideOptions && this.resetPage) {
            this.options.page = 1;
          }
        },
        deep: true
      },

      selectedRows: {
        handler(newValue) {
          if (newValue) {
            this.selectedRows = newValue;
            this.$emit('input', this.selectedRows);
          }
        }
      },

      // Sync with selectedRows value if selected items were changed without user action
      selected: {
        handler(newValue) {
          if (newValue === this.selectedRows) {
            return;
          }
          this.selectedRows = newValue;
        }
      },

      expanded: 'setExpandedRows',
      expandedRows: 'onExpandedRowsChange',
      groupedRows__expanded: 'onExpandedRowsChange',
    },

    beforeMount() {
      if (this.serverSideOptions) {
        this.updateOptions(this.serverSideOptions);
      }
      else if (this.initialOptions) {
        this.updateOptions(this.initialOptions);
      }
    },

    mounted() {
      const { expanded } = this.$props;
      this.lastItems = this._items;

      if (expanded) {
        this.setExpandedRows(expanded);
      }
      else {
        this.initiallyExpandAllGroupedRows();
      }
      this.initStickyHeader();
    },

    updated() {
      this.initStickyHeader();
      if (this.haveItemsChanged() && !this.serverSideOptions && !this.$props.keepBulkRow) {
        this.lastItems = this._items;
        this.selectedRows = this.selectedRows.filter(row => this.lastItems.includes(row));
        this.sortable__update();
        this.initiallyExpandAllGroupedRows();
      }

      const { showOnlySelectedInput, showOnlySelectedInputAction } = this.$props;

      // reset input state to show all rows again, otherwise the table will show no rows
      // and batch actions won't be shown too since no rows are selected
      if (showOnlySelectedInput?.value && !this.selectedRows.length) {
        showOnlySelectedInputAction({
          input: showOnlySelectedInput,
          value: false
        });
      }
    },

    methods: {
      toggleSelect(item, isSelected, select) {
        return select(!isSelected);
      },

      toggleExpand(item, isExpanded, expand) {
        if (this.$props.groupedRows && this.$props.showExpand) {
          this.groupedRows__toggleRowExpand(item, isExpanded, expand);
          expand(!isExpanded);
          return;
        }

        if (this.$props.groupedRows) {
          return this.groupedRows__toggleRowExpand(item, isExpanded, expand);
        }
        else {
          return expand(!isExpanded);
        }
      },

      forceUpdate() {
        this.key = uniqueId('table-');
      },

      updateOptions(newOptions) {
        Object.assign(this.options, newOptions);
      },

      onOptionsChange: debounce(
        function() {
          const { optionsChange } = this.$props;

          if (optionsChange) {
            optionsChange(this.options);
          }

          // vuetify will mutate this.options thus we need to store a local copy
          // to determine whether anything changed
          this.lastOptions = {
            ...this.options
          };
        },
        300
      ),

      haveItemsChanged() {
        const prev = this.lastItems;
        const cur = this._items;

        return (
          (cur === prev && !cur) ||                             // both falsey
          (cur !== prev && !cur || !prev) ||                    // one is falsey
          cur.length !== prev.length ||                         // different lengths
          cur.some((item, index) => item !== prev[index])       // some items changed
        );
      },

      toggleSelectAll() {
        if (this.serverSideOptions) {
          this.toggleSelectAllSsp();
        }
        else {
          this.toggleSelectAllWithoutSsp();
        }
        this.$emit('toggleAll', this.selectedRows);
      },

      toggleSelectAllSsp() {
        const itemsInTableKeys = this.selectableRows.map(this.getRowId);
        const selectedRowsKeys = this.selectedRows.map(this.getRowId);

        if (itemsInTableKeys.every(i => selectedRowsKeys.includes(i))) {
          this.selectedRows = this.selectedRows.filter(r => !itemsInTableKeys.includes(this.getRowId(r)));
          return;
        }

        this.selectedRows = uniqBy([ ...this.selectedRows, ...this._items], this.idKey)
          .filter(r => this.isItemSelectable(r));
      },

      toggleSelectAllWithoutSsp() {
        this.selectedRows = this.allRowsSelected
          ? []
          : this.selectableRows;
      },

      setPage(page) {
        this.options.page = page;
      },

      getRowId(row) {
        return row[this._uniqKey];
      },

      onSave(action) {
        action.action(this.selectedRows);
      },

      // WARNING: checking _showSelect or _showExpand here can cause a cyclic dependency
      isItemSelectable(item) {
        const { isSelectableFn, selectableKey } = this.$props;

        if (isSelectableFn) {
          return isSelectableFn(item);
        }

        return get(item, selectableKey, true);
      },

      isItemExpandable(item) {
        const { isExpandableFn, expandableKey, groupedRows } = this.$props;

        if (isExpandableFn) {
          return isExpandableFn(item);
        }

        if (groupedRows) {
          return this.groupedRows__hasChildRows(item);
        }

        return get(item, expandableKey, true);
      },

      getNestingLevel(item) {
        if (this.$props.groupedRows) {
          return this.groupedRows__getNestingLevel(item);
        }

        return 0;
      },

      initiallyExpandAllGroupedRows() {
        if (this.$props.groupedRows && this.$props.expandAll) {
          this.groupedRows__initiallyExpandAll();
        }
      },

      setExpandedRows(expanded) {
        if (this.$props.groupedRows && this.$props.showExpand) {
          this.groupedRows__expanded = expanded;
          this.expandedRows = expanded;
          return;
        }

        // could use a computed for automatic check, or prevent passing prop to VDataTable
        if (this.$props.groupedRows) {
          this.groupedRows__expanded = expanded;
        }
        else {
          this.expandedRows = expanded;
        }
      },

      onExpandedRowsChange(expanded) {
        if (expanded !== this.expanded) {
          // 'update:expanded' works with `.sync` modifier and Vue3 `v-model:`
          this.$emit('expanded:change', expanded);
        }
      },

      isPopoverRow(rowItem) {
        const rowId = this.getRowId(rowItem);
        return this.activePopoverRowId === rowId;
      },

      onPopoverChange(event) {
        const { rowId, active, action } = event;
        const isSameCaller = (rowId === this.popoverRow?.rowId && (action?.label === this.popoverRow?.action?.label || action?.icon === this.popoverRow?.action?.icon)); // HACKY: checks by label/icon, by lack of name/id. Object reference breaks in some cases.
        if (!active && isSameCaller) {
          this.popoverRow = null; // only unset if the deactivating popover is the same as the open one.
        }
        else if (active) {
          this.popoverRow = { rowId, action };
        }
      },

      initStickyHeader() {
        // if there are filters take into consideration the height
        if (this.$refs.headingTable?.$el) {
          let headerHeight = this.$el.firstChild.offsetHeight;
          // if there are selected item add that height also
          if (this.$refs.selectedItems) {
            this.$refs.selectedItems.style.top = `${ headerHeight }px`;
            headerHeight += this.$refs.selectedItems.offsetHeight;
          }
          // set top of the thead
          this.$el.querySelector('thead').style.top = `${ headerHeight }px`;
          this.$el.querySelector('thead').classList.add(styles.tableHeader);
        }
      }
    }
  };
</script>
