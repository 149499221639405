import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { PopupType } from '@h4h/popups/src/constants/popupType';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { patientReactivate } from '../../../services/api/patients/archiving/archiving';

export const actions = Object.freeze({
  showRestorePopup,
  restorePatient,
  cancel,
});

async function showRestorePopup({ commit }, patientUuid) {
  const { id, closed } = await popups.show({
    name: PopupName.PatientRestorePopup,
    type: PopupType.Modal
  });
  commit(types.INIT_FORM, {
    popupId: id,
    patientUuid,
  });
  const result = await closed;
  return result.success && !!result.data;
}

async function restorePatient({ state, commit }) {
  commit(types.SET_LOADING, true);
  const result = await patientReactivate(state.patientUuid);
  commit(types.SET_LOADING, false);

  if (result.success) {
    popups.hide({
      id: state.popupId,
      result: true,
    });
  }
  else {
    showRestError(result.error, 'messages.cantRestorePatient');
  }
}

async function cancel({ state }) {
  popups.hide({
    id: state.popupId,
    result: false,
  });
}
