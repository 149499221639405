import { toast } from '../../services/toast';

import { types } from './types';
import { showRestError } from '../../utils/errors';
import { localize } from '../../services/localize';
import { questionnaireTemplates } from '../../services/api/questionnaire/questionnaireTemplates';
import { anamnesis } from '../../services/api/anamnesis/anamnesis';
import { AnamnesisComponentObjectsModel, AnamnesisInitModel } from './model';

export const actions = Object.freeze({
  init,
  save,
});

async function load(commit, initData) {
  commit(types.SET_ANAMNESIS_DATA, null);
  try {
    commit(types.SET_LOADING, true);
    if (!initData.anamnesisTemplateId) {
      toast.error(localize('anamnesis.anamnesisIsNotChosen'));
      return;
    }
    const templateResult = await questionnaireTemplates.get(initData.anamnesisTemplateId);
    if (!templateResult.success) {
      showRestError(templateResult.error, 'messages.cantFetchQuestionnaireTemplate');
      return;
    }

    const anamnesisResult = await anamnesis(initData.patientId).fetchOne();
    if (!anamnesisResult.success) {
      showRestError(anamnesisResult.error, 'messages.cantFetchAnamnesis');
      return;
    }

    commit(types.SET_ANAMNESIS_DATA, new AnamnesisComponentObjectsModel({
      patientId: initData.patientId,
      anamnesis: anamnesisResult.data,
      questionnaireTemplate: templateResult.data,
      terminologyTypes: initData.terminologyTypes,
    }));
  }
  finally {
    commit(types.SET_LOADING, false);
  }
}

/**
 *
 * @param state
 * @param commit
 * @param {AnamnesisInitModel} initData
 */
async function init({ commit }, initData) {
  await load(commit, initData);
}

async function save({ commit, state, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.valid) {
    return;
  }
  const questionnaire = getters.updatedQuestionnaire;
  commit(types.SET_LOADING, true);
  let anamnesisResult;
  if (state.anamnesis.questionnaire) {
    anamnesisResult = await anamnesis(state.patientId).updateOne(questionnaire);
  }
  else {
    anamnesisResult = await anamnesis(state.patientId).create(questionnaire);
  }

  commit(types.SET_LOADING, false);
  if (anamnesisResult.success) {
    toast.success(localize('messages.anamnesisUpdated'));
    load(commit, new AnamnesisInitModel({
      anamnesisTemplateId: state.questionnaireTemplate.uuid,
      patientId: state.patientId,
      terminologyTypes: state.terminologyTypes,
    }));
  }
  else {
    showRestError(anamnesisResult.error, 'messages.cantUpdateAnamnesis');
  }
}

