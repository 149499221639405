export function getInputValues(inputs) {
  const result = {};
  inputs.forEach(i => {
    const { value, multiple, id } = i;
    if (i.value === null || multiple && !value.size) {
      return;
    }

    if (multiple) {
      const arrayResult = Array.from(value).map(processSingleValue);

      result[id] = value instanceof Set
        ? new Set(arrayResult)
        : arrayResult;
    }
    else {
      result[id] = processSingleValue(value);
    }
  });

  return result;
}

export function isMultipleValue(type) {
  return type === 'Array' || type === 'Set';
}

function processSingleValue(value) {
  if (!value) {
    return null;
  }

  if (value.id) {
    return {
      id: value.id
    };
  }

  return value;
}
