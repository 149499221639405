import { InputType } from '../constants';

import { validateInput } from './validations';

export const SET_INPUT_VALUE = 'SET_INPUT_VALUE';
export const UPDATE_INPUT = 'UPDATE_INPUT';

export const InputMutationTypes = Object.freeze({
  SET_INPUT_VALUE,
  UPDATE_INPUT
});

export const InputMutations = Object.freeze({
  [SET_INPUT_VALUE]: setInputValueMutation,
  [UPDATE_INPUT]: updateInputMutation
});

/**
 * Updates input value
 *
 * Purposely made consistent with Vuex actions and mutations
 * which allow to pass only a single argument
 *
 * @param {InputConfig} input
 * @param {*}           value
 */
export function setInputValue({ input, value }) {
  input.value = value;

  // address inputs should be marked as dirty manually
  // since they are composed of multiple inputs
  if (input.type !== InputType.Address) {
    input.pristine = false;
  }

  input.valid = validateInput(input);
}

/**
 * @param {Store}       _
 * @param {InputConfig} input
 * @param {*}           value
 */
export function setInputValueMutation(_, { input, value }) {
  setInputValue({ input, value });
}

/**
 * @param {InputConfig}           input
 * @param {Partial<InputConfig>}  patch
 */
export function updateInput({ input, patch }) {
  Object.assign(input, patch);
  input.valid = validateInput(input);
}

/**
 * @param {Store}                 _
 * @param {InputConfig}           input
 * @param {Partial<InputConfig>}  patch
 */
export function updateInputMutation(_, { input, patch }) {
  updateInput({ input, patch });
}
/**
 * @param {Store}                 _
 * @param {InputConfig}           input
 * @param {String}                id // File id
 */
export function toggleFileRemoveMutation(_, { input, id }) {
  const itemIndex = input.value.findIndex(i => i.id === id);
  input.value[itemIndex].removed = !input.value[itemIndex].removed;
}
