export const types = Object.freeze({
  SET_OBSERVATIONS_LOADING: 'SET_OBSERVATIONS_LOADING',
  SET_GROUPED_OBSERVATIONS_PAGE: 'SET_GROUPED_OBSERVATIONS_PAGE',
  INIT_PAGE: 'INIT_PAGE',
  SET_OBSERVATION_TASK_MAP: 'SET_OBSERVATION_TASK_MAP',
  SET_SELECTED_OBSERVATIONS: 'SET_SELECTED_OBSERVATIONS',
  SET_PAGINATION_CONFIG: 'SET_PAGINATION_CONFIG',
  CLEAN: 'CLEAN',
  SET_PAGE_INDEX: 'SET_PAGE_INDEX',
});
