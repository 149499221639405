export const ActionType = Object.freeze({
  Create: 'create',
  Read: 'read',
  Update: 'update',
  Delete: 'delete',
  Single: 'single',
  Static: 'static'
});

export const RoleType = Object.freeze({
  RealmRole: 'RealmRole',
  ClientRole: 'ClientRole'
});

export const Role = Object.freeze({
  Admin: 'ADMIN'
});
