import { getInputsMap } from '@h4h/inputs';
import { buildSortedDataTableConfig, DataTableBuilderSortItem } from './dataTableConfigModel';
import { mapDataTablePageConfigToSpringPageConfig } from './page';
import { TaskListTypes } from '../constants/taskListTypes';
import moment from 'moment';
import { Severity, severityForTasks } from '../constants/severity';

export class TaskListModel {
  /**
   *
   * @param {Object}                config
   * @param {String}                config.type
   * @param {String}                config.userFiltersTableId
   * @param {Boolean}               config.hasSearch
   * @param {Boolean}               config.usesFilters
   * @param {Boolean}               config.usesCheckboxes
   * @param {Array}                 [config.disabledColumns]
   * @param {Object}                [config.staticInputValues]
   * @param {Object}                [config.inputExtensions]
   * @param {DataTableConfigModel}  [config.tableOptions]
   */
  constructor(config) {
    // TODO add static options for inputs
    this.type = config.type;
    this.userFiltersTableId = config.userFiltersTableId;
    this.usesCheckboxes = config.usesCheckboxes;
    this.disabledColumns = config.disabledColumns || [];
    this.staticInputValues = config.staticInputValues || {};
    this.inputExtensions = config.inputExtensions || {};

    const dateSortField = config.type === TaskListTypes.Default ? 'observationDate' : 'createdOn';
    let sortOptions = [
      new DataTableBuilderSortItem({
        field: dateSortField,
        sortAsc: true,
      }),
    ];
    if (this.type === TaskListTypes.Default) {
      sortOptions = [
        new DataTableBuilderSortItem({
          field: 'severity',
          sortAsc: true,
        }),
        ...sortOptions
      ];
    }
    this.tableOptions = buildSortedDataTableConfig(
      sortOptions,
      config.tableOptions || {},
    );
    this.serverSideOptions = this.tableOptions;

    // init the rest of the model attributes to defaults
    this.loading = false;
    this.tasksPage = null;
    this.selectedTasks = [];
    this.inputs = null;
    this.filterParams = null;
  }

  get inputsMap() {
    return getInputsMap(this.inputs);
  }

  /**
   * Gets only inputs that don't have a predefined static value
   */
  get visibleFilterInputs() {
    return this.inputs?.filter(input =>
      !this.staticInputValues?.[input.id]
      // && !['ownPatients'].includes(input.id)
    ) || [];
  }

  get paginationConfig() {
    return mapDataTablePageConfigToSpringPageConfig(this.tableOptions);
  }

  get showHandleButton() {
    return (this.type === TaskListTypes.Default) || (this.type === TaskListTypes.Informational);
  }

  get showPeriodChooser() {
    return this.type === TaskListTypes.Informational;
  }

  get supportSingleHandle() {
    return this.type === TaskListTypes.Informational;
  }

  get tasks() {
    return this.tasksPage ? this.tasksPage.content : [];
  }

  get tasksCount() {
    return this.tasksPage ? this.tasksPage.totalElements : 0;
  }

  get tasksListAvailableForView() {
    return !!this.inputs;
  }

  updateFilterParams() {
    const params = {};
    if (this.inputs) {
      this.inputs.forEach(input => {
        if (input.value) {
          if (input.id === 'period') {
            let period = [input.value, input.value];
            if (Array.isArray(input.value)) {
              period = input.value;
            }
            if (period.length === 0) {
              return;
            }
            if (period.length === 1) {
              period = [period[0], period[0]];
            }
            params['from'] = moment(period[0]).startOf('day').toDate();
            params['to'] = moment(period[1]).endOf('day').toDate();
            params['useObservationDateFiltering'] = this.type === TaskListTypes.Default;
          }
          else {
            let value = input.value;
            if (value instanceof Set) {
              if (!value.size) {
                if (input.id === 'severity') {
                  // on task page we want to show only [HIGH, MEDIUM, UNCLASSIFIED] severity.
                  // So, if nothing is chosen on the dropdown, then we restrict by these types, so other types of
                  // severity don't go to the response.
                  // It is acceptable only to SEVERITY dropdown, and not to the other dropdowns.
                  switch (this.type) {
                    case TaskListTypes.Default:
                      value = new Set(severityForTasks);
                      break;
                    case TaskListTypes.Informational:
                      value = new Set([Severity.INFO]);
                      break;
                    default:
                      value = new Set([]);
                  }
                }
                else {
                  return;
                }
              }
              value = Array.from(value);
            }
            params[input.id] = value;
          }
        }
      });
    }
    this.filterParams = params;
  }

}
