import { CrudService } from '../../../classes/crudService';
import { ObservationCommentModel } from '../../../model/observationCommentModel';

export const observationComments = observationUuid => new CrudService(
  `/observations/${ observationUuid }/comments`,
  data => new ObservationCommentModel(data),
  model => model
);

export function getObservationsComments(observationIds, config = null) {
  return new CrudService(
    '/observation-comments/search-queries',
    data => data.map(comment => new ObservationCommentModel(comment)),
    model => model
  ).create({ observationIds }, config);
}
