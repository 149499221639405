import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { formatDate } from '@h4h/date';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_ENROLLMENTS](state, value) {
    state.enrollments = value;
  },

  [types.SET_PATIENT](state, patient) {
    state.patient = patient;
    if (patient) {
      state.inputs = getInputsConfig(patient);
    }
    else {
      state.inputs = [];
    }
  },

};

function getInputsConfig(patient) {
  const initials = createTextInputConfig(patient.initials, 'initials', 'common.initials');
  const phone = createTextInputConfig(patient.phone, 'phone', 'common.telephoneNumber');
  const firstName = createTextInputConfig(patient.firstName, 'firstName', 'common.firstName');
  const middleName = createTextInputConfig(patient.middleName, 'middleName', 'common.middleName');
  const lastName = createTextInputConfig(patient.lastName, 'lastName', 'common.lastName');
  const dateOfBirth = createTextInputConfig(formatDate(patient.dateOfBirthAsDate), 'dateOfBirth', 'common.dateOfBirth');
  const externalPatientId = createTextInputConfig(patient.externalPatientId, 'externalPatientId', 'common.hisPatientId');
  let ssn = null;
  if (patient.bsn) {
    ssn = createTextInputConfig(patient.bsn, 'bsn', 'patient.ssn');
  }
  const email = createTextInputConfig(patient.email, 'email', 'common.email');

  return [
    externalPatientId,
    ssn,
    initials,
    firstName,
    middleName,
    lastName,
    phone,
    dateOfBirth,
    email,
  ];
}

function createTextInputConfig(value, id, label) {
  return createInputConfig({
    id,
    value,
    type: InputType.Label,
    label,
    disabled: true
  });
}
