import { addPatient } from './add';
import { list } from './list';
import { viewPatient } from './view';
import { avatar } from './avatar';
import { file } from './file';
import { responsiblePhysician } from './responsiblePhysician';
import { editPatient } from './edit';
import { restore } from './restore';
import { mandatoryPatientActions } from './mandatoryPatientActions';
import { lastXPatients } from './lastXPatients';

export const patients = {
  namespaced: true,
  modules: {
    addPatient,
    list,
    viewPatient,
    editPatient,
    avatar,
    file,
    restore,
    responsiblePhysician,
    mandatoryPatientActions,
    lastXPatients,
  }
};
