import { createInputConfig, InputType } from '@h4h/inputs';
import { findByKey } from '../../../../filters/findByKey';
import { AnswerOptionType, AnswerType } from '../../../../constants/answerType';
import { valueExtractorBuilder } from './valueExtractor';

/**
 * @param {QuestionDefinitionModel}      definition
 * @param {QuestionnaireResponseItem} questionnaireResponseItem
 * @param {TerminologyTranslationModel[]}   terminologyTypes
 */
export function createQuestionInputConfig(definition, questionnaireResponseItem, terminologyTypes) {
  const value = valueExtractorBuilder(definition)(questionnaireResponseItem);
  switch (definition.answerType) {
    case AnswerType.NUMERIC:
      return createNumericInput(value, definition, terminologyTypes);
    case AnswerType.INTEGER:
      return createIntInput(value, definition, terminologyTypes);
    case AnswerType.BOOLEAN:
      return createBooleanInput(value, definition, terminologyTypes);
    case AnswerType.FREE_STRING:
      return createStringInput(value, definition, terminologyTypes);
    case AnswerType.CHOICE:
      return createChoiceInput(value, definition, terminologyTypes);
  }
  return null;
}

/**
 * @param {*}   value
 * @param {QuestionDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createNumericInput(value, definition, terminologyTypes) {
  return createInputConfig({
    value,
    id: definition.questionTerminologyCode,
    type: InputType.Float,
    required: true,
    label: translateTerminology(definition.questionTerminologyCode, terminologyTypes),
    min: definition.min,
    max: definition.max,
    unit: translateTerminology(definition.answerTerminologyUnit, terminologyTypes),
  });
}

/**
 * @param {*}   value
 * @param {QuestionDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createIntInput(value, definition, terminologyTypes) {
  return createInputConfig({
    value,
    id: definition.questionTerminologyCode,
    type: InputType.Integer,
    required: true,
    label: translateTerminology(definition.questionTerminologyCode, terminologyTypes),
    min: definition.min,
    max: definition.max,
    unit: translateTerminology(definition.answerTerminologyUnit, terminologyTypes),
  });
}

/**
 * @param {*}   value
 * @param {QuestionDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createBooleanInput(value, definition, terminologyTypes) {
  return createInputConfig({
    value,
    id: definition.questionTerminologyCode,
    type: InputType.Boolean,
    required: true,
    label: translateTerminology(definition.questionTerminologyCode, terminologyTypes),
  });
}

/**
 * @param {*}   value
 * @param {QuestionDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createStringInput(value, definition, terminologyTypes) {
  return createInputConfig({
    value,
    id: definition.questionTerminologyCode,
    type: InputType.Text,
    required: true,
    label: translateTerminology(definition.questionTerminologyCode, terminologyTypes),
    min: definition.min,
    max: definition.max,
  });
}

/**
 * @param {*}   value
 * @param {QuestionDefinitionModel}    definition
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function createChoiceInput(value, definition, terminologyTypes) {
  return createInputConfig({
    value,
    id: definition.questionTerminologyCode,
    type: InputType.Select2,
    required: true,
    clearable: false,
    label: translateTerminology(definition.questionTerminologyCode, terminologyTypes),
    options: definition.answersValueSet
      .map(answerOptionModel => getOptionItem(answerOptionModel, terminologyTypes))
      .filter(v => !!v),
  });
}

/**
 * @param {AnswerOptionModel}    answerOptionModel
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function getOptionItem(answerOptionModel, terminologyTypes) {
  switch (answerOptionModel.type) {
    case AnswerOptionType.FREE_STRING :
      return {
        value: answerOptionModel.answerFreeString,
        label: answerOptionModel.answerFreeString,
      };
    case AnswerOptionType.INTEGER :
      return {
        value: '' + answerOptionModel.answerInt,
        label: '' + answerOptionModel.answerInt,
      };
    case AnswerOptionType.NUMERIC :
      return {
        value: '' + answerOptionModel.answerNumeric,
        label: '' + answerOptionModel.answerNumeric,
      };
    case AnswerOptionType.TERMINOLOGY :
      return {
        value: answerOptionModel.answerTerminology,
        label: translateTerminology(answerOptionModel.answerTerminology, terminologyTypes),
      };
  }
}

/**
 *
 * @param {String}                        type
 * @param {TerminologyTranslationModel[]} terminologyTypes
 */
function translateTerminology(type, terminologyTypes) {
  return findByKey(type, 'key', terminologyTypes, 'translation');
}
