import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';
import { EhPatterns } from '../../utils/ehPatterns';
import { createAddressInputConfig } from '../../utils/addressComponentUtils';
import { createPhoneInputConfig } from '../../utils/phone/phoneComponentUtils';
// import { localize } from '../../services/localize';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },
  [types.SET_ORGANIZATION_SETTING](state, organizationSetting) {
    state.organizationSetting = organizationSetting;
  },
  [types.SET_TIMEZONES](state, timezones) {
    state.timezones = timezones;
  },
  [types.INIT_FORM](state, { readOnly, isAdmin, countries }) {
    const {
      inputs,
      customForwardingLinkToDoctor
    } = getInputsConfig(state.organizationSetting || {}, readOnly, state.timezones, isAdmin, countries);
    state.inputs = inputs;
    state.customForwardingLinkToDoctor = customForwardingLinkToDoctor;
    state.inputsMap = getInputsMap(inputs);
  },
  [types.SET_FETCHED](state, value) {
    state.fetched = value;
  },
  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => {
      input.pristine = value;
    });
  },
  [types.SET_ADDRESS_COMPONENTS_PRISTINE](state, { components, value }) {
    if (Array.isArray(components)) {
      components.forEach(input => input.pristine = value);
    }
  },
  [types.SET_CC_SETTINGS_LOADING](state, value) {
    state.callCenterSettingsloading = value;
  },
  [types.SET_CC_SETTINGS](state, value) {
    state.callCenterSettings = value;
  },
  [types.INIT_POPUP](state, value) {
    state.activePopupId = value;
  },

};

function getInputsConfig(organizationSetting, readOnly, timezones, isAdmin, countryOptions) {

  const inputType = readOnly ? InputType.Label : InputType.Text;
  let timeZone = organizationSetting.defaultTimeZone;
  let defaultTimeZone = createTextInputConfig(timeZone, 'defaultTimeZone', 'common.defaultTimeZone', InputType.Label);
  if (timezones) {
    if (readOnly) {
      const title = timezones.availableTimeZones.find(zone => zone.name === timeZone)?.displayName;
      defaultTimeZone = createTextInputConfig(title || timeZone, 'defaultTimeZone', 'common.defaultTimeZone', InputType.Label);
    }
    else {
      defaultTimeZone = createInputConfig({
        id: 'defaultTimeZone',
        type: InputType.Select2,
        value: timeZone,
        clearable: false,
        required: true,
        label: 'common.defaultTimeZone',
        options: timezones.availableTimeZones.map(zone => ({ label: zone.displayName, value: zone.name })),
      });
    }
  }
  let phoneNumber;
  if (readOnly) {
    phoneNumber = createTextInputConfig(organizationSetting.phoneNumber, 'phoneNumber', 'common.phoneNumber', inputType, false, EhPatterns.phoneNumber);
  }
  else {
    phoneNumber = createPhoneInputConfig(organizationSetting.phoneNumber, 'phoneNumber','common.phoneNumber',true, { onlyStandardCountries: true });
  }
  const biography = createTextInputConfig(organizationSetting.biography, 'biography', 'common.biography', inputType);
  const clinicName = createTextInputConfig(organizationSetting.clinicName, 'clinicName', 'common.clinicName', inputType);
  const replyLink = createTextInputConfig(organizationSetting.replyLink, 'replyLink', 'common.replyLink', inputType, false, EhPatterns.email);
  let tenantCountry;
  if (readOnly) {
    const countryName = countryOptions.find(country => country.code2 === organizationSetting.tenantCountry)?.name || organizationSetting.tenantCountry;
    tenantCountry = createTextInputConfig(countryName, 'tenantCountry', 'tenant.country', inputType, false);
  }
  else {
    tenantCountry = createInputConfig({
      id: 'tenantCountry',
      type: InputType.Select2,
      value: organizationSetting.tenantCountry,
      clearable: false,
      required: true,
      label: 'tenant.country',
      options: countryOptions.map(country => ({
        value: country.code2,
        label: `${ country.name } ${ country.code2 }`
      })),
    });
  }
  const inputs = [
    defaultTimeZone,
    phoneNumber,
    biography,
    clinicName,
    replyLink,
    tenantCountry,
  ];
  if (isAdmin) {
    const showDisableDirectLoginSsoDropdown = createTextInputConfig(organizationSetting.showDisableDirectLoginSsoDropdown, 'showDisableDirectLoginSsoDropdown', 'common.showDisableDirectLoginDropdown', InputType.Checkbox, readOnly);
    const bsnRegexp = createTextInputConfig(organizationSetting.bsnRegexp, 'bsnRegexp','common.bsnRegexp', inputType, readOnly);
    if (!readOnly) {
      bsnRegexp.info = 'common.pleaseUseValidRegexpForThisField';
    }
    const applyDutchDeepBsnCheck = createTextInputConfig(organizationSetting.applyDutchDeepBsnCheck, 'applyDutchDeepBsnCheck', 'common.applyDutchDeepBsnCheck', InputType.Checkbox, readOnly);
    inputs.push(bsnRegexp, applyDutchDeepBsnCheck, showDisableDirectLoginSsoDropdown);

  }

  if (!readOnly) {
    inputs.push(
      createAddressInputConfig(organizationSetting.address, countryOptions, 'address', 'common.postalAddress', true)
    );
  }
  let customForwardingLinkToDoctor = null;
  if (isAdmin) {
    if (!readOnly || organizationSetting.customForwardingLinkToDoctor) {
      customForwardingLinkToDoctor = createTextInputConfig(organizationSetting.customForwardingLinkToDoctor, 'customForwardingLinkToDoctor', 'organization.customForwardingLinkToDoctor', inputType);
    }
  }

  return { inputs, customForwardingLinkToDoctor };
}

function createTextInputConfig(value = null, id, label, inputType, disabled = false, pattern, required = false) {
  return createInputConfig({
    id,
    type: inputType,
    pattern,
    value,
    required,
    label,
    disabled: disabled
  });
}

// function createTreatmentAdviceEnabledInputConfig(value = null, id, label, required = false) {
//   return createInputConfig({
//     id,
//     type: InputType.Checkbox,
//     value,
//     required,
//     label,
//   });
// }

