import { Page } from '../../../../constants/page';

export let observationTemplates = {
  path: 'questionnaire-templates',
  name: Page.AdminQuestionnaireTemplates,
  component: () => import('../../../../components/views/app/admin/questionnaireTemplates/QuestionnaireTemplates'),
  redirect: { name: Page.AdminQuestionnaireTemplatesList },
  meta: {
    title: 'questionnaireTemplates.questionnaireTemplates'
  },
  children: [
    {
      path: 'list',
      name: Page.AdminQuestionnaireTemplatesList,
      component: () => import('../../../../components/views/app/admin/questionnaireTemplates/list/QuestionnaireTemplateList'),
      meta: {
        title: 'questionnaireTemplates.questionnaireTemplates'
      },
    },
    {
      path: 'add',
      name: Page.AdminQuestionnaireTemplatesAdd,
      component: () => import('../../../../components/views/app/admin/questionnaireTemplates/add/AddQuestionnaireTemplate'),
      meta: {
        title: 'questionnaireTemplates.addQuestionnaireTemplate'
      },
    },
    {
      path: 'view/:uuid',
      name: Page.AdminQuestionnaireTemplatesView,
      props: true,
      component: () => import('../../../../components/views/app/admin/questionnaireTemplates/view/ViewQuestionnaireTemplate'),
      meta: {
        title: 'questionnaireTemplates.questionnaireTemplate'
      },
    },
    {
      path: 'edit/:uuid',
      name: Page.AdminQuestionnaireTemplatesEdit,
      props: true,
      component: () => import('../../../../components/views/app/admin/questionnaireTemplates/edit/EditQuestionnaireTemplate'),
      meta: {
        title: 'questionnaireTemplates.questionnaireTemplate'
      },
    },
  ]
};
