import { Shared } from './shared';
import { getFilteredRowGroups, getNestingLevel, getRowMap, getRowsByParentIdMap } from './groupedRowsUtils';

export const GroupedRows = {
  props: {
    columns: Shared.props.columns,
    expanded: Shared.props.expanded,
    groupedRows: Shared.props.groupedRows,

    idKey: Shared.props.idKey,
    parentRowIdKey: Shared.props.parentRowIdKey,
  },

  data() {
    return {
      groupedRows__expanded: [],
      groupedRows__initialExpandCompleted: false
    };
  },

  computed: {
    groupedRows__items() {
      return [];
    },

    groupedRows__rowMap() {
      return getRowMap(this.groupedRows__items, this.$props.idKey);
    },

    groupedRows__rowsByParentIdMap() {
      return getRowsByParentIdMap(this.groupedRows__items, this.$props.parentRowIdKey);
    }
  },

  methods: {
    groupedRows__getRowId(row) {
      return row[this.$props.idKey];
    },

    groupedRows__isRowExpanded(row) {
      return this.groupedRows__expanded.includes(row);
    },

    groupedRows__hasChildRows(row) {
      const rowId = this.groupedRows__getRowId(row);

      return !!this.groupedRows__rowsByParentIdMap[rowId]?.length;
    },

    groupedRows__getNestingLevel(row) {
      const { idKey, parentRowIdKey } = this.$props;
      return getNestingLevel(row, idKey, parentRowIdKey, this.groupedRows__rowMap);
    },

    groupedRows__toggleRowExpand(row) {
      if (this.groupedRows__isRowExpanded(row)) {
        this.groupedRows__expanded = this.groupedRows__expanded.filter(r => r !== row);
      }
      else {
        this.groupedRows__expanded = this.groupedRows__expanded.concat(row);
      }
    },

    groupedRows__getFilteredShownRows(rows) {
      const { groupedRows, idKey, parentRowIdKey } = this.$props;

      if (!groupedRows) {
        return rows;
      }

      return getFilteredRowGroups(
        rows,
        idKey,
        parentRowIdKey,
        this.groupedRows__rowMap,
        this.groupedRows__rowsByParentIdMap,
        this.groupedRows__expanded
      );
    },

    groupedRows__initiallyExpandAll() {
      if (this.groupedRows__initialExpandCompleted || !this.groupedRows__items.length) {
        return;
      }

      this.groupedRows__expandAll();
      this.groupedRows__initialExpandCompleted = true;
    },

    groupedRows__expandAll() {
      this.groupedRows__expanded = this.groupedRows__items.filter(this.groupedRows__hasChildRows);
    }
  }
};
