import { isNumber } from 'lodash';
import { makeSeverityConditionId, SeverityConditionGroup } from './SeverityConditionGroup';
import {
  createInputConfig,
  InputType,
  setInputValueAction,
  validateFloatInput,
  validateIntegerInput
} from '@h4h/inputs';
import { ObservationInputType } from '../../../../../constants/observationInputType';
import {
  greaterThanField,
  lessThanField,
  TriggerConditionModel
} from '../../../../../model/thresholds/triggerConditionModel';
import { SeverityConditionsLabelTranslationMap, SeverityConditionsSortOrder } from './SeverityConditionTranslationMap';
import { localize } from '../../../../../services/localize';
import {
  ObservationThresholdSeverityConditionsModel
} from '../../../../../model/thresholds/observationThresholdSeverityConditionsModel';
import { TriggerConditionsModel } from '../../../../../model/thresholds/triggerConditionsModel';
import { findByKey } from '../../../../../filters/findByKey';
import { ObservationThresholdModel } from '../../../../../model/thresholds/observationThresholdModel';
import { Severity } from '../../../../../constants/severity';
import { TriggerConditionListItemModel } from '../../../../../model/thresholds/triggerConditionListItemModel';
import { ChangedStatus } from '../../../../../constants/changedStatus';

function createInvalidInputValidationMessage(lessInput, greaterInput) {
  const greater = localize(SeverityConditionsLabelTranslationMap[greaterInput.id]);
  const less = localize(SeverityConditionsLabelTranslationMap[lessInput.id]);
  return localize('observationTemplates.xMustNotBeLowerThanY', [greater, less]);
}

function validateThresholdInput(input) {
  input.validationMessage = '';
  let baseValid = false;
  switch (input.type) {
    case InputType.Float:
      baseValid = validateFloatInput(input);
      break;
    case InputType.Integer:
      baseValid = validateIntegerInput(input);
      break;
  }
  if (!baseValid) {
    return false;
  }
  if (!isNumber(input.value)) {
    return true;
  }
  const sortedInputs = SeverityConditionsSortOrder
    .map(id => input.allInputs.find(input => input.id === id))
    .filter(x => !!x)
    .filter(input => isNumber(input.value));
  const index = sortedInputs.indexOf(input);
  if (index > 0) {
    if (sortedInputs[index - 1].value > input.value) {
      input.validationMessage = createInvalidInputValidationMessage(sortedInputs[index - 1], input);
      hideValidationMessageForFocusedInput(input);
      return false;
    }
  }
  if (index < sortedInputs.length - 1) {
    if (sortedInputs[index + 1].value < input.value) {
      input.validationMessage = createInvalidInputValidationMessage(input, sortedInputs[index + 1]);
      hideValidationMessageForFocusedInput(input);
      return false;
    }
  }
  return true;
}

function hideValidationMessageForFocusedInput(input) {
  if (!input.focused) {
    return;
  }
  if (input.validBeforeFocus) {
    input.validationMessage = ''; // don't show validation message if focused input was valid before focus
  }
}

export class ThresholdInputsGroup {
  /**
   *
   * @param config
   * @param {ObservationThresholdSeverityConditionsModel} config.observationThresholdSeverityConditionsModel
   * @param {ObservationTemplateModel} config.observationTemplateModel
   * @param {Object[]} config.terminologyTypes
   * @param {Boolean} config.inputAreRequired
   * @param {Boolean} config.highlightModified
   */
  constructor(config) {
    this.observationThresholdSeverityConditionsModel = config.observationThresholdSeverityConditionsModel;
    this.observationTemplateModel = config.observationTemplateModel;
    this.terminologyTypes = config.terminologyTypes;
    this.inputAreRequired = config.inputAreRequired;
    this.highlightModified = !!config.highlightModified;
    this.groups = this.observationThresholdSeverityConditionsModel.conditions.list
      .map(condition => {
        let inputType;
        switch (config.observationThresholdSeverityConditionsModel.inputType) {
          case ObservationInputType.INT :
            inputType = InputType.Integer;
            break;
          case ObservationInputType.NUMERIC :
            inputType = InputType.Float;
            break;
        }
        if (!inputType) {
          return null;
        }
        let field;
        let conditionObject = condition.condition || condition.originalCondition;
        if (isNumber(conditionObject[greaterThanField])) {
          field = greaterThanField;
        }
        if (isNumber(conditionObject[lessThanField])) {
          field = lessThanField;
        }
        if (!field) {
          return null;
        }
        let originalValue = conditionObject[field];
        if (condition.originalCondition) {
          originalValue = condition.originalCondition[field];
        }
        const input = createInputConfig({
          value: conditionObject[field],
          originalValue,
          id: makeSeverityConditionId(condition.severity, field),
          type: inputType,
          required: !!this.inputAreRequired,
          validate: validateThresholdInput,
          unit: this.unit,
          allInputs: [],
        });
        return new SeverityConditionGroup({
          condition,
          field,
          input,
          highlightModified: this.highlightModified,
        });
      })
      .filter(x => !!x);
    this.refreshGroupsIndexes();
  }

  toObservationThresholdSeverityConditionsModel(modifiedOnly = false) {
    return new ObservationThresholdSeverityConditionsModel({
      ...this.observationThresholdSeverityConditionsModel,
      conditions: new TriggerConditionsModel({
        list: this.groups
          .filter(group => !modifiedOnly || group.modified)
          .map(group => group.toTriggerConditionListItemModel())
          .filter(x => !!x),
      }),
    });
  }

  hasChangedGroups() {
    return this.groups.some(group => group.modified);
  }

  removeAddedItem(group) {
    let index = this.groups.indexOf(group);
    if (index === -1) {
      return;
    }
    this.groups.splice(index, 1);
    this.refreshGroupsIndexes();
  }

  refreshGroupsIndexes() {
    let notDeletedGroups = this.groups.filter(group => !group.deleted);
    notDeletedGroups.forEach(group => {
      group.allGroups = notDeletedGroups;
      group.input.allInputs = notDeletedGroups.map(group => group.input);
    });
  }

  hasMissingItems() {
    return this.groups.length < 4;
  }

  addMissingItems() {
    if (this.groups.length === 4) {
      return;
    }
    const has = (severity, field) => this.groups.some(group => group.severity === severity && group.field === field);
    const restoreIfNeed = (severity, field, order) => {
      if (!has(severity, field)) {
        let inputType;
        switch (this.observationThresholdSeverityConditionsModel.inputType) {
          case ObservationInputType.INT :
            inputType = InputType.Integer;
            break;
          case ObservationInputType.NUMERIC :
            inputType = InputType.Float;
            break;
        }
        const input = createInputConfig({
          value: null,
          id: makeSeverityConditionId(severity, field),
          type: inputType,
          required: !!this.inputAreRequired,
          validate: validateThresholdInput,
          unit: this.unit,
          allInputs: [],
        });
        let severityConditionGroup = new SeverityConditionGroup({
          condition: new TriggerConditionListItemModel({
            severity,
            condition: new TriggerConditionModel({}),
            changedStatus: ChangedStatus.CREATED,
            order,
          }),
          field,
          input,
          highlightModified: this.highlightModified,
        });
        this.groups.splice(order, 0, severityConditionGroup);
      }
    };
    restoreIfNeed(Severity.HIGH, greaterThanField, 0);
    restoreIfNeed(Severity.MEDIUM, greaterThanField, 1);
    restoreIfNeed(Severity.MEDIUM, lessThanField, 2);
    restoreIfNeed(Severity.HIGH, lessThanField, 3);
    this.refreshGroupsIndexes();
  }

  hasDeletedItems() {
    return this.groups.some(group => group.deleted);
  }

  get unit() {
    const type = this.observationThresholdSeverityConditionsModel.type;
    const definition = this.observationTemplateModel.definitions.find(definition => definition.type === type);
    let unitCode = definition && definition.unitCode;
    if (unitCode) {
      unitCode = findByKey(unitCode, 'key', this.terminologyTypes, 'translation');
    }
    return unitCode;
  }

  get valid() {
    return this.groups.every(group => group.valid);
  }

  resetThresholdsToOriginalValues() {
    this.groups.forEach(group => group.resetToOriginalValues());
  }

  setPristine(value) {
    this.groups.forEach(group => group.input.pristine = value);
  }
}

export function createResetThresholdInputAction() {
  return async function({ commit, dispatch }, input) {
    await setInputValueAction({ commit, dispatch }, { input, value: input.originalValue });
  };
}

export class ObservationThresholdInputsGroup {
  /**
   *
   * @param config
   * @param {ObservationThresholdModel} config.observationThresholdModel
   * @param {ObservationTemplateModel} config.observationTemplateModel
   * @param {Object[]} config.terminologyTypes
   * @param {Boolean} config.inputAreRequired
   * @param {Boolean} config.highlightModified
   */
  constructor(config) {
    this.observationThresholdModel = config.observationThresholdModel;
    this.observationTemplateModel = config.observationTemplateModel;
    this.terminologyTypes = config.terminologyTypes;
    this.inputAreRequired = config.inputAreRequired;
    this.highlightModified = config.highlightModified;
    this.thresholdInputsGroups = this.observationThresholdModel
      .severityConditionPerDefinition
      .map(severityConditionPerDefinition => new ThresholdInputsGroup({
        terminologyTypes: this.terminologyTypes,
        observationTemplateModel: this.observationTemplateModel,
        observationThresholdSeverityConditionsModel: severityConditionPerDefinition,
        inputAreRequired: this.inputAreRequired,
        highlightModified: this.highlightModified,
      }));
  }

  get id() {
    return this.observationTemplateModel.uuid;
  }

  get valid() {
    return this.thresholdInputsGroups.every(thresholdInputsGroup => thresholdInputsGroup.valid);
  }

  resetThresholdsToOriginalValues() {
    this.thresholdInputsGroups.forEach(thresholdInputsGroup => thresholdInputsGroup.resetThresholdsToOriginalValues());
  }

  getPersonalizedThresholdModel() {
    const threshold = new ObservationThresholdModel({
      ...this.observationThresholdModel,
      severityConditionPerDefinition: this.thresholdInputsGroups
        .filter(thresholdInputsGroup => thresholdInputsGroup.hasChangedGroups())
        .map(tig => tig.toObservationThresholdSeverityConditionsModel(true))
    });

    if (!threshold.severityConditionPerDefinition.length) {
      return null;
    }
    return threshold;
  }

  setPristine(value) {
    this.thresholdInputsGroups.forEach(thresholdInputsGroup => thresholdInputsGroup.setPristine(value));
  }

}
