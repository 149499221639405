export class TerminologyTranslationContainerModel {
  /**
  * TerminologyTranslationContainerModel config
  *
  * @param {Object}                        config
  * @param {String}                        config.locale
  * @param {TerminologyTranslationModel[]} config.i18n
  */
  constructor(config) {
    config = config || {};

    this.i18n = config.i18n;
    this.locale = config.locale;
  }
}