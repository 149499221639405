export const state = {
  loading: false,
  comments: [],
  comment: null,

  // Popup - adding, viewing, editing comment
  observationId: null,
  inputs: null,
  popupId: null,
  popupObservationType: null,
  popupViewModeOn: false,
};
