export const state = {
  patientId: null,
  loading: false,
  questionnaireTemplate: null,
  questionnaire: null,
  anamnesis: null,
  inputs: [],
  secondaryInputs: [],
  terminologyTypes: [],
};
