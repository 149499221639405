import { EnrollmentStatus } from '../../constants/enrollmentStatus';

export const enrollments = {
  enrollments: 'Programma’s',
  enrollment: 'Inschrijving',
  enrollPatientToTheProgram: 'Inschrijven',
  addProgramToPatient: 'Programma toevoegen aan patiënt',
  stopEnrollment: 'Uitschakelen',
  enroll: 'Schrijf',
  settingsType: 'Type instellingen',
  monitoringPeriod: 'Protocol',
  treatmentAdvice: 'Behandeladvies',
  treatmentAdviceAgree: 'Ik ben het eens met het advies',
  treatmentAdviceDisagree: 'Ik ben het niet eens met het advies',
  stopConfirmQuestion: 'Weet je zeker dat je de inschrijving wilt beëindigen voordat de duur is afgelopen?',
  reason: 'Reden van stoppen',
  stopHeader: 'Reden van stoppen',
  stopDisclaimer: 'Selecteer de reden van stoppen van {1} voor {0} hieronder',
  stoppingEnrollments: 'Inschrijvingen stoppen...',
  stoppedOn: 'Stopgezet op',
  active: 'Actief',
  history: 'Historie',
  registrationDate: 'Registratiedatum',
  deregistrationDate: 'Deregistratiedatum',
  activationDate: ' Activatiedatum',
  period: 'Periode',
  dayShort: 'd',
  weekShort: 'w',
  yearShort: 'j',
  schedule: 'Schema',
  standard: 'Standaard',
  adjusted: 'Aangepast',
  inMonths: 'Maanden',
  weekOfMonth: 'Week van de maand',
  onDay: 'Dag',
  onDaysOfMonth: 'Dagen van de maand',
  onDaysOfWeek: 'Dagen van de week',
  measureAt: 'Op tijdstip',
  standardScheduleInfo: 'Standaard schema',
  editSchedule: 'Bewerk schema',
  sendDevicesToPatients: 'Stuur het apparaat naar de patiënt',
  giveOptionToSendDeviceToPatient: 'Geef de optie om het apparaat naar de patiënt te sturen',
  confirmCurrentAddress: 'Bevestig het huidige adres',
  updateCurrentAddress: 'Werk het huidige adres bij',
  requestPatientAddress: 'Opvragen patiënt adres',
  requestPatientAddressExplanation: 'Het meest recente adres van de patiënt kan niet verbonden zijn met hun profiel. Klik de onderstaande knop om in de app een taak naar de patiënt te sturen om hun adres op te vragen en om door te gaan met de inschrijving. Nadat de patiënt het adres heeft ingevuld, zal de logistieke afdeling het apparaat versturen.',
  noSchemeAvailable: 'Geen schema beschikbaar',
  scheduleEditabilityInfo: 'Het is niet mogelijk om schema\'s aan te passen, wanneer er geen schema\'s zijn aangemaakt in het desbetreffende programma',
  addAddress: 'Adres toevoegen',
  waitForPendingPatientAddressRequest: 'Wacht op een in behandeling zijnde patiëntverzoek',
  continueEnrollment: 'Ga door met inschrijving',
  status: 'Status',
  adjustThresholdHint: 'U kunt de standaard grenswaarden zelf aanpassen.',
  schedules: 'Schema’s',
  adjustScheduleHint: 'U kunt de schema’s zelf aanpassen per observatie en vragenlijst.',
  programOverview: 'Programmaoverzicht',
  [EnrollmentStatus.PENDING]: 'In afwachting',
  [EnrollmentStatus.ACTIVE]: 'Actief',
  [EnrollmentStatus.DEACTIVATED]: 'Gedeactiveerd',
  stoppedBy: 'Door',
  selectAPeriod: 'Selecteer een periode',
  measuringSchedule: 'Meetschema',
  cantCreateEnrollmentTheAddressOfThePatientIsUnknownAndNotRequested: 'Kan geen inschrijving maken, patiënt adres is onbekend en niet aangevraagd.',
  other: 'Overig',
  otherPleaseSpecify: 'Overig, namelijk',
};
