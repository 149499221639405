<template>
  <div :class="styles.container">
    <div
      v-if="$props.label"
      :class="[styles.label, 'lineLoading_label']"
    >
      {{ $localize($props.label) }}
    </div>

    <div :class="[styles.spinner, 'lineLoading_spinner']">
      <div :class="[styles.line1, 'lineLoading_line1']"/>
      <div :class="[styles.line2, 'lineLoading_line2']"/>
    </div>
  </div>
</template>

<script>
  import styles from './lineLoading.scss';

  export default {
    name: 'H4hLineLoading',
    props: {
      label: [String, Array],
    },

    data() {
      return {
        styles
      };
    }
  };
</script>
