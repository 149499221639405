/* global VERSION_API_URL */

import { CrudService } from '../../../classes/crudService';
import { ProductVersionModel, UdiDiModel } from '../../../model/certification';

export const certificationService = new CrudService(
  '/product/version',
  data => new ProductVersionModel(data),
  model => model,
  VERSION_API_URL
);
export const EhealthPortalProductName = 'ehealth-portal';
export const udiDiService = new CrudService(
  '/udi-di',
  data => new UdiDiModel(data),
  model => model,
  VERSION_API_URL
);

