import { types } from './types';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { PopupType } from '@h4h/popups';

export const actions = Object.freeze({
  initPopup,
  remove,
  cancel,
});

async function initPopup({ commit }, labResultGroup) {
  const { id, closed } = await popups.show({
    name: PopupName.DeleteLabResultGroup,
    type: PopupType.Modal
  });
  commit(types.INIT_POPUP, { popupId: id });
  commit(types.SET_SELECTED_GROUP, { labResultGroup });
  return { closed };
}

async function remove({ state }) {
  popups.hide({ id: state.popupId, result: state.selectedGroup });
}

async function cancel({ state, commit }) {
  commit(types.DESELECT_GROUP);
  popups.hide({ id: state.popupId });
}
