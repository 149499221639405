import { CrudService } from '../../../classes/crudService';

export const currentLanguage = new CrudService(
  '/i18n',
  data => data
);
export const currentCcLanguage = new CrudService(
  '/i18n/call-center-user-language',
  data => data
);

