import { createInputConfig, InputType } from '@h4h/inputs';

export const filerConstraints = Object.freeze({
  min: 3,
  validationMessage: ['common.minCharacters', 3],
});
export const shortFilerConstraints = Object.freeze({
  min: 2,
  validationMessage: ['common.minCharacters', 2],
});
export function createMainFilterInput(customizations) {
  const icon = customizations?.label ? '' : 'search';
  const constraints = customizations?.label === 'common.name' ? shortFilerConstraints : filerConstraints;
  return createInputConfig({
    id: 'filterInput',
    icon,
    type: InputType.Text,
    value: '',
    label: 'common.search',
    ...constraints,
    ...customizations,
  });
}
