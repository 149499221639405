import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';

export const mutations = {
  [types.SET_FETCHED](state, value) {
    state.fetched = value;
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_MEDICAL_HISTORIES](state, medicalHistories) {
    state.medicalHistories = medicalHistories;
  },

  [types.INIT_ADD_POPUP](state, { popupId }) {
    state.popupId = popupId;
    state.medicalHistory = createInputConfig({
      id: 'medicalHistory',
      type: InputType.Textarea,
      required: true,
      value: null,
      label: 'patient.newMedicalHistory',
    });
  },

  [types.INIT_EDIT_POPUP](state, { popupId, value }) {
    state.popupId = popupId;
    state.medicalHistory = createInputConfig({
      id: 'editMedicalHistory',
      type: InputType.Textarea,
      required: true,
      value: value,
      label: 'patient.editMedicalHistory',
    });
  },

  [types.SET_EDITABLE_MEDICAL_HISTORY_UUID](state, value) {
    state.editableMedicalHistoryUuid = value;
  },

  [types.SET_PATIENT_USER_UUID](state, value) {
    state.patientUserUuid = value;
  },
};
