import { isNil, isPlainObject } from 'lodash';

class Prop {
  constructor({ type, required, validator, defaultValue }) {
    this.type = type;
    this.required = required;
    this.validator = validator;
    this.default = defaultValue;
  }
}

export class ArrayProp extends Prop {
  constructor(config) {
    super(config);

    // Vue validates the prop too and expects type to return
    // the "type" of the value that is passed
    const type = this.type;
    this.type = Array;

    const validator = this.validator || (value => checkByType(value, type));

    this.validator = function(value) {
      if (isNil(value)) {
        return !this.required;
      }

      return Array.isArray(value) && value.every(validator);
    };
  }
}

export class EnumProp extends Prop {
  constructor(config) {
    super(config);

    // Vue validates the prop too and expects type to return
    // the "type" of the value that is passed
    const type = this.type;
    this.type = String;

    const validator = this.validator || (value => !!type[value]);

    this.validator = function(value) {
      if (isNil(value)) {
        return !this.required;
      }

      return validator(value);
    };
  }
}

// type checking is copy-pasted and adapted from vue/src/core/util/props.js
const simpleCheckRE = /^(String|Number|Boolean|Function|Symbol)$/;

/**
 * If array of types is provided the value should match at least one
 * otherwise check with the only type provided
 */
function checkByType(value, type) {
  if (Array.isArray(type)) {
    return type.some(t => checkType(value, t));
  }
  return checkType(value, type);
}

function checkType(value, type) {
  const expectedType = getType(type);

  if (simpleCheckRE.test(expectedType)) {
    const t = typeof value;

    return t === expectedType.toLowerCase()
      ? true
      // for primitive wrapper objects
      : t === 'object' && value instanceof type;
  }
  else if (expectedType === 'Object') {
    return isPlainObject(value);
  }
  else {
    return value instanceof type;
  }
}

/**
 * Use function string name to check built-in types,
 * because a simple equality check will fail when running
 * across different vms / iframes.
 */
function getType(fn) {
  const match = fn && fn.toString().match(/^\s*function (\w+)/);
  return match ? match[1] : '';
}
