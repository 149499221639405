import { CrudService } from '../../../classes/crudService';
import { MandatoryPatientAction } from '../../../model/mandatoryPatientAction';
import { MandatoryPatientActionType } from '../../../constants/mandatoryPatientActionType';

export function getMandatoryPatientAddressActions(patientId) {
  return new CrudService(
    `/patients/${ patientId }/mandatory-patient-actions`,
    data => new MandatoryPatientAction(data),
  ).fetch({
    params: { type: MandatoryPatientActionType.ADDRESS_REQUEST }
  });
}

export function sendMandatoryPatientActionAddressRequest(patientId) {
  return new CrudService(
    `/patients/${ patientId }/send-address-request`,
    data => new MandatoryPatientAction(data),
  ).updateOne();
}

