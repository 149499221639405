import { PopupName as DefaultPopupName } from '@h4h/popups';

export const PopupName = Object.freeze({
  ...DefaultPopupName,
  NewObservation: 'NewObservation',
  ChooseObservationGroup: 'ChooseObservationGroup',
  ObservationComment: 'ObservationComment',
  ObservationThreshold: 'ObservationThreshold',
  ChooseQuestionnaireTemplateForAnamnesis: 'ChooseQuestionnaireTemplateForAnamnesis',
  AddObservationTemplateDefinition: 'AddObservationTemplateDefinition',
  AddQuestionDefinition: 'AddQuestionDefinition',
  AddQuestionDefinitionAnswer: 'AddQuestionDefinitionAnswer',
  EditProgramObservation: 'EditProgramObservation',
  EditProgramQuestionnaire: 'EditProgramQuestionnaire',
  AddSupportedVendor: 'AddSupportedVendor',
  AddNewEnrollment: 'AddNewEnrollment',
  EditAddress: 'EditAddress',
  EnrollmentStop: 'EnrollmentStop',
  EditEnrollmentSchedule: 'EditEnrollmentSchedule',
  HandleTask: 'HandleTask',
  HandleLogisticTask: 'HandleLogisticTask',
  ForwardTask: 'ForwardTask',
  ChooseTask: 'ChooseTask',
  AddNewMedicalHistory: 'AddNewMedicalHistory',
  EditMedicalHistory: 'EditMedicalHistory',
  AddNewAllergy: 'AddNewAllergy',
  EditAllergy: 'EditAllergy',
  AddNewMedication: 'AddNewMedication',
  EditMedication: 'EditMedication',
  TreatmentAdvice: 'TreatmentAdvice',
  TreatmentAdviceAgreedPopup: 'TreatmentAdviceAgreedPopup',
  DeleteGroupPopup: 'DeleteGroupPopup',
  PatientRestorePopup: 'PatientRestorePopup',
  PatientArchivingPopup: 'PatientArchivingPopup',
  PatientFinalArchivingWarningPopup: 'PatientFinalArchivingWarningPopup',
  ProgramInputGroupPopup: 'ProgramInputGroupPopup',
  CallCenterDisablePopup: 'CallCenterDisablePopup',
  CallCenterEnablePopup: 'CallCenterEnablePopup',
  LogisticsDisablePopup: 'LogisticsDisablePopup',
  LogisticsEnablePopup: 'LogisticsEnablePopup',
  EcgPreviewPopup: 'EcgPreviewPopup',
  ExportObservationsToPdf: 'ExportObservationsToPdf',
});
