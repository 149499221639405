import moment from 'moment';
import { memoize } from 'lodash';
import { localize } from '../../services/localize';

export const getPersonAge = memoize(function(dateOfBirth) {
  if (!dateOfBirth) {
    return '';
  }

  const age = moment().diff(moment(dateOfBirth), 'years');
  return age;
});

export function formatAge(dateOfBirth) {
  if (!dateOfBirth) {
    return '';
  }

  return getPersonAge(dateOfBirth) + ' ' + localize('common.years').toLowerCase();
}

export function toDate(date) {
  if (date) {
    const m = moment(date);
    if (m.isValid()) {
      return m.toDate();
    }
  }
  return null;
}

export function getDuration(start, end) {
  if (!start || !end) {
    return '-';
  }

  let days = moment.duration(end.diff(start)).asDays();
  let weeks = Math.floor(days / 7);
  const years = Math.floor(days / 365);

  let dStr = '';
  if (years > 0) {
    dStr += `${ years }${ localize('enrollments.yearShort') } `;
    days -= years * 365;
    weeks = Math.floor(days / 7);
  }
  if (weeks > 0) {
    dStr += `${ weeks }${ localize('enrollments.weekShort') } `;
    days -= weeks * 7;
  }
  if (days > 0) {
    days = Math.ceil(days);
    dStr += `${ days }${ localize('enrollments.dayShort') }`;
  }
  return dStr;
}

export function dateSortHelper(a, b) {
  if (!a || !b) {
    return 0;
  }
  if (!a.isValid()) {
    return -1;
  }
  if (!b.isValid()) {
    return 1;
  }

  if (a.isAfter(b)) {
    return 1;
  }
  if (a.isBefore(b)) {
    return -1;
  }

  return 0;
}

export function durationSortHelper(aStart, aEnd, bStart, bEnd) {
  const eADuration = moment.duration(aEnd.diff(aStart)).asMilliseconds();
  const eBDuration = moment.duration(bEnd.diff(bStart)).asMilliseconds();
  if (eADuration > eBDuration) {
    return 1;
  }
  if (eADuration < eBDuration) {
    return -1;
  }
  return 0;
}
