import { ecgKey } from '../../../../../../model/observation/ecg';

export const getters = {
  isEcg,
};

function isEcg(state) {
  if (state.observationTemplate) {
    return state.observationTemplate.terminologyType === ecgKey;
  }
}

