<template>
  <label
    ref="componentRef"
    :for="id"
    :data-testid="`checkbox-${$props.name}-label`"
    :class="[
      styles.label,
      $props.labelStyle,
      $props.value && 'active',
      $props.disabled && 'disabled',
      $props.cardStyle && 'cardCheckbox',
      _indeterminate && styles.indeterminate,
      $props.inputStyle
    ]"
    :tabindex="!$props.disabled && tabIndex"
    @focus="tabIndexSubscriber__onFocus"
    @keydown.space="onClick"
    @keydown.enter="onClick"
    @click="onClick"
  >
    <VSwitch
      v-if="$props.switch"
      :id="id"
      :class="styles.switch"
      :inputValue="$props.value"
      :disabled="$props.disabled"
      :data-testid="`checkbox-${dataTestId}`"
      :tabindex="tabIndex"
      @focus="tabIndexSubscriber__onFocus"
    />
    <template v-else>
      <input
        :id="id"
        type="checkbox"
        :class="styles.input"
        :checked="$props.value"
        :tabindex="tabIndex"
        :data-testid="`checkbox-${dataTestId}`"
        @focus="tabIndexSubscriber__onFocus"
      />
      <span
        :class="[styles.checkbox, $props.labelStyle, styles[colorClass], 'mpCheckbox', error && styles.invalid]"
      >
        <Icon
          type="check"
          :class="styles.tick"
        />
      </span>
    </template>

    <slot name="label">
      <span
        v-if="$props.label"
        :class="styles.title"
      >
        {{ formattedLabel }}
        <div
          v-for="subLabel in $props.subLabels"
          :key="subLabel"
          :class="styles.subLabel"
        >
          {{ $localize(subLabel) }}
        </div>
      </span>
    </slot>
  </label>
</template>

<script>
  import { uniqueId } from 'lodash';
  import { VSwitch } from 'vuetify/lib';
  import { ArrayProp } from '@h4h/classes';
  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';
  import styles from './checkbox.scss';

  const colorClassNames = [
    'blue',
    'orange',
    'green',
    'red',
    'lightBlue',
    'lightGray',
    'purple',
    'gray'
  ];

  export default {
    name: 'H4hCheckbox',

    components: {
      VSwitch
    },

    mixins: [
      InputMixin
    ],

    props: {
      value: inputProps.booleanFalse,

      /** labels */
      info: inputProps.label,

      // Array<String | String[]>
      subLabels: new ArrayProp({
        type: String,
        validator: inputProps.label.validator
      }),

      /** flags */
      switch: inputProps.booleanFalse,
      readonly: inputProps.booleanFalse,
      cardStyle: inputProps.booleanFalse,
      indeterminate: inputProps.booleanFalse,

      /** styles */
      labelStyle: inputProps.style,
      inputStyle: inputProps.style,
      colorClass: {
        type: String,
        default: 'blue',
        // TODO: refactor to use ComponentStyle enum.
        validator: colorClass => colorClassNames.includes(colorClass)
      }
    },

    data() {
      return {
        styles,
        id: uniqueId('checkbox-')
      };
    },

    computed: {
      formattedLabel() {
        return this.$localize(this.$props.label);
      },
      dataTestId() {
        // Generation of data test id value is done by:
        // 1. Name - custom value that can be set for a single checkbox. Also set if Checkbox is an item in an options list
        // 2. Label - used when we have a group of checkboxes listed and the label is the actual name of the entity (Eg. Name of location) - we want to avoid using the value since it can be an object or an id of the entity
        // 3. Value - used when we have options with primitive values - we want to avoid using translated labels (generally used for ENUM values)
        const formattedValue = (this.$props.name || (!Array.isArray(this.$props.label) && this.$props.label) || this.$props.value || this.$props.id) + '';
        return formattedValue?.toLowerCase().replace(/(\s|\.)/g, '-') || '';
      },
      _indeterminate() {
        const { indeterminate, value } = this.$props;
        return !value && indeterminate;
      },

      error() {
        const { pristine, valid } = this.$props;

        return !valid && !pristine;
      },
    },

    methods: {
      focus() {
        this.$refs.componentRef.focus();

        if (this.$props.switch) {
          const element = document.getElementById(this.id);

          if (element) {
            element.focus();
          }
        }
        if (this.$props.autofocus) {
          this.$nextTick(() => {
            return this.$refs.componentRef.focus();
          });
        }
      },
      onClick(e) {
        const { disabled, value, readonly } = this.$props;

        const newValue = !value;
        if (!disabled && !readonly) {
          e.preventDefault();
          this.$emit('change', newValue);
          this.$emit('input', newValue);
        }
      }
    }
  };
</script>
