export const ecg = {
  ecg: 'HartWacht ECG',
  ecgShort: 'ECG',
  ecgHistory: 'ECG history',
  heartRateShort: 'HR',
  duration: 'Duration',
  downloadYourEcgAsAPdf: 'Download your ECG as a pdf',
  errorLoadingEcg: 'Error loading ECG',
  conclusion: 'Conclusion',
  conclusionBy: 'Conclusion by',
  interpretation: 'Interpretation',
  contact: 'Contact',
  note: 'Note',
  patientNote: 'Patient note',
  particularities: 'Particularities',
  selectPeriod: 'Select a period',
  comment: 'Comment',
  selectAnOption: 'Select an option',
  contactedVia: 'Contacted via',
  advice: 'Advice',
};
