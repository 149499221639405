import { toast } from '../../../services/toast';
import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { localize } from '../../../services/localize';
import { allergies, delAllergy, editAllergy } from '../../../services/api/simpleAnamnesis/allergies/allergies';
import { popups } from '../../../services/popups';
import { PopupType } from '@h4h/popups';
import { PopupName } from '../../../constants/popupName';

export const actions = Object.freeze({
  deleteAllergy,
  reload,
  fetchAllergies,
  showAddDialog,
  showEditDialog,
  createAllergy,
  updateAllergy,
});

async function deleteAllergy({ state, commit }, allergyId) {
  commit(types.SET_LOADING, true);
  const result = await delAllergy.delete(allergyId);
  commit(types.SET_LOADING, false);
  if (result.success) {
    toast.success(localize('messages.allergyDeleted'));
    await reload({ state, commit });
  }
  else {
    showRestError(result.error, 'messages.cantDeleteAllergy');
  }
}

async function reload({ state, commit }) {
  commit(types.SET_LOADING, true);
  commit(types.SET_FETCHED, false);
  const result = await allergies(state.patientUserUuid).fetch();
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_FETCHED, true);
    commit(types.SET_ALLERGIES, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchAllergies');
  }
  // return any result anyway. Empty array is better then null.
  return state.allergies;
}

async function fetchAllergies({ state, commit }, patientUserUuid) {
  commit(types.SET_LOADING, true);
  commit(types.SET_PATIENT_USER_UUID, patientUserUuid);
  const result = await allergies(patientUserUuid).fetch();
  commit(types.SET_LOADING, false);
  if (result.success) {
    commit(types.SET_ALLERGIES, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchAllergies');
  }
  return state.allergies;
}

async function showAddDialog({ commit }) {
  const { id } = await popups.show({
    name: PopupName.AddNewAllergy,
    type: PopupType.Modal
  });
  commit(types.INIT_ADD_POPUP, {
    popupId: id,
  });
}

async function showEditDialog({ commit }, item) {
  const { id } = await popups.show({
    name: PopupName.EditAllergy,
    type: PopupType.Modal
  });
  commit(types.SET_EDITABLE_ALLERGY_UUID, item.uuid);
  commit(types.INIT_EDIT_POPUP, {
    popupId: id,
    value: item.value,
  });
}

async function createAllergy({ state, commit, getters }) {
  if (!getters.valid) {
    return;
  }
  commit(types.SET_LOADING, true);
  const result = await allergies(state.patientUserUuid).create({
    value: state.allergy.value
  });
  if (result.success) {
    popups.hide({ id: state.popupId });
    await reload({ state, commit });
    toast.success(localize('messages.patientFileSaved'));
  }
  else {
    showRestError(result.error, 'messages.cantCreateAllergy');
  }
}

async function updateAllergy({ state, commit, getters }) {
  if (!getters.valid) {
    return;
  }
  commit(types.SET_LOADING, true);
  const result = await editAllergy.update(
    state.editableAllergyUuid,
    {
      value: state.allergy.value
    });
  if (result.success) {
    popups.hide({ id: state.popupId });
    toast.success(localize('messages.allergyUpdated'));
    await reload({ state, commit });
  }
  else {
    showRestError(result.error, 'messages.cantUpdateAllergy');
  }
}
