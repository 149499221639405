export const EnrollmentStatus = Object.freeze({
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED'
});

export const EnrollmentStatusOptions = Object.keys(EnrollmentStatus).map(status => ({
  label: `enrollments.${ status }`,
  value: status
}));

