import { state } from './state';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { forward } from './forward';
import { withInputs } from '@h4h/inputs';

export const practitionerTasks = withInputs({
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    forward
  }
});
