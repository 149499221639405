<template>
  <PopupContent>
    <template slot="heading">
      {{ $l('common.renameFilter') }}
    </template>
    <template slot="content">
      <Smart
        v-for="input in inputs"
        :key="input.label"
        :input="input"
        :action="setInputValue"
      />
    </template>
    <template slot="footer">
      <Button
        :primary="true"
        :text="true"
        :label="'common.cancel'"
        @click="closePopup(false)"
      />
      <Button
        :primary="true"
        :label="'common.save'"
        @click="closePopup(true)"
      />
    </template>
  </PopupContent>
</template>

<script>
  import styles from './editTableProfilePopup.scss';
  import { PopupContent } from '@h4h/popups';
  import { Button } from '@h4h/button';
  import { Smart } from '@h4h/inputs';
  import { MultipleProfilesMixin } from '../../multipleProfilesMixin';

  export default {
    name: 'H4hEditTableProfilePopup',
    components: { PopupContent, Button, Smart },
    mixins: [MultipleProfilesMixin],
    data() {
      return {
        styles,
      };
    },
    computed: {
      inputs() {
        return this.getState('editProfileInputs');
      }
    },
    methods: {
      closePopup(success) {
        this.dispatch('closePopup', success);
      },
    }
  };
</script>
