import { CrudService } from '../../../classes/crudService';
import { ProgramModel } from '../../../model/programModel';

export const callCenterPrograms = new CrudService(
  '/call-center/programs',
  toProgramModel,
  data => data
);

export function toProgramModel(data, tenant) {
  let result = new ProgramModel(data);
  result.tenant = tenant;
  return result;
}
