import { Page } from '../../../../../../constants/page';

const enrollmentView = {
  path: ':enrollmentId',
  name: Page.PractitionerPatientEnrollmentsView,
  redirect: { name: Page.PractitionerPatientEnrollmentsViewOverview },
  props: true,
  component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/EnrollmentView'),
  meta: {
    title: 'enrollments.enrollment',
  },
  children: [
    {
      path: 'view',
      name: Page.PractitionerPatientEnrollmentsViewOverview,
      component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/overview/EnrollmentOverview'),
      meta: {
        title: 'enrollments.enrollment',
      }
    },
    {
      path: 'observation/:observationTemplateUuid',
      props: true,
      name: Page.PractitionerPatientEnrollmentsViewObservation,
      component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/observation/EnrollmentObservationContainer'),
      meta: {
        title: 'observations.observations',
      },
      children: [
        {
          path: ':observationUuid',
          props: true,
          name: Page.PractitionerPatientEnrollmentsViewObservationSingle,
          component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/observation/single/SingleObservation2'),
          meta: {
            title: 'observations.observations',
          }
        },
      ]
    },
    {
      path: 'terminology-type/:terminologyTypeEncoded',
      props: true,
      name: Page.PractitionerPatientEnrollmentsViewTerminologyType,
      component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/terminologyType/TerminologyTypeContainer'),
      meta: {
        title: 'observations.observations',
      }
    },
    {
      path: 'questionnaire/:questionnaireTemplateUuid',
      props: true,
      name: Page.PractitionerPatientEnrollmentsViewQuestionnaire,
      component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/questionnaire/EnrollmentQuestionnaireContainer'),
      meta: {
        title: 'questionnaire.questionnaire',
      },
      children: [
        {
          path: 'add',
          name: Page.PractitionerPatientEnrollmentsViewQuestionnaireAdd,
          component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/questionnaire/add/AddQuestionnaireContainer'),
          redirect: { name: Page.PractitionerPatientEnrollmentsViewQuestionnaireAddChooser },
          meta: {
            title: 'questionnaire.addQuestionnaire',
          },
          children: [
            {
              path: 'group-selector',
              name: Page.PractitionerPatientEnrollmentsViewQuestionnaireAddChooser,
              component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/questionnaire/add/chooser/ChooseQuestionnaireGroup'),
              meta: {
                title: 'questionnaire.addQuestionnaire',
              },
            },
            {
              path: 'single',
              name: Page.PractitionerPatientEnrollmentsViewQuestionnaireAddSingle,
              component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/questionnaire/add/single/AddSingleQuestionnaire'),
              meta: {
                title: 'questionnaire.addQuestionnaire',
              },
            },
            {
              path: 'multiple/:questionnaireTemplateUuids',
              name: Page.PractitionerPatientEnrollmentsViewQuestionnaireAddMultiple,
              props: true,
              component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/questionnaire/add/multiple/AddMultipleQuestionnaire'),
              meta: {
                title: 'questionnaire.addQuestionnaire',
              },
            },
          ]
        },
        {
          path: 'edit/:questionnaireUuid',
          props: true,
          name: Page.PractitionerPatientEnrollmentsViewQuestionnaireEdit,
          component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/view/questionnaire/edit/EditQuestionnaireContainer'),
          meta: {
            title: 'questionnaire.questionnaire',
          }
        },
      ]

    },
  ]
};

export const enrollmentsRoutes = {
  path: 'enrollments',
  redirect: { name: Page.PractitionerPatientEnrollmentsList },
  name: Page.PractitionerPatientEnrollments,
  props: true,
  component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/EnrollmentContainer'),
  meta: {
    title: 'enrollments.enrollments',
  },
  children: [
    {
      path: 'list',
      name: Page.PractitionerPatientEnrollmentsList,
      component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/list/Enrollment'),
      meta: {
        title: 'enrollments.enrollments',
      }
    },
    {
      path: 'auto-enroll',
      name: Page.PractitionerPatientEnrollmentsAutoEnroll,
      component: () => import('../../../../../../components/views/app/practitioner/patients/view/enrollments/auto-enroll/AutoEnroll'),
      meta: {
        title: 'enrollments.enrollments',
      }
    },
    enrollmentView,
  ]
};
