<template>
  <PopupContent
    :light="$props.light"
    :showClose="showClose"
    :class="$props.className"
    :heading="$localize($props.title)"
    :subheading="$localize($props.subtitle)"
  >
    <template #content>
      <div
        v-if="isMsgHtml"
        v-html="sanitizeHtmlMsg($props.message)"
      />
      <pre
        v-else
        :class="styles.content"
      >
        {{ $localize($props.message) }}
      </pre>
    </template>

    <template #footer>
      <Button
        v-for="(button, index) in buttons"
        :key="index"
        :ref="index"
        secondary
        v-bind="button"
        :class="button.class"
        :data-testid="`button-save-${index}`"
        :groupId="'modal.modalButtons'"
        @click="popupClose(button.result)"
        @keydown.enter="popupClose(button.result)"
      />
    </template>
  </PopupContent>
</template>

<script>
  import { isUndefined } from 'lodash';

  import { ComponentStyle } from '@h4h/theme';

  import styles from './modal.scss';
  import PopupContent from '../PopupContent/PopupContent';
  import { sanitizeHtmlMsg } from '../../utils/htmlMessage';
  import { ConfirmationResult } from '../../constants/confirmationResult';

  export default {
    name: 'H4hModal',

    components: {
      PopupContent
    },
    inject: ['popupClose'],

    props: {
      title: {
        type: [String, Array]
      },

      subtitle: {
        type: [String, Array]
      },

      message: {
        type: [String, Array],
        required: true
      },

      modalStyle: { // Not used currently.
        type: String,
        validator(value) {
          return !!ComponentStyle[value];
        }
      },

      className: {
        type: String
      },

      buttons: {
        type: Array,
        validator(value) {
          return Array.isArray(value) && !value.some(function(buttonConfig) {
            return !(buttonConfig.label && !isUndefined(buttonConfig.result));
          });
        }
      },

      showClose: {
        type: Boolean,
        default: false
      },

      light: {
        type: Boolean,
        default: true
      },

      isMsgHtml: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        styles,
        sanitizeHtmlMsg
      };
    },

    computed: {
      confirmBtnIndex() {
        return this.$props.buttons.findIndex(this.isConfirmBtn);
      },

      confirmBtn() {
        return this.$props.buttons.find(this.isConfirmBtn);
      }
    },

    created() {
      window.addEventListener('keydown', this.onKeydown);
    },

    beforeDestroy() {
      window.removeEventListener('keydown', this.onKeydown);
    },

    mounted() {
      if (this.confirmBtnIndex !== -1) {
        const [element] = this.$refs[this.confirmBtnIndex];
        element.$el.focus();
      }
    },

    methods: {
      isConfirmBtn(button) {
        return (button.result === true || button.result === ConfirmationResult.Confirm);
      },

      onKeydown(e) {
        if (e.key === 'Enter' && this.confirmBtn) {
          this.popupClose(this.confirmBtn.result);
        }
      }
    }
  };
</script>
