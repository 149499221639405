import { CrudService } from '../../../classes/crudService';
import { mapPatientModelToRestEntity, PatientModel } from '../../../model/patientModel';
import { PageModel } from '../../../model/page';
import { tryRequest } from '../../../utils/tryRequest';
import { axios } from '../../axios';

export const patients = new CrudService(
  'patients',
  data => new PatientModel(data),
  mapPatientModelToRestEntity
);

export function findPatientByExternalPatientId(externalId) {
  return new CrudService(
    `patients/external-id=(${ externalId })`,
    data => new PatientModel(data),
    mapPatientModelToRestEntity
  ).fetchOne();
}

export const patientsList = new CrudService(
  'patients',
  data => new PageModel(data, v => new PatientModel(v))
);

export const patientsBundle = new CrudService(
  'patients/list',
  data => new PatientModel(data)
);

export const patientAvatar = patientId => new CrudService(
  `patients/${ patientId }/photo`,
  data => data && data.base64Photo
);

/**
 * Update patient responsible physician
 *
 * @param {String} patientId
 * @param {PatientUpdateRequestModel} config
 */
export async function updatePatientResponsiblePhysician(patientId, config) {
  return tryRequest(
    () => axios.put(`patients/${ patientId }/treatment-specialist`, config),
    data => new PatientModel(data)
  );
}

/**
 * Send a request for a new activation email
 * @param {string} patientUuid
 */
export async function sendPatientActivationEmail(patientUuid) {
  return await new CrudService(`patients/${ patientUuid }/activation-links`).create();
}
