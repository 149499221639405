/* global TASK_API_URL */

import { CrudService } from '../../../classes/crudService';

export const practitionerTasksObservationTerminologyTypes = new CrudService(
  '/practitioner/observation-terminology-types',
  data => data,
  data => data,
  TASK_API_URL
);
