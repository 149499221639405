import { OBSERVATION_THRESHOLD_POPUP_MODE } from './constants';

export const getters = {
  valid,
  canClear,
  canResetInput,
  showDeletedButtonVisible,
  hideDeletedButtonVisible,
  showAddItemsButton,
};

function valid(state) {
  return state.thresholdInputsGroups &&
    state.thresholdInputsGroups.length &&
    state.thresholdInputsGroups.every(thresholdInputsGroup => thresholdInputsGroup.valid);
}

function canClear(state) {
  return state.mode === OBSERVATION_THRESHOLD_POPUP_MODE.CREATE_PROGRAM;
}

function canResetInput(state) {
  return state.mode === OBSERVATION_THRESHOLD_POPUP_MODE.PERSONALIZED_THRESHOLD;
}

function showDeletedButtonVisible() {
  // temporary hide deleted threshold and add missing items buttons
  // if (state.mode === OBSERVATION_THRESHOLD_POPUP_MODE.PERSONALIZED_THRESHOLD) {
  //   if (state.thresholdInputsGroups) {
  //     return !state.showDeletedThresholds && state.thresholdInputsGroups.some(thresholdInputsGroup => thresholdInputsGroup.hasDeletedItems());
  //   }
  // }
  return false;
}

function hideDeletedButtonVisible() {
  // temporary hide deleted threshold and add missing items buttons
  // if (state.mode === OBSERVATION_THRESHOLD_POPUP_MODE.PERSONALIZED_THRESHOLD) {
  //   if (state.thresholdInputsGroups) {
  //     return state.showDeletedThresholds && state.thresholdInputsGroups.some(thresholdInputsGroup => thresholdInputsGroup.hasDeletedItems());
  //   }
  // }
  return false;
}

function showAddItemsButton() {
  // temporary hide deleted threshold and add missing items buttons
  // if (state.mode === OBSERVATION_THRESHOLD_POPUP_MODE.PERSONALIZED_THRESHOLD) {
  //   if (state.thresholdInputsGroups) {
  //     return state.thresholdInputsGroups.some(thresholdInputGroup => thresholdInputGroup.hasMissingItems());
  //   }
  // }
  return false;
}
