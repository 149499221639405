<template>
  <tr
    :id="$props.item[$props.itemKey]"
    :class="[className, $props.isSelected && styles.selectedRow]"
    :data-nesting-level="_nestingLevel"
    @click="onRowClick"
  >
    <td
      v-for="header in $props.headers"
      :key="header.value"
      :class="getCellClass(header)"
    >
      <div
        tabindex="-1"
        :class="styles.prependedIconContainer"
        @click.stop
      >
        <Icon
          v-if="header.value === '_sort'"
          type="menu"
          :class="styles.sortAnchor"
        />
        <Checkbox
          v-else-if="isSelectable && header.value === '_select'"
          :name="`select-row-${ $props.item[$props.itemKey] }`"
          :value="isSelected"
          :class="styles.checkbox"
          @change="onSelectCheckboxChange"
        />
        <Button
          v-else-if="isExpandable && header.value === '_expand'"
          :class="[styles.expandIcon, $props.nestExpandButton && styles.groupedRowExpandIcon]"
          text
          round
          :icon="$props.isExpanded ? 'chevron-down' : 'chevron-right'"
          @click.stop.prevent="onChevronClick"
        />
      </div>

      <RowActions
        v-if="hasAvailableActions && header.value === '_actions'"
        :item="$props.item"
        :class="styles.rowActions"
        :selected="$props.isSelected"
        :actions="_actions"
        @popoverChange="onPopoverChange"
      />

      <!-- values -->
      <!-- <component v-bind="genCellContent(header)" /> -->

      <MultipleItem
        v-else-if="header.isMultipleItemsCell"
        :formattedValue="getFormattedValue(header)"
        :multipleItemsShown.sync="multipleItemsShown"
      />

      <TruncatedText
        v-else-if="header.isTruncatedTextCell"
        :formattedValue="getFormattedValue(header)"
        :fullTextShown.sync="fullTextShown"
      />

      <template v-else>
        <slot
          :name="createCellSlotName('*')"
          v-bind="createCellSlotScope(header)"
        >
          <slot
            :name="createCellSlotName(header.value)"
            v-bind="createCellSlotScope(header)"
          >
            {{ getFormattedValue(header) }}
          </slot>
        </slot>
      </template>
    </td>
  </tr>
</template>

<script>
  import { get, flatten, isFunction, isString, isBoolean, isUndefined } from 'lodash';

  import { Checkbox } from '@h4h/inputs';

  import { Shared } from '../../mixins/shared';
  import { createCellSlotName } from '../../utils/slotUtils';
  import { isActionableHeader } from '../../utils/tableColumns';

  import RowActions from '../rowActions/RowActions';

  import styles from './tableRow.scss';
  import MultipleItem from '../multipleItem/MultipleItem.vue';
  import TruncatedText from '../truncatedText/TruncatedText';

  export default {
    name: 'H4hTableRow',

    components: {
      MultipleItem,
      TruncatedText,
      RowActions,
      Checkbox,
    },

    props: {
      item: {
        type: Object,
        required: true
      },

      itemKey: {
        type: String,
        required: true
      },

      headers: {
        type: Array,
        required: true
      },

      itemClass: {
        type: [String, Function],
      },

      highlightRow: {
        type: Function
      },

      isPopoverRow: {
        type: Boolean,
        default: false
      },

      // Boolean, or (item?: any) => Boolean to cache result in computed
      showSelect: {
        type: [Boolean, Function],
        default: false
      },

      // enables item selection on row click
      rowClickSelect: {
        type: Boolean,
        default: false
      },

      showExpand: {
        type: [Boolean, Function],
        default: false
      },

      isSelected: {
        type: Boolean,
      },

      isExpanded: {
        type: Boolean,
      },

      // control whether expand button should shift according to nesting level
      nestExpandButton: {
        type: Boolean,
        default: true,
      },

      // apply custom nesting level (used in non grouped expansion content)
      nestingLevel: {
        type: Number,
      },

      // Function prop & computed instead of method in Table improves performance with caching for groupedRows.
      getNestingLevel: {
        type: Function,
      },

      actions: Shared.props.actions
    },

    data() {
      return {
        styles,
        createCellSlotName,
        multipleItemsShown: false,
        fullTextShown: false
      };
    },

    computed: {
      className() {
        const { item, itemClass, highlightRow, isPopoverRow } = this.$props;

        const classes = [];

        if (isFunction(itemClass)) {
          classes.push(itemClass(item));
        }
        else if (isString(itemClass)) {
          classes.push(itemClass);
        }

        if (highlightRow) {
          const color = highlightRow(item);

          if (color) {
            classes.push(color);
          }
        }

        if (isPopoverRow) {
          classes.push(styles.popoverRow);
        }

        return flatten(classes);
      },

      _actions() {
        const { item, actions } = this.$props;

        return actions
          // Return only actions that should be shown:
          ?.filter(action => {
            if (isBoolean(action.show)) {
              return action.show;
            }
            else if (isFunction(action.show)) {
              return action.show(item);
            }
            // default value if there no has defined show property into action
            return true;
          })
          // Generate label from item content if needed:
          .map(action => ({
            ...action,
            icon: this.getActionProperty(action.icon, item),
            label: this.getActionProperty(action.label, item),
            isDisabled: this.getActionProperty(action.isDisabled, item)
          }));
      },

      hasAvailableActions() {
        return !!this._actions?.length;
      },

      isSelectable() {
        // return only if it should be shown
        const { showSelect, item } = this.$props;
        if (isBoolean(showSelect)) {
          return showSelect;
        }
        else if (isFunction(showSelect)) {
          return showSelect(item);
        }
        return false;
      },

      isExpandable() {
        // return only if it should be shown
        const { showExpand, item } = this.$props;
        if (isBoolean(showExpand)) {
          return showExpand;
        }
        else if (isFunction(showExpand)) {
          return showExpand(item);
        }
        return false;
      },

      firstDataColumn() {
        return this.$props.headers.find(x => !isActionableHeader(x));
      },

      _nestingLevel() {
        return isUndefined(this.$props.nestingLevel) ? this.$props.getNestingLevel(this.$props.item) : this.$props.nestingLevel; // 0 is falsey
      },
    },

    watch: {
      item() {
        this.multipleItemsShown = false;
        this.fullTextShown = false;
      }
    },

    methods: {
      getActionProperty(property, item) {
        if (isFunction(property)) {
          return property(item);
        }
        return property;
      },

      getValue(header) {
        return get(this.$props.item, header.value);
      },

      getFormattedValue(header) {
        const { item } = this.$props;

        const value = this.getValue(header);

        return header.formatter
          ? header.formatter(value, { item })  // simulate Vuetify's cell interface
          : value;
      },

      getCellClass(header) {
        return [
          styles.defaultCell,
          isActionableHeader(header) && styles.iconCell,
          header === this.firstDataColumn && styles.groupedRowFirstColumn,
          this.getColumnAlignClass(header)
        ].filter(x => x);
      },

      getColumnAlignClass(header) {
        switch (header.align) {
          case 'right':
            return styles.alignEnd;
          case 'center':
            return styles.alignCenter;
          default:
            return styles.alignStart;
        }
      },

      createCellSlotScope(header) {
        const { item } = this.$props;

        return {
          item,
          header,
          value: this.getValue(header),
          formattedValue: this.getFormattedValue(header)
        };
      },

      onSelectCheckboxChange() {
        if (this.$props.rowClickSelect) {
          this.$emit('click', this.item);
        }
        this.$emit('toggleSelect');
      },

      onRowClick() {
        if (this.$props.rowClickSelect) {
          this.onSelectCheckboxChange();
        }
        else {
          this.$emit('click', this.item);
        }
      },

      onChevronClick($event) {
        $event.preventDefault();
        $event.stopPropagation();

        this.$emit('toggleExpand');
      },

      onPopoverChange(event) {
        this.$emit('popoverChange', { rowId: this.$props.item[this.$props.itemKey], ...event });
      }
    }
  };
</script>
