import { getEnumOptions } from '../utils/options';

export const AddressKind = Object.freeze({
  Regular: 'Regular',
  PoBox: 'PoBox'
});

export const addressKindOptions = [
  {
    label: 'address.address',
    value: AddressKind.Regular
  },
  {
    label: 'address.box',
    value: AddressKind.PoBox
  }
];

export const AddressType = Object.freeze({
  Address: 'Address',
  Postal: 'Postal',
  Invoice: 'Invoice'
});

export const addressTypeOptions = getEnumOptions(AddressType, 'address');

