import { mapArray } from '../utils/mapUtils';
import { CreationResultModel } from './creationResultModel';

export class BatchCreationResultModel {
  /**
   * BatchCreationResultModel
   *
   * @param {Object}              config
   * @param {CreationResultModel[]}    config.results
   */
  constructor(config) {
    this.results = mapArray(config.results, CreationResultModel);
  }

}
