import { mapValues } from 'lodash';

import { toast } from '../../../services/toast';

import { types } from './types';
import { localize } from '../../../services/localize';
import { showRestError } from '../../../utils/errors';
import { groups } from '../../../services/api/users/groups';
import { router } from '../../../services/router';
import { Page } from '../../../constants/page';

export const actions = Object.freeze({
  saveGroup,
  initForm,
  resetForm,
});

async function saveGroup({ state, commit, getters, dispatch }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const group = mapValues(state.inputsMap, i => i.value);

  commit(types.SET_LOADING, true);
  const result = await groups.create(group);
  commit(types.SET_LOADING, false);

  if (result.success) {
    dispatch('groups/list/reload', {}, { root: true });
    toast.success(localize('messages.groupSaved'));
    router.push({ name: Page.AdminGroupsList });
  }
  else {
    showRestError(result.error, 'messages.cantCreateGroup');
  }
}

async function initForm({ commit }) {
  commit(types.INIT_FORM);
}

async function resetForm({ commit }) {
  commit(types.RESET_FORM);
}

