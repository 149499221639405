import { CrudService } from '../../../classes/crudService';
import { TreatmentAdviceModel } from '../../../model/treatmentadvice/treatmentAdviceModel';

export function latestTreatmentAdvice(enrollmentUuid) {
  return new CrudService(
    `/enrollments/${ enrollmentUuid }/treatment-advices/latest`,
    data => new TreatmentAdviceModel(data)
  );
}

export async function sendTAFeedback(enrollmentUuid, config) {
  return new CrudService(
    `/enrollments/${ enrollmentUuid }/treatment-advices/feedback`,
    data => data
  ).updateOne(config);
}
