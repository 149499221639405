import { AdminRoutes } from './admin/adminRoutes';
import { CallCenterAdminRoutes } from './callCenterAdminRoutes';
import { PractitionerRoutes } from './practitionerRoutes';
import { Page } from '../../../constants/page';
import { EhRole } from '../../auth/ehRole';
import { LogisticUserRoutes } from './logisticUserRoutes';

export const appViews = [
  PractitionerRoutes,
  AdminRoutes,
  CallCenterAdminRoutes,
  LogisticUserRoutes,
  {
    path: 'choose-role',
    name: Page.ChooseRole,
    component: () => import('../../../components/views/app/role-chooser/ChooseRole'),
    meta: {
      roles: [
        EhRole.Admin,
        EhRole.Physician,
        EhRole.Poh
      ],
      title: 'common.chooseRole'
    }
  },
  {
    path: 'choose-call-center-role',
    name: Page.ChooseCcRole,
    component: () => import('../../../components/views/app/role-chooser/ChooseCcRole'),
    meta: {
      roles: [
        EhRole.CallCenterAdmin,
        EhRole.CallCenterSupport,
      ],
      title: 'common.chooseRole'
    }
  },
  {
    path: 'patient-login-success',
    name: Page.PatientLoginSuccess,
    component: () => import('../../../components/views/static/patient-auth-success/PatientAuthSuccess'),
    meta: {
      roles: [EhRole.Patient],
      title: 'common.successfulLogin'
    }
  },
  {
    path: 'iframe/patients/:userId',
    props: true,
    name: Page.Iframe,
    component: () => import('../../../components/views/app/iframe/Iframe'),
    meta: {
      roles: [EhRole.Physician, EhRole.Poh],
      title: 'patient.patient',
    },
  },
  {
    path: 'iframe/external-patients/:externalPatientId',
    props: true,
    name: Page.IframeByExternalPatientId,
    component: () => import('../../../components/views/app/iframe/IframeByExternalPatientId'),
    meta: {
      roles: [EhRole.Physician, EhRole.Poh],
      title: 'patient.patient',
    },
  },
];
