import { CrudService } from '../../../classes/crudService';
import { TenantOptionModel } from '../../../model/utils/tenantOptionModel';

export const currentTenant = new CrudService(
  'current-tenant',
  data => new TenantOptionModel(data),
);

export const userTenants = new CrudService(
  'user-tenants',
  data => new TenantOptionModel(data),
);
