import { toast } from '../../services/toast';
import { localize } from '../../services/localize';
import { getBase64FromFile } from '../../utils/base64';
import { photo } from '../../services/api/profile/photo';
import { AvatarFileModel } from '../../classes/avatarFile';

import { types } from './types';
import { showRestError } from '../../utils/errors';
import { CustomDocument } from '@h4h/inputs';

const MAX_SIZE_IN_MEGABYTES = 2;
const MAX_SIZE_IN_BYTES = MAX_SIZE_IN_MEGABYTES * 1024 * 1024;

export const actions = Object.freeze({
  fetchAvatar,
  uploadAvatarImage,
  clearAvatarImage,
});

async function fetchAvatar({ commit }) {
  commit(types.SET_AVATAR_IS_LOADING, true);
  const result = await photo.fetchOne();
  commit(types.SET_AVATAR_IS_LOADING, false);
  if (result.success) {
    commit(types.SET_AVATAR, result.data);
  }
}

async function uploadAvatarImage({ commit, state }, files) {
  commit(types.SET_AVATAR_IS_LOADING, true);
  if (!files.length) {
    return toast.error(
      localize('avatar.errorUpdateAvatar'),
      new Error(localize('avatar.chooseFile'))
    );
  }
  const file = new CustomDocument(files[0]);
  if (file.size > MAX_SIZE_IN_BYTES) {
    return toast.error(
      localize('avatar.errorUpdateAvatar'),
      new Error(localize('avatar.maxImageSizeError', [MAX_SIZE_IN_MEGABYTES]))
    );
  }

  const content = await getBase64FromFile(file.file);

  commit(
    types.SET_AVATAR_FILE,
    new AvatarFileModel({
      name: file.name,
      size: file.size,
      content
    })
  );

  const result = await photo.updateOne(state.avatarFile.content);
  commit(types.SET_AVATAR_IS_LOADING, false);

  if (result.success) {
    commit(types.SET_AVATAR, result.data);
    commit(types.SET_AVATAR_FILE, null);
  }
  else {
    showRestError(result.error, 'avatar.errorUpdateAvatar');
  }
}

async function clearAvatarImage({ commit }) {
  commit(types.SET_AVATAR_IS_LOADING, true);
  const result = await photo.updateOne(null);
  commit(types.SET_AVATAR_IS_LOADING, false);

  if (result.success) {
    commit(types.SET_AVATAR, result.data);
    commit(types.SET_AVATAR_FILE, null);
  }
  else {
    showRestError(result.error, 'avatar.errorUpdateAvatar');
  }
}

