export const healthRecords = {
  phone: 'Phone',
  email: 'E-mail',
  reassurance: 'Reassurance',
  // consultByPractitioner: 'Consult by general practitioner',
  consultByCardiologist: 'Consult by treating cardiologist',
  consultByPhoneCardiologist: 'Consult by phone with treating cardiologist',
  refToFirstAid: 'Referral to first aid',
  other: 'Other',
  asystole: 'Asystole',
  atrialFibrillation: 'AFIB',
  atrialFlutter: 'Aflutter',
  avBlockFirstDegree: '1st degree AV block ',
  avBlockSecondDegree: '2nd degree AV block',
  avBlockThirdDegree: '3rd degree AV block',
  indivisible: 'Unappreciable',
  malfunction: 'Malfunction',
  pac: 'PAC',
  pvc: 'PVC',
  svt: 'SVT',
  sinusRhythm: 'Sinus rhythm',
  sinusJudgment: 'Sinus arrest',
  pause: 'Pause',
  sinusbradycardie: 'Sinus bradycardia',
  sinustachycardie: 'Sinus tachycardia',
  doubletPac: 'Doublet PAC',
  doubletPvc: 'Doublet PVC',
  avnrt: 'AVNRT',
  atrialTachycardia: 'Atrial tachycardia',
  nsvt: 'NSVT',
  sustainedVt: 'Sustained VT',
  sa_block: 'SA block',
  bigeminy: 'Bigeminy',
};
