export const tenant = {
  navigateToAdminPage: 'Navigate to Admin page',
  navigateToPractitionerPage: 'Navigate to Practitioner page',
  navigateToCallCenterAdminPage: 'Navigate to Admin page',
  navigateToCallCenterSupportPage: 'Navigate to Support page',
  navigateToLogisticPage: 'Navigate to Logistic page',
  authorize: 'Authorize',
  noTenantInfoFound: 'No tenant info found',
  authorizeToX: 'Authorize to {0}',
  errorAuthorize: 'Error authorize',
  errorGetEmailInfo: 'Error get email info',
  fillInYourEmail: 'Fill in your email address:',
  showAvailableOrganizations: 'Show available care organizations',
  country: 'Country',
};
