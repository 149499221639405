import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { formatDate } from '@h4h/date';
import { formatGender } from '../../../utils/formatters/gender';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PATIENT](state, patient) {
    state.patient = patient;
    if (patient) {
      state.inputs = getInputsConfig(patient);
    }
    else {
      state.inputs = [];
    }
  },

};

function getInputsConfig(patient) {
  const initials = createTextInputConfig(patient.initials, 'initials', 'common.initials');
  const firstName = createTextInputConfig(patient.firstName, 'firstName', 'common.firstName');
  const middleName = createTextInputConfig(patient.middleName, 'middleName', 'common.middleName');
  const lastName = createTextInputConfig(patient.lastName, 'lastName', 'common.lastName');
  const dateOfBirth = createTextInputConfig(formatDate(patient.dateOfBirthAsDate), 'dateOfBirth', 'common.dateOfBirth');
  const gender = createTextInputConfig(formatGender(patient.gender), 'gender', 'gender.gender');
  const externalPatientId = createTextInputConfig(patient.externalPatientId, 'externalPatientId', 'common.hisPatientId');
  const patientNumber = createTextInputConfig(patient.patientNumber, 'patientNumber', 'patient.patientNumber');
  let ssn = [];
  if (patient.bsn) {
    ssn = [createTextInputConfig(patient.bsn, 'bsn', 'patient.ssn'), null];
  }
  const phone = createTextInputConfig(patient.phone, 'phone', 'common.telephoneNumber');
  const email = createTextInputConfig(patient.email, 'email', 'common.email');
  const partnerMiddleName = createTextInputConfig(patient.partnerMiddleName, 'partnerMiddleName', 'common.partnerMiddleName');
  const partnerName = createTextInputConfig(patient.partnerName, 'partnerName', 'common.partnerName');

  return [
    externalPatientId,
    patientNumber,
    ...ssn,
    initials,
    firstName,
    middleName,
    lastName,
    partnerName,
    partnerMiddleName,
    dateOfBirth,
    gender,
    phone,
    email,
  ];
}

function createTextInputConfig(value, id, label) {
  return createInputConfig({
    id,
    value,
    type: InputType.Label,
    label,
    disabled: true
  });
}
