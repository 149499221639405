import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig, getInputsMap } from '@h4h/inputs';
import { ehRoleOptionsSupportedForSelect } from '../../../services/auth/ehRole';
import { EhGroupFieldsLengthConstraints } from '../../../utils/ehGroupFieldsLengthConstraints';
import { validateRoles } from '../utils';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.INIT_FORM](state) {
    const inputs = getInputsConfig();
    state.inputs = inputs;
    state.inputsMap = getInputsMap(inputs);
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.RESET_FORM](state) {
    state.inputs = null;
    state.inputsMap = null;
  },
};

function getInputsConfig() {
  const name = createTextInputConfig('name', 'common.name', true, EhGroupFieldsLengthConstraints.name);

  const defaultForRole = createInputConfig({
    id: 'defaultForRole',
    type: InputType.Select2,
    required: false,
    label: 'group.defaultForRole',
    options: ehRoleOptionsSupportedForSelect,
  });

  const roles = createInputConfig({
    id: 'roles',
    type: InputType.Pills,
    label: 'common.roles',
    options: ehRoleOptionsSupportedForSelect,
    value: new Set(),
    validate: validateRoles
  });

  return [
    name,
    defaultForRole,
    roles
  ];
}

function createTextInputConfig(id, label, required = false, constraints) {
  return createInputConfig({
    id,
    type: InputType.Text,
    required,
    label,
    ...constraints,
  });
}
