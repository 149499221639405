export const state = {
  enrollment: null,
  patient: null,

  loading: false,
  observationTaskMap: new Map(),
  observationsPage: null,
  serverSideOptions: {},
  validPageForObservation: null,
};
