export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_SHORT = 'H:mm';
export const TIME_FORMAT_LONG = TIME_FORMAT + ':ss';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_FORMAT_WITH_WEEKDAY = 'DD-MM-YYYY (ddd)';
export const FULL_MONTH_FORMAT = 'DD MMMM YYYY';
export const SHORTHAND_MONTH_FORMAT = 'DD MMM YYYY';
export const MONTH_FORMAT = 'MMMM';
export const DATETIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT;
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATETIME_FORMAT = ISO_DATE_FORMAT + ' ' + TIME_FORMAT;
export const DAY_FORMAT = 'ddd DD';
export const DAY_MONTH_FORMAT = 'ddd DD MMM';
export const DAY_DATE_FORMAT = 'dddd, ' + DATE_FORMAT;
export const DATE_FORMAT_WITHOUT_SEPARATORS = 'YYYYMMDD';
export const MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const DATE_DAY_TIME = DATE_FORMAT + ' (dd), ' + TIME_FORMAT;
