import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { state } from './state';
import { previewPopup } from './previewPopup';
import { withInputs } from '@h4h/inputs';
import { ecgObservationsList } from './list';

export const ecg = withInputs({
  namespaced: true,

  actions,
  getters,
  mutations,
  state,
  modules: {
    previewPopup,
    ecgObservationsList,
  }
});
