import { comments } from './comments';
import { taskComments } from './taskComments';

export const observations = {
  namespaced: true,
  modules: {
    comments,
    taskComments,
  }
};
