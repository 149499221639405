export class ProfileModel {
  /**
   * Profile config
   *
   * @param {Object}    config
   * @param {String}    config.initials
   * @param {String}    config.firstName
   * @param {String}    config.middleName
   * @param {String}    config.lastName
   * @param {String}    config.alarmPhoneNumber
   * @param {String}    config.partnerMiddleName
   * @param {String}    config.partnerName
   */
  constructor(config) {
    this.initials = config.initials;
    this.firstName = config.firstName;
    this.middleName = config.middleName;
    this.lastName = config.lastName;
    this.alarmPhoneNumber = config.alarmPhoneNumber;
    this.partnerMiddleName = config.partnerMiddleName;
    this.partnerName = config.partnerName;
  }
}
