import { Page } from '../../../constants/page';
import { EhRole } from '../../auth/ehRole';
import { disable } from '../routerPermissions';

export const DashboardRoutes = {
  path: 'practitioner-dashboard',
  name: Page.PractitionerDashboard,
  props: true,
  component: () => import('../../../components/views/app/practitioner/dashboard/Dashboard'),
  meta: {
    title: 'tasks.informationalNotifications',
    roles: [disable(EhRole.SsoUser)]
  },
};
