import { ApiEnumMapper } from '@h4h/classes';

import { EhRole } from './ehRole';

export const apiRoleMapper = new ApiEnumMapper('Role', {
  ADMIN: EhRole.Admin,
  PHYSICIAN: EhRole.Physician,
  POH: EhRole.Poh,
  PATIENT: EhRole.Patient,
  CALL_CENTER_SUPPORT: EhRole.CallCenterSupport,
  CALL_CENTER_ADMIN: EhRole.CallCenterAdmin,
  LOGISTICS_SUPPORT: EhRole.LogisticsSupport,
  RKZ_USER: EhRole.RKZUser,
  SSO_USER: EhRole.SsoUser,
  AUMC_READ_USER: EhRole.AumcReadUser,
});
