export const types = Object.freeze({
  SET_SAVING: 'SET_SAVING',
  INIT_PAGE: 'INIT_PAGE',
  SET_PRISTINE: 'SET_PRISTINE',
  SET_CONCLUSIONS: 'SET_CONCLUSIONS',
  SET_COMMENT: 'SET_COMMENT',

  SET_ECG_PDF_LOADING: 'SET_ECG_PDF_LOADING',
  SET_PDF: 'SET_PDF',
});
