import moment from 'moment';
import { mapArray } from '../../utils/mapUtils';

export class QuestionnaireModel {
  /**
   * ObservationModel config
   *
   * @param {Object}                        config
   * @param {String[]}                      config.associatedProgramInputQuestionnaireIds
   * @param {String}                        config.authored
   * @param {String}                        config.commentByMedicalPersonnel
   * @param {QuestionnaireResponseItem[]}   config.items
   * @param {String}                        config.patientUuid
   * @param {String}                        config.questionnaireIdempotencyKey
   * @param {String}                        config.questionnaireTemplateId
   * @param {String}                        config.uuid
   */
  constructor(config) {
    config = config || {};
    this.associatedProgramInputQuestionnaireIds = config.associatedProgramInputQuestionnaireIds;
    this.authored = config.authored;
    this.commentByMedicalPersonnel = config.commentByMedicalPersonnel;
    this.items = mapArray(config.items, QuestionnaireResponseItem);
    this.patientUuid = config.patientUuid;
    this.questionnaireIdempotencyKey = config.questionnaireIdempotencyKey;
    this.questionnaireTemplateId = config.questionnaireTemplateId;
    this.uuid = config.uuid;
  }

  /**
   * @param {String}            questionTerminologyCode
   * @return {QuestionnaireResponseItem}
   */
  getAnswer(questionTerminologyCode) {
    return this.items.filter(v => v.questionTerminologyCode === questionTerminologyCode)[0];
  }

  /**
   * @param {String}            questionTerminologyCode
   * @return {QuestionnaireResponseItem}
   */
  getOrCreateAnswer(questionTerminologyCode) {
    let value = this.getAnswer(questionTerminologyCode);
    if (!value) {
      value = new QuestionnaireResponseItem({ questionTerminologyCode });
      this.items.push(value);
    }
    return value;
  }

  get date() {
    return moment(this.authored).toDate();
  }

  set date(value) {
    this.authored = moment(value).format();
  }
}

export class QuestionnaireResponseItem {
  /**
   * QuestionnaireResponseItem config
   *
   * @param {Object}    config
   * @param {String}    config.questionTerminologyCode
   * @param {Boolean}   config.valueBoolean
   * @param {String}    config.valueFreeString
   * @param {number}    config.valueInt
   * @param {number}    config.valueNumeric
   * @param {String}    config.valueTerminology
   */
  constructor(config) {
    config = config || {};
    this.questionTerminologyCode = config.questionTerminologyCode;
    this.valueBoolean = config.valueBoolean;
    this.valueFreeString = config.valueFreeString;
    this.valueInt = config.valueInt;
    this.valueNumeric = config.valueNumeric;
    this.valueTerminology = config.valueTerminology;
  }
}
