import { types } from './types';

export const mutations = {
  [types.SET_INITIALIZED](state, value) {
    state.initialized = value;
  },
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },
  [types.SET_PRODUCT_VERSION](state, productVersion) {
    state.productVersion = productVersion;
  },
};

