import moment from 'moment';

import { DATE_FORMAT, TIME_FORMAT, DATETIME_FORMAT, DAY_FORMAT } from './constants';

import { isValidDate } from './isValidDate';

export function formatTableDate(date) {
  return formatDate(date);
}

export function formatDate(date, format = DATE_FORMAT, config) {
  return formatDateTime(date, format, config);
}

export function formatTime(date, format = TIME_FORMAT, config) {
  return formatDateTime(date, format, config);
}

export function formatDay(date, format = DAY_FORMAT, config) {
  return formatDateTime(date, format, config);
}

export function formatDateTime(date, format = DATETIME_FORMAT, config = {}) {
  if (!isValidDate(date)) {
    return '';
  }

  const m = moment(date);

  if (config.isUtc) {
    m.utc();
  }

  return m.format(format);
}

export function getWeekNumber(date) {
  // returns week number in the year
  return moment(date).isoWeek();
}
