import { types } from './types';
import { popups } from '../../../../../services/popups';
import { showRestError } from '../../../../../utils/errors';
import {
  getObservationsComments,
  observationComments
} from '../../../../../services/api/observations/observationComments';
import { PopupName } from '../../../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { toast } from '../../../../../services/toast';
import { localize } from '../../../../../services/localize';
import { ObservationType } from '../../../../../constants/observations';
import { evalPopupTopForIframeIfNeed } from '../../../../../utils/popupTopForIframeEvaluator';

export const actions = Object.freeze({
  saveComment,
  turnOnPopupEditMode,
  reloadComments,
  initCommentPopup,
});

async function reloadComments({ commit }, observations) {
  if (!observations.length) {
    return;
  }
  const result = await getObservationsComments(observations.map((observation => observation.uuid)));
  if (result.success) {
    commit(types.SET_COMMENTS, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchObservationComments');
  }
}

async function initCommentPopup({ state, commit }, observation) {
  let comment = state.comments.find(c => c.observationUuid === observation.uuid);
  if (comment) {
    commit(types.SET_COMMENT, comment);
    commit(types.SET_POPUP_VIEW_MODE_ON, true);
  }
  else {
    cleanState({ commit });
  }
  initPopup({ commit }, observation.uuid, ObservationType.COMMON);
}

async function turnOnPopupEditMode({ commit }) {
  commit(types.SET_POPUP_VIEW_MODE_ON, false);
}

async function saveComment({ rootState, state, commit, getters }) {
  if (!getters.valid || getValueInput(state).pristine) {
    return;
  }
  commit(types.SET_LOADING, true);
  let result;
  let value = getValueInput(state).value;
  if (state.comment) {
    result = await observationComments(state.observationId).update(state.comment.uuid,
      {
        value: value
      });
  }
  else {
    result = await observationComments(state.observationId).create({
      value: value
    });
  }
  if (result.success) {
    toast.success(localize('messages.observationCommentSaved'));
    popups.hide({ id: state.popupId });

    let page;
    if (state.popupObservationType === ObservationType.ECG) {
      page = rootState.patients.file.observations.single.ecg.list.observationsPage;
    }
    else {
      page = rootState.patients.file.enrollments.observation.list.table.groupedObservationsPage;
    }
    const observations = page?.content || [];
    await reloadComments({ commit }, observations);
  }
  else {
    showRestError(result.error, 'messages.cantSaveObservationComment');
  }
  commit(types.SET_LOADING, false);
}

async function initPopup({ commit }, observationId, observationType) {
  commit(types.INIT_POPUP, observationId);
  const { id } = await popups.show({
    name: PopupName.ObservationComment,
    type: PopupType.Modal,
    observationType: observationType,
    props: {
      ...evalPopupTopForIframeIfNeed(340 / 2),
    }
  });
  commit(types.SET_POPUP_ID, id);
  commit(types.SET_POPUP_OBSERVATION_TYPE, observationType);
}

function cleanState({ commit }) {
  commit(types.CLEAN_STATE);
}

function getValueInput(state) {
  for (let input of state.inputs) {
    if (input.id === 'value') {
      return input;
    }
  }
  return null;
}
