<template>
  <TextInput
    ref="input"
    :errorMessage="errorMessage"
    v-bind="$props"
    :value="renderValue($props.value)"
    v-on="listeners"
  />
</template>

<script>
  import { isNumber, isString } from 'lodash';

  import TextInput from './Text';

  import { InputMixin } from '../mixins';
  import { DECIMAL_SEPARATOR, inputsFeatureConfig } from '../utils';
  import { validationHelper } from '@h4h/utils';

  export default {
    name: 'H4hNumberInput',

    components: {
      TextInput
    },

    mixins: [
      InputMixin,
      validationHelper
    ],

    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      ...TextInput.props,
      value: [Number, String],
      rules: Array
    },

    data() {
      return {
        decimalSeparator: inputsFeatureConfig.getValue(DECIMAL_SEPARATOR)
      };
    },

    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          change: this.onChange
        };
      }
    },

    methods: {
      ...TextInput.methods,
      onInput(value) {
        this.$emit('input', this.parseValue(value));
      },

      onChange(value) {
        this.$emit('change', this.parseValue(value));
      },

      parseValue(value) {
        if (value === '') {
          return null;
        }

        // pass falsy or invalid values, numbers and strings that include a dot or multiple commas
        if (!value || isNumber(value) || !isString(value) || value.indexOf('.') !== value.lastIndexOf('.') || value.indexOf(',') !== value.lastIndexOf(',')) {
          return value;
        }

        // The parsed value must be a valid number. Regardless of the decimal separator config, in code it has to be decimal dot
        const parsed = +value.replace(',', '.');
        return Number.isFinite(parsed) ? parsed : value;
      },

      renderValue(value) {
        // pass on invalid values
        if (!Number.isFinite(this.parseValue(value))) {
          return value;
        }

        // when rendering the value, we check if we should replace the dot with a comma
        if (this.decimalSeparator !== '.') {
          return (value + '').replace('.', this.decimalSeparator);
        }

        return value;
      }
    }
  };
</script>
