import { CrudService } from '../../../classes/crudService';
import { AnamnesisModel } from '../../../model/anamnesis/anamnesisModel';

export function anamnesis(patientUuid) {
  return new CrudService(
    `/patients/${ patientUuid }/anamnesis`,
    data => new AnamnesisModel(data)
  );
}

