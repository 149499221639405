export const messages = {
  cantObtainTenantInfo: 'Kan geen informatie over de huurder verkrijgen',
  cantFetchQuestionnaire: 'Kan vragenlijst niet ophalen',
  questionnaireUpdated: 'Vragenlijst bijgewerkt',
  cantUpdateQuestionnaire: 'Kan vragenlijst niet bijwerken',
  cantCreateQuestionnaire: 'Kan geen vragenlijst maken',
  cantFetchAnamnesis: 'Kan anamnese niet ophalen',
  cantFetchAnamnesisSettings: 'Kan anamnese-instellingen niet ophalen',
  anamnesisUpdated: 'Anamnesis bijgewerkt',
  cantUpdateAnamnesis: 'Kan anamnese niet updaten',
  cantFetchProfile: 'Kan profiel niet ophalen',
  cantFetchUserPreferences: 'Kan gebruikersvoorkeuren niet ophalen',
  cantUpdateUserPreferences: 'Kan gebruikersvoorkeuren niet bijwerken',
  cantFetchAvatar: 'Kan avatar niet ophalen',
  cantUpdateProfile: 'Kan profiel niet updaten',
  profileUpdated: 'Profiel geüpdatet',
  patientDetailsUpdated: 'Patiëntgegevens bijgewerkt',
  cantUpdatePatientDetails: 'Kan patiëntgegevens niet bijwerken',
  cantCreatePatient: 'Kan geen patiënt creëren',
  cantLoadPatient: 'Kan patiënt niet laden. Patient is gearchiveerd of het profiel kan niet worden gevonden.',
  cantFetchOrganizationSetting: 'Kan organisatie-instelling niet ophalen',
  cantFetchTimezones: 'Kan tijdzones niet ophalen',
  organizationSettingUpdated: 'Organisatie-instelling geüpdatet',
  cantCreateOrganizationSetting: 'Kan geen organisatie-instelling maken',
  callCenterSuccessfullyDisabled: 'Zenuwcentrum is succesvol uitgeschakeld',
  callCenterSuccessfullyEnabled: 'Zenuwcentrum is succesvol ingeschakeld',
  cantDisableCallCenter: 'Kan call-center niet uitschakelen',
  cantEnableCallCenter: 'Kan call-center niet inschakelen',
  cantFetchObservation: 'Kan geen observatie ophalen',
  cantCreateObservation: 'Kan geen observatie creëren',
  someOfObservationsWereNotSavedCorrectly: 'Sommige observaties zijn niet correct opgeslagen',
  observationSaved: 'Observatie opgeslagen',
  cantFetchObservationTemplates: 'Kan observatie sjablonen niet ophalen',
  cantFetchObservationTemplate: 'Kan observatie sjabloon niet ophalen',
  cantFetchObservationTemplateInfo: 'Kan gegevens van observatiesjabloon niet ophalen',
  cantCreateObservationTemplate: 'Kan geen observatie sjabloon maken',
  cantUpdateObservationTemplate: 'Kan observatiesjabloon niet updaten',
  observationTemplateSaved: 'Observatie sjabloon opgeslagen',
  observationTemplateIsNotUpdatable: 'Observatiesjabloon kan niet worden geüpdate',
  cantFetchQuestionnaireTemplates: 'Kan geen vragenlijst sjablonen ophalen',
  cantFetchQuestionnaireTemplate: 'Kan vragenlijst sjabloon niet ophalen',
  cantCreateQuestionnaireTemplate: 'Kan geen vragenlijst sjabloon maken',
  questionnaireTemplateCreated: 'Vragenlijst sjabloon gemaakt',
  questionnaireTemplateUpdated: 'Vragenlijst sjabloon bijgewerkt',
  questionnaireTemplateIsNotUpdatable: 'Sjabloon voor vragenlijst kan niet worden bijgewerkt',
  cantFetchQuestionnaireTemplateInfo: 'Kan sjablooninformatie voor vragenlijst niet ophalen',
  cantDeleteGroups: 'Kan groepen verwijderen',
  cantFetchGroups: 'Kan groepen niet ophalen',
  medicalHistoryDeleted: 'Medische geschiedenis verwijderd',
  medicalHistoryUpdated: 'Medische geschiedenis bijgewerkt',
  cantDeleteMedicalHistory: 'Kan medische geschiedenis niet verwijderen',
  cantUpdateMedicalHistory: 'Kan medische geschiedenis niet updaten',
  cantCreateMedicalHistory: 'Kan geen medische geschiedenis maken',
  cantFetchMedicalHistories: 'Kan geen medische geschiedenis ophalen',
  allergyDeleted: 'Allergie verwijderd',
  allergyUpdated: 'Allergie bijgewerkt',
  cantUpdateAllergy: 'Kan allergie niet bijwerken',
  cantDeleteAllergy: 'Kan allergie niet verwijderen',
  cantCreateAllergy: 'Kan geen allergie veroorzaken',
  cantSendTAFeedback: 'Kan feedback over behandeladvies niet versturen',
  sentTAFeedback: 'Feedback over behandeladvies wordt verzonden',
  cantFetchAllergies: 'Kan geen allergieën ophalen',
  medicationDeleted: 'Medicatie verwijderd',
  medicationUpdated: 'Medicatie bijgewerkt',
  cantUpdateMedication: 'Kan medicatie niet updaten',
  cantDeleteMedication: 'Kan medicatie niet verwijderen',
  cantCreateMedication: 'Kan geen medicatie maken',
  cantFetchMedications: 'Kan geen medicijnen halen',
  cantFetchPrograms: 'Kan programma\'s niet ophalen',
  cantFetchProgram: 'Kan programma niet ophalen',
  cantFetchProgramEnrollmentsInfo: 'Kan informatie over programma-inschrijvingen niet ophalen',
  programSaved: 'Programma opgeslagen',
  cantCreateProgram: 'Kan programma niet maken',
  cantUpdateProgram: 'Kan programma niet updaten',
  cantFetchEnrollments: 'Kan inschrijvingen niet ophalen',
  cantFetchEnrollment: 'Kan inschrijving niet ophalen',
  cantCreateEnrollment: 'Kan geen inschrijvingen maken',
  cantFetchPatients: 'Ik kan geen patiënten ophalen',
  cantFetchOrgUsers: 'Kan organisatiegebruikers niet ophalen',
  cantFetchGroupsById: 'Kan gebruikersgroepen niet ophalen',
  cantJoinUserToGroup: 'Kan gebruiker niet bij groep voegen',
  cantExcludeUserFromGroup: 'Kan gebruiker niet uitsluiten van groep',
  patientSaved: 'Patiënt opgeslagen',
  patientUpdated: 'Patiënt geüpdatet',
  cantUpdatePatient: 'Kan patiënt niet updaten',
  cantFetchResponsiblePhysician: 'Kan behandelend arts niet ophalen',
  cantFetchResponsiblePhysicians: 'Kan behandelend arts niet ophalen',
  cantFetchCallCenterSupportUsers: 'Kan gebruikers van callcenter niet ophalen',
  cantFetchOrganizationCallCenterSetting: 'Kan callcenter-instellingen van de organisatie niet ophalen',
  cantFetchCallCenterTenantAddress: 'Kan het adres van het callcenter niet ophalen',
  cantCreateUser: 'Kan geen gebruiker aanmaken',
  userSaved: 'Gebruiker opgeslagen',
  userGroupsUpdated: 'Gebruikersgroepen bijgewerkt',
  cantFetchLabResult: 'Kan lab resultaat niet ophalen',
  labResultAdded: 'Lab resultaat toegevoegd',
  cantAddLabResult: 'Kan lab resultaat toevoegen',
  groupSaved: 'Groep opgeslagen',
  cantCreateGroup: 'Kan geen groep maken',
  groupUpdated: 'Groep geüpdatet',
  cantUpdateGroup: 'Kan groep niet updaten',
  groupDeleted: 'Groep verwijderd',
  cantDeleteGroup: 'Kan groep niet verwijderen',
  passwordSuccessfullyUpdated: 'Wachtwoord succesvol bijgewerkt',
  errorUpdatingPassword: 'Fout bij bijwerken van wachtwoord',
  passwordsMustBeTheSame: 'Wachtwoorden moeten hetzelfde zijn',
  passwordMustBeAtLeastXLettersLong: 'Wachtwoord moet  ten minste {0} letters lang zijn',
  passwordResetSuccessfully: 'Wachtwoord opnieuw ingesteld',
  errorResetPassword: 'Fout bij opnieuw instellen van wachtwoord',
  errors: {
    userAlreadyExists: 'Dit e-mailadres is al in gebruik',
    patientHisIdAlreadyUsed: 'De Patiënt ID is al in gebruik. Geef een uniek ID op om het patiëntendossier op te slaan.',
    programInputGroupsMismatchException: 'Sommige observaties zijn niet ingevoerd',
  },
  taskHandled: 'Taak succesvol afgehandeld',
  cantHandleTask: 'Taak afhandelen mislukt',
  cantFetchTasks: 'Kan taken niet ophalen',
  cantFetchTerminology: 'Kan taken terminologie',
  cantFetchEcgData: 'Kan taken EKG',
  successfullySaved: 'Succesvol opgeslagen',
  cantFetchObservationComments: 'Kan geen notities ophalen',
  cantSaveObservationComment: 'Opslaan niet gelukt. Probeer het opnieuw of neem contact op met uw beheerder.',
  observationCommentSaved: 'Notitie opgeslagen',
  enrollmentsStopped: 'Inschrijvingen gestopt',
  cantStopEnrollment: 'Kan inschrijving niet stoppen',
  roleAddedSuccessfully: 'Rol succesvol toegevoegd',
  roleRemovedSuccessfully: 'Rol succesvol verwijderd',
  errorAddRole: 'Kan rol niet toevoegen',
  errorRemoveRole: 'Kan rol niet verwijderen',
  cantFetchEmailTemplate: 'Kan e-mailsjabloon niet ophalen',
  taskIsForwarded: 'Taak wordt doorgestuurd',
  cantForwardTask: 'Kan taak niet doorsturen',
  cantFetchSupportedLanguages: 'Kan beschikbare talen niet ophalen',
  cantFetchCurrentLanguage: 'Kan ingestelde taal niet ophalen',
  currentLanguageUpdated: 'Ingestelde taal bijgewerkt',
  cantUpdateCurrentLanguage: 'Kan ingestelde taal niet bijwerken',
  cantFetchLabResultGroups: 'Kan labresultaat groepen niet ophalen',
  labResultGroupDeleted: 'Labresultaat groep verwijderd',
  cantDeleteLabResultGroup: 'Kan labresultaat groep niet verwijderen',
  labResultGroupSaved: 'Labresultaat groep opgeslagen',
  cantCreateLabResultGroup: 'Kan geen labresultaten groep maken',
  cantFetchLabResultGroup: 'Kan labresultaat groep niet ophalen',
  cantUpdateLabResultGroup: 'Kan labresultaat groep niet updaten',
  patientWasArchived: 'Patiënt is gearchiveerd',
  cantArchivePatient: 'Kan patiënt niet archiveren',
  cantRestorePatient: 'Kan de patiënt niet herstellen',
  cantUpdateObservationThresholds: 'Kan observatiedrempels niet updaten',
  cantUpdateObservation: 'Kan observatie niet updaten',
  cantUpdateProgramSchedule: 'Kan het programmaschema niet bijwerken',
  cantFetchConclusions: 'Kan conclusie van gezondheidsdossier niet ophalen',
  patientFileSaved: 'Patiënt dossier opgeslagen',
  enrollmentSaved: 'Inschrijving opgeslagen',
  cantFetchProductVersion: 'Kan productversie niet ophalen',
  cantFetchUdiDi: 'Kan UDI/DI niet ophalen',
  cantFetchEnvironmentProperties: 'Kan omgevingseigenschappen niet ophalen',
};
