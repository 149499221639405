import { UserFilter } from './userFilter';
import { mapValues } from 'lodash';

export class UserFilterStructure {
  /**
   *
   * @param {Object} config
   * @param {String} config.id
   * @param {String} config.userId
   * @param {Object} config.userFilters
   */
  constructor(config) {
    this.id = config.id;
    this.userId = config.userId;
    this.userFilters = config.userFilters;
  }

  static toApi(userFilter) {
    if (!userFilter) {
      return null;
    }

    const { id, userId, userFilters } = userFilter;

    return {
      id,
      userId,
      userFilters: prepareUserFilters(userFilters, UserFilter.toApi)
    };
  }

  static fromApi(userFilter) {
    if (!userFilter) {
      return null;
    }

    const { id, userId, userFilters } = userFilter;

    return new UserFilterStructure({
      id,
      userId,
      userFilters: prepareUserFilters(userFilters, UserFilter.fromApi)
    });
  }
}

function prepareUserFilters(userFilters, tableFiltersMapper) {
  return mapValues(
    userFilters,
    tableFilters => tableFilters && mapValues(tableFilters, tableFiltersMapper)
  );
}
