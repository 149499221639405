import { popups } from '@h4h/popups';

import { TableProfilePopups } from './components/model/TableProfilePopups';
import EditTableProfilePopup from './components/popups/edit/EditTableProfilePopup';

export { multipleFiltersProfiles } from './components/store';
export MultipleFiltersProfilesPopover from './components/MultipleFiltersProfilesPopover';
export { TableFiltersProfiles, TableFiltersProfile } from './components/model/TableFilterProfile';
export { MultipleFiltersProfilesConfig, ToastType } from './components/model/MultipleFiltersProfilesConfig';

popups.registerComponent(TableProfilePopups.EditTableProfile, EditTableProfilePopup);
