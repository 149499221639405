import { MonitoringPeriodModel } from './monitoringPeriodModel';
import { mapArray, mapObject } from '../utils/mapUtils';
import { ProgramVendorModel } from './programVendorModel';
import { ObservationThresholdModel } from './thresholds/observationThresholdModel';

export class ControlPlaneScriptModel {
  /**
   * ControlPlaneScriptModel config
   *
   * @param {Object}                  config
   * @param {String}                  config.content
   * @param {String}                  config.uuid
   */
  constructor(config) {
    this.content = config.content;
    this.uuid = config.uuid;
  }

}

export class TreatmentAdviceScriptModel {
  /**
   * TreatmentAdviceScriptModel config
   *
   * @param {Object}                  config
   * @param {String}                  config.content
   * @param {String}                  config.uuid
   * @param {String}                  config.renderTemplate
   */
  constructor(config) {
    this.uuid = config.uuid;
    this.content = config.content;
    this.renderTemplate = config.renderTemplate;
  }

}

export class ProgramModel {
  /**
   * ProgramModel config
   *
   * @param {Object}                  config
   * @param {MonitoringPeriodModel[]} config.availablePeriods
   * @param {ProgramVendorModel[]}    config.supportedVendors
   * @param {String}                  config.basePeriodUuid
   * @param {String}                  config.name
   * @param {String}                  config.uuid
   * @param {String}                  config.tenant
   * @param {Boolean}                 config.notUseEhealthApp
   * @param {ObservationThresholdModel[]} config.observationThresholds
   * @param {ControlPlaneScriptModel} config.controlPlaneScript
   * @param {TreatmentAdviceScriptModel} config.treatmentAdviceScript
   */
  constructor(config) {
    this.availablePeriods = mapArray(config.availablePeriods, MonitoringPeriodModel);
    this.supportedVendors = mapArray(config.supportedVendors, ProgramVendorModel);
    this.basePeriodUuid = config.basePeriodUuid;
    this.name = config.name;
    this.uuid = config.uuid;
    this.tenant = config.tenant;
    this.observationThresholds = mapArray(config.observationThresholds, ObservationThresholdModel);
    this.controlPlaneScript = mapObject(config.controlPlaneScript, ControlPlaneScriptModel);
    this.treatmentAdviceScript = mapObject(config.treatmentAdviceScript, TreatmentAdviceScriptModel);
    this.notUseEhealthApp = config.notUseEhealthApp;
  }
}

export class ProgramLimitedUpdateModel {
  /**
   * ProgramLimitedUpdateModel config
   *
   * @param {Object}                  config
   * @param {ControlPlaneScriptModel} config.controlPlaneScript
   * @param {TreatmentAdviceScriptModel} config.treatmentAdviceScript
   * @param {ObservationThresholdModel[]} config.observationThresholds
   * @param {MonitoringPeriodModel[]} config.availablePeriods //only hasNoDuration attribute can be changed here
   * @param {Boolean}                 config.notUseEhealthApp
   */
  constructor(config) {
    this.controlPlaneScript = mapObject(config.controlPlaneScript, ControlPlaneScriptModel);
    this.treatmentAdviceScript = mapObject(config.treatmentAdviceScript, TreatmentAdviceScriptModel);
    this.observationThresholds = mapArray(config.observationThresholds, ObservationThresholdModel);
    this.availablePeriods = mapArray(config.availablePeriods, MonitoringPeriodModel);
    this.notUseEhealthApp = config.notUseEhealthApp || false;
  }
}

export class ProgramUpdateModel extends ProgramLimitedUpdateModel {
  /**
   * ProgramModel config
   *
   * @param {Object}                  config
   * @param {ControlPlaneScriptModel} config.controlPlaneScript
   * @param {ObservationThresholdModel[]} config.observationThresholds
   * @param {TreatmentAdviceScriptModel} config.treatmentAdviceScript
   * @param {String} config.basePeriodName
   * @param {ProgramVendorModel[]}    config.supportedVendors
   * @param {Boolean}                 config.notUseEhealthApp
   */
  constructor(config) {
    super(config);
    this.basePeriodName = config.basePeriodName;
    this.supportedVendors = mapArray(config.supportedVendors, ProgramVendorModel);
  }
}

export class ProgramCreationModel {
  /**
   * ProgramCreationModel config
   *
   * @param {Object}                  config
   * @param {MonitoringPeriodModel[]} config.availablePeriods
   * @param {ProgramVendorModel[]}    config.supportedVendors
   * @param {String}                  config.basePeriodName
   * @param {String}                  config.name
   * @param {String}                  config.controlPlaneScript
   * @param {String}                  config.treatmentAdviceScript
   * @param {String}                  config.renderTemplate
   * @param {Boolean}                 config.notUseEhealthApp
   * @param {ObservationThresholdModel[]} config.observationThresholds
   */
  constructor(config) {
    this.availablePeriods = mapArray(config.availablePeriods, MonitoringPeriodModel);
    this.supportedVendors = mapArray(config.supportedVendors, ProgramVendorModel);
    this.basePeriodName = config.basePeriodName;
    this.name = config.name;
    this.controlPlaneScript = config.controlPlaneScript;
    this.treatmentAdviceScript = config.treatmentAdviceScript;
    this.renderTemplate = config.renderTemplate;
    this.observationThresholds = mapArray(config.observationThresholds, ObservationThresholdModel);
    this.notUseEhealthApp = config.notUseEhealthApp || false;
  }
}

export class ProgramHasEnrollmentsModel {
  /**
   * ProgramHasEnrollmentsModel config
   *
   * @param {Object}                  config
   * @param {Boolean} config.hasEnrollments
   */
  constructor(config) {
    this.hasEnrollments = config.hasEnrollments;
  }
}

