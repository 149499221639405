/* global VERSION_API_URL */

import { CrudService } from '../../../classes/crudService';
import { EnvironmentModel } from '../../../model/environment';

export const environmentService = new CrudService(
  '/environment-properties',
  data => new EnvironmentModel(data),
  model => model,
  VERSION_API_URL
);

