<template>
  <div :class="[$props.disabled && styles.disabled]">
    <TextInput
      v-if="$props.options.length && $props.searchInput"
      v-bind="$props.searchInput"
      :value="query"
      :groupId="$props.groupId"
      :class="styles.search"
      @change="setQuery"
      @blur="onInputBlur"
      @focus="onInputFocus"
    >
      <template
        v-if="dropdownTreeView"
        #pills
      >
        <Chip
          v-for="(item, index) in selection"
          :key="index"
          outlined
          :label="false"
          :close="true"
          @click:close="uncheckSelectItem(item)"
        >
          <slot name="chipSelectionContent">
            {{ getItemName(item) }}
          </slot>
        </Chip>
      </template>
    </TextInput>

    <div
      v-if="displayTreeView || !$props.dropdownTreeView"
      :class="[$props.dropdownTreeView && styles.wrapper]"
      @mouseover="onDropdownMouseOver"
      @mouseleave="onDropdownMouseLeave"
    >
      <VTreeview
        activable
        returnObject
        :dense="true"
        :tabindex="tabIndex"
        :items="options"
        :search="query"
        :openOnClick="openOnClick"
        :openAll="openAll"
        @focus="tabIndexSubscriber__onFocus"
      >
        <template #prepend="{ item, open }">
          <div :class="styles.iconContainer">
            <Icon
              v-if="item.children && item.children.length"
              :class="styles.icon"
              :type="open ? 'chevron-down' : 'chevron-right'"
            />
            <div
              v-else
              :class="styles.emptyDiv"
            />
            <Checkbox
              v-if="selectable"
              :value="isItemCheckMarked(item, selection)"
              :groupId="$props.groupId"
              @change="setSelection(item)"
            />
          </div>
        </template>

        <template
          #label="{ item }"
        >
          <div
            :class="styles.label"
            @click="clickable && onItemClick(item)"
          >
            {{ item.name }}
          </div>
        </template>
        <template
          v-for="(_, name) in $scopedSlots"
          :slot="name"
          slot-scope="item"
        >
          <slot
            :name="name"
            v-bind="item"
          />
        </template>
      </VTreeview>
    </div>
  </div>
</template>

<script>
  import { VTreeview } from 'vuetify/lib/components/VTreeview';

  import { Icon } from '@h4h/icons';
  import { Chip } from '@h4h/chip';

  import {
    select,
    inputProps,
    getItemByPath,
    isItemCheckMarked
  } from '../../utils';

  import { InputMixin } from '../../mixins';

  import TextInput from '../Text';
  import Checkbox from '../checkbox/Checkbox';

  import styles from './treeView.scss';

  // @todo: Baizulin - get rid of internal selection, rework to match common inputs interface
  export default {
    name: 'H4hTreeView',

    components: {
      VTreeview,
      TextInput,
      Checkbox,
      Icon,
      Chip
    },

    mixins: [
      InputMixin
    ],

    props: {
      value: Array,

      options: {
        type: Array,
        required: true
      },

      searchInput: {
        type: Object,
        default: null
      },

      /** flags */
      openOnClick: inputProps.booleanTrue,
      selectable: inputProps.booleanTrue,
      clickable: inputProps.booleanFalse,
      multiple: inputProps.booleanFalse,
      dropdownTreeView: inputProps.booleanFalse,
      disabled: inputProps.booleanFalse,
      openAll: inputProps.booleanTrue
    },

    data() {
      return {
        styles,
        query: '',
        selection: this.$props.value || [],
        displayTreeView: false,
        treeViewHovered: false,
        inputFocused: false
      };
    },

    watch: {
      ['$props.value']() {
        this.selection = this.$props.value;
      }
    },

    methods: {
      isItemCheckMarked,
      getItemByPath,

      setSelection(item) {
        if (!this.selection) {
          this.selection = [];
        }
        else {
          this.selection = this.selection.slice();
        }

        select.setSelection(item, this.selection, this.$props.options, undefined, undefined, undefined, this.multiple);
        this.$emit('change', this.selection);

        const selectedIds = this.selection.map(path => path.slice(-1)[0]);
        this.$emit('select-item', this.multiple ? selectedIds : selectedIds[0]);
      },

      uncheckSelectItem(item) {
        const item2 = this.getItemByPath(item, this.options);
        return this.setSelection(item2);
      },

      getItemName(item) {
        const item2 = this.getItemByPath(item, this.options);

        return item2.name;
      },

      onItemClick(item) {
        this.$emit('onClick', item);
      },

      setQuery(value) {
        this.query = value;
      },

      onInputFocus() {
        if (!this.$props.dropdownTreeView) {
          return;
        }
        this.openIds = this.$props.options;
        this.displayTreeView = true;
        this.inputFocused = true;
      },

      onInputBlur() {
        if (!this.treeViewHovered) {
          this.displayTreeView = false;
        }
        this.inputFocused = false;
      },

      onDropdownMouseOver() {
        this.treeViewHovered = true;
        this.displayTreeView = true;
      },

      onDropdownMouseLeave() {

        this.treeViewHovered = false;
      },
    }
  };
</script>
