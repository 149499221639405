import { stopEnrollmentInputs } from './utils';

export const getters = {
  valid,
  inputs: stopEnrollmentInputs,
  programsNames,
};

function valid(state) {
  return stopEnrollmentInputs(state).every(i => i?.valid);
}

function programsNames(state) {
  return state.enrollments
    .map(e => e.program.name)
    .filter(n => !!n)
    .join(', ');
}
