export const observations = {
  observations: 'Observations',
  observation: 'Observation',
  addObservation: 'Add observation',
  chooseObservationGroup: 'Choose observation group',
  chooseObservationsHelpText: 'You may be able to enter more than one type of measurement. Please indicate what you would like to do.',
  makeIrrelevant: 'Make irrelevant',
  makeRelevant: 'Make relevant',
  measurements: 'Measurements',
  observationNote: 'Observation note',
  measuredBy: 'Measurement by',
  callCenterUser: 'Call center user',
  other: 'Other',
  observationIsNotInTheCurrentPeriodHelpText: 'This measurement will not be displayed in the graph because the observation date does not fall within the start- and end date of this period.',
  observationIsHidden: 'Observation is hidden.',
  observationIsVisible: 'Observation is visible.',
  allMeasurements: 'All measurements',
  pdf: 'PDF',
};
