export const state = {
  observationsLoading: false,
  observationTemplate: null,
  patient: null,
  enrollment: null,

  period: null,
  conclusionNames: null,
  conclusionBy: null,

  groupedObservationsPage: null,
  serverSideOptions: {},
  observationTaskMap: new Map(),
  selectedGroupedObservations: [],

  selectedObservationUuid: null,
};
