import { KardiaConnectionStatus } from '../../../constants/kardia/kardiaConnectionStatus';

export const kardia = {
  cantFetchKardiaPatientInformation: 'Can\'t fetch Kardia patient information',
  alivecorKardia: 'AliveCor Kardia',
  kardia: 'Kardia',
  requestNewConnectionCode: 'Request new connection code',
  errorPerformRequest: 'Error perform request',
  requestSuccessfullyCreatedMessage: 'Ask patient to check email for connection code',
  kardiaConnectionStatus: 'Alivecor Kardia connection status',
  [KardiaConnectionStatus.CONNECTED]: 'Connected',
  [KardiaConnectionStatus.PENDING]: 'Pending',
  [KardiaConnectionStatus.DISCONNECTED]: 'Disconnected',
};
