export const patient = {
  addPatient: 'Add patient',
  patientDetails: 'Patient details',
  patient: 'Patient',
  patients: 'Patients',
  patientOverview: 'Patient overview',
  showArchivedPatients: 'Show archived patients',
  restorePatient: 'Restore patient',
  areYouSureYouWantToRestoreThisPatient: 'Are you sure you want to restore this patient?',
  filterText: 'Search patient',
  bloodPressure: 'Blood pressure',
  patientFile: 'Patient file',
  diagnose: 'Diagnosis',
  medicalHistories: 'Medical histories',
  medicalHistory: 'Medical history',
  addMedicalHistory: 'Add medical history',
  newMedicalHistory: 'New medical history',
  editMedicalHistory: 'Edit medical history',
  addNewMedicalHistory: 'Add new medical history',
  allergy: 'Allergy',
  allergies: 'Allergies',
  addAllergy: 'Add allergy',
  newAllergy: 'New allergy',
  editAllergy: 'Edit allergy',
  addNewAllergy: 'Add new allergy',
  medication: 'Medication',
  medications: 'Medications',
  addMedication: 'Add medication',
  newMedication: 'New medication',
  editMedication: 'Edit medication',
  addNewMedication: 'Add new medication',
  responsiblePhysician: 'Responsible physician',
  responsiblePhysicianAlarmNumber: 'Responsible physician alarm number',
  responsiblePhysicianEmail: 'Responsible physician email',
  noResponsiblePhysician: 'No treating specialist selected',
  patientInfo: 'Patient info',
  partnerInfo: 'Partner info',
  treatingPhysician: 'Treating physician',
  phoneNumberPhysician: 'Phone number physician',
  patientSince: 'Patient since',
  addMedicalHistoryHeading: 'Add medical history',
  editMedicalHistoryHeading: 'Edit medical history',
  editAllergyHeading: 'Edit allergy',
  editMedicationHeading: 'Edit medication',
  ssn: 'Social security number',
  // missingAddressWarning: 'Please note: Without an address it is not possible to send measurement devices to the patient.',
  onlyDutchPhonesAreSupportedForSms: 'At this moment, it is only possible to use of the Heart for Health app with Dutch phone numbers.',
  patientNumber: 'Patient number',
};
