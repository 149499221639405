export const InputStateChangeEventType = Object.freeze({
  FOCUS: 'FOCUS',
  BLUR: 'BLUR',
});

export class InputStateChangeEvent {

  /**
   * @param{Object} config
   * @param{Object} config.input
   * @param{String} config.type
   */
  constructor({ input, type }) {
    this.input = input;
    this.type = type;
  }
}

/**
 * @param state
 * @param {InputStateChangeEvent} payload
 */
export function inputStateChangeMutation(state, payload) {
  payload.input.focused = payload.type === InputStateChangeEventType.FOCUS;
  if (payload.input.focused) {
    payload.input.validBeforeFocus = payload.input.valid;
  }
  if (payload.type === InputStateChangeEventType.BLUR) {
    if (payload.input.validate) {
      payload.input.valid = payload.input.validate(payload.input);
    }
  }
}

export function createInputStateChangeAction(mutationName) {
  return function({ commit }, payload) {
    commit(mutationName, payload);
  };
}
