import { fromPairs } from 'lodash';
import sanitizeHtml from 'sanitize-html';

export const WysiwygActionType = Object.freeze({
  Tag: 'Tag',
  CustomTag: 'CustomTag'
});

export const wysiwygActionsList = [
  { id: 'b', label: 'wysiwyg.bold', type: WysiwygActionType.Tag, tag: 'b', command: 'bold' },
  { id: 'i', label: 'wysiwyg.italic', type: WysiwygActionType.Tag, tag: 'i', command: 'italic' },
  // 'underline',
  { id: 'strike', label: 'wysiwyg.strike', type: WysiwygActionType.Tag, tag: 'strike', command: 'strikeThrough' },
  { id: 'h1', label: 'wysiwyg.h1', type: WysiwygActionType.Tag, tag: 'h1', command: 'formatBlock' },
  { id: 'h2', label: 'wysiwyg.h2', type: WysiwygActionType.Tag, tag: 'h2', command: 'formatBlock' },
  { id: 'p', label: 'wysiwyg.p', type: WysiwygActionType.Tag, tag: 'p', command: 'formatBlock' },
  // 'quote',
  { id: 'ol', label: 'wysiwyg.ol', type: WysiwygActionType.Tag, tag: 'ol', command: 'insertOrderedList' },
  { id: 'ul', label: 'wysiwyg.ul', type: WysiwygActionType.Tag, tag: 'ul', command: 'insertUnorderedList' },
  { id: 'link', label: 'wysiwyg.link', type: WysiwygActionType.Tag, tag: 'a', command: 'createLink' }
  // 'code',
  // 'line',
  // 'image'
];

export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const wysiwygActions = fromPairs(wysiwygActionsList.map(a => [a.id, a]));

export function getWysiwygActionSlots(action) {
  const item = 'action.' + action.id;

  return {
    item,
    content: item + '.content'
  };
}

export const TAG_ID_PREFIX = 'h4hCustomTag=';
const allowedTags = ['br', 'li', ...wysiwygActionsList.map(a => a.tag)];

const allowedAttributes = ['style', 'href'];
const allowedAttributesMap = {};
allowedTags.forEach(tag => {
  allowedAttributesMap[tag] = allowedAttributes;
});

export function insertElementAtRange(element, range) {
  if (!element) {
    throw new Error(`Can't insert element at range - element is ${ JSON.stringify(element) }`);
  }

  if (!range) {
    throw new Error(`Can't insert element at range - range is ${ JSON.stringify(range) }`);
  }

  range.deleteContents();
  range.insertNode(element);
}

export function convertCustomTagsToBbCode(string) {
  string = string.replace(/\n/g, '');

  string = string.replace(
    /<div[^>]+data-tag-id="([^"]+)"[^>]+>[\s\S]*?<\/div>/g,
    (_, id) => `[${ id }]`
  );

  return sanitizeHtml(string, {
    allowedTags,
    allowedAttributes: allowedAttributesMap
  });
}

export function convertCustomTagsToHtml(string, allowedTagIds) {
  return string.replace(
    new RegExp(
      `\\[${ TAG_ID_PREFIX }([^\\]]+)]`,
      'g'
    ),
    (_, tagId) => !allowedTagIds || allowedTagIds.includes(tagId)
      ? `<div data-tag-id="${ TAG_ID_PREFIX }${ tagId }"></div>`
      : '');
}

export function getSelection() {
  return window.getSelection();
}

export function getSelectionRange() {
  const range = getSelection();
  return range.rangeCount ? range.getRangeAt(0) : null;
}
