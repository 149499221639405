export const notificationType = Object.freeze({
  MONITORING_PERIOD_IS_ENDED: 'tasks.practitioner.info.mp.end',
  PROGRAM_WAS_STOPPED: 'task.info.enrollment.stop',
  PATIENT_IS_ENROLLED: 'enrollment.patient.message',
  YOUR_PATIENT_HAS_SUBMITTED_QUESTIONNAIRE: 'your.patient.has.submitted.questionnaire',
  PATIENT_HAS_DISONNECTED_FROM_KARDIA: 'alivecor.kardia.task.patient.disconnected',
});
export const allNotificationTypes = [
  notificationType.MONITORING_PERIOD_IS_ENDED,
  notificationType.PROGRAM_WAS_STOPPED,
  notificationType.PATIENT_IS_ENROLLED,
  notificationType.YOUR_PATIENT_HAS_SUBMITTED_QUESTIONNAIRE,
  notificationType.PATIENT_HAS_DISONNECTED_FROM_KARDIA,
];
