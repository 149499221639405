import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { questionnaireTemplates } from '../../../services/api/questionnaire/questionnaireTemplates';

export const actions = Object.freeze({
  fetchTemplates,
  reload,
  initPage,
  onFilterChange,
});

async function initPage({ commit, state }) {
  commit(types.INIT_PAGE);
  await fetchTemplates({ commit, state });
}

async function onFilterChange({ commit }) {
  commit(types.UPDATE_FILTER);
}

async function reload({ commit, state }) {
  commit(types.SET_FETCHED, false);
  return await fetchTemplates({ commit, state });
}

async function fetchTemplates({ commit, state }) {
  if (state.fetched) {
    return state.questionnaireTemplates;
  }

  commit(types.SET_LOADING, true);
  const result = await questionnaireTemplates.fetch();
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_FETCHED, true);
    commit(types.SET_QUESTIONNAIRE_TEMPLATES, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchQuestionnaireTemplates');
  }
  commit(types.UPDATE_FILTER);
  return state.questionnaireTemplates;
}

