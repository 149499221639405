<template>
  <div
    :class="[
      inputStyles.container,
      $props.inline && inputStyles.inline
    ]"
  >
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />

    <label
      :for="id"
      :class="[
        inputStyles.inputGroup,
        {
          focus: focused,
          disabled: $props.disabled,
          invalid: !$props.pristine && !$props.valid,
          warning: $props.warning
        }
      ]"
    >
      <textarea
        :id="id"
        ref="input"
        autocomplete="off"

        :maxlength="$props.max"
        :rows="$props.rows"
        :name="$props.name"
        :value="$props.value"
        :class="[inputStyles.input, inputStyles.textArea]"
        :disabled="$props.disabled"
        :tabindex="tabIndex"
        :readonly="$props.readonly"
        :placeholder="$localize($props.placeholder)"
        :data-testid="`field-${$props.name}`"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        @change="onChange"
      />
    </label>
  </div>
</template>

<script>
  import { uniqueId } from 'lodash';

  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { inputProps } from '../utils';
  import { InputMixin } from '../mixins';

  import LabelGroup from './labelGroup/LabelGroup';

  export default {
    name: 'H4hTextArea',

    components: {
      LabelGroup
    },

    mixins: [
      InputMixin
    ],

    props: {
      value: [String, Number],

      rows: {
        type: Number,
        default: 3
      },

      max: {
        type: Number,
        required: false
      },

      /** labels */
      placeholder: [String, Array],
      otherLabel: [Array, String],
      info: [String, Array],

      /** flags */
      inline: inputProps.booleanFalse,
      warning: inputProps.booleanFalse,
      autosize: inputProps.booleanFalse,
      readonly: inputProps.booleanFalse,

      /** styles */
      labelStyle: inputProps.style
    },

    data() {
      return {
        inputStyles,
        focused: false,
        id: uniqueId('textarea-'),
      };
    },

    computed: {
      input() {
        return this.$refs.input;
      }
    },

    mounted() {
      this.$nextTick(
        () => {
          this.updateSize();
        }
      );
    },

    methods: {
      onInput(e) {
        this.updateSize();
        // also emit change because for text inputs it the same
        this.$emit('input', e.target.value);
        this.$emit('change', e.target.value);
      },

      onChange(e) {
        this.$emit('change', e.target.value);
      },

      onFocus(e) {
        this.focused = true;
        this.$emit('focus', e);

        this.tabIndexSubscriber__onFocus();
      },

      onBlur(e) {
        this.focused = false;
        this.$emit('blur', e);
      },

      focus() {
        this.input.focus();
      },

      updateSize() {
        if (!this.$props.autosize) {
          return;
        }

        this.input.style.height = 'auto';
        this.input.style.height = `${ this.input.scrollHeight }px`;
      }
    }
  };
</script>
