import { types } from './types';
import { QuestionnaireWrapper } from '../../../../../../components/questionnaire/wrapper/questionnaireWrapper';
import { batchPatientQuestionnaire } from '../../../../../../services/api/questionnaire/questionnaire';
import { showRestError } from '../../../../../../utils/errors';
import { QuestionnaireModel } from '../../../../../../model/anamnesis/questionnaireModel';

export const actions = Object.freeze({
  init,
  save,
});

async function init({ commit }, {
  questionnaireTemplate,
  questionnaireTemplateUuids,
  questionnaireTemplates,
  patient,
  terminologyTypes,
  enrollment
}) {
  commit(types.INIT_FORM, {
    questionnaireTemplate,
    questionnaireTemplateUuids,
    questionnaireTemplates,
    patient,
    terminologyTypes,
    enrollment,
  });
}

async function save({ commit, state, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.valid) {
    return null;
  }
  let authored = state.date.value;
  if (authored.getTime() === state.originalQuestionnaireDate.getTime()) {
    authored = null;
  }
  const questionnaires = state.inputGroups.map(ig => {
    const questionnaire = new QuestionnaireModel({
      ...ig.questionnaire,
      authored,
    });
    new QuestionnaireWrapper(ig.questionnaireTemplate, questionnaire).updateValuesFromInputs(ig.inputs);
    return questionnaire;
  });

  commit(types.SET_LOADING, true);
  const result = await batchPatientQuestionnaire(state.patient.uuid).create({ questionnaires });
  commit(types.SET_LOADING, false);
  if (result.success) {
    return true;
  }
  else {
    showRestError(result.error, 'messages.cantUpdateQuestionnaire');
  }
  return true;
}

