<template>
  <VMenu
    v-model="isActive"
    v-bind="$attrs"

    :closeOnClick="$props.closeOnClick"
    :closeOnContentClick="$props.closeOnContentClick"

    :left="$props.left"
    :right="$props.right"
    :top="$props.top"
    :bottom="$props.bottom"
    :zIndex="zIndex"

    :nudgeBottom="$props.nudgeBottom"
    :nudgeLeft="$props.nudgeLeft"
    :nudgeRight="$props.nudgeRight"
    :nudgeTop="$props.nudgeTop"
    :nudgeWidth="$props.nudgeWidth"

    :offsetY="$props.offsetY"
    :offsetX="$props.offsetX"
    :offsetOverflow="$props.offsetOverflow"

    :openOnClick="$props.openOnClick"
    :openOnHover="$props.openOnHover"
    :openDelay="openDelay"

    :attach="$props.attach"

    :class="styles.menu"
  >
    <template #activator="activatorProps">
      <slot
        name="activator"
        v-bind="activatorProps"
      >
        <div
          class="clickable"
          v-on="activatorProps.on"
        >
          <slot
            name="openPopover"
            :value="activatorProps.value"
          />
        </div>
      </slot>
    </template>
    <slot v-bind="{ isActive, show, hide, toggle }"/>
  </VMenu>
</template>

<script>
  import { VMenu } from 'vuetify/lib';
  import { isBoolean, isNil } from 'lodash';

  import styles from './popover.scss';

  export default {
    name: 'H4hPopover',
    components: {
      VMenu
    },

    model: {
      prop: 'value',
      event: 'change',
    },

    props: {
      // https://vuetifyjs.com/en/api/v-menu/#props
      attach: {
        type: [Boolean, HTMLElement],
        default: false,
      },
      bottom: {
        type: Boolean,
        default: false
      },
      closeOnClick: {
        type: Boolean,
        default: true
      },
      closeOnContentClick: {
        type: Boolean,
        default: true
      },
      left: {
        type: Boolean,
        default: false
      },
      nudgeBottom: {
        type: [Number, String],
        default: 0
      },
      nudgeLeft: {
        type: [Number, String],
        default: 0
      },
      nudgeRight: {
        type: [Number, String],
        default: 0
      },
      nudgeTop: {
        type: [Number, String],
        default: 0
      },
      nudgeWidth: {
        type: [Number, String],
        default: 0
      },
      offsetOverflow: {
        type: Boolean,
        default: false
      },
      offsetX: {
        type: Boolean,
        default: false
      },
      offsetY: {
        type: Boolean,
        default: false
      },
      openOnClick: {
        type: Boolean,
        default: true
      },
      openOnHover: {
        type: Boolean,
        default: false
      },
      right: {
        type: Boolean,
        default: false
      },
      top: {
        type: Boolean,
        default: false
      },
      openDelay: {
        type: [Number, String],
        default: 400
      },
      value: {
        type: null, // type: Boolean automatically casts to false, while we want to discern between false and undefined
        default: undefined,
        validator(v) {
          return isBoolean(v) || isNil(v);
        }
      },
      zIndex: {
        type: [Number, String],
      }
    },

    data() {
      return {
        styles,
        active: false
      };
    },

    computed: {
      isActive: {
        // use true/false value from parent, otherwise use internal state.
        get() {
          return this.$props?.value ?? this.active;
        },
        set(v) {
          this.active = v;
          this.$emit('change', v);
        }
      }
    },

    methods: {
      show() {
        this.isActive = true;
      },

      hide() {
        this.isActive = false;
      },

      toggle() {
        this.isActive = !this.isActive;
      }
    }
  };
</script>
