import { types } from './types';
import { createInputConfig } from '@h4h/inputs';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { groupBy } from 'lodash';

export const mutations = {

  [types.INIT](state, { tasks }) {
    state.tasks = tasks;
    state.loading = false;
    state.commentInput = null;
    state.sharedCommentValue = null;
    const taskWithObservations = tasks
      .filter(task => !!task.observation?.payload)
      .filter(task => !!task.observation.externalObservationId)
      // .filter(task => task.observation.payload.terminologyType !== ecgKey)
    ;
    state.observations = groupBy(taskWithObservations, task => task.tenantId);
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_COMMENTS](state, comments) {
    state.comments = comments;
  },

  [types.INIT_COMMENT_INPUT](state) {
    if (Object.keys(state.observations).length) {
      state.commentInput = createInputConfig({
        id: 'observationComment',
        type: InputType.Textarea,
        value: null,
        required: false,
        label: 'ecg.comment',
      });
    }
  },
};

