<template>
  <v-tooltip
    :key="key"
    v-model="tooltipValue"
    v-bind="$props"
    :attach="attach"
  >
    <template #activator="{ on }">
      <div
        :id="id"
        :class="$props.activatorStyle"
        v-on="on"
      >
        <slot name="activatorContent"/>
      </div>
    </template>
    <slot name="info"/>
  </v-tooltip>
</template>

<script>
  import { VTooltip } from 'vuetify/lib';
  import { uniqueId } from 'lodash';

  export default {
    name: 'H4hTooltip',

    components: {
      VTooltip
    },

    props: {
      icon: String,
      value: Boolean,
      top: {
        type: Boolean,
        default: true
      },
      left: Boolean,
      right: Boolean,
      bottom: Boolean,
      contentClass: String,
      nudgeBottom: Number,
      nudgeLeft: Number,
      nudgeRight: Number,
      nudgeTop: Number,
      nudgeWidth: Number,
      maxWidth: Number,
      zIndex: String,
      color: String,
      disabled: Boolean,
      absolute: Boolean,
      openOnClick: {
        default: false,
        type: Boolean
      },
      openOnFocus: {
        default: false,
        type: Boolean
      },
      openOnHover: {
        default: true,
        type: Boolean
      },
      openDelay: {
        default: 100,
        type: Number
      },
      fixed: {
        type: Boolean,
        default: true
      },
      activatorStyle: String,
      scrollStrategy: {
        type: String,
        default: 'close' // Override default value from Vuetify - https://vuetifyjs.com/en/api/v-tooltip/#props-scroll-strategy
      }
    },

    data() {
      return {
        key: 0,
        tooltipValue: false,
        id: uniqueId('tooltip-'),
      };
    },
    computed: {
      attach() {
        return this.$props.absolute ? `#${ this.id }` : false;
      },
    },
    watch: {
      tooltipValue() {
        this.$emit('change', this.tooltipValue);
      }
    }
  };
</script>
