import { CrudService } from '../../../classes/crudService';
import { QuestionnaireTemplateModel } from '../../../model/questionnaireTemplateModel';
import { IsUpdatable } from '../../../model/isUpdatable';

export const questionnaireTemplates = new CrudService(
  'questionnaire-templates',
  data => new QuestionnaireTemplateModel(data),
);

export async function questionnaireTemplatesUpdatable(uuid) {
  return new CrudService(
    `questionnaire-templates/${ uuid }/updatable`,
    data => new IsUpdatable(data),
  ).fetchOne();
}

