<template>
  <div
    v-if="$props.isErroring && error"
    :class="[styles.hint, styles.error]"
  >
    <Icon
      :class="[styles.iconInfo, styles.error, styles.icon]"
      type="info"
    />
    {{ $localize($props.error) }}
  </div>
  <div
    v-else-if="$props.hint"
    :class="styles.hint"
  >
    <Icon
      :class="[styles.iconInfo, styles.icon]"
      type="info"
    />
    {{ $localize($props.hint) }}
  </div>
</template>

<script>
  import styles from './file.scss';

  export default {
    name: 'H4hInfoBar',

    props: {
      isErroring: Boolean,

      error: {
        type: String,
        default: null
      },

      hint: {
        type: String,
        valid: null
      },
    },

    data() {
      return {
        styles
      };
    }

  };
</script>
