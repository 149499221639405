export const common = {
  all: 'Allemaal',
  min: 'Min',
  max: 'Max',
  tenant: 'Tenant',
  date: 'Datum',
  logout: 'Uitloggen',
  profile: 'Profiel',
  organizationSetting: 'Organisatie',
  phoneNumber: 'Telefoonnummer',
  number: 'Nummer',
  numberShort: 'Nummer',
  biography: 'Biografie',
  clinicName: 'Naam kliniek',
  replyLink: 'Algemeen E-mail adres',
  showDisableDirectLoginDropdown: 'Toon dropdown voor direct inloggen uitschakelen',
  bsnRegexp: 'BSN Regex',
  pleaseUseValidRegexpForThisField: 'Gebruik geldige Regex voor dit veld',
  applyDutchDeepBsnCheck: 'Pas uitgebreide BSN check toe',
  postalAddress: 'Postadres',
  addressData: 'Adresgegevens',
  treatmentAdviceEnabled: 'Behandeladvies',
  treatmentAdviceEnabledValue: 'ingeschakeld',
  treatmentAdviceDisabledValue: 'uitgeschakeld',
  save: 'Opslaan',
  cancel: 'Annuleren',
  add: 'Toevoegen',
  remove: 'Verwijderen',
  edit: 'Wijzig',
  initials: 'Initialen',
  firstName: 'Voornaam',
  middleName: 'Tussenvoegsel',
  lastName: 'Achternaam',
  partnerMiddleName: 'Partner tussenvoegsel',
  partnerName: 'Partner achternaam',
  dateOfBirth: 'Geboortedatum',
  age: 'Leeftijd',
  alarmPhoneNumber: 'Contact nummer',
  telephoneNumber: 'Mobiel telefoonnummer',
  hisPatientId: 'Patiënt ID',
  email: 'E-mailadres',
  emailAgain: 'E-mailadres controleren',
  bigCode: 'BIG code',
  groups: 'Groep(en)',
  title: 'Titel',
  role: 'Rol',
  noMatchingEntity: 'Geen overeenkomende resultaten gevonden',
  noEntity: 'Geen gegevens beschikbaar',
  actions: 'Acties',
  changeProfilePhoto: 'Profielfoto wijzigen',
  upload: 'Uploaden',
  to: 'tot',
  name: 'Naam',
  defaultForRole: 'Standaard voor rol',
  roles: 'Rol(len)',
  start: 'Begin',
  end: 'Einde',
  yes: 'Ja',
  no: 'Nee',
  successfulLogin: 'Inloggen succesvol',
  accountActivatedMsg: 'U heeft uw account geactiveerd. Open uw Heart for Health app om door te gaan.',
  getMobileAppMsg: 'Heeft u de app nog niet gedownload? Gebruik dan onderstaande links om de app te downloaden uit de App Store (Apple) of Play Store (Android).',
  program: 'Programma',
  chooseRole: 'Choose role',

  perMin: '/min',
  secondsShort: 'sec',
  document: 'Document',
  documentNotAvailable: 'Document niet beschikbaar',
  average: 'Gemiddelde',
  pageNotFound: 'Pagina niet gevonden',
  forbidden: 'Verboden',
  text: 'Tekst',
  subject: 'Onderwerp',
  delete: 'Verwijderen',
  comment: 'opmerking',
  addComment: 'Voeg opmerking toe',
  filter: 'Filter',
  reset: 'Reset',
  pleaseWait: 'Even geduld aub ...',
  update: 'Bijwerken',
  measurement: 'Meting',
  sendActivationEmail: 'Verstuur nieuwe activatiemail',
  activationEmailSent: 'Activatiemail verstuurd',
  activationEmailFailed: 'Activatiemail versturen niet geluk, probeer het opnieuw.',
  defaultTimeZone: 'Standaard tijdzone',
  thisDateIsShownInXTimeZone: 'Deze datum wordt weergegeven in tijdzone: {0}',
  search: 'Zoeken',
  directLoginEnabled: 'Direct inloggen?',
  selectAll: 'Selecteer alles',
  deselectAll: 'Selecteer niets',
  period: 'Periode',
  show: 'Toon',
  itemsSelected: 'Geselecteerde objecten',
  copy: 'Kopiëren',
  copyAll: 'Kopieer alles',
  loading: 'Laden',
  other: 'Anders',
  others: '{0} anderen',
  othersCount: '{0} anderen',
  filters: 'Filters',
  savedFilters: 'Opgeslagen filters',
  filterName: 'Naam filter',
  makeDefaultFilter: 'Maak standaard filter',
  renameFilter: 'Naam filter wijzigen',
  areYouSureYouWantToDeleteThisSavedFilter: 'Weet u zeker dat u dit opgeslagen filter wilt verwijderen?',
  filterSuccessfullySaved: 'Filter succesvol opgeslagen',
  filterRemoved: 'Filter verwijderd',
  defaultFilterRemoved: 'Standaard filter verwijderd',
  filterMarkedAsDefault: 'Filter gemarkeerd als standaard',
  filterWithThisNameAlreadyExists: 'Een filter met deze naam bestaat al',
  clearAll: 'Wis alles',
  clearFilters: 'Filters wissen',
  minCharacters: 'Minimaal {0} karakters',
  type: 'Type',
  optional: 'Optioneel',
  next: 'Vorige',
  previous: 'Volgende',
  select: 'Selecteer',
  overview: 'Overzicht',
  or: 'of',
  dateFormat: 'dd-mm-jjjj',
  timeFormat: 'uu:mm',
  download: 'Download',
  cantFetchEntity: 'Kan {0|lowercase} niet ophalen',
  cantFetchEntityCommon: 'Kan {0} niet ophalen',
};
