export const stopEnrollmentReasonsCodes = [
  'consistent_arrhythmia_and_treated_optimally',
  'arrhythmia_can_not_be_determined',
  'arrhythmia_is_cured',
  'heart_failure_is_optimally_treated',
  'heart_failure_is_cured',
  'hypertension_is_determined_diagnosed',
  'hypertension_is_cured',
  'practitioner_no_added_value',
  'patient_no_added_value',
  'patient_is_unsatisfied_with_ehealth_service',
  'patient_is_unsatisfied_with_ehealth_technology',
  'ehealth_is_too_difficult_for_the_patient',
  'patient_thinks_it_is_too_expensive',
  'patient_does_not_want_to_pay_for_ehealth',
  'patient_never_started_code_is_never_activated',
  'patient_moved_to_a_different_hospital',
  'patient_changed_their_health_insurance',
  'patient_is_inactive',
];
export const otherPredefinedReason = 'other';
