<template>
  <div
    :class="[
      styles.fileInput,
      inputStyles.container
    ]"
  >
    <LabelGroup
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />

    <div
      v-if="$props.canAddFile"
      :class="[styles.fileFieldWrapper, isDisabled && styles.disabled]"
      @click="openBrowse"
    >
      <Dropzone
        displayBlock
        :disabled="isDisabled"
        :class="[
          styles.uploadZone,
          isErroring && styles.invalid
        ]"
        @dropContent="addFiles"
      >
        <div :class="styles.fileField">
          <input
            :id="id"
            ref="input"
            type="file"
            :name="$props.name"
            :class="styles.htmlInput"
            :disabled="isDisabled"
            :tabindex="tabIndex"
            :accept="$props.accept"
            :multiple="$props.multiple"
            :data-testid="`field-${$props.name}`"

            @change="onChange"
            @focus="tabIndexSubscriber__onFocus"
          />
          <Icon
            :class="styles.icon"
            type="plus-circle"
          />
          <span
            :class="styles.placeholder"
            v-html="$localize($props.placeholder || 'common.dropFileOrBrowse')"
          />
        </div>
      </Dropzone>
    </div>

    <InfoBar
      :isErroring="isErroring"
      :error="error"
      :hint="$props.hint"
    />

    <FileUpload
      v-for="file in $props.value"
      :key="getFileItemKey(file)"
      :inlineLoading="$props.inlineLoading"
      :uploadProgress="$props.uploadProgress"
      :additionalFileButtonIcon="$props.additionalFileButtonIcon"
      :file="file"
      :disabled="$props.disabled"
      :allowRevert="!isDisabled"
      :downloadAction="$props.downloadAction"
      @toggleRemove="toggleRemove"
      @fileAction="onFileAction"
    />
  </div>
</template>

<script>
  import { uniqBy } from 'lodash';

  import { Icon } from '@h4h/icons';
  import { popups } from '@h4h/popups';
  import { ArrayProp } from '@h4h/classes';
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';
  import { CustomDocument } from '../../classes';
  import { ALLOWED_FILE_FORMATS } from '../../constants/allowedFileFormats';

  import Dropzone from '../dropzone/Dropzone';
  import LabelGroup from '../labelGroup/LabelGroup';

  import styles from './file.scss';
  import InfoBar from './InfoBar.vue';
  import FileUpload from './FileUpload';
  import { MAX_DOCUMENT_SIZE_MB } from './utils.js';

  export default {
    name: 'H4hFileInput',

    components: {
      Icon,
      LabelGroup,
      InfoBar,
      Dropzone,
      FileUpload,
    },

    mixins: [
      InputMixin
    ],

    props: {
      id: {
        type: String,
        required: true,
      },

      value: new ArrayProp({
        type: CustomDocument
      }),

      accept: {
        type: String,
        required: false,
        default: ALLOWED_FILE_FORMATS
      },

      min: {
        type: Number,
        required: false
      },

      max: {
        type: Number,
        required: false,
        default: 1,
        validator(value) {
          return !value || Number.isInteger(value) && value > 0;
        }
      },

      sizeLimitMb: {
        type: Number,
        required: false,
        default: MAX_DOCUMENT_SIZE_MB
      },

      additionalFileButtonIcon: {
        type: String,
        required: false,
        default: null
      },

      downloadAction: {
        type: Function,
        required: false
      },

      uploadProgress: {
        type: Object,
        default: () => ({
          value: null
        })
      },

      /** labels */
      info: inputProps.label,
      otherLabel: inputProps.label,
      placeholder: inputProps.label,

      /** flags */
      multiple: inputProps.booleanFalse,
      canAddFile: inputProps.booleanTrue,
      isDeletable: inputProps.booleanTrue,
      inlineLoading: inputProps.booleanFalse
    },

    data() {
      return {
        error: null,
        styles,
        inputStyles
      };
    },

    computed: {
      isDisabled() {
        return (this.$props.multiple && !this.leftFiles) || (this.$props.disabled || this.uploading);
      },
      isErroring() {
        return Boolean(!this.$props.pristine && (!this.$props.valid || this.error));
      },
      uploading() {
        return this.$props.uploadProgress.value !== null && this.$props.uploadProgress.value < 100;
      },
      leftFiles() {
        if (this.$props.max) {
          // Existing files that were removed are excluded from the count
          return this.$props.max - (this.$props.value?.filter(i => !i.removed)?.length || 0);
        }
        return Infinity;
      },
      allowedExtensions() {
        return this.accept.replace(/\s/g,'').split(',');
      },
    },

    methods: {
      // can't be a computed property because Vue will memoize the first returned value forever
      getFiles() {
        return [...this.$refs.input.files];
      },

      openBrowse() {
        this.$refs.input.click();
      },

      toggleRemove(file) {
        // If file existed - just update the removed property
        if (file.id) {
          this.$emit('toggleRemove', file.id);
        }
        // If file was newly added - remove it from the input values
        else {
          this.$emit('change', this.$props.value.filter(v => v.id !== file.id || v.name !== file.name));
        }
      },

      onFileAction(e) {
        this.$emit('fileAction', e);
      },

      async shouldReplaceFile() {
        if (!this.$props.multiple && this.$props.value?.length > 0) {

          const { confirmed } = await popups.confirm({
            title: this.$localize('common.fileReplaceWarningTitle'),
            message: this.$localize('common.fileReplaceWarning')
          });
          return confirmed;
        }
        return true;
      },

      async canAddNewFile(files) {
        // file selection dialog was closed by using "Cancel" - don't change the selected file
        if (!files.length) {
          return false;
        }
        else if (this.uploading) {
          return false;
        }
        // Don't allow to exceed maximum limit (when selecting multiple files at once)
        else if (this.$props.multiple && (this.leftFiles - files.length) < 0) {
          this.$emit('toast', [
            {
              message: 'maximumFilesExceeded',
              type: 'warning',
              messageParams: this.$props.max,
            }
          ]);

          // This is necessary since the onChange does not trigger the next time
          this.$refs['input'].value = null;

          return false;
        }
        else if (!await this.shouldReplaceFile(this)) {
          return false;
        }
        else {
          return true;
        }
      },

      async addFiles(dataTransfer) {
        const files = dataTransfer?.files;
        if (!await this.canAddNewFile(files)) {
          return;
        }
        const input = this.$refs.input.files;

        const oldFiles = input.files ? Array.from(input.files) : [];
        const allFiles = [...oldFiles, ...files];
        this.$refs.input.files = this.createFileList(allFiles);
        this.changeValue(false);
      },

      onChange() {
        this.changeValue(true);
      },

      async changeValue(performCheck = true) {
        const files = this.getFiles();
        if (performCheck && !await this.canAddNewFile(files)) {
          return;
        }

        // reset input value so it doesn't eat up the change when you select the same file as before
        this.$refs.input.value = '';
        const validFiles = this.getValidFiles(files.map(CustomDocument.fromFile));

        // just format files if no previous ones
        if (!this.$props.value) {
          return this.$emit('change', validFiles);
        }

        // format and find unique by name and id (only existing items have ids)
        let allFiles;

        if (this.$props.multiple) {
          allFiles = [...validFiles, ...this.$props.value];
          this.$emit('change', uniqBy(allFiles, i => this.getFileItemKey(i)));
        }
        else {
          allFiles = validFiles.length === 1 && validFiles[0] ? validFiles : null;
          this.$emit('change', allFiles);
        }
      },

      createFileList(files) {
        const dt = new DataTransfer();
        files.forEach(file => dt.items.add(file));
        return dt.files;
      },

      getValidFiles(files) {
        return Array.from(files)
          .filter(this.validateFile);
      },

      setValid() {
        this.error = null;
        return true;
      },

      setError(msg) {
        this.error = msg;
        return false;
      },

      validateExtension({ name }) {
        const fileExtension = name.split('.').pop();

        if (this.allowedExtensions.includes(`.${ fileExtension.toLowerCase() }`)) {
          return this.setValid();
        }
        return this.setError('upload.fileExtensionNotValid');
      },

      validateSize({ size, name }) {
        if (size <= (this.$props.sizeLimitMb * 1024 * 1024)) {
          return this.setValid();
        }
        return this.setError(['common.fileSizeEntityNameWarning', name, this.$props.sizeLimitMb]);
      },

      validateFile(file) {
        return this.validateExtension(file) && this.validateSize(file);
      },

      getFileItemKey(item) {
        return `${ item.id }${ item.name }`;
      }
    }
  };
</script>
