<template>
  <div>
    <div
      v-for="subLabel in subLabels"
      :key="subLabel"
      :class="styles.selectSubLabel"
    >
      {{ $localize(subLabel) }}
    </div>
  </div>
</template>

<script>

  import styles from '../select.scss';

  export default {
    name: 'SubLabels',
    props: {
      subLabels: Array
    },
    data() {
      return {
        styles
      };
    }
  };
</script>
