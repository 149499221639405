<template>
  <div
    v-if="values.length"
    :class="[
      // For single phone input margin under component wrapper is not needed
      // since there is margin already set under each input's row
      $props.multiple && inputStyles.container,
      $props.inline && inputStyles.inline
    ]"
  >
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />

    <div
      v-for="(item, i) in values"
      :key="i"
      :class="styles.phones"
    >
      <Phone
        v-bind="$props"
        :key="'phone' + i"
        :value="item"
        :item="item"
        :index="i"
        @change="onPhoneChange"
      />
      <div
        v-if="multiple && allowActions"
        :class="styles.addNewPhone"
      >
        <Icon
          v-if="(i === 0) && canAddPhone"
          type="plus-circle"
          @click="addPhone"
        />
        <Icon
          v-else-if="i !== 0"
          type="trash"
          @click="deletePhone(i)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { PhoneModel } from '../../models';
  import { InputMixin } from '../../mixins';
  import { getHomeOption } from '../../constants';
  import { inputProps, phoneProps } from '../../utils';

  import LabelGroup from '../labelGroup/LabelGroup';

  import Phone from './Phone';
  import styles from './phone.scss';

  export default {
    name: 'H4hPhones',

    components: {
      Phone,
      LabelGroup
    },

    mixins: [
      InputMixin
    ],

    props: {
      ...phoneProps,

      value: {
        type: Array,
        required: true
      },

      phonesLimit: {
        type: Number,
        default: 1
      },

      /** labels */
      otherLabel: inputProps.label,
      info: inputProps.label,

      /** flags */
      setDefaultPrimary: inputProps.booleanTrue,
      someRequired: inputProps.booleanFalse,
      allowActions: inputProps.booleanFalse,

      // @todo: Baizulin - find out why those two are inverted in Phone.vue
      required: inputProps.booleanFalse,
      showPhoneType: inputProps.booleanTrue,

      /** styles */
      labelStyle: inputProps.style
    },

    data() {
      return {
        inputStyles,
        styles,
        phones: [],
      };
    },

    computed: {
      values() {
        return this.$props.value;
      },
      isRequired() {
        // needs only for processing of inside inputs color by each field, not affecting common field validation
        if (this.someRequired && this.required) {
          return this.value.every(phone => !phone.number?.length);
        }
        return this.required;
      },

      canAddPhone() {
        return this.phones.length < this.phonesLimit;
      }
    },

    beforeMount() {
      this.phones = this.multiple ? Array.from(this.$props.value) : [this.$props.value];
    },

    methods: {
      onChange() {
        this.$emit(
          'change',
          this.$props.multiple
            ? [...new Set(this.phones)]
            : [this.phones[0]]
        );
      },

      onPhoneChange({ phone, index }) {
        this.phones[index] = phone;
        if (phone.number && phone.primary) {
          this.resetPrimary(phone);
        }
        // Try to set another as default if there is more than one value
        if (this.phones.length > 1 && !phone.number || !phone.primary) {
          this.setAsDefaultAnother();
        }
        this.onChange();
      },

      setAsDefaultAnother() {
        const primaryPhone = this.phones.find(phone => phone.primary && phone.number);
        if (primaryPhone) {
          return this.resetPrimary(primaryPhone);
        }

        let primarySet = false;
        this.phones.forEach(item => {
          if (!primarySet && item.number && this.setDefaultPrimary) {
            primarySet = true;
            item.primary = true;
            return;
          }

          item.primary = false;
        });
      },

      resetPrimary(current) {
        this.phones.forEach(item => {
          if (item !== current) {
            item.primary = false;
          }
        });
      },

      addPhone() {
        if (this.multiple) {
          const { phoneEnum } = this.$props;
          const homeOption = getHomeOption(phoneEnum);
          const hasHomeNumber = this.phones.filter(phone => phone.type === homeOption).length > 0;
          this.phones.push(
            new PhoneModel({
              type: !hasHomeNumber ? homeOption : phoneEnum.Mobile,
              countryCode: this.defaultCountryCode,
              primary: false,
              number: ''
            })
          );
          this.onChange();
        }
      },

      deletePhone(index) {
        const checkPrimary = this.phones[index].primary;
        this.phones.splice(index, 1);
        if (checkPrimary) {
          this.setAsDefaultAnother();
        }
        this.onChange();
        return this.phones.length;
      }
    }
  };
</script>
