import { types } from './types';
import { createMainFilterInput } from '../../services/filter/utils/filterUtils';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PROGRAMS](state, programs) {
    state.programs = programs;
  },

  [types.SET_FETCHED](state, value) {
    state.fetched = value;
  },
  [types.INIT_PAGE](state) {
    state.filterInput = createMainFilterInput();
  },

  [types.UPDATE_FILTER](state) {
    state.filteredRows = (state.programs || []).filter(program => {
      const filter = state.filterInput?.value.trim();
      if (filter && state.filterInput.valid) {
        return program.name.toLowerCase().includes(filter.toLowerCase());
      }
      return true;
    });
  },

};

