export class HealthRecordConclusionModel {
  /**
   * HealthRecordConclusionModel config
   *
   * @param {Object} config
   * @param {String} config.uuid
   * @param {String} config.name
   * @param {Number} config.orderOfFollowing
   * @param {String[]} config.associatedProgramInputTypes
   */
  constructor(config) {
    this.uuid = config.uuid;
    this.name = config.name;
    this.associatedProgramInputTypes = config.associatedProgramInputTypes;
    this.orderOfFollowing = config.orderOfFollowing;
  }
}