import { types } from './types';
import { programs } from '../../../services/api/programs/programs';
import { showRestError } from '../../../utils/errors';

export const actions = Object.freeze({
  initForm,
});

async function initForm({ commit }, { uuid }) {
  commit(types.SET_LOADING, true);
  commit(types.INIT_FORM, { program: null });

  let result = await programs.get(uuid);
  if (result.success) {
    commit(types.INIT_FORM, { program: result.data });
  }
  else {
    showRestError(result.error, 'messages.cantFetchProgram');
  }
  commit(types.SET_LOADING, false);
}
