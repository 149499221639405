/**
 * Memoizes async function by caching the original promise and returning it
 * until it is resolved, then either busts the cache in case of an error or
 * keeps returning an already resolved promise
 *
 * @template {any} T
 * @param {() => Promise<T>} asyncFunction
 *
 * @returns {() => Promise<T>}
 */
export function memoizeAsync(asyncFunction) {
  let promise = null;

  return function memoizedAsyncFunction() {
    if (!promise) {
      promise = new Promise((resolve, reject) =>
        asyncFunction()
          .then(resolve)
          .catch(e => {
            promise = null;
            reject(e);
          })
      );
    }

    return promise;
  };
}
