export class AllergyModel {
  /**
   * AllergyModel config
   *
   * @param {Object}    config
   * @param {String}    config.uuid
   * @param {String}    config.patientUserUuid
   * @param {String}    config.value
   * @param {Boolean}   config.immutable
   */
  constructor(config) {
    config = config || {};

    this.uuid = config.uuid;
    this.patientUserUuid = config.patientUserUuid;
    this.value = config.value;
    this.immutable = config.immutable;
  }
}
