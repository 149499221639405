import { Page } from '../../constants/page';

export class SpecialPathModel {
  /**
   * SpecialPathModel config
   *
   * @param {Object}                    config
   * @param {String}                    config.path
   * @param {String}                    config.page
   * @param {Boolean}                   config.rootPage
   */

  constructor(config) {
    this.path = config.path;
    this.page = config.page;
    this.rootPage = config.rootPage;
  }

  getPath() {
    return (this.rootPage ? '/' : '') + this.path;
  }
}

export const SpecialPath = {
  Ingress: new SpecialPathModel({
    path: 'ingress',
    page: Page.Ingress,
  }),
  Page404: new SpecialPathModel({
    path: '404',
    page: Page.NotFound,
    rootPage: true,
  }),
  Page403: new SpecialPathModel({
    path: '403',
    page: Page.Forbidden,
    rootPage: true,
  }),
};
