import moment from 'moment';

export const ChosenPeriodGranularity = Object.freeze({
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  HALF_YEAR: 'half-year',
});

/**
 *
 * @param {Object}    input
 * @param {String[]}  allowedGranularityList
 * @returns {string|null}
 */
export function evalGranularityPeriod(input, allowedGranularityList) {
  function matchPeriod(granularity) {
    return new ChosenPeriodHelper({
      granularity,
      input,
      count: 1
    }).matches;
  }

  for (let granularity of allowedGranularityList) {
    if (matchPeriod(granularity)) {
      return granularity;
    }
  }
  return null;
}

export class ChosenPeriodHelper {
  /**
   *
   * @param {Object}                  config
   * @param {Object}                  config.input
   * @param {ChosenPeriodGranularity} config.granularity
   * @param {number}                  config.count
   * @param {Function}                config.currentTimeProvider
   *
   */
  constructor(config) {
    this.input = config.input;
    this.count = config.count;
    this.granularity = config.granularity;
    this.currentTimeProvider = config.currentTimeProvider;
  }

  get matches() {
    const value = this.toPeriod(this.input.value);
    if (!value) {
      return false;
    }
    if (!moment(value[1]).isSame(this.now(), ChosenPeriodGranularity.DAY)) {
      return false;
    }
    const start = this.getExpectedStartOfPeriod();
    return moment(start).isSame(value[0], ChosenPeriodGranularity.DAY);
  }

  getExpectedStartOfPeriod() {
    switch (this.granularity) {
      case ChosenPeriodGranularity.DAY :
        return this.now().subtract(this.count, ChosenPeriodGranularity.DAY);
      case ChosenPeriodGranularity.WEEK :
        return this.now().subtract(this.count, ChosenPeriodGranularity.WEEK);
      case ChosenPeriodGranularity.MONTH :
        return this.now().subtract(this.count, ChosenPeriodGranularity.MONTH);
      case ChosenPeriodGranularity.QUARTER :
        return this.now().subtract(this.count * 3, ChosenPeriodGranularity.MONTH);
      case ChosenPeriodGranularity.HALF_YEAR :
        return this.now().subtract(this.count * 6, ChosenPeriodGranularity.MONTH);
    }
    return null;
  }

  toPeriod(value) {
    if (value) {
      if (!Array.isArray(value)) {
        value = [value, value];
      }
      return value;
    }
    return null;
  }

  now() {
    if (this.currentTimeProvider) {
      return this.currentTimeProvider();
    }
    return moment();
  }
}
