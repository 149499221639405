export const types = Object.freeze({
  SET_LOADING: 'SET_LOADING',
  SET_PATIENT: 'SET_PATIENT',
  SET_ENROLLMENTS: 'SET_ENROLLMENTS',
  SET_PROGRAMS: 'SET_PROGRAMS',
  INIT_POPUP: 'INIT_POPUP',
  SET_PROGRAM: 'SET_PROGRAM',
  SET_MONITORING_PERIOD: 'SET_MONITORING_PERIOD',
  SET_ENROLLMENT_OBS_TEMPLATES_MAP: 'SET_ENROLLMENT_OBS_TEMPLATES_MAP',
  SET_OBSERVATION_TEMPLATES: 'SET_OBSERVATION_TEMPLATES',
  SET_ENROLLMENT_QUESTIONNAIRE_TEMPLATES_MAP: 'SET_ENROLLMENT_QUESTIONNAIRE_TEMPLATES_MAP',
  SET_QUESTIONNAIRE_TEMPLATES: 'SET_QUESTIONNAIRE_TEMPLATES',
  SET_NAV_LINKS: 'SET_NAV_LINKS',
  SET_ENROLLMENTS_TO_STOP: 'SET_ENROLLMENTS_TO_STOP',
  SET_TABLE_OPTIONS: 'SET_TABLE_OPTIONS',
  ACTIVE_TABLE_NAME: 'active',
  HISTORY_TABLE_NAME: 'history',
  RESET_THRESHOLDS: 'RESET_THRESHOLDS',
  STANDARD_SCHEDULE: 'standard',
  ADJUSTED_SCHEDULE: 'adjusted',
  SET_ITEM_DELETE_STATE: 'SET_ITEM_DELETE_STATE',
  SET_PRISTINE: 'SET_PRISTINE',
  SET_CRON_MODEL: 'SET_CRON_MODEL',
  INPUT_STATE_CHANGED: 'INPUT_STATE_CHANGED',
});
