import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_POPUP_ID](state, popupId) {
    state.popupId = popupId;
  },

  [types.SET_PRISTINE](state, value) {
    state.input.pristine = value;
  },

  [types.INIT_FORM](state, questionnaireTemplates) {
    if (!questionnaireTemplates) {
      state.input = null;
      return;
    }
    state.input = createInputConfig({
      id: 'template',
      type: InputType.Typeahead2,
      required: true,
      clearable: false,
      label: 'questionnaireTemplates.questionnaireTemplate',
      options: questionnaireTemplates.map(template => ({
        label: template.title,
        value: template.uuid,
      })),
    });
  },

};
