import { localize } from '../../services/localize';

export function formatObservation(observation) {
  if (observation.terminologyType === 'h4h.bp.type') {
    return observation.averageValues['h4h.bp.systolic'] + '/' + observation.averageValues['h4h.bp.diastolic'];
  }
}

export function formatObservationName(observation) {
  if (observation.terminologyType === 'h4h.bp.type') {
    return localize('patients.bloodPressure');
  }
}
