import { popup } from './popup';
import { thresholds } from './thresholds';
import { single } from './single';
import { withInputs } from '@h4h/inputs';
import { pdf } from './pdf';
import { list } from './list';

export const observation = withInputs({
  namespaced: true,

  modules: {
    popup,
    thresholds,
    single,
    pdf,
    list,
  }
});
