export const types = Object.freeze({
  INIT_FORM: 'INIT_FORM',
  SET_LOADING: 'SET_LOADING',
  SET_PRISTINE: 'SET_PRISTINE',
  UPSERT_THRESHOLD: 'UPSERT_THRESHOLD',
  SET_INPUT_VALUE: 'SET_INPUT_VALUE',

  ADD_PERIOD: 'ADD_PERIOD',
  REMOVE_PERIOD: 'REMOVE_PERIOD',
  ADD_OBSERVATION: 'ADD_OBSERVATION',
  REMOVE_OBSERVATION: 'REMOVE_OBSERVATION',
  ADD_QUESTIONNAIRE: 'ADD_QUESTIONNAIRE',
  REMOVE_QUESTIONNAIRE: 'REMOVE_QUESTIONNAIRE',
  SET_PRIMARY_PERIOD: 'SET_PRIMARY_PERIOD',
  ADD_SUPPORTED_VENDOR: 'ADD_SUPPORTED_VENDOR',
  REMOVE_SUPPORTED_VENDOR: 'REMOVE_SUPPORTED_VENDOR',

  ADD_NEW_PROGRAM_INPUT_GROUP: 'ADD_NEW_PROGRAM_INPUT_GROUP',
  LINK_INPUT_GROUP: 'LINK_TO_INPUT_GROUP',
  UNLINK_INPUT_GROUP: 'UNLINK_INPUT_GROUP',
});
