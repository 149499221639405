<template>
  <div
    :class="[
      inputStyles.container,
      $props.inline && inputStyles.inline
    ]"
  >
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />
    <label
      :for="id"
      :class="[
        $props.inputStyle,
        inputStyles.inputGroup,
        inputStyles.textInput,
        {
          [inputStyles.passwordGroup]: password,
          focus: focused,
          disabled: $props.disabled,
          invalid: (!$props.pristine && !$props.valid) || errorMessage !== null,
          success: $props.success,
          warning: $props.warning
        }
      ]"
    >
      <Icon
        v-if="$props.icon"
        :type="$props.icon"
        :class="inputStyles.icon"
      />
      <slot name="pills"/>

      <input
        :id="id"
        ref="input"
        autocomplete="off"
        v-bind="limitProps"
        :type="inputType"
        :name="$props.name"
        :value="$props.value"
        :class="[inputStyles.input, isPasswordType && inputStyles.password]"
        :disabled="$props.disabled"
        :tabindex="tabIndex"
        :readonly="$props.readonly"
        :placeholder="!password && $localize($props.placeholder)"
        :data-testid="`field-${$props.name}`"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
        @change="onChange"
        @keydown="$emit('keydown', $event)"
      />
      <span
        v-if="$props.unit"
        :class="inputStyles.unit"
      >
        {{ $props.unit }}
      </span>
      <Button
        v-if="showClear"
        text
        round
        small
        :tabindex="-1"
        icon="x-circle-invert"
        @click.stop.prevent="clear"
        @keydown.enter="clear"
      />
      <Button
        v-if="$props.password"
        text
        round
        small
        :tabindex="-1"
        :icon="eyeIcon"
        @click.stop.prevent="toggleShowPassword"
        @keydown.enter="toggleShowPassword"
      />
    </label>
    <div
      v-if="errorMessage !== null"
      :class="inputStyles.errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  import { uniqueId } from 'lodash';

  import { Icon } from '@h4h/icons';
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { inputProps } from '../utils';
  import { InputMixin } from '../mixins';
  import { InputType } from '../constants';

  import LabelGroup from './labelGroup/LabelGroup';
  import { validationHelper } from '@h4h/utils';

  export default {
    name: 'H4hTextInput',

    components: {
      Icon,
      LabelGroup
    },

    mixins: [
      InputMixin,
      validationHelper
    ],

    props: {
      type: inputProps.type,
      value: [String, Number],

      unit: String,
      icon: String,

      max: Number,
      min: Number,

      /** labels */
      info: inputProps.label,
      otherLabel: inputProps.label,
      placeholder: inputProps.label,

      /** flags */
      password: inputProps.booleanFalse,
      inline: inputProps.booleanFalse,
      success: inputProps.booleanFalse,
      warning: inputProps.booleanFalse,
      readonly: inputProps.booleanFalse,
      clearable: inputProps.booleanFalse,

      /** styles */
      labelStyle: inputProps.style,
      inputStyle: inputProps.style
    },

    data() {
      return {
        inputStyles,
        focused: false,
        passVisible: false,
        id: uniqueId('text-input-')
      };
    },
    computed: {
      inputType() {
        return this.isPasswordType ? 'password' : 'text';
      },
      eyeIcon() {
        return this.passVisible ? 'eye-off' : 'eye';
      },
      isPasswordType() {
        return this.$props.password && !this.passVisible && this.value;
      },
      showClear() {
        return (this.$props.clearable || this.$props.password) && this.value;
      },
      limitProps() {
        const { type, min, max } = this.$props;

        if (type === InputType.Float || type === InputType.Integer) {
          return {
            min,
            max
          };
        }

        return {
          minLength: min,
          maxLength: max
        };
      }
    },
    methods: {
      onInput(e) {
        // also emit change because for text inputs it the same
        this.$emit('input', e.target.value);
        this.$emit('change', e.target.value);
      },

      onChange(e) {
        this.$emit('change', e.target.value);
      },

      onFocus(e) {
        this.focused = true;
        this.$emit('focus', e);
        this.tabIndexSubscriber__onFocus();
      },

      onBlur(e) {
        this.focused = false;
        this.$emit('blur', e);
        this.validateRules(e.target.value);
      },

      focus() {
        this.$refs.input.focus();
      },

      blur() {
        this.$refs.input.blur();
      },

      toggleShowPassword() {
        this.passVisible = !this.passVisible;
      },

      clear() {
        this.$emit('change', '');
      },
    }
  };
</script>
