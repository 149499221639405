<template>
  <transition
    appear
    duration="200"
  >
    <div
      :style="customContainerStyle"
      :class="[
        styles.container,
        {
          [styles.modal]: !isSidePanel(),
          [styles.sidePanel]: isSidePanel()
        }
      ]"
      @mouseup="onMouseUp"
      @mousedown="onMouseDown"
    >
      <!-- TODO:DN Remove $props.name after data-testId is added for each pop-up  -->
      <div
        ref="content"
        :class="[styles.popup, contentClass]"
        :data-testid="`popup-${$props.dataTestId || $props.name}`"
      >
        <component
          :is="component"

          v-if="component"
          v-bind="$props.props"
        />
      </div>
    </div>
  </transition>
</template>

<script>
  import { get } from 'lodash';

  import { PopupType } from '../constants';

  import styles from '../styles/popup.scss';

  export default {
    name: 'H4hPopup',

    provide() {
      return {
        'popupClose': this.close,
      };
    },

    props: {
      id: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      dataTestId: {
        type: String
      },
      props: {
        type: Object
      },
      type: {
        type: String,
        required: true,
        validator(type) {
          return PopupType[type];
        }
      },
      closeOnClickOutside: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        styles,
        mouseDownEvent: null
      };
    },

    computed: {
      component() {
        return this.$popups.getComponent(this.$props.name);
      },

      contentClass() {
        const fullHeight = get(this.$props, 'props.fullHeight') || false;

        return {
          [styles.fullHeight]: fullHeight
        };
      },

      customContainerStyle() {
        const style = {};
        const customTop = this.$props.props?.customTop;
        if (customTop) {
          style.paddingTop = customTop;
        }
        return style;
      }
    },

    methods: {
      isSidePanel() {
        return this.$props.type === PopupType.SidePanel;
      },

      close(result) {
        this.$popups.hide({
          result,
          id: this.$props.id
        });
      },

      onMouseDown(e) {
        this.mouseDownEvent = e;
      },

      onMouseUp(e) {
        const { closeOnClickOutside, mouseDownEvent, $refs } = this;
        if (closeOnClickOutside && mouseDownEvent?.target === e.target && !$refs.content.contains(e.target)) {
          this.close();
        }
        this.mouseDownEvent = null;
      }
    }
  };
</script>
