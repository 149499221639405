import { types } from './types';
import { loadAxiosErrors, removeAxiosErrorByIndex, setCaptureOnlyErrors } from '../../services/axios/axiosError';
import { setInputValueAction } from '@h4h/inputs';

export const actions = Object.freeze({
  init,
  deleteItem,
  setInputValue,
});

async function init({ commit }) {
  commit(types.INIT);
}

async function setInputValue({ commit, dispatch }, { input, value }) {
  await setInputValueAction({ commit, dispatch }, { input, value });
  setCaptureOnlyErrors(value);
}

async function deleteItem({ commit, state }, item) {
  let idx = state.errors.indexOf(item);
  if (idx > -1) {
    removeAxiosErrorByIndex(idx);
    commit(types.SET_ERRORS, loadAxiosErrors());
  }
}
