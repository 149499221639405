import { types } from './types';
import { ccUserPreferences, userPreferences } from '../../../services/api/preferences/preferences';
import { showRestError } from '../../../utils/errors';
import { UserPreferences } from '../../../model/userPreferences';
import { TableFiltersProfiles } from '@h4h/multiple-table-filters';
import { forceAuth, isCallCenter } from '../../../services/auth/auth';
import { EhRole } from '../../../services/auth/ehRole';

export const getPreferencesAction = 'getPreferences';
export const setPreferencesAction = 'setPreferences';
export const getTableFiltersAction = 'getTableFilters';
export const actions = Object.freeze({
  [getPreferencesAction]: getPreferences,
  [setPreferencesAction]: setPreferences,
  [getTableFiltersAction]: getTableFilters,
  saveTableProfiles,
  getTableProfiles,
});

function getPreferencesProvider() {
  return isCallCenter() ? ccUserPreferences : userPreferences;
}
let loadingPromise = null;

async function getPreferences({ commit, state }) {
  const auth = forceAuth();
  if (auth.hasRole(EhRole.SsoUser)) {
    // return empty options for sso-user, since sso user isn't stored in database
    return new UserPreferences({});
  }
  if (state.preferences) {
    return state.preferences;
  }
  if (loadingPromise) {
    await loadingPromise;
  }
  else {
    let resolveFunction = null;
    loadingPromise = new Promise(resolve => {
      resolveFunction = resolve;
    });
    commit(types.SET_LOADING, true);
    const result = await getPreferencesProvider().fetchOne();
    commit(types.SET_LOADING, false);

    if (result.success) {
      commit(types.SET_PREFERENCES, result.data);
    }
    else {
      commit(types.SET_PREFERENCES, new UserPreferences({}));
      showRestError(result.error, 'messages.cantFetchUserPreferences');
    }
    resolveFunction();
  }
  return state.preferences;
}

async function setPreferences({ commit, state }, preferences) {
  const auth = forceAuth();
  if (auth.hasRole(EhRole.SsoUser)) {
    // don't store settings for sso-user, since sso user isn't stored in database
    return new UserPreferences({});
  }
  commit(types.SET_LOADING, true);
  const result = await getPreferencesProvider().patchOne(preferences);
  commit(types.SET_LOADING, false);
  if (result.success) {
    commit(types.SET_PREFERENCES, result.data);
  }
  else {
    commit(types.SET_PREFERENCES, new UserPreferences({}));
    showRestError(result.error, 'messages.cantUpdateUserPreferences');
  }
  return state.preferences;
}

async function getTableFilters({ dispatch }, tableId) {
  const preferences = await dispatch(getPreferencesAction);
  return new TableFiltersProfiles(preferences.tableFilterProfiles[tableId] || {}).getDefaultProfile();
}

async function getTableProfiles({ dispatch }, tableId) {
  const preferences = await dispatch(getPreferencesAction);
  return new TableFiltersProfiles(preferences.tableFilterProfiles[tableId] || {});
}

async function saveTableProfiles({ dispatch }, { tableId, profiles }) {
  let preferences = new UserPreferences(await dispatch(getPreferencesAction));
  preferences.setTableFilterProfiles(tableId, profiles);
  await dispatch(setPreferencesAction, preferences);
}
