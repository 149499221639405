<template>
  <div
    :class="[
      styles.slotContent,
      hidden && styles.hidden,
      styles.centeredItems,
      'inlineInputs',
      'e2eSelectOption',
      itemProps.item.subItem && styles.subItem
    ]"
    :data-testid="`dropdown-${$props.name}-value-${transformDataTestId}`"
  >
    <Checkbox
      v-if="multiple"
      :name="transformDataTestId"
      :label="itemProps.item.label"
      :value="itemProps.attrs.inputValue"
      :colorClass="itemProps.item.colorClass"
      :disabled="$props.disabled || itemProps.item.disabled"
    >
      <template #label>
        <slot
          name="itemLabel"
          v-bind="itemProps"
        >
          <div :class="styles.selectLabelGroup">
            <div :class="styles.selectLabel">
              {{ $localize(itemProps.item.label) }}
            </div>
            <SubLabels :subLabels="subLabels"/>
          </div>
        </slot>
      </template>
    </Checkbox>
    <div v-else>
      <slot
        name="itemLabel"
        v-bind="itemProps"
      >
        <div :class="styles.selectLabel">
          {{ $localize(itemProps.item.label) }}
        </div>
        <SubLabels :subLabels="subLabels"/>
      </slot>
    </div>
  </div>
</template>

<script>
  import Checkbox from '../../checkbox/Checkbox';
  import styles from '../select.scss';
  import SubLabels from './SubLabels';

  export default {
    name: 'H4hCommonSelectListItem',

    components: {
      Checkbox,
      SubLabels,
    },
    props: {
      multiple: Boolean,
      itemProps: {
        type: Object,
        required: true,
      },
      disabled: Boolean,
      hidden: Boolean,
      name: String
    },
    data() {
      return {
        styles,
      };
    },

    computed: {
      subLabels() {
        const subLabels = this.itemProps.item.subLabels;
        return subLabels ? subLabels : [];
      },
      transformDataTestId() {
        // Generation of data test id value is done by:
        // 1. Custom dataTestIds - used when we have select options where the value is not a primitive value and the label is NOT a formatted name of an entity
        // 2. Label - used when we have a list of entities listed and the label is the actual name of the entity (Eg. Name of location) - we want to avoid using the value since it can be an object or an id of the entity
        // 3. Value - used when we have options with primitive values - we want to avoid using translated labels
        const formattedValue = (this.itemProps.item.dataTestId || (!Array.isArray(this.itemProps.item.label) && this.itemProps.item.label) || this.itemProps.item.value) + '';
        return formattedValue.toLowerCase().replace(/(\s|\.)/g, '-');
      }
    }
  };
</script>
