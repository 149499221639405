export const PhoneTypeOrganization = Object.freeze({
  Mobile: 'Mobile',
  Landline: 'Landline',
  Fax: 'Fax'
});

export const phoneTypeOrganizationOptions = Object.freeze([
  { value: PhoneTypeOrganization.Mobile, label: 'common.mobile' },
  { value: PhoneTypeOrganization.Landline, label: 'common.landline' }
]);

export function getHomeOption(phoneEnum) {
  return phoneEnum.Home ? phoneEnum.Home : phoneEnum.Landline;
}
