import { parseDate } from './parsers';
import { formatDate } from './formatters';
import { ISO_DATE_FORMAT } from './constants';

export class IsoDate {
  static fromApi(string) {
    if (!string) {
      return null;
    }

    return parseDate(string, ISO_DATE_FORMAT);
  }

  static toApi(date) {
    if (!date) {
      // do not return null when date is undefined to prevent messing up
      // with fields that should not be updated during PUT call
      return date;
    }

    return formatDate(date, ISO_DATE_FORMAT);
  }
}
