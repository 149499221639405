import { types } from './types';
import { mapValues } from 'lodash';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { MonitoringPeriodObservationModel } from '../../../model/monitoringPeriodModel';
import { createCronModelAction } from '../../../components/cronEditor/store/actions';

export const actions = Object.freeze({
  initPopup,
  save,
  cancel,
  setCronModel: createCronModelAction(types.SET_CRON_MODEL),
});

async function initPopup({ commit }, { period, observationTemplates }) {
  commit(types.INIT_POPUP, { observationTemplates, period });
  const { id, closed } = await popups.show({
    name: PopupName.EditProgramObservation,
    type: PopupType.Modal
  });
  commit(types.SET_POPUP_ID, id);
  return { closed };
}

async function save({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const observation = new MonitoringPeriodObservationModel({
    ...mapValues(state.inputsMap, i => i.value),
  });
  if (!state.cronEnabled.value) {
    observation.cron = null;
  }
  popups.hide({ id: state.popupId, result: observation });
}

async function cancel({ state }) {
  popups.hide({ id: state.popupId });
}
