<template>
  <div
    :class="[
      inputStyles.container,
      styles.container,
      $props.inline && inputStyles.inline
    ]"
  >
    <div
      :class="[
        styles.from,
        $props.inline && inputStyles.inline
      ]"
    >
      <DateInput
        v-bind="inputs.from"
        :value="values.from"
        :groupId="$props.groupId"
        :class="styles.dateInput"
        :min="$props.min"
        :max="$props.max"
        :data-testid="`field-${$props.name}-from`"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange({ input: inputs.from, value: $event })"
      />
    </div>
    <div :class="[$props.inline && inputStyles.inline]">
      <DateInput
        v-bind="inputs.to"
        :value="values.to"
        :groupId="$props.groupId"
        :class="styles.dateInput"
        :min="$props.min"
        :max="$props.max"
        :data-testid="`field-${$props.name}-to`"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange({ input: inputs.to, value: $event })"
      />
    </div>
  </div>
</template>

<script>
  import { isArray } from 'lodash';

  import { ArrayProp, EnumProp } from '@h4h/classes';
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { InputMixin } from '../../mixins';
  import { InputType } from '../../constants';
  import { createInputConfig, inputProps } from '../../utils';

  import DateInput from '../dateInput/DateInput';

  import styles from './dateRange.scss';

  export default {
    name: 'H4hDateRange',

    components: {
      DateInput
    },

    mixins: [
      InputMixin
    ],

    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      value: new ArrayProp({
        type: Date
      }),

      pickerType: new EnumProp({
        type: InputType,
        required: true
      }),

      min: Date,
      max: Date,

      /** labels */
      placeholder: inputProps.label,

      /** flags */
      inline: inputProps.booleanFalse,

      /** styles */
      labelStyle: inputProps.style
    },

    data() {
      return {
        inputStyles,
        styles,
        inputs: {
          from: this.initField(0),
          to: this.initField(1)
        }
      };
    },

    computed: {
      values() {
        const [from, to] = this.$props.value || [];
        return {
          from,
          to
        };
      }
    },

    methods: {
      normalizeValue(value, valueIndex) {
        if (isArray(value)) {
          return value[valueIndex] || value[0];
        }

        return value;
      },

      initField(valueIndex = 0) {
        const { name, pickerType, placeholder, label, tabindex, ...common } = this.$props;

        return createInputConfig({
          name: this.normalizeValue(name, valueIndex),
          type: pickerType,
          placeholder: this.normalizeValue(placeholder, valueIndex),
          label: this.normalizeValue(label, valueIndex),
          tabindex: tabindex + valueIndex,
          valueIndex,
          ...common
        });
      },

      onChange({ input, value }) {
        const values = [this.values.from, this.values.to];
        if (values[input.valueIndex].getTime() === value.getTime()) {
          return;
        }
        values[input.valueIndex] = value;
        this.$emit('change', values);
      },

      isFrom(input) {
        return input.valueIndex === 0;
      },

      isTo(input) {
        return input.valueIndex === 1;
      },

      onFocus(e) {
        this.$emit('focus', e);
      },

      onBlur(e) {
        this.$emit('blur', e);
      },
    }
  };
</script>
