export const getters = {
  inputs,
  ecgInputs,
  valid,
};

function valid(state) {
  return inputs(state).every(i => i.valid);
}

function inputs(state) {
  return [
    state.conclusion,
    state.commentInput,
    state.advice,
    state.contactedVia,
  ].filter(x => !!x);
}
function ecgInputs(state) {
  return [
    state.conclusion,
    state.advice,
    state.contactedVia,
  ];
}
