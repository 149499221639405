import { parseCronExpression } from './helpers';
import { CronInputModel, CronObjectModel } from './model';

export function createCronInput(cron) {
  const { type, valid } = parseCronExpression(cron);
  return new CronInputModel({ cron, valid, type: type.value });
}

export function getDefaultCron() {
  let model = new CronObjectModel({
    minute: new Date().getMinutes(),
    hour: new Date().getUTCHours(),
    day: '*',
    dayOfWeek: '*',
    month: '*',
  });
  return model.toCronString();
}
