/**
 * @property {String} tenantCountry
 * @property {String} tenantName
 */
export class TenantSettings {

  /**
   * @param {String} tenantCountry
   * @param {String} tenantName
   */
  constructor({ tenantCountry, tenantName }) {
    this.tenantCountry = tenantCountry;
    this.tenantName = tenantName;
  }
}
