import { Page } from '../../../../constants/page';
import { EhRole } from '../../../auth/ehRole';
import { anamnesis } from './anamnesis';
import { observationTemplates } from './observationTemplates';
// import { labResultGroups } from './labResultGroups';

export const AdminRoutes = {
  path: 'admin',
  name: Page.Admin,
  redirect: { name: Page.AdminHome },
  component: () => import('../../../../components/views/app/admin/AdminRoot'),
  meta: {
    title: 'admin.admin',
    roles: [EhRole.Admin]
  },
  children: [
    {
      path: 'home',
      name: Page.AdminHome,
      component: () => import('../../../../components/views/app/admin/home/AdminHome'),
      meta: {
        // todo : internationalize in case *Home will not delete
        title: 'Admin home',
      }
    },
    {
      path: 'troubleshooting-report',
      name: Page.AdminTroubleShootingReport,
      component: () => import('../../../../components/troubleshooting/report/TroubleshootingReport'),
      meta: {
        title: 'troubleshooting.troubleshootingReport',
      },
    },
    {
      path: 'users',
      name: Page.AdminOrgUsers,
      component: () => import('../../../../components/views/app/admin/user/AdminOrgUsers'),
      redirect: { name: Page.AdminOrgUsersList },
      children: [
        {
          path: 'list',
          name: Page.AdminOrgUsersList,
          component: () => import('../../../../components/views/app/admin/user/list/AdminOrgUsersList'),
          meta: {
            title: 'orgUser.users'
          }
        },
        {
          path: 'add',
          name: Page.AdminAddUser,
          component: () => import('../../../../components/users/add/AddUserComponent'),
          meta: {
            title: 'orgUser.addUser',
          }
        },
        {
          path: 'profile/:userId',
          name: Page.AdminOrgUserOverview,
          redirect: { name: Page.AdminViewOrgUserProfile },
          props: true,
          component: () => import('../../../../components/views/app/admin/user/profile/AdminOrgUserOverview'),
          meta: {
            title: 'orgUser.profile',
          },
          children: [
            {
              path: 'view',
              name: Page.AdminViewOrgUserProfile,
              props: true,
              component: () => import('../../../../components/views/app/admin/user/profile/AdminOrgUserProfile'),
              meta: {
                title: 'orgUser.profile',
              }
            },
            {
              path: 'edit',
              name: Page.AdminEditOrgUserProfile,
              props: true,
              component: () => import('../../../../components/views/app/admin/user/profile/AdminOrgUserProfileEdit'),
              meta: {
                title: 'orgUser.editProfile',
              }
            },
            {
              path: 'groups',
              name: Page.AdminEditOrgUserGroups,
              props: true,
              component: () => import('../../../../components/views/app/admin/user/profile/AdminOrgUserGroups'),
              meta: {
                title: 'orgUser.editUserGroups',
              }
            }
          ]
        }
      ]
    },
    {
      path: 'profile',
      name: Page.AdminProfile,
      redirect: { name: Page.AdminProfileView },
      component: () => import('../../../../components/views/app/admin/profile/AdminProfileRoot'),
      meta: {
        title: 'common.profile',
      },
      children: [
        {
          path: 'view',
          name: Page.AdminProfileView,
          component: () => import('../../../../components/views/app/admin/profile/ViewAdminProfile'),
          meta: {
            title: 'common.profile',
          }
        },
        {
          path: 'edit',
          name: Page.AdminProfileEdit,
          component: () => import('../../../../components/views/app/admin/profile/EditAdminProfile'),
          meta: {
            title: 'common.profile',
          }
        }
      ]
    },
    {
      path: 'organization-setting',
      name: Page.AdminOrganizationSetting,
      redirect: { name: Page.AdminOrganizationSettingView },
      component: () => import('../../../../components/views/app/admin/organization-setting/AdminOrganizationSettingRoot'),
      meta: {
        title: 'common.organizationSetting',
      },
      children: [
        {
          path: 'view',
          name: Page.AdminOrganizationSettingView,
          component: () => import('../../../../components/views/app/admin/organization-setting/ViewAdminOrganizationSetting'),
          meta: {
            title: 'common.organizationSetting',
          }
        },
        {
          path: 'edit',
          name: Page.AdminOrganizationSettingEdit,
          component: () => import('../../../../components/views/app/admin/organization-setting/EditAdminOrganizationSetting'),
          meta: {
            title: 'common.organizationSetting',
          }
        },
        {
          path: 'edit-language-settings',
          name: Page.AdminLanguageSettingEdit,
          component: () => import('../../../../components/views/app/admin/organization-setting/i18n/EditLanguageSettings'),
          meta: {
            title: 'language.language',
          }
        },
      ]
    },
    {
      path: 'groups',
      name: Page.AdminGroups,
      component: () => import('../../../../components/views/app/admin/group/AdminGroups'),
      redirect: { name: Page.AdminGroupsList },
      meta: {
        title: 'group.groups'
      },
      children: [
        {
          path: 'list',
          name: Page.AdminGroupsList,
          props: true,
          component: () => import('../../../../components/views/app/admin/group/list/AdminGroupsList'),
          meta: {
            title: 'group.groups'
          }
        },
        {
          path: 'add',
          name: Page.AdminAddGroup,
          component: () => import('../../../../components/groups/add/AddGroupComponent'),
          meta: {
            title: 'group.addGroup',
          }
        },
        {
          path: 'edit',
          name: Page.AdminEditGroup,
          props: true,
          component: () => import('../../../../components/groups/edit/EditGroupComponent'),
          meta: {
            title: 'group.editGroup',
          }
        },
      ]
    },
    {
      path: 'observation-templates',
      name: Page.AdminObservationTemplates,
      component: () => import('../../../../components/views/app/admin/observationTemplates/ObservationTemplates'),
      redirect: { name: Page.AdminObservationTemplatesList },
      meta: {
        title: 'observationTemplates.observationTemplates'
      },
      children: [
        {
          path: 'list',
          name: Page.AdminObservationTemplatesList,
          component: () => import('../../../../components/views/app/admin/observationTemplates/list/ObservationTemplatesList'),
          meta: {
            title: 'observationTemplates.observationTemplates'
          },
        },
        {
          path: 'add',
          name: Page.AdminObservationTemplatesAdd,
          component: () => import('../../../../components/views/app/admin/observationTemplates/add/AddObservationTemplate'),
          meta: {
            title: 'observationTemplates.addObservationTemplate'
          },
        },
        {
          path: 'view/:uuid',
          name: Page.AdminObservationTemplatesView,
          props: true,
          component: () => import('../../../../components/views/app/admin/observationTemplates/view/ViewObservationTemplate.vue'),
          meta: {
            title: 'observationTemplates.observationTemplate'
          },
        },
        {
          path: 'edit/:uuid',
          name: Page.AdminObservationTemplatesEdit,
          props: true,
          component: () => import('../../../../components/views/app/admin/observationTemplates/edit/EditObservationTemplate'),
          meta: {
            title: 'observationTemplates.observationTemplate'
          },
        },
      ]
    },
    anamnesis,
    observationTemplates,
    // labResultGroups,
    {
      path: 'programs',
      name: Page.AdminPrograms,
      component: () => import('../../../../components/views/app/admin/programs/Programs'),
      redirect: { name: Page.AdminProgramsList },
      meta: {
        title: 'programs.programs'
      },
      children: [
        {
          path: 'list',
          name: Page.AdminProgramsList,
          component: () => import('../../../../components/views/app/admin/programs/list/ProgramsList'),
          meta: {
            title: 'programs.programs'
          },
        },
        {
          path: 'add',
          name: Page.AdminProgramsAdd,
          component: () => import('../../../../components/views/app/admin/programs/add/AddProgram'),
          meta: {
            title: 'programs.addProgram'
          },
        },
        {
          path: 'view/:uuid',
          name: Page.AdminProgramsView,
          props: true,
          component: () => import('../../../../components/views/app/admin/programs/view/ViewProgram'),
          meta: {
            title: 'programs.program'
          },
        },
        {
          path: 'edit/:uuid',
          name: Page.AdminProgramsEdit,
          props: true,
          component: () => import('../../../../components/views/app/admin/programs/edit/EditProgram'),
          meta: {
            title: 'programs.program'
          },
        },
      ]
    },
  ]
};
