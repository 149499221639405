import { addOrgUser } from './add';
import { editOrgUserProfile } from './profile';
import { editOrgUserGroups } from './groups';
import { list } from './list';

export const orgUsers = {
  namespaced: true,
  modules: {
    list,
    addOrgUser,
    editOrgUserProfile,
    editOrgUserGroups
  }
};
