import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { isCaptureOnlyErrors, loadAxiosErrors } from '../../services/axios/axiosError';

export const mutations = {
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  },

  [types.INIT](state) {
    state.errors = loadAxiosErrors();
    state.captureOnlyErrors = createInputConfig({
      id: 'captureOnlyErrors',
      type: InputType.Checkbox,
      required: false,
      label: 'troubleshooting.captureOnlyErrors',
      value: isCaptureOnlyErrors(),
    });
  },
};
