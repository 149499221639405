import { types } from './types';
import {
  setPageIndexMutation,
  setPaginationConfigMutation,
  toServerPageOptions
} from '../../../../../../../../model/page';

export const mutations = {

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_VALID_PAGE_FOR_OBSERVATION](state, validPageForObservation) {
    state.validPageForObservation = validPageForObservation;
  },

  [types.INIT_PAGE](state, { enrollment, patient }) {
    state.enrollment = enrollment;
    state.patient = patient;
  },

  [types.SET_OBSERVATION_TASK_MAP](state, observationTaskMap) {
    state.observationTaskMap = observationTaskMap;
  },

  [types.SET_OBSERVATIONS_PAGE](state, value) {
    state.observationsPage = value;
    Object.assign(state.serverSideOptions, toServerPageOptions(value));
  },

  [types.SET_PAGINATION_CONFIG]: setPaginationConfigMutation,

  [types.SET_PAGE_INDEX]: setPageIndexMutation,

};
