import { types } from './types';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PATIENTS](state, value) {
    state.patients = value;
  },

};

