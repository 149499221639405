import { popups } from '@h4h/popups';

import { PopupName } from '../constants/popupName';

export { popups } from '@h4h/popups';

const componentsMap = Object.freeze({
  [PopupName.NewObservation]: () => import('../components/views/app/practitioner/patients/view/enrollments/view/observation/components/add/popup/AddObservationPopup'),
  [PopupName.ChooseObservationGroup]: () => import('../components/views/app/practitioner/patients/view/enrollments/view/observation/components/add/popup/chooseObservationGroup/ChooseObservationGroupPopup'),
  [PopupName.ObservationComment]: () => import('../components/views/app/practitioner/patients/view/enrollments/view/observation/components/comment/popup/ObservationCommentPopup'),
  [PopupName.ObservationThreshold]: () => import('../components/observation/thresholds/dialog/ObservationsThresholdPopup'),
  [PopupName.AddNewEnrollment]: () => import('../components/views/app/practitioner/patients/view/enrollments/list/popup/AddEnrollment'),
  [PopupName.EditAddress]: () => import('../components/views/app/practitioner/patients/view/enrollments/list/addressPopup/AddressPopup'),
  [PopupName.EnrollmentStop]: () => import('../components/views/app/practitioner/patients/view/enrollments/stop/popup/EnrollmentStopPopup'),
  [PopupName.EditEnrollmentSchedule]: () => import('../components/views/app/practitioner/patients/view/enrollments/view/popup/EditEnrollmentSchedule'),
  [PopupName.AddObservationTemplateDefinition]: () => import('../components/views/app/admin/observationTemplates/popup/EditDefinitionPopup'),
  [PopupName.ChooseQuestionnaireTemplateForAnamnesis]: () => import('../components/views/app/admin/anamnesis/settings/popup/choose-template/ChooseAnamnesisTemplatePopup'),
  [PopupName.AddQuestionDefinition]: () => import('../components/views/app/admin/questionnaireTemplates/popup/EditQuestionPopup'),
  [PopupName.AddQuestionDefinitionAnswer]: () => import('../components/views/app/admin/questionnaireTemplates/popup/EditAnswerPopup'),
  [PopupName.EditProgramObservation]: () => import('../components/views/app/admin/programs/editObservationPopup/EditObservationPopup'),
  [PopupName.EditProgramQuestionnaire]: () => import('../components/views/app/admin/programs/editQuestionnairePopup/EditQuestionnairePopup'),
  [PopupName.AddSupportedVendor]: () => import('../components/views/app/admin/programs/addVendorPopup/addVendorPopup'),
  [PopupName.HandleTask]: () => import('../components/popups/handleTask/HandleTask'),
  [PopupName.HandleLogisticTask]: () => import('../components/popups/handleLogisticTask/HandleLogisticTask'),
  [PopupName.ForwardTask]: () => import('../components/popups/forwardTask/ForwardTask'),
  [PopupName.ChooseTask]: () => import('../components/popups/forwardTask/chooseTask/ChooseTask'),
  [PopupName.AddNewMedicalHistory]: () => import('../components/views/app/practitioner/patients/view/simpleAnamnesis/popup/add/AddMedicalHistory'),
  [PopupName.EditMedicalHistory]: () => import('../components/views/app/practitioner/patients/view/simpleAnamnesis/popup/edit/EditMedicalHistory'),
  [PopupName.AddNewAllergy]: () => import('../components/views/app/practitioner/patients/view/simpleAnamnesis/popup/add/AddAllergy'),
  [PopupName.EditAllergy]: () => import('../components/views/app/practitioner/patients/view/simpleAnamnesis/popup/edit/EditAllergy'),
  [PopupName.AddNewMedication]: () => import('../components/views/app/practitioner/patients/view/simpleAnamnesis/popup/add/AddMedication'),
  [PopupName.EditMedication]: () => import('../components/views/app/practitioner/patients/view/simpleAnamnesis/popup/edit/EditMedication'),
  [PopupName.TreatmentAdvice]: () => import('../components/views/app/practitioner/patients/view/enrollments/view/overview/treatmentadvice/TreatmentAdvice'),
  [PopupName.TreatmentAdviceAgreedPopup]: () => import('../components/views/app/practitioner/patients/view/enrollments/view/overview/treatmentadvice/popup/TreatmentAdviceAgreedPopup'),
  [PopupName.DeleteGroupPopup]: () => import('../components/groups/popup/DeleteGroupPopup'),
  [PopupName.PatientArchivingPopup]: () => import('../components/popups/archiving/PatientArchivingPopup'),
  [PopupName.PatientRestorePopup]: () => import('../components/popups/archiving/restore/PatientRestorePopup'),
  [PopupName.PatientFinalArchivingWarningPopup]: () => import('../components/popups/archiving/PatientFinalArchivingWarningPopup'),
  [PopupName.ProgramInputGroupPopup]: () => import('../components/views/app/admin/programs/programInputGroupPopup/programInputGroupPopup'),
  [PopupName.CallCenterDisablePopup]: () => import('../components/views/app/organization-setting/popup/CallCenterDisablePopup'),
  [PopupName.CallCenterEnablePopup]: () => import('../components/views/app/organization-setting/popup/CallCenterEnablePopup'),
  [PopupName.LogisticsEnablePopup]: () => import('../components/views/app/organization-setting/popup/LogisticsEnablePopup'),
  [PopupName.LogisticsDisablePopup]: () => import('../components/views/app/organization-setting/popup/LogisticsDisablePopup'),
  [PopupName.EcgPreviewPopup]: () => import('../components/ecg/popup/EcgPreviewPopup.vue'),
  [PopupName.ExportObservationsToPdf]: () => import('../components/views/app/practitioner/patients/view/enrollments/view/observation/components/view/pdf/ExportObservationsPopup'),
});

Object.entries(componentsMap).forEach(([name, component]) => popups.registerComponent(name, component));
