import { InputType } from '../constants/inputType';

const dateInputTypes = new Set([
  InputType.Date, InputType.Time, InputType.DateTime, InputType.Calendar, InputType.MonthPicker
]);

export function isKindOfDateInput(input) {
  return input && dateInputTypes.has(input.type);
}

export function isSelectInput(input) {
  return input?.type === InputType.Select;
}

// todo IANTONOV replace or rename
export function isSelect2Input(input) {
  return input?.type === InputType.Select2;
}

// todo IANTONOV replace or rename
export function isTypeahead2Input(input) {
  return input?.type === InputType.Typeahead2;
}

export function isCountrySelectInput(input) {
  return input?.type === InputType.CountrySelect;
}

export function isIntegerInput(input) {
  return input?.type === InputType.Integer;
}

export function isFloatInput(input) {
  return input?.type === InputType.Float;
}

export function isTextInput(input) {
  return input?.type === InputType.Text;
}

export function isBooleanInput(input) {
  return input?.type === InputType.Boolean;
}

export function isCheckboxInput(input) {
  return input?.type === InputType.Checkbox;
}

export function isAddress(input) {
  return input?.type === InputType.Address;
}

export function isTypeahead(input) {
  return input?.type === InputType.Typeahead;
}

export function isLabel(input) {
  return input?.type === InputType.Label;
}

export function isTextAreaInput(input) {
  return input?.type === InputType.Textarea;
}

export function isDateRange(input) {
  return input?.type === InputType.DateRange;
}

export function isDayPicker(input) {
  return input?.type === InputType.DayPicker;
}

export function isPills(input) {
  return input?.type === InputType.Pills;
}

export function isFile(input) {
  return input?.type === InputType.File;
}

export function isWysiwyg(input) {
  return input?.type === InputType.Wysiwyg;
}

export function isRadio(input) {
  return input?.type === InputType.Radio;
}

export function isPhone(input) {
  return input?.type === InputType.Phones;
}

export function isTreeView(input) {
  return input?.type === InputType.TreeView;
}

export function isPasswordStrengthInput(input) {
  return input?.type === InputType.PasswordStrength;
}

export function isCodeInput(input) {
  return input?.type === InputType.CodeInput;
}

export function isDatePartial(input) {
  return input?.type === InputType.DatePartial;
}