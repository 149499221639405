import { types } from './types';
import { showRestError } from '../../../../../../utils/errors';
import {
  kardiaPatients,
  requestNewConnectionCodeForKardiaPatient
} from '../../../../../../services/api/kardia/kardiaPatients';
import { popups } from '../../../../../../services/popups';

export const actions = Object.freeze({
  init,
  performKardiaStatusRequest,
});

async function performKardiaStatusRequest({ commit, state }) {
  commit(types.SET_KARDIA_REQUEST_IS_PERFORMING, true);
  const result = await requestNewConnectionCodeForKardiaPatient(state.patientUuid);
  commit(types.SET_KARDIA_REQUEST_IS_PERFORMING, false);
  if (!result.success) {
    showRestError(result.error, 'kardia.errorPerformRequest');
    return;
  }
  popups.showModal({
    message: 'kardia.requestSuccessfullyCreatedMessage',
    title: 'kardia.alivecorKardia',
    buttons: {
      confirm: {
        label: 'Ok',
        class: 'btn-primary',
        result: 'ok'
      }
    }
  });
  await reload(commit, state.patientUuid);
}

async function reload(commit, patientUuid) {
  commit(types.SET_KARDIA_PATIENT, null);
  commit(types.SET_LOADING, true);
  const result = await kardiaPatients.get(patientUuid);
  commit(types.SET_LOADING, false);

  if (result.error) {
    showRestError(result.error, 'kardia.cantFetchKardiaPatientInformation');
    return;
  }

  commit(types.SET_KARDIA_PATIENT, result.data);
}

async function init({ commit }, { patientUuid, enrollment }) {
  commit(types.INIT, { patientUuid, enrollment });
  await reload(commit, patientUuid);
}
