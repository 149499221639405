export const types = Object.freeze({

  SET_LOADING: 'SET_LOADING',
  SET_COMMENT: 'SET_COMMENT',
  SET_COMMENTS: 'SET_COMMENTS',

  SET_POPUP_ID: 'SET_POPUP_ID',
  SET_POPUP_OBSERVATION_TYPE: 'SET_POPUP_OBSERVATION_TYPE',
  INIT_POPUP: 'INIT_POPUP',
  SET_POPUP_VIEW_MODE_ON: 'SET_POPUP_VIEW_MODE_ON',
  CLEAN_STATE: 'CLEAN_STATE',
});
