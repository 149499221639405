import { types } from './types';
import { intervalChooserConfig, periodInputConfig } from './forms';
import { setInputValueMutation } from '@h4h/inputs';
import {
  ChosenPeriodHelper,
  evalGranularityPeriod
} from '../../../../../../../components/period/periodHelper/chosenPeriodHelper';
import moment from 'moment';

export const mutations = {
  [types.SET_OBSERVATIONS_LOADING](state, value) {
    state.observationsLoading = value;
  },
  [types.SET_LOADING_PERCENT](state, value) {
    state.loadingPercent = value;
  },

  [types.SET_GROUPED_OBSERVATIONS](state, groupedObservations) {
    state.groupedObservations = groupedObservations;
  },

  [types.INIT_PAGE](state, { patient, enrollment, conclusionNames, conclusionBy, observationTemplate }) {
    state.groupedObservations = [];

    state.observationTemplate = observationTemplate;
    state.patient = patient;
    state.enrollment = enrollment;
    state.period = periodInputConfig(enrollment);
    state.intervalChooser = intervalChooserConfig(state.period);
    state.conclusionNames = conclusionNames;
    state.conclusionBy = conclusionBy;
  },

  [types.SET_OBSERVATION_TASK_MAP](state, observationTaskMap) {
    state.observationTaskMap = observationTaskMap;
  },

  [types.SET_ALL_TASKS](state, tasks) {
    const map = new Map();
    tasks.forEach(task => {
      const observationUuid = task.observation.payload.uuid;
      const observationTasks = map.get(observationUuid) || [];
      observationTasks.push(task);
      map.set(observationUuid, observationTasks);
    });
    state.observationTaskMap = map;
  },

  [types.SET_INPUT_VALUE](state, { input, value }) {
    setInputValueMutation(state, { input, value });
    if (input === state.period) {
      state.intervalChooser.value = evalGranularityPeriod(state.period, state.intervalChooser.options.map(option => option.value));
    }
    if (input === state.intervalChooser && !!value) {
      const chosenPeriodHelper = new ChosenPeriodHelper({
        granularity: value,
        count: 1,
        currentTimeProvider: () => moment(state.period.max)
      });
      let start = chosenPeriodHelper
        .getExpectedStartOfPeriod()
        .startOf('day')
        .toDate();
      if (start < state.period.min) {
        start = state.period.min;
      }
      state.period.value = [start, state.period.max];
    }
  },
};
