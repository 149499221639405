import { types } from './types';

export const mutations = {
  [types.SET_QUESTIONNAIRE_TEMPLATE_LOADING](state, value) {
    state.questionnaireTemplateLoading = value;
  },

  [types.SET_QUESTIONNAIRE_TEMPLATE](state, questionnaireTemplate) {
    state.questionnaireTemplate = questionnaireTemplate;
  },

  [types.SET_QUESTIONNAIRES_LOADING](state, value) {
    state.questionnairesLoading = value;
  },

  [types.SET_QUESTIONNAIRES](state, questionnaires) {
    state.questionnaires = questionnaires;
  },

  [types.INIT_PAGE](state, { patient }) {
    state.patient = patient;
    state.questionnaires = [];
  },
};
