<template>
  <div
    :class="[
      styles.option,
      !$props.squaredButtons && styles.rounded,
      $props.squaredButtons && styles.squared,
      $props.active && 'active',
      $props.disabled && styles.optionDisabled,
      $props.pillStyle,
    ]"
    @click="$emit('click', $event)"
  >
    <span
      v-if="showSelectIcon"
      :class="styles.optionIcon"
      :tabindex="$props.tabindex"
      @focus="$emit('focus', $event)"
      @click="$emit('iconClick', $event)"
    >
      <Icon :type="$props.active ? 'x' : 'plus'"/>
    </span>

    <LabelTooltip
      :info="$props.info"
      :label="$props.label"
      :labelStyle="[$props.squaredButtons && styles.squaredButtonsLabelStyle, $props.squaredButtons && $props.active && 'active']"
    />
  </div>
</template>

<script>
  import { inputProps } from '../../utils';

  import LabelTooltip from '../labelTooltip/LabelTooltip';

  import styles from './pills.scss';

  export default {
    name: 'H4hPill',

    components: {
      LabelTooltip
    },

    props: {
      tabindex: inputProps.tabindex,

      /** labels */
      info: inputProps.label,

      // eslint-disable-next-line vue/require-prop-types
      label: {
        ...inputProps.label,
        required: true
      },

      /** flags */
      active: inputProps.booleanFalse,
      disabled: inputProps.booleanFalse,
      showSelectIcon: inputProps.booleanTrue,
      squaredButtons: inputProps.booleanFalse,
      pillStyle: inputProps.style,
    },

    data() {
      return {
        styles
      };
    }
  };
</script>
