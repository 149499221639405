/**
 *
 * @param {} key - key for search
 * @param {String}  keyFieldName - keyFieldName for comparison
 * @param {Array}   values - an array to search
 * @param {String}  resultField - field to return
 * @return {Object} result[resultField] or uuid, if not found
 */
export function findByKey(key, keyFieldName, values, resultField) {
  if (Array.isArray(values)) {
    const result = values.find(v => v[keyFieldName] === key);
    if (result) {
      return result[resultField];
    }
  }
  return key;
}

