<template>
  <div
    :class="[
      styles.container,
      $props.inline && styles.inline,
      !$props.valid && styles.invalid
    ]"
  >
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />

    <div
      :class="[styles.value, $props.inputStyle]"
      @click="onClick"
    >
      {{ $props.formatter($props.value) }}
    </div>
  </div>
</template>

<script>
  import { inputProps } from '../../utils';
  import LabelGroup from '../labelGroup/LabelGroup';
  import styles from './label.scss';

  export default {
    name: 'H4hLabel',

    components: {
      LabelGroup
    },

    props: {
      value: [String, Number, Date, Object],

      formatter: {
        type: Function,
        default: i => i
      },

      /** labels */
      info: inputProps.label,
      label: inputProps.label,
      otherLabel: inputProps.label,

      /** flags */
      inline: inputProps.booleanTrue,
      valid: inputProps.booleanTrue,

      /** styles */
      labelStyle: inputProps.style,
      inputStyle: inputProps.style
    },

    data() {
      return {
        styles
      };
    },

    methods: {
      onClick(e) {
        this.$emit('click', e);
      }
    }
  };
</script>
