import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';
import { ObservationInputTypeOptions } from '../../../constants/observationInputType';

export const mutations = {
  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.INIT_POPUP](state, { popupId, terminologyTypes }) {
    state.popupId = popupId;
    state.inputs = getInputsConfig(terminologyTypes);
    state.inputsMap = getInputsMap(state.inputs);
  },
};

function getInputsConfig(terminologyTypes) {
  const inputType = createInputConfig({
    id: 'inputType',
    type: InputType.Select2,
    label: 'observationTemplates.inputType',
    required: true,
    clearable: false,
    options: ObservationInputTypeOptions
  });
  const type = createInputConfig({
    id: 'type',
    type: InputType.Typeahead,
    label: 'observationTemplates.terminologyType',
    required: true,
    options: createTerminologyInputTypeOptions(terminologyTypes),
    formatter: i => i ? i.label : i
  });
  const unitCode = createInputConfig({
    id: 'unitCode',
    type: InputType.Typeahead,
    label: 'observationTemplates.unitCode',
    required: true,
    options: createTerminologyInputTypeOptions(terminologyTypes),
    formatter: i => i ? i.label : i
  });
  const min = createTextInputConfig('min', 'common.min', InputType.Float, false);
  const max = createTextInputConfig('max', 'common.max', InputType.Float, false);
  return [
    inputType,
    type,
    unitCode,
    min,
    max
  ];
}

function createTextInputConfig(id, label, inputType, required) {
  return createInputConfig({
    id,
    type: inputType,
    label,
    required,
  });
}

function createTerminologyInputTypeOptions(terminologyTypes) {
  return terminologyTypes.map(type => ({
    label: type.translation,
    value: type.key
  }));
}
