import { isNil } from 'lodash';

export const validationRules = {
  methods: {
    // @refactor-team todo: uncomment email validation and get the translations for it when it becomes a requirement
    // vIsEmail: value => {
    //   return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) || 'validationMessages.invalidEmail';
    // },

    vIsRequired: value => {
      if (value === '' || isNil(value)) {
        return 'validationMessages.required';
      }
      return true;
    },
    vIsMultipleRequired: value => {
      if (!value?.size) {
        return 'validationMessages.required';
      }
      return true;
    },
    vIsMinLength: length => value => !value || value?.length === 0 || value?.length >= length || (['validationMessages.minLength', length]),
    vIsMaxLength: length => value => !value || value?.length === 0 || value?.length <= length || (['validationMessages.maxLength', length]),
    vIsBSN: value => {
      console.log(value);
      // we can pattern match the value here
      // we can call a service here to check the number against a DB
      // return true or string
    },
    vIsCOV: value => {
      console.log(value);
      // we can pattern match the value here
      // we can call a service here to check the number against a DB
      // return true or string
    },
    isFormValid(form) {
      let isValid = true;
      for (let input of form) {
        if (input.name && this.$refs[input.name]) {
          this.$refs[input.name].validateRules();
          isValid = isValid && this.$refs[input.name].errorMessage === null;
        }
      }
      return isValid;
    }
  }
};
