export const common = {
  all: 'All',
  min: 'Min',
  max: 'Max',
  tenant: 'Tenant',
  date: 'Date',
  logout: 'Logout',
  profile: 'Profile',
  organizationSetting: 'Organization',
  phoneNumber: 'Phone number',
  number: 'Number',
  numberShort: 'Number',
  biography: 'Biography',
  clinicName: 'Clinic name',
  replyLink: 'General e-mail address',
  showDisableDirectLoginDropdown: 'Show disable direct login dropdown',
  bsnRegexp: 'BSN Regex',
  pleaseUseValidRegexpForThisField: 'Please use valid Regex for this field',
  applyDutchDeepBsnCheck: 'Apply dutch deep BSN check',
  postalAddress: 'Postal address',
  addressData: 'Address data',
  treatmentAdviceEnabled: 'Treatment advice',
  treatmentAdviceEnabledValue: 'enabled',
  treatmentAdviceDisabledValue: 'disabled',
  save: 'Save',
  cancel: 'Cancel',
  add: 'Add',
  remove: 'Remove',
  edit: 'Edit',
  initials: 'Initials',
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  partnerMiddleName: 'Partner middle name',
  partnerName: 'Partner last name',
  dateOfBirth: 'Date of birth',
  age: 'Age',
  telephoneNumber: 'Mobile phone number',
  alarmPhoneNumber: 'Contact number',
  hisPatientId: 'Patient ID',
  email: 'Email address',
  emailAgain: 'Email address again',
  bigCode: 'BIG code',
  groups: 'Group(s)',
  title: 'Title',
  role: 'Role',
  noMatchingEntity: 'No matching records found',
  noEntity: 'No data available',
  actions: 'Actions',
  changeProfilePhoto: 'Change profile photo',
  upload: 'Upload',
  to: 'to',
  name: 'Name',
  defaultForRole: 'Default for role',
  roles: 'Role(s)',
  start: 'Start',
  end: 'End',
  yes: 'Yes',
  no: 'No',
  successfulLogin: 'Login successful',
  accountActivatedMsg: 'You have activated your account. Open your Heart for Health app to proceed.',
  getMobileAppMsg: 'If you do not have the app yet, use the links below to download the app from the App Store (Apple) or Play Store (Android).',
  program: 'Program',
  chooseRole: 'Choose role',

  perMin: '/min',
  secondsShort: 'sec',
  document: 'Document',
  documentNotAvailable: 'Document not available',
  average: 'Average',
  pageNotFound: 'Page not found',
  forbidden: 'Forbidden',
  text: 'Text',
  subject: 'Subject',
  delete: 'Delete',
  comment: 'comment',
  addComment: 'Add comment',
  filter: 'Filter',
  reset: 'Reset',
  pleaseWait: 'Please wait ...',
  update: 'Update',
  measurement: 'Measurement',
  sendActivationEmail: 'Send new activation email',
  activationEmailSent: 'Activation email has been sent.',
  activationEmailFailed: 'Activation email could not be sent, please try again.',
  defaultTimeZone: 'Default timezone',
  thisDateIsShownInXTimeZone: 'This date is shown in {0} timezone',
  search: 'Search',
  directLoginEnabled: 'Direct login?',
  selectAll: 'Select all',
  deselectAll: 'Select none',
  period: 'Period',
  show: 'Show',
  itemsSelected: 'Items selected',
  copy: 'Copy',
  copyAll: 'Copy all',
  loading: 'Loading',
  other: 'Other',
  others: '{0} others',
  othersCount: '{0} others',
  filters: 'Filters',
  savedFilters: 'Saved filters',
  filterName: 'Filter name',
  makeDefaultFilter: 'Make default filter',
  renameFilter: 'Rename filter',
  areYouSureYouWantToDeleteThisSavedFilter: 'Are you sure you want to delete this saved filter?',
  filterSuccessfullySaved: 'Filter successfully saved',
  filterRemoved: 'Filter removed',
  defaultFilterRemoved: 'Default filter removed',
  filterMarkedAsDefault: 'Filter marked as default',
  filterWithThisNameAlreadyExists: 'A filter with this name already exists',
  clearAll: 'Clear all',
  clearFilters: 'Clear filters',
  minCharacters: 'Minimum of {0} characters',
  type: 'Type',
  optional: 'Optional',
  next: 'Next',
  previous: 'Previous',
  select: 'Select',
  overview: 'Overview',
  or: 'or',
  dateFormat: 'dd-mm-yyyy',
  timeFormat: 'hh:mm',
  download: 'Download',
  cantFetchEntity: 'Can\'t fetch {0|lowercase}',
  cantFetchEntityCommon: 'Can\'t fetch {0}',
};
