import { valueExtractorBuilder, valueSetterBuilder } from './utils/valueExtractor';
import { EnhancedQuestionnaireResponseItemModel } from './utils/enhancedQuestionnaireResponseItemModel';
import { createQuestionInputConfig } from './utils/inputsBuilder';

export class QuestionnaireWrapper {

  /**
   *
   * @param {QuestionnaireTemplateModel} questionnaireTemplateModel
   * @param {QuestionnaireModel} questionnaireModel
   */
  constructor(questionnaireTemplateModel, questionnaireModel) {
    this.questionnaireTemplateModel = questionnaireTemplateModel;
    this.questionnaireModel = questionnaireModel;
  }

  getFields() {
    return this.questionnaireTemplateModel.questions
      .map(definition => {
        const questionnaireResponseItem = this.questionnaireModel.getOrCreateAnswer(definition.questionTerminologyCode);
        return new EnhancedQuestionnaireResponseItemModel({
          value: valueExtractorBuilder(definition)(questionnaireResponseItem),
          questionnaireResponseItem
        });
      });
  }

  /**
   * @param {TerminologyTranslationModel[]}   terminologyTypes
   */
  getInputs(terminologyTypes) {
    return this.questionnaireTemplateModel.questions
      .map(definition => {
        const questionnaireResponseItem = this.questionnaireModel.getOrCreateAnswer(definition.questionTerminologyCode);
        return createQuestionInputConfig(definition, questionnaireResponseItem, terminologyTypes);
      })
      .filter(v => !!v);
  }

  /**
   * @param {Array}   inputs
   */
  updateValuesFromInputs(inputs) {
    inputs.forEach(input => {
      const answer = this.questionnaireModel.getAnswer(input.id);
      const definition = this.questionnaireTemplateModel
        .questions
        .find(definition => definition.questionTerminologyCode === input.id);
      if (answer && definition) {
        const valueSetter = valueSetterBuilder(definition);
        if (valueSetter) {
          valueSetter(answer, input.value);
        }
      }
    });
  }
}

