<template>
  <Loading :label="label"/>
</template>

<script>
  import { Loading } from '@h4h/loading';

  export default {
    name: 'H4hLoadingPopup',

    components: {
      Loading
    },

    props: {
      label: {
        type: String,
        default: 'common.loading'
      }
    }
  };
</script>
