import { ecgKey } from '../../../../../../../model/observation/ecg';

export const getters = {
  isEcg,
  tableFilters,
};

function isEcg(state) {
  if (state.observationTemplate) {
    return state.observationTemplate.terminologyType === ecgKey;
  }
}

function tableFilters(state) {
  if (!isEcg(state)) {
    return [];
  }
  return [
    state.conclusionNames,
    state.conclusionBy,
  ].filter(x => !!x);
}

