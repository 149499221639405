import { state } from './state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { withInputs } from '@h4h/inputs';

export const table = withInputs({
  namespaced: true,

  state,
  actions,
  getters,
  mutations,
});
