import { validateCronExpressionObject } from './ruleset';

export { getCronRulesetInfo } from './ruleset';
export { rulesetInfoMessageTypes } from './ruleset/rulesetInfoGenerator';
export {
  cronValidator,
  cronInputValidator,
};

/**
 * Determines whether a cron expression is valid
 * @param {String} cronExpression
 *
 * @returns {Boolean}
 */
function cronValidator(cronExpression) {
  if (!cronExpression) {
    return false;
  }

  // cron expression must consist of six non-empty parts
  const parts = cronExpression.split(' ').filter(p => p && p.trim().length > 0);
  if (parts.length !== 6) {
    return false;
  }

  // prepare the split parts
  const cronObject = {
    seconds: parts[0].trim(),
    minutes: parts[1].trim(),
    hours: parts[2].trim(),
    daysOfMonth: parts[3].trim(),
    months: parts[4].trim(),
    daysOfWeek: parts[5].trim(),
  };

  try {
    return validateCronExpressionObject(cronObject);
  }
  catch (e) {
    // if any exception occurs then the validation has implicitly failed
    return false;
  }
}

/**
 * Validates the value of the input field against the cron validator
 * @param {Object} input
 * @returns {Boolean}
 */
function cronInputValidator(input) {
  return cronValidator(input.value);
}
