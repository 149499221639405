import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';

export const mutations = {

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.INIT_POPUP](state, { popupId, observationTemplates }) {
    state.popupId = popupId;
    state.inputs = getInputsConfig({ observationTemplates });
    state.inputsMap = getInputsMap(state.inputs);
  },
};

function getInputsConfig({ observationTemplates }) {
  const options = observationTemplates.map(observationTemplate => {
    return {
      label: observationTemplate.name,
      value: observationTemplate,
    };
  });
  const observationTemplateInput = createInputConfig({
    id: 'observationTemplateId',
    type: InputType.Select2,
    label: 'labResultGroups.observationTemplate',
    required: true,
    clearable: false,
    options,
  });

  return [
    observationTemplateInput,
  ];
}
