import { uniqBy } from 'lodash';

const size = 500;

export class PartialRequestLoaderResult {

  /**
   *
   * @param {Object[]}            items
   * @param {Map<String, Object>} linkedEntities
   * @param {Boolean}             success
   * @param {Object}              error
   */
  constructor({ items, linkedEntities, success, error }) {
    this.items = items;
    this.linkedEntities = linkedEntities;
    this.success = success;
    this.error = error;
  }
}

export class PartialRequestLoaderConfig {

  /**
   *
   * @param {(config:Object) => RequestResult}            dataProvider
   * @param {String[]}                                    linkedEntitiesKeys
   * @param {(key:String, ids:String[]) => RequestResult} linkedEntityProvider
   * @param {({percent}) => void}                         progressCallback
   * @param {String}                                      idField
   */
  constructor({ dataProvider, linkedEntitiesKeys, linkedEntityProvider, progressCallback,idField }) {
    this.dataProvider = dataProvider;
    this.linkedEntitiesKeys = linkedEntitiesKeys;
    this.linkedEntityProvider = linkedEntityProvider;
    this.progressCallback = progressCallback;
    this.idField = idField;
  }
}

export class PartialRequestLoader {

  /**
   *
   * @param {PartialRequestLoaderConfig} config
   */
  constructor(config) {
    this.config = config;
  }

  /**
   *
   * @param {RequestResult} result
   * @returns {PartialRequestLoaderResult}
   */
  extractError(result) {
    if (!result.success) {
      return new PartialRequestLoaderResult({
        ...result,
        success: false,
      });
    }
  }

  /**
   * @returns {Promise<PartialRequestLoaderResult>}
   */
  async getData() {
    this.linkedEntities = new Map();
    this.items = [];
    const firstPageResult = await this.config.dataProvider({ size });
    const error = this.extractError(firstPageResult);
    if (error) {
      return error;
    }
    /**
     * @type {PageModel}
     */
    const firstPageData = firstPageResult.data;
    this.items.push(...firstPageData.content);
    await this.getLinkedItems(firstPageData.content);

    const totalPages = firstPageData.totalPages;
    for (let page = 1; page < totalPages; page++) {
      if (this.config.progressCallback) {
        this.config.progressCallback(100 * page / totalPages);
      }
      const nextPageResult = await this.config.dataProvider({ page, size });
      const error = this.extractError(nextPageResult);
      if (error) {
        return error;
      }
      this.items.push(...nextPageResult.data.content);
      await this.getLinkedItems(nextPageResult.data.content);
    }

    this.items = uniqBy(this.items, this.config.idField);
    return new PartialRequestLoaderResult({
      items: this.items,
      linkedEntities: this.linkedEntities,
      success: true,
    });
  }

  /**
   *
   * @param {{uuid}[]}  content
   */
  async getLinkedItems(content) {
    const uuids = content.map(object => object.uuid);
    const promises = this.config.linkedEntitiesKeys.map(key => this.loadLinkedItemsByKey(key, uuids));
    await Promise.all(promises);
  }

  async loadLinkedItemsByKey(key, uuids) {
    const requestResult = await this.config.linkedEntityProvider(key, uuids);
    if (requestResult.success) {
      const list = this.linkedEntities.get(key) || [];
      list.push(...requestResult.data);
      this.linkedEntities.set(key, list);
    }
  }
}
