import { types } from './types';
import { ObservationThresholdModel } from '../../../model/thresholds/observationThresholdModel';
import { ThresholdInputsGroup } from '../../../components/observation/thresholds/dialog/components/ThresholdInputsGroup';
import { setInputValue, validateInput } from '@h4h/inputs';
import { OBSERVATION_THRESHOLD_POPUP_MODE } from './constants';
import { inputStateChangeMutation } from '../../../components/observation/thresholds/dialog/components/InputStateChange';

/**
 *
 * @param {ObservationThresholdModel} observationThreshold
 * @param {ObservationTemplateModel} observationTemplateModel
 * @param {Object[]} terminologyTypes
 * @return {ThresholdInputsGroup[]}
 */
function toThresholdInputGroups(observationThreshold, observationTemplateModel, terminologyTypes, mode) {
  const personalizedThresholdMode = mode === OBSERVATION_THRESHOLD_POPUP_MODE.PERSONALIZED_THRESHOLD;
  return observationThreshold.severityConditionPerDefinition.map(observationThresholdSeverityConditionsModel => {
    return new ThresholdInputsGroup({
      observationThresholdSeverityConditionsModel,
      observationTemplateModel,
      terminologyTypes,
      highlightModified: personalizedThresholdMode,
      inputAreRequired: personalizedThresholdMode,
    });
  });
}

function resetThreshold(state) {
  state.observationThreshold.resetThresholds(state.observationTemplate);
  state.thresholdInputsGroups = toThresholdInputGroups(state.observationThreshold, state.observationTemplate, state.terminologyTypes, state.mode);
  state.thresholdInputsGroups.forEach(inputGroup => {
    inputGroup.groups.forEach(group => group.input.value = null);
  });
}

export const mutations = {
  /**
   *
   * @param state
   * @param {ObservationThresholdDialogInitModel} model
   */
  [types.INIT_DIALOG](state, model) {
    state.showDeletedThresholds = true;
    state.mode = model.mode;
    state.observationThreshold = new ObservationThresholdModel(model.observationThreshold);
    if (model.mode === OBSERVATION_THRESHOLD_POPUP_MODE.CREATE_PROGRAM) {
      state.observationThreshold.createMissedConditions(model.observationTemplate);
    }
    state.observationTemplate = model.observationTemplate;
    state.terminologyTypes = model.terminologyTypes;
    state.thresholdInputsGroups = toThresholdInputGroups(state.observationThreshold, model.observationTemplate, model.terminologyTypes, state.mode);
    if (!model.observationThreshold.severityConditionPerDefinition.length) {
      resetThreshold(state);
    }
  },

  [types.RESET_THRESHOLDS]: resetThreshold,

  [types.SET_POPUP_ID](state, popupId) {
    state.popupId = popupId;
  },

  [types.SET_SHOW_DELETED_THRESHOLDS](state, value) {
    state.showDeletedThresholds = value;
  },

  [types.SET_PRISTINE](state, value) {
    state.thresholdInputsGroups.forEach(thresholdInputsGroup => thresholdInputsGroup.setPristine(value));
  },

  [types.ADD_MISSING_ITEMS](state) {
    state.thresholdInputsGroups.forEach(thresholdInputsGroup => thresholdInputsGroup.addMissingItems());
  },

  [types.SET_ITEM_DELETE_STATE](state, { group, value }) {
    group.setDeleted(value);
    state.thresholdInputsGroups.forEach(thresholdInputsGroup => thresholdInputsGroup.refreshGroupsIndexes());
  },

  [types.DELETE_ADDED_ITEM](state, group) {
    state.thresholdInputsGroups.forEach(thresholdInputsGroup => thresholdInputsGroup.removeAddedItem(group));
  },

  [types.SET_INPUT_VALUE](_, { input, value }) {
    setInputValue({ input, value });
    (input.allInputs || [])
      .filter(otherInput => otherInput !== input)
      .forEach(otherInput => otherInput.valid = validateInput(otherInput));
  },

  [types.INPUT_STATE_CHANGED]: inputStateChangeMutation,
};

