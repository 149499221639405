export const getters = {
  canPerformRequest,
  loading,
  mandatoryPatientActionsLoading,
  isPatientRequestPending,
};

function canPerformRequest(state) {
  if (state.actions) {
    return state.actions.length === 0;
  }
  return false;
}

function loading(state) {
  return state.loadingActions || state.requestIsPerforming;
}

function mandatoryPatientActionsLoading(state) {
  return loading(state);
}

function isPatientRequestPending(state) {
  if (state.actions) {
    return state.actions.length > 0;
  }
  return false;
}
