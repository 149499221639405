import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';

import { types } from './types';
import { createEhPeriodInputConfig } from '../../utils/factories';
import { LogisticTaskStatus, logisticTaskStatusOptions } from '../../constants/logistic/logisticTaskStatus';
import moment from 'moment';
import { cleanPageNum, setPaginationConfigMutation, toServerPageOptions } from '../../model/page';
import { createMainFilterInput } from '../../services/filter/utils/filterUtils';

function createFilterParams(state) {
  const params = {};

  state.inputs.forEach(input => {
    if (input.value) {
      if (input.id === 'period') {
        let period = [input.value, input.value];
        if (Array.isArray(input.value)) {
          period = input.value;
        }
        if (period.length === 0) {
          return;
        }
        if (period.length === 1) {
          period = [period[0], period[0]];
        }
        params['createdFromDate'] = moment(period[0]).startOf('day').toDate();
        params['createdToDate'] = moment(period[1]).endOf('day').toDate();
      }
      else {
        let value = input.value;
        if (value instanceof Set) {
          if (!value.size) {
            return;
          }
          value = Array.from(value);
        }
        params[input.id] = value;
      }
    }
  });
  return params;
}

export const mutations = {
  [types.SET_POPUP_ID](state, popupId) {
    state.popupId = popupId;
  },

  [types.SET_TASKS_FOR_HANDLE](state, tasksForHandle) {
    state.tasksForHandle = tasksForHandle;
  },

  [types.SET_PROGRAMS](state, value) {
    state.programs = value;
  },

  [types.INIT](state) {
    state.inputs = [
      createMainFilterInput({
        id: 'multipleFilter',
        label: 'common.name',
      }),
      createProgramInputConfig(state.programs),
      createStatusInputConfig(),
      createEhPeriodInputConfig({
        id: 'period',
        value: [
          moment().subtract(2, 'month').toDate(),
          new Date()
        ],
        label: 'common.date',
        placeholder: 'common.date',
      }),
    ];
    state.inputsMap = getInputsMap(state.inputs);
    state.filterParams = createFilterParams(state);
  },

  [types.UPDATE_FILTER_PARAMS](state) {
    state.filterParams = createFilterParams(state);
    cleanPageNum(state);
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_TASKS_PAGE](state, value) {
    state.tasksPage = value;
    Object.assign(state.serverSideOptions, toServerPageOptions(value));
  },

  [types.SET_PAGINATION_CONFIG]: setPaginationConfigMutation,

  [types.SET_SELECTED_TASKS](state, value) {
    state.selectedTasks = value;
  },
};

function createStatusInputConfig() {
  return createInputConfig({
    id: 'handledStatuses',
    multiple: true,
    showSelectAll: false,
    value: new Set([LogisticTaskStatus.UNHANDLED]),
    type: InputType.Select2,
    label: 'tasks.status',
    options: logisticTaskStatusOptions,
    required: false,
  });
}

function createProgramInputConfig(programs) {
  const programNames = new Set(programs.map(p => p.name));
  const programOptions = Array.from(programNames)
    .map(name => ({
      value: name,
      label: name
    }));
  return createInputConfig({
    id: 'programName',
    multiple: true,
    showSelectAll: false,
    value: new Set([]),
    type: InputType.Select2,
    label: 'common.program',
    options: programOptions,
    required: false,
  });
}
