export async function getCountryCode(dispatch) {
  const countryCode = await dispatch('i18n/countryCode/fetchCountryCode',{},{ root: true });
  return countryCode?.countryCode || null;
}
export const taskServiceCountryParam = 'tenantCountry';
export const logisticCountryParam = 'filterCountry';
export async function setCountryCodeParamIfNeed(dispatch, config, paramName = taskServiceCountryParam) {
  const countryCode = await getCountryCode(dispatch);
  if (countryCode) {
    config[paramName] = countryCode;
  }
}
