import { mapValues } from 'lodash';

import { types } from './types';
import { orgUserAvatar, orgUsers, resetUserPassword } from '../../../services/api/users/orgUsers';
import { showRestError } from '../../../utils/errors';
import { toast } from '../../../services/toast';
import { localize } from '../../../services/localize';
import { router } from '../../../services/router';
import { Page } from '../../../constants/page';
import { convertPhonesToStrings } from '../../../utils/phone/phoneComponentUtils';

export const actions = Object.freeze({
  initViewProfileForm,
  initEditProfileForm,
  fetchProfile,
  updateProfile,
  fetchAvatar,
  resetPassword,
});

async function initViewProfileForm({ commit }, { organizationSetting }) {
  commit(types.INIT_FORM, { organizationSetting, readOnly: true });
}

async function initEditProfileForm({ commit }, { organizationSetting }) {
  commit(types.INIT_FORM, { organizationSetting, readOnly: false });
}

async function fetchProfile({ commit }, userId) {
  commit(types.SET_LOADING, true);
  const result = await orgUsers.get(userId);
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_PROFILE, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchProfile');
  }
}

async function updateProfile({ state, commit, getters }, userId) {
  commit(types.SET_PRISTINE, false);
  if (!getters.areProfileInputsValid) {
    return false;
  }

  const updatedProfile = {
    ...state.profile,
    ...mapValues(state.inputsMap, i => i.value)
  };
  convertPhonesToStrings(updatedProfile, ['phone']);

  commit(types.SET_LOADING, true);
  const result = await orgUsers.update(userId, updatedProfile);
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_PROFILE, result.data);
    toast.success(localize('messages.profileUpdated'));
    router.push({ name: Page.AdminViewOrgUserProfile });
  }
  else {
    showRestError(result.error, 'messages.cantUpdateProfile');
  }
  return result.success;
}

async function fetchAvatar({ commit }, userId) {
  commit(types.SET_AVATAR, null);
  commit(types.SET_AVATAR_IS_LOADING, true);
  const result = await orgUserAvatar(userId).fetchOne();
  commit(types.SET_AVATAR_IS_LOADING, false);

  if (result.success) {
    commit(types.SET_AVATAR, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchAvatar');
  }
}

async function resetPassword({ commit }, id) {
  commit(types.SET_LOADING, true);
  let result = await resetUserPassword(id);
  commit(types.SET_LOADING, false);
  if (result.success) {
    toast.success(localize('messages.passwordResetSuccessfully'));
  }
  else {
    showRestError(result.error, 'messages.errorResetPassword');
  }
}

