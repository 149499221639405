import { ObservationModel } from './observationModel';
import observationTypesKardia from '../../terminology/concepts/vendor/alivecor/kardia/alivecore-kardia.json';
import file from '../../terminology/concepts/file/file.json';
import observationHeartRate from '../../terminology/concepts/observation/observation-heart-rate.json';
import measurementAttribute from '../../terminology/concepts/measurement-attribute/measurement-attribute.json';
import extension from '../../terminology/concepts/extension/extension.json';

import { findChildByKey } from '../../terminology/terminologyHelper';
import { formatDate } from '@h4h/date';
import moment from 'moment';
import { ecgKeyName } from './ecgConstants';

export const ecgKey = ecgKeyName;
export const documentReferenceKey = findChildByKey(file, 'ehlt.file').key;
export const heartRateKey = findChildByKey(observationHeartRate, 'observation.heartRate').key;
export const durationKey = findChildByKey(measurementAttribute, 'ehlt.measurementAttribute.duration').key;
export const noteKey = findChildByKey(extension, 'extension.note').key;
export const algorithmDeterminationKey = findChildByKey(observationTypesKardia, 'ecg.algorithmDetermination').key;

export class Ecg extends ObservationModel {
  /**
   * Ecg config
   *
   * @param {Object}                    config
   * @param {String[]}                  config.enrollmentIds
   * @param {ObservationValueModel[]}   config.observationValues
   *
   * @param {Date}                      config.authored
   * @param {String}                    config.patientIamId
   * @param {String}                    config.terminologyType
   * @param {String}                    config.uuid
   */
  constructor(config) {
    super(config);
    this.terminologyType = ecgKey;
  }

  get heartRate() {
    return this.getOrCreateValue(heartRateKey, this.unit).valueInt;
  }

  set heartRate(value) {
    this.getOrCreateValue(heartRateKey, this.unit).valueInt = value;
  }

  get duration() {
    return this.getValue(durationKey)
      ? this.getValue(durationKey).valueInt
      : null;
  }

  get note() {
    return this.getValue(noteKey)
      ? this.getValue(noteKey).valueString
      : null;
  }

  get algorithmDetermination() {
    return this.getValue(algorithmDeterminationKey)
      ? this.getValue(algorithmDeterminationKey).valueString
      : null;
  }

  get documentReference() {
    return this.getValue(documentReferenceKey)
      ? this.getValue(documentReferenceKey).valueReference
      : null;
  }

  get observationDateFormatted() {
    if (this.authored) {
      return formatDate(moment(this.authored).toDate());
    }
    return null;
  }
}
