import { types } from './types';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { InitPopupMethodName } from './constants';

export const actions = Object.freeze({
  [InitPopupMethodName]: initPopup,
  select,
});

async function initPopup({ commit }, existsSupportedVendors) {
  const { id, closed } = await popups.show({
    name: PopupName.AddSupportedVendor,
    type: PopupType.Modal
  });
  commit(types.INIT_POPUP, { popupId: id, existsSupportedVendors });
  return { closed };
}

async function select({ state }, vendor) {
  popups.hide({ id: state.popupId, result: vendor });
}

