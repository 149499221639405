export const SKIPPED_RANGE = '...';

export function getPageButtons(page, pageCount, maxButtons) {
  if (!(maxButtons % 2)) {
    // even maxButtons won't work since we need to show equal
    // count of neighbours on each side when in the middle of a range
    throw new Error('Even maxButtons not allowed');
  }

  if (maxButtons < 7) {
    // maxButtons < 7 won't work since we need to show
    // 1, ..., page - 1, page, page + 1, ..., pageCount
    throw new Error('maxButtons < 7 not allowed');
  }

  if (pageCount <= maxButtons) {
    return range(1, pageCount);
  }

  // we need to always show first and last page
  const maxNeighbours = maxButtons - 2;

  // subtract skipped range labels and determine minimal
  // count of neighbours to be shown on each side
  const minSideNeighbours = Math.floor((maxNeighbours - 2) / 2);

  // close enough to the start
  if (page + minSideNeighbours <= maxNeighbours) {
    return [
      ...range(1, maxNeighbours),
      SKIPPED_RANGE,
      pageCount
    ];
  }

  // close enough to the end
  if (page - minSideNeighbours > pageCount - maxNeighbours) {
    return [
      1,
      SKIPPED_RANGE,
      ...range(pageCount - maxNeighbours + 1, pageCount)
    ];
  }

  return [
    1,
    SKIPPED_RANGE,
    ...range(page - minSideNeighbours, page + minSideNeighbours),
    SKIPPED_RANGE,
    pageCount
  ];
}

function range(from, to) {
  from = Math.max(from, 1);

  const range = [];

  for (let i = from; i <= to; i++) {
    range.push(i);
  }

  return range;
}
