<template>
  <SelectTemplate
    ref="component"
    :component="component"
    :errorMessage="errorMessage"
    v-bind="$props"
    :data-testid="`field-${$props.name}`"
    @change="$emit('change', $event)"
    @blur="onBlur"
    @search="$emit('search', $event)"
    @focus="$emit('focus', $event)"
    @click="$emit('click', $event)"
    @keydown="$emit('keydown', $event)"
  >
    <template
      v-if="hasSlot('item')"
      #item="itemProps"
    >
      <slot
        name="item"
        v-bind="itemProps"
      />
    </template>

    <template
      v-if="hasSlot('selection')"
      #selection="itemProps"
    >
      <slot
        name="selection"
        v-bind="itemProps"
      />
    </template>

    <template
      v-if="hasSlot('chipSelectionContent')"
      #chipSelectionContent="itemProps"
    >
      <slot
        name="chipSelectionContent"
        v-bind="itemProps"
      />
    </template>

    <template
      v-if="hasSlot('itemLabel')"
      #itemLabel="itemProps"
    >
      <slot
        name="itemLabel"
        v-bind="itemProps"
      />
    </template>
  </SelectTemplate>
</template>

<script>
  import { VAutocomplete } from 'vuetify/lib';

  import { hasSlot } from '@h4h/utils';

  import { selectProps } from '../../utils';
  import { InputMixin } from '../../mixins';

  import SelectTemplate from './SelectTemplate';
  import { validationHelper } from '@h4h/utils';

  export default {
    name: 'H4hTypeahead2',

    components: {
      SelectTemplate,
    },

    mixins: [
      InputMixin,
      validationHelper
    ],

    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      ...selectProps,
      input: Object
    },

    data() {
      return {
        hasSlot,
        component: VAutocomplete
      };
    },
    methods: {
      focus() {
        this.$refs.component?.focus();
      },
      onBlur(e) {
        this.validateRules();
        this.$emit('blur', e);
      }
    }
  };
</script>
