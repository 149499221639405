import styles from '../components/tableRow/tableRow.scss';

export const HighlightColor = Object.freeze({
  Primary: styles.highlight__primary,
  Secondary: styles.highlight__secondary,
  Tertiary: styles.highlight__tertiary,
  Quaternary: styles.highlight__quaternary,
  Gray: styles.highlight__gray,
  SupportRed: styles.highlight__supportRed,
  SupportYellowOrange: styles.highlight__supportYellowOrange,
  SupportRedAndYellowOrange: styles.highlight__supportRedAndYellowOrange,
});
