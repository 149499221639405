const NLZip = /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/;
const SRZip = /^[1-9]\d{4,5}$/;
const postalCode = {
  test(s) {
    return NLZip.test(s) || SRZip.test(s);
  }
};
export const EhPatterns = Object.freeze({
  phoneNumber: /^((\+|00(\s|\s?)?)31(\s|\s?)?(\(0\)[\s]?)?|0)[1-9]((\s|\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/,
  postalCode,
  lettersOnly: /^[A-Za-zÀ-öù-žĀ-ƿǍ-ǰǴ-ɿ]*$/,
  lettersHyphensSpacesApostropheOnly: /^[A-Za-zÀ-öù-žĀ-ƿǍ-ǰǴ-ɿ\s-']*$/,
  lettersDotsOnly: /^[A-Za-zÀ-öù-žĀ-ƿǍ-ǰǴ-ɿ.]*$/,
  phoneNumberInternational: /^(\+)?[0-9]*$/,
  email: /^(?=.{1,64}@)[A-Za-z0-9+_-]+(\.[A-Za-z0-9+_-]+)*@[^-][A-Za-z0-9+-]+(\.[A-Za-z0-9+-]+)*(\.[A-Za-z]{2,})$/,
});
