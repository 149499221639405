import { isNumber, noop } from 'lodash';
import { cronValidDaysOfWeek } from './constants';
export {
  numberIsInRange,
  cronNumericListToArray,
  everyNumericListMemberIsValid,
  isValidStepExpression,
  isValidNumericRange,
  isValidWeekdaysRange,
};

/**
 * Parses a string to a number if necessary
 * @param {String|Number} v
 */
const strToNum = v => isNumber(v) ? v : parseInt(v, 10);

/**
 * Tests whether a number is in a range
 * @param {Number|String} num
 */
function numberIsInRange(num, { from, to }) {
  const p = strToNum(num);
  return (p >= from && p <= to);
}

/**
 * Convert a cron value list into an array of numeric values
 * @param {String} arrayString
 */
function cronNumericListToArray(arrayString) {
  return arrayString.split(',').map(v => parseInt(v, 10));
}

/**
 * Verifies every element of a numeric cron list is valid against the validator function
 * @param {Function} validator
 */
function everyNumericListMemberIsValid(validator) {
  return list => {
    return cronNumericListToArray(list)
      .every(v => validator(v));
  };
}

/**
 * Validate a cron step expression
 * @param {String} stepExpression
 * @param {Boolean} allowAnyStart
 * @param {Function} startValidator
 * @param {Function} stepValidator
 */
function isValidStepExpression(
  stepExpression,
  allowAnyStart = true,
  numericValueValidator = noop
) {

  const [start, step] = stepExpression.split('/');

  // validate the start of the stepping since it could be *
  let startValid = false;
  if (start === '*' && allowAnyStart) {
    startValid = true;
  }
  else {
    startValid = numericValueValidator(parseInt(start, 10));
  }

  // validate the stepping value
  let stepValid = false;
  const stepNum = parseInt(step, 10);
  if (stepNum > 0 && numericValueValidator(stepNum)) {
    stepValid = true;
  }

  return !!(startValid && stepValid);

}

/**
 * Checks whether a numeric range cron expression is valid
 * Start must be less than or equal to the end of the range
 * @param {Number} range
 * @param {Function} numberValueValidator
 */
function isValidNumericRange(range, numberValueValidator = () => true) {
  const [start, end] = range.split('-');
  const startVal = parseInt(start, 10);
  const endVal = parseInt(end, 10);
  return !!(
    startVal <= endVal &&
    numberValueValidator(startVal) &&
    numberValueValidator(endVal)
  );
}

/**
 * Checks whether a range of weekdays in a cron expression is valid.
 * @param {String} range
 */
function isValidWeekdaysRange(range) {
  const [dayStart, dayEnd] = range.split('-');

  const dayStartIdx = cronValidDaysOfWeek.indexOf(dayStart);
  const dayEndIdx = cronValidDaysOfWeek.indexOf(dayEnd);

  return !!(
    dayStartIdx >= 0 && dayEndIdx >= 0 &&
    dayStartIdx <= dayEndIdx
  );

}
