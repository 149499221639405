export const ecg = {
  ecg: 'HartWacht ECG',
  ecgShort: 'ECG',
  ecgHistory: 'ECG geschiedenis',
  heartRateShort: 'HS',
  duration: 'Looptijd',
  downloadYourEcgAsAPdf: 'Download uw ECG als pdf',
  errorLoadingEcg: 'Fout bij laden van ecg',
  conclusion: 'Conclusie',
  conclusionBy: 'Conclusie Door',
  interpretation: 'Interpretatie',
  contact: 'Contact',
  note: 'Notitie',
  patientNote: 'Patiënt notitie',
  particularities: 'Bijzonderheden',
  selectPeriod: 'Selecteer een periode',
  comment: 'Commentaar',
  selectAnOption: 'Kies een optie',
  contactedVia: 'Contact opgenomen via',
  advice: 'Advies',
};
