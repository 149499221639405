import { isEqual } from 'lodash';

// gets an item by path
export function getItemByPath(path, items) {
  if (!path || !items) {
    return null;
  }

  let value = null;
  let searchTree = item => {
    if (isEqual(item.path, path)) {
      return value = item;
    }
    else if (item && item.children && item.children.length > 0) {
      item.children.forEach(child => searchTree(child));
    }
  };

  items.some(x => true === searchTree(x));

  return value;
}

export function allDescendantsChecked(item, listOfCheckedItems) {
  if (!item || !listOfCheckedItems) {
    return false;
  }

  const allDescendantsByItem = allDescendants(item);
  return allDescendantsByItem.every(child => isItemChecked(child.path, listOfCheckedItems) === true);
}

export function allDescendants(item) {
  if (!item || !item.children) {
    return [];
  }

  let nodes = [];
  let searchSubTree = item => {
    nodes.push(item);
    if (item.children && item.children.length > 0) {
      item.children.forEach(child => searchSubTree(child));
    }
  };

  searchSubTree(item);
  // remove the first element (the root), return only the descendants
  return nodes.slice(1);
}

export function isItemCheckMarked(item, items) {
  if (item?.children?.length) {
    return isItemChecked(item.path, items) || allDescendantsChecked(item, items);
  }

  return isItemChecked(item.path, items);
}

export function isItemChecked(item, items) {
  if (!item || !items) {
    return false;
  }

  return items.some(a => isEqual(item, a));
}

export const select = {
  setSelection(data, selection, allItems, isChild, isParentChecked, selectedByChild, multiple) {
    if (!select.areSelectedPropsValid(data, selection, allItems)) {
      return;
    }

    const item = data.path;
    // Item is checked if it's not already in the selected items list
    let checked = !isItemChecked(item, selection);
    let checkParent = false;

    // Single select logic
    if (multiple === false) {
      selection.splice(0, selection.length);
      selection.push(checked ? item : [allItems[0].id]);
      return;
    }

    // Multiple select logic
    if (checked) {
      if ((isChild && isParentChecked) || (isChild === undefined && isParentChecked === undefined)) {
        selection.push(item);
        checked = true;
        checkParent = true;
      }
    }
    else {
      const index = selection.findIndex(a => isEqual(item, a));
      if (index !== -1 && ((isChild && !isParentChecked) || (isChild === undefined && isParentChecked === undefined))) {
        selection.splice(index, 1);
        checked = false;
        checkParent = true;
      }
    }

    if (data.children && data.children.length > 0 && !selectedByChild) {
      data.children.forEach(x => select.setSelection(x, selection, allItems, true, checked || isParentChecked));
    }

    // it's necessary to check parent after children
    if (checkParent && isChild === undefined && isParentChecked === undefined) {
      select.checkOrUncheckParent(data, selection, allItems);
    }
  },

  // Check parent if all subitems are selected or uncheck if at least one subitem is unselected
  checkOrUncheckParent(data, selection, allItems) {
    if (!select.areSelectedPropsValid(data, selection, allItems)) {
      return;
    }

    if (data.path.length > 1) {
      let parentPath = [...data.path].slice(0, -1);
      const value = getItemByPath(parentPath, allItems);
      if (allDescendantsChecked(value, selection)) {
        return select.setSelection(value, selection, allItems, undefined, undefined, true);
      }
      else if (isItemChecked(value.path, selection)) {
        return select.setSelection(value, selection, allItems, undefined, undefined, true);
      }
    }
  },

  areSelectedPropsValid(data, selection, allItems) {
    if (!data || !selection || !allItems ||
        !data.path || !Array.isArray(selection) || !Array.isArray(allItems)) {
      return;
    }

    return true;
  }
};

export function convertToTreeviewStructure(data, type) {
  return data && data[type] && [mapTreeviewItem(data[type], [])] || [];
}

function mapTreeviewItem(item, path) {
  const itemPath = [...path, item.id];
  return {
    ...item,
    path: itemPath,
    children: item.children?.map(child => mapTreeviewItem(child, itemPath))
  };
}
