import { CallCenterUserModel } from '../../../model/callCenterUserModel';
import { CrudService } from '../../../classes/crudService';
import { PageModel } from '../../../model/page';

export const callCenterUsers = new CrudService(
  '/call-center/users',
  data => new CallCenterUserModel(data)
);

export const callCenterUsersPage = new CrudService(
  '/call-center/users',
  data => new PageModel(data, model => new CallCenterUserModel(model))
);

export const currentCallCenterUser = new CrudService(
  '/call-center/users/currentUser',
  data => new CallCenterUserModel(data)
);

export const callCenterSupportUsers = new CrudService(
  '/call-center/users/support-users',
  data => new CallCenterUserModel(data)
);

export async function addUserRole(userId, role) {
  return new CrudService(`/call-center/users/${ userId }/roles/${ role }`).updateOne({});
}

export async function removeUserRole(userId, role) {
  return new CrudService(`/call-center/users/${ userId }/roles/${ role }`).deleteOne({});
}

export async function resetUserPassword(userId) {
  return new CrudService(`/call-center/users/${ userId }/reset-password`).create();
}

/**
 * Send a request for a new activation email
 * @param {string} userUuid
 */
export async function sendCallcenterUserActivationEmail(userUuid) {
  return await new CrudService(`call-center/users/${ userUuid }/activation-links`).create();
}
