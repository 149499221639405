export const Severity = Object.freeze({
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  INFO: 'INFO',
  UNCLASSIFIED: 'UNCLASSIFIED'
});

export const severityIconColorMap = new Map([
  [Severity.MEDIUM, 'rgb(255,162,2)'],
  [Severity.HIGH, 'rgb(232,4,4)'],
  [Severity.LOW, 'rgb(13,193,112)'],
  [Severity.INFO, '#0c76f5'],
  [Severity.UNCLASSIFIED, 'rgb(155,155,155)']
]);

export const severityOptions = Object.keys(Severity).map(severity => ({
  label: `tasks.${ severity.toLowerCase() }`,
  value: severity
}));

export const defaultChosenSeverityForTasks = [Severity.HIGH, Severity.MEDIUM, Severity.UNCLASSIFIED];
export const severityForTasks = [Severity.HIGH, Severity.MEDIUM, Severity.LOW, Severity.UNCLASSIFIED];

export const severityOptionsForTasks = severityForTasks.map(severity => ({
  label: `tasks.${ severity.toLowerCase() }`,
  value: severity
}));

export const OrderedSeverities = Object.freeze([
  Severity.UNCLASSIFIED,
  Severity.INFO,
  Severity.LOW,
  Severity.MEDIUM,
  Severity.HIGH,
]);
