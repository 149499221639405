import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';

export const mutations = {
  [types.SET_FETCHED](state, value) {
    state.fetched = value;
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_MEDICATIONS](state, medications) {
    state.medications = medications;
  },

  [types.INIT_ADD_POPUP](state, { popupId }) {
    state.popupId = popupId;
    state.medication = createInputConfig({
      id: 'medication',
      type: InputType.Textarea,
      required: true,
      value: null,
      label: 'patient.newMedication',
    });
  },

  [types.INIT_EDIT_POPUP](state, { popupId, value }) {
    state.popupId = popupId;
    state.medication = createInputConfig({
      id: 'editMedication',
      type: InputType.Textarea,
      required: true,
      value: value,
      label: 'patient.editMedication',
    });
  },

  [types.SET_EDITABLE_MEDICATION_UUID](state, value) {
    state.editableMedicationUuid = value;
  },

  [types.SET_PATIENT_USER_UUID](state, value) {
    state.patientUserUuid = value;
  },
};
