<template>
  <div :class="[styles.container, styles.stickyHeader]">
    <div :class="styles.title">
      {{ $localize($props.label) }}
    </div>

    <div
      v-if="hasSlot('actionButtons')"
      :class="[styles.actionButtons, styles.stickyButtons]"
    >
      <div :class="styles.stickyButtons">
        <slot name="actionButtons"/>
      </div>
    </div>
    <slot name="filter"/>
  </div>
</template>

<script>
  import { hasSlot } from '@h4h/utils';

  import styles from './heading.scss';

  export default {
    name: 'H4hHeading',

    props: {
      label: {
        type: [String, Array],
        required: false
      },

      search: Boolean,
      query: String
    },

    data() {
      return {
        styles,
        hasSlot
      };
    },
  };
</script>
