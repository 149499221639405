import { types } from './types';
import { observationTemplates, observationTemplateUpdatable } from '../../../services/api/observations/observationTemplates';
import { showRestError } from '../../../utils/errors';

export const actions = Object.freeze({
  initForm,
});

async function initForm({ commit }, { uuid, terminologyTypes }) {
  commit(types.CLEAR_FORM);
  commit(types.SET_LOADING, true);
  let observationTemplate = await observationTemplates.get(uuid);
  if (observationTemplate.success) {
    commit(types.INIT_FORM, { observationTemplate: observationTemplate.data, terminologyTypes });
  }
  else {
    showRestError(observationTemplate.error, 'messages.cantFetchObservationTemplate');
  }

  let updatable = await observationTemplateUpdatable(uuid);
  if (updatable.success) {
    commit(types.SET_UPDATABLE, updatable.data.updatable);
  }
  else {
    showRestError(updatable.error, 'messages.cantFetchObservationTemplateInfo');
  }
  commit(types.SET_LOADING, false);
}
