import { types } from './types';
import { QuestionnaireWrapper } from '../../../../../../components/questionnaire/wrapper/questionnaireWrapper';
import { questionnaires } from '../../../../../../services/api/questionnaire/questionnaire';
import { showRestError } from '../../../../../../utils/errors';
import { QuestionnaireModel } from '../../../../../../model/anamnesis/questionnaireModel';
import { toast } from '../../../../../../services/toast';
import { localize } from '../../../../../../services/localize';

export const actions = Object.freeze({
  init,
  save,
});

async function init({ commit }, { questionnaireTemplate, questionnaireUuid, terminologyTypes }) {
  commit(types.SET_QUESTIONNAIRE, null);
  commit(types.SET_LOADING_QUESTIONNAIRE, true);
  const result = await questionnaires.get(questionnaireUuid);
  commit(types.SET_LOADING_QUESTIONNAIRE, false);
  if (!result.success) {
    showRestError(result.error, 'messages.cantFetchQuestionnaire');
    return;
  }
  commit(types.INIT_FORM, {
    questionnaireTemplate,
    questionnaire: result.data,
    terminologyTypes
  });
}

// save functionality is temporary disabled since it isn't implemented on backend
async function save({ commit, state, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.valid) {
    return null;
  }

  const questionnaire = new QuestionnaireModel(state.questionnaire);
  new QuestionnaireWrapper(state.questionnaireTemplate, questionnaire).updateValuesFromInputs(state.inputs);

  commit(types.SET_LOADING, true);
  const result = await questionnaires.update(state.questionnaire.uuid, questionnaire);
  commit(types.SET_LOADING, false);
  if (result.success) {
    toast.success(localize('messages.questionnaireUpdated'));
  }
  else {
    showRestError(result.error, 'messages.cantUpdateAnamnesis');
  }
  return result.success;
}

