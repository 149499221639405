export const DropzoneMixins = {
  mounted() {
    document.addEventListener('dragstart', this.setDragTarget);
    document.addEventListener('dragend', this.resetDragTarget);
  },
  beforeDestroy() {
    document.removeEventListener('dragstart', this.setDragTarget);
    document.removeEventListener('dragend', this.resetDragTarget);
  },
  methods: {
    setDragTarget(e) {
      this.target = e.target;
      const element = this.$refs.container;
      if (!element.contains(this.target)) {
        this.showZones = true;
      }
    },
    resetDragTarget() {
      this.target = null;
      this.showZones = false;
    },
    setActive() {
      if (this.disabled) {
        return;
      }
      const element = this.$refs.container;
      if (!element.contains(this.target)) {
        this.active = true;
      }
    },
    setInactive(e) {
      const element = this.$refs.container;
      if (!element.contains(e.relatedTarget)) {
        this.active = false;
      }
    },
    onDrop(e) {
      this.$emit('dropFinish', e);
      this.active = false;
      this.showZones = false;
      if (!this.disabled) {
        this.$emit('dropContent', e.dataTransfer);
        this.$emit('dropElement', this.target);
      }
    },
  }
};
