import { types } from './types';

export const mutations = {
  [types.SET_IFRAME_MODE](state, value) {
    state.iframeMode = value;
  },
  [types.SET_PATIENT_NOT_FOUND](state, value) {
    state.patientNotFound = value;
  },
};
