import { ProgramInputGroupModel } from '../../../../../../model/monitoringPeriodModel';

export const observationCommentFieldName = 'observationComment';

export class ObservationTemplateInputGroup {
  /**
   * @param {Object}                          config
   * @param {Object[]}                        config.inputs
   * @param {ObservationTemplateModel}        config.observationTemplate
   * @param {QuestionnaireTemplateModel}      config.questionnaireTemplate
   */
  constructor(config) {
    this.inputs = config.inputs;
    this.observationTemplate = config.observationTemplate;
    this.questionnaireTemplate = config.questionnaireTemplate;
    this.id = '' + Math.random();
  }

  get name() {
    if (this.observationTemplate) {
      return this.observationTemplate.name;
    }
    if (this.questionnaireTemplate) {
      return this.questionnaireTemplate.title;
    }
    return '';
  }
}

export class GroupedProgramInputInfo {
  /**
   * @param {ProgramInputGroupModel[]} groups
   * @param {Boolean} hasProgramInputWithoutGroups
   */
  constructor(groups, hasProgramInputWithoutGroups) {
    this.groups = groups;
    this.hasProgramInputWithoutGroups = hasProgramInputWithoutGroups;
  }

  isNeedShowChooseGroupDialog() {
    return (this.groups.length > 1) || (this.groups.length > 0 && this.hasProgramInputWithoutGroups);
  }
}

export class GroupedProgramInputType {
  /**
   *
   * @param {Object} config
   * @param {String} config.arrayField
   * @param {String} config.keyField
   */
  constructor(config) {
    this.arrayField = config.arrayField;
    this.keyField = config.keyField;
  }
}

export const GroupedProgramInputTypes = Object.freeze({
  OBSERVATIONS: new GroupedProgramInputType({ arrayField: 'observations', keyField: 'observationTemplateId' }),
  QUESTIONNAIRE: new GroupedProgramInputType({ arrayField: 'questionnaires', keyField: 'questionnaireTemplateId' }),
});

/**
 *
 * @param {Object} templateModel
 * @param {EnrollmentModel} enrollmentModel
 * @param {GroupedProgramInputType} target
 * @return GroupedProgramInputInfo
 */
export function getGroupedProgramInputInfo(templateModel, enrollmentModel, target) {
  const empty = new GroupedProgramInputInfo([]);
  if (!(enrollmentModel.monitoringPeriod && enrollmentModel.monitoringPeriod.templateLink)) {
    return empty;
  }
  const monitoringPeriodUuid = enrollmentModel.monitoringPeriod.templateLink.uuid;
  if (!(enrollmentModel.program && Array.isArray(enrollmentModel.program.availablePeriods))) {
    return empty;
  }
  const monitoringPeriodModel = enrollmentModel.program.availablePeriods.find(p => p.uuid === monitoringPeriodUuid);
  if (!monitoringPeriodModel) {
    return empty;
  }
  let hasProgramInputWithoutGroups = false;
  const allGroups = new Map();
  monitoringPeriodModel[target.arrayField].forEach(mpo => {
    mpo.groups.forEach(group => {
      if (!allGroups.has(group.uuid)) {
        const programInputGroupModel = new ProgramInputGroupModel(group);
        programInputGroupModel.programInputMap = new Map();
        allGroups.set(group.uuid, programInputGroupModel);
      }
      allGroups.get(group.uuid).programInputMap.set(mpo[target.keyField], mpo);
    });
    // check for situation when one mpo has groups and other - not. And it points to the same template
    if (mpo[target.keyField] === templateModel.uuid) {
      if (!mpo.groups.length) {
        hasProgramInputWithoutGroups = true;
      }
    }
  });
  const thisTemplateGroups = Array.from(allGroups.values())
    .filter(group => group.programInputMap.has(templateModel.uuid));
  thisTemplateGroups.forEach(group => group.programInputs = Array.from(group.programInputMap.values()));
  return new GroupedProgramInputInfo(thisTemplateGroups, hasProgramInputWithoutGroups);
}

