import { types } from './types';
import { getGroupedProgramInputInfo, GroupedProgramInputTypes } from '../../../observation/popup/utils';
import { router } from '../../../../../../../services/router';
import { Page } from '../../../../../../../constants/page';
import { encodeTemplatesGuids } from './utils';

export const actions = Object.freeze({
  init,
  chooseQuestionnaireGroup,
  chooseQuestionnaireWithoutGroup,
});

async function init({ commit }, { questionnaireTemplate, enrollment }) {
  const groupedObservationsInfo = getGroupedProgramInputInfo(questionnaireTemplate, enrollment, GroupedProgramInputTypes.QUESTIONNAIRE);
  if (groupedObservationsInfo.isNeedShowChooseGroupDialog()) {
    commit(types.INIT_FORM, {
      questionnaireGroups: groupedObservationsInfo.groups,
      hasProgramInputWithoutGroups: groupedObservationsInfo.hasProgramInputWithoutGroups,
    });
    return;
  }
  let group = groupedObservationsInfo.groups[0];
  if (group && group.programInputs.length < 2) {
    group = null;
  }
  if (group) {
    chooseQuestionnaireGroup({}, group);
  }
  else {
    chooseQuestionnaireWithoutGroup();
  }
}

function chooseQuestionnaireGroup(_, group) {
  if (group.programInputs.length < 2) {
    chooseQuestionnaireWithoutGroup();
    return;
  }
  router.push({
    name: Page.PractitionerPatientEnrollmentsViewQuestionnaireAddMultiple,
    params: { questionnaireTemplateUuids: encodeTemplatesGuids(group) }
  });
}

function chooseQuestionnaireWithoutGroup() {
  router.push({ name: Page.PractitionerPatientEnrollmentsViewQuestionnaireAddSingle });
}

