<template>
  <div
    :class="[
      inputStyles.container,
      $props.inline && inputStyles.inline
    ]"
  >
    <Radio
      v-if="showKind"
      v-bind="addressKind"
      :groupId="$props.groupId"
      :pristine="pristine && addressKind.pristine"
      :valid="addressKind.valid || valid"
      :disabled="$props.disabled || addressKind.disabled"
      :options="addressKindOptions"
      :class="inputStyles.radio"
      :data-testid="`field-${$props.label}`"
      @change="onAddressKindChange({ input: addressKind, value: $event })"
    />

    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />

    <RegularAddress
      v-if="isRegular"
      v-bind="$props"

      @change="onChange"
    />

    <PoBoxAddress
      v-else
      v-bind="$props"

      @change="onChange"
    />
  </div>
</template>

<script>
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';

  import { InputMixin } from '../../mixins';
  import { AddressModel, PoBoxAddressModel } from '../../models';
  import { addressProps, createInputConfig, inputProps, setInputValue } from '../../utils';
  import { AddressType, addressTypeOptions, AddressKind, addressKindOptions, InputType } from '../../constants';

  import Radio from '../radio/Radio';
  import LabelGroup from '../labelGroup/LabelGroup';

  import PoBoxAddress from './PoBoxAddress';
  import RegularAddress from './RegularAddress';

  import styles from './address.scss';

  export default {
    name: 'H4hAddress',

    components: {
      Radio,
      LabelGroup,
      PoBoxAddress,
      RegularAddress,
    },

    mixins: [
      InputMixin
    ],

    props: {
      value: addressProps.value,
      customizations: addressProps.customizations,
      countryOptions: addressProps.countryOptions,
      defaultCountryCode: addressProps.defaultCountryCode,

      patterns: {
        type: Object,
        required: false,
        default: () => ({})
      },

      addressTypeOptions: {
        type: Array,
        default: () => addressTypeOptions
      },

      /** labels */
      info: inputProps.label,
      otherLabel: inputProps.label,

      /** flags */
      inline: inputProps.booleanFalse,
      showType: inputProps.booleanFalse,
      showKind: inputProps.booleanFalse,
      required: inputProps.booleanFalse
    },

    data: function() {
      return {
        inputStyles,
        styles,
        AddressType,
        AddressKind,
        addressKindOptions,
        addressKind: createInputConfig({
          id: 'addressKind',
          type: InputType.Radio,
          value: this.$props.value && this.$props.value.addressKind || AddressKind.Regular,
          inline: true,
          tabindex: this.$props.tabindex + 0.8,
          options: addressKindOptions,
          ...this.$props.customizations.addressKind,
        }),
        addressModel: this.$props.value || new AddressModel({}),
      };
    },

    computed: {
      isRegular() {
        return this.addressKind.value === AddressKind.Regular;
      }
    },

    methods: {
      onChange(addressModel) {
        this.$emit('change', addressModel.isEmpty() ? null : addressModel);
      },

      onAddressKindChange({ input, value }) {
        setInputValue({ input, value });

        this.addressModel = new (value === AddressKind.Regular ? AddressModel : PoBoxAddressModel)(this.addressModel);
        this.onChange(this.addressModel);
      }
    }
  };
</script>
