import { settings } from './settings';
import { countryCode } from './countryCode';

export const i18n = {
  namespaced: true,
  modules: {
    settings,
    countryCode,
  }
};
