export const patient = {
  addPatient: 'Patiënt toevoegen',
  patientDetails: 'Patiëntgegevens',
  patients: 'Patiënten',
  patient: 'Patiënt',
  patientOverview: 'Patiënt overzicht',
  showArchivedPatients: 'Toon gearchiveerde patiënten',
  restorePatient: 'Herstel patiënt',
  areYouSureYouWantToRestoreThisPatient: 'Weet u zeker dat u deze patiënt wilt herstellen?',
  filterText: 'Zoek patiënt',
  bloodPressure: 'Bloeddruk',
  patientFile: 'Patient Dossier',
  diagnose: 'Diagnose',
  medicalHistories: 'Medische geschiedenissen',
  medicalHistory: 'Medische voorgeschiedenis',
  addMedicalHistory: 'Voeg medische geschiedenis toe',
  newMedicalHistory: 'Nieuwe medische geschiedenis',
  editMedicalHistory: 'Bewerk de medische geschiedenis',
  addNewMedicalHistory: 'Voeg nieuwe medische geschiedenis toe',
  allergy: 'Allergie',
  allergies: 'Allergieën',
  addAllergy: 'Allergie toevoegen',
  newAllergy: 'Nieuwe allergie',
  editAllergy: 'Allergie bewerken',
  addNewAllergy: 'Voeg nieuwe allergie toe',
  medication: 'Medicatie',
  medications: 'Medicaties',
  addMedication: 'Medicatie toevoegen',
  newMedication: 'Nieuwe medicatie',
  editMedication: 'Medicatie bewerken',
  addNewMedication: 'Voeg nieuwe medicatie toe',
  responsiblePhysician: 'Behandelend arts',
  responsiblePhysicianAlarmNumber: 'Behandelend arts contactnummer',
  responsiblePhysicianEmail: 'Behandelend arts e-mailadres',
  noResponsiblePhysician: 'Geen Behandelend arts gekozen',
  patientInfo: 'Patiënt info',
  partnerInfo: 'Partner info',
  treatingPhysician: 'Behandelend arts',
  phoneNumberPhysician: 'Telefoonnummer arts',
  patientSince: 'Patiënt sinds',
  addMedicalHistoryHeading: 'Medische geschiedenis toevoegen',
  editMedicalHistoryHeading: 'Medische geschiedenis aanpassen',
  editAllergyHeading: 'Allergie aanpassen',
  editMedicationHeading: 'Medicatie aanpassen',
  ssn: 'Burgerservicenummer',
  // missingAddressWarning: 'Let op: Zonder adres is het niet mogelijk om meetapparatuur te versturen naar de patiënt.',
  onlyDutchPhonesAreSupportedForSms: 'Op dit moment is het alleen mogelijk om met Nederlandse telefoonnummers de Heart for Health app te gebruiken.',
  patientNumber: 'Patiënt nummer',
};
