export const state = {
  loading: false,
  observationTemplate: null,
  popupId: null,
  date: null,
  inputGroups: [],
  additionalInputs: [],
  originalObservationDate: null,

  observationGroups: [],
  observationGroupsPopupsId: null,
  hasProgramInputWithoutGroups: false,
  monitoringPeriodStartDate: null,
  enrollment: null,
};
