import { TaskListModel } from '../model/taskListModel';
import { Severity, severityOptions } from '../constants/severity';
import { TaskListTypes } from '../constants/taskListTypes';
import { TableId } from '../constants/tableId';

/**
 *
 * @param {String} type
 * @returns {null|TaskListModel}
 * @constructor
 */
export function TaskListTypesModelFactory(type) {
  switch (type) {
    case TaskListTypes.Default:
      return new TaskListModel({
        type,
        userFiltersTableId: TableId.Tasks,
        usesCheckboxes: true,
        staticInputValues: {
          messageCodes: new Set([]),
        },
      });
    case TaskListTypes.Informational:
      return new TaskListModel({
        type,
        userFiltersTableId: TableId.InfoTasks,
        disableItemsPerPage: false,
        usesCheckboxes: true,
        disabledColumns: ['severity','terminologyType'],
        staticInputValues: {
          severity: new Set([Severity.INFO]),
          terminologyType: new Set([]),
        },
        // TODO pass in the default static table options data
      });
    case TaskListTypes.Logistic:
      return new TaskListModel({
        type,
        userFiltersTableId: TableId.LogisticTasks,
        usesCheckboxes: false,
        staticInputValues: {
          terminologyType: new Set([]),
          forwarded: null,
        },
        inputExtensions: {
          severity: {
            options: severityOptions
          },
        }
      });
    default:
      return null;
  }
}
