import { mapArray } from '../utils/mapUtils';

const timeZoneRegex = /[\w\d\s]*\(([+-]\d\d:\d\d)\)/;
const offsetRegex = /([+-])(\d\d):(\d\d)/;

export class TimezoneModel {
  /**
   * TimezoneModel config
   *
   * @param {Object}      config
   * @param {String}      config.name
   * @param {String}      config.displayName
   */
  constructor(config) {
    config = config || {};
    this.name = config.name;
    this.displayName = config.displayName;
  }

  get offsetInMinutes() {
    if (this.displayName && timeZoneRegex.test(this.displayName)) {
      const match = this.displayName.match(timeZoneRegex);
      if (match.length > 1) {
        const offset = match[1];
        const [, signSymbol, hours, minutes] = offset.match(offsetRegex);
        if (signSymbol && hours && minutes) {
          const sign = signSymbol === '+' ? -1 : 1; // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
          return sign * ((+hours) * 60 + +minutes);
        }
      }
    }
    return 0;
  }
}

export class TimezoneListModel {
  /**
   * TimezoneListModel config
   *
   * @param {Object}      config
   * @param {TimezoneModel} config.availableTimeZones
   */
  constructor(config) {
    config = config || {};
    this.availableTimeZones = mapArray(config.availableTimeZones, TimezoneModel);
  }
}
