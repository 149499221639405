<template>
  <thead>
    <tr>
      <th
        v-for="(header, index) in $props.headers"
        :key="header.text + index"
        :width="header.width"
        :class="[styles.headerCell, header.value === 'actions' && styles.actionsHeader]"
      >
        <slot
          :name="createHeaderSlotName(header)"
          v-bind="{ header, index }"
        >
          <Checkbox
            v-if="header.value === '_select' && $props.showSelect"
            name="select-all"
            :value="!!$props.allRowsSelected"
            :indeterminate="!!$props.someRowsSelected"
            :title="$localize('common.selectAll')"
            :class="styles.checkbox"
            @change="onCheckboxChange"
          />

          <ColumnHeader
            v-if="header.value !== 'actions' && header.text"
            :width="header.width"
            :label="header.text"
            :desc="header.desc"
            :align="header.align"
            :tooltip="header.tooltip"
            :actions="header.actions"
            :subLabel="header.subLabel"
            :isSortedBy="header.isSortedBy"
            :sortable="$props.sortingEnabled && header.sortable"
            @changeColumnSorting="onChangeColumnSorting(header)"
          />
        </slot>
      </th>
    </tr>
  </thead>
</template>

<script>
  import { Checkbox } from '@h4h/inputs';

  import { HEADER_SLOT_NAME_PREFIX } from '../../utils/slotUtils';

  import ColumnHeader from '../columnHeader/ColumnHeader';

  import styles from './tableHeader.scss';

  export default {
    name: 'H4hTableHeader',

    components: {
      ColumnHeader,
      Checkbox
    },

    props: {
      headers: {
        type: Array,
        required: true
      },

      allRowsSelected: {
        type: Boolean,
        required: true
      },

      someRowsSelected: {
        type: Boolean,
        required: true
      },

      sortingEnabled: {
        type: Boolean,
        default: true
      },

      showSelect: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        styles
      };
    },

    methods: {
      createHeaderSlotName(column) {
        return HEADER_SLOT_NAME_PREFIX + column.value;
      },

      onChangeColumnSorting(column) {
        this.$emit('changeColumnSorting', column);
      },

      onCheckboxChange() {
        this.$emit('checkboxChange');
      }
    }
  };
</script>
