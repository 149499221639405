import { has } from 'lodash';

export class FeatureConfig {
  constructor(config) {
    this.config = config;
  }

  getValue(key) {
    if (has(this.config, key)) {
      return this.config[key];
    }

    return null;
  }

  setValue(key, value) {
    if (Object.prototype.hasOwnProperty.call(this.config, key)) {
      this.config[key] = value;
    }
    else {
      console.error(`Feature Config: Trying to set value ${ value } to non existing key ${ key }`);
    }
  }
}
