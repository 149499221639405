import { isDate, isEmpty, isObject } from 'lodash';

import { isClientSideTableSearchInput } from '@h4h/inputs';

export function getInputEmptyValue(value) {
  if (value instanceof Set) {
    return new Set();
  }

  return null;
}

export function isFilledInputValue(value) {
  if (isDate(value)) {
    return true;
  }

  return isObject(value)
    ? !isEmpty(value)
    : !!value;
}

export function getClientSideTableSearchInput(filterInputs) {
  return filterInputs?.find(isClientSideTableSearchInput);
}
