import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';

export const mutations = {
  [types.SET_POPUP_ID](state, popupId) {
    state.popupId = popupId;
  },

  [types.INIT_POPUP](state) {
    const { subject, text } = getPopupInputsConfig();
    state.organizationSetting = null;
    state.subject = subject;
    state.text = text;
  },

  [types.UPDATE_EMAIL_TEMPLATE](state, template) {
    state.subject.value = template.emailSubject;
    state.text.value = template.emailText;
    state.baseUrl = template.baseUrl;
  },

  [types.SET_ORGANIZATION_SETTING](state, organizationSetting) {
    state.organizationSetting = organizationSetting;
  },

  [types.SET_PHYSICIAN](state, physicianId) {
    state.physicianId = physicianId;
  },

  [types.SET_FORWARDING_IN_PROGRESS](state, value) {
    state.forwardingInProgress = value;
  },

  [types.SET_CALL_CENTER_BASE_URL](state, value) {
    state.callCenterBaseUrl = value;
  },

  [types.SET_CHOOSE_TASK_POPUP_ID](state, value) {
    state.chooseTaskPopupId = value;
  },
};

function getPopupInputsConfig() {
  return {
    subject: createInputConfig({
      id: 'subject',
      type: InputType.Text,
      placeholder: 'practitionerTasks.subject',
      disabled: true,
      label: 'common.subject',
    }),
    text: createInputConfig({
      id: 'text',
      type: InputType.Textarea,
      placeholder: 'practitionerTasks.text',
      disabled: true,
      label: 'common.text',
    }),
  };
}

