import { tryRequest, compilePath } from '@h4h/utils';
import { axios } from '../../../../apps/medical-portal/src/services/axios';

import { Pagination } from './pagination';

export class CrudService {
  /**
   * / suffix was removed from url name in path because of keycloak resource configurations.
   * Now every API from BE is a separate keycloak resourse and none of the resources end with / as suffix.
   * If we don’t remove suffix / if user tries to access some api keycloak will return 403 error (access forbidden).
   * / is needed only when we concatenate some path part to this.path() for example () => axios.put(this.path() + '/lab', labValues.map(OrderItem.toApi))
   *
   * @param {String}    endpointPath
   * @param {Function}  [dataMapper]  - map data received from the API
   * @param {Function}  [modelMapper] - map data sent to API
   * @param {Object}    [options] - crud service options
   * @param {Boolean}   [options.withPagination] - enable pagination mappers in fetch request
   * @param {Array}     [options.columns] - table columns using for sorting and filtering
   */
  constructor(
    endpointPath,
    dataMapper = data => data,
    modelMapper = model => model,
    options = {},
  ) {
    this.path = compilePath(endpointPath);
    this.dataMapper = dataMapper;
    this.modelMapper = modelMapper;
    this.options = options;
  }

  async fetch(urlParams, searchParams, paginationParams) {
    const pagination = this.options.withPagination
      ? Pagination.toApi(paginationParams)
      : null;

    return tryRequest(
      () => axios.get(
        this.path(urlParams),
        {
          params: {
            ...searchParams,
            ...pagination
          }
        }
      ),
      data => {
        if (this.options.withPagination) {
          return {
            content: data.content.map(this.dataMapper),
            pagination: Pagination.fromApi(data),
          };
        }
        else if (data.content) {
          return data.content.map(this.dataMapper);
        }
        else if (Array.isArray(data)) {
          return data.map(this.dataMapper);
        }
        else {
          return this.dataMapper(data);
        }
      }
    );
  }

  async get(id, urlParams) {
    const path = id ? this.path(urlParams) + '/' + id : this.path(urlParams);
    return tryRequest(
      () => axios.get(path),
      this.dataMapper
    );
  }

  async create(model, urlParams) {
    return tryRequest(
      () => axios.post(this.path(urlParams), this.modelMapper(model)),
      this.dataMapper
    );
  }

  async update(id, model, urlParams) {
    const path = id ? this.path(urlParams) + '/' + id : this.path(urlParams);
    return tryRequest(
      () => axios.put(path, this.modelMapper(model)),
      this.dataMapper
    );
  }

  async delete(id, urlParams) {
    const path = id ? this.path(urlParams) + '/' + id : this.path(urlParams);

    return tryRequest(
      () => axios.delete(path)
    );
  }
}
