import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { router } from '../../../services/router';
import { Page } from '../../../constants/page';
import { logisticPatients } from '../../../services/api/patients/logisticPatients';
import { fetchEnrollments } from '../../../services/api/enrollments/enrollments';
import { EnrollmentStatus } from '../../../constants/enrollmentStatus';

export const actions = Object.freeze({
  loadPatient,
});

async function loadPatient({ commit }, id) {
  commit(types.SET_PATIENT, null);
  commit(types.SET_ENROLLMENTS, []);
  commit(types.SET_LOADING, true);
  try {
    const result = await logisticPatients.get(id);
    if (result.success) {
      commit(types.SET_PATIENT, result.data);
    }
    else {
      showRestError(result.error, 'messages.cantLoadPatient');
      router.push({ name: Page.CallCenterLogisticUserPatientsList });
      return false;
    }
    const { data, error } = await fetchEnrollments(id);
    if (error) {
      showRestError(error, 'messages.cantFetchEnrollments');
      return false;
    }
    const enrollments = data
      .filter(e => e.monitoringPeriod?.sendDevicesToPatients)
      .filter(e => e.enrollmentStatus === EnrollmentStatus.ACTIVE || e.enrollmentStatus === EnrollmentStatus.PENDING);
    commit(types.SET_ENROLLMENTS, enrollments);
    return true;
  }
  finally {
    commit(types.SET_LOADING, false);
  }
}
