/* global API_URL */

import Axios from 'axios';

import { handleErrors, handleSuccess } from './handleErrors';

import qs from 'qs';

Axios.defaults.baseURL = API_URL;

Axios.defaults.headers.common.Accept = 'application/json';

Axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' });

Axios.interceptors.response.use(
  response => handleSuccess(response),
  error => handleErrors(error)
);

export const axios = Axios;
