import { Page } from '../../../constants/page';
import { EhRole } from '../../auth/ehRole';
import { disable } from '../routerPermissions';

export const TaskRoutes = {
  path: 'practitioner-tasks',
  name: Page.PractitionerTasks,
  props: true,
  component: () => import('../../../components/views/app/practitioner/tasks/Tasks'),
  meta: {
    title: 'tasks.pageTitle',
    roles: [disable(EhRole.SsoUser)]
  },
  redirect: { name: Page.PractitionerTasksList },
  children: [
    {
      path: 'list',
      name: Page.PractitionerTasksList,
      props: true,
      component: () => import('../../../components/views/app/practitioner/tasks/list/TasksList'),
      meta: {
        title: 'tasks.pageTitle',
      }
    }
  ]
};
