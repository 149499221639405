<template>
  <VMenu
    v-model="isPopoverShown"
    :offsetY="true"
    :closeOnContentClick="false"
    :left="true"
    :class="popoverStyles.menu"
  >
    <template #activator="{ on }">
      <button v-on="on">
        <div :class="[styles.popoverButton, isPopoverShown && styles.activeButton]">
          <Button
            text
            round
            icon="filter-plus"
            data-testid="button-filter"
          />
          <Badge
            v-if="hasHiddenFiltersWithValue"
            :offsetY="20"
            :offsetX="20"
            :color="ComponentStyle.Success"
            :content="filledInputCount"
            overlap
          />
        </div>
      </button>
    </template>

    <div :class="styles.popover">
      <div :class="[styles.popoverHeader, styles.flexEnd]">
        <div :class="[styles.headerText]">
          {{ $localize('common.filters') }}
        </div>
        <Badge
          v-if="hasHiddenFiltersWithValue"
          :color="ComponentStyle.Success"
          :content="filledInputCount"
          inline
        />
        <div
          :class="styles.closePopover"
        >
          <Button
            text
            round
            xLarge
            icon="x"
            data-testid="button-hide"
            @click="hidePopover"
          />
        </div>

        <Button
          v-if="hasHiddenFiltersWithValue"
          primary
          text
          label="common.clearAll"
          @click="clearAll"
        />
      </div>
      <div :class="styles.popoverContent">
        <div
          v-for="input in inputs"
          :key="input.id"
          :class="styles.filterInput"
        >
          <slot :name="createInputSlotName(input.id)">
            <Smart
              ref="inputs"
              :input="input"
              :info="input.info"
              :action="$props.setInputValueAction"
              :searchAction="input.searchAction"
              @keyup.enter.native="$emit('applyFilters')"
            />
          </slot>
        </div>

        <Button
          v-if="isServerSide"
          primary
          label="common.search"
          @click="$emit('applyFilters')"
        />
      </div>
    </div>
  </VMenu>
</template>

<script>
  import { VMenu } from 'vuetify/lib';

  import { Smart } from '@h4h/inputs';
  import { Button } from '@h4h/button';
  import { Badge } from '@h4h/badge';
  import { ComponentStyle } from '@h4h/theme';
  import popoverStyles from '@h4h/popover/src/components/popover/popover.scss';

  import { getInputEmptyValue, isFilledInputValue } from '../../utils/filters';
  import { createInputSlotName } from '../../utils/slotUtils';

  import styles from './filter.scss';

  export default {
    name: 'H4hFilterPopover',
    components: {
      Button,
      VMenu,
      Smart,
      Badge
    },

    props: {
      inputs: {
        type: Array,
        required: true
      },

      setInputValueAction: {
        type: Function,
        required: true
      },

      isServerSide: {
        type: Boolean,
        default: false
      },

      hiddenFiltersWithValueCount: {
        type: Number,
        default: 0
      }
    },

    data() {
      return {
        styles,
        popoverStyles,
        ComponentStyle,
        createInputSlotName,
        isPopoverShown: false,
        filledInputCount: this.$props.hiddenFiltersWithValueCount || 0
      };
    },

    computed: {
      hasHiddenFiltersWithValue() {
        return this.filledInputCount > 0;
      }
    },

    watch: {
      ['$props.hiddenFiltersWithValueCount']() {
        this.filledInputCount = this.$props.hiddenFiltersWithValueCount;
      }
    },

    methods: {
      clearAll() {
        let someFilterCleared = false;
        this.$props.inputs.forEach(i => {
          if (isFilledInputValue(i.value)) {
            this.$props.setInputValueAction({ input: i, value: getInputEmptyValue(i.value) });
            someFilterCleared = true;
          }
        });

        if (someFilterCleared) {
          this.$emit('applyFilters');
        }

        this.hidePopover();
      },

      hidePopover() {
        this.isPopoverShown = false;
      },

      togglePopover() {
        this.isPopoverShown = !this.isPopoverShown;
      }
    }
  };
</script>
