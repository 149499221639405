import { types } from './types';
import { createInputConfig } from '@h4h/inputs';
import { InputType } from '@h4h/inputs/src/constants/inputType';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_COMMENT](state, comment) {
    state.comment = comment;
  },

  [types.INIT_POPUP](state, observationId) {
    const inputs = getInputsConfig(state);

    Object.assign(state, {
      observationId,
      inputs
    });
  },

  [types.SET_POPUP_ID](state, popupId) {
    state.popupId = popupId;
  },

  [types.SET_POPUP_OBSERVATION_TYPE](state, observationType) {
    state.popupObservationType = observationType;
  },

  [types.SET_POPUP_VIEW_MODE_ON](state, modeOn) {
    state.popupViewModeOn = modeOn;
    setFieldsAccordingMode(state);
  },

  [types.CLEAN_STATE](state) {
    state.loading = false;
    state.comment = null;
    state.observationId = null;
    state.inputs = null;
    state.popupId = null;
    state.popupObservationType = null;
    state.popupViewModeOn = false;
  },

  [types.SET_COMMENTS](state, comments) {
    state.comments = comments;
  }
};

function getInputsConfig(state) {
  let config = [];
  config.push(createTextInputConfig(state.comment ? state.comment.value : null, 'value', 'observationComment.value', InputType.Textarea, true, state.popupViewModeOn));
  if (state.comment) {
    config.push(createTextInputConfig(state.comment.modifiedBy, 'modifiedBy', 'observationComment.modifiedBy', InputType.Label, false, true));
  }
  return config;
}

function createTextInputConfig(value = null, id, label, inputType, required = false, disabled = false) {
  return createInputConfig({
    id,
    type: inputType,
    value,
    required,
    label,
    disabled,
  });
}

function setFieldsAccordingMode(state) {
  if (state.inputs) {
    for (let input of state.inputs) {
      if (input.id === 'value') {
        input.disabled = state.popupViewModeOn;
        return;
      }
    }
  }
}
