/* global PRODUCTION */

import { flatten, noop } from 'lodash';
import createLogger from 'vuex/dist/logger';

import { popups } from '../services/popups';
import { pageTitle, pageTitlePlugin } from './pageTitle';
import { preferences } from './preferences';
import { profile } from './profile';
import { patients } from './patients';
import { groups } from './groups';
import { orgUsers } from './org-users';
import { organizationSetting } from './organization-setting';
import { avatar } from './avatar';
import { programs } from './programs';
import { anamnesis } from './anamnesis';
import { observationTemplates } from './observationTemplates';
import { practitionerTasks } from './practitionerTasks';
import { terminology } from './terminology';
import { questionnaireTemplates } from './questionnaireTemplates';
import { callCenter } from './callCenter';
import { simpleAnamnesis } from './simpleAnamnesis';
import { ingress } from './ingress';
import { i18n } from './i18n';
import { labResultGroups } from './labResultGroups';
import { iframe } from './iframe';
import { address } from './adress';
import { activationEmails } from './activationEmails';
import { certification } from './certification';
import { logisticPatients } from './logisticPatients';
import { logisticTasks } from './logisticTask';
import { countries } from './countries';
import { troubleshooting } from './troubleshooting';
import { environment } from './environment';
import { evalPopupTopForIframeIfNeedPlugin } from '../utils/popupTopForIframeEvaluator';

export const modules = {
  ingress,
  countries,
  profile,
  programs,
  patients,
  iframe,
  anamnesis,
  callCenter,
  organizationSetting,
  observationTemplates,
  questionnaireTemplates,
  pageTitle,
  preferences,
  groups,
  orgUsers,
  avatar,
  practitionerTasks,
  logisticTasks,
  terminology,
  simpleAnamnesis,
  i18n,
  labResultGroups,
  address,
  activationEmails,
  certification,
  logisticPatients,
  troubleshooting,
  environment,
};

export const plugins = [
  PRODUCTION ? noop : createLogger(),
  pageTitlePlugin,
  evalPopupTopForIframeIfNeedPlugin,
  popups.plugin,

  ...flatten(
    Object.values(modules)
      .map(m => m.plugins)
      .filter(m => !!m)
  )
];
