import Vue from 'vue';
import Toasted from 'vue-toasted';

import styles from '@h4h/theme/styles/toasted-override.scss';

Vue.use(Toasted, {
  duration: 3500,
  position: 'top-right',
  iconPack: 'fontawesome',
  containerClass: styles.toasted
});

export const toast = {
  show(message) {
    window.console.log('toast', message);
    return Vue.toasted.show(message);
  },

  success(message = 'Success') {
    window.console.log('success', message);
    return Vue.toasted.show(message, {
      type: 'success',
      icon: 'check-circle'
    });
  },

  info(message) {
    window.console.log('info', message);
    return Vue.toasted.show(message, {
      type: 'info',
    });
  },

  warning(message) {
    window.console.log('warning', message);
    return Vue.toasted.show(message, {
      type: 'warning',
    });
  },

  error(message = 'Error', error) {
    /* eslint-disable-next-line no-console */
    console.error(message, error);

    let errorMessage;
    if (error) {
      const formattedErrorMessage = error.message[0].toLowerCase() + error.message.substr(1);
      errorMessage = `${ message } - ${ formattedErrorMessage }`;
    }
    else {
      errorMessage = message;
    }
    return Vue.toasted.show(errorMessage, {
      type: 'error',
      icon: 'exclamation-circle'
    });
  }
};
