import Vue from 'vue';
import Vuetify from 'vuetify';
import { sync } from 'vuex-router-sync';

import { formatDate, formatDateTime, formatTime } from '@h4h/date';

import { formatObservation, formatObservationName } from './utils/formatters/observation';

import App from './components/views/app/App';

import { i18n } from './services/i18n';
import { store } from './services/store';
import { popups } from './services/popups';
import { router } from './services/router/index';
import { clickOutside } from './utils/directives/clickOutside';
import { Icon } from '@h4h/icons';
import { Button } from '@h4h/button';
import nl from 'vuetify/es5/locale/nl';
import en from 'vuetify/es5/locale/en';
import { findByKey } from './filters/findByKey';
import { localize, localizeEnumConstant } from './services/localize';
import { toDate } from './utils/formatters/date';
import { formatGender } from './utils/formatters/gender';
import { fullName } from './utils/formatters/fullNameFormatter';
import { initErrorConfig } from './services/axios/handleErrors';

sync(store, router);
initErrorConfig({ router });

Vue.use(Vuetify);
Vue.use(popups);
Vue.directive(clickOutside.name, clickOutside);

Vue.filter('datetime', formatDateTime);
Vue.filter('date', formatDate);
Vue.filter('time', formatTime);
Vue.filter('findByKey', findByKey);
Vue.filter('localize', localize);
Vue.filter('localizeEnumConstant', localizeEnumConstant);
Vue.filter('observation', formatObservation);
Vue.filter('observationName', formatObservationName);
Vue.filter('toDate', toDate);
Vue.filter('gender', formatGender);
Vue.filter('fullName', fullName);

// register it global since some components in packages doesn't register it locally (see DateInput)
Vue.component('Icon', Icon);
Vue.component('Button', Button);
/* eslint-disable-next-line no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  vuetify: new Vuetify({
    lang: {
      locales: { nl, en },
      current: 'nl',
    },
    icons: {
      iconfont: 'mdiSvg',
    }
  }),
  render: h => h(App)
});
