export const address = {
  postalCode: 'Postcode',
  city: 'City',
  country: 'Country',
  number: 'Number',
  numberExtension: 'Number extension',
  street: 'Street',
  enterPostalAddressInformation: 'Enter postal address information',
  changePostalAddressInformation: 'Change postal address information',
};
