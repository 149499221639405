export const types = Object.freeze({
  UPDATE_FILTER_PARAMS: 'UPDATE_FILTER_PARAMS',
  INIT_FORM: 'INIT_FORM',
  SET_PROGRAMS_LOADING: 'SET_PROGRAMS_LOADING',
  SET_OBSERVATION_TEMPLATES_LOADING: 'SET_OBSERVATION_TEMPLATES_LOADING',
  SET_LIST_LOADING: 'SET_LIST_LOADING',

  SET_TENANT_ID: 'SET_TENANT_ID',

  SET_TASKS_PAGE: 'SET_TASKS_PAGE',

  SET_POPUP_ID: 'SET_POPUP_ID',
  SET_FORWARDED: 'SET_FORWARDED',

  SET_PAGINATION_CONFIG: 'SET_PAGINATION_CONFIG',
  SET_CURRENT_FORWARDING_TASK_ID: 'SET_CURRENT_FORWARDING_TASK_ID',
  SET_SELECTED_TASKS: 'SET_SELECTED_TASKS',

  SET_TASKS_FOR_HANDLE: 'SET_TASKS_FOR_HANDLE',

  INIT_LIST_MODEL: 'INIT_LIST_MODEL',
  POPULATE_LIST_MODEL: 'POPULATE_LIST_MODEL',
  SET_PROGRAMS: 'SET_PROGRAMS',
  SET_OBSERVATION_TEMPLATES: 'SET_OBSERVATION_TEMPLATES',
  SET_HANDLING: 'SET_HANDLING',
  APPLY_FILTERS: 'APPLY_FILTERS',
});
