import { types } from './types';
import { createInputConfig, InputType, setInputValue } from '@h4h/inputs';
import { createEhDateInputConfig } from '../../../../utils/factories';
import {
  ObservationThresholdInputsGroup
} from '../../../../components/observation/thresholds/dialog/components/ThresholdInputsGroup';
import { createCronInput } from '../../../../components/cronEditor/store/cronInput';
import { setCronModelMutation } from '../../../../components/cronEditor/store/mutations';
import {
  inputStateChangeMutation
} from '../../../../components/observation/thresholds/dialog/components/InputStateChange';

export const mutations = {
  [types.SET_CRON_MODEL]: setCronModelMutation,

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PATIENT](state, patient) {
    state.patient = patient;
  },

  [types.SET_ENROLLMENTS](state, enrollments) {
    state.enrollments = enrollments;
  },

  [types.SET_PROGRAMS](state, programs) {
    state.programs = programs;
  },

  [types.SET_ENROLLMENT_OBS_TEMPLATES_MAP](state, map) {
    state.enrollmentObsTemplatesMap = map;
  },

  [types.SET_OBSERVATION_TEMPLATES](state, observationTemplates) {
    state.observationTemplates = observationTemplates;
  },

  [types.SET_ENROLLMENTS_TO_STOP](state, enrollmentsToStop) {
    state.enrollmentsToStop = enrollmentsToStop;
  },

  [types.SET_PRISTINE](state, { inputs, value }) {
    inputs.forEach(input => input.pristine = value);
  },

  [types.SET_ENROLLMENT_QUESTIONNAIRE_TEMPLATES_MAP](state, map) {
    state.enrollmentQuestionnaireTemplatesMap = map;
  },

  [types.SET_QUESTIONNAIRE_TEMPLATES](state, questionnaireTemplates) {
    state.questionnaireTemplates = questionnaireTemplates;
  },

  [types.INIT_POPUP](state, { popupId, programs, terminologyTypes, logisticsEnabled }) {
    state.popupId = popupId;
    state.terminologyTypes = terminologyTypes;
    state.logisticsEnabled = logisticsEnabled;
    state.newEnrollmentAdvancedMode = createInputConfig({
      id: 'newEnrollmentMode',
      value: false,
      label: 'enrollments.settingsType',
      toggle: true,
      inline: true,
      type: InputType.Radio,
      options: [
        { value: false, label: 'enrollments.standard' },
        { value: true, label: 'enrollments.adjusted' }
      ],
    });
    state.newEnrollmentDate = createEhDateInputConfig({
      id: 'startDate',
      type: InputType.Date,
      required: true,
      value: new Date(),
      label: 'enrollments.registrationDate',
    });
    state.newEnrollmentProgram = createInputConfig({
      id: 'program',
      type: InputType.Select2,
      clearable: false,
      required: true,
      value: programs[0].uuid,
      label: 'programs.selectAProgram',
      options: programs.map(program => ({
        label: program.name,
        value: program.uuid
      }))
    });
    createMonitoringPeriodInputConfig(state);
  },

  [types.SET_PROGRAM](state, { input, value }) {
    setInputValue({ input, value });
    createMonitoringPeriodInputConfig(state);
  },

  [types.SET_MONITORING_PERIOD](state, { input, value }) {
    setInputValue({ input, value });
    const programUuid = state.newEnrollmentProgram.value;
    const program = state.programs.find(program => program.uuid === programUuid);
    state.observationThresholdInputsGroups = createObservationThresholdInputsGroups(program, state.newEnrollmentPeriod.value, state.observationTemplates, state.terminologyTypes);
    createUseCustomThresholdsInput(state);
    createCustomScheduleForProgramInputs(state);
    createSendDeviceToPatientIfNeed(state);
  },

  [types.SET_NAV_LINKS](state, links) {
    state.navigationLinks = links;
  },

  [types.RESET_THRESHOLDS](state, observationThresholdInputsGroup) {
    observationThresholdInputsGroup.resetThresholdsToOriginalValues();
  },

  [types.SET_ITEM_DELETE_STATE](state, { group, value }) {
    group.setDeleted(value);
    state.observationThresholdInputsGroups.forEach(observationThresholdInputsGroup =>
      observationThresholdInputsGroup.thresholdInputsGroups.forEach(thresholdInputsGroup => thresholdInputsGroup.refreshGroupsIndexes())
    );
  },

  [types.INPUT_STATE_CHANGED]: inputStateChangeMutation,

};

function createObservationThresholdInputsGroups(program, monitoringPeriodUuid, observationTemplates, terminologyTypes) {
  const observationThresholds = program.observationThresholds;
  const monitoringPeriod = program.availablePeriods.find(period => period.uuid === monitoringPeriodUuid);
  const originalThresholds = monitoringPeriod.observations
    .map(obs => observationThresholds.find(threshold => threshold.observationTemplateUuid === obs.observationTemplateId))
    .filter(x => !!x);
  return originalThresholds.map(originalThreshold => new ObservationThresholdInputsGroup({
    observationThresholdModel: originalThreshold,
    observationTemplateModel: observationTemplates.find(observationTemplate => observationTemplate.uuid === originalThreshold.observationTemplateUuid),
    terminologyTypes,
    inputAreRequired: true,
    highlightModified: true,
  }));
}

function createUseCustomThresholdsInput(state) {
  if (!state.observationThresholdInputsGroups.length) {
    state.newEnrollmentUseCustomThresholds = null;
    return;
  }
  state.newEnrollmentUseCustomThresholds = createInputConfig({
    id: 'newEnrollmentUseCustomThresholds',
    value: false,
    toggle: true,
    inline: true,
    type: InputType.Radio,
    options: [
      { value: false, label: 'enrollments.standard' },
      { value: true, label: 'enrollments.adjusted' },
    ]
  });
}

function createMonitoringPeriodInputConfig(state) {
  const programUuid = state.newEnrollmentProgram.value;
  const program = state.programs.find(program => program.uuid === programUuid);
  let uuid;
  if (program.availablePeriods.length) {
    let period = program.availablePeriods.find(period => period.uuid === program.basePeriodUuid);
    if (!period) {
      period = program.availablePeriods[0];
    }
    uuid = period.uuid;
  }
  state.newEnrollmentPeriod = createInputConfig({
    id: 'monitoring-period',
    type: InputType.Select2,
    clearable: false,
    required: true,
    value: uuid,
    label: 'enrollments.selectAPeriod',
    options: program.availablePeriods.map(program => ({
      label: program.name,
      value: program.uuid
    }))
  });
  state.observationThresholdInputsGroups = createObservationThresholdInputsGroups(program, uuid, state.observationTemplates, state.terminologyTypes);
  createUseCustomThresholdsInput(state);
  createCustomScheduleForProgramInputs(state);
  createSendDeviceToPatientIfNeed(state);
}

function createSendDeviceToPatientIfNeed(state) {
  const program = state.programs.find(p => p.uuid === state.newEnrollmentProgram.value);
  const period = program.availablePeriods.find(p => p.uuid === state.newEnrollmentPeriod.value);
  if (state.logisticsEnabled && period.sendDevicesToPatients) {
    state.newEnrollmentSendDevicesToPatients = createInputConfig({
      id: 'sendDevicesToPatients',
      type: InputType.Boolean,
      value: null,
      label: 'enrollments.sendDevicesToPatients',
      required: true,
    });
  }
  else {
    state.newEnrollmentSendDevicesToPatients = null;
  }
}

function createCustomScheduleForProgramInputs(state) {
  // grab all of the program inputs in the selected period
  const program = state.programs.find(p => p.uuid === state.newEnrollmentProgram.value);
  const period = program.availablePeriods.find(p => p.uuid === state.newEnrollmentPeriod.value);
  const periodProgramInputs = [
    ...period.observations,
    ...period.questionnaires,
  ].filter(item => !!item.cron);

  // generate cron settings for all of the program inputs
  const schedules = [];
  for (const [idx, programInput] of periodProgramInputs.entries()) {
    const scheduleType = createInputConfig({
      id: `scheduleType-${ idx }`,
      type: InputType.Radio,
      toggle: true,
      options: [
        { value: types.STANDARD_SCHEDULE, label: 'enrollments.standard' },
        { value: types.ADJUSTED_SCHEDULE, label: 'enrollments.adjusted' }
      ],
      value: types.STANDARD_SCHEDULE,
    });
    const cronSettings = createCronInput(programInput.cron);

    schedules.push({
      programInput,
      scheduleType,
      cronSettings,
    });
  }
  state.newEnrollmentSchedules = schedules;
}
