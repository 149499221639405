import { isUndefined } from 'lodash';

/**
 * Checks whether a particular slot is used in component instance
 *
 * ATTENTION: has to be defined as a method on component itself
 * in order to get access to component scope (this). Unfortunately
 * scope can't be passed as a parameter due to Vue limitations -
 * the method will throw if called inside the template
 *
 * don't work with array of child, for this case need to add a wrapper
 *
 * @param     {String}    name
 * @returns   {Boolean}
 */
export function hasSlot(name) {
  if (this.$slots[name]) {
    return true;
  }

  if (!this.$scopedSlots[name]) {
    return false;
  }

  try {
    return !isUndefined(this.$scopedSlots[name]());
  }
  catch (err) {
    // it probably tried to read scope property
    // thus we conclude that the slot was passed
    return true;
  }
}
