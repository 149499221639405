import { types } from './types';
import { showRestError } from '../../../../../../../utils/errors';
import { groupedPatientObservations } from '../../../../../../../services/api/observations/observations';
import moment from 'moment';
import { ISO_FULL_DATE_FORMAT } from '../../../../../../../constants/dateConstants';
import { observationDateField } from '../../../../../../../model/observation/observationModel';
import { loadDependedTasks } from '../../observationTaskHelper';
import { setInputValueAction } from '@h4h/inputs';
import { sameDatepickerValue } from '../utils';
import { PartialRequestLoader, PartialRequestLoaderConfig } from './PartialRequestLoader';
import { flatten, noop } from 'lodash';
import {
  pageablePractitionerTasks
} from '../../../../../../../services/api/practitionerTasks/pageablePractitionerTasks';
import { isCallCenter } from '../../../../../../../services/auth/auth';
import { getTenantFromRoute } from '../../../../../../../services/router/tenantRouteUtils';
import { RequestResult } from '../../../../../../../utils/tryRequest';

const reloadTasksAction = 'reloadTasks';
export const actions = Object.freeze({
  cleanStore,
  init,
  reload,
  [reloadTasksAction]: reloadTasks,
  setPeriodValue,
});

async function cleanStore({ commit }) {
  commit(types.SET_GROUPED_OBSERVATIONS, []);
}

async function init({ commit, dispatch }, payload) {
  commit(types.INIT_PAGE, payload);
  await dispatch('reload');
}

async function reload({ commit, state, dispatch }) {
  return await doReload(state, commit, dispatch);
}

const TASK_KEY = 'task';

async function doReload(state, commit) {
  commit(types.SET_OBSERVATION_TASK_MAP, new Map());
  if (!state.observationTemplate) {
    commit(types.SET_GROUPED_OBSERVATIONS, []);
  }
  const patientUuid = state.patient.uuid;
  const terminologyType = state.observationTemplate.terminologyType;
  const params = {
    type: terminologyType,
    sort: observationDateField + ',desc',
  };

  let period = state.period.value;
  if (period) {
    if (!Array.isArray(period)) { // in case only one date is selected
      period = [period, period];
    }
    params.from = moment(period[0]).startOf('day').format(ISO_FULL_DATE_FORMAT);
    params.to = moment(period[1]).endOf('day').format(ISO_FULL_DATE_FORMAT);
  }
  const conclusionNames = state.conclusionNames?.value;
  if (conclusionNames?.size) {
    params.conclusionNames = Array.from(conclusionNames);
  }
  const conclusionBy = state.conclusionBy?.value;
  if (state.conclusionBy?.valid && conclusionBy?.length) {
    params.conclusionBy = conclusionBy;
  }

  commit(types.SET_OBSERVATIONS_LOADING, true);
  commit(types.SET_LOADING_PERCENT, null);
  const loaderConfig = new PartialRequestLoaderConfig({
    dataProvider: config => groupedPatientObservations(patientUuid).fetchOne({
      params: {
        ...params,
        ...config,
      }
    }),
    linkedEntitiesKeys: [TASK_KEY],
    linkedEntityProvider: async(key, uuids) => {
      const map = await loadDependedTasks(uuids, state.enrollment.uuid, state.patient);
      return new RequestResult({
        success: true,
        data: flatten(Array.from(map.values()))
      });
    },
    progressCallback: percent => commit(types.SET_LOADING_PERCENT, percent),
    idField: 'uuid',
  });
  const result = await new PartialRequestLoader(loaderConfig).getData();
  if (result.success) {
    commit(types.SET_GROUPED_OBSERVATIONS, result.items);
    if (result.linkedEntities.has(TASK_KEY)) {
      commit(types.SET_ALL_TASKS, result.linkedEntities.get(TASK_KEY));
    }
  }
  else {
    commit(types.SET_GROUPED_OBSERVATIONS, []);
    showRestError(result.error, 'messages.cantFetchObservation');
  }
  commit(types.SET_LOADING_PERCENT, null);
  commit(types.SET_OBSERVATIONS_LOADING, false);
  return result.success;
}

async function reloadTasks({ state, commit }) {
  const observationExtIds = state.groupedObservations
    .map(gro => gro.observation)
    .map(observation => observation.uuid);

  const loaderConfig = new PartialRequestLoaderConfig({
    dataProvider: async params => {
      const tasksResponse = await pageablePractitionerTasks.create({ observationExtIds, sort: 'uuid' }, { params });
      if (tasksResponse.success) {
        let tenantFromRoute = getTenantFromRoute();
        tasksResponse.data.content = tasksResponse.data.content
          .filter(task => task.monitoringPeriod?.enrollment?.id === state.enrollment.uuid)
          .map(task => {
            if (isCallCenter() && !task.tenantId) {
              task.tenantId = tenantFromRoute;
            }
            task.patient = state.patient;
            return task;
          });
      }
      return tasksResponse;
    },
    linkedEntitiesKeys: [],
    linkedEntityProvider: noop,
    progressCallback: noop,
    idField: 'id',
  });
  let loader = new PartialRequestLoader(loaderConfig);
  const result = await loader.getData();
  commit(types.SET_ALL_TASKS, result.items || []);
}

async function setPeriodValue({ commit, state, dispatch }, { input, value }) {
  const oldValue = input.value;
  await setInputValueAction({ commit, state, dispatch }, { input, value });
  if (input.id === 'observationsListPeriod') {
    // sometime calendar emits value even if there is no change
    if (sameDatepickerValue(oldValue, value)) {
      return;
    }
  }
  if (input.valid) {
    await dispatch('reload');
  }
}

