export class RawEcgFile {

  /**
   *
   * @param {Object}    config
   * @param {Number}    config.amplitudeResolution
   * @param {Number}    config.frequency
   * @param {Number}    config.mainsFrequency
   * @param {String}    config.uuid
   * @param {String[]}  config.rawECGData
   */
  constructor({ amplitudeResolution, frequency, mainsFrequency, uuid, rawECGData }) {
    this.amplitudeResolution = amplitudeResolution;
    this.frequency = frequency;
    this.mainsFrequency = mainsFrequency;
    this.uuid = uuid;
    this.rawECGData = rawECGData;
  }

  get empty() {
    return !this.uuid;
  }
}
