export class DatePartialModel {
  /**
    * DatePartialModel config
    *
    * @param {Object}                                   config
    * @param {Integer}                                  config.day
    * @param {Integer}                                  config.month
    * @param {Integer}                                  config.year
  */
  constructor(config = {}) {
    this.day = config.day;
    this.month = config.month;
    this.year = config.year;
  }
}