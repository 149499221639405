import { state } from './state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { view } from './view';
import { observation } from './observation';
import { stop } from './stop';
import { questionnaire } from './questionnaire';
import { withInputs } from '@h4h/inputs';

export const enrollments = withInputs({
  namespaced: true,

  state,
  actions,
  getters,
  mutations,
  modules: {
    view,
    observation,
    questionnaire,
    stop,
  }
});
