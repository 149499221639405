export const ObservationInputType = Object.freeze({
  NUMERIC: 'NUMERIC',
  INT: 'INT',
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  LOCAL_DATE: 'LOCAL_DATE',
  LOCAL_TIME: 'LOCAL_TIME',
  LOCAL_DATE_TIME: 'LOCAL_DATE_TIME',
  DATE_TIME: 'DATE_TIME',
  REFERENCE: 'REFERENCE'
});

export const ObservationInputTypeOptions = Object.keys(ObservationInputType).map(unit => ({
  label: `enums.${ unit }`,
  value: unit
}));
