<template>
  <div :class="styles.container">
    {{ firstItem }}
    <Popover
      v-if="popoverItemsSize > 0"
      offsetY
      offsetX
      nudgeLeft="30"
    >
      <span
        slot="openPopover"
        :class="styles.openPopover"
      >
        {{ `(+${ popoverItemsSize })` }}
      </span>
      <PopoverList
        basic
        :actions="itemsInPopover"
      />
    </Popover>
  </div>
</template>

<script>
  import Popover from '../popover/Popover';
  import PopoverList from '../popoverList/PopoverList';
  import { ArrayProp } from '@h4h/classes';

  import styles from './basicPopover.scss';

  export default {
    // component needed just to show items in a popover
    // without any actions
    name: 'H4hBasicPopover',

    components: {
      Popover,
      PopoverList
    },

    props: {
      items: new ArrayProp({
        type: Object,
        required: true
      })
    },

    data() {
      return {
        styles
      };
    },

    computed: {
      firstItem() {
        return this.$props.items[0].title;
      },

      popoverItemsSize() {
        return this.$props.items.length - 1;
      },

      itemsInPopover() {
        return this.$props.items.slice(1);
      }
    }
  };
</script>
