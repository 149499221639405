export function createCronModelAction(mutationName) {
  return function({ commit }, payload) {
    commit(mutationName, payload);
  };
}

export function onHourInputChanged(inputs, input, value) {
  // make sure no invalid date is provided
  if (!value) {
    input.value = new Date(input.value.getTime());
  }
  else {
    // minutes should be the same for all inputs since it is impossible to use in cron many time with different minutes
    // for example 14:00 and 19:15 at the same time
    input.value = value;
    const defaultMinutes = value.getUTCMinutes();
    for (const timeInput of inputs) {
      const fixedMinutesDate = new Date(timeInput.value.getTime());
      fixedMinutesDate.setUTCMinutes(defaultMinutes);
      timeInput.value = fixedMinutesDate;
    }
  }
}

export function onRemoveItem(inputs, item) {
  const itemIdx = inputs.indexOf(item);
  if (itemIdx >= 0) {
    inputs.splice(itemIdx, 1);
  }
}
