import { types } from './types';
import { getInputsMap } from '@h4h/inputs';
import { TableFiltersProfile } from '../model/TableFilterProfile';
import { mapValues } from 'lodash';
import { ToastRequest, ToastType } from '../model/MultipleFiltersProfilesConfig';
import { ConfirmationResult, popups, PopupType } from '@h4h/popups';
import { TableProfilePopups } from '../model/TableProfilePopups';
import { ComponentStyle } from '@h4h/theme';

export const actions = Object.freeze({
  init,
  saveNew,
  switchDefaultProfileState,
  editProfile,
  deleteProfile,
  activateProfile,
  closePopup,
});

async function refresh(config, commit) {
  const tableFiltersProfiles = await config.getProfileModel();
  commit(types.INIT_FORM, { config, profiles: tableFiltersProfiles.profiles });
}

/**
 *
 * @param commit
 * @param {MultipleFiltersProfilesConfig} config
 */
async function init({ commit }, config) {
  await refresh(config, commit);
}

/**
 *
 * @param commit
 * @param state
 * @param {MultipleFiltersProfilesConfig} config
 * @param {Object[]}  filterInputs
 * @returns {Promise<boolean>}
 */
async function saveNew({ commit, state }, { config, filterInputs }) {
  const inputs = state.dataPerTable[config.tableId].inputs;
  commit(types.SET_PRISTINE, { inputs, pristine: false });
  if (inputs.some(input => !input.valid)) {
    return false;
  }
  const newProfile = new TableFiltersProfile({
    id: Math.floor(Math.random() * 1_000_000),
    ...mapValues(getInputsMap(inputs), i => i.value),
    filters: mapValues(getInputsMap(filterInputs), i => i.value),
  });
  const profiles = await config.getProfileModel();
  if (profiles.profileExists(newProfile)) {
    config.toast(new ToastRequest({
      messageCode: 'common.filterWithSuchSettingsAlreadyExists',
      toastType: ToastType.Warning,
    }));
    return false;
  }
  profiles.profiles.push(newProfile);
  profiles.validateDefaultProfile(newProfile);
  await config.saveProfileModel(profiles);
  await refresh(config, commit);
  config.toast(new ToastRequest({
    messageCode: 'common.filterSuccessfullySaved',
    toastType: ToastType.Success,
  }));
  return true;
}

/**
 *
 * @param commit
 * @param {MultipleFiltersProfilesConfig} config
 * @param {TableFiltersProfile}  profile
 */
async function switchDefaultProfileState({ commit }, { config, profile }) {
  const profiles = await config.getProfileModel();
  const tableFiltersProfile = profiles.profiles.find(p => p.id === profile.id);
  tableFiltersProfile.defaultProfile = !tableFiltersProfile.defaultProfile;
  profiles.validateDefaultProfile(tableFiltersProfile);
  await config.saveProfileModel(profiles);
  await refresh(config, commit);
  let messageCode = tableFiltersProfile.defaultProfile ? 'common.filterMarkedAsDefault' : 'common.defaultFilterRemoved';
  config.toast(new ToastRequest({ messageCode, toastType: ToastType.Success }));

  return true;
}

/**
 *
 * @param commit
 * @param state
 * @param {MultipleFiltersProfilesConfig} config
 * @param {TableFiltersProfile}  profile
 */
async function editProfile({ commit, state }, { config, profile }) {
  const { closed, id } = await popups.show({
    type: PopupType.Modal,
    name: TableProfilePopups.EditTableProfile,
    props: {
      multipleFiltersProfilesConfig: config,
    }
  });
  commit(types.SET_POPUP_ID, id);
  commit(types.INIT_PROFILE_EDITOR, profile);
  const { success, data } = await closed;
  commit(types.SET_POPUP_ID, null);
  const saveResult = !!data;
  if (saveResult) {
    const profiles = await config.getProfileModel();
    const tableFiltersProfile = profiles.profiles.find(p => p.id === profile.id);
    tableFiltersProfile.name = getInputsMap(state.editProfileInputs).name.value;
    await config.saveProfileModel(profiles);
    await refresh(config, commit);
    config.toast(new ToastRequest({
      messageCode: 'common.filterSuccessfullySaved',
      toastType: ToastType.Success,
    }));
  }
  return success && saveResult;
}

/**
 *
 * @param commit
 * @param {MultipleFiltersProfilesConfig} config
 * @param {TableFiltersProfile}  profile
 */
async function deleteProfile({ commit }, { config, profile }) {
  // use artificial ID to prevent hide popover
  commit(types.SET_POPUP_ID, Math.random());
  const { confirmed } = await popups.confirm({
    style: ComponentStyle.Warning,
    title: 'common.delete',
    message: 'common.areYouSureYouWantToDeleteThisSavedFilter',
    buttons: {
      confirm: {
        label: 'common.delete',
        primary: false,
        destructive: true,
        result: ConfirmationResult.Confirm
      },
      decline: {
        label: 'common.cancel',
        primary: true,
        outlined: true,
        noShadow: true,
        result: ConfirmationResult.Decline
      },
    }
  });
  commit(types.SET_POPUP_ID, null);
  if (confirmed) {
    const profiles = await config.getProfileModel();
    const index = profiles.profiles.findIndex(p => p.id === profile.id);
    profiles.profiles.splice(index, 1);
    await config.saveProfileModel(profiles);
    await refresh(config, commit);
    config.toast(new ToastRequest({
      messageCode: 'common.filterRemoved',
      toastType: ToastType.Success,
    }));
  }
  return confirmed;
}

/**
 *
 * @param _
 * @param {MultipleFiltersProfilesConfig} config
 * @param {TableFiltersProfile}  profile
 */
async function activateProfile(_, { config, profile }) {
  await config.activateProfile(profile);
  return true;
}

async function closePopup({ state }, result) {
  popups.hide({
    id: state.popupId,
    result,
  });
}

