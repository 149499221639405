<template>
  <div
    :data-tag-id="tagId"
    :data-label="$props.label"
    :class="[styles.customTagContainer, selected && styles.selected]"
    contenteditable="true"
    @click="onClick"
  >
    <span
      ref="content"
      :class="[styles.customTag, $props.missingTag && styles.missingTag]"
      contenteditable="false"
    >
      <Icon
        v-if="!$props.isDisabled()"
        type="x"
        :class="styles.customTagIcon"
        @click.stop="remove()"
      />
      <LabelTooltip
        :info="$props.info"
        :label="$props.label"
      />
    </span>
  </div>
</template>

<script>
  import { Icon } from '@h4h/icons';
  import LabelTooltip from '../labelTooltip/LabelTooltip';

  import { TAG_ID_PREFIX, getSelectionRange } from '../../utils/wysiwygUtils';

  import styles from './wysiwyg.scss';

  const Position = {
    Before: 'Before',
    Pre: 'Pre',
    Post: 'Post',
    After: 'After'
  };

  export const UserAction = {
    Mouse: 'Mouse',
    Keyboard: 'Keyboard'
  };

  export default {
    name: 'H4hWysiwygTag',

    components: {
      Icon,
      LabelTooltip
    },

    props: {
      info: [String, Array],
      id: {
        type: String,
        required: true
      },

      missingTag: {
        type: Boolean,
        default: false
      },

      label: {
        type: String,
        required: true
      },

      onRemove: {
        type: Function,
        required: true
      },

      isDisabled: {
        type: Function,
        default: () => false
      }
    },

    data() {
      return {
        styles,
        selected: false,
        lastSelectionPosition: {
          start: null,
          end: null
        }
      };
    },

    computed: {
      tagId() {
        return TAG_ID_PREFIX + this.$props.id;
      }
    },

    methods: {
      remove() {
        this.discard();
        this.$props.onRemove();
      },

      discard() {
        this.$destroy();
        this.$el.remove();
      },

      onClick(event) {
        // place cursor before or after tag based on horizontal click position
        const { left, right } = this.$el.getBoundingClientRect();
        const middle = (left + right) / 2;

        if (event.pageX >= middle) {
          this.placeCursorAfterContainer();
        }
        else {
          this.placeCursorBeforeContainer();
        }
      },

      onSelectionChange(trigger) {
        const range = getSelectionRange();

        if (!range) {
          return;
        }

        let selectionPosition = this.getSelectionPosition(range);

        // we only need to shift the cursor position if the selection range
        // is collapsed (so just the pointer) and is inside the tag container
        // @todo: Baizulin - think about shifting non-collapsed ranges too
        if (
          range &&
          range.collapsed &&
          (selectionPosition.start === Position.Pre || selectionPosition.start === Position.Post)
        ) {
          this.moveCollapsedRange(range, selectionPosition.start, trigger);
        }

        // range might have been modified
        selectionPosition = this.getSelectionPosition(range);
        this.lastSelectionPosition = selectionPosition;

        // highlight tag if it is inside the selection
        this.selected = (
          [Position.Before, Position.Pre].includes(selectionPosition.start) &&
          [Position.Post, Position.After].includes(selectionPosition.end)
        );
      },

      moveCollapsedRange(range, position, trigger) {
        if (!trigger) {
          // range was changed programmatically - do nothing
          return;
        }
        else if (trigger === UserAction.Mouse) {
          // cursor was expected to be positioned to an exact point so we only need to
          // move the cursor outside tag container without shifting it in any direction.
          // Chrome is inconsistent and will sometimes put the cursor inside the tag
          // container if it was the last element in line even if you clicked outside
          if (position === Position.Pre) {
            this.placeCursorBeforeContainer(range);
          }
          else if (position === Position.Post) {
            this.placeCursorAfterContainer(range);
          }
        }
        else if (trigger === UserAction.Keyboard) {
          // cursor was moved inside the tag container from Before or After
          // thus we need to move it in the corresponding direction and
          // place it either After or Before
          if (this.lastSelectionPosition.end === Position.Before) {
            this.placeCursorAfterContainer(range);
          }
          else if (this.lastSelectionPosition.start === Position.After) {
            this.placeCursorBeforeContainer(range);
          }
        }
      },

      placeCursorBeforeContainer(range = getSelectionRange()) {
        range.setStartBefore(this.$el);
        range.setEndBefore(this.$el);
      },

      placeCursorAfterContainer(range = getSelectionRange()) {
        range.setStartAfter(this.$el);
        range.setEndAfter(this.$el);
      },

      getSelectionPosition(range) {
        return {
          start: this.getPosition(range, true),
          end: this.getPosition(range, false)
        };
      },

      getPosition(range, start) {
        const container = start ? range.startContainer : range.endContainer;
        const offset = start ? range.startOffset : range.endOffset;

        if (container === this.$el) {
          return offset === 0
            ? Position.Pre
            : Position.Post;
        }

        // check either container's start or end against the selection
        const result = range.comparePoint(this.$el, start ? 0 : 1);

        switch (result) {
          case -1:
            return Position.After;
          case 1:
            return Position.Before;
          case 0:
            // container's start or end is inside the selection which means
            // that selection start is Before the tag or selection end is After
            return start ? Position.Before : Position.After;
        }
      },

      // occasionally the browser might explicitly put the caret inside WysiwygTag main div when the user starts typing
      // e.g. if the tag is the first element in a line and the focus was before that tag
      // we would like to ensure that the tag markup stays clean, thus we move all the extra nodes outside the tag
      cleanup() {
        const childNodes = Array.from(this.$el.childNodes);

        if (childNodes.length === 1) {
          // do nothing if inner markup wasn't messed up
          return;
        }

        const contentIndex = childNodes.findIndex(node => node === this.$refs.content);

        childNodes.forEach((node, index) => {
          if (index === contentIndex) {
            return;
          }

          if (index < contentIndex) {
            this.$el.before(node);
          }
          else {
            this.$el.after(node);
          }
        });
      }
    }
  };
</script>
