import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { withInputs } from '@h4h/inputs';

export const multipleFiltersProfiles = withInputs({
  namespaced: true,

  state,
  actions,
  mutations
});
