import euro from './svg/euro.svg?component';
import biohazard from './svg/biohazard.svg?component';
import newspaper from './svg/newspaper.svg?component';
import heartbeat from './svg/heartbeat.svg?component';
import starOfLife from './svg/star-of-life.svg?component';
import skullCrossbones from './svg/skull-crossbones.svg?component';
import hourglass from './svg/hourglass.svg?component';
import identification from './svg/identification.svg?component';
import medicalCross from './svg/medicalCross.svg?component';
import unlink from './svg/unlink.svg?component';
import euroSignX from './svg/euroSignX.svg?component';
import park from './svg/park.svg?component';
import assignUser from './svg/assignUser.svg?component';
import unpark from './svg/unpark.svg?component';
import xCircleInvert from './svg/x-circle-invert.svg?component';
import dashboard from './svg/dashboard.svg?component';
import addChild from './svg/addChild.svg?component';
import addParent from './svg/addParent.svg?component';
import vector from './svg/vector.svg?component';
import waitingRoom from './svg/waiting-room.svg?component';
import minusPlus from './svg/minusPlus.svg?component';
import lightBulb from './svg/lightBulb.svg?component';
import creditDebit from './svg/creditDebit.svg?component';
import unarchive from './svg/unarchive.svg?component';
import filterPlus from './svg/filter-plus.svg?component';
import radiology from './svg/radiology.svg?component';
import lab from './svg/lab.svg?component';
import externalLink from './svg/external-link.svg?component';
import h4h from './svg/h4h.svg?component';

export const icons = Object.freeze({
  'external-link': {
    icon: externalLink,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  euro: {
    icon: euro,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  biohazard: {
    icon: biohazard,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  newspaper: {
    icon: newspaper,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  heartbeat: {
    icon: heartbeat,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'star-of-life': {
    icon: starOfLife,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'skull-crossbones': {
    icon: skullCrossbones,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  hourglass: {
    icon: hourglass,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  identification: {
    icon: identification,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'medical-cross': {
    icon: medicalCross,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'dashboard': {
    icon: dashboard,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'unlink': {
    icon: unlink,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'euro-sign-x': {
    icon: euroSignX,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  radiology: {
    icon: radiology,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  lab: {
    icon: lab,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  park: {
    icon: park,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'assign-user': {
    icon: assignUser,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'unpark': {
    icon: unpark,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'x-circle-invert': {
    icon: xCircleInvert,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'add-child': {
    icon: addChild,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'add-parent': {
    icon: addParent,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  vector: {
    icon: vector,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'waiting-room': {
    icon: waitingRoom,
    attrs: {
      stroke: 'currentColor',
      'stroke-width': 2
    }
  },
  'minus-plus': {
    icon: minusPlus,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'light-bulb': {
    icon: lightBulb,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'credit-debit': {
    icon: creditDebit,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  unarchive: {
    icon: unarchive,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'filter-plus': {
    icon: filterPlus,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  },
  'h4h': {
    icon: h4h,
    attrs: {
      fill: 'currentColor',
      'stroke-width': 0
    }
  }
});
