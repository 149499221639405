/**
 * @param dispatch
 * @return {Promise<OrganizationSettingModel>}
 */
export function getCurrentOrganizationSettings(dispatch) {
  return dispatch('organizationSetting/fetchOrganizationSetting',{}, { root: true });
}

/**
 * @param dispatch
 * @return {Promise<OrganizationCallCenterSettingModel>}
 */
export function getCurrentCallCenterSettings(dispatch) {
  return dispatch('organizationSetting/fetchCallCenterSettings',{}, { root: true });
}
