import { edit } from './edit';
import { view } from './view';

export const responsiblePhysician = {
  namespaced: true,
  modules: {
    edit,
    view,
  }
};
