import { EhRole } from '../../services/auth/ehRole';

export const role = {
  [EhRole.Patient]: 'Patient',
  [EhRole.Poh]: 'POH',
  [EhRole.Physician]: 'Physician',
  [EhRole.Admin]: 'Admin',
  [EhRole.CallCenterSupport]: 'Call Center Support',
  [EhRole.CallCenterAdmin]: 'Call Center Admin',
  [EhRole.LogisticsSupport]: 'Call Center Logistics Support',
  [EhRole.RKZUser]: 'RKZ User',
  [EhRole.SsoUser]: 'SSO User',
};
