import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { observationTemplates } from '../../../services/api/observations/observationTemplates';

export const actions = Object.freeze({
  fetchTypes,
  reload,
  initPage,
  onFilterChange,
});

async function initPage({ commit, state }, { terminologyTypes }) {
  commit(types.INIT_PAGE, { terminologyTypes });
  await fetchTypes({ commit, state });
}

async function onFilterChange({ commit }) {
  commit(types.UPDATE_FILTER);
}

async function reload({ commit, state }) {
  commit(types.SET_FETCHED, false);
  return await fetchTypes({ commit, state });
}

async function fetchTypes({ commit, state }) {
  if (state.fetched) {
    return state.observationTemplates;
  }

  commit(types.SET_LOADING, true);
  const result = await observationTemplates.fetch();
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_FETCHED, true);
    commit(types.SET_OBSERVATION_TEMPLATES, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchObservationTemplates');
  }
  commit(types.UPDATE_FILTER);
  return state.observationTemplates;
}

