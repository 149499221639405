export function mapObject(config, mapperClass) {
  if (config) {
    return new mapperClass(config);
  }
  return null;
}

export function mapArray(config, mapperClass) {
  if (Array.isArray(config)) {
    return config.map(o => new mapperClass(o));
  }
  return [];
}

export function mapArrayCustom(config, mapper) {
  if (Array.isArray(config)) {
    return config.map(o => mapper(o));
  }
  return [];
}

