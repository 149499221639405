import { ObservationThresholdModel } from '../../../model/thresholds/observationThresholdModel';

export class ObservationThresholdDialogInitModel {
  /**
   * MonitoringPeriodInputModel config
   *
   * @param {Object}                      config
   * @param {ObservationThresholdModel}   config.observationThreshold
   * @param {ObservationTemplateModel}   config.observationTemplate
   * @param {Object[]}   config.terminologyTypes
   * @param {OBSERVATION_THRESHOLD_POPUP_MODE}   config.mode
   */
  constructor(config) {
    this.observationThreshold = new ObservationThresholdModel(config.observationThreshold);
    this.observationTemplate = config.observationTemplate;
    this.terminologyTypes = config.terminologyTypes;
    this.mode = config.mode;
  }
}
