export class ProgramVendorModel {
  /**
   * ProgramVendorModel config
   *
   * @param {Object}        config
   * @param {DeviceVendor}  config.vendor
   * @param {String}        config.uuid
   */
  constructor(config) {
    this.vendor = config.vendor;
    this.uuid = config.uuid;
  }
}
