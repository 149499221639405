export const state = {
  loading: false,
  inputs: [],
  availablePeriods: [],
  primaryPeriod: null,
  supportedVendors: [],
  observationGroups: [],
  questionnaireGroups: [],
  observationThresholds: [],
};
