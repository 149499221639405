export const callCenter = {
  addRole: 'Voeg rol toe',
  addUser: 'Voeg gebruiker toe',
  admin: 'Admin',
  availableRoles: 'Beschikbare rollen',
  currentRoles: 'Huidige rollen',
  editUserRoles: 'Bewerk rollen gebruikers',
  filterUsers: 'Zoek een gebruiker',
  profile: 'Profiel',
  removeRole: 'Verwijder rol',
  roleManagement: 'Rollen beheer',
  roles: 'Rollen',
  tenantNote: 'U bekijkt op het moment de pagina voor: {0}',
  userDetails: 'Gebruiker gegevens',
  users: 'Gebruikers',
};
