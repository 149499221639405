import { types } from './types';
import { showRestError } from '../../../../../../utils/errors';
import { observationTemplates } from '../../../../../../services/api/observations/observationTemplates';
import { setInputValueAction } from '@h4h/inputs';
import { observationsStorePath } from './constants';

export const actions = Object.freeze({
  setInputValue,
  cleanStore,
  initContainer,
  init,
  reload,
  reloadTasks,
});

async function setInputValue({ commit, state, dispatch }, { input, value }) {
  await setInputValueAction({ commit, state, dispatch }, { input, value });
  if (input.valid) {
    dispatch('reload');
  }
}

async function cleanStore({ dispatch }) {
  await Promise.all([
    dispatch(observationsStorePath + '/graph/cleanStore', {}, { root: true }),
    dispatch(observationsStorePath + '/table/cleanStore', {}, { root: true }),
  ]);
}

async function initContainer({ state, commit }, { observationTemplateUuid }) {
  // prevent redundant reload in case of navigating to same observation from different enrollment
  if (state.observationTemplate) {
    if (state.observationTemplate.uuid === observationTemplateUuid) {
      return;
    }
  }

  commit(types.SET_OBSERVATION_TEMPLATE_LOADING, true);
  const observationTemplate = await fetchObservationTemplate(observationTemplateUuid);
  commit(types.SET_OBSERVATION_TEMPLATE_LOADING, false);
  if (observationTemplate) {
    commit(types.SET_OBSERVATION_TEMPLATE, observationTemplate);
  }
}

async function init({ dispatch, commit, state }, { patient, enrollment, conclusions ,selectedObservationUuid }) {
  commit(types.INIT_PAGE, { conclusions });

  const payload = {
    patient,
    enrollment,
    selectedObservationUuid,
    observationTemplate: state.observationTemplate,
    conclusionNames: state.conclusionNames,
    conclusionBy: state.conclusionBy,
  };
  await Promise.all([
    dispatch(observationsStorePath + '/graph/init', payload, { root: true }),
    dispatch(observationsStorePath + '/table/init', payload, { root: true }),
  ]);
}

async function reload({ dispatch }) {
  await Promise.all([
    dispatch(observationsStorePath + '/graph/reload', {}, { root: true }),
    dispatch(observationsStorePath + '/table/reload', {}, { root: true }),
  ]);
}

async function reloadTasks({ dispatch }) {
  await Promise.all([
    dispatch(observationsStorePath + '/graph/reloadTasks', {}, { root: true }),
    dispatch(observationsStorePath + '/table/reloadTasks', {}, { root: true }),
  ]);
}

async function fetchObservationTemplate(observationTemplateUuid) {
  const result = await observationTemplates.fetch({
    params: {
      id: observationTemplateUuid
    }
  });

  if (!result.success || !result.data.length) {
    showRestError(result.error, 'messages.cantFetchObservationTemplates');
    return;
  }
  return result.data[0];
}
