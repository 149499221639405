export const EhRole = Object.freeze({
  Admin: 'Admin', // Role for administrator of e-health portal
  Patient: 'Patient', // Patient role
  Physician: 'Physician', // Practitioner role
  Poh: 'Poh', // Practitioner assistant role
  CallCenterSupport: 'CallCenterSupport', // Call center support role
  CallCenterAdmin: 'CallCenterAdmin', // Call center admin role
  LogisticsSupport: 'LogisticsSupport', // Call center logistic user
  RKZUser: 'RKZUser', // RKZ User
  SsoUser: 'SsoUser',
  AumcReadUser: 'AumcReadUser',
});

export const ehRoleOptions = Object.keys(EhRole).map(role => ({
  label: `role.${ role }`,
  value: role
}));

const ccRoles = [EhRole.CallCenterSupport, EhRole.CallCenterAdmin, EhRole.LogisticsSupport];
const ehRoles = [EhRole.Admin, EhRole.Physician, EhRole.Poh];
const commonDisabledRoles = [EhRole.RKZUser, EhRole.Patient, EhRole.SsoUser, EhRole.AumcReadUser];
const rolesDisabledForEh = [...ccRoles, ...commonDisabledRoles];
const rolesDisabledForCc = [...ehRoles, ...commonDisabledRoles];

export const ehRoleOptionsSupportedForSelect = Object.keys(EhRole)
  .filter(role => !rolesDisabledForEh.includes(role))
  .map(role => ({
    label: `role.${ role }`,
    value: role
  }));

export const ccRoleOptionsSupportedForSelect = Object.keys(EhRole)
  .filter(role => !rolesDisabledForCc.includes(role))
  .map(role => ({
    label: `role.${ role }`,
    value: role
  }));
