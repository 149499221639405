export const organization = {
  callCenterDisablePopupHeading: 'Zenuwcentrum uitschakelen',
  callCenterDisablePopupConsent1: 'Weet u zeker dat u het Zenuwcentrum wilt uitschakelen?',
  callCenterDisablePopupConsent2: `Wanneer het Zenuwcentrum uitgeschakeld is zijn taken,
    metingen en patiëntinformatie niet zichtbaar voor de zorgprofessionals in het Zenuwcentrum`,
  callCenterEnabled: 'Zenuwcentrum ingeschakeld',
  callCenterEnablePopupHeading: 'Zenuwcentrum inschakelen',
  callCenterEnablePopupConsent1: 'Weet u zeker dat u het Zenuwcentrum wilt inschakelen?',
  callCenterEnablePopupConsent2: `Wanneer het Zenuwcentrum ingeschakeld is zijn taken,
    metingen en patiëntinformatie zichtbaar voor de zorgprofessionals in het Zenuwcentrum`,
  organization: 'Organisatie',
  organizationInformation: 'Organisatie informatie',
  customForwardingLinkToDoctor: 'Aangepaste doorstuurlink naar arts',
  customForwardingLinkToDoctorHint: 'Aangepaste koppelingen voor het doorsturen van taken naar org-gebruikers: https://example.com/patients/{externalPatientId} waarbij {externalPatientId} variabel is gevuld door systeem',
  extraServices: 'Extra diensten',
  logisticsDevicesEnabled: 'Logistiek apparaten ingeschakeld',
  logisticsDevicesEnabledHint: 'Once logistics is enabled, name and address of the patient will be visible to logistics',
  disableLogistics: 'Logistiek uitschakelen?',
  disableLogisticsText: 'Wanneer logistiek uitgeschakeld is, zijn naam en adres niet meer zichtbaar voor logistiek.',
  enableLogistics: 'Logistiek inschakelen?',
  enableLogisticsText: 'Wanneer logistiek ingeschakeld is, zijn naam en adres zichtbaar voor logistiek.',

  cantDisableLogistics: 'Kan logistiek niet uitschakelen',
  logisticsDisabled: 'Logistiek uitgeschakeld',
  cantEnableLogistics: 'Kan logistiek niet inschakelen',
  logisticsEnabled: 'Logistiek ingeschakeld',
};
