import { types } from './types';
import { periodInputConfig } from './forms';
import {
  setPageIndexMutation,
  setPaginationConfigMutation,
  toServerPageOptions
} from '../../../../../../../model/page';

export const mutations = {
  [types.SET_OBSERVATIONS_LOADING](state, value) {
    state.observationsLoading = value;
  },
  [types.CLEAN](state) {
    state.selectedObservationUuid = null;
    state.serverSideOptions = { };
    state.groupedObservationsPage = null;
  },

  [types.SET_PAGINATION_CONFIG]: setPaginationConfigMutation,

  [types.SET_PAGE_INDEX]: setPageIndexMutation,

  [types.SET_GROUPED_OBSERVATIONS_PAGE](state, groupedObservationsPage) {
    state.groupedObservationsPage = groupedObservationsPage;
    Object.assign(state.serverSideOptions, toServerPageOptions(groupedObservationsPage));
  },

  [types.INIT_PAGE](state, { patient, enrollment, conclusionNames, conclusionBy, observationTemplate, selectedObservationUuid }) {
    state.selectedGroupedObservations = [];
    state.groupedObservationsPage = null;
    state.selectedObservationUuid = selectedObservationUuid;

    state.observationTemplate = observationTemplate;
    state.patient = patient;
    state.enrollment = enrollment;
    state.period = periodInputConfig(enrollment);
    state.conclusionNames = conclusionNames;
    state.conclusionBy = conclusionBy;
  },

  [types.SET_OBSERVATION_TASK_MAP](state, observationTaskMap) {
    state.observationTaskMap = observationTaskMap;
  },

  [types.SET_SELECTED_OBSERVATIONS](state, selectedGroupedObservations) {
    state.selectedGroupedObservations = selectedGroupedObservations;
  },
};
