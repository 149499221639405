import { CrudService } from '../../../../classes/crudService';
import { AllergyModel } from '../../../../model/simpleAnamnesis/allergyModel';

export const allergies = patientUserUuid => new CrudService(
  `patients/${ patientUserUuid }/allergies`,
  data => new AllergyModel(data)
);

export const delAllergy = new CrudService(
  'patients/allergies',
);

export const editAllergy = new CrudService(
  'patients/allergies',
  data => new AllergyModel(data)
);
