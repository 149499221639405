import { types } from './types';

export const mutations = {

  [types.INIT_POPUP](state, { popupId }) {
    state.popupId = popupId;
  },

  [types.SET_SELECTED_GROUP](state, { labResultGroup }) {
    state.selectedGroup = labResultGroup;
  },

  [types.DESELECT_GROUP](state) {
    state.selectedGroup = null;
  },
};

