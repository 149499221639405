export const orgUser = {
  nameFilterText: 'Search user',
  users: 'Users',
  addUser: 'Add user',
  editUser: 'Edit user',
  editUserGroups: 'Edit user groups',
  groupManagement: 'Group Management',
  groupsUserIn: 'Groups user in:',
  availableGroupsToJoin: 'Available groups to join:',
  excludeUserFromGroup: 'Exclude user from group',
  joinUserToGroup: 'Join user to group',
  profile: 'Profile',
  editProfile: 'Edit profile',
  bigCode: 'BIG code',
  userGroups: 'User groups',
  newPassword: 'New password',
  repeatNewPassword: 'Repeat new password',
  resetPassword: 'Reset password',
  resetPasswordHint: '*User receives new mail with link for password changing',
  setTemporaryPassword: 'Make temporarly password',
  saveTemporaryPassword: 'Save temporary password',
  setTempPasswordHint: '*User can log once with temporarly password and can change it themselves by first login'
};
