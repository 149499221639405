import { types } from './types';
import { showRestError } from '../../../../../utils/errors';
import {
  getObservationsComments,
  observationComments
} from '../../../../../services/api/observations/observationComments';
import { toast } from '../../../../../services/toast';
import { localize } from '../../../../../services/localize';
import { getHeadersXTenantId } from '../../../../../utils/getHeaders';

export const actions = Object.freeze({
  initTaskObservationsComments,
  saveTaskObservationsComments,
});

function getObservations(observations) {
  return observations.map(task => task.observation?.payload);
}

async function initTaskObservationsComments({ commit, state }, tasks) {
  commit(types.INIT, { tasks });
  const tenants = Object.keys(state.observations);
  if (tenants.length) {
    commit(types.SET_LOADING, true);
    if (tenants.length === 1 && tenants[0] === 'undefined') {
      // no tenant info => just execute regular requests
      const observations = Object.values(state.observations)[0];
      const result = await getObservationsComments(getObservations(observations).map((observation => observation.uuid)));
      if (result.success) {
        commit(types.SET_COMMENTS, result.data);
      }
      else {
        showRestError(result.error, 'messages.cantFetchObservationComments');
      }
    }
    else {
      // execute calls with tennant info
      const results = await Promise.all(Object.keys(state.observations).map(tenantId => {
        let config = getHeadersXTenantId(tenantId);
        const observations = state.observations[tenantId];
        return getObservationsComments(getObservations(observations).map((observation => observation.uuid)), config);
      }));
      results.filter(result => !result.success)
        .forEach(resultWithError => showRestError(resultWithError.error, 'messages.cantFetchObservationComments'));
      if (results.some(result => result.success)) {
        const comments = results.map(result => result.data).flat();
        commit(types.SET_COMMENTS, comments);
      }
    }
  }
  commit(types.SET_LOADING, false);
  commit(types.INIT_COMMENT_INPUT);
}

async function saveTaskObservationsComments({ state, commit }) {
  if (!state.commentInput) { // maybe we don't have observations for this task
    return;
  }
  const commentValue = state.commentInput.value;
  if (!commentValue) {
    return;
  }
  const promises = Object.keys(state.observations)
    .map(tenantId => getObservations(state.observations[tenantId])
      .map(observation => ({ tenantId, observation })))
    .flat()
    .map(({ tenantId, observation }) => ({ tenantId, observationUuid: observation.uuid }))
    .map(({ tenantId, observationUuid }) => {
      let config = {};
      if (tenantId && tenantId !== 'undefined') {
        config = getHeadersXTenantId(tenantId);
        config.headers['X-call-center-flag'] = false;
      }
      let comment = state.comments.find(comment => comment.observationUuid === observationUuid);
      if (comment) {
        if (comment.value === commentValue) { // nothing to update
          return null;
        }
        const value = [comment.value, commentValue].filter(x => !!x).join('\r\n');
        return observationComments(observationUuid).update(comment.uuid, { value }, config);
      }
      else {
        return observationComments(observationUuid).create({ value: commentValue }, config);
      }
    })
    .filter(x => !!x);
  if (promises.length) {
    commit(types.SET_LOADING, true);
    const results = await Promise.all(promises);
    if (results.some(result => result.success)) {
      toast.success(localize('messages.observationCommentSaved'));
    }
    const error = results.find(result => !result.success)?.error;
    if (error) {
      showRestError(error, 'messages.cantSaveObservationComment');
    }
    commit(types.SET_LOADING, false);
  }
}

