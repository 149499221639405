import { types } from './types';
import { showRestError } from '../../../utils/errors';
import {
  questionnaireTemplates,
  questionnaireTemplatesUpdatable
} from '../../../services/api/questionnaire/questionnaireTemplates';

export const actions = Object.freeze({
  initForm,
});

async function initForm({ commit }, uuid) {
  commit(types.SET_UPDATABLE, false);
  commit(types.SET_LOADING, true);
  let questionnaireTemplate = await questionnaireTemplates.get(uuid);
  if (questionnaireTemplate.success) {
    commit(types.INIT_FORM, questionnaireTemplate.data);
  }
  else {
    showRestError(questionnaireTemplate.error, 'messages.cantFetchQuestionnaireTemplate');
  }

  let updatable = await questionnaireTemplatesUpdatable(uuid);
  if (updatable.success) {
    commit(types.SET_UPDATABLE, updatable.data.updatable);
  }
  else {
    showRestError(updatable.error, 'messages.cantFetchQuestionnaireTemplateInfo');
  }

  commit(types.SET_LOADING, false);
}

