import { state } from './state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { withLoading } from '../../../../utils/withLoadind';

export const current = withLoading({
  namespaced: true,

  state,
  actions,
  getters,
  mutations
});
