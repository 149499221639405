import { InputType } from '@h4h/inputs';
import { NL } from './phoneComponentUtils';
import { popups } from '../../services/popups';

function getCode(value) {
  if (Array.isArray(value) && value.length) {
    return value[0].countryCode;
  }
  return null;
}

export function checkNotSupportedPhoneCode(input,oldValue, value) {
  if (input.type !== InputType.Phones) {
    return;
  }

  // temporary disable this functionality
  if (input.type === InputType.Phones) {
    return;
  }

  // if (!input.valid) {
  //   return;
  // }
  const oldCode = getCode(oldValue);
  const newCode = getCode(value);
  if (oldCode !== newCode) {
    if (newCode !== NL) {
      popups.showModal({
        message: 'patient.onlyDutchPhonesAreSupportedForSms',
        buttons: [{
          label: 'Ok',
          class: 'btn-primary',
          result: 'ok',
        }],
      });
    }
  }
}
