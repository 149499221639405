import { types } from './types';
import { createInputConfig, getInputsMap, InputType } from '@h4h/inputs';
import { setCronModelMutation } from '../../../components/cronEditor/store/mutations';
import { createCronInput, getDefaultCron } from '../../../components/cronEditor/store/cronInput';
import { shortenNameForDropdown } from '../utils';

export const mutations = {
  [types.SET_CRON_MODEL]: setCronModelMutation,

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
  },

  [types.INIT_POPUP](state, { popupId, questionnaireTemplates, period }) {
    state.popupId = popupId;
    const { cronEnabled, cron, inputs } = getInputsConfig({ questionnaireTemplates, period });
    state.inputs = inputs;
    state.cronEnabled = cronEnabled;
    state.cronInput = cron;
    state.inputsMap = getInputsMap(state.inputs);
  },

};

function getInputsConfig({ questionnaireTemplates, period }) {
  const templates = questionnaireTemplates.filter(template =>
    !period.questionnaires.some(mpo => mpo.questionnaireTemplateId === template.uuid));
  const options = templates.map(questionnaireTemplate => {
    return {
      label: shortenNameForDropdown(questionnaireTemplate.title),
      value: questionnaireTemplate.uuid,
    };
  });
  const inputType = createInputConfig({
    id: 'questionnaireTemplateId',
    type: InputType.Select2,
    label: 'questionnaireTemplates.questionnaireTemplate',
    clearable: false,
    required: true,
    options,
  });
  const name = createTextInputConfig('name', 'common.name', InputType.Text);
  const cronEnabled = createInputConfig({
    id: 'scheduleEnabled',
    label: 'enrollments.schedule',
    type: InputType.Radio,
    toggle: true,
    inline: false,
    value: true,
    options: [
      { value: true, label: 'programs.includeSchedule' },
      { value: false, label: 'programs.noSchedule' },
    ]
  });
  const cron = createCronInput(getDefaultCron());
  cron.id = 'cron';
  return {
    cronEnabled,
    cron,
    inputs: [
      name,
      inputType,
      cronEnabled,
      cron,
    ]
  };
}

function createTextInputConfig(id, label, inputType, required = true, validate) {
  return createInputConfig({
    id,
    type: inputType,
    label,
    required,
    validate,
  });
}

