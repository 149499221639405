import { get } from 'lodash';

export class BatchAction {
  /**
   * @param {Object}    config
   *
   * @param {String}    config.icon
   * @param {String}    config.label
   * @param {String}    config.testId
   * @param {Function}  config.action
   * @param {Function}  config.matcher
   *
   * @param {Boolean}   [config.show]
   * @param {Boolean}   [config.popoverContent]
   * @param {Object}    [config.input]
   * @param {Function}  [config.inputAction]
   * @param {Function}  [config.initPopover]
   * @param {String}    [config.tooltip]
   * @param {String}    [config.popoverClass]
   */

  constructor(config) {
    const { icon, label, action, matcher, show, popoverContent, input, inputAction, initPopover, tooltip, testId, popoverClass } = config;

    if (!icon || !(label || tooltip) || !action || !matcher) {
      throw new Error(`Invalid BatchAction config - ${ JSON.stringify(config) }`);
    }

    this.icon = icon;
    this.label = label;
    this.testId = testId;
    this.action = action;
    this.matcher = matcher;
    this.show = show === undefined ? true : show;
    this.popoverContent = popoverContent;
    this.input = input;
    this.inputAction = inputAction;
    this.initPopover = initPopover;
    this.tooltip = tooltip;
    this.popoverClass = popoverClass;
  }

  static alwaysMatch = () => true;

  static createEqualsMatcher(path, value) {
    return item => get(item, path) === value;
  }

  static createOneOfMatcher(path, valueSet) {
    return item => valueSet.has(get(item, path));
  }

  static createEveryOfMatcher(checks) {
    return item => checks.every(c => c.valueSet.has(get(item, c.path)));
  }

  static createAllSelectedItemsHaveEqualPropsMatcher(path, selectedItems) {
    const value = get(selectedItems[0], path);
    const allItemsHaveSameValue = selectedItems.every(item => get(item, path) === value);
    return item => allItemsHaveSameValue && get(item, path) === value;
  }
}
