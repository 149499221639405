import { InputType } from '@h4h/inputs';
import moment from 'moment';
import { createEhDateInputConfig } from '../../../../utils/factories';
import { EnrollmentStatus } from '../../../../constants/enrollmentStatus';
import { types } from './types';

export const getters = {
  availablePrograms,
  valid,
  newEnrollmentEndDate,
  filteredEnrollments,
  thresholdsVisible,
  isAdjustedSchedule,
  inputsToValidate,
};

function availablePrograms(state) {
  return state.programs
    .filter(programModel => {
      const enrollmentsForThisProgram = state.enrollments.filter(e => e.program.uuid === programModel.uuid);
      return !enrollmentsForThisProgram.some(e => e.active);
    });
}

function inputsToValidate(state) {
  const inputFieldsToValidate = [
    state.newEnrollmentDate,
    state.newEnrollmentProgram,
    state.newEnrollmentPeriod,
    state.newEnrollmentSendDevicesToPatients,
    ...state.observationThresholdInputsGroups];

  for (const schedule of state.newEnrollmentSchedules) {
    if (schedule.scheduleType.value === types.ADJUSTED_SCHEDULE) {
      inputFieldsToValidate.push(schedule.cronSettings);
    }
  }

  return inputFieldsToValidate.filter(input => !!input);
}

function valid(state) {
  return inputsToValidate(state).every(input => input && input.valid);
}

function newEnrollmentEndDate(state, getters) {
  let date;
  if (getters.valid) {
    const program = state.programs.find(program => program.uuid === state.newEnrollmentProgram.value);
    if (program) {
      const period = program.availablePeriods.find(period => period.uuid === state.newEnrollmentPeriod.value);
      if (period && period.duration) {
        date = moment(state.newEnrollmentDate.value).add(period.duration.unit.toLowerCase(), period.duration.value).toDate();
      }
    }
  }
  return createEhDateInputConfig({
    id: 'endDate',
    type: InputType.Date,
    required: false,
    disabled: true,
    value: date,
    label: 'enrollments.deregistrationDate',
  });
}

function filteredEnrollments(state) {
  return {
    [types.ACTIVE_TABLE_NAME]: state.enrollments.filter(e => e.enrollmentStatus !== EnrollmentStatus.DEACTIVATED),
    [types.HISTORY_TABLE_NAME]: state.enrollments.filter(e => e.enrollmentStatus === EnrollmentStatus.DEACTIVATED),
  };
}

function thresholdsVisible(state) {
  if (state.newEnrollmentUseCustomThresholds) {
    return state.newEnrollmentUseCustomThresholds.value;
  }
  return false;
}

function isAdjustedSchedule() {
  return schedule => {
    return schedule.scheduleType.value === types.ADJUSTED_SCHEDULE;
  };
}
