export class TenantOptionModel {
  /**
   * TenantOptionModel config
   *
   * @param {Object}                    config
   * @param {String}                    config.name
   */
  constructor(config) {
    this.name = config.name;
  }

}
