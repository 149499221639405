import { CountryModel } from '../../model/countryModel';

export const getters = {
  countries,
};

function countries(state) {
  if (state.countriesList) {
    return state.countriesList
      .filter(country => country.code)
      .map(country => new CountryModel({
        code2: country.code,
        countryName: country.countryName,
      }));
  }
  else {
    return [new CountryModel({ code2: 'NL' })];
  }
}

