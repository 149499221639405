import { toast } from '../../../services/toast';
import { types } from './types';
import { showRestError } from '../../../utils/errors';
import { localize } from '../../../services/localize';
import {
  delMedicalHistory,
  editMedicalHistory,
  medicalHistories
} from '../../../services/api/simpleAnamnesis/medicalHistories/medicalHistories';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { PopupType } from '@h4h/popups';

export const actions = Object.freeze({
  deleteMedicalHistory,
  reload,
  fetchMedicalHistories,
  showAddDialog,
  showEditDialog,
  createMedicalHistory,
  updateMedicalHistory,
});

async function deleteMedicalHistory({ state, commit }, medicalHistoryId) {
  commit(types.SET_LOADING, true);
  const result = await delMedicalHistory.delete(medicalHistoryId);
  commit(types.SET_LOADING, false);
  if (result.success) {
    toast.success(localize('messages.medicalHistoryDeleted'));
    await reload({ state, commit });
  }
  else {
    showRestError(result.error, 'messages.cantDeleteMedicalHistory');
  }
}

async function reload({ state, commit }) {
  commit(types.SET_LOADING, true);
  commit(types.SET_FETCHED, false);
  const result = await medicalHistories(state.patientUserUuid).fetch();
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_FETCHED, true);
    commit(types.SET_MEDICAL_HISTORIES, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchMedicalHistories');
  }
  // return any result anyway. Empty array is better then null.
  return state.medicalHistories;
}

async function fetchMedicalHistories({ state, commit }, patientUserUuid) {
  commit(types.SET_LOADING, true);
  commit(types.SET_PATIENT_USER_UUID, patientUserUuid);
  const result = await medicalHistories(patientUserUuid).fetch();
  commit(types.SET_LOADING, false);
  if (result.success) {
    commit(types.SET_MEDICAL_HISTORIES, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchMedicalHistories');
  }
  return state.medicalHistories;
}

async function showAddDialog({ commit }) {
  const { id } = await popups.show({
    name: PopupName.AddNewMedicalHistory,
    type: PopupType.Modal
  });
  commit(types.INIT_ADD_POPUP, {
    popupId: id,
  });
}

async function showEditDialog({ commit }, item) {
  const { id } = await popups.show({
    name: PopupName.EditMedicalHistory,
    type: PopupType.Modal
  });
  commit(types.SET_EDITABLE_MEDICAL_HISTORY_UUID, item.uuid);
  commit(types.INIT_EDIT_POPUP, {
    popupId: id,
    value: item.value,
  });
}

async function createMedicalHistory({ state, commit, getters }) {
  if (!getters.valid) {
    return;
  }
  commit(types.SET_LOADING, true);
  const result = await medicalHistories(state.patientUserUuid).create({
    value: state.medicalHistory.value
  });
  if (result.success) {
    popups.hide({ id: state.popupId });
    await reload({ state, commit });
    toast.success(localize('messages.patientFileSaved'));
  }
  else {
    showRestError(result.error, 'messages.cantCreateMedicalHistory');
  }
}

async function updateMedicalHistory({ state, commit, getters }) {
  if (!getters.valid) {
    return;
  }
  commit(types.SET_LOADING, true);
  const result = await editMedicalHistory.update(
    state.editableMedicalHistoryUuid,
    {
      value: state.medicalHistory.value
    });
  if (result.success) {
    popups.hide({ id: state.popupId });
    toast.success(localize('messages.medicalHistoryUpdated'));
    await reload({ state, commit });
  }
  else {
    showRestError(result.error, 'messages.cantUpdateMedicalHistory');
  }
}
