import { KardiaConnectionStatus } from '../../../constants/kardia/kardiaConnectionStatus';

export const kardia = {
  cantFetchKardiaPatientInformation: 'Kan Kardia-patiëntgegevens niet ophalen',
  alivecorKardia: 'AliveCor Kardia',
  kardia: 'Kardia',
  requestNewConnectionCode: 'Vraag een nieuwe verbindingscode aan',
  errorPerformRequest: 'Fout bij uitvoeren van verzoek',
  requestSuccessfullyCreatedMessage: 'Vraag de patiënt om de e-mail te controleren op verbindingscode',
  kardiaConnectionStatus: 'Alivecor Kardia verbindingsstatus',
  [KardiaConnectionStatus.CONNECTED]: 'Verbonden',
  [KardiaConnectionStatus.PENDING]: 'Verbinding afwachten',
  [KardiaConnectionStatus.DISCONNECTED]: 'Niet verbonden',
};
