import { isEqual } from 'lodash';

export const ColumnSorting = {
  props: {
    // sorting can be controlled externally by using the individual props
    sorting: {
      type: Array,
      default: () => []
    },

    // initial sorting options
    serverSideOptions: Object
  },

  data() {
    return {
      columnSort__sortBy: [],
      columnSort__sortDesc: []
    };
  },

  watch: {
    ['$props.sorting']: {
      handler() {
        this.columnSort__updateSorting(this.$props.sorting, this.$props.serverSideOptions);
      },
      deep: true
    },
    ['$props.serverSideOptions']: {
      handler(newValue) {
        if (!isEqual(newValue.sortBy, this.columnSort__sortBy) ||
          !isEqual(newValue.sortDesc, this.columnSort__sortDesc)) {
          this.columnSort__updateSorting(this.$props.sorting, this.$props.serverSideOptions);
        }
      }
    }
  },

  mounted() {
    this.columnSort__updateSorting(this.$props.sorting, this.$props.serverSideOptions);
  },

  methods: {
    columnSort__getColumnSorting(column) {
      const index = this.columnSort__sortBy.indexOf(column.sortKey || column.valueKey);
      const isSortedBy = index !== -1;
      return {
        isSortedBy,
        desc: isSortedBy && this.columnSort__sortDesc[index]
      };
    },

    columnSort__changeColumnSorting(column) {
      if (!column.sortable) {
        return;
      }

      const index = this.columnSort__sortBy.indexOf(column.sortKey);

      if (index === -1) {
        return this.columnSort__updateSorting([{
          sortKey: column.sortKey,
          desc: false
        }]);
      }

      const prevDirectionDesc = this.columnSort__sortDesc[index];

      if (!prevDirectionDesc) {
        return this.columnSort__updateSorting([{
          sortKey: column.sortKey,
          desc: true
        }]);
      }

      this.columnSort__updateSorting([]);
    },

    columnSort__updateSorting(sorting, serverSideOptions) {
      this.columnSort__sortBy = [];
      this.columnSort__sortDesc = [];
      if (serverSideOptions) {
        this.columnSort__sortBy = serverSideOptions.sortBy || [];
        this.columnSort__sortDesc = serverSideOptions.sortDesc || [];
      }
      else if (sorting) {
        sorting.forEach(col => {
          this.columnSort__sortBy.push(col.sortKey || col.name); // name as fallback value when sortKey is not provided
          this.columnSort__sortDesc.push(!!col.desc);
        });
      }
    }
  }
};
