export const state = {
  loading: false,
  patient: null,
  enrollment: null,
  pageInitialized: false,
  inputs: [],
  observations: [],
  observationTemplates: [],
  questionnaires: [],
  questionnaireTemplates: [],
  treatmentAdvicePopupId: null,
  treatmentAdviceAgreedPopupId: null,
  treatmentAdviceText: null,
  hasFeedback: false,
  treatmentAdviceAgreedComment: null,
  editSchedule: {
    popupId: null,
    cronModel: null,
    programInput: null,
  }
};
