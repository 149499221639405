import { types } from './types';
import { popups, PopupType } from '@h4h/popups';
import { PopupName } from '../../../../../../../../constants/popupName';
import { evalPopupTopForIframeIfNeed } from '../../../../../../../../utils/popupTopForIframeEvaluator';

export const actions = Object.freeze({
  initDialog,
  close,
});

async function initDialog({ commit }, { ecg, rawEcg }) {
  commit(types.SET_DATA, { ecg, rawEcg });
  const { id } = await popups.show({
    name: PopupName.EcgPreviewPopup,
    type: PopupType.Modal,
    props: {
      ...evalPopupTopForIframeIfNeed(300),
    }
  });
  commit(types.SET_POPUP_ID, id);
}

function close({ state }) {
  popups.hide({ id: state.popupId });
}
