import { types } from './types';
import { patients } from '../../../services/api/patients/patients';
import { showRestError } from '../../../utils/errors';
import { router } from '../../../services/router';
import { Page } from '../../../constants/page';
import { isCallCenter } from '../../../services/auth/auth';

export const actions = Object.freeze({
  loadPatient,
});

async function loadPatient({ commit }, id) {

  commit(types.SET_PATIENT, null);
  commit(types.SET_LOADING, true);
  const result = await patients.get(id);

  if (result.success) {
    commit(types.SET_PATIENT, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantLoadPatient');
    if (isCallCenter()) {
      router.push({ name: Page.CallCenterPatientsList });
    }
    else {
      router.push({ name: Page.PractitionerPatientsList });
    }
  }
  commit(types.SET_LOADING, false);
  return result.success;
}
