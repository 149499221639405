import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig, getInputsMap } from '@h4h/inputs';
import { ehRoleOptionsSupportedForSelect } from '../../../services/auth/ehRole';
import { EhGroupFieldsLengthConstraints } from '../../../utils/ehGroupFieldsLengthConstraints';
import { validateRoles } from '../utils';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.INIT_FORM](state) {
    const inputs = getInputsConfig(state.group);
    state.inputs = inputs;
    state.inputsMap = getInputsMap(inputs);
  },

  [types.SET_GROUP](state, group) {
    state.group = group;
  },

  [types.RESET_FORM](state) {
    state.inputs = null;
    state.inputsMap = null;
  },
};

function getInputsConfig(group) {
  const name = createTextInputConfig(group.name, 'name', 'common.name', true, EhGroupFieldsLengthConstraints.name);

  const defaultForRole = createInputConfig({
    id: 'defaultForRole',
    type: InputType.Select2,
    value: group.defaultForRole,
    label: 'group.defaultForRole',
    options: ehRoleOptionsSupportedForSelect,
  });

  const roles = createInputConfig({
    id: 'roles',
    type: InputType.Pills,
    label: 'common.roles',
    options: ehRoleOptionsSupportedForSelect,
    value: new Set(group.roles),
    validate: validateRoles
  });

  return [
    name,
    defaultForRole,
    roles
  ];
}

function createTextInputConfig(value = null, id, label, required = false, constraints) {
  return createInputConfig({
    id,
    value,
    type: InputType.Text,
    required,
    label,
    ...constraints,
  });
}
