<template>
  <VBadge
    v-bind="$props"
    :class="styles.mpbadge"
    :color="setColor"
    :title="$localize($props.title)"
  >
    <slot/>
    <Icon
      v-if="$props.icon"
      :type="icon"
      :class="styles.mpicon"
    />
  </VBadge>
</template>

<script>
  import { VBadge } from 'vuetify/lib';
  import styles from './badge.scss';
  import { Icon } from '@h4h/icons';
  import { ComponentStyle } from '@h4h/theme';

  export default {
    name: 'H4hBadge',

    components: {
      VBadge,
      Icon
    },

    props: {
      avatar: Boolean,
      bordered: Boolean,
      color: {
        type: String,
        validator(value) {
          return !!ComponentStyle[value];
        }
      },
      dot: Boolean,
      content: [String, Number],
      icon: String,
      overlap: Boolean,
      inline: Boolean,
      tile: Boolean,
      title: [String, Array],
      offsetX: [Number, String],
      offsetY: [Number, String]
    },

    data() {
      return {
        styles
      };
    },

    computed: {
      setColor() {
        switch (this.color) {
          case ComponentStyle.Info:
            return styles.infoColor;
          case ComponentStyle.Error:
            return styles.errorColor;
          case ComponentStyle.Warning:
            return styles.warningColor;
          case ComponentStyle.Success:
            return styles.successColor;
          default:
            return styles.defaultColor;
        }
      }
    }
  };
</script>
