<template>
  <div :class="styles.list">
    <Pill
      v-for="(option, index) in options"

      :key="index"
      :label="option.label"
      :showSelectIcon="$props.showSelectIcon"
      :squaredButtons="$props.squaredButtons"
      :pillStyle="$props.pillStyle"
      :active="isActive(option)"
      :tabindex="$props.tabindex"
      @focus="$emit('focus', $event)"
      @click="toggleOption(option)"
    />
  </div>
</template>

<script>
  import { uniqueId } from 'lodash';

  import { inputProps } from '../../utils';

  import Pill from './Pill';
  import styles from './pills.scss';

  export default {
    name: 'H4hPillsOptions',

    components: {
      Pill
    },

    props: {
      value: [Object, String, Number, Boolean, Set],

      options: {
        type: Array,
        required: true
      },

      optionAll: Object,
      tabindex: inputProps.tabindex,

      /** flags */
      disabled: inputProps.booleanFalse,
      showSelectIcon: inputProps.booleanTrue,
      squaredButtons: inputProps.booleanFalse,
      pillStyle: inputProps.style,
    },

    data() {
      return {
        styles,
        id: uniqueId('pills-')
      };
    },

    methods: {
      selectOption(option) {
        if (this.$props.disabled || this.isActive(option)) {
          return;
        }

        const { value, options, optionAll } = this.$props;
        let newValues = [option.value];

        if (optionAll && option.value === optionAll.value) {
          newValues = options.map(o => o.value);
        }

        if (!value) {
          this.$emit('change', new Set(newValues));
        }
        else if (!value.has(option.value)) {
          this.$emit('change', new Set([...value, ...newValues]));
        }
      },

      deselectOption(option) {
        const { disabled, value, optionAll } = this.$props;

        if (disabled || !this.isActive(option)) {
          return;
        }

        let newValue = new Set(value);
        if (optionAll && option.value === optionAll.value) {
          newValue = new Set([]);
        }
        else if (optionAll) {
          newValue.delete(optionAll.value);
        }

        newValue.delete(option.value);

        this.$emit('change', newValue);
      },

      toggleOption(option) {
        if (this.$props.disabled) {
          return;
        }

        if (this.isActive(option)) {
          this.deselectOption(option);
        }
        else {
          this.selectOption(option);
        }
      },

      isActive(option) {
        const { value } = this.$props;

        return value && value.has(option.value);
      }
    }
  };
</script>
