import { merge } from 'lodash';

import { createStoreModule } from '../factories';
import { ConfirmationResult, PopupName, PopupType } from '../constants';

const storeConnectionError = new Error('Popup service is not connected to Vuex store');

export class PopupService {
  /**
   * @param {Record<PopupName, Vue.Component>} componentsMap
   */
  constructor(componentsMap = {}) {
    this._componentsMap = componentsMap;
  }

  /**
   * The service works as a proxy for Vuex store via this plugin
   * You *must* pass this plugin to Vuex store, otherwise you'll get storeConnectionError when trying to show a popup
   *
   * @param {Vuex.Store} store
   */
  plugin = store => {
    this._store = store;
    this._store.registerModule('popups', createStoreModule());
  };

  /**
   * The service injects itself in components to prevent circular dependencies like
   * popupService.js => TabbedPopup.vue => popupService.js
   *
   * @param {typeof Vue} Vue
   */
  install(Vue) {
    // inject to be available in components
    Vue.prototype.$popups = this;
  }

  /**
   * Registers custom popup components to be later consumed by Popup.vue
   * You *must* register your custom popups, otherwise they won't be shown
   *
   * @param {String} name
   * @param {Vue.Component|(() => Promise<Vue.Component>)} component
   */
  registerComponent(name, component) {
    this._componentsMap[name] = component;
  }

  /**
   * Shows any registered popup
   *
   * @param   {PopupType}    type
   * @param   {String}       name
   * @param   {Boolean}      closeOnClickOutside
   * @param   {Object}       [props]
   *
   * @returns {Promise<{ id: string, closed: Promise<{ success: boolean, data?: any, error?: string }> }>} - popup close result
   */
  async show({ type, name, closeOnClickOutside, props }) {
    if (!this._store) {
      throw storeConnectionError;
    }

    const { id } = await this._store.dispatch('popups/show', {
      type,
      name,
      closeOnClickOutside,
      props
    });

    return {
      id,
      closed: this.getCloseResult(id)
    };
  }

  /**
   * Shows any registered popup
   *
   * @param   {Object}  [props]
   *    props is an object containing:
   *    @param {Number}     width
   *    @param {PopupTab[]} tabs
   *
   * @param   {Boolean} closeOnClickOutside
   *
   * @returns {Promise<{ id: string, closed: Promise<{ success: boolean, data?: any, error?: string }> }>} - popup close result
   */
  async showTabbed({ props, closeOnClickOutside }) {
    if (!this._store) {
      throw storeConnectionError;
    }

    const { id } = await this._store.dispatch('popups/show', {
      type: PopupType.Modal,
      name: PopupName.TabbedPopup,
      closeOnClickOutside,
      props
    });

    return {
      id,
      closed: this.getCloseResult(id)
    };
  }

  /**
   * Shows a modal
   *
   * @param modalConfig      Object with props compatible with src/components/modal/Modal.vue
   * @param {String}         dataTestId
   * @param {String|Array}   title
   * @param {String|Array}   subtitle
   * @param {String|Array}   message
   * @param {Object[]}       [buttons]
   * @param {String}         [className]
   * @param {Boolean}        [isMsgHtml]
   * @param {ComponentStyle} [modalStyle] // not in use
   * @returns {Promise<{ id: string, closed: Promise<{ success: boolean, data?: any, error?: string}> }>} - modal close result
   */
  async showModal({ dataTestId, title, subtitle, message, buttons, className, modalStyle, isMsgHtml }) {
    if (!this._store) {
      throw storeConnectionError;
    }

    const { id } = await this._store.dispatch('popups/showModal',
      { dataTestId, title, subtitle, message, buttons, className, modalStyle, isMsgHtml }
    );

    return {
      id,
      closed: this.getCloseResult(id)
    };
  }

  // @todo: Baizulin - refactor to provide same response format as other methods to be able to close programmatically
  /**
   * Shows a simple confirmation modal
   *
   * @param modalConfig
   * @param {String}                 title
   * @param {String | Array<string>} message
   * @param {ComponentStyle}         [style]
   * @param {{ confirm, decline }}   [buttons]
   *
   * @returns {Promise<{ confirmed: boolean, declined: boolean }>} - modal close result
   */
  async confirm({ dataTestId, title, message, modalStyle, buttons }) {
    buttons = merge(
      {
        confirm: {
          label: 'common.yes',
          primary: true,
          result: ConfirmationResult.Confirm
        },

        decline: {
          label: 'common.no',
          primary: true,
          outlined: true,
          result: ConfirmationResult.Decline
        }
      },
      buttons
    );

    const { closed } = await this.showModal({
      dataTestId,
      title,
      modalStyle,
      message,
      buttons: [
        buttons.decline,
        buttons.confirm
      ]
    });

    const closeResult = await closed;
    const confirmed = closeResult.success && closeResult.data === ConfirmationResult.Confirm;

    return {
      confirmed,
      declined: !confirmed
    };
  }

  /**
   * Hides any shown popup
   *
   * @param {String} id
   * @param {any}    result
   */
  hide({ id, result }) {
    if (!this._store) {
      throw storeConnectionError;
    }

    this._store.dispatch('popups/hide', { id, result });
  }

  /**
   * Shows a loading spinner in popup
   *
   * @param {String} [label]
   *
   * @returns {Promise<{ id: string, closed: Promise<{ success: boolean, data?: any, error?: string }> }>} - popup close result
   */
  showLoading({ label }) {
    return this.show({
      type: PopupType.Modal,
      name: PopupName.LoadingPopup,
      props: {
        label
      }
    });
  }

  /**
   * Returns a promise that will be resolved when the popup will be closed
   *
   * @param {String} popupId
   *
   * @returns {Promise<{ success: boolean, data?: any, error?: string }>} - popup close result
   */
  async getCloseResult(popupId) {
    if (!this._store) {
      throw storeConnectionError;
    }

    return new Promise(resolve => {
      const unsubscribe = this._store.subscribeAction(function({ type, payload }) {
        if (type === 'popups/hide' && payload.id === popupId) {
          resolve({
            success: true,
            data: payload.result
          });
          unsubscribe();
        }
      });
    });
  }

  /**
   * Returns a registered popup component if there is one
   *
   * @param {String} popupName
   *
   * @returns {Function|null}
   */
  getComponent(popupName) {
    return popupName && this._componentsMap[popupName] || null;
  }
}
