<template>
  <div
    :class="[
      styles.radio,
      inputStyles.container,
      $props.toggle && styles.toggle,
      $props.inline && inputStyles.inline
    ]"
    :data-testid="`field-${$props.name}`"
  >
    <LabelGroup
      v-if="$props.label"
      :info="$props.info"
      :label="$props.label"
      :otherLabel="$props.otherLabel"
      :labelStyle="$props.labelStyle"
    />
    <VRadioGroup
      :id="radioId"
      :value="_value"
      :multiple="$props.multiple"
      :disabled="$props.disabled"
      :readonly="$props.readonly"
      :class="[inputStyles.radioGroup, $props.disabled && styles.disabled]"
      :row="$props.inline || $props.toggle || $props.horizontal"
      :mandatory="$props.required"
      @mousedown="onMouseDown"
      @change="onChange"
    >
      <VRadio
        v-for="option in $props.options"
        ref="componentRef"
        :key="option.id"
        :label="$localize(option.label)"
        :value="option.value"
        :disabled="option.disabled"
        :class="{ [styles.withoutPadding]: hasSlot('label'), [styles.iconOnly]: option.icon && !option.label }"
        :data-testid="dataTestId(option.dataTestId || option.value)"
        :tabindex="tabIndex"
        @focus="tabIndexSubscriber__onFocus"
        @keydown.enter="onKeydown(option)"
      >
        <template #label>
          <div
            ref="toggleRef"
            :class="styles.radioSlotWrapper"
            :tabindex="[$props.toggle && tabIndex]"
            @focus="tabIndexSubscriber__onFocus"
            @keydown.enter="onKeydown(option)"
          >
            <Icon
              v-if="$props.toggle && option.icon"
              :type="option.icon"
              :title="$localize(option.tooltip)"
              :class="styles.optionIcon"
            />
            <span
              v-if="option.label"
              :class="{ [styles.iconLabel]: option.icon}"
            >
              {{ $localize(option.label) }}
            </span>
            <div
              v-if="option.sublabel"
              :class="option.sublabelStyle || styles.sublabel"
            >
              {{ $localize(option.sublabel) }}
            </div>
          </div>
        </template>
      </VRadio>

      <OtherOption
        v-if="otherOptionInputConfig"
        :inputConfig="otherOptionInputConfig"
        :parentType="InputType.Radio"
        @change-other-option-input="onOtherChange"
      />
    </VRadioGroup>
  </div>
</template>

<script>
  import { uniqueId } from 'lodash';
  import { VRadioGroup, VRadio } from 'vuetify/lib';

  import { hasSlot } from '@h4h/utils';
  import { inputs as inputStyles } from '@h4h/theme/styles/shared';
  import { Icon } from '@h4h/icons';

  import { inputProps } from '../../utils';
  import { InputMixin } from '../../mixins';

  import LabelGroup from '../labelGroup/LabelGroup';

  import styles from './radio.scss';
  import { ArrayProp } from '@h4h/classes';
  import OtherOption from '../otherOption/OtherOption';
  import { isOtherOptionInputWithParentId } from '../../utils/factories';
  import { InputType } from '../../constants/inputType';

  export default {
    name: 'H4hRadio',

    components: {
      OtherOption,
      VRadioGroup,
      VRadio,
      LabelGroup,
      Icon
    },
    mixins: [
      InputMixin
    ],

    model: {
      prop: 'value',
      event: 'change'
    },

    props: {
      id: String,
      name: String,
      value: [Object, String, Number, Boolean, Set, Date],
      /**
       * Options - for radio buttons
       * @value {Object[]} options
       *    @property {String} id
       *    @property {String} label - label of option
       *    @property {String} icon - icon of option
       *    @property {String} tooltip - tooltip, hover over icon
       *    @property {Object, String, Number, Boolean, Set, Date} value - value of option - required
       *    @property {String} sublabel - sub label of option
       *    @property {String} sublabelStyle - style of sublabel
       */
      options: {
        type: Array,
        required: true
      },

      linkedInputs: new ArrayProp({
        type: Object,
        required: false
      }),

      /** labels */
      info: inputProps.label,
      otherLabel: inputProps.label,
      placeholder: inputProps.label,

      /** flags */
      horizontal: inputProps.booleanTrue,
      inline: inputProps.booleanFalse,
      toggle: inputProps.booleanFalse,
      readonly: inputProps.booleanFalse,
      required: inputProps.booleanFalse,
      multiple: inputProps.booleanFalse,
      autofocus: inputProps.booleanFalse,

      /** styles */
      labelStyle: inputProps.style
    },

    data() {
      return {
        styles,
        hasSlot,
        inputStyles,
        InputType,
        radioId: uniqueId('radio-')
      };
    },

    computed: {
      _value() {
        const { value } = this.$props;

        if (value instanceof Set) {
          return Array.from(value);
        }

        return value;
      },

      otherOptionInputConfig() {
        return this.$props.linkedInputs?.find(i => isOtherOptionInputWithParentId(i, this.$props.id));
      }
    },

    methods: {

      emitChange(value) {
        this.$emit('change', value);
      },

      onChange(value) {
        this.emitChange(value);
      },
      onMouseDown(e) {
        if (!this.$props.disabled) {
          this.$emit('mousedown', e);
        }
      },
      onOtherChange(value) {
        this.$emit('change-other-option-input', { input: this.otherOptionInputConfig, value });
      },
      focus() {
        this.$nextTick(() => {
          if (this.$props.toggle) {
            return this.$refs.toggleRef[0].focus();
          }
          if (this.$props.autofocus) {
            return this.$refs.componentRef[0].$el.querySelector('input[type="radio"]').focus();
          }
        });
      },
      onKeydown(option) {
        this.emitChange(option.value);
      },
      dataTestId(value) {
        const formattedValue = (value + '').toLowerCase().replace(/(\s|\.)/g, '') || '-';
        return `radio-value-${ formattedValue }`;
      },
    }
  };
</script>
