<template>
  <div :class="styles.linkActionButton">
    <div
      :class="[styles.action, $props.disabled && 'disabled']"
      :title="$localize(action.label)"
      @click="onActionButtonClick"
    >
      <Icon
        type="link"
        :label="'wysiwyg.link'"
      />
    </div>

    <Popover
      ref="popover"
      :closeOnContentClick="false"
      :offsetY="true"
    >
      <div
        :class="styles.linkFields"
        @keyup.enter="emitLink"
      >
        <div :class="styles.linkField">
          <TextInput
            v-bind="linkInput"
            :class="styles.linkInput"
            @change="setInputValue({ input: linkInput, value: $event })"
          />
        </div>

        <div :class="styles.linkField">
          <TextInput
            v-bind="linkLabelInput"
            :class="styles.linkInput"
            @change="setInputValue({ input: linkLabelInput, value: $event })"
          />
        </div>
      </div>
    </Popover>
  </div>
</template>

<script>
  import { uniqueId } from 'lodash';

  import { Popover } from '@h4h/popover';

  import { InputType } from '../../constants';
  import { createInputConfig, setInputValue } from '../../utils';
  import { urlRegex, wysiwygActions } from '../../utils/wysiwygUtils';

  import TextInput from '../Text';

  import styles from './wysiwyg.scss';

  export default {
    name: 'H4hLinkAction',

    components: {
      TextInput,
      Popover
    },

    props: {
      disabled: Boolean
    },

    data() {
      return {
        styles,

        setInputValue,
        showPopover: false,

        action: wysiwygActions.link,

        linkInput: createInputConfig({
          id: uniqueId('wysiwygLinkInput-'),
          type: InputType.Text,
          pattern: urlRegex,
          required: true,
          icon: 'link',
        }),

        linkLabelInput: createInputConfig({
          id: uniqueId('wysiwygLinkLabelInput-'),
          type: InputType.Text,
          required: true,
          icon: 'align-left',
        })
      };
    },

    methods: {
      onActionButtonClick() {
        this.resetInput(this.linkInput);
        this.resetInput(this.linkLabelInput);

        this.$refs.popover.show();
      },

      resetInput(input) {
        Object.assign(input, {
          value: null,
          valid: false,
          pristine: true
        });
      },

      emitLink() {
        if (!this.linkInput.valid || !this.linkLabelInput.valid) {
          return;
        }

        this.$refs.popover.hide();

        this.$emit('action', {
          command: 'createLink',
          link: this.linkInput.value,
          linkText: this.linkLabelInput.value
        });
      }
    }
  };
</script>
