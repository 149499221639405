export const state = {
  subject: null,
  text: null,

  baseUrl: null,
  organizationSetting: null,

  popupId: null,
  chooseTaskPopupId: null,
  physicianId: null,
  callCenterBaseUrl: null,
  forwardingInProgress: false,
};
