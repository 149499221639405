function matches(object, keyOrPartOfKey) {
  return object.key.toLowerCase().indexOf(keyOrPartOfKey.toLowerCase()) > -1;
}

export function findChildByKey(object, keyOrPartOfKey) {
  if (matches(object, keyOrPartOfKey)) {
    return object;
  }
  if (Array.isArray(object.children)) {
    for (let i = 0; i < object.children.length; i++) {
      const foundItem = findChildByKey(object.children[i], keyOrPartOfKey);
      if (foundItem) {
        return foundItem;
      }
    }
  }
}
