import { list } from './list';
import { add } from './add';
import { profile } from './profile';
import { current } from './current';

export const users = {
  namespaced: true,
  modules: {
    add,
    list,
    profile,
    current,
  }
};
