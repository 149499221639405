import {
  callCenterSetting,
  organizationSetting,
  organizationSettingDisableCallCenter,
  organizationSettingEnableCallCenter,
  setLogisticsEnableState
} from '../../services/api/organization-setting/organization-setting';
import { timezones } from '../../services/api/timezone/timezone';
import { mapValues } from 'lodash';
import { toast } from '../../services/toast';
import { types } from './types';
import { showRestError } from '../../utils/errors';
import { localize } from '../../services/localize';
import { router } from '../../services/router';
import { extractAddressReferenceInputs } from '../../utils/addressComponentUtils';
import { popups } from '../../services/popups';
import { PopupName } from '../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { fetchCountries } from '../countries/utils';
import { getHeadersXTenantId } from '../../utils/getHeaders';
import { convertPhonesToStrings } from '../../utils/phone/phoneComponentUtils';

export const actions = Object.freeze({
  updateOrganizationSetting,
  fetchOrganizationSetting,
  getOrganizationSetting,
  fetchCallCenterSettings,
  enableCallCenter,
  disableCallCenter,
  callCenterCheckboxChange,

  enableLogistics,
  disableLogistics,
  logisticsCheckboxChange,

  hidePopup,
  initViewForm,
  initEditForm,
});
let groupedPromise;

async function fetchOrganizationSetting({ state, commit }) {
  if (state.fetched) {
    return state.organizationSetting;
  }

  if (groupedPromise) {
    await groupedPromise;
    return state.organizationSetting;
  }

  commit(types.SET_LOADING, true);
  commit(types.SET_FETCHED, false);
  groupedPromise = Promise.all([organizationSetting.fetchOne(), timezones.fetchOne()]);
  const [result, timezonesResult] = await groupedPromise;
  groupedPromise = null;
  commit(types.SET_LOADING, false);

  if (timezonesResult.success) {
    commit(types.SET_TIMEZONES, timezonesResult.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchTimezones');
  }
  if (result.success) {
    commit(types.SET_FETCHED, true);
    commit(types.SET_ORGANIZATION_SETTING, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchOrganizationSetting');
  }
  return state.organizationSetting;
}

// just load organization settings. Don't store in cache, can be usefull for callcenter, where tenant depends on task or practitioner
async function getOrganizationSetting(_, { tenantId }) {
  let config = {};
  if (tenantId) {
    config = getHeadersXTenantId(tenantId);
  }
  const [organizationSettingResult, timezonesResult] = await Promise.all([organizationSetting.fetchOne(config), timezones.fetchOne(config)]);
  const result = {};
  if (timezonesResult.success) {
    result.timezones = timezonesResult.data;
  }
  else {
    showRestError(result.error, 'messages.cantFetchTimezones');
  }
  if (organizationSettingResult.success) {
    result.organizationSetting = organizationSettingResult.data;
  }
  else {
    showRestError(organizationSettingResult.error, 'messages.cantFetchOrganizationSetting');
  }
  return result;
}

async function updateOrganizationSetting({ state, commit, getters }, { returnPage, addressRef }) {
  commit(types.SET_ADDRESS_COMPONENTS_PRISTINE, {
    components: extractAddressReferenceInputs(addressRef),
    value: false,
  });
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }
  const updatedOrganizationSetting = {
    ...state.organizationSetting,
    ...mapValues(state.inputsMap, i => i.value),
    customForwardingLinkToDoctor: state.customForwardingLinkToDoctor.value,
  };
  convertPhonesToStrings(updatedOrganizationSetting, ['phoneNumber']);

  commit(types.SET_LOADING, true);

  const result = await organizationSetting.updateOne(updatedOrganizationSetting);
  commit(types.SET_LOADING, false);

  if (result.success) {
    commit(types.SET_ORGANIZATION_SETTING, result.data);
    toast.success(localize('messages.organizationSettingUpdated'));
  }
  else {
    showRestError(result.error, 'messages.cantCreateOrganizationSetting');
  }

  if (result.success) {
    router.push({ name: returnPage });
  }
}

async function fetchCallCenterSettings({ commit, state }) {
  commit(types.SET_CC_SETTINGS_LOADING, true);
  const result = await callCenterSetting.fetchOne();
  if (result.success) {
    commit(types.SET_CC_SETTINGS, result.data);
  }
  else {
    showRestError(result.error, 'messages.cantFetchOrganizationCallCenterSetting');
  }
  commit(types.SET_CC_SETTINGS_LOADING, false);
  return state.callCenterSettings;
}

async function callCenterCheckboxChange({ commit }, setEnabled) {
  if (!setEnabled) {
    const { id } = await popups.show({
      name: PopupName.CallCenterDisablePopup,
      type: PopupType.Modal
    });
    commit(types.INIT_POPUP, id);
  }
  else {
    const { id } = await popups.show({
      name: PopupName.CallCenterEnablePopup,
      type: PopupType.Modal
    });
    commit(types.INIT_POPUP, id);
  }
}

async function logisticsCheckboxChange({ commit }, setEnabled) {
  if (!setEnabled) {
    const { id } = await popups.show({
      name: PopupName.LogisticsDisablePopup,
      type: PopupType.Modal
    });
    commit(types.INIT_POPUP, id);
  }
  else {
    const { id } = await popups.show({
      name: PopupName.LogisticsEnablePopup,
      type: PopupType.Modal
    });
    commit(types.INIT_POPUP, id);
  }
}

function hidePopup({ state }) {
  popups.hide({ id: state.activePopupId });
}

async function disableCallCenter({ state, commit, dispatch }) {
  commit(types.SET_CC_SETTINGS_LOADING, true);
  const result = await organizationSettingDisableCallCenter.create();
  if (result.success) {
    toast.success(localize('messages.callCenterSuccessfullyDisabled'));
    hidePopup({ state });
  }
  else {
    showRestError(result.error, 'messages.cantDisableCallCenter');
  }

  dispatch('fetchCallCenterSettings');
}

async function enableCallCenter({ state, commit,dispatch }) {
  commit(types.SET_CC_SETTINGS_LOADING, true);
  const result = await organizationSettingEnableCallCenter.create();
  if (result.success) {
    toast.success(localize('messages.callCenterSuccessfullyEnabled'));
    hidePopup({ state });
  }
  else {
    showRestError(result.error, 'messages.cantEnableCallCenter');
  }
  dispatch('fetchCallCenterSettings');
}

async function disableLogistics({ state, commit,dispatch }) {
  commit(types.SET_CC_SETTINGS_LOADING, true);
  const result = await setLogisticsEnableState(false);
  if (result.success) {
    toast.success(localize('organization.logisticsDisabled'));
    hidePopup({ state });
  }
  else {
    showRestError(result.error, 'organization.cantDisableLogistics');
  }
  dispatch('fetchCallCenterSettings');
}

async function enableLogistics({ state, commit, dispatch }) {
  commit(types.SET_CC_SETTINGS_LOADING, true);
  const result = await setLogisticsEnableState(true);
  if (result.success) {
    toast.success(localize('organization.logisticsEnabled'));
    hidePopup({ state });
  }
  else {
    showRestError(result.error, 'organization.cantEnableLogistics');
  }
  dispatch('fetchCallCenterSettings');
}

async function initViewForm({ commit, dispatch }, { isAdmin }) {
  const countries = await fetchCountries(dispatch);
  commit(types.INIT_FORM, { readOnly: true, isAdmin, countries });
}

async function initEditForm({ commit, dispatch }) {
  const countries = await fetchCountries(dispatch);
  commit(types.INIT_FORM, { readOnly: false, isAdmin: true, countries });
}

