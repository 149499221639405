import { CrudService } from '../../../classes/crudService';
import { EnrollmentModel } from '../../../model/enrollmentModel';
import { BatchCreationResultModel } from '../../../model/BatchCreationResultModel';

export const createEnrollments = new CrudService(
  'enrollments',
  data => new EnrollmentModel(data)
);

/**
 *
 * @param {StopEnrollmentModel} model
 * @returns {Promise<{data: BatchCreationResultModel, success: boolean}|{success: boolean, error: *}|undefined>}
 */
export function stopEnrollments(model) {
  return new CrudService(
    '/enrollments/stop/batch',
    data => new BatchCreationResultModel(data),
    model => model
  ).updateOne(model);
}

export const enrollments = new CrudService(
  'enrollments-for-org-user',
  data => new EnrollmentModel(data)
);

export function fetchEnrollments(patientUuid) {
  return enrollments.fetch({ params: { patientUuid } });
}

export function fetchEnrollment(patientUuid, enrollmentUuid) {
  return enrollments.get(enrollmentUuid, { params: { patientUuid } });
}

export const updateEnrollmentProgramSchedule = (enrollmentUuid, programInputId) => new CrudService(
  `enrollments-for-org-user/${ enrollmentUuid }/program-inputs/${ programInputId }/cron`,
  data => data,
  model => model,
);
