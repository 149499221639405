<template>
  <SelectTemplate
    ref="component"
    :component="component"
    :errorMessage="errorMessage"
    v-bind="$props"
    @change="$emit('change', $event)"
    @search="$emit('search', $event)"
    @blur="onBlur"
    @focus="$emit('focus', $event)"
    @click="$emit('click', $event)"
    @keydown="$emit('keydown', $event)"
  >
    <template
      v-if="hasSlot('item')"
      #item="itemProps"
    >
      <slot
        name="item"
        v-bind="itemProps"
      />
    </template>

    <template
      v-if="hasSlot('selection')"
      #selection="itemProps"
    >
      <slot
        name="selection"
        v-bind="itemProps"
      />
    </template>

    <template
      v-if="hasSlot('chipSelectionContent')"
      #chipSelectionContent="itemProps"
    >
      <slot
        name="chipSelectionContent"
        v-bind="itemProps"
      />
    </template>

    <template
      v-if="hasSlot('itemLabel')"
      #itemLabel="itemProps"
    >
      <slot
        name="itemLabel"
        v-bind="itemProps"
      />
    </template>
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotScope"
    >
      <slot
        :name="name"
        v-bind="slotScope"
      />
    </template>
    <template
      v-for="(_, name) in $slots"
      :slot="name"
    >
      <slot :name="name"/>
    </template>
  </SelectTemplate>
</template>

<script>
  import { VSelect } from 'vuetify/lib';

  import { hasSlot, validationHelper } from '@h4h/utils';

  import { selectProps } from '../../utils';
  import { InputMixin } from '../../mixins';

  import SelectTemplate from './SelectTemplate';

  export default {
    name: 'H4hSelect2',

    components: {
      SelectTemplate,
    },

    mixins: [
      InputMixin,
      validationHelper
    ],

    model: {
      prop: 'value',
      event: 'change'
    },

    props: selectProps,

    data() {
      return {
        hasSlot,
        component: VSelect
      };
    },

    mounted() {
      if (this.$props.autofocus) {
        this.focus();
      }
    },

    methods: {
      focus() {
        this.$refs.component.focus();
      },
      onBlur(e) {
        this.validateRules();
        this.$emit('blur', e);
      }
    }
  };
</script>
