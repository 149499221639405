import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig } from '@h4h/inputs';
import { EhPatterns } from '../../utils/ehPatterns';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_TENANTS](state, value) {
    state.tenants = value;
  },

  [types.INIT_FORM](state) {
    state.tenants = null;
    state.input = createInputConfig({
      id: 'email',
      label: 'tenant.fillInYourEmail',
      placeholder: 'common.email',
      type: InputType.Text,
      pattern: EhPatterns.email,
      required: true,
    });
  },

  [types.SET_PRISTINE](state, value) {
    state.input.pristine = value;
  },
};

