import { OTHER_OPTION_VALUE } from '@h4h/utils';
import { Enum } from './enum';

const otherOption = {
  value: OTHER_OPTION_VALUE,
  label: 'common.other'
};

export class EnumWithOtherOption extends Enum {
  /**
   *
   * @param {String} name - enum name
   * @param {Object} obj - object representing enum
   * @param {Object} translationMapping - map enum values with translations
   * @param {Object} apiMapping - API value to enum value map
   * @param {EnumOptionsModifiers} [optionsModifiers]
   */
  constructor(name, obj, translationMapping, apiMapping, optionsModifiers) {
    super(
      name,
      obj,
      {
        ...translationMapping,
        [OTHER_OPTION_VALUE]: 'common.other'
      },
      {
        ...apiMapping,
        OTHER: OTHER_OPTION_VALUE
      },
      optionsModifiers
    );
  }

  _initOptions() {
    let options = super._initOptions();

    options.push(
      this.optionsModifiers?.mapper
        ? this.optionsModifiers.mapper(otherOption)
        : otherOption
    );

    return options;
  }
}
