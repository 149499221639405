import { mapObject } from '../../utils/mapUtils';
import { TriggerConditionModel } from './triggerConditionModel';
import { ChangedStatus } from '../../constants/changedStatus';

export class TriggerConditionListItemModel {
  /**
   * TriggerConditionListItemModel
   *
   * @param {Object}                    config
   * @param {Number}                    config.order
   * @param {Severity}                  config.severity
   * @param {Boolean}                   config.deleted //an artificial field, used only for save
   * @param {ChangedStatus}             config.changedStatus
   * @param {TriggerConditionModel}     config.condition
   * @param {TriggerConditionModel}     config.originalCondition
   */
  constructor(config) {
    this.order = config.order;
    this.changedStatus = config.changedStatus || ChangedStatus.NOT_CHANGED;
    this.deleted = config.deleted || false;
    this.severity = config.severity;
    this.condition = mapObject(config.condition, TriggerConditionModel);
    this.originalCondition = mapObject(config.originalCondition, TriggerConditionModel);
  }

  get isEmpty() {
    return !this.condition || this.condition.isEmpty;
  }

}
