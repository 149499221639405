import { types } from './types';
import { createInputConfig, InputType } from '@h4h/inputs';
import { createMainFilterInput } from '../../../services/filter/utils/filterUtils';

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_GROUPS](state, groups) {
    state.groups = groups;
  },

  [types.SET_FETCHED](state, value) {
    state.fetched = value;
  },

  [types.SET_EDIT_USER_GROUPS](state, groupsUser) {
    state.groupsUser = groupsUser;
  },

  [types.INIT_FORM](state) {
    state.groupsInput = getInputConfig(state);
    state.filterInput = createMainFilterInput();
  },

  [types.INIT_DELETE_GROUP_POPUP](state, { popupId, groupId }) {
    state.deleteGroupPopupId = popupId;
    state.currentGroupId = groupId;
  },

  [types.UPDATE_FILTER](state) {
    state.filteredRows = (state.groups || []).filter(group => {
      const filter = state.filterInput?.value.trim();
      if (filter && state.filterInput.valid) {
        return group.name.toLowerCase().includes(filter.toLowerCase());
      }
      return true;
    });
  },

};

function getInputConfig(state) {
  return createInputConfig({
    id: 'group',
    type: InputType.Select2,
    label: 'group.groups',
    required: false,
    options: [{
      label: 'common.all',
      value: null,
    }].concat(state.groups.map(group => ({
      label: group.immutable ? `group.${ group.name }` : group.name,
      value: group
    })))
  });
}
