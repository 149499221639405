export class DurationModel {
  /**
   * DurationModel config
   *
   * @param {Object}        config
   * @param {DurationUnit}  config.unit
   * @param {Number}        config.value
   */
  constructor(config) {
    this.unit = config.unit;
    this.value = config.value;
  }
}
