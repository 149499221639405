import { types } from './types';
import { createInputConfig, InputType, setInputValue, validateInput } from '@h4h/inputs';
import { durationUnitOptions } from '../../../constants/durationUnit';
import { MonitoringPeriodInputModel } from '../models';
import { without } from 'lodash';
import { HAS_NO_DURATION_INPUT } from '../constants';

export const mutations = {
  [types.INIT_FORM](state, treatmentAdviceEnabled) {
    state.availablePeriods = [];
    state.supportedVendors = [];
    state.inputs = getInputsConfig(treatmentAdviceEnabled);
    state.primaryPeriod = null;
    state.observationGroups = [];
    state.questionnaireGroups = [];
    state.observationThresholds = [];
  },

  [types.SET_INPUT_VALUE](state, { input, value }) {
    // behaviour - if noDuration is set then validate duration value and unit, and no in other case
    if (input.id === HAS_NO_DURATION_INPUT) {
      state.availablePeriods.forEach(period => {
        if (period.inputs.includes(input)) {
          period.durationValue.required = !value;
          period.durationValue.valid = validateInput(period.durationValue);
          period.durationValue.disabled = value;
          period.durationUnit.required = !value;
          period.durationUnit.valid = validateInput(period.durationUnit);
          period.durationUnit.disabled = value;
          if (value) {
            period.durationValue.value = null;
            period.durationUnit.value = null;
            period.durationValue.pristine = true;
            period.durationUnit.pristine = true;
          }
        }
      });
    }
    setInputValue({ input, value });
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => input.pristine = value);
    state.availablePeriods.forEach(period => period.setPeriodAndInputsPristine(value));
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.ADD_PERIOD](state) {
    state.availablePeriods.push(createPeriodConfig());
    if (!state.primaryPeriod) {
      state.primaryPeriod = state.availablePeriods[0];
    }
  },

  [types.REMOVE_PERIOD](state, period) {
    state.availablePeriods = without(state.availablePeriods, period);
    if (state.primaryPeriod === period) {
      state.primaryPeriod = state.availablePeriods[0];
    }
  },

  [types.ADD_SUPPORTED_VENDOR](state, programVendorModel) {
    state.supportedVendors.push(programVendorModel);
  },

  [types.REMOVE_SUPPORTED_VENDOR](state, programVendorModel) {
    state.supportedVendors = without(state.supportedVendors, programVendorModel);
  },

  [types.ADD_NEW_PROGRAM_INPUT_GROUP](state, { groups, group, programInput }) {
    programInput.groups.push(group);
    groups.push(group);
  },

  [types.LINK_INPUT_GROUP](state, { group, programInput }) {
    programInput.groups.push(group);
  },

  [types.UNLINK_INPUT_GROUP](state, { group, programInput }) {
    programInput.groups = without(programInput.groups, group);
  },

  [types.ADD_OBSERVATION](state, { period, observation }) {
    period.observations.push(observation);
    period.pristine = false;
  },

  [types.REMOVE_OBSERVATION](state, { period, observation }) {
    period.observations = without(period.observations, observation);
    period.pristine = false;
  },

  [types.ADD_QUESTIONNAIRE](state, { period, questionnaire }) {
    period.questionnaires.push(questionnaire);
    period.pristine = false;
  },

  [types.REMOVE_QUESTIONNAIRE](state, { period, questionnaire }) {
    period.questionnaires = without(period.questionnaires, questionnaire);
    period.pristine = false;
  },

  [types.SET_PRIMARY_PERIOD](state, period) {
    state.primaryPeriod = period;
  },

  [types.UPSERT_THRESHOLD](state, threshold) {
    const oldThreshold = state.observationThresholds.find(t => t.observationTemplateUuid === threshold.observationTemplateUuid);
    if (oldThreshold) {
      const index = state.observationThresholds.indexOf(oldThreshold);
      if (threshold.isEmpty) {
        state.observationThresholds.splice(index, 1);
      }
      else {
        state.observationThresholds[index] = threshold;
      }
    }
    else {
      if (!threshold.isEmpty) {
        state.observationThresholds.push(threshold);
      }
    }
    state.observationThresholds = [...state.observationThresholds];
  },
};

/**
 *
 * @return {MonitoringPeriodInputModel}
 */
function createPeriodConfig() {
  const hasNoDuration = createInputConfig({
    id: HAS_NO_DURATION_INPUT,
    label: 'programs.hasNoDuration',
    type: InputType.Checkbox,
    required: false,
    value: false,
    default: false,
  });
  const sendEmailOnEnrollment = createInputConfig({
    id: 'sendEmailOnEnrollment',
    label: 'programs.sendEmailOnEnrollment',
    type: InputType.Checkbox,
    required: false,
    value: false,
    default: false,
  });
  const sendDevicesToPatients = createInputConfig({
    id: 'sendDevicesToPatients',
    type: InputType.Checkbox,
    required: false,
    value: false,
    label: 'enrollments.giveOptionToSendDeviceToPatient',
  });

  const name = createTextInputConfig('name', 'common.name', InputType.Text);
  const durationValue = createTextInputConfig('durationValue', 'programs.durationValue', InputType.Integer, true);
  const durationUnit = createInputConfig({
    id: 'durationUnit',
    type: InputType.Select2,
    label: 'programs.durationUnit',
    options: durationUnitOptions,
    clearable: false,
    required: true,
  });
  return new MonitoringPeriodInputModel({
    generateFakeUuid: true,
    name,
    durationValue,
    durationUnit,
    hasNoDuration,
    sendEmailOnEnrollment,
    sendDevicesToPatients,
    inputs: [hasNoDuration, sendEmailOnEnrollment, sendDevicesToPatients, name, durationValue, durationUnit],
    observations: [],
    questionnaires: [],
  });
}

/**
 *
 * @return {Object[]}
 */
function getInputsConfig(treatmentAdviceEnabled) {
  const name = createTextInputConfig('name', 'common.name', InputType.Text);
  const controlPlaneScript = createTextInputConfig('controlPlaneScript', 'programs.controlPlaneScript', InputType.Textarea);
  const notUseEhealthApp = createTextInputConfig('notUseEhealthApp', 'programs.doesntUseH4Happ', InputType.Checkbox, false);

  let inputs = [
    name,
    controlPlaneScript,
  ];
  if (treatmentAdviceEnabled) {
    inputs.push(
      createTextInputConfig('treatmentAdviceScript', 'programs.treatmentAdviceScript', InputType.Textarea),
      createTextInputConfig('renderTemplate', 'programs.treatmentAdviceTemplate', InputType.Textarea),
    );
  }
  inputs.push(notUseEhealthApp);
  return inputs;
}

function createTextInputConfig(id, label, inputType, required = true) {
  return createInputConfig({
    id,
    type: inputType,
    label,
    required,
  });
}

