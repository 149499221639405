import { ccRoleOptionsSupportedForSelect } from '../../../../services/auth/ehRole';
import { apiRoleMapper } from '../../../../services/auth/apiRoleMapper';
import { localize } from '../../../../services/localize';

export const getters = {
  areProfileInputsValid,
  rolesProposed,
  rolesCurrent
};

function areProfileInputsValid(state) {
  return state.inputs && state.inputs.every(i => i.valid);
}

function rolesProposed(state) {
  if (state.profile) {
    let rolesInner = state.profile.roles.map(role => (apiRoleMapper.fromApi(role)));
    return ccRoleOptionsSupportedForSelect
      .filter(role => !rolesInner.includes(role.value))
      .map(role => ({
        uuid: role.value,
        name: localize(role.label)
      }));
  }
  return [];
}

function rolesCurrent(state) {
  if (state.profile) {
    let rolesInner = state.profile.roles.map(role => (apiRoleMapper.fromApi(role)));
    return ccRoleOptionsSupportedForSelect
      .filter(role => rolesInner.includes(role.value))
      .map(role => ({
        uuid: role.value,
        name: localize(role.label)
      }));
  }
  return [];
}
