import { types } from './types';
import { InputType } from '@h4h/inputs/src/constants/inputType';
import { createInputConfig, getInputsMap, Patterns } from '@h4h/inputs';
import { EhPatterns } from '../../../../utils/ehPatterns';
import { EhUserFieldsLengthConstraints } from '../../../../utils/ehUserFieldsLengthConstraints';
import { createPhoneInputConfig } from '../../../../utils/phone/phoneComponentUtils';
import { EhRole } from '../../../../services/auth/ehRole';
import { allowedCountryCodesForCC } from '../../constants';

export const mutations = {
  [types.INIT_FORM](state, { readOnly, countries }) {
    const inputs = getInputsConfig(state.profile || {}, readOnly, countries);
    state.inputs = inputs;
    state.inputsMap = getInputsMap(inputs);
  },

  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PRISTINE](state, value) {
    state.inputs.forEach(input => {
      input.pristine = value;
    });
  },

  [types.SET_PROFILE](state, profile) {
    state.profile = profile;
  }

};

function getInputsConfig(profile, readOnly, countries) {
  const inputType = readOnly ? InputType.Label : InputType.Text;
  const bigCode = createTextInputConfig(profile.bigCode, 'bigCode', 'common.bigCode', inputType, null, false, false, EhUserFieldsLengthConstraints.bigCode);
  const email = createTextInputConfig(profile.email, 'email', 'common.email', inputType, EhPatterns.email, true, false, EhUserFieldsLengthConstraints.email);

  let phone;
  if (readOnly) {
    phone = createTextInputConfig(profile.phone, 'phone', 'common.phoneNumber', inputType, EhPatterns.phoneNumberInternational, true, false);
  }
  else {
    phone = createPhoneInputConfig(profile.phone, 'phone', 'common.telephoneNumber', true);
  }
  let visibleCountry;
  if (readOnly) {
    const countryName = countries.find(country => country.code2 === profile.visibleCountry)?.name || profile.visibleCountry;
    visibleCountry = createTextInputConfig(countryName, 'visibleCountry', 'tenant.country', InputType.Label, false);
  }
  else {
    visibleCountry = createInputConfig({
      id: 'visibleCountry',
      type: InputType.Select2,
      value: profile.visibleCountry,
      clearable: false,
      required: true,
      disabled: !profile.hasRole(EhRole.CallCenterAdmin),
      label: 'tenant.country',
      options: countries
        .filter(country => allowedCountryCodesForCC.includes(country.code2))
        .map(country => ({
          value: country.code2,
          label: `${ country.name } ${ country.code2 }`
        })),
    });
  }

  const alarmPhoneNumber = createTextInputConfig(profile.alarmPhoneNumber, 'alarmPhoneNumber', 'common.alarmPhoneNumber', inputType, EhPatterns.phoneNumberInternational, true, false, EhUserFieldsLengthConstraints.alarmPhoneNumber);
  const initials = createTextInputConfig(profile.initials, 'initials', 'common.initials', inputType, Patterns.Initials, false, false, EhUserFieldsLengthConstraints.initials);
  const firstName = createTextInputConfig(profile.firstName, 'firstName', 'common.firstName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, false, EhUserFieldsLengthConstraints.firstName);
  const middleName = createTextInputConfig(profile.middleName, 'middleName', 'common.middleName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, false, EhUserFieldsLengthConstraints.middleName);
  const lastName = createTextInputConfig(profile.lastName, 'lastName', 'common.lastName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, true, false, EhUserFieldsLengthConstraints.lastName);
  const partnerName = createTextInputConfig(profile.partnerName, 'partnerName', 'common.partnerName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, false, EhUserFieldsLengthConstraints.partnerName);
  const partnerMiddleName = createTextInputConfig(profile.partnerMiddleName, 'partnerMiddleName', 'common.partnerMiddleName', inputType, EhPatterns.lettersHyphensSpacesApostropheOnly, false, false, EhUserFieldsLengthConstraints.partnerMiddleName);
  const roles = createTextInputConfig(profile.rolesString, 'roles', 'common.roles', inputType, null, false, true, false);

  return [
    bigCode,
    email,
    phone,
    alarmPhoneNumber,
    initials,
    firstName,
    lastName,
    middleName,
    partnerName,
    partnerMiddleName,
    visibleCountry,
    roles
  ];
}

function createTextInputConfig(value = null, id, label, inputType, pattern, required = false, disabled = false, constraints) {
  return createInputConfig({
    id,
    type: inputType,
    pattern,
    value,
    required,
    label,
    disabled: disabled,
    ...constraints,
  });
}
