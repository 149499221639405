export class KardiaPatientModel {
  /**
   * OrgUserModel config
   *
   * @param {Object}                    config
   * @param {String}                  config.connectionStatus
   */
  constructor(config) {
    this.connectionStatus = config.connectionStatus;
  }
}
