import { types } from './types';
import { showRestError } from '../../utils/errors';
import { userTenants } from '../../services/api/tenant/tenant';
import { sortBy } from 'lodash';
import { forceAuth, getReturnUri, loginKeyName, setTenant } from '../../services/auth/auth';
import axios from 'axios';
import { toast } from '../../services/toast';
import { localize } from '../../services/localize';
import { popups } from '../../services/popups';

export const actions = Object.freeze({
  loadUserInfo,
  initForm,
  authorize,
});

async function authorizeWithLogin(auth, returnUriFunction, userName) {
  try {
    window.localStorage.setItem(loginKeyName, userName);
    await auth.initialized;
    return await new Promise((resolve, reject) => {
      auth.keycloak.init({
        checkLoginIframe: false
      }).success(() => {
        auth.keycloak.login({
          redirectUri: returnUriFunction(),
          loginHint: userName,
        }).success(() => {
          auth.keycloak.updateToken(10);
          axios.defaults.headers.common = { 'Authorization': 'Bearer ' + this.keycloak.token };
          resolve(true);
        }).error(reject);
      }).error(reject);
    });
  }
  catch (e) {
    toast.error(localize('tenant.errorAuthorize'), e);
    return false;
  }
}

async function doAuthorize(tenantName, username) {
  setTenant(tenantName);
  const auth = forceAuth();
  return await authorizeWithLogin(auth, () => getReturnUri(tenantName), username);
}

async function authorize({ getters }, tenantName) {
  return await doAuthorize(tenantName, getters.username);
}

async function loadUserInfo({ commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }

  const username = getters.username;
  commit(types.SET_LOADING, true);
  const result = await userTenants.fetch({ params: { username } });
  commit(types.SET_LOADING, false);

  if (result.success) {
    if (result.data.length === 1) {
      await doAuthorize(result.data[0].name, username);
      return;
    }
    if (result.data.length === 0) {
      popups.showModal({
        message: 'tenant.noTenantInfoFound',
        buttons: [{
          label: 'Ok',
          class: 'btn-primary',
          result: 'ok',
        }],
      });
      return;
    }
    commit(types.SET_TENANTS, sortBy(result.data, 'name'));
  }
  else {
    showRestError(result.error, 'tenant.errorGetEmailInfo');
  }
}

async function initForm({ commit }) {
  commit(types.INIT_FORM);
}

