<template>
  <VChip
    :class="[styles.chip, 'chip-overwrite', isWithIcon && styles.withIcon, chipClass]"
    v-bind="$props"
    @click:close="$emit('click:close', $event)"
    @click="$emit('click', $event)"
  >
    <slot/>
    <Icon
      v-if="$props.icon"
      :type="icon"
      :class="styles.icon"
    />
  </VChip>
</template>

<script>
  import { VChip } from 'vuetify/lib';
  import { ChipStyle } from './chipStyle';
  import { Icon } from '@h4h/icons';

  import styles from './chip.scss';

  export default {
    name: 'H4hChip',

    components: {
      VChip,
      Icon
    },

    props: {
      label: {
        type: Boolean,
        default: true
      },

      icon: {
        type: String
      },

      close: Boolean,
      small: Boolean,
      large: Boolean,
      disabled: Boolean,
      draggable: Boolean,
      filter: Boolean,
      outlined: Boolean,
      link: Boolean,
      href: [String, Object],
      to: [String, Object],
      type: {
        type: String,
        default: ChipStyle.Default
      },
      target: String,
      replace: Boolean
    },

    data() {
      return {
        styles
      };
    },

    computed: {
      chipClass() {
        switch (this.$props.type) {
          case ChipStyle.Default:
            return styles.default;
          case ChipStyle.Success:
            return styles.success;
          case ChipStyle.Dark:
            return styles.dark;
          case ChipStyle.Primary:
            return this.styles.primary;
          case ChipStyle.Danger:
            return this.styles.danger;
          case ChipStyle.Warning:
            return this.styles.warning;
          case ChipStyle.Transparent:
            return this.styles.transparent;
          case ChipStyle.Cancel:
            return this.styles.cancel;
          default:
            return styles.default;
        }
      },

      isWithIcon() {
        return this.$props.icon?.length;
      }
    }
  };
</script>
