export const state = {
  ecg: null,
  commentEntity: null,
  commentInput: null,
  conclusion: null,
  contactedVia: null,
  advice: null,
  saving: false,
  conclusions: [],

  pdf: null,
  ecgPdfLoading: false,
};
