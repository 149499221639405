import { DurationUnit } from '../../constants/durationUnit';
import { ProgramInputType } from '../../constants/programInputType';
import { ObservationInputType } from '../../constants/observationInputType';
import { AnswerOptionType, AnswerType } from '../../constants/answerType';
import { DeviceVendor } from '../../constants/deviceVendor';

export const enums = {
  [DurationUnit.DAYS]: 'Days',
  [ProgramInputType.OBSERVATION]: 'Observation',
  [ObservationInputType.NUMERIC]: 'Numeric',
  [ObservationInputType.INT]: 'Integer',
  [ObservationInputType.STRING]: 'String',
  [ObservationInputType.BOOLEAN]: 'Boolean',
  [ObservationInputType.LOCAL_DATE]: 'Local date',
  [ObservationInputType.LOCAL_TIME]: 'Local time',
  [ObservationInputType.LOCAL_DATE_TIME]: 'Local date and time',
  [ObservationInputType.DATE_TIME]: 'Date time',
  [ObservationInputType.REFERENCE]: 'Reference',

  [AnswerType.BOOLEAN]: 'Boolean',
  [AnswerType.NUMERIC]: 'Numeric',
  [AnswerType.INTEGER]: 'Integer',
  [AnswerType.FREE_STRING]: 'String',
  [AnswerType.CHOICE]: 'Choice',

  [AnswerOptionType.NUMERIC]: 'Numeric',
  [AnswerOptionType.INTEGER]: 'Integer',
  [AnswerOptionType.FREE_STRING]: 'String',
  [AnswerOptionType.TERMINOLOGY]: 'Terminology',

  [DeviceVendor.ALIVECOR_KARDIA]: 'Kardia',
};
