import { types } from './types';
import { ObservationTemplateModel } from '../../../model/ObservationTemplateModel';
import { popups } from '../../../services/popups';
import { PopupName } from '../../../constants/popupName';
import { PopupType } from '@h4h/popups';

export const actions = Object.freeze({
  initPopup,
  save,
  cancel,
});

async function initPopup({ commit }, observationTemplates) {
  const { id, closed } = await popups.show({
    name: PopupName.AddObservationTemplate,
    type: PopupType.Modal
  });
  commit(types.INIT_POPUP, { popupId: id, observationTemplates });
  return { closed };
}

async function save({ state, commit, getters }) {
  commit(types.SET_PRISTINE, false);
  if (!getters.isValid) {
    return false;
  }
  const template = new ObservationTemplateModel(state.inputsMap.observationTemplateId.value);
  popups.hide({ id: state.popupId, result: template });
}

async function cancel({ state }) {
  popups.hide({ id: state.popupId });
}
