<template>
  <div
    :class="[
      styles.tabs,
      $props.primary ? styles.primary : styles.light
    ]"
  >
    <VTabs
      v-model="activeTab"
      :dark="$props.primary"
      :centerActive="centerActive"
      :centered="centered"
      :grow="grow"
      :hideSlider="hideSlider"
      :right="right"
      :showArrows="showArrows"
      :vertical="vertical"
      :value="value"
      @change="$emit('change', $event)"
    >
      <div :class="tabContainerStyles">
        <slot name="tab"/>
      </div>
      <VTabsItems v-model="activeTab">
        <slot name="sharedContent"/>
        <slot name="tabItems"/>
      </VTabsItems>
    </VTabs>
  </div>
</template>

<script>
  import { VTabs, VTabsItems } from 'vuetify/lib';
  import styles from './tabs.scss';

  export default {
    name: 'H4hTabs',

    components: {
      VTabs,
      VTabsItems,
    },

    props: {
      value: Number,
      showArrows: {
        type: Boolean,
        default: true
      },
      centerActive: Boolean,
      centered: Boolean,
      grow: Boolean,
      right: Boolean,
      hideSlider: Boolean,
      vertical: Boolean,
      borderBottom: {
        type: Boolean,
        default: true
      },
      primary: {
        type: Boolean, // Tabs with primary theme
        default: false
      },
      borderOuter: Boolean
    },

    data() {
      return {
        styles,
        activeTab: 0 || this.value
      };
    },

    computed: {
      tabContainerStyles() {
        const { borderBottom, borderOuter } = this.$props;

        return [
          styles.tabContainer,
          borderBottom && styles.borderBottom,
          borderOuter && styles.borderOuter
        ].filter(Boolean);
      }
    },

    watch: {
      value() {
        if (this.value !== undefined) {
          this.activeTab = this.value;
        }
      }
    }
  };
</script>
