import { isMultipleValue } from '../utils/tableWithUserFilters';

export class UserFilter {
  /**
   *
   * @param {Object} config
   * @param {Any}    config.value
   * @param {String} config.type
   * @param {String} config.itemType
   */
  constructor(config) {
    this.value = config.value;
    this.type = config.type;
    this.itemType = config.itemType;
  }

  static toApi(userFilter) {
    if (!userFilter) {
      return null;
    }

    // we need to store type to correctly recreate Set and Date from api
    const type = userFilter.constructor.name;
    const itemType = isMultipleValue(type)
      ? Array.from(userFilter)[0]?.constructor.name
      : null;

    return {
      type,
      itemType,
      value: type === 'Set' ? Array.from(userFilter) : userFilter
    };
  }

  static fromApi(userFilter) {
    if (!userFilter) {
      return null;
    }

    const { value, type, itemType } = userFilter;

    if (isMultipleValue(type)) {
      const result = value.map(v => fromApiSingleValue(v, itemType));

      if (type === 'Set') {
        return new Set(result);
      }

      return result;
    }
    return fromApiSingleValue(value, type);
  }
}

function fromApiSingleValue(value, type) {
  if (type === 'Date') {
    return new Date(value);
  }

  return value;
}
