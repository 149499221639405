import { Page } from '../../../../constants/page';

export let anamnesis = {
  path: 'anamnesis',
  name: Page.AdminAnamnesis,
  redirect: { name: Page.AdminAnamnesisSettings },
  component: () => import('../../../../components/views/app/admin/anamnesis/Anamnesis'),
  meta: {
    title: 'anamnesis.anamnesisTemplate',
  },
  children: [
    {
      path: 'settings',
      name: Page.AdminAnamnesisSettings,
      component: () => import('../../../../components/views/app/admin/anamnesis/settings/AnamnesisSettings'),
      meta: {
        title: 'anamnesis.anamnesisTemplate',
      }
    }
  ]
};
