<template>
  <WysiwygActionBar
    :actions="$props.actions"
    :disabled="$props.disabled"
    @action="onAction"
  >
    <template :slot="getContentSlotName(wysiwygActions.b)">
      <b>B</b>
    </template>

    <template :slot="getContentSlotName(wysiwygActions.i)">
      <i>I</i>
    </template>

    <template :slot="getContentSlotName(wysiwygActions.strike)">
      <strike>S</strike>
    </template>

    <template :slot="getContentSlotName(wysiwygActions.h1)">
      <b>H</b><sub>1</sub>
    </template>

    <template :slot="getContentSlotName(wysiwygActions.h2)">
      <b>H</b><sub>2</sub>
    </template>

    <template :slot="getContentSlotName(wysiwygActions.p)">
      ¶
    </template>

    <template :slot="getContentSlotName(wysiwygActions.ol)">
      #
    </template>

    <template :slot="getContentSlotName(wysiwygActions.ul)">
      •
    </template>

    <template :slot="getWysiwygActionSlots(wysiwygActions.link).item">
      <LinkAction
        :disabled="$props.disabled"
        @action="emitAction"
      />
    </template>
  </WysiwygActionBar>
</template>

<script>
  import { wysiwygActions, getWysiwygActionSlots } from '../../utils';

  import styles from './wysiwyg.scss';
  import LinkAction from './LinkAction';
  import WysiwygActionBar from './WysiwygActionBar';

  export default {
    name: 'H4hWysiwygActionBar',

    components: {
      WysiwygActionBar,
      LinkAction
    },

    props: WysiwygActionBar.props,

    data() {
      return {
        styles,
        wysiwygActions,
        link: '',
        linkText: ''
      };
    },

    methods: {
      getWysiwygActionSlots,

      onAction(action) {
        if (action.command !== 'createLink') {
          this.emitAction(action);
        }
      },

      emitAction(action) {
        this.$emit('action', action);
      },

      getContentSlotName(action) {
        return getWysiwygActionSlots(action).content;
      }
    }
  };
</script>
