export class AnamnesisSettings {

  /**
   * AnamnesisSettings
   *
   * @param {Object}    config
   * @param {String}    config.questionnaireTemplateId
   */
  constructor(config) {
    this.questionnaireTemplateId = config.questionnaireTemplateId;
  }
}
