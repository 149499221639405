/**
 *
 * @param {Severity} severity
 * @param {String} field
 */
import { isNumber } from 'lodash';
import { TriggerConditionListItemModel } from '../../../../../model/thresholds/triggerConditionListItemModel';
import {
  greaterThanField,
  lessThanField,
  TriggerConditionModel
} from '../../../../../model/thresholds/triggerConditionModel';
import { validateInput } from '@h4h/inputs';
import { localize } from '../../../../../services/localize';
import { Severity } from '../../../../../constants/severity';
import { ChangedStatus } from '../../../../../constants/changedStatus';

export function makeSeverityConditionId(severity, field) {
  return severity + field;
}

export class SeverityConditionGroup {
  /**
   *
   * @param config
   * @param {TriggerConditionListItemModel} config.condition
   * @param {Object} config.input
   * @param {String} config.field
   * @param {Boolean} config.highlightModified
   * @param {SeverityConditionGroup[]} config.allGroups
   */
  constructor(config) {
    this.condition = config.condition;
    this.input = config.input;
    this.field = config.field;
    this.highlightModified = config.highlightModified;
    this.allGroups = config.allGroups || [];
    this.changedStatus = this.condition.changedStatus;
  }

  toTriggerConditionListItemModel() {
    const value = this.input.value;
    if (!isNumber(value)) {
      return null;
    }
    return new TriggerConditionListItemModel({
      ...this.condition,
      deleted: this.deleted,
      condition: new TriggerConditionModel({
        lessThan: this.field === lessThanField ? value : null,
        greaterThan: this.field === greaterThanField ? value : null,
      }),
    });
  }

  setDeleted(deleted) {
    if (this.deleted === deleted) {
      return;
    }
    if (deleted) {
      this.originalChangedStatus = this.changedStatus;
      this.changedStatus = ChangedStatus.DELETED;
    }
    else {
      this.changedStatus = this.originalChangedStatus || ChangedStatus.NOT_CHANGED;
    }
  }

  get id() {
    return makeSeverityConditionId(this.severity, this.field);
  }

  get severity() {
    return this.condition.severity;
  }

  get highlighted() {
    return this.highlightModified && this.valueModified;
  }

  get valueModified() {
    return this.input.value !== this.input.originalValue;
  }

  get modified() {
    return this.deleted || this.valueModified || this.created;
  }

  get deleted() {
    return this.changedStatus === ChangedStatus.DELETED;
  }

  get created() {
    return this.changedStatus === ChangedStatus.CREATED;
  }

  get canDelete() {
    return this.highlightModified && !(this.changedStatus === ChangedStatus.CREATED);
  }

  get oppositeLimit() {
    if (this.condition.severity === Severity.MEDIUM) {
      const highThresholdGroup = this.allGroups.find(group => group.condition.severity === Severity.HIGH && group.field === this.field);
      if (highThresholdGroup && isNumber(highThresholdGroup.input.value)) {
        return localize('observationTemplates.oppositeLimit', [highThresholdGroup.input.value, highThresholdGroup.input.unit]);
      }
    }
    return '';
  }

  resetToOriginalValues() {
    this.input.value = this.input.originalValue;
    validateInput(this.input);
    this.input.pristine = true;
  }

  get valid() {
    if (this.deleted) {
      return true;
    }
    else {
      return this.input.valid;
    }
  }
}
