import moment from 'moment';
import { ISO_DATE_FORMAT } from '@h4h/date';
import { UserModel } from './userModel';
import { getPersonAge } from '../utils/formatters/date';
import { PatientStatus } from '../constants/patientStatus';
import { EhAddressModel, mapEhAddressToMpAddress, mapMpAddressToEhAddress } from './addressModel';
import { mapObject } from '../utils/mapUtils';
import { TenantSettings } from './tenantSettings';

export class PatientModel extends UserModel {
  /**
   * PatientModel config
   *
   * @param {Object}        config
   * @param {String}        config.email
   * @param {String}        config.firstName
   * @param {String}        config.gender
   * @param {String}        config.iamId
   * @param {String}        config.initials
   * @param {String}        config.lastName
   * @param {String}        config.middleName
   * @param {String}        config.partnerMiddleName
   * @param {String}        config.partnerName
   * @param {String}        config.phone
   * @param {String}        config.uuid
   * @param {String}        config.dateOfBirth
   * @param {String}        config.patientNumber
   * @param {String}        config.externalPatientId
   * @param {String}        config.bsn
   * @param {String}        config.responsiblePhysicianId
   * @param {String}        config.patientSince
   * @param {PatientStatus} config.userStatus
   * @param {EhAddressModel} config.address
   */
  constructor(config) {
    super(config);
    config = config || {};
    this.dateOfBirth = config.dateOfBirth;
    this.patientNumber = config.patientNumber ;
    this.externalPatientId = config.externalPatientId;
    this.bsn = config.bsn;
    this.responsiblePhysicianId = config.responsiblePhysicianId;
    this.patientSince = config.patientSince;
    this.userStatus = config.userStatus;
    this.address = mapEhAddressToMpAddress(mapObject(config.address, EhAddressModel));
  }

  get readablePatientId() {
    return this.patientNumber || this.externalPatientId;
  }

  get dateOfBirthAsDate() {
    if (this.dateOfBirth) {
      return moment(this.dateOfBirth).toDate();
    }
    return null;
  }

  get age() {
    return getPersonAge(this.dateOfBirth);
  }

  get universalUuid() {
    return this.tenantDependentUuid || this.uuid;
  }

  get isArchived() {
    return this.userStatus === PatientStatus.ARCHIVED;
  }

  // try to translate this field since bsn is dutch translation
  get ssn() {
    return this.bsn;
  }

  set ssn(value) {
    this.bsn = value;
  }
}

/**
 * PatientModel mapper
 *
 * @param {PatientModel}    model
 */
export function mapPatientModelToRestEntity(model) {
  model = model || {};
  let entity = {
    ...model,
    address: mapMpAddressToEhAddress(model.address),
  };
  if (model.dateOfBirth) {
    entity.dateOfBirth = moment(model.dateOfBirth).format(ISO_DATE_FORMAT);
  }
  return entity;
}

export class CrossTenantPatientModel extends PatientModel {

  /**
   * PatientModel config
   *
   * @param {Object}    config
   * @param {String}    config.email
   * @param {String}    config.firstName
   * @param {String}    config.gender
   * @param {String}    config.iamId
   * @param {String}    config.initials
   * @param {String}    config.lastName
   * @param {String}    config.middleName
   * @param {String}    config.partnerMiddleName
   * @param {String}    config.partnerName
   * @param {String}    config.phone
   * @param {String}    config.uuid
   * @param {String}    config.dateOfBirth
   * @param {String}    config.externalPatientId
   * @param {String}    config.tenantId
   * @param {String}    config.tenantDependentUuid
   * @param {EhAddressModel} config.address
   * @param {TenantSettings} config.tenantSettings
   */

  constructor(config) {
    super(config);
    config = config || {};
    this.tenantId = config.tenantId;
    this.tenantDependentUuid = config.tenantDependentUuid;
    this.tenantSettings = mapObject(config.tenantSettings, TenantSettings);
  }
}

export class PatientUpdateRequestModel {

  /**
   * PatientUpdateRequestModel config
   *
   * @param {Object}    config
   * @param {String}    config.responsiblePhysicianId
   */

  constructor(config) {
    config = config || {};
    this.treatmentSpecialistId = config.responsiblePhysicianId; // todo - remove this line after backand changes field name
    this.responsiblePhysicianId = config.responsiblePhysicianId;
  }
}

