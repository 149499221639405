import { CrudService } from '../../../classes/crudService';
import { ObservationTemplateModel } from '../../../model/ObservationTemplateModel';
import { IsUpdatable } from '../../../model/isUpdatable';

export const observationTemplates = new CrudService(
  'observation-templates',
  data => new ObservationTemplateModel(data),
);

export const observationTemplateUpdatable = uuid => new CrudService(
  `observation-templates/${ uuid }/updatable`,
  data => new IsUpdatable(data),
).fetchOne();

