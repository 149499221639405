export const patients = {
  // pageTitle: 'Patiënten',
  //
  anamnesis: 'Anamnese',
  updateAnamnesis: 'Update anamnese',
  loadingAnamnesis: 'Loading anamnesis',
  //
  questionnaire: 'Vragenlijst',
  updateQuestionnaire: 'Update vragenlijst',
  loadingQuestionnaire: 'Vragenlijst inladen',
  updatingQuestionnaire: 'Vragenlijst bijwerken',
  //
  // from: 'Van',
  // form: 'Formulier',
  // period: 'Periode',
  patient: 'Patiënt',
  // filters: 'Filters',
  // through: 'Door',
  // smoking: 'Roken',
  // currentWeight: 'Huidige gewicht',
  // physicalActivity: 'Lichamelijke activiteit',
  // absence: 'Afwezigheid',
  // programs: 'Programma\'s',
  // addedPrograms: 'Toegevoegde programma\'s',
  // newPrograms: 'Nieuwe programma\'s',
  // allPatients: 'Alle patiënten',
  // onlyMyPatients: 'Alleen mijn patiënten',
  // alphabeticalOrder: 'Alfabetische volgorde',
  // addPatient: 'Voeg patiënt toe',
  // newPatient: 'Nieuwe patiënt',
  labResults: 'Labresultaten',
  // frequencies: 'Frequenties',
  // diagnostics: 'Diagnostiek',
  // medicalDossier: 'Medisch dossier',
  // dossier: 'Dossier',
  // observations: 'Observaties',
  // showQrCode: 'Toon QR-code',
  // hypertension: 'Hypertensie',
  // hypertensionProgram: 'Hypertensie Programma',
  bloodPressure: 'Bloeddruk',
  // weight: 'Gewicht',
  systolic: 'Systolisch',
  avgSystolic: 'Gem. Systolisch',
  diastolic: 'Diastolisch',
  avgDiastolic: 'Gem. Diastolisch',
  // avgWeight: 'Gem. Gewicht',
  // createPatient: 'Maak een patiënt',
  measuredValue: 'Gemeten waarde',
  addLabResult: 'Labresultaat toevoegen',
  editLabResult: 'Labresultaat wijzigen',
  deleteLabResult: 'Labresultaat verwijderen',
  addBloodPressure: 'Voeg de bloeddruk toe',
  // addWeight: 'Voeg de gewicht toe',
  // faq: 'Veelgestelde vragen',
  // searchPatients: 'Zoek patiënten',
  observationDate: 'Observatiedatum',
  questionnaireDate: 'Datum vragenlijst',
  // noPatientsFound: 'Geen patiënten gevonden',
  // treatmentAdvice: 'Behandeladvies',
  // loadingPatients: 'Patiënten laden',
  // editInformation: 'Bewerk informatie',
  // observationTemplate: 'Meettype',
  // creatingPatient: 'Een patiënt maken',
  // basicInformation: 'Basis informatie',
  loadingLabResults: 'Labresultaten laden',
  addingObservation: 'Een opmerking toevoegen',
  // patientInformation: 'Patiënt informatie',
  // loadingPatientData: 'Patiëntgegevens laden',
  // lifestyleMonitoring: 'Levensstijl monitoring',
  // hypertensionMonitoring: 'Hypertensie monitoring',
  // absenceInfo: 'Als een patiënt afwezig is vanwege een vakantie of een ziekenhuisopname, kunt u meldingen uitschakelen door de betreffende periode in te voeren.',
  // activatePatientPrompt: 'De patient heeft het account voor de Heart for Health app nog niet geactiveerd. Toon de patient de QR-code en laat deze scannen in de app van de patient.',
  //
  // treatmentHistory: 'Behandelgeschiedenis',
  //
  loadingBloodPressureHistory: 'Bloeddruk geschiedenis laden',
  bloodPressureHistory: 'Bloeddruk geschiedenis',
  //
  // loadingWeightHistory: 'Gewicht geschiedenis laden',
  // weightHistory: 'Gewicht geschiedenis',
  //
  // loadingSubProgram: 'Loading Sub Programs',
  // subProgram: 'Sub Programma\'s',
  // subProgramActivate: 'Activeren',
  // subProgramDeactivate: 'Deactiveren',
  // subProgramPageDescription: 'Op deze pagina kunt u het subprogramma voor de patiënt activeren of deactiveren. Op basis van het programma krijgt de patiënt herinneringen in de app om metingen uit te voeren. Daarnaast krijgt u een melding in taken-tab wanneer het weekgemiddelde in de rode (hoger dan 170 mmHG) of in de oranje (tussen 136 en 170 mmHG) categorie valt. Wanneer de meetweek afloopt of het evaluatiemoment in de monitoringsperiode is aangebroken zult u hier tevens in het taken-tab over geïnformeerd worden.',
  // subProgramMeetweek: 'Meetweek',
  // subProgramMeetweekDescription: 'Nodig uw patiënt uit om gedurende 7 dagen tweemaal per dag zijn bloeddruk te meten. Activeer het programma om te beginnen.',
  // subProgramMonitoringsperiod: 'Monitoringsperiode',
  // subProgramMonitoringsperiodDescription: 'Nodig uw patiënt uit om gedurende 12 weken te beginnen met het meten van zijn bloeddruk. Activeer het programma om te beginnen.',
  //
  // loadingECG: 'ECG laden',
  ecg: 'ECG',
  ecgHistory: 'ECG geschiedenis',
  // ecgDownloadFailed: 'ECG-download mislukt',
  // noEcgObservationsFound: 'Geen ECG-waarnemingen gevonden',
  //
  // currentDiagnoses: 'Current Diagnoses',
  // cardiologicalHistory: 'Cardiological History',
  // generalHistory: 'General History',
  // medications: 'Medications',
  // allergies: 'Allergies',
  // carePlan: 'Zorgplan',
  // decursus: 'Decursus',
  // summary: 'Conclusie',
  month: 'Maand',
  quarterOfYear: 'Kwartaal',
  halfOfYear: 'Half jaar',
  // deletePatient: 'Patiënt verwijderen',
  // deletePatientMessage: 'Weet u zeker dat u deze patiënt wilt verwijderen?',
  archivePatient: 'Patiënt archiveren',
  archivePatientMessage: 'Weet u zeker dat u deze patiënt wilt archiveren?',
  archivePatientFinalWarningMessage: 'Gearchiveerde patiënten zijn niet meer beschikbaar in het platform, alle programma’s voor deze patiënten worden stopgezet. Weet u echt zeker dat u de patiënt wilt archiveren?',
  years: 'jaar',
  noDataAvailablePleaseSpecifyAtLeastXSymbolsInFilter: 'Geen gegevens beschikbaar. Specificeer ten minste {0} symbolen in filter.',
  yourLastXVisitedPatientFiles: 'Uw laatste 10 bezochte patiëntendossiers',
};
