import { isNil } from 'lodash';

import { tabIndexManager } from '../services';
import { TabIndexSubscriber } from '../classes';

export const TabIndexSubscriberMixin = {
  props: {
    groupId: String,

    /**
     * @deprecated
     * should be used with caution - most probably you don't need it
     */
    tabindex: {
      type: Number,
      default: -1
    }
  },

  data() {
    return {
      tabIndexSubscriber__tabIndex: null,

      /** @type Subscription */
      tabIndexSubscriber__subscription: null
    };
  },

  watch: {
    tabIndexSubscriber__subscriber() {
      this.tabIndexSubscriber__subscribe();
    }
  },

  mounted() {
    this.tabIndexSubscriber__subscribe();
  },

  beforeDestroy() {
    this.tabIndexSubscriber__subscription?.unsubscribe();
  },

  computed: {
    /**
     * graceful fallback to old behavior for unmigrated components
     */
    tabIndex() {
      const { groupId, tabindex } = this.$props;

      return isNil(groupId)
        ? tabindex
        : this.tabIndexSubscriber__tabIndex;
    },

    tabIndexSubscriber__subscriber() {
      const { groupId, tabindex } = this.$props;

      return new TabIndexSubscriber(this.tabIndexSubscriber__setTabIndex, {
        tabIndex: tabindex,
        groupId
      });
    }
  },

  methods: {
    tabIndexSubscriber__setTabIndex(tabIndex) {
      if (this.$props.groupId) {
        this.tabIndexSubscriber__tabIndex = tabIndex;
      }
    },

    tabIndexSubscriber__subscribe() {
      this.tabIndexSubscriber__subscription?.unsubscribe();

      if (this.tabIndexSubscriber__subscriber) {
        this.tabIndexSubscriber__subscription = tabIndexManager.subscribe(this.tabIndexSubscriber__subscriber);
      }
    },

    tabIndexSubscriber__onFocus() {
      if (this.tabIndexSubscriber__subscriber) {
        tabIndexManager.activate(this.tabIndexSubscriber__subscriber);
      }
    }
  }
};
