import { types } from './types';
import { cleanPageNum, setPaginationConfigMutation, toServerPageOptions } from '../../../model/page';
import { createMainFilterInput } from '../../../services/filter/utils/filterUtils';

function createFilterParams(state) {
  const params = {};

  if (state.filterInput.value && state.filterInput.valid) {
    params.multipleFilter = state.filterInput.value;
  }

  return params;
}

export const mutations = {
  [types.SET_LOADING](state, value) {
    state.loading = value;
  },

  [types.SET_PATIENTS_PAGE](state, value) {
    state.patientsPage = value;
    Object.assign(state.serverSideOptions, toServerPageOptions(value));
  },

  [types.SET_PAGINATION_CONFIG]: setPaginationConfigMutation,

  [types.INIT_FORM](state) {
    state.filterInput = createMainFilterInput({
      id: 'filter',
      placeholder: 'patient.filterText',
    });
    state.filterParams = createFilterParams(state);
  },

  [types.UPDATE_FILTER_PARAMS](state) {
    state.filterParams = createFilterParams(state);
    cleanPageNum(state);
  }
};

