import { createInputConfig, validateInput } from '../../utils';
import { AddressType, InputType } from '../../constants';

export function getAddressCommonConfig({ value, customizations, tabindex, addressTypeOptions }) {
  return {
    postalCode: initField(value, 'postalCode', 'address.postalCode', 0.4, true, tabindex, customizations),
    city: initField(value, 'city', 'address.city', 0.5, true, tabindex, customizations),
    country: createInputConfig({
      id: 'country',
      type: InputType.Typeahead2,
      options: [],
      value: null,
      placeholder: 'address.country',
      tabindex: tabindex + 0.6,
      required: true,
      ...customizations.country,
    }),
    addressType: createInputConfig({
      id: 'addressType',
      placeholder: 'address.addressType',
      type: InputType.Select2,
      value: value ? value.addressType : `addressType.${ AddressType.Address }`,
      inline: true,
      tabindex: tabindex + 0.7,
      options: addressTypeOptions,
      ...customizations.addressType,
    })
  };
}

export function initField(value, id, label, tabindexOffset, required, tabindex, customizations) {
  return createInputConfig({
    id,
    label: null,
    placeholder: label,
    type: InputType.Text,
    required,
    value: value ? value[id] : null,
    tabindex: tabindex + tabindexOffset,
    ...customizations[id],
  });
}

export function patchValidation(inputs, required) {
  // if address required, then all fields stay required (except initially not required)
  // if not, then all fields are required only in case at least one is filled
  const hasAnyValue = inputs.some(input => !!input.value);
  inputs.forEach(i => {
    i.required = i.originalRequired && (required || hasAnyValue);
    i.valid = validateInput(i);
  });
}

export function formatCountryOptions(options) {
  return options.map(o => ({ label: formatCountryLabel(o), value: o }));
}

export function formatCountryLabel(country) {
  return country && `${ country.name } ${ country.code2 }`;
}

export function getSelectedCountry(value, countryOptions, defaultCountryCode) {
  if ((!value || !value.country) && !defaultCountryCode) {
    return null;
  }
  else if (!countryOptions) {
    return value || {
      country: {
        code2: defaultCountryCode
      }
    };
  }

  const code = value?.country?.code2 || defaultCountryCode;

  return countryOptions.find(o => o.code2 === code);
}

export function getSelectedAddressType(value) {
  if (!value || !value.addressType) {
    return AddressType.Address;
  }

  return value.addressType;
}
