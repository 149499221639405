import { CrudService } from '../../../classes/crudService';
import { CrossTenantPatientModel } from '../../../model/patientModel';
import { PageModel } from '../../../model/page';

export const pageableLogisticPatients = new CrudService(
  'logistics-patients',
  data => new PageModel(data, v => new CrossTenantPatientModel(v))
);

export const logisticPatients = new CrudService(
  'logistics-patients',
  data => new CrossTenantPatientModel(data)
);
