import { mapArrayCustom } from '../../utils/mapUtils';
import { observationBuilder } from './observationBuilder';

export class GroupedObservationModel {
  /**
   * GroupedObservationModel config
   *
   * @param {Object}                config
   * @param {ObservationModel}      config.observation
   * @param {ObservationModel[]}    config.linkedObservation
   */
  constructor(config) {
    this.observation = observationBuilder(config.observation);
    this.linkedObservation = mapArrayCustom(config.linkedObservation, observationBuilder);
  }

  findLinkedObservation(terminologyType) {
    return this.linkedObservation.find(observation => observation.terminologyType === terminologyType);
  }

  get id() {
    return this.uuid;
  }

  get uuid() {
    return this.observation?.uuid;
  }

}
