import { createInputConfig, InputType } from '@h4h/inputs';
import { createMainFilterInput } from '../../../../../../services/filter/utils/filterUtils';
import { localize } from '../../../../../../services/localize';
import { healthRecordConclusions } from '../../../../../../constants/healthRecords';

/**
 *
 * @param enrollment
 * @param conclusions
 * @returns {{period, intervalChooser, severities, programFilter, statusFilter, searchFilter, forwardedFilter}}
 */
export function createFilters({ conclusions }) {
  const conclusionNames = createInputConfig({
    id: 'conclusionNames',
    type: InputType.Select2,
    required: false,
    multiple: true,
    label: 'ecg.conclusion',
    options: getKnownConclusionsOptions(conclusions),
    value: new Set(),
  });
  const conclusionBy = createMainFilterInput({
    id: 'conclusionBy',
    icon: null,
    label: 'ecg.conclusionBy',
  });
  return {
    conclusionNames,
    conclusionBy,
  };
}

/**
 * Maps health record types in {name, translationKey} format to {label, value} format
 * @param {Object} hrObject
 *
 * @returns {{label:string, value:string}[]}
 */
function getHealthRecordSelectOptionsFor(hrObject) {
  return Object.keys(hrObject).map(key => ({
    value: hrObject[key].name,
    label: localize(hrObject[key].translationKey),
  }));
}

function getKnownConclusionsOptions(conclusions) {
  const knownConclusionsOptions = getHealthRecordSelectOptionsFor(healthRecordConclusions);
  return conclusions.map(c => {
    return knownConclusionsOptions.find(kc => kc.value === c.name) ||
      { label: c.name, value: c.name };
  });
}

