import { types } from './types';
import { fetchEnrollment, updateEnrollmentProgramSchedule } from '../../../../../services/api/enrollments/enrollments';
import { showRestError } from '../../../../../utils/errors';
import { observationTemplates } from '../../../../../services/api/observations/observationTemplates';
import { questionnaireTemplates } from '../../../../../services/api/questionnaire/questionnaireTemplates';
import { popups } from '../../../../../services/popups';
import { PopupName } from '../../../../../constants/popupName';
import { PopupType } from '@h4h/popups';
import { latestTreatmentAdvice, sendTAFeedback } from '../../../../../services/api/treatmentadvice/treatmentadvice';
import { toast } from '../../../../../services/toast';
import { localize } from '../../../../../services/localize';
import { createCronModelAction } from '../../../../../components/cronEditor/store/actions';

export const actions = Object.freeze({
  initEnrollmentContainer,
  fetchObservationTemplates,
  initTreatmentAdvice,
  showTreatmentAdvice,
  showTreatmentAdviceAgreedPopup,
  sendAgreementTAFeedback,
  sendDisagreementTAFeedback,
  sendTreatmentAdviceFeedback,
  showDialog,
  hideDialog,
  updateSchedule,
  setCronModel: createCronModelAction(types.SET_CRON_MODEL),
});

async function initEnrollmentContainer({ commit, state }, { patient, enrollmentId }) {
  commit(types.SET_PAGE_INITIALIZED, false);
  commit(types.SET_PATIENT, null);
  commit(types.SET_ENROLLMENT, null);
  commit(types.SET_LOADING, true);
  const result = await fetchEnrollment(patient.uuid, enrollmentId);
  commit(types.SET_LOADING, false);

  if (result.error) {
    showRestError(result.error, 'messages.cantFetchEnrollment');
    return null;
  }

  commit(types.SET_PATIENT, patient);
  commit(types.SET_ENROLLMENT, result.data);

  const observationTemplateIds = state.observations.map(observation => observation.observationTemplateId);
  await fetchObservationTemplates({ commit }, observationTemplateIds);
  const questionnaireTemplateIds = state.questionnaires.map(questionnaire => questionnaire.questionnaireTemplateId);
  await fetchQuestionnaireTemplates({ commit }, questionnaireTemplateIds);

  commit(types.INIT_PAGE);
  commit(types.SET_PAGE_INITIALIZED, true);
  return result.success;
}

async function fetchObservationTemplates({ commit }, observationTemplateIds) {
  commit(types.SET_LOADING, true);
  const result = await observationTemplates.fetch({
    params: {
      id: observationTemplateIds
    }
  });
  commit(types.SET_LOADING, false);
  if (result.success) {
    commit(types.SET_OBSERVATION_TEMPLATES, result.data);
    return result.data;
  }

  showRestError(result.error, 'messages.cantFetchObservationTemplates');
  return [];
}

async function fetchQuestionnaireTemplates({ commit }, questionnaireTemplateIds) {
  commit(types.SET_LOADING, true);
  const result = await questionnaireTemplates.fetch({
    params: {
      id: questionnaireTemplateIds
    }
  });
  commit(types.SET_LOADING, false);
  if (result.success) {
    commit(types.SET_QUESTIONNAIRE_TEMPLATES, result.data);
    return result.data;
  }

  showRestError(result.error, 'messages.cantFetchQuestionnaireTemplates');
  return [];
}

async function initTreatmentAdvice({ commit }, enrollmentUuid) {
  const latestTA = await latestTreatmentAdvice(enrollmentUuid).fetchOne();
  commit(types.SET_TREATMENT_ADVICE, {
    treatmentAdviceText: latestTA.data.template,
    hasFeedback: latestTA.data.hasFeedback,
  });
}

async function showTreatmentAdvice({ commit }) {
  const { id } = await popups.show({
    name: PopupName.TreatmentAdvice,
    type: PopupType.Modal
  });
  commit(types.SET_TREATMENT_ADVICE_POPUP_ID, {
    popupId: id,
  });
}

async function showTreatmentAdviceAgreedPopup({ commit }) {
  const { id } = await popups.show({
    name: PopupName.TreatmentAdviceAgreedPopup,
    type: PopupType.Modal
  });
  commit(types.INIT_TREATMENT_ADVICE_AGREED_POPUP, {
    popupId: id,
  });
}

async function sendAgreementTAFeedback({ state, commit }, enrollmentUuid) {
  sendTreatmentAdviceFeedback({ state, commit }, { enrollmentUuid, accepted: true });
}

async function sendDisagreementTAFeedback({ state, commit }, enrollmentUuid) {
  sendTreatmentAdviceFeedback({ state, commit }, { enrollmentUuid, accepted: false });
}

async function sendTreatmentAdviceFeedback({ state, commit }, { enrollmentUuid, accepted }) {
  let config;
  if (accepted) {
    config = { text: state.treatmentAdviceAgreedComment.value, accepted: true };
  }
  else {
    config = { text: '', accepted: false };
  }
  const result = await sendTAFeedback(enrollmentUuid, config);
  if (result.success) {
    popups.hide({ id: state.treatmentAdviceAgreedPopupId });
    await initTreatmentAdvice({ commit }, enrollmentUuid);
    toast.success(localize('messages.sentTAFeedback'));
  }
  else {
    showRestError(result.error, 'messages.cantSendTAFeedback');
  }
}

async function showDialog({ commit }, { programInput }) {
  const { id } = await popups.show({
    name: PopupName.EditEnrollmentSchedule,
    type: PopupType.Modal,
  });
  commit(types.INIT_POPUP, {
    popupId: id,
    programInput,
  });
}

async function hideDialog({ state }) {
  popups.hide({ id: state.editSchedule.popupId });
}

async function updateSchedule({ state, commit, dispatch }) {
  if (!state.editSchedule.cronModel.valid) {
    return;
  }

  commit(types.SET_LOADING, true);

  const result = await updateEnrollmentProgramSchedule(state.enrollment.uuid, state.editSchedule.programInput.templateLink.uuid).updateOne({
    overriddenCron: state.editSchedule.cronModel.cron,
  });

  commit(types.SET_LOADING, false);

  if (result.success) {
    popups.hide({ id: state.editSchedule.popupId });
    dispatch('initEnrollmentContainer', {
      patient: state.patient,
      enrollmentId: state.enrollment.uuid,
    });
  }
  else {
    showRestError(result.error, 'messages.cantUpdateProgramSchedule');
  }
}

