export const state = {
  fetched: false,
  loading: false,
  groups: [],
  groupsInput: null,
  deleteGroupPopupId: null,
  currentGroupId: null,
  filterInput: null,
  filteredRows: [],
};
