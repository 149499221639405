import sanitizeHtml from 'sanitize-html';

const allowedTags = ['div', 'span', 'p', 'br', 'li', 'ol', 'ul', 'b', 'i', 'u', 'a'];
const allowedSchemes = ['http', 'https'];
const allowedAttributes = ['href', 'type'];
const allowedAttributesMap = {};
allowedTags.forEach(tag => {
  allowedAttributesMap[tag] = allowedAttributes;
});

export function sanitizeHtmlMsg(string) {
  return sanitizeHtml(string, {
    allowedTags,
    allowedAttributes: allowedAttributesMap,
    allowedSchemes
  });
}