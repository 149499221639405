import { localize } from '../../../../services/localize';
import { Password } from '../../../../constants/password';

export const getters = {
  isValid,
  errorMessage,
};

function isValid(state) {
  if (state.password && state.passwordConfirm && !state.password.pristine) {
    return state.password.valid && state.passwordConfirm.valid &&
      state.password.value === state.passwordConfirm.value;
  }
  else {
    return true;
  }
}

function errorMessage(state, getters) {
  if (!getters.isValid) {
    if (!state.password.valid) {
      return localize('messages.passwordMustBeAtLeastXLettersLong', [Password.Length]);
    }
    else if (state.password.value !== state.passwordConfirm.value) {
      return localize('messages.passwordsMustBeTheSame');
    }
  }
}
