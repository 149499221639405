import { mapObject } from '../../utils/mapUtils';
import { TriggerConditionsModel } from './triggerConditionsModel';

export class ObservationThresholdSeverityConditionsModel {
  /**
   * ObservationThresholdSeverityConditionsModel
   *
   * @param {Object}                  config
   * @param {String}                  config.type  //h4h observation component terminology code
   * @param {ObservationInputType}    config.inputType
   * @param {TriggerConditionsModel}  config.conditions
   */
  constructor(config) {
    this.type = config.type;
    this.inputType = config.inputType;
    this.conditions = mapObject(config.conditions, TriggerConditionsModel);
  }

  createDefaultSeverityConditionsList() {
    this.conditions = new TriggerConditionsModel({ list: [] });
    this.conditions.createDefaultSeverityConditions();
  }

  get isEmpty() {
    return this.conditions.isEmpty;
  }
}
